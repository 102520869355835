export const levelRatio = {
  "1952-03-01": {
    householdsCorporateOrSelf: 1.231,
    households: 0.238,
    corporateOrSelf: 0.293,
    government: 0.629,
    municipalities: 0.071,
    corporate: 0.222,
    selfemployed: 0.071,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.045,
    financialLoans: 0.012,
    corporateBonds: 0.114,
    corporateDepositaryLoansExmortgage: 0.062,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.009
  },
  "1952-06-01": {
    householdsCorporateOrSelf: 1.254,
    households: 0.247,
    corporateOrSelf: 0.298,
    government: 0.63,
    municipalities: 0.079,
    corporate: 0.225,
    selfemployed: 0.073,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.047,
    financialLoans: 0.012,
    corporateBonds: 0.117,
    corporateDepositaryLoansExmortgage: 0.061,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.009
  },
  "1952-09-01": {
    householdsCorporateOrSelf: 1.253,
    households: 0.25,
    corporateOrSelf: 0.299,
    government: 0.623,
    municipalities: 0.08,
    corporate: 0.226,
    selfemployed: 0.073,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.048,
    financialLoans: 0.012,
    corporateBonds: 0.118,
    corporateDepositaryLoansExmortgage: 0.061,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1952-12-01": {
    householdsCorporateOrSelf: 1.231,
    households: 0.251,
    corporateOrSelf: 0.296,
    government: 0.604,
    municipalities: 0.081,
    corporate: 0.223,
    selfemployed: 0.072,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.047,
    financialLoans: 0.012,
    corporateBonds: 0.117,
    corporateDepositaryLoansExmortgage: 0.06,
    corporateMortgages: 0.035,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1953-03-01": {
    householdsCorporateOrSelf: 1.229,
    households: 0.257,
    corporateOrSelf: 0.295,
    government: 0.595,
    municipalities: 0.083,
    corporate: 0.223,
    selfemployed: 0.071,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.046,
    financialLoans: 0.012,
    corporateBonds: 0.117,
    corporateDepositaryLoansExmortgage: 0.06,
    corporateMortgages: 0.035,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1953-06-01": {
    householdsCorporateOrSelf: 1.238,
    households: 0.263,
    corporateOrSelf: 0.296,
    government: 0.593,
    municipalities: 0.085,
    corporate: 0.225,
    selfemployed: 0.072,
    selfDepositaryLoans: 0.012,
    financialMortages: 0.048,
    financialLoans: 0.012,
    corporateBonds: 0.118,
    corporateDepositaryLoansExmortgage: 0.06,
    corporateMortgages: 0.035,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1953-09-01": {
    householdsCorporateOrSelf: 1.265,
    households: 0.27,
    corporateOrSelf: 0.301,
    government: 0.606,
    municipalities: 0.088,
    corporate: 0.228,
    selfemployed: 0.073,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.049,
    financialLoans: 0.012,
    corporateBonds: 0.12,
    corporateDepositaryLoansExmortgage: 0.06,
    corporateMortgages: 0.035,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.012
  },
  "1953-12-01": {
    householdsCorporateOrSelf: 1.293,
    households: 0.28,
    corporateOrSelf: 0.305,
    government: 0.615,
    municipalities: 0.093,
    corporate: 0.231,
    selfemployed: 0.073,
    selfDepositaryLoans: 0.011,
    financialMortages: 0.05,
    financialLoans: 0.012,
    corporateBonds: 0.124,
    corporateDepositaryLoansExmortgage: 0.058,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1954-03-01": {
    householdsCorporateOrSelf: 1.307,
    households: 0.285,
    corporateOrSelf: 0.309,
    government: 0.616,
    municipalities: 0.096,
    corporate: 0.234,
    selfemployed: 0.075,
    selfDepositaryLoans: 0.012,
    financialMortages: 0.05,
    financialLoans: 0.013,
    corporateBonds: 0.127,
    corporateDepositaryLoansExmortgage: 0.058,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1954-06-01": {
    householdsCorporateOrSelf: 1.319,
    households: 0.292,
    corporateOrSelf: 0.312,
    government: 0.617,
    municipalities: 0.099,
    corporate: 0.235,
    selfemployed: 0.077,
    selfDepositaryLoans: 0.012,
    financialMortages: 0.052,
    financialLoans: 0.012,
    corporateBonds: 0.129,
    corporateDepositaryLoansExmortgage: 0.057,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1954-09-01": {
    householdsCorporateOrSelf: 1.32,
    households: 0.297,
    corporateOrSelf: 0.313,
    government: 0.609,
    municipalities: 0.102,
    corporate: 0.235,
    selfemployed: 0.078,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.053,
    financialLoans: 0.013,
    corporateBonds: 0.13,
    corporateDepositaryLoansExmortgage: 0.056,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1954-12-01": {
    householdsCorporateOrSelf: 1.315,
    households: 0.301,
    corporateOrSelf: 0.31,
    government: 0.601,
    municipalities: 0.103,
    corporate: 0.232,
    selfemployed: 0.077,
    selfDepositaryLoans: 0.012,
    financialMortages: 0.053,
    financialLoans: 0.013,
    corporateBonds: 0.129,
    corporateDepositaryLoansExmortgage: 0.054,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1955-03-01": {
    householdsCorporateOrSelf: 1.291,
    households: 0.304,
    corporateOrSelf: 0.304,
    government: 0.58,
    municipalities: 0.103,
    corporate: 0.227,
    selfemployed: 0.076,
    selfDepositaryLoans: 0.011,
    financialMortages: 0.052,
    financialLoans: 0.013,
    corporateBonds: 0.126,
    corporateDepositaryLoansExmortgage: 0.054,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1955-06-01": {
    householdsCorporateOrSelf: 1.29,
    households: 0.312,
    corporateOrSelf: 0.306,
    government: 0.568,
    municipalities: 0.104,
    corporate: 0.228,
    selfemployed: 0.078,
    selfDepositaryLoans: 0.012,
    financialMortages: 0.053,
    financialLoans: 0.013,
    corporateBonds: 0.125,
    corporateDepositaryLoansExmortgage: 0.056,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1955-09-01": {
    householdsCorporateOrSelf: 1.286,
    households: 0.318,
    corporateOrSelf: 0.306,
    government: 0.557,
    municipalities: 0.105,
    corporate: 0.227,
    selfemployed: 0.079,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.054,
    financialLoans: 0.013,
    corporateBonds: 0.124,
    corporateDepositaryLoansExmortgage: 0.057,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1955-12-01": {
    householdsCorporateOrSelf: 1.29,
    households: 0.324,
    corporateOrSelf: 0.311,
    government: 0.549,
    municipalities: 0.106,
    corporate: 0.232,
    selfemployed: 0.08,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.054,
    financialLoans: 0.013,
    corporateBonds: 0.125,
    corporateDepositaryLoansExmortgage: 0.059,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1956-03-01": {
    householdsCorporateOrSelf: 1.299,
    households: 0.331,
    corporateOrSelf: 0.317,
    government: 0.544,
    municipalities: 0.107,
    corporate: 0.236,
    selfemployed: 0.081,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.055,
    financialLoans: 0.013,
    corporateBonds: 0.125,
    corporateDepositaryLoansExmortgage: 0.062,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1956-06-01": {
    householdsCorporateOrSelf: 1.292,
    households: 0.335,
    corporateOrSelf: 0.321,
    government: 0.528,
    municipalities: 0.108,
    corporate: 0.239,
    selfemployed: 0.082,
    selfDepositaryLoans: 0.014,
    financialMortages: 0.056,
    financialLoans: 0.013,
    corporateBonds: 0.125,
    corporateDepositaryLoansExmortgage: 0.065,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1956-09-01": {
    householdsCorporateOrSelf: 1.292,
    households: 0.338,
    corporateOrSelf: 0.324,
    government: 0.52,
    municipalities: 0.109,
    corporate: 0.241,
    selfemployed: 0.083,
    selfDepositaryLoans: 0.014,
    financialMortages: 0.057,
    financialLoans: 0.013,
    corporateBonds: 0.126,
    corporateDepositaryLoansExmortgage: 0.066,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1956-12-01": {
    householdsCorporateOrSelf: 1.283,
    households: 0.34,
    corporateOrSelf: 0.323,
    government: 0.51,
    municipalities: 0.109,
    corporate: 0.241,
    selfemployed: 0.082,
    selfDepositaryLoans: 0.014,
    financialMortages: 0.056,
    financialLoans: 0.013,
    corporateBonds: 0.126,
    corporateDepositaryLoansExmortgage: 0.067,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.01
  },
  "1957-03-01": {
    householdsCorporateOrSelf: 1.276,
    households: 0.341,
    corporateOrSelf: 0.324,
    government: 0.503,
    municipalities: 0.109,
    corporate: 0.242,
    selfemployed: 0.081,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.055,
    financialLoans: 0.013,
    corporateBonds: 0.127,
    corporateDepositaryLoansExmortgage: 0.067,
    corporateMortgages: 0.038,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1957-06-01": {
    householdsCorporateOrSelf: 1.279,
    households: 0.347,
    corporateOrSelf: 0.33,
    government: 0.492,
    municipalities: 0.111,
    corporate: 0.247,
    selfemployed: 0.082,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.056,
    financialLoans: 0.013,
    corporateBonds: 0.129,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.011
  },
  "1957-09-01": {
    householdsCorporateOrSelf: 1.278,
    households: 0.347,
    corporateOrSelf: 0.33,
    government: 0.489,
    municipalities: 0.112,
    corporate: 0.248,
    selfemployed: 0.083,
    selfDepositaryLoans: 0.013,
    financialMortages: 0.057,
    financialLoans: 0.013,
    corporateBonds: 0.13,
    corporateDepositaryLoansExmortgage: 0.068,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.012
  },
  "1957-12-01": {
    householdsCorporateOrSelf: 1.301,
    households: 0.355,
    corporateOrSelf: 0.339,
    government: 0.491,
    municipalities: 0.116,
    corporate: 0.254,
    selfemployed: 0.085,
    selfDepositaryLoans: 0.014,
    financialMortages: 0.058,
    financialLoans: 0.013,
    corporateBonds: 0.135,
    corporateDepositaryLoansExmortgage: 0.067,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.012
  },
  "1958-03-01": {
    householdsCorporateOrSelf: 1.337,
    households: 0.364,
    corporateOrSelf: 0.35,
    government: 0.503,
    municipalities: 0.121,
    corporate: 0.262,
    selfemployed: 0.087,
    selfDepositaryLoans: 0.014,
    financialMortages: 0.06,
    financialLoans: 0.013,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.067,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.013
  },
  "1958-06-01": {
    householdsCorporateOrSelf: 1.346,
    households: 0.365,
    corporateOrSelf: 0.351,
    government: 0.507,
    municipalities: 0.122,
    corporate: 0.262,
    selfemployed: 0.089,
    selfDepositaryLoans: 0.015,
    financialMortages: 0.061,
    financialLoans: 0.013,
    corporateBonds: 0.142,
    corporateDepositaryLoansExmortgage: 0.066,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.012
  },
  "1958-09-01": {
    householdsCorporateOrSelf: 1.32,
    households: 0.361,
    corporateOrSelf: 0.346,
    government: 0.491,
    municipalities: 0.122,
    corporate: 0.258,
    selfemployed: 0.088,
    selfDepositaryLoans: 0.014,
    financialMortages: 0.061,
    financialLoans: 0.013,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.065,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.011
  },
  "1958-12-01": {
    householdsCorporateOrSelf: 1.314,
    households: 0.361,
    corporateOrSelf: 0.345,
    government: 0.487,
    municipalities: 0.121,
    corporate: 0.256,
    selfemployed: 0.088,
    selfDepositaryLoans: 0.015,
    financialMortages: 0.061,
    financialLoans: 0.013,
    corporateBonds: 0.14,
    corporateDepositaryLoansExmortgage: 0.063,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.012
  },
  "1959-03-01": {
    householdsCorporateOrSelf: 1.308,
    households: 0.363,
    corporateOrSelf: 0.343,
    government: 0.481,
    municipalities: 0.122,
    corporate: 0.253,
    selfemployed: 0.089,
    selfDepositaryLoans: 0.015,
    financialMortages: 0.061,
    financialLoans: 0.013,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.063,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.012
  },
  "1959-06-01": {
    householdsCorporateOrSelf: 1.305,
    households: 0.365,
    corporateOrSelf: 0.344,
    government: 0.474,
    municipalities: 0.122,
    corporate: 0.253,
    selfemployed: 0.091,
    selfDepositaryLoans: 0.015,
    financialMortages: 0.063,
    financialLoans: 0.013,
    corporateBonds: 0.136,
    corporateDepositaryLoansExmortgage: 0.065,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.012
  },
  "1959-09-01": {
    householdsCorporateOrSelf: 1.325,
    households: 0.374,
    corporateOrSelf: 0.349,
    government: 0.477,
    municipalities: 0.125,
    corporate: 0.255,
    selfemployed: 0.094,
    selfDepositaryLoans: 0.015,
    financialMortages: 0.065,
    financialLoans: 0.013,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.067,
    corporateMortgages: 0.038,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.013
  },
  "1959-12-01": {
    householdsCorporateOrSelf: 1.338,
    households: 0.383,
    corporateOrSelf: 0.354,
    government: 0.475,
    municipalities: 0.126,
    corporate: 0.259,
    selfemployed: 0.095,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.066,
    financialLoans: 0.013,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.067,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.013
  },
  "1960-03-01": {
    householdsCorporateOrSelf: 1.317,
    households: 0.38,
    corporateOrSelf: 0.351,
    government: 0.461,
    municipalities: 0.126,
    corporate: 0.257,
    selfemployed: 0.095,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.066,
    financialLoans: 0.013,
    corporateBonds: 0.135,
    corporateDepositaryLoansExmortgage: 0.068,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.015
  },
  "1960-06-01": {
    householdsCorporateOrSelf: 1.34,
    households: 0.39,
    corporateOrSelf: 0.36,
    government: 0.461,
    municipalities: 0.129,
    corporate: 0.262,
    selfemployed: 0.097,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.068,
    financialLoans: 0.013,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.07,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.016
  },
  "1960-09-01": {
    householdsCorporateOrSelf: 1.344,
    households: 0.395,
    corporateOrSelf: 0.361,
    government: 0.458,
    municipalities: 0.13,
    corporate: 0.262,
    selfemployed: 0.099,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.07,
    financialLoans: 0.013,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.016
  },
  "1960-12-01": {
    householdsCorporateOrSelf: 1.375,
    households: 0.407,
    corporateOrSelf: 0.371,
    government: 0.463,
    municipalities: 0.134,
    corporate: 0.27,
    selfemployed: 0.102,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.072,
    financialLoans: 0.014,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.07,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.017
  },
  "1961-03-01": {
    householdsCorporateOrSelf: 1.377,
    households: 0.41,
    corporateOrSelf: 0.372,
    government: 0.459,
    municipalities: 0.135,
    corporate: 0.268,
    selfemployed: 0.104,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.074,
    financialLoans: 0.014,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.071,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.016
  },
  "1961-06-01": {
    householdsCorporateOrSelf: 1.37,
    households: 0.41,
    corporateOrSelf: 0.371,
    government: 0.454,
    municipalities: 0.134,
    corporate: 0.267,
    selfemployed: 0.105,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.075,
    financialLoans: 0.014,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.016
  },
  "1961-09-01": {
    householdsCorporateOrSelf: 1.364,
    households: 0.408,
    corporateOrSelf: 0.37,
    government: 0.452,
    municipalities: 0.134,
    corporate: 0.264,
    selfemployed: 0.106,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.076,
    financialLoans: 0.014,
    corporateBonds: 0.14,
    corporateDepositaryLoansExmortgage: 0.068,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1961-12-01": {
    householdsCorporateOrSelf: 1.358,
    households: 0.41,
    corporateOrSelf: 0.37,
    government: 0.445,
    municipalities: 0.134,
    corporate: 0.263,
    selfemployed: 0.106,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.077,
    financialLoans: 0.013,
    corporateBonds: 0.139,
    corporateDepositaryLoansExmortgage: 0.066,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1962-03-01": {
    householdsCorporateOrSelf: 1.347,
    households: 0.407,
    corporateOrSelf: 0.367,
    government: 0.439,
    municipalities: 0.134,
    corporate: 0.26,
    selfemployed: 0.107,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.077,
    financialLoans: 0.013,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.066,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1962-06-01": {
    householdsCorporateOrSelf: 1.356,
    households: 0.412,
    corporateOrSelf: 0.372,
    government: 0.437,
    municipalities: 0.135,
    corporate: 0.263,
    selfemployed: 0.109,
    selfDepositaryLoans: 0.016,
    financialMortages: 0.079,
    financialLoans: 0.013,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.067,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1962-09-01": {
    householdsCorporateOrSelf: 1.355,
    households: 0.416,
    corporateOrSelf: 0.373,
    government: 0.431,
    municipalities: 0.135,
    corporate: 0.263,
    selfemployed: 0.11,
    selfDepositaryLoans: 0.017,
    financialMortages: 0.081,
    financialLoans: 0.013,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.068,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1962-12-01": {
    householdsCorporateOrSelf: 1.376,
    households: 0.424,
    corporateOrSelf: 0.38,
    government: 0.435,
    municipalities: 0.137,
    corporate: 0.267,
    selfemployed: 0.113,
    selfDepositaryLoans: 0.017,
    financialMortages: 0.082,
    financialLoans: 0.013,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1963-03-01": {
    householdsCorporateOrSelf: 1.376,
    households: 0.427,
    corporateOrSelf: 0.381,
    government: 0.431,
    municipalities: 0.137,
    corporate: 0.266,
    selfemployed: 0.114,
    selfDepositaryLoans: 0.017,
    financialMortages: 0.083,
    financialLoans: 0.014,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1963-06-01": {
    householdsCorporateOrSelf: 1.384,
    households: 0.434,
    corporateOrSelf: 0.383,
    government: 0.429,
    municipalities: 0.138,
    corporate: 0.267,
    selfemployed: 0.117,
    selfDepositaryLoans: 0.017,
    financialMortages: 0.086,
    financialLoans: 0.014,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1963-09-01": {
    householdsCorporateOrSelf: 1.371,
    households: 0.435,
    corporateOrSelf: 0.381,
    government: 0.418,
    municipalities: 0.136,
    corporate: 0.264,
    selfemployed: 0.117,
    selfDepositaryLoans: 0.017,
    financialMortages: 0.086,
    financialLoans: 0.014,
    corporateBonds: 0.135,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.016
  },
  "1963-12-01": {
    householdsCorporateOrSelf: 1.377,
    households: 0.441,
    corporateOrSelf: 0.386,
    government: 0.414,
    municipalities: 0.136,
    corporate: 0.266,
    selfemployed: 0.119,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.087,
    financialLoans: 0.014,
    corporateBonds: 0.135,
    corporateDepositaryLoansExmortgage: 0.07,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.017
  },
  "1964-03-01": {
    householdsCorporateOrSelf: 1.365,
    households: 0.441,
    corporateOrSelf: 0.383,
    government: 0.407,
    municipalities: 0.135,
    corporate: 0.264,
    selfemployed: 0.119,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.087,
    financialLoans: 0.014,
    corporateBonds: 0.133,
    corporateDepositaryLoansExmortgage: 0.069,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.017
  },
  "1964-06-01": {
    householdsCorporateOrSelf: 1.372,
    households: 0.446,
    corporateOrSelf: 0.386,
    government: 0.404,
    municipalities: 0.136,
    corporate: 0.265,
    selfemployed: 0.121,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.089,
    financialLoans: 0.014,
    corporateBonds: 0.133,
    corporateDepositaryLoansExmortgage: 0.07,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.018
  },
  "1964-09-01": {
    householdsCorporateOrSelf: 1.369,
    households: 0.447,
    corporateOrSelf: 0.387,
    government: 0.399,
    municipalities: 0.136,
    corporate: 0.265,
    selfemployed: 0.122,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.09,
    financialLoans: 0.014,
    corporateBonds: 0.132,
    corporateDepositaryLoansExmortgage: 0.07,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.018
  },
  "1964-12-01": {
    householdsCorporateOrSelf: 1.384,
    households: 0.455,
    corporateOrSelf: 0.393,
    government: 0.399,
    municipalities: 0.137,
    corporate: 0.269,
    selfemployed: 0.124,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.092,
    financialLoans: 0.014,
    corporateBonds: 0.133,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.018
  },
  "1965-03-01": {
    householdsCorporateOrSelf: 1.368,
    households: 0.451,
    corporateOrSelf: 0.392,
    government: 0.389,
    municipalities: 0.136,
    corporate: 0.269,
    selfemployed: 0.123,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.091,
    financialLoans: 0.014,
    corporateBonds: 0.13,
    corporateDepositaryLoansExmortgage: 0.075,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.018
  },
  "1965-06-01": {
    householdsCorporateOrSelf: 1.368,
    households: 0.453,
    corporateOrSelf: 0.396,
    government: 0.383,
    municipalities: 0.136,
    corporate: 0.271,
    selfemployed: 0.124,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.092,
    financialLoans: 0.014,
    corporateBonds: 0.13,
    corporateDepositaryLoansExmortgage: 0.077,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.019
  },
  "1965-09-01": {
    householdsCorporateOrSelf: 1.352,
    households: 0.451,
    corporateOrSelf: 0.395,
    government: 0.37,
    municipalities: 0.135,
    corporate: 0.271,
    selfemployed: 0.125,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.092,
    financialLoans: 0.014,
    corporateBonds: 0.129,
    corporateDepositaryLoansExmortgage: 0.078,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.019
  },
  "1965-12-01": {
    householdsCorporateOrSelf: 1.34,
    households: 0.448,
    corporateOrSelf: 0.394,
    government: 0.364,
    municipalities: 0.134,
    corporate: 0.27,
    selfemployed: 0.124,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.092,
    financialLoans: 0.014,
    corporateBonds: 0.126,
    corporateDepositaryLoansExmortgage: 0.079,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.019
  },
  "1966-03-01": {
    householdsCorporateOrSelf: 1.324,
    households: 0.443,
    corporateOrSelf: 0.394,
    government: 0.355,
    municipalities: 0.132,
    corporate: 0.27,
    selfemployed: 0.124,
    selfDepositaryLoans: 0.018,
    financialMortages: 0.092,
    financialLoans: 0.014,
    corporateBonds: 0.126,
    corporateDepositaryLoansExmortgage: 0.08,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.019
  },
  "1966-06-01": {
    householdsCorporateOrSelf: 1.333,
    households: 0.447,
    corporateOrSelf: 0.402,
    government: 0.351,
    municipalities: 0.133,
    corporate: 0.276,
    selfemployed: 0.126,
    selfDepositaryLoans: 0.019,
    financialMortages: 0.093,
    financialLoans: 0.014,
    corporateBonds: 0.128,
    corporateDepositaryLoansExmortgage: 0.083,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.02
  },
  "1966-09-01": {
    householdsCorporateOrSelf: 1.325,
    households: 0.443,
    corporateOrSelf: 0.405,
    government: 0.344,
    municipalities: 0.132,
    corporate: 0.279,
    selfemployed: 0.126,
    selfDepositaryLoans: 0.019,
    financialMortages: 0.093,
    financialLoans: 0.014,
    corporateBonds: 0.128,
    corporateDepositaryLoansExmortgage: 0.085,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.001,
    CorporateOther: 0.019
  },
  "1966-12-01": {
    householdsCorporateOrSelf: 1.323,
    households: 0.442,
    corporateOrSelf: 0.406,
    government: 0.343,
    municipalities: 0.132,
    corporate: 0.28,
    selfemployed: 0.126,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.093,
    financialLoans: 0.014,
    corporateBonds: 0.129,
    corporateDepositaryLoansExmortgage: 0.084,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.002,
    CorporateOther: 0.02
  },
  "1967-03-01": {
    householdsCorporateOrSelf: 1.326,
    households: 0.44,
    corporateOrSelf: 0.411,
    government: 0.343,
    municipalities: 0.132,
    corporate: 0.284,
    selfemployed: 0.127,
    selfDepositaryLoans: 0.019,
    financialMortages: 0.093,
    financialLoans: 0.014,
    corporateBonds: 0.131,
    corporateDepositaryLoansExmortgage: 0.085,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.003,
    CorporateOther: 0.02
  },
  "1967-06-01": {
    householdsCorporateOrSelf: 1.333,
    households: 0.444,
    corporateOrSelf: 0.419,
    government: 0.337,
    municipalities: 0.134,
    corporate: 0.29,
    selfemployed: 0.129,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.095,
    financialLoans: 0.014,
    corporateBonds: 0.135,
    corporateDepositaryLoansExmortgage: 0.087,
    corporateMortgages: 0.046,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.019
  },
  "1967-09-01": {
    householdsCorporateOrSelf: 1.334,
    households: 0.442,
    corporateOrSelf: 0.421,
    government: 0.338,
    municipalities: 0.134,
    corporate: 0.291,
    selfemployed: 0.13,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.096,
    financialLoans: 0.014,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.003,
    CorporateOther: 0.019
  },
  "1967-12-01": {
    householdsCorporateOrSelf: 1.351,
    households: 0.456,
    corporateOrSelf: 0.421,
    government: 0.341,
    municipalities: 0.133,
    corporate: 0.29,
    selfemployed: 0.131,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.097,
    financialLoans: 0.014,
    corporateBonds: 0.139,
    corporateDepositaryLoansExmortgage: 0.087,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.003,
    CorporateOther: 0.019
  },
  "1968-03-01": {
    householdsCorporateOrSelf: 1.325,
    households: 0.437,
    corporateOrSelf: 0.419,
    government: 0.338,
    municipalities: 0.131,
    corporate: 0.29,
    selfemployed: 0.129,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.095,
    financialLoans: 0.014,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.085,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.003,
    CorporateOther: 0.019
  },
  "1968-06-01": {
    householdsCorporateOrSelf: 1.314,
    households: 0.437,
    corporateOrSelf: 0.418,
    government: 0.33,
    municipalities: 0.13,
    corporate: 0.289,
    selfemployed: 0.129,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.095,
    financialLoans: 0.014,
    corporateBonds: 0.137,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.003,
    CorporateOther: 0.019
  },
  "1968-09-01": {
    householdsCorporateOrSelf: 1.318,
    households: 0.437,
    corporateOrSelf: 0.422,
    government: 0.33,
    municipalities: 0.129,
    corporate: 0.292,
    selfemployed: 0.13,
    selfDepositaryLoans: 0.019,
    financialMortages: 0.096,
    financialLoans: 0.014,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.087,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.019
  },
  "1968-12-01": {
    householdsCorporateOrSelf: 1.322,
    households: 0.441,
    corporateOrSelf: 0.426,
    government: 0.325,
    municipalities: 0.13,
    corporate: 0.296,
    selfemployed: 0.131,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.097,
    financialLoans: 0.014,
    corporateBonds: 0.139,
    corporateDepositaryLoansExmortgage: 0.089,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.019
  },
  "1969-03-01": {
    householdsCorporateOrSelf: 1.307,
    households: 0.432,
    corporateOrSelf: 0.426,
    government: 0.317,
    municipalities: 0.131,
    corporate: 0.296,
    selfemployed: 0.13,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.096,
    financialLoans: 0.014,
    corporateBonds: 0.139,
    corporateDepositaryLoansExmortgage: 0.09,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.019
  },
  "1969-06-01": {
    householdsCorporateOrSelf: 1.305,
    households: 0.434,
    corporateOrSelf: 0.434,
    government: 0.305,
    municipalities: 0.132,
    corporate: 0.301,
    selfemployed: 0.133,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.098,
    financialLoans: 0.014,
    corporateBonds: 0.14,
    corporateDepositaryLoansExmortgage: 0.091,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.022
  },
  "1969-09-01": {
    householdsCorporateOrSelf: 1.301,
    households: 0.431,
    corporateOrSelf: 0.437,
    government: 0.301,
    municipalities: 0.132,
    corporate: 0.303,
    selfemployed: 0.134,
    selfDepositaryLoans: 0.021,
    financialMortages: 0.099,
    financialLoans: 0.014,
    corporateBonds: 0.14,
    corporateDepositaryLoansExmortgage: 0.091,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.025
  },
  "1969-12-01": {
    householdsCorporateOrSelf: 1.315,
    households: 0.437,
    corporateOrSelf: 0.444,
    government: 0.301,
    municipalities: 0.133,
    corporate: 0.307,
    selfemployed: 0.137,
    selfDepositaryLoans: 0.021,
    financialMortages: 0.101,
    financialLoans: 0.015,
    corporateBonds: 0.142,
    corporateDepositaryLoansExmortgage: 0.092,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.027
  },
  "1970-03-01": {
    householdsCorporateOrSelf: 1.313,
    households: 0.429,
    corporateOrSelf: 0.451,
    government: 0.299,
    municipalities: 0.134,
    corporate: 0.322,
    selfemployed: 0.128,
    selfDepositaryLoans: 0.021,
    financialMortages: 0.093,
    financialLoans: 0.015,
    corporateBonds: 0.143,
    corporateDepositaryLoansExmortgage: 0.091,
    corporateMortgages: 0.054,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.029
  },
  "1970-06-01": {
    householdsCorporateOrSelf: 1.315,
    households: 0.425,
    corporateOrSelf: 0.458,
    government: 0.297,
    municipalities: 0.135,
    corporate: 0.327,
    selfemployed: 0.13,
    selfDepositaryLoans: 0.021,
    financialMortages: 0.095,
    financialLoans: 0.015,
    corporateBonds: 0.146,
    corporateDepositaryLoansExmortgage: 0.091,
    corporateMortgages: 0.054,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.031
  },
  "1970-09-01": {
    householdsCorporateOrSelf: 1.317,
    households: 0.423,
    corporateOrSelf: 0.461,
    government: 0.298,
    municipalities: 0.134,
    corporate: 0.329,
    selfemployed: 0.133,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.097,
    financialLoans: 0.015,
    corporateBonds: 0.148,
    corporateDepositaryLoansExmortgage: 0.093,
    corporateMortgages: 0.054,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.028
  },
  "1970-12-01": {
    householdsCorporateOrSelf: 1.338,
    households: 0.429,
    corporateOrSelf: 0.47,
    government: 0.3,
    municipalities: 0.138,
    corporate: 0.334,
    selfemployed: 0.136,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.101,
    financialLoans: 0.015,
    corporateBonds: 0.153,
    corporateDepositaryLoansExmortgage: 0.093,
    corporateMortgages: 0.055,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.026
  },
  "1971-03-01": {
    householdsCorporateOrSelf: 1.306,
    households: 0.419,
    corporateOrSelf: 0.461,
    government: 0.29,
    municipalities: 0.136,
    corporate: 0.326,
    selfemployed: 0.134,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.099,
    financialLoans: 0.015,
    corporateBonds: 0.152,
    corporateDepositaryLoansExmortgage: 0.09,
    corporateMortgages: 0.054,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.025
  },
  "1971-06-01": {
    householdsCorporateOrSelf: 1.316,
    households: 0.42,
    corporateOrSelf: 0.465,
    government: 0.293,
    municipalities: 0.137,
    corporate: 0.327,
    selfemployed: 0.138,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.103,
    financialLoans: 0.015,
    corporateBonds: 0.154,
    corporateDepositaryLoansExmortgage: 0.089,
    corporateMortgages: 0.054,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.025
  },
  "1971-09-01": {
    householdsCorporateOrSelf: 1.323,
    households: 0.421,
    corporateOrSelf: 0.469,
    government: 0.295,
    municipalities: 0.138,
    corporate: 0.328,
    selfemployed: 0.14,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.105,
    financialLoans: 0.015,
    corporateBonds: 0.154,
    corporateDepositaryLoansExmortgage: 0.09,
    corporateMortgages: 0.053,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.025
  },
  "1971-12-01": {
    householdsCorporateOrSelf: 1.341,
    households: 0.43,
    corporateOrSelf: 0.473,
    government: 0.298,
    municipalities: 0.14,
    corporate: 0.33,
    selfemployed: 0.144,
    selfDepositaryLoans: 0.021,
    financialMortages: 0.108,
    financialLoans: 0.015,
    corporateBonds: 0.156,
    corporateDepositaryLoansExmortgage: 0.089,
    corporateMortgages: 0.055,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.025
  },
  "1972-03-01": {
    householdsCorporateOrSelf: 1.326,
    households: 0.426,
    corporateOrSelf: 0.471,
    government: 0.29,
    municipalities: 0.139,
    corporate: 0.328,
    selfemployed: 0.143,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.108,
    financialLoans: 0.015,
    corporateBonds: 0.153,
    corporateDepositaryLoansExmortgage: 0.089,
    corporateMortgages: 0.055,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.025
  },
  "1972-06-01": {
    householdsCorporateOrSelf: 1.318,
    households: 0.426,
    corporateOrSelf: 0.471,
    government: 0.283,
    municipalities: 0.138,
    corporate: 0.325,
    selfemployed: 0.146,
    selfDepositaryLoans: 0.021,
    financialMortages: 0.111,
    financialLoans: 0.015,
    corporateBonds: 0.152,
    corporateDepositaryLoansExmortgage: 0.087,
    corporateMortgages: 0.055,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.024
  },
  "1972-09-01": {
    householdsCorporateOrSelf: 1.323,
    households: 0.429,
    corporateOrSelf: 0.475,
    government: 0.282,
    municipalities: 0.137,
    corporate: 0.326,
    selfemployed: 0.149,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.115,
    financialLoans: 0.014,
    corporateBonds: 0.151,
    corporateDepositaryLoansExmortgage: 0.089,
    corporateMortgages: 0.055,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.025
  },
  "1972-12-01": {
    householdsCorporateOrSelf: 1.324,
    households: 0.43,
    corporateOrSelf: 0.477,
    government: 0.28,
    municipalities: 0.136,
    corporate: 0.325,
    selfemployed: 0.152,
    selfDepositaryLoans: 0.02,
    financialMortages: 0.118,
    financialLoans: 0.014,
    corporateBonds: 0.149,
    corporateDepositaryLoansExmortgage: 0.09,
    corporateMortgages: 0.056,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.025
  },
  "1973-03-01": {
    householdsCorporateOrSelf: 1.316,
    households: 0.429,
    corporateOrSelf: 0.479,
    government: 0.275,
    municipalities: 0.134,
    corporate: 0.324,
    selfemployed: 0.155,
    selfDepositaryLoans: 0.022,
    financialMortages: 0.117,
    financialLoans: 0.015,
    corporateBonds: 0.145,
    corporateDepositaryLoansExmortgage: 0.094,
    corporateMortgages: 0.055,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.026
  },
  "1973-06-01": {
    householdsCorporateOrSelf: 1.313,
    households: 0.428,
    corporateOrSelf: 0.485,
    government: 0.269,
    municipalities: 0.132,
    corporate: 0.326,
    selfemployed: 0.158,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.118,
    financialLoans: 0.015,
    corporateBonds: 0.143,
    corporateDepositaryLoansExmortgage: 0.093,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.027
  },
  "1973-09-01": {
    householdsCorporateOrSelf: 1.326,
    households: 0.433,
    corporateOrSelf: 0.495,
    government: 0.264,
    municipalities: 0.133,
    corporate: 0.336,
    selfemployed: 0.16,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.116,
    financialLoans: 0.015,
    corporateBonds: 0.143,
    corporateDepositaryLoansExmortgage: 0.094,
    corporateMortgages: 0.065,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.029
  },
  "1973-12-01": {
    householdsCorporateOrSelf: 1.315,
    households: 0.431,
    corporateOrSelf: 0.493,
    government: 0.259,
    municipalities: 0.132,
    corporate: 0.337,
    selfemployed: 0.156,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.11,
    financialLoans: 0.015,
    corporateBonds: 0.14,
    corporateDepositaryLoansExmortgage: 0.091,
    corporateMortgages: 0.071,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.029
  },
  "1974-03-01": {
    householdsCorporateOrSelf: 1.329,
    households: 0.435,
    corporateOrSelf: 0.504,
    government: 0.256,
    municipalities: 0.134,
    corporate: 0.343,
    selfemployed: 0.16,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.113,
    financialLoans: 0.016,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.094,
    corporateMortgages: 0.072,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.031
  },
  "1974-06-01": {
    householdsCorporateOrSelf: 1.328,
    households: 0.434,
    corporateOrSelf: 0.509,
    government: 0.252,
    municipalities: 0.132,
    corporate: 0.346,
    selfemployed: 0.163,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.115,
    financialLoans: 0.016,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.097,
    corporateMortgages: 0.07,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.031
  },
  "1974-09-01": {
    householdsCorporateOrSelf: 1.332,
    households: 0.435,
    corporateOrSelf: 0.514,
    government: 0.25,
    municipalities: 0.132,
    corporate: 0.347,
    selfemployed: 0.167,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.118,
    financialLoans: 0.016,
    corporateBonds: 0.141,
    corporateDepositaryLoansExmortgage: 0.099,
    corporateMortgages: 0.067,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.033
  },
  "1974-12-01": {
    householdsCorporateOrSelf: 1.323,
    households: 0.432,
    corporateOrSelf: 0.513,
    government: 0.248,
    municipalities: 0.13,
    corporate: 0.346,
    selfemployed: 0.167,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.118,
    financialLoans: 0.016,
    corporateBonds: 0.142,
    corporateDepositaryLoansExmortgage: 0.098,
    corporateMortgages: 0.065,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.034
  },
  "1975-03-01": {
    householdsCorporateOrSelf: 1.334,
    households: 0.433,
    corporateOrSelf: 0.515,
    government: 0.255,
    municipalities: 0.13,
    corporate: 0.346,
    selfemployed: 0.169,
    selfDepositaryLoans: 0.034,
    financialMortages: 0.119,
    financialLoans: 0.016,
    corporateBonds: 0.146,
    corporateDepositaryLoansExmortgage: 0.093,
    corporateMortgages: 0.064,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.034
  },
  "1975-06-01": {
    householdsCorporateOrSelf: 1.334,
    households: 0.429,
    corporateOrSelf: 0.509,
    government: 0.267,
    municipalities: 0.129,
    corporate: 0.34,
    selfemployed: 0.169,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.119,
    financialLoans: 0.017,
    corporateBonds: 0.148,
    corporateDepositaryLoansExmortgage: 0.088,
    corporateMortgages: 0.064,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.034
  },
  "1975-09-01": {
    householdsCorporateOrSelf: 1.318,
    households: 0.423,
    corporateOrSelf: 0.498,
    government: 0.272,
    municipalities: 0.125,
    corporate: 0.332,
    selfemployed: 0.166,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.116,
    financialLoans: 0.017,
    corporateBonds: 0.145,
    corporateDepositaryLoansExmortgage: 0.084,
    corporateMortgages: 0.062,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.034
  },
  "1975-12-01": {
    householdsCorporateOrSelf: 1.313,
    households: 0.424,
    corporateOrSelf: 0.488,
    government: 0.276,
    municipalities: 0.125,
    corporate: 0.325,
    selfemployed: 0.163,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.017,
    corporateBonds: 0.144,
    corporateDepositaryLoansExmortgage: 0.081,
    corporateMortgages: 0.061,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.034
  },
  "1976-03-01": {
    householdsCorporateOrSelf: 1.303,
    households: 0.422,
    corporateOrSelf: 0.48,
    government: 0.278,
    municipalities: 0.124,
    corporate: 0.317,
    selfemployed: 0.163,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.017,
    corporateBonds: 0.143,
    corporateDepositaryLoansExmortgage: 0.075,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.034
  },
  "1976-06-01": {
    householdsCorporateOrSelf: 1.317,
    households: 0.426,
    corporateOrSelf: 0.481,
    government: 0.285,
    municipalities: 0.125,
    corporate: 0.318,
    selfemployed: 0.163,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.017,
    corporateBonds: 0.143,
    corporateDepositaryLoansExmortgage: 0.074,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.035
  },
  "1976-09-01": {
    householdsCorporateOrSelf: 1.326,
    households: 0.428,
    corporateOrSelf: 0.483,
    government: 0.29,
    municipalities: 0.125,
    corporate: 0.318,
    selfemployed: 0.164,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.018,
    corporateBonds: 0.144,
    corporateDepositaryLoansExmortgage: 0.073,
    corporateMortgages: 0.06,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.036
  },
  "1976-12-01": {
    householdsCorporateOrSelf: 1.326,
    households: 0.432,
    corporateOrSelf: 0.482,
    government: 0.289,
    municipalities: 0.123,
    corporate: 0.318,
    selfemployed: 0.164,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.018,
    corporateBonds: 0.143,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.038
  },
  "1977-03-01": {
    householdsCorporateOrSelf: 1.327,
    households: 0.435,
    corporateOrSelf: 0.483,
    government: 0.288,
    municipalities: 0.121,
    corporate: 0.318,
    selfemployed: 0.165,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.019,
    corporateBonds: 0.142,
    corporateDepositaryLoansExmortgage: 0.073,
    corporateMortgages: 0.058,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.04
  },
  "1977-06-01": {
    householdsCorporateOrSelf: 1.32,
    households: 0.437,
    corporateOrSelf: 0.481,
    government: 0.282,
    municipalities: 0.12,
    corporate: 0.315,
    selfemployed: 0.165,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.019,
    corporateBonds: 0.139,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.058,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.041
  },
  "1977-09-01": {
    householdsCorporateOrSelf: 1.324,
    households: 0.44,
    corporateOrSelf: 0.479,
    government: 0.287,
    municipalities: 0.119,
    corporate: 0.314,
    selfemployed: 0.165,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.114,
    financialLoans: 0.019,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.071,
    corporateMortgages: 0.058,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.042
  },
  "1977-12-01": {
    householdsCorporateOrSelf: 1.338,
    households: 0.447,
    corporateOrSelf: 0.485,
    government: 0.288,
    municipalities: 0.118,
    corporate: 0.319,
    selfemployed: 0.166,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.115,
    financialLoans: 0.019,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.071,
    corporateMortgages: 0.06,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.044
  },
  "1978-03-01": {
    householdsCorporateOrSelf: 1.356,
    households: 0.455,
    corporateOrSelf: 0.491,
    government: 0.289,
    municipalities: 0.12,
    corporate: 0.323,
    selfemployed: 0.168,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.115,
    financialLoans: 0.02,
    corporateBonds: 0.138,
    corporateDepositaryLoansExmortgage: 0.075,
    corporateMortgages: 0.06,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.044
  },
  "1978-06-01": {
    householdsCorporateOrSelf: 1.325,
    households: 0.449,
    corporateOrSelf: 0.479,
    government: 0.279,
    municipalities: 0.118,
    corporate: 0.314,
    selfemployed: 0.166,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.113,
    financialLoans: 0.02,
    corporateBonds: 0.133,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.044
  },
  "1978-09-01": {
    householdsCorporateOrSelf: 1.334,
    households: 0.455,
    corporateOrSelf: 0.479,
    government: 0.281,
    municipalities: 0.119,
    corporate: 0.311,
    selfemployed: 0.168,
    selfDepositaryLoans: 0.034,
    financialMortages: 0.113,
    financialLoans: 0.021,
    corporateBonds: 0.132,
    corporateDepositaryLoansExmortgage: 0.071,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.045
  },
  "1978-12-01": {
    householdsCorporateOrSelf: 1.328,
    households: 0.455,
    corporateOrSelf: 0.478,
    government: 0.275,
    municipalities: 0.119,
    corporate: 0.309,
    selfemployed: 0.169,
    selfDepositaryLoans: 0.035,
    financialMortages: 0.113,
    financialLoans: 0.021,
    corporateBonds: 0.129,
    corporateDepositaryLoansExmortgage: 0.07,
    corporateMortgages: 0.057,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.046
  },
  "1979-03-01": {
    householdsCorporateOrSelf: 1.338,
    households: 0.463,
    corporateOrSelf: 0.483,
    government: 0.271,
    municipalities: 0.12,
    corporate: 0.313,
    selfemployed: 0.171,
    selfDepositaryLoans: 0.036,
    financialMortages: 0.114,
    financialLoans: 0.021,
    corporateBonds: 0.129,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.057,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.048
  },
  "1979-06-01": {
    householdsCorporateOrSelf: 1.343,
    households: 0.467,
    corporateOrSelf: 0.489,
    government: 0.268,
    municipalities: 0.12,
    corporate: 0.313,
    selfemployed: 0.176,
    selfDepositaryLoans: 0.036,
    financialMortages: 0.117,
    financialLoans: 0.023,
    corporateBonds: 0.127,
    corporateDepositaryLoansExmortgage: 0.073,
    corporateMortgages: 0.056,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.049
  },
  "1979-09-01": {
    householdsCorporateOrSelf: 1.348,
    households: 0.471,
    corporateOrSelf: 0.491,
    government: 0.268,
    municipalities: 0.119,
    corporate: 0.313,
    selfemployed: 0.178,
    selfDepositaryLoans: 0.037,
    financialMortages: 0.119,
    financialLoans: 0.023,
    corporateBonds: 0.125,
    corporateDepositaryLoansExmortgage: 0.074,
    corporateMortgages: 0.055,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.05
  },
  "1979-12-01": {
    householdsCorporateOrSelf: 1.354,
    households: 0.476,
    corporateOrSelf: 0.493,
    government: 0.266,
    municipalities: 0.118,
    corporate: 0.313,
    selfemployed: 0.181,
    selfDepositaryLoans: 0.037,
    financialMortages: 0.12,
    financialLoans: 0.024,
    corporateBonds: 0.124,
    corporateDepositaryLoansExmortgage: 0.075,
    corporateMortgages: 0.053,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.052
  },
  "1980-03-01": {
    householdsCorporateOrSelf: 1.358,
    households: 0.48,
    corporateOrSelf: 0.496,
    government: 0.265,
    municipalities: 0.117,
    corporate: 0.313,
    selfemployed: 0.183,
    selfDepositaryLoans: 0.037,
    financialMortages: 0.122,
    financialLoans: 0.024,
    corporateBonds: 0.123,
    corporateDepositaryLoansExmortgage: 0.076,
    corporateMortgages: 0.051,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.053
  },
  "1980-06-01": {
    householdsCorporateOrSelf: 1.379,
    households: 0.483,
    corporateOrSelf: 0.502,
    government: 0.274,
    municipalities: 0.119,
    corporate: 0.315,
    selfemployed: 0.187,
    selfDepositaryLoans: 0.036,
    financialMortages: 0.126,
    financialLoans: 0.025,
    corporateBonds: 0.127,
    corporateDepositaryLoansExmortgage: 0.074,
    corporateMortgages: 0.049,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.054
  },
  "1980-09-01": {
    householdsCorporateOrSelf: 1.38,
    households: 0.484,
    corporateOrSelf: 0.501,
    government: 0.277,
    municipalities: 0.119,
    corporate: 0.313,
    selfemployed: 0.189,
    selfDepositaryLoans: 0.036,
    financialMortages: 0.128,
    financialLoans: 0.025,
    corporateBonds: 0.127,
    corporateDepositaryLoansExmortgage: 0.075,
    corporateMortgages: 0.047,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.054
  },
  "1980-12-01": {
    householdsCorporateOrSelf: 1.357,
    households: 0.476,
    corporateOrSelf: 0.494,
    government: 0.272,
    municipalities: 0.115,
    corporate: 0.308,
    selfemployed: 0.186,
    selfDepositaryLoans: 0.035,
    financialMortages: 0.126,
    financialLoans: 0.025,
    corporateBonds: 0.122,
    corporateDepositaryLoansExmortgage: 0.078,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.054
  },
  "1981-03-01": {
    householdsCorporateOrSelf: 1.327,
    households: 0.462,
    corporateOrSelf: 0.483,
    government: 0.27,
    municipalities: 0.112,
    corporate: 0.299,
    selfemployed: 0.184,
    selfDepositaryLoans: 0.034,
    financialMortages: 0.124,
    financialLoans: 0.025,
    corporateBonds: 0.119,
    corporateDepositaryLoansExmortgage: 0.074,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.054
  },
  "1981-06-01": {
    householdsCorporateOrSelf: 1.346,
    households: 0.466,
    corporateOrSelf: 0.495,
    government: 0.272,
    municipalities: 0.113,
    corporate: 0.309,
    selfemployed: 0.186,
    selfDepositaryLoans: 0.035,
    financialMortages: 0.126,
    financialLoans: 0.026,
    corporateBonds: 0.12,
    corporateDepositaryLoansExmortgage: 0.078,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.057
  },
  "1981-09-01": {
    householdsCorporateOrSelf: 1.336,
    households: 0.46,
    corporateOrSelf: 0.496,
    government: 0.269,
    municipalities: 0.112,
    corporate: 0.311,
    selfemployed: 0.185,
    selfDepositaryLoans: 0.035,
    financialMortages: 0.124,
    financialLoans: 0.026,
    corporateBonds: 0.118,
    corporateDepositaryLoansExmortgage: 0.079,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.012,
    CorporateOther: 0.058
  },
  "1981-12-01": {
    householdsCorporateOrSelf: 1.361,
    households: 0.465,
    corporateOrSelf: 0.506,
    government: 0.277,
    municipalities: 0.113,
    corporate: 0.317,
    selfemployed: 0.189,
    selfDepositaryLoans: 0.035,
    financialMortages: 0.128,
    financialLoans: 0.026,
    corporateBonds: 0.119,
    corporateDepositaryLoansExmortgage: 0.08,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.061
  },
  "1982-03-01": {
    householdsCorporateOrSelf: 1.396,
    households: 0.472,
    corporateOrSelf: 0.519,
    government: 0.288,
    municipalities: 0.117,
    corporate: 0.326,
    selfemployed: 0.193,
    selfDepositaryLoans: 0.035,
    financialMortages: 0.132,
    financialLoans: 0.026,
    corporateBonds: 0.122,
    corporateDepositaryLoansExmortgage: 0.085,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.014,
    CorporateOther: 0.062
  },
  "1982-06-01": {
    householdsCorporateOrSelf: 1.402,
    households: 0.47,
    corporateOrSelf: 0.524,
    government: 0.29,
    municipalities: 0.118,
    corporate: 0.33,
    selfemployed: 0.195,
    selfDepositaryLoans: 0.034,
    financialMortages: 0.134,
    financialLoans: 0.026,
    corporateBonds: 0.121,
    corporateDepositaryLoansExmortgage: 0.091,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.014,
    CorporateOther: 0.063
  },
  "1982-09-01": {
    householdsCorporateOrSelf: 1.421,
    households: 0.466,
    corporateOrSelf: 0.532,
    government: 0.304,
    municipalities: 0.12,
    corporate: 0.334,
    selfemployed: 0.198,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.138,
    financialLoans: 0.026,
    corporateBonds: 0.122,
    corporateDepositaryLoansExmortgage: 0.095,
    corporateMortgages: 0.038,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.066
  },
  "1982-12-01": {
    householdsCorporateOrSelf: 1.44,
    households: 0.47,
    corporateOrSelf: 0.531,
    government: 0.318,
    municipalities: 0.122,
    corporate: 0.332,
    selfemployed: 0.199,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.141,
    financialLoans: 0.026,
    corporateBonds: 0.124,
    corporateDepositaryLoansExmortgage: 0.095,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.065
  },
  "1983-03-01": {
    householdsCorporateOrSelf: 1.45,
    households: 0.463,
    corporateOrSelf: 0.535,
    government: 0.33,
    municipalities: 0.122,
    corporate: 0.334,
    selfemployed: 0.201,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.143,
    financialLoans: 0.026,
    corporateBonds: 0.123,
    corporateDepositaryLoansExmortgage: 0.095,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.064
  },
  "1983-06-01": {
    householdsCorporateOrSelf: 1.452,
    households: 0.462,
    corporateOrSelf: 0.53,
    government: 0.337,
    municipalities: 0.123,
    corporate: 0.331,
    selfemployed: 0.199,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.143,
    financialLoans: 0.025,
    corporateBonds: 0.122,
    corporateDepositaryLoansExmortgage: 0.092,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.066
  },
  "1983-09-01": {
    householdsCorporateOrSelf: 1.452,
    households: 0.461,
    corporateOrSelf: 0.527,
    government: 0.341,
    municipalities: 0.122,
    corporate: 0.33,
    selfemployed: 0.197,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.143,
    financialLoans: 0.025,
    corporateBonds: 0.12,
    corporateDepositaryLoansExmortgage: 0.092,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.067
  },
  "1983-12-01": {
    householdsCorporateOrSelf: 1.449,
    households: 0.464,
    corporateOrSelf: 0.526,
    government: 0.338,
    municipalities: 0.122,
    corporate: 0.329,
    selfemployed: 0.197,
    selfDepositaryLoans: 0.029,
    financialMortages: 0.144,
    financialLoans: 0.024,
    corporateBonds: 0.118,
    corporateDepositaryLoansExmortgage: 0.093,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.067
  },
  "1984-03-01": {
    householdsCorporateOrSelf: 1.459,
    households: 0.464,
    corporateOrSelf: 0.53,
    government: 0.344,
    municipalities: 0.121,
    corporate: 0.331,
    selfemployed: 0.199,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.145,
    financialLoans: 0.024,
    corporateBonds: 0.116,
    corporateDepositaryLoansExmortgage: 0.095,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.068
  },
  "1984-06-01": {
    householdsCorporateOrSelf: 1.473,
    households: 0.466,
    corporateOrSelf: 0.538,
    government: 0.347,
    municipalities: 0.121,
    corporate: 0.337,
    selfemployed: 0.201,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.147,
    financialLoans: 0.024,
    corporateBonds: 0.116,
    corporateDepositaryLoansExmortgage: 0.098,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.012,
    CorporateOther: 0.07
  },
  "1984-09-01": {
    householdsCorporateOrSelf: 1.495,
    households: 0.469,
    corporateOrSelf: 0.547,
    government: 0.355,
    municipalities: 0.124,
    corporate: 0.343,
    selfemployed: 0.204,
    selfDepositaryLoans: 0.031,
    financialMortages: 0.15,
    financialLoans: 0.024,
    corporateBonds: 0.117,
    corporateDepositaryLoansExmortgage: 0.098,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.014,
    CorporateOther: 0.072
  },
  "1984-12-01": {
    householdsCorporateOrSelf: 1.521,
    households: 0.477,
    corporateOrSelf: 0.559,
    government: 0.361,
    municipalities: 0.124,
    corporate: 0.351,
    selfemployed: 0.208,
    selfDepositaryLoans: 0.031,
    financialMortages: 0.154,
    financialLoans: 0.023,
    corporateBonds: 0.119,
    corporateDepositaryLoansExmortgage: 0.099,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.014,
    CorporateOther: 0.077
  },
  "1985-03-01": {
    householdsCorporateOrSelf: 1.552,
    households: 0.491,
    corporateOrSelf: 0.562,
    government: 0.371,
    municipalities: 0.128,
    corporate: 0.352,
    selfemployed: 0.21,
    selfDepositaryLoans: 0.031,
    financialMortages: 0.156,
    financialLoans: 0.023,
    corporateBonds: 0.121,
    corporateDepositaryLoansExmortgage: 0.101,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.076
  },
  "1985-06-01": {
    householdsCorporateOrSelf: 1.579,
    households: 0.499,
    corporateOrSelf: 0.569,
    government: 0.379,
    municipalities: 0.132,
    corporate: 0.356,
    selfemployed: 0.213,
    selfDepositaryLoans: 0.031,
    financialMortages: 0.16,
    financialLoans: 0.022,
    corporateBonds: 0.125,
    corporateDepositaryLoansExmortgage: 0.1,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.077
  },
  "1985-09-01": {
    householdsCorporateOrSelf: 1.596,
    households: 0.507,
    corporateOrSelf: 0.57,
    government: 0.385,
    municipalities: 0.135,
    corporate: 0.358,
    selfemployed: 0.212,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.16,
    financialLoans: 0.022,
    corporateBonds: 0.126,
    corporateDepositaryLoansExmortgage: 0.101,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.078
  },
  "1985-12-01": {
    householdsCorporateOrSelf: 1.652,
    households: 0.524,
    corporateOrSelf: 0.579,
    government: 0.396,
    municipalities: 0.153,
    corporate: 0.369,
    selfemployed: 0.21,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.159,
    financialLoans: 0.021,
    corporateBonds: 0.13,
    corporateDepositaryLoansExmortgage: 0.102,
    corporateMortgages: 0.038,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.082
  },
  "1986-03-01": {
    householdsCorporateOrSelf: 1.667,
    households: 0.529,
    corporateOrSelf: 0.585,
    government: 0.402,
    municipalities: 0.151,
    corporate: 0.373,
    selfemployed: 0.211,
    selfDepositaryLoans: 0.029,
    financialMortages: 0.161,
    financialLoans: 0.021,
    corporateBonds: 0.136,
    corporateDepositaryLoansExmortgage: 0.103,
    corporateMortgages: 0.038,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.081
  },
  "1986-06-01": {
    householdsCorporateOrSelf: 1.704,
    households: 0.536,
    corporateOrSelf: 0.596,
    government: 0.415,
    municipalities: 0.157,
    corporate: 0.382,
    selfemployed: 0.214,
    selfDepositaryLoans: 0.029,
    financialMortages: 0.164,
    financialLoans: 0.021,
    corporateBonds: 0.144,
    corporateDepositaryLoansExmortgage: 0.104,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.014,
    CorporateOther: 0.081
  },
  "1986-09-01": {
    householdsCorporateOrSelf: 1.731,
    households: 0.546,
    corporateOrSelf: 0.604,
    government: 0.423,
    municipalities: 0.158,
    corporate: 0.39,
    selfemployed: 0.214,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.166,
    financialLoans: 0.02,
    corporateBonds: 0.148,
    corporateDepositaryLoansExmortgage: 0.104,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.083
  },
  "1986-12-01": {
    householdsCorporateOrSelf: 1.764,
    households: 0.557,
    corporateOrSelf: 0.615,
    government: 0.431,
    municipalities: 0.161,
    corporate: 0.4,
    selfemployed: 0.215,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.166,
    financialLoans: 0.02,
    corporateBonds: 0.151,
    corporateDepositaryLoansExmortgage: 0.112,
    corporateMortgages: 0.04,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.084
  },
  "1987-03-01": {
    householdsCorporateOrSelf: 1.775,
    households: 0.556,
    corporateOrSelf: 0.62,
    government: 0.433,
    municipalities: 0.166,
    corporate: 0.404,
    selfemployed: 0.216,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.168,
    financialLoans: 0.02,
    corporateBonds: 0.155,
    corporateDepositaryLoansExmortgage: 0.108,
    corporateMortgages: 0.046,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.083
  },
  "1987-06-01": {
    householdsCorporateOrSelf: 1.787,
    households: 0.563,
    corporateOrSelf: 0.62,
    government: 0.437,
    municipalities: 0.167,
    corporate: 0.405,
    selfemployed: 0.214,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.167,
    financialLoans: 0.02,
    corporateBonds: 0.156,
    corporateDepositaryLoansExmortgage: 0.106,
    corporateMortgages: 0.047,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.083
  },
  "1987-09-01": {
    householdsCorporateOrSelf: 1.796,
    households: 0.568,
    corporateOrSelf: 0.623,
    government: 0.437,
    municipalities: 0.168,
    corporate: 0.409,
    selfemployed: 0.214,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.166,
    financialLoans: 0.019,
    corporateBonds: 0.157,
    corporateDepositaryLoansExmortgage: 0.106,
    corporateMortgages: 0.049,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.084
  },
  "1987-12-01": {
    householdsCorporateOrSelf: 1.789,
    households: 0.563,
    corporateOrSelf: 0.622,
    government: 0.436,
    municipalities: 0.168,
    corporate: 0.41,
    selfemployed: 0.213,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.164,
    financialLoans: 0.02,
    corporateBonds: 0.157,
    corporateDepositaryLoansExmortgage: 0.105,
    corporateMortgages: 0.049,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.085
  },
  "1988-03-01": {
    householdsCorporateOrSelf: 1.806,
    households: 0.57,
    corporateOrSelf: 0.628,
    government: 0.44,
    municipalities: 0.168,
    corporate: 0.414,
    selfemployed: 0.214,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.166,
    financialLoans: 0.02,
    corporateBonds: 0.16,
    corporateDepositaryLoansExmortgage: 0.105,
    corporateMortgages: 0.049,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.087
  },
  "1988-06-01": {
    householdsCorporateOrSelf: 1.806,
    households: 0.572,
    corporateOrSelf: 0.629,
    government: 0.439,
    municipalities: 0.167,
    corporate: 0.415,
    selfemployed: 0.214,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.166,
    financialLoans: 0.02,
    corporateBonds: 0.162,
    corporateDepositaryLoansExmortgage: 0.105,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.014,
    CorporateOther: 0.086
  },
  "1988-09-01": {
    householdsCorporateOrSelf: 1.811,
    households: 0.575,
    corporateOrSelf: 0.63,
    government: 0.44,
    municipalities: 0.166,
    corporate: 0.416,
    selfemployed: 0.215,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.166,
    financialLoans: 0.02,
    corporateBonds: 0.164,
    corporateDepositaryLoansExmortgage: 0.104,
    corporateMortgages: 0.047,
    corporateCommecialPaper: 0.014,
    CorporateOther: 0.087
  },
  "1988-12-01": {
    householdsCorporateOrSelf: 1.811,
    households: 0.574,
    corporateOrSelf: 0.632,
    government: 0.439,
    municipalities: 0.165,
    corporate: 0.42,
    selfemployed: 0.213,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.166,
    financialLoans: 0.019,
    corporateBonds: 0.164,
    corporateDepositaryLoansExmortgage: 0.105,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.087
  },
  "1989-03-01": {
    householdsCorporateOrSelf: 1.808,
    households: 0.574,
    corporateOrSelf: 0.632,
    government: 0.439,
    municipalities: 0.163,
    corporate: 0.421,
    selfemployed: 0.211,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.164,
    financialLoans: 0.019,
    corporateBonds: 0.164,
    corporateDepositaryLoansExmortgage: 0.103,
    corporateMortgages: 0.049,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.088
  },
  "1989-06-01": {
    householdsCorporateOrSelf: 1.81,
    households: 0.577,
    corporateOrSelf: 0.633,
    government: 0.437,
    municipalities: 0.162,
    corporate: 0.424,
    selfemployed: 0.209,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.163,
    financialLoans: 0.019,
    corporateBonds: 0.164,
    corporateDepositaryLoansExmortgage: 0.103,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.018,
    CorporateOther: 0.089
  },
  "1989-09-01": {
    householdsCorporateOrSelf: 1.813,
    households: 0.582,
    corporateOrSelf: 0.631,
    government: 0.439,
    municipalities: 0.162,
    corporate: 0.422,
    selfemployed: 0.208,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.162,
    financialLoans: 0.018,
    corporateBonds: 0.165,
    corporateDepositaryLoansExmortgage: 0.103,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.088
  },
  "1989-12-01": {
    householdsCorporateOrSelf: 1.832,
    households: 0.589,
    corporateOrSelf: 0.636,
    government: 0.444,
    municipalities: 0.164,
    corporate: 0.427,
    selfemployed: 0.208,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.162,
    financialLoans: 0.018,
    corporateBonds: 0.167,
    corporateDepositaryLoansExmortgage: 0.105,
    corporateMortgages: 0.046,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.09
  },
  "1990-03-01": {
    householdsCorporateOrSelf: 1.833,
    households: 0.592,
    corporateOrSelf: 0.631,
    government: 0.446,
    municipalities: 0.163,
    corporate: 0.426,
    selfemployed: 0.205,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.159,
    financialLoans: 0.018,
    corporateBonds: 0.165,
    corporateDepositaryLoansExmortgage: 0.102,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.092
  },
  "1990-06-01": {
    householdsCorporateOrSelf: 1.834,
    households: 0.594,
    corporateOrSelf: 0.628,
    government: 0.45,
    municipalities: 0.163,
    corporate: 0.426,
    selfemployed: 0.202,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.156,
    financialLoans: 0.018,
    corporateBonds: 0.165,
    corporateDepositaryLoansExmortgage: 0.101,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.093
  },
  "1990-09-01": {
    householdsCorporateOrSelf: 1.843,
    households: 0.597,
    corporateOrSelf: 0.626,
    government: 0.458,
    municipalities: 0.162,
    corporate: 0.427,
    selfemployed: 0.2,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.154,
    financialLoans: 0.018,
    corporateBonds: 0.165,
    corporateDepositaryLoansExmortgage: 0.099,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.094
  },
  "1990-12-01": {
    householdsCorporateOrSelf: 1.873,
    households: 0.607,
    corporateOrSelf: 0.63,
    government: 0.471,
    municipalities: 0.164,
    corporate: 0.431,
    selfemployed: 0.2,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.154,
    financialLoans: 0.018,
    corporateBonds: 0.168,
    corporateDepositaryLoansExmortgage: 0.099,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.096
  },
  "1991-03-01": {
    householdsCorporateOrSelf: 1.881,
    households: 0.613,
    corporateOrSelf: 0.623,
    government: 0.479,
    municipalities: 0.166,
    corporate: 0.425,
    selfemployed: 0.198,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.153,
    financialLoans: 0.018,
    corporateBonds: 0.17,
    corporateDepositaryLoansExmortgage: 0.096,
    corporateMortgages: 0.049,
    corporateCommecialPaper: 0.018,
    CorporateOther: 0.092
  },
  "1991-06-01": {
    householdsCorporateOrSelf: 1.876,
    households: 0.613,
    corporateOrSelf: 0.614,
    government: 0.483,
    municipalities: 0.166,
    corporate: 0.419,
    selfemployed: 0.195,
    selfDepositaryLoans: 0.027,
    financialMortages: 0.15,
    financialLoans: 0.017,
    corporateBonds: 0.172,
    corporateDepositaryLoansExmortgage: 0.092,
    corporateMortgages: 0.048,
    corporateCommecialPaper: 0.018,
    CorporateOther: 0.089
  },
  "1991-09-01": {
    householdsCorporateOrSelf: 1.872,
    households: 0.61,
    corporateOrSelf: 0.601,
    government: 0.494,
    municipalities: 0.168,
    corporate: 0.411,
    selfemployed: 0.19,
    selfDepositaryLoans: 0.027,
    financialMortages: 0.146,
    financialLoans: 0.017,
    corporateBonds: 0.173,
    corporateDepositaryLoansExmortgage: 0.09,
    corporateMortgages: 0.046,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.087
  },
  "1991-12-01": {
    householdsCorporateOrSelf: 1.88,
    households: 0.615,
    corporateOrSelf: 0.59,
    government: 0.502,
    municipalities: 0.172,
    corporate: 0.403,
    selfemployed: 0.187,
    selfDepositaryLoans: 0.027,
    financialMortages: 0.143,
    financialLoans: 0.017,
    corporateBonds: 0.174,
    corporateDepositaryLoansExmortgage: 0.087,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.083
  },
  "1992-03-01": {
    householdsCorporateOrSelf: 1.873,
    households: 0.614,
    corporateOrSelf: 0.581,
    government: 0.509,
    municipalities: 0.169,
    corporate: 0.398,
    selfemployed: 0.183,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.14,
    financialLoans: 0.017,
    corporateBonds: 0.174,
    corporateDepositaryLoansExmortgage: 0.084,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.081
  },
  "1992-06-01": {
    householdsCorporateOrSelf: 1.862,
    households: 0.609,
    corporateOrSelf: 0.571,
    government: 0.514,
    municipalities: 0.167,
    corporate: 0.392,
    selfemployed: 0.179,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.137,
    financialLoans: 0.016,
    corporateBonds: 0.174,
    corporateDepositaryLoansExmortgage: 0.081,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.08
  },
  "1992-09-01": {
    householdsCorporateOrSelf: 1.859,
    households: 0.608,
    corporateOrSelf: 0.562,
    government: 0.521,
    municipalities: 0.167,
    corporate: 0.387,
    selfemployed: 0.175,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.133,
    financialLoans: 0.016,
    corporateBonds: 0.174,
    corporateDepositaryLoansExmortgage: 0.079,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.08
  },
  "1992-12-01": {
    householdsCorporateOrSelf: 1.845,
    households: 0.607,
    corporateOrSelf: 0.552,
    government: 0.523,
    municipalities: 0.164,
    corporate: 0.381,
    selfemployed: 0.171,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.13,
    financialLoans: 0.016,
    corporateBonds: 0.173,
    corporateDepositaryLoansExmortgage: 0.077,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.079
  },
  "1993-03-01": {
    householdsCorporateOrSelf: 1.852,
    households: 0.609,
    corporateOrSelf: 0.551,
    government: 0.528,
    municipalities: 0.165,
    corporate: 0.382,
    selfemployed: 0.169,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.128,
    financialLoans: 0.016,
    corporateBonds: 0.18,
    corporateDepositaryLoansExmortgage: 0.075,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.077
  },
  "1993-06-01": {
    householdsCorporateOrSelf: 1.861,
    households: 0.611,
    corporateOrSelf: 0.549,
    government: 0.535,
    municipalities: 0.166,
    corporate: 0.383,
    selfemployed: 0.166,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.126,
    financialLoans: 0.015,
    corporateBonds: 0.185,
    corporateDepositaryLoansExmortgage: 0.074,
    corporateMortgages: 0.032,
    corporateCommecialPaper: 0.017,
    CorporateOther: 0.075
  },
  "1993-09-01": {
    householdsCorporateOrSelf: 1.869,
    households: 0.614,
    corporateOrSelf: 0.549,
    government: 0.539,
    municipalities: 0.167,
    corporate: 0.385,
    selfemployed: 0.163,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.124,
    financialLoans: 0.015,
    corporateBonds: 0.189,
    corporateDepositaryLoansExmortgage: 0.073,
    corporateMortgages: 0.032,
    corporateCommecialPaper: 0.017,
    CorporateOther: 0.074
  },
  "1993-12-01": {
    householdsCorporateOrSelf: 1.861,
    households: 0.614,
    corporateOrSelf: 0.544,
    government: 0.54,
    municipalities: 0.164,
    corporate: 0.384,
    selfemployed: 0.16,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.121,
    financialLoans: 0.015,
    corporateBonds: 0.191,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.017,
    CorporateOther: 0.073
  },
  "1994-03-01": {
    householdsCorporateOrSelf: 1.856,
    households: 0.615,
    corporateOrSelf: 0.545,
    government: 0.535,
    municipalities: 0.161,
    corporate: 0.387,
    selfemployed: 0.158,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.118,
    financialLoans: 0.015,
    corporateBonds: 0.193,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.03,
    corporateCommecialPaper: 0.017,
    CorporateOther: 0.075
  },
  "1994-06-01": {
    householdsCorporateOrSelf: 1.847,
    households: 0.615,
    corporateOrSelf: 0.541,
    government: 0.535,
    municipalities: 0.156,
    corporate: 0.385,
    selfemployed: 0.156,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.116,
    financialLoans: 0.015,
    corporateBonds: 0.193,
    corporateDepositaryLoansExmortgage: 0.072,
    corporateMortgages: 0.03,
    corporateCommecialPaper: 0.017,
    CorporateOther: 0.074
  },
  "1994-09-01": {
    householdsCorporateOrSelf: 1.849,
    households: 0.617,
    corporateOrSelf: 0.542,
    government: 0.536,
    municipalities: 0.154,
    corporate: 0.387,
    selfemployed: 0.155,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.115,
    financialLoans: 0.014,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.073,
    corporateMortgages: 0.03,
    corporateCommecialPaper: 0.018,
    CorporateOther: 0.074
  },
  "1994-12-01": {
    householdsCorporateOrSelf: 1.843,
    households: 0.621,
    corporateOrSelf: 0.54,
    government: 0.534,
    municipalities: 0.149,
    corporate: 0.387,
    selfemployed: 0.153,
    selfDepositaryLoans: 0.025,
    financialMortages: 0.113,
    financialLoans: 0.015,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.074,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.074
  },
  "1995-03-01": {
    householdsCorporateOrSelf: 1.848,
    households: 0.625,
    corporateOrSelf: 0.545,
    government: 0.534,
    municipalities: 0.145,
    corporate: 0.392,
    selfemployed: 0.153,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.112,
    financialLoans: 0.015,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.078,
    corporateMortgages: 0.029,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.075
  },
  "1995-06-01": {
    householdsCorporateOrSelf: 1.866,
    households: 0.631,
    corporateOrSelf: 0.55,
    government: 0.543,
    municipalities: 0.142,
    corporate: 0.397,
    selfemployed: 0.153,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.112,
    financialLoans: 0.015,
    corporateBonds: 0.193,
    corporateDepositaryLoansExmortgage: 0.08,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.076
  },
  "1995-09-01": {
    householdsCorporateOrSelf: 1.862,
    households: 0.635,
    corporateOrSelf: 0.549,
    government: 0.54,
    municipalities: 0.138,
    corporate: 0.396,
    selfemployed: 0.153,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.112,
    financialLoans: 0.015,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.081,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.075
  },
  "1995-12-01": {
    householdsCorporateOrSelf: 1.856,
    households: 0.637,
    corporateOrSelf: 0.551,
    government: 0.534,
    municipalities: 0.135,
    corporate: 0.398,
    selfemployed: 0.152,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.112,
    financialLoans: 0.015,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.081,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.077
  },
  "1996-03-01": {
    householdsCorporateOrSelf: 1.862,
    households: 0.645,
    corporateOrSelf: 0.55,
    government: 0.536,
    municipalities: 0.132,
    corporate: 0.397,
    selfemployed: 0.153,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.112,
    financialLoans: 0.015,
    corporateBonds: 0.193,
    corporateDepositaryLoansExmortgage: 0.08,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.021,
    CorporateOther: 0.076
  },
  "1996-06-01": {
    householdsCorporateOrSelf: 1.852,
    households: 0.643,
    corporateOrSelf: 0.548,
    government: 0.533,
    municipalities: 0.129,
    corporate: 0.396,
    selfemployed: 0.152,
    selfDepositaryLoans: 0.026,
    financialMortages: 0.111,
    financialLoans: 0.015,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.08,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.021,
    CorporateOther: 0.077
  },
  "1996-09-01": {
    householdsCorporateOrSelf: 1.851,
    households: 0.644,
    corporateOrSelf: 0.549,
    government: 0.532,
    municipalities: 0.127,
    corporate: 0.396,
    selfemployed: 0.152,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.111,
    financialLoans: 0.014,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.081,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.077
  },
  "1996-12-01": {
    householdsCorporateOrSelf: 1.838,
    households: 0.643,
    corporateOrSelf: 0.545,
    government: 0.526,
    municipalities: 0.124,
    corporate: 0.393,
    selfemployed: 0.152,
    selfDepositaryLoans: 0.028,
    financialMortages: 0.11,
    financialLoans: 0.014,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.08,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.075
  },
  "1997-03-01": {
    householdsCorporateOrSelf: 1.839,
    households: 0.646,
    corporateOrSelf: 0.547,
    government: 0.523,
    municipalities: 0.123,
    corporate: 0.394,
    selfemployed: 0.153,
    selfDepositaryLoans: 0.029,
    financialMortages: 0.11,
    financialLoans: 0.014,
    corporateBonds: 0.192,
    corporateDepositaryLoansExmortgage: 0.082,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.074
  },
  "1997-06-01": {
    householdsCorporateOrSelf: 1.83,
    households: 0.644,
    corporateOrSelf: 0.548,
    government: 0.516,
    municipalities: 0.122,
    corporate: 0.395,
    selfemployed: 0.153,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.109,
    financialLoans: 0.014,
    corporateBonds: 0.193,
    corporateDepositaryLoansExmortgage: 0.082,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.074
  },
  "1997-09-01": {
    householdsCorporateOrSelf: 1.829,
    households: 0.644,
    corporateOrSelf: 0.553,
    government: 0.51,
    municipalities: 0.122,
    corporate: 0.399,
    selfemployed: 0.154,
    selfDepositaryLoans: 0.03,
    financialMortages: 0.109,
    financialLoans: 0.015,
    corporateBonds: 0.196,
    corporateDepositaryLoansExmortgage: 0.082,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.074
  },
  "1997-12-01": {
    householdsCorporateOrSelf: 1.829,
    households: 0.646,
    corporateOrSelf: 0.557,
    government: 0.503,
    municipalities: 0.123,
    corporate: 0.402,
    selfemployed: 0.155,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.11,
    financialLoans: 0.014,
    corporateBonds: 0.195,
    corporateDepositaryLoansExmortgage: 0.082,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.019,
    CorporateOther: 0.077
  },
  "1998-03-01": {
    householdsCorporateOrSelf: 1.838,
    households: 0.651,
    corporateOrSelf: 0.567,
    government: 0.497,
    municipalities: 0.123,
    corporate: 0.409,
    selfemployed: 0.159,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.113,
    financialLoans: 0.014,
    corporateBonds: 0.201,
    corporateDepositaryLoansExmortgage: 0.082,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.077
  },
  "1998-06-01": {
    householdsCorporateOrSelf: 1.854,
    households: 0.657,
    corporateOrSelf: 0.579,
    government: 0.494,
    municipalities: 0.124,
    corporate: 0.417,
    selfemployed: 0.162,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.115,
    financialLoans: 0.015,
    corporateBonds: 0.208,
    corporateDepositaryLoansExmortgage: 0.084,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.077
  },
  "1998-09-01": {
    householdsCorporateOrSelf: 1.846,
    households: 0.656,
    corporateOrSelf: 0.583,
    government: 0.483,
    municipalities: 0.123,
    corporate: 0.419,
    selfemployed: 0.164,
    selfDepositaryLoans: 0.032,
    financialMortages: 0.118,
    financialLoans: 0.014,
    corporateBonds: 0.207,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.026,
    corporateCommecialPaper: 0.022,
    CorporateOther: 0.077
  },
  "1998-12-01": {
    householdsCorporateOrSelf: 1.841,
    households: 0.658,
    corporateOrSelf: 0.587,
    government: 0.473,
    municipalities: 0.123,
    corporate: 0.421,
    selfemployed: 0.166,
    selfDepositaryLoans: 0.033,
    financialMortages: 0.119,
    financialLoans: 0.014,
    corporateBonds: 0.207,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.021,
    CorporateOther: 0.08
  },
  "1999-03-01": {
    householdsCorporateOrSelf: 1.85,
    households: 0.665,
    corporateOrSelf: 0.597,
    government: 0.465,
    municipalities: 0.123,
    corporate: 0.428,
    selfemployed: 0.169,
    selfDepositaryLoans: 0.034,
    financialMortages: 0.121,
    financialLoans: 0.015,
    corporateBonds: 0.21,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.022,
    CorporateOther: 0.082
  },
  "1999-06-01": {
    householdsCorporateOrSelf: 1.855,
    households: 0.672,
    corporateOrSelf: 0.601,
    government: 0.46,
    municipalities: 0.122,
    corporate: 0.43,
    selfemployed: 0.171,
    selfDepositaryLoans: 0.034,
    financialMortages: 0.123,
    financialLoans: 0.015,
    corporateBonds: 0.214,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.029,
    corporateCommecialPaper: 0.022,
    CorporateOther: 0.08
  },
  "1999-09-01": {
    householdsCorporateOrSelf: 1.859,
    households: 0.675,
    corporateOrSelf: 0.612,
    government: 0.45,
    municipalities: 0.122,
    corporate: 0.439,
    selfemployed: 0.174,
    selfDepositaryLoans: 0.035,
    financialMortages: 0.125,
    financialLoans: 0.015,
    corporateBonds: 0.217,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.033,
    corporateCommecialPaper: 0.023,
    CorporateOther: 0.079
  },
  "1999-12-01": {
    householdsCorporateOrSelf: 1.847,
    households: 0.676,
    corporateOrSelf: 0.612,
    government: 0.441,
    municipalities: 0.119,
    corporate: 0.436,
    selfemployed: 0.176,
    selfDepositaryLoans: 0.036,
    financialMortages: 0.126,
    financialLoans: 0.015,
    corporateBonds: 0.215,
    corporateDepositaryLoansExmortgage: 0.085,
    corporateMortgages: 0.033,
    corporateCommecialPaper: 0.023,
    CorporateOther: 0.078
  },
  "2000-03-01": {
    householdsCorporateOrSelf: 1.859,
    households: 0.687,
    corporateOrSelf: 0.622,
    government: 0.431,
    municipalities: 0.119,
    corporate: 0.443,
    selfemployed: 0.179,
    selfDepositaryLoans: 0.036,
    financialMortages: 0.128,
    financialLoans: 0.015,
    corporateBonds: 0.217,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.024,
    CorporateOther: 0.082
  },
  "2000-06-01": {
    householdsCorporateOrSelf: 1.833,
    households: 0.684,
    corporateOrSelf: 0.623,
    government: 0.411,
    municipalities: 0.115,
    corporate: 0.443,
    selfemployed: 0.181,
    selfDepositaryLoans: 0.038,
    financialMortages: 0.128,
    financialLoans: 0.015,
    corporateBonds: 0.215,
    corporateDepositaryLoansExmortgage: 0.087,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.026,
    CorporateOther: 0.081
  },
  "2000-09-01": {
    householdsCorporateOrSelf: 1.841,
    households: 0.695,
    corporateOrSelf: 0.629,
    government: 0.402,
    municipalities: 0.115,
    corporate: 0.446,
    selfemployed: 0.183,
    selfDepositaryLoans: 0.038,
    financialMortages: 0.13,
    financialLoans: 0.015,
    corporateBonds: 0.217,
    corporateDepositaryLoansExmortgage: 0.087,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.027,
    CorporateOther: 0.08
  },
  "2000-12-01": {
    householdsCorporateOrSelf: 1.837,
    households: 0.698,
    corporateOrSelf: 0.632,
    government: 0.392,
    municipalities: 0.115,
    corporate: 0.446,
    selfemployed: 0.186,
    selfDepositaryLoans: 0.039,
    financialMortages: 0.132,
    financialLoans: 0.015,
    corporateBonds: 0.217,
    corporateDepositaryLoansExmortgage: 0.086,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.027,
    CorporateOther: 0.082
  },
  "2001-03-01": {
    householdsCorporateOrSelf: 1.852,
    households: 0.707,
    corporateOrSelf: 0.634,
    government: 0.394,
    municipalities: 0.117,
    corporate: 0.445,
    selfemployed: 0.189,
    selfDepositaryLoans: 0.04,
    financialMortages: 0.134,
    financialLoans: 0.015,
    corporateBonds: 0.226,
    corporateDepositaryLoansExmortgage: 0.085,
    corporateMortgages: 0.032,
    corporateCommecialPaper: 0.02,
    CorporateOther: 0.083
  },
  "2001-06-01": {
    householdsCorporateOrSelf: 1.851,
    households: 0.715,
    corporateOrSelf: 0.638,
    government: 0.38,
    municipalities: 0.118,
    corporate: 0.448,
    selfemployed: 0.191,
    selfDepositaryLoans: 0.04,
    financialMortages: 0.136,
    financialLoans: 0.015,
    corporateBonds: 0.235,
    corporateDepositaryLoansExmortgage: 0.081,
    corporateMortgages: 0.032,
    corporateCommecialPaper: 0.017,
    CorporateOther: 0.083
  },
  "2001-09-01": {
    householdsCorporateOrSelf: 1.89,
    households: 0.738,
    corporateOrSelf: 0.645,
    government: 0.387,
    municipalities: 0.12,
    corporate: 0.451,
    selfemployed: 0.194,
    selfDepositaryLoans: 0.041,
    financialMortages: 0.139,
    financialLoans: 0.014,
    corporateBonds: 0.238,
    corporateDepositaryLoansExmortgage: 0.079,
    corporateMortgages: 0.035,
    corporateCommecialPaper: 0.016,
    CorporateOther: 0.084
  },
  "2001-12-01": {
    householdsCorporateOrSelf: 1.901,
    households: 0.743,
    corporateOrSelf: 0.647,
    government: 0.389,
    municipalities: 0.122,
    corporate: 0.45,
    selfemployed: 0.197,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.141,
    financialLoans: 0.014,
    corporateBonds: 0.24,
    corporateDepositaryLoansExmortgage: 0.075,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.017,
    CorporateOther: 0.081
  },
  "2002-03-01": {
    householdsCorporateOrSelf: 1.905,
    households: 0.752,
    corporateOrSelf: 0.644,
    government: 0.385,
    municipalities: 0.123,
    corporate: 0.446,
    selfemployed: 0.199,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.142,
    financialLoans: 0.014,
    corporateBonds: 0.242,
    corporateDepositaryLoansExmortgage: 0.073,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.015,
    CorporateOther: 0.08
  },
  "2002-06-01": {
    householdsCorporateOrSelf: 1.916,
    households: 0.759,
    corporateOrSelf: 0.641,
    government: 0.391,
    municipalities: 0.125,
    corporate: 0.44,
    selfemployed: 0.2,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.144,
    financialLoans: 0.014,
    corporateBonds: 0.242,
    corporateDepositaryLoansExmortgage: 0.068,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.013,
    CorporateOther: 0.08
  },
  "2002-09-01": {
    householdsCorporateOrSelf: 1.932,
    households: 0.77,
    corporateOrSelf: 0.638,
    government: 0.397,
    municipalities: 0.128,
    corporate: 0.436,
    selfemployed: 0.202,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.146,
    financialLoans: 0.014,
    corporateBonds: 0.239,
    corporateDepositaryLoansExmortgage: 0.065,
    corporateMortgages: 0.039,
    corporateCommecialPaper: 0.012,
    CorporateOther: 0.081
  },
  "2002-12-01": {
    householdsCorporateOrSelf: 1.954,
    households: 0.786,
    corporateOrSelf: 0.637,
    government: 0.4,
    municipalities: 0.131,
    corporate: 0.433,
    selfemployed: 0.204,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.147,
    financialLoans: 0.014,
    corporateBonds: 0.238,
    corporateDepositaryLoansExmortgage: 0.063,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.081
  },
  "2003-03-01": {
    householdsCorporateOrSelf: 1.963,
    households: 0.798,
    corporateOrSelf: 0.633,
    government: 0.4,
    municipalities: 0.132,
    corporate: 0.428,
    selfemployed: 0.204,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.147,
    financialLoans: 0.014,
    corporateBonds: 0.236,
    corporateDepositaryLoansExmortgage: 0.06,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.081
  },
  "2003-06-01": {
    householdsCorporateOrSelf: 1.991,
    households: 0.82,
    corporateOrSelf: 0.626,
    government: 0.41,
    municipalities: 0.134,
    corporate: 0.423,
    selfemployed: 0.203,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.147,
    financialLoans: 0.014,
    corporateBonds: 0.237,
    corporateDepositaryLoansExmortgage: 0.056,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.079
  },
  "2003-09-01": {
    householdsCorporateOrSelf: 1.985,
    households: 0.823,
    corporateOrSelf: 0.617,
    government: 0.411,
    municipalities: 0.133,
    corporate: 0.417,
    selfemployed: 0.2,
    selfDepositaryLoans: 0.041,
    financialMortages: 0.145,
    financialLoans: 0.014,
    corporateBonds: 0.234,
    corporateDepositaryLoansExmortgage: 0.054,
    corporateMortgages: 0.043,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.078
  },
  "2003-12-01": {
    householdsCorporateOrSelf: 1.983,
    households: 0.831,
    corporateOrSelf: 0.607,
    government: 0.412,
    municipalities: 0.133,
    corporate: 0.409,
    selfemployed: 0.198,
    selfDepositaryLoans: 0.041,
    financialMortages: 0.144,
    financialLoans: 0.014,
    corporateBonds: 0.231,
    corporateDepositaryLoansExmortgage: 0.052,
    corporateMortgages: 0.042,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.077
  },
  "2004-03-01": {
    householdsCorporateOrSelf: 2.061,
    households: 0.841,
    corporateOrSelf: 0.61,
    government: 0.412,
    municipalities: 0.197,
    corporate: 0.409,
    selfemployed: 0.202,
    selfDepositaryLoans: 0.042,
    financialMortages: 0.146,
    financialLoans: 0.014,
    corporateBonds: 0.229,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.079
  },
  "2004-06-01": {
    householdsCorporateOrSelf: 2.077,
    households: 0.853,
    corporateOrSelf: 0.606,
    government: 0.42,
    municipalities: 0.199,
    corporate: 0.403,
    selfemployed: 0.203,
    selfDepositaryLoans: 0.041,
    financialMortages: 0.149,
    financialLoans: 0.013,
    corporateBonds: 0.224,
    corporateDepositaryLoansExmortgage: 0.048,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.078
  },
  "2004-09-01": {
    householdsCorporateOrSelf: 2.086,
    households: 0.86,
    corporateOrSelf: 0.606,
    government: 0.42,
    municipalities: 0.2,
    corporate: 0.401,
    selfemployed: 0.205,
    selfDepositaryLoans: 0.041,
    financialMortages: 0.151,
    financialLoans: 0.013,
    corporateBonds: 0.22,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.047,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.079
  },
  "2004-12-01": {
    householdsCorporateOrSelf: 2.096,
    households: 0.873,
    corporateOrSelf: 0.605,
    government: 0.419,
    municipalities: 0.199,
    corporate: 0.398,
    selfemployed: 0.207,
    selfDepositaryLoans: 0.041,
    financialMortages: 0.153,
    financialLoans: 0.013,
    corporateBonds: 0.215,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.046,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.079
  },
  "2005-03-01": {
    householdsCorporateOrSelf: 2.099,
    households: 0.876,
    corporateOrSelf: 0.604,
    government: 0.419,
    municipalities: 0.199,
    corporate: 0.395,
    selfemployed: 0.209,
    selfDepositaryLoans: 0.043,
    financialMortages: 0.153,
    financialLoans: 0.012,
    corporateBonds: 0.211,
    corporateDepositaryLoansExmortgage: 0.048,
    corporateMortgages: 0.047,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.081
  },
  "2005-06-01": {
    householdsCorporateOrSelf: 2.121,
    households: 0.891,
    corporateOrSelf: 0.608,
    government: 0.422,
    municipalities: 0.2,
    corporate: 0.396,
    selfemployed: 0.213,
    selfDepositaryLoans: 0.046,
    financialMortages: 0.155,
    financialLoans: 0.012,
    corporateBonds: 0.207,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.05,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.082
  },
  "2005-09-01": {
    householdsCorporateOrSelf: 2.126,
    households: 0.9,
    corporateOrSelf: 0.608,
    government: 0.419,
    municipalities: 0.2,
    corporate: 0.392,
    selfemployed: 0.216,
    selfDepositaryLoans: 0.048,
    financialMortages: 0.156,
    financialLoans: 0.012,
    corporateBonds: 0.203,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.053,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.083
  },
  "2005-12-01": {
    householdsCorporateOrSelf: 2.142,
    households: 0.909,
    corporateOrSelf: 0.614,
    government: 0.419,
    municipalities: 0.199,
    corporate: 0.394,
    selfemployed: 0.22,
    selfDepositaryLoans: 0.05,
    financialMortages: 0.157,
    financialLoans: 0.012,
    corporateBonds: 0.2,
    corporateDepositaryLoansExmortgage: 0.044,
    corporateMortgages: 0.058,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.086
  },
  "2006-03-01": {
    householdsCorporateOrSelf: 2.151,
    households: 0.92,
    corporateOrSelf: 0.617,
    government: 0.418,
    municipalities: 0.195,
    corporate: 0.394,
    selfemployed: 0.223,
    selfDepositaryLoans: 0.051,
    financialMortages: 0.159,
    financialLoans: 0.012,
    corporateBonds: 0.197,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.088
  },
  "2006-06-01": {
    householdsCorporateOrSelf: 2.176,
    households: 0.935,
    corporateOrSelf: 0.625,
    government: 0.42,
    municipalities: 0.196,
    corporate: 0.397,
    selfemployed: 0.228,
    selfDepositaryLoans: 0.053,
    financialMortages: 0.162,
    financialLoans: 0.013,
    corporateBonds: 0.196,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.09
  },
  "2006-09-01": {
    householdsCorporateOrSelf: 2.192,
    households: 0.948,
    corporateOrSelf: 0.63,
    government: 0.416,
    municipalities: 0.197,
    corporate: 0.397,
    selfemployed: 0.234,
    selfDepositaryLoans: 0.055,
    financialMortages: 0.166,
    financialLoans: 0.013,
    corporateBonds: 0.195,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.06,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.09
  },
  "2006-12-01": {
    householdsCorporateOrSelf: 2.208,
    households: 0.956,
    corporateOrSelf: 0.641,
    government: 0.413,
    municipalities: 0.198,
    corporate: 0.403,
    selfemployed: 0.238,
    selfDepositaryLoans: 0.056,
    financialMortages: 0.169,
    financialLoans: 0.013,
    corporateBonds: 0.195,
    corporateDepositaryLoansExmortgage: 0.044,
    corporateMortgages: 0.062,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.094
  },
  "2007-03-01": {
    householdsCorporateOrSelf: 2.225,
    households: 0.958,
    corporateOrSelf: 0.651,
    government: 0.417,
    municipalities: 0.199,
    corporate: 0.407,
    selfemployed: 0.243,
    selfDepositaryLoans: 0.058,
    financialMortages: 0.173,
    financialLoans: 0.013,
    corporateBonds: 0.194,
    corporateDepositaryLoansExmortgage: 0.044,
    corporateMortgages: 0.062,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.099
  },
  "2007-06-01": {
    householdsCorporateOrSelf: 2.244,
    households: 0.967,
    corporateOrSelf: 0.663,
    government: 0.412,
    municipalities: 0.201,
    corporate: 0.415,
    selfemployed: 0.248,
    selfDepositaryLoans: 0.06,
    financialMortages: 0.176,
    financialLoans: 0.013,
    corporateBonds: 0.195,
    corporateDepositaryLoansExmortgage: 0.044,
    corporateMortgages: 0.062,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.104
  },
  "2007-09-01": {
    householdsCorporateOrSelf: 2.262,
    households: 0.971,
    corporateOrSelf: 0.678,
    government: 0.412,
    municipalities: 0.201,
    corporate: 0.425,
    selfemployed: 0.253,
    selfDepositaryLoans: 0.061,
    financialMortages: 0.179,
    financialLoans: 0.013,
    corporateBonds: 0.195,
    corporateDepositaryLoansExmortgage: 0.047,
    corporateMortgages: 0.064,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.11
  },
  "2007-12-01": {
    householdsCorporateOrSelf: 2.277,
    households: 0.975,
    corporateOrSelf: 0.689,
    government: 0.413,
    municipalities: 0.2,
    corporate: 0.43,
    selfemployed: 0.259,
    selfDepositaryLoans: 0.063,
    financialMortages: 0.182,
    financialLoans: 0.013,
    corporateBonds: 0.194,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.064,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.114
  },
  "2008-03-01": {
    householdsCorporateOrSelf: 2.316,
    households: 0.987,
    corporateOrSelf: 0.703,
    government: 0.425,
    municipalities: 0.202,
    corporate: 0.438,
    selfemployed: 0.265,
    selfDepositaryLoans: 0.065,
    financialMortages: 0.186,
    financialLoans: 0.014,
    corporateBonds: 0.196,
    corporateDepositaryLoansExmortgage: 0.052,
    corporateMortgages: 0.065,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.117
  },
  "2008-06-01": {
    householdsCorporateOrSelf: 2.308,
    households: 0.971,
    corporateOrSelf: 0.709,
    government: 0.426,
    municipalities: 0.201,
    corporate: 0.442,
    selfemployed: 0.268,
    selfDepositaryLoans: 0.066,
    financialMortages: 0.187,
    financialLoans: 0.014,
    corporateBonds: 0.199,
    corporateDepositaryLoansExmortgage: 0.052,
    corporateMortgages: 0.064,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.119
  },
  "2008-09-01": {
    householdsCorporateOrSelf: 2.353,
    households: 0.977,
    corporateOrSelf: 0.717,
    government: 0.458,
    municipalities: 0.201,
    corporate: 0.445,
    selfemployed: 0.272,
    selfDepositaryLoans: 0.068,
    financialMortages: 0.19,
    financialLoans: 0.014,
    corporateBonds: 0.199,
    corporateDepositaryLoansExmortgage: 0.054,
    corporateMortgages: 0.061,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.122
  },
  "2008-12-01": {
    householdsCorporateOrSelf: 2.416,
    households: 0.973,
    corporateOrSelf: 0.733,
    government: 0.505,
    municipalities: 0.205,
    corporate: 0.451,
    selfemployed: 0.282,
    selfDepositaryLoans: 0.072,
    financialMortages: 0.196,
    financialLoans: 0.014,
    corporateBonds: 0.204,
    corporateDepositaryLoansExmortgage: 0.053,
    corporateMortgages: 0.06,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.124
  },
  "2009-03-01": {
    householdsCorporateOrSelf: 2.466,
    households: 0.981,
    corporateOrSelf: 0.736,
    government: 0.54,
    municipalities: 0.21,
    corporate: 0.451,
    selfemployed: 0.285,
    selfDepositaryLoans: 0.072,
    financialMortages: 0.199,
    financialLoans: 0.014,
    corporateBonds: 0.213,
    corporateDepositaryLoansExmortgage: 0.05,
    corporateMortgages: 0.059,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.122
  },
  "2009-06-01": {
    householdsCorporateOrSelf: 2.502,
    households: 0.983,
    corporateOrSelf: 0.732,
    government: 0.574,
    municipalities: 0.213,
    corporate: 0.449,
    selfemployed: 0.284,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.2,
    financialLoans: 0.014,
    corporateBonds: 0.219,
    corporateDepositaryLoansExmortgage: 0.046,
    corporateMortgages: 0.058,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.121
  },
  "2009-09-01": {
    householdsCorporateOrSelf: 2.507,
    households: 0.975,
    corporateOrSelf: 0.72,
    government: 0.598,
    municipalities: 0.214,
    corporate: 0.44,
    selfemployed: 0.28,
    selfDepositaryLoans: 0.067,
    financialMortages: 0.199,
    financialLoans: 0.014,
    corporateBonds: 0.219,
    corporateDepositaryLoansExmortgage: 0.041,
    corporateMortgages: 0.056,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.119
  },
  "2009-12-01": {
    householdsCorporateOrSelf: 2.475,
    households: 0.959,
    corporateOrSelf: 0.696,
    government: 0.606,
    municipalities: 0.214,
    corporate: 0.422,
    selfemployed: 0.274,
    selfDepositaryLoans: 0.064,
    financialMortages: 0.196,
    financialLoans: 0.014,
    corporateBonds: 0.218,
    corporateDepositaryLoansExmortgage: 0.037,
    corporateMortgages: 0.052,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.11
  },
  "2010-03-01": {
    householdsCorporateOrSelf: 2.482,
    households: 0.949,
    corporateOrSelf: 0.689,
    government: 0.63,
    municipalities: 0.214,
    corporate: 0.417,
    selfemployed: 0.272,
    selfDepositaryLoans: 0.064,
    financialMortages: 0.194,
    financialLoans: 0.014,
    corporateBonds: 0.22,
    corporateDepositaryLoansExmortgage: 0.034,
    corporateMortgages: 0.05,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.108
  },
  "2010-06-01": {
    householdsCorporateOrSelf: 2.473,
    households: 0.931,
    corporateOrSelf: 0.674,
    government: 0.656,
    municipalities: 0.212,
    corporate: 0.407,
    selfemployed: 0.267,
    selfDepositaryLoans: 0.063,
    financialMortages: 0.191,
    financialLoans: 0.014,
    corporateBonds: 0.218,
    corporateDepositaryLoansExmortgage: 0.032,
    corporateMortgages: 0.047,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.104
  },
  "2010-09-01": {
    householdsCorporateOrSelf: 2.473,
    households: 0.915,
    corporateOrSelf: 0.669,
    government: 0.678,
    municipalities: 0.21,
    corporate: 0.405,
    selfemployed: 0.264,
    selfDepositaryLoans: 0.062,
    financialMortages: 0.189,
    financialLoans: 0.014,
    corporateBonds: 0.221,
    corporateDepositaryLoansExmortgage: 0.031,
    corporateMortgages: 0.045,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.103
  },
  "2010-12-01": {
    householdsCorporateOrSelf: 2.461,
    households: 0.906,
    corporateOrSelf: 0.657,
    government: 0.688,
    municipalities: 0.211,
    corporate: 0.397,
    selfemployed: 0.26,
    selfDepositaryLoans: 0.061,
    financialMortages: 0.186,
    financialLoans: 0.013,
    corporateBonds: 0.221,
    corporateDepositaryLoansExmortgage: 0.032,
    corporateMortgages: 0.044,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.095
  },
  "2011-03-01": {
    householdsCorporateOrSelf: 2.468,
    households: 0.905,
    corporateOrSelf: 0.655,
    government: 0.699,
    municipalities: 0.209,
    corporate: 0.396,
    selfemployed: 0.259,
    selfDepositaryLoans: 0.06,
    financialMortages: 0.185,
    financialLoans: 0.013,
    corporateBonds: 0.222,
    corporateDepositaryLoansExmortgage: 0.033,
    corporateMortgages: 0.041,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.095
  },
  "2011-06-01": {
    householdsCorporateOrSelf: 2.455,
    households: 0.89,
    corporateOrSelf: 0.651,
    government: 0.709,
    municipalities: 0.205,
    corporate: 0.397,
    selfemployed: 0.254,
    selfDepositaryLoans: 0.059,
    financialMortages: 0.182,
    financialLoans: 0.013,
    corporateBonds: 0.223,
    corporateDepositaryLoansExmortgage: 0.034,
    corporateMortgages: 0.038,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.096
  },
  "2011-09-01": {
    householdsCorporateOrSelf: 2.466,
    households: 0.879,
    corporateOrSelf: 0.652,
    government: 0.732,
    municipalities: 0.204,
    corporate: 0.401,
    selfemployed: 0.251,
    selfDepositaryLoans: 0.058,
    financialMortages: 0.18,
    financialLoans: 0.013,
    corporateBonds: 0.224,
    corporateDepositaryLoansExmortgage: 0.037,
    corporateMortgages: 0.037,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.096
  },
  "2011-12-01": {
    householdsCorporateOrSelf: 2.455,
    households: 0.867,
    corporateOrSelf: 0.65,
    government: 0.736,
    municipalities: 0.202,
    corporate: 0.403,
    selfemployed: 0.247,
    selfDepositaryLoans: 0.057,
    financialMortages: 0.178,
    financialLoans: 0.013,
    corporateBonds: 0.224,
    corporateDepositaryLoansExmortgage: 0.04,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.096
  },
  "2012-03-01": {
    householdsCorporateOrSelf: 2.448,
    households: 0.855,
    corporateOrSelf: 0.646,
    government: 0.748,
    municipalities: 0.199,
    corporate: 0.4,
    selfemployed: 0.246,
    selfDepositaryLoans: 0.057,
    financialMortages: 0.177,
    financialLoans: 0.012,
    corporateBonds: 0.225,
    corporateDepositaryLoansExmortgage: 0.04,
    corporateMortgages: 0.033,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.094
  },
  "2012-06-01": {
    householdsCorporateOrSelf: 2.457,
    households: 0.845,
    corporateOrSelf: 0.647,
    government: 0.766,
    municipalities: 0.199,
    corporate: 0.4,
    selfemployed: 0.247,
    selfDepositaryLoans: 0.058,
    financialMortages: 0.176,
    financialLoans: 0.013,
    corporateBonds: 0.227,
    corporateDepositaryLoansExmortgage: 0.041,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.093
  },
  "2012-09-01": {
    householdsCorporateOrSelf: 2.467,
    households: 0.838,
    corporateOrSelf: 0.653,
    government: 0.778,
    municipalities: 0.197,
    corporate: 0.405,
    selfemployed: 0.248,
    selfDepositaryLoans: 0.058,
    financialMortages: 0.177,
    financialLoans: 0.013,
    corporateBonds: 0.232,
    corporateDepositaryLoansExmortgage: 0.042,
    corporateMortgages: 0.029,
    corporateCommecialPaper: 0.007,
    CorporateOther: 0.095
  },
  "2012-12-01": {
    householdsCorporateOrSelf: 2.469,
    households: 0.832,
    corporateOrSelf: 0.66,
    government: 0.782,
    municipalities: 0.194,
    corporate: 0.411,
    selfemployed: 0.249,
    selfDepositaryLoans: 0.059,
    financialMortages: 0.177,
    financialLoans: 0.013,
    corporateBonds: 0.237,
    corporateDepositaryLoansExmortgage: 0.043,
    corporateMortgages: 0.029,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.093
  },
  "2013-03-01": {
    householdsCorporateOrSelf: 2.463,
    households: 0.823,
    corporateOrSelf: 0.656,
    government: 0.792,
    municipalities: 0.192,
    corporate: 0.409,
    selfemployed: 0.247,
    selfDepositaryLoans: 0.059,
    financialMortages: 0.176,
    financialLoans: 0.012,
    corporateBonds: 0.237,
    corporateDepositaryLoansExmortgage: 0.042,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.094
  },
  "2013-06-01": {
    householdsCorporateOrSelf: 2.468,
    households: 0.82,
    corporateOrSelf: 0.661,
    government: 0.797,
    municipalities: 0.19,
    corporate: 0.414,
    selfemployed: 0.248,
    selfDepositaryLoans: 0.059,
    financialMortages: 0.176,
    financialLoans: 0.013,
    corporateBonds: 0.239,
    corporateDepositaryLoansExmortgage: 0.043,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.096
  },
  "2013-09-01": {
    householdsCorporateOrSelf: 2.46,
    households: 0.812,
    corporateOrSelf: 0.665,
    government: 0.797,
    municipalities: 0.187,
    corporate: 0.419,
    selfemployed: 0.246,
    selfDepositaryLoans: 0.058,
    financialMortages: 0.175,
    financialLoans: 0.013,
    corporateBonds: 0.243,
    corporateDepositaryLoansExmortgage: 0.043,
    corporateMortgages: 0.029,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.094
  },
  "2013-12-01": {
    householdsCorporateOrSelf: 2.46,
    households: 0.811,
    corporateOrSelf: 0.663,
    government: 0.8,
    municipalities: 0.186,
    corporate: 0.418,
    selfemployed: 0.245,
    selfDepositaryLoans: 0.058,
    financialMortages: 0.174,
    financialLoans: 0.013,
    corporateBonds: 0.242,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.094
  },
  "2014-03-01": {
    householdsCorporateOrSelf: 2.486,
    households: 0.813,
    corporateOrSelf: 0.673,
    government: 0.814,
    municipalities: 0.186,
    corporate: 0.424,
    selfemployed: 0.248,
    selfDepositaryLoans: 0.059,
    financialMortages: 0.177,
    financialLoans: 0.013,
    corporateBonds: 0.246,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.096
  },
  "2014-06-01": {
    householdsCorporateOrSelf: 2.464,
    households: 0.803,
    corporateOrSelf: 0.67,
    government: 0.81,
    municipalities: 0.181,
    corporate: 0.422,
    selfemployed: 0.248,
    selfDepositaryLoans: 0.059,
    financialMortages: 0.176,
    financialLoans: 0.013,
    corporateBonds: 0.246,
    corporateDepositaryLoansExmortgage: 0.047,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.093
  },
  "2014-09-01": {
    householdsCorporateOrSelf: 2.443,
    households: 0.787,
    corporateOrSelf: 0.67,
    government: 0.811,
    municipalities: 0.175,
    corporate: 0.422,
    selfemployed: 0.248,
    selfDepositaryLoans: 0.059,
    financialMortages: 0.176,
    financialLoans: 0.013,
    corporateBonds: 0.245,
    corporateDepositaryLoansExmortgage: 0.047,
    corporateMortgages: 0.026,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.094
  },
  "2014-12-01": {
    householdsCorporateOrSelf: 2.448,
    households: 0.785,
    corporateOrSelf: 0.679,
    government: 0.809,
    municipalities: 0.174,
    corporate: 0.428,
    selfemployed: 0.251,
    selfDepositaryLoans: 0.06,
    financialMortages: 0.178,
    financialLoans: 0.013,
    corporateBonds: 0.249,
    corporateDepositaryLoansExmortgage: 0.048,
    corporateMortgages: 0.026,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.095
  },
  "2015-03-01": {
    householdsCorporateOrSelf: 2.449,
    households: 0.781,
    corporateOrSelf: 0.685,
    government: 0.809,
    municipalities: 0.174,
    corporate: 0.434,
    selfemployed: 0.252,
    selfDepositaryLoans: 0.061,
    financialMortages: 0.179,
    financialLoans: 0.012,
    corporateBonds: 0.253,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.096
  },
  "2015-06-01": {
    householdsCorporateOrSelf: 2.456,
    households: 0.779,
    corporateOrSelf: 0.692,
    government: 0.813,
    municipalities: 0.172,
    corporate: 0.44,
    selfemployed: 0.252,
    selfDepositaryLoans: 0.061,
    financialMortages: 0.179,
    financialLoans: 0.013,
    corporateBonds: 0.259,
    corporateDepositaryLoansExmortgage: 0.05,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.094
  },
  "2015-09-01": {
    householdsCorporateOrSelf: 2.455,
    households: 0.775,
    corporateOrSelf: 0.697,
    government: 0.812,
    municipalities: 0.171,
    corporate: 0.443,
    selfemployed: 0.254,
    selfDepositaryLoans: 0.061,
    financialMortages: 0.18,
    financialLoans: 0.013,
    corporateBonds: 0.262,
    corporateDepositaryLoansExmortgage: 0.051,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.093
  },
  "2015-12-01": {
    householdsCorporateOrSelf: 2.477,
    households: 0.774,
    corporateOrSelf: 0.705,
    government: 0.827,
    municipalities: 0.171,
    corporate: 0.448,
    selfemployed: 0.257,
    selfDepositaryLoans: 0.062,
    financialMortages: 0.183,
    financialLoans: 0.013,
    corporateBonds: 0.264,
    corporateDepositaryLoansExmortgage: 0.052,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.091
  },
  "2016-03-01": {
    householdsCorporateOrSelf: 2.501,
    households: 0.774,
    corporateOrSelf: 0.717,
    government: 0.84,
    municipalities: 0.17,
    corporate: 0.455,
    selfemployed: 0.261,
    selfDepositaryLoans: 0.064,
    financialMortages: 0.185,
    financialLoans: 0.012,
    corporateBonds: 0.27,
    corporateDepositaryLoansExmortgage: 0.053,
    corporateMortgages: 0.03,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.092
  },
  "2016-06-01": {
    householdsCorporateOrSelf: 2.504,
    households: 0.773,
    corporateOrSelf: 0.717,
    government: 0.845,
    municipalities: 0.17,
    corporate: 0.453,
    selfemployed: 0.264,
    selfDepositaryLoans: 0.064,
    financialMortages: 0.187,
    financialLoans: 0.012,
    corporateBonds: 0.271,
    corporateDepositaryLoansExmortgage: 0.053,
    corporateMortgages: 0.03,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.09
  },
  "2016-09-01": {
    householdsCorporateOrSelf: 2.511,
    households: 0.773,
    corporateOrSelf: 0.721,
    government: 0.849,
    municipalities: 0.168,
    corporate: 0.454,
    selfemployed: 0.267,
    selfDepositaryLoans: 0.065,
    financialMortages: 0.189,
    financialLoans: 0.012,
    corporateBonds: 0.273,
    corporateDepositaryLoansExmortgage: 0.053,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.008,
    CorporateOther: 0.09
  },
  "2016-12-01": {
    householdsCorporateOrSelf: 2.497,
    households: 0.77,
    corporateOrSelf: 0.716,
    government: 0.844,
    municipalities: 0.167,
    corporate: 0.447,
    selfemployed: 0.269,
    selfDepositaryLoans: 0.066,
    financialMortages: 0.191,
    financialLoans: 0.012,
    corporateBonds: 0.269,
    corporateDepositaryLoansExmortgage: 0.05,
    corporateMortgages: 0.03,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.088
  },
  "2017-03-01": {
    householdsCorporateOrSelf: 2.497,
    households: 0.769,
    corporateOrSelf: 0.723,
    government: 0.841,
    municipalities: 0.164,
    corporate: 0.45,
    selfemployed: 0.273,
    selfDepositaryLoans: 0.067,
    financialMortages: 0.193,
    financialLoans: 0.012,
    corporateBonds: 0.273,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.091
  },
  "2017-06-01": {
    householdsCorporateOrSelf: 2.505,
    households: 0.769,
    corporateOrSelf: 0.729,
    government: 0.845,
    municipalities: 0.162,
    corporate: 0.453,
    selfemployed: 0.276,
    selfDepositaryLoans: 0.068,
    financialMortages: 0.196,
    financialLoans: 0.012,
    corporateBonds: 0.274,
    corporateDepositaryLoansExmortgage: 0.048,
    corporateMortgages: 0.028,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.094
  },
  "2017-09-01": {
    householdsCorporateOrSelf: 2.5,
    households: 0.765,
    corporateOrSelf: 0.73,
    government: 0.845,
    municipalities: 0.16,
    corporate: 0.451,
    selfemployed: 0.279,
    selfDepositaryLoans: 0.069,
    financialMortages: 0.197,
    financialLoans: 0.012,
    corporateBonds: 0.274,
    corporateDepositaryLoansExmortgage: 0.046,
    corporateMortgages: 0.026,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.094
  },
  "2017-12-01": {
    householdsCorporateOrSelf: 2.488,
    households: 0.762,
    corporateOrSelf: 0.732,
    government: 0.835,
    municipalities: 0.159,
    corporate: 0.451,
    selfemployed: 0.28,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.198,
    financialLoans: 0.012,
    corporateBonds: 0.271,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.098
  },
  "2018-03-01": {
    householdsCorporateOrSelf: 2.498,
    households: 0.758,
    corporateOrSelf: 0.73,
    government: 0.854,
    municipalities: 0.156,
    corporate: 0.45,
    selfemployed: 0.28,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.198,
    financialLoans: 0.012,
    corporateBonds: 0.271,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.027,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.096
  },
  "2018-06-01": {
    householdsCorporateOrSelf: 2.497,
    households: 0.751,
    corporateOrSelf: 0.74,
    government: 0.853,
    municipalities: 0.153,
    corporate: 0.461,
    selfemployed: 0.279,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.198,
    financialLoans: 0.012,
    corporateBonds: 0.267,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.029,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.109
  },
  "2018-09-01": {
    householdsCorporateOrSelf: 2.502,
    households: 0.75,
    corporateOrSelf: 0.743,
    government: 0.856,
    municipalities: 0.152,
    corporate: 0.463,
    selfemployed: 0.28,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.198,
    financialLoans: 0.012,
    corporateBonds: 0.268,
    corporateDepositaryLoansExmortgage: 0.045,
    corporateMortgages: 0.029,
    corporateCommecialPaper: 0.011,
    CorporateOther: 0.11
  },
  "2018-12-01": {
    householdsCorporateOrSelf: 2.504,
    households: 0.75,
    corporateOrSelf: 0.746,
    government: 0.858,
    municipalities: 0.15,
    corporate: 0.464,
    selfemployed: 0.282,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.199,
    financialLoans: 0.012,
    corporateBonds: 0.267,
    corporateDepositaryLoansExmortgage: 0.048,
    corporateMortgages: 0.03,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.11
  },
  "2019-03-01": {
    householdsCorporateOrSelf: 2.517,
    households: 0.747,
    corporateOrSelf: 0.752,
    government: 0.871,
    municipalities: 0.148,
    corporate: 0.47,
    selfemployed: 0.282,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.2,
    financialLoans: 0.012,
    corporateBonds: 0.269,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.111
  },
  "2019-06-01": {
    householdsCorporateOrSelf: 2.505,
    households: 0.744,
    corporateOrSelf: 0.75,
    government: 0.866,
    municipalities: 0.145,
    corporate: 0.468,
    selfemployed: 0.281,
    selfDepositaryLoans: 0.07,
    financialMortages: 0.2,
    financialLoans: 0.012,
    corporateBonds: 0.269,
    corporateDepositaryLoansExmortgage: 0.049,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.01,
    CorporateOther: 0.111
  },
  "2019-09-01": {
    householdsCorporateOrSelf: 2.515,
    households: 0.742,
    corporateOrSelf: 0.753,
    government: 0.876,
    municipalities: 0.144,
    corporate: 0.471,
    selfemployed: 0.282,
    selfDepositaryLoans: 0.069,
    financialMortages: 0.202,
    financialLoans: 0.012,
    corporateBonds: 0.27,
    corporateDepositaryLoansExmortgage: 0.048,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.111
  },
  "2019-12-01": {
    householdsCorporateOrSelf: 2.514,
    households: 0.742,
    corporateOrSelf: 0.751,
    government: 0.878,
    municipalities: 0.144,
    corporate: 0.468,
    selfemployed: 0.283,
    selfDepositaryLoans: 0.068,
    financialMortages: 0.203,
    financialLoans: 0.012,
    corporateBonds: 0.268,
    corporateDepositaryLoansExmortgage: 0.048,
    corporateMortgages: 0.031,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.112
  },
  "2020-03-01": {
    householdsCorporateOrSelf: 2.613,
    households: 0.756,
    corporateOrSelf: 0.794,
    government: 0.918,
    municipalities: 0.146,
    corporate: 0.501,
    selfemployed: 0.293,
    selfDepositaryLoans: 0.073,
    financialMortages: 0.208,
    financialLoans: 0.012,
    corporateBonds: 0.279,
    corporateDepositaryLoansExmortgage: 0.064,
    corporateMortgages: 0.032,
    corporateCommecialPaper: 0.009,
    CorporateOther: 0.117
  },
  "2020-06-01": {
    householdsCorporateOrSelf: 3.075,
    households: 0.834,
    corporateOrSelf: 0.909,
    government: 1.17,
    municipalities: 0.162,
    corporate: 0.573,
    selfemployed: 0.335,
    selfDepositaryLoans: 0.088,
    financialMortages: 0.232,
    financialLoans: 0.016,
    corporateBonds: 0.33,
    corporateDepositaryLoansExmortgage: 0.07,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.131
  },
  "2020-09-01": {
    householdsCorporateOrSelf: 2.862,
    households: 0.779,
    corporateOrSelf: 0.838,
    government: 1.094,
    municipalities: 0.151,
    corporate: 0.524,
    selfemployed: 0.313,
    selfDepositaryLoans: 0.081,
    financialMortages: 0.216,
    financialLoans: 0.016,
    corporateBonds: 0.307,
    corporateDepositaryLoansExmortgage: 0.056,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.123
  },
  "2020-12-01": {
    householdsCorporateOrSelf: 2.853,
    households: 0.778,
    corporateOrSelf: 0.825,
    government: 1.1,
    municipalities: 0.149,
    corporate: 0.516,
    selfemployed: 0.31,
    selfDepositaryLoans: 0.077,
    financialMortages: 0.216,
    financialLoans: 0.016,
    corporateBonds: 0.302,
    corporateDepositaryLoansExmortgage: 0.052,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.122
  },
  "2021-03-01": {
    householdsCorporateOrSelf: 2.827,
    households: 0.771,
    corporateOrSelf: 0.814,
    government: 1.096,
    municipalities: 0.147,
    corporate: 0.509,
    selfemployed: 0.305,
    selfDepositaryLoans: 0.075,
    financialMortages: 0.213,
    financialLoans: 0.016,
    corporateBonds: 0.3,
    corporateDepositaryLoansExmortgage: 0.051,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.004,
    CorporateOther: 0.121
  },
  "2021-06-01": {
    householdsCorporateOrSelf: 2.785,
    households: 0.762,
    corporateOrSelf: 0.792,
    government: 1.087,
    municipalities: 0.144,
    corporate: 0.495,
    selfemployed: 0.297,
    selfDepositaryLoans: 0.071,
    financialMortages: 0.209,
    financialLoans: 0.017,
    corporateBonds: 0.291,
    corporateDepositaryLoansExmortgage: 0.046,
    corporateMortgages: 0.034,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.119
  },
  "2021-09-01": {
    householdsCorporateOrSelf: 2.747,
    households: 0.758,
    corporateOrSelf: 0.786,
    government: 1.062,
    municipalities: 0.141,
    corporate: 0.494,
    selfemployed: 0.292,
    selfDepositaryLoans: 0.067,
    financialMortages: 0.208,
    financialLoans: 0.017,
    corporateBonds: 0.288,
    corporateDepositaryLoansExmortgage: 0.043,
    corporateMortgages: 0.035,
    corporateCommecialPaper: 0.005,
    CorporateOther: 0.122
  },
  "2021-12-01": {
    householdsCorporateOrSelf: 2.71,
    households: 0.747,
    corporateOrSelf: 0.772,
    government: 1.054,
    municipalities: 0.136,
    corporate: 0.485,
    selfemployed: 0.287,
    selfDepositaryLoans: 0.065,
    financialMortages: 0.205,
    financialLoans: 0.017,
    corporateBonds: 0.277,
    corporateDepositaryLoansExmortgage: 0.044,
    corporateMortgages: 0.036,
    corporateCommecialPaper: 0.006,
    CorporateOther: 0.122
  }
};
export const newdebt = {
  "1952-03-01": {
    corporateOrSelf: 8.062,
    households: 10.797,
    corporate: 6.254,
    government: 5.006,
    municipalities: 35.309
  },
  "1952-06-01": {
    corporateOrSelf: 8.597,
    households: 15.508,
    corporate: 8.662,
    government: 2.156,
    municipalities: 42.045
  },
  "1952-09-01": {
    corporateOrSelf: 7.133,
    households: 12.853,
    corporate: 8.935,
    government: 2.838,
    municipalities: 16.795
  },
  "1952-12-01": {
    corporateOrSelf: 6.999,
    households: 16.162,
    corporate: 8.787,
    government: 1.208,
    municipalities: 16.754
  },
  "1953-03-01": {
    corporateOrSelf: 7.032,
    households: 17.404,
    corporate: 6.256,
    government: 1.918,
    municipalities: 15.834
  },
  "1953-06-01": {
    corporateOrSelf: 6.403,
    households: 13.755,
    corporate: 5.274,
    government: 2.513,
    municipalities: 15.581
  },
  "1953-09-01": {
    corporateOrSelf: 8.267,
    households: 9.293,
    corporate: 5.678,
    government: 8.125,
    municipalities: 15.117
  },
  "1953-12-01": {
    corporateOrSelf: 3.214,
    households: 9.76,
    corporate: -0.489,
    government: 0.467,
    municipalities: 14.694
  },
  "1954-03-01": {
    corporateOrSelf: 3.703,
    households: 6.699,
    corporate: 5.197,
    government: 0.003,
    municipalities: 14.285
  },
  "1954-06-01": {
    corporateOrSelf: 4.679,
    households: 9.829,
    corporate: 3.948,
    government: 1.158,
    municipalities: 14.3
  },
  "1954-09-01": {
    corporateOrSelf: 5.226,
    households: 11.727,
    corporate: 7.211,
    government: -0.227,
    municipalities: 13.744
  },
  "1954-12-01": {
    corporateOrSelf: 7.293,
    households: 15.446,
    corporate: 4.167,
    government: 3.803,
    municipalities: 14.043
  },
  "1955-03-01": {
    corporateOrSelf: 5.932,
    households: 17.548,
    corporate: 5.582,
    government: -0.989,
    municipalities: 13.391
  },
  "1955-06-01": {
    corporateOrSelf: 7.938,
    households: 18.856,
    corporate: 10.816,
    government: -0.023,
    municipalities: 12.025
  },
  "1955-09-01": {
    corporateOrSelf: 6.841,
    households: 15.786,
    corporate: 9.088,
    government: -0.087,
    municipalities: 11.244
  },
  "1955-12-01": {
    corporateOrSelf: 7.777,
    households: 13.743,
    corporate: 13.195,
    government: 0.905,
    municipalities: 10.341
  },
  "1956-03-01": {
    corporateOrSelf: 5.173,
    households: 11.501,
    corporate: 9.317,
    government: -1.774,
    municipalities: 9.691
  },
  "1956-06-01": {
    corporateOrSelf: 3.542,
    households: 10.66,
    corporate: 10.855,
    government: -6.207,
    municipalities: 9.368
  },
  "1956-09-01": {
    corporateOrSelf: 4.604,
    households: 8.273,
    corporate: 8.758,
    government: -1.047,
    municipalities: 8.468
  },
  "1956-12-01": {
    corporateOrSelf: 5.414,
    households: 9.945,
    corporate: 7.549,
    government: 0.6,
    municipalities: 7.968
  },
  "1957-03-01": {
    corporateOrSelf: 6.034,
    households: 9.201,
    corporate: 8.291,
    government: 2.102,
    municipalities: 7.876
  },
  "1957-06-01": {
    corporateOrSelf: 2.865,
    households: 9.057,
    corporate: 9.786,
    government: -7.031,
    municipalities: 8.619
  },
  "1957-09-01": {
    corporateOrSelf: 5.956,
    households: 6.822,
    corporate: 7.068,
    government: 3.881,
    municipalities: 9.139
  },
  "1957-12-01": {
    corporateOrSelf: 3.234,
    households: 5.199,
    corporate: 6.475,
    government: -1.934,
    municipalities: 10.134
  },
  "1958-03-01": {
    corporateOrSelf: 4.746,
    households: 3.83,
    corporate: 5.98,
    government: 3.33,
    municipalities: 9.935
  },
  "1958-06-01": {
    corporateOrSelf: 6.344,
    households: 4.986,
    corporate: 5.081,
    government: 7.354,
    municipalities: 9.885
  },
  "1958-09-01": {
    corporateOrSelf: 3.99,
    households: 7.376,
    corporate: 6.615,
    government: -1.535,
    municipalities: 9.273
  },
  "1958-12-01": {
    corporateOrSelf: 9.425,
    households: 11.131,
    corporate: 9.309,
    government: 8.026,
    municipalities: 10.342
  },
  "1959-03-01": {
    corporateOrSelf: 6.8,
    households: 10.689,
    corporate: 6.111,
    government: 3.726,
    municipalities: 9.521
  },
  "1959-06-01": {
    corporateOrSelf: 8.652,
    households: 12.002,
    corporate: 11.637,
    government: 3.669,
    municipalities: 9.969
  },
  "1959-09-01": {
    corporateOrSelf: 7.943,
    households: 12.3,
    corporate: 7.376,
    government: 4.279,
    municipalities: 10.763
  },
  "1959-12-01": {
    corporateOrSelf: 6.549,
    households: 11.843,
    corporate: 7.892,
    government: 1.012,
    municipalities: 8.089
  },
  "1960-03-01": {
    corporateOrSelf: 4.329,
    households: 7.183,
    corporate: 7.975,
    government: -1.882,
    municipalities: 8.852
  },
  "1960-06-01": {
    corporateOrSelf: 5.728,
    households: 10.024,
    corporate: 8.35,
    government: -0.514,
    municipalities: 8.308
  },
  "1960-09-01": {
    corporateOrSelf: 4.593,
    households: 8.108,
    corporate: 5.192,
    government: 0.203,
    municipalities: 8.001
  },
  "1960-12-01": {
    corporateOrSelf: 5.162,
    households: 8.307,
    corporate: 7.084,
    government: 0.429,
    municipalities: 6.937
  },
  "1961-03-01": {
    corporateOrSelf: 4.088,
    households: 6.49,
    corporate: 4.763,
    government: 0.382,
    municipalities: 7.727
  },
  "1961-06-01": {
    corporateOrSelf: 5.797,
    households: 7.837,
    corporate: 6.603,
    government: 3.268,
    municipalities: 5.983
  },
  "1961-09-01": {
    corporateOrSelf: 6.982,
    households: 7.01,
    corporate: 6.815,
    government: 6.645,
    municipalities: 8.492
  },
  "1961-12-01": {
    corporateOrSelf: 7.274,
    households: 10.532,
    corporate: 9.209,
    government: 2.538,
    municipalities: 7.98
  },
  "1962-03-01": {
    corporateOrSelf: 5.893,
    households: 6.863,
    corporate: 6.019,
    government: 3.966,
    municipalities: 8.975
  },
  "1962-06-01": {
    corporateOrSelf: 6.854,
    households: 8.744,
    corporate: 9.425,
    government: 2.904,
    municipalities: 7.008
  },
  "1962-09-01": {
    corporateOrSelf: 5.499,
    households: 9.722,
    corporate: 7.73,
    government: -0.524,
    municipalities: 5.989
  },
  "1962-12-01": {
    corporateOrSelf: 8.347,
    households: 10.217,
    corporate: 9.476,
    government: 5.733,
    municipalities: 7.804
  },
  "1963-03-01": {
    corporateOrSelf: 6.15,
    households: 8.902,
    corporate: 6.683,
    government: 2.996,
    municipalities: 6.15
  },
  "1963-06-01": {
    corporateOrSelf: 7.584,
    households: 11.322,
    corporate: 7.954,
    government: 3.353,
    municipalities: 8.22
  },
  "1963-09-01": {
    corporateOrSelf: 5.423,
    households: 10.71,
    corporate: 7.199,
    government: -1.412,
    municipalities: 5.138
  },
  "1963-12-01": {
    corporateOrSelf: 7.846,
    households: 11.202,
    corporate: 10.541,
    government: 2.492,
    municipalities: 6.014
  },
  "1964-03-01": {
    corporateOrSelf: 6.055,
    households: 9.647,
    corporate: 6.464,
    government: 1.97,
    municipalities: 5.701
  },
  "1964-06-01": {
    corporateOrSelf: 7.245,
    households: 10.123,
    corporate: 9.037,
    government: 2.31,
    municipalities: 7.636
  },
  "1964-09-01": {
    corporateOrSelf: 7.034,
    households: 8.855,
    corporate: 8.787,
    government: 3.163,
    municipalities: 7.576
  },
  "1964-12-01": {
    corporateOrSelf: 7.704,
    households: 10.085,
    corporate: 9.833,
    government: 3.227,
    municipalities: 6.954
  },
  "1965-03-01": {
    corporateOrSelf: 6.87,
    households: 8.522,
    corporate: 10.49,
    government: 1.265,
    municipalities: 7.325
  },
  "1965-06-01": {
    corporateOrSelf: 6.942,
    households: 8.956,
    corporate: 10.612,
    government: 0.264,
    municipalities: 8.783
  },
  "1965-09-01": {
    corporateOrSelf: 5.659,
    households: 8.159,
    corporate: 10.155,
    government: -2.729,
    municipalities: 7.838
  },
  "1965-12-01": {
    corporateOrSelf: 8.3,
    households: 9.435,
    corporate: 10.201,
    government: 5.457,
    municipalities: 6.745
  },
  "1966-03-01": {
    corporateOrSelf: 7.371,
    households: 7.266,
    corporate: 12.577,
    government: 2.149,
    municipalities: 6.626
  },
  "1966-06-01": {
    corporateOrSelf: 7.424,
    households: 8.167,
    corporate: 13.429,
    government: -0.416,
    municipalities: 8.127
  },
  "1966-09-01": {
    corporateOrSelf: 4.879,
    households: 4.149,
    corporate: 9.883,
    government: -0.239,
    municipalities: 5.7
  },
  "1966-12-01": {
    corporateOrSelf: 6.15,
    households: 5.481,
    corporate: 7.739,
    government: 5.386,
    municipalities: 5.521
  },
  "1967-03-01": {
    corporateOrSelf: 6.025,
    households: 3.815,
    corporate: 9.871,
    government: 4.621,
    municipalities: 5.256
  },
  "1967-06-01": {
    corporateOrSelf: 4.513,
    households: 5.103,
    corporate: 10.746,
    government: -4.865,
    municipalities: 7.52
  },
  "1967-09-01": {
    corporateOrSelf: 7.947,
    households: 6.148,
    corporate: 9.222,
    government: 8.952,
    municipalities: 7.388
  },
  "1967-12-01": {
    corporateOrSelf: 12.666,
    households: 20.938,
    corporate: 7.447,
    government: 11.055,
    municipalities: 5.83
  },
  "1968-03-01": {
    corporateOrSelf: 4.598,
    households: -4.621,
    corporate: 10.749,
    government: 8.827,
    municipalities: 5.943
  },
  "1968-06-01": {
    corporateOrSelf: 7.922,
    households: 10.052,
    corporate: 11.404,
    government: 1.388,
    municipalities: 6.521
  },
  "1968-09-01": {
    corporateOrSelf: 8.112,
    households: 7.078,
    corporate: 10.404,
    government: 7.248,
    municipalities: 6.39
  },
  "1968-12-01": {
    corporateOrSelf: 8.582,
    households: 11.156,
    corporate: 11.554,
    government: 0.742,
    municipalities: 10.189
  },
  "1969-03-01": {
    corporateOrSelf: 6.545,
    households: 4.535,
    corporate: 10.632,
    government: 1.43,
    municipalities: 12.718
  },
  "1969-06-01": {
    corporateOrSelf: 5.606,
    households: 8.092,
    corporate: 13.392,
    government: -10.106,
    municipalities: 10.128
  },
  "1969-09-01": {
    corporateOrSelf: 7.011,
    households: 4.885,
    corporate: 11.358,
    government: 3.394,
    municipalities: 8.064
  },
  "1969-12-01": {
    corporateOrSelf: 7.33,
    households: 8.223,
    corporate: 9.504,
    government: 3.355,
    municipalities: 6.28
  },
  "1970-03-01": {
    corporateOrSelf: 4.811,
    households: 1.726,
    corporate: 9.065,
    government: 2.453,
    municipalities: 6.084
  },
  "1970-06-01": {
    corporateOrSelf: 6.803,
    households: 2.346,
    corporate: 12.625,
    government: 3.376,
    municipalities: 9.159
  },
  "1970-09-01": {
    corporateOrSelf: 7.463,
    households: 4.8,
    corporate: 9.735,
    government: 8.116,
    municipalities: 6.706
  },
  "1970-12-01": {
    corporateOrSelf: 7.308,
    households: 6.799,
    corporate: 8.417,
    government: 4.25,
    municipalities: 11.881
  },
  "1971-03-01": {
    corporateOrSelf: 7.282,
    households: 7.285,
    corporate: 9.172,
    government: 2.319,
    municipalities: 11.637
  },
  "1971-06-01": {
    corporateOrSelf: 10.506,
    households: 8.043,
    corporate: 11.356,
    government: 12.574,
    municipalities: 10.809
  },
  "1971-09-01": {
    corporateOrSelf: 9.674,
    households: 8.893,
    corporate: 10.443,
    government: 9.674,
    municipalities: 9.457
  },
  "1971-12-01": {
    corporateOrSelf: 9.766,
    households: 12.4,
    corporate: 8.165,
    government: 8.377,
    municipalities: 10.136
  },
  "1972-03-01": {
    corporateOrSelf: 8.819,
    households: 10.457,
    corporate: 11.273,
    government: 1.72,
    municipalities: 10.608
  },
  "1972-06-01": {
    corporateOrSelf: 9.334,
    households: 11.566,
    corporate: 12.282,
    government: 2.16,
    municipalities: 7.455
  },
  "1972-09-01": {
    corporateOrSelf: 9.26,
    households: 9.661,
    corporate: 11.064,
    government: 6.715,
    municipalities: 7.073
  },
  "1972-12-01": {
    corporateOrSelf: 11.898,
    households: 13.406,
    corporate: 13.343,
    government: 9.383,
    municipalities: 7.367
  },
  "1973-03-01": {
    corporateOrSelf: 11.987,
    households: 12.716,
    corporate: 16.14,
    government: 5.833,
    municipalities: 7.798
  },
  "1973-06-01": {
    corporateOrSelf: 9.764,
    households: 9.508,
    corporate: 15.752,
    government: 1.567,
    municipalities: 5.979
  },
  "1973-09-01": {
    corporateOrSelf: 9.465,
    households: 10.722,
    corporate: 14.529,
    government: -0.626,
    municipalities: 7.327
  },
  "1973-12-01": {
    corporateOrSelf: 8.483,
    households: 10.252,
    corporate: 9.727,
    government: 2.907,
    municipalities: 9.174
  },
  "1974-03-01": {
    corporateOrSelf: 8.377,
    households: 7.794,
    corporate: 13.052,
    government: 0.105,
    municipalities: 9.042
  },
  "1974-06-01": {
    corporateOrSelf: 10.18,
    households: 9.33,
    corporate: 14.978,
    government: 3.881,
    municipalities: 6.928
  },
  "1974-09-01": {
    corporateOrSelf: 9.023,
    households: 8.821,
    corporate: 11.813,
    government: 4.77,
    municipalities: 7.045
  },
  "1974-12-01": {
    corporateOrSelf: 7.566,
    households: 7.534,
    corporate: 9.113,
    government: 6.379,
    municipalities: 3.903
  },
  "1975-03-01": {
    corporateOrSelf: 7.178,
    households: 5.017,
    corporate: 5.299,
    government: 16.326,
    municipalities: 4.342
  },
  "1975-06-01": {
    corporateOrSelf: 9.394,
    households: 6.186,
    corporate: 4.688,
    government: 27.003,
    municipalities: 4.135
  },
  "1975-09-01": {
    corporateOrSelf: 9.323,
    households: 9.035,
    corporate: 4.729,
    government: 21.761,
    municipalities: 2.692
  },
  "1975-12-01": {
    corporateOrSelf: 10.419,
    households: 12.934,
    corporate: 4.084,
    government: 18.369,
    municipalities: 9.864
  },
  "1976-03-01": {
    corporateOrSelf: 10.683,
    households: 10.391,
    corporate: 7.749,
    government: 16.497,
    municipalities: 10.303
  },
  "1976-06-01": {
    corporateOrSelf: 11.24,
    households: 10.715,
    corporate: 7.982,
    government: 17.598,
    municipalities: 11.386
  },
  "1976-09-01": {
    corporateOrSelf: 10.142,
    households: 10.193,
    corporate: 8.668,
    government: 14.142,
    municipalities: 6.511
  },
  "1976-12-01": {
    corporateOrSelf: 10.262,
    households: 13.839,
    corporate: 9.111,
    government: 9.368,
    municipalities: 4.505
  },
  "1977-03-01": {
    corporateOrSelf: 11.372,
    households: 13.789,
    corporate: 11.751,
    government: 9.071,
    municipalities: 6.803
  },
  "1977-06-01": {
    corporateOrSelf: 11.367,
    households: 15.316,
    corporate: 11.46,
    government: 5.678,
    municipalities: 10.342
  },
  "1977-09-01": {
    corporateOrSelf: 13.693,
    households: 14.833,
    corporate: 11.082,
    government: 18.672,
    municipalities: 8.287
  },
  "1977-12-01": {
    corporateOrSelf: 12.76,
    households: 15.197,
    corporate: 13.646,
    government: 9.859,
    municipalities: 7.172
  },
  "1978-03-01": {
    corporateOrSelf: 13.333,
    households: 15.293,
    corporate: 13.646,
    government: 9.316,
    municipalities: 14.419
  },
  "1978-06-01": {
    corporateOrSelf: 13.779,
    households: 17.618,
    corporate: 13.092,
    government: 8.688,
    municipalities: 14.292
  },
  "1978-09-01": {
    corporateOrSelf: 13.465,
    households: 16.002,
    corporate: 11.076,
    government: 13.384,
    municipalities: 13.702
  },
  "1978-12-01": {
    corporateOrSelf: 12.212,
    households: 14.988,
    corporate: 12.548,
    government: 5.587,
    municipalities: 15.903
  },
  "1979-03-01": {
    corporateOrSelf: 10.867,
    households: 14.813,
    corporate: 12.541,
    government: 1.731,
    municipalities: 10.185
  },
  "1979-06-01": {
    corporateOrSelf: 12.021,
    households: 14.091,
    corporate: 14.776,
    government: 5.049,
    municipalities: 8.691
  },
  "1979-09-01": {
    corporateOrSelf: 13.168,
    households: 14.595,
    corporate: 13.431,
    government: 12.129,
    municipalities: 8.834
  },
  "1979-12-01": {
    corporateOrSelf: 10.303,
    households: 12.854,
    corporate: 10.834,
    government: 6.243,
    municipalities: 7.155
  },
  "1980-03-01": {
    corporateOrSelf: 9.93,
    households: 9.91,
    corporate: 11.983,
    government: 7.493,
    municipalities: 6.937
  },
  "1980-06-01": {
    corporateOrSelf: 7.237,
    households: 4.011,
    corporate: 6.387,
    government: 15.065,
    municipalities: 6.34
  },
  "1980-09-01": {
    corporateOrSelf: 8.938,
    households: 8.646,
    corporate: 7.746,
    government: 12.508,
    municipalities: 6.921
  },
  "1980-12-01": {
    corporateOrSelf: 11.019,
    households: 11.275,
    corporate: 12.065,
    government: 10.529,
    municipalities: 6.697
  },
  "1981-03-01": {
    corporateOrSelf: 9.344,
    households: 6.239,
    corporate: 9.617,
    government: 14.864,
    municipalities: 7.963
  },
  "1981-06-01": {
    corporateOrSelf: 10.877,
    households: 9.023,
    corporate: 15.175,
    government: 8.709,
    municipalities: 5.225
  },
  "1981-09-01": {
    corporateOrSelf: 9.358,
    households: 6.511,
    corporate: 13.111,
    government: 7.474,
    municipalities: 9.196
  },
  "1981-12-01": {
    corporateOrSelf: 9.866,
    households: 6.118,
    corporate: 11.298,
    government: 14.084,
    municipalities: 8.781
  },
  "1982-03-01": {
    corporateOrSelf: 9.454,
    households: 4.58,
    corporate: 10.387,
    government: 15.058,
    municipalities: 11.618
  },
  "1982-06-01": {
    corporateOrSelf: 8.899,
    households: 5.038,
    corporate: 10.839,
    government: 10.714,
    municipalities: 11.395
  },
  "1982-09-01": {
    corporateOrSelf: 11.054,
    households: 4.084,
    corporate: 10.622,
    government: 23.604,
    municipalities: 9.843
  },
  "1982-12-01": {
    corporateOrSelf: 9.843,
    households: 8.394,
    corporate: 3.94,
    government: 22.227,
    municipalities: 10.229
  },
  "1983-03-01": {
    corporateOrSelf: 10.923,
    households: 7.548,
    corporate: 6.115,
    government: 24.139,
    municipalities: 10.429
  },
  "1983-06-01": {
    corporateOrSelf: 12.665,
    households: 11.03,
    corporate: 8.274,
    government: 21.281,
    municipalities: 14.813
  },
  "1983-09-01": {
    corporateOrSelf: 12.223,
    households: 11.733,
    corporate: 10.393,
    government: 17.03,
    municipalities: 8.754
  },
  "1983-12-01": {
    corporateOrSelf: 10.646,
    households: 13.812,
    corporate: 10.376,
    government: 7.033,
    municipalities: 9.947
  },
  "1984-03-01": {
    corporateOrSelf: 14.754,
    households: 12.866,
    corporate: 14.277,
    government: 19.777,
    municipalities: 10.074
  },
  "1984-06-01": {
    corporateOrSelf: 14.388,
    households: 12.801,
    corporate: 16.916,
    government: 13.551,
    municipalities: 11.783
  },
  "1984-09-01": {
    corporateOrSelf: 13.63,
    households: 9.672,
    corporate: 14.181,
    government: 17.641,
    municipalities: 14.949
  },
  "1984-12-01": {
    corporateOrSelf: 13.227,
    households: 12.987,
    corporate: 15.284,
    government: 12.551,
    municipalities: 6.973
  },
  "1985-03-01": {
    corporateOrSelf: 15.13,
    households: 15.409,
    corporate: 11.341,
    government: 18.932,
    municipalities: 20.097
  },
  "1985-06-01": {
    corporateOrSelf: 13.558,
    households: 12.933,
    corporate: 11.094,
    government: 15.552,
    municipalities: 21.029
  },
  "1985-09-01": {
    corporateOrSelf: 12.804,
    households: 15.085,
    corporate: 8.835,
    government: 14.733,
    municipalities: 15.754
  },
  "1985-12-01": {
    corporateOrSelf: 19.253,
    households: 19.276,
    corporate: 11.04,
    government: 17.491,
    municipalities: 58.996
  },
  "1986-03-01": {
    corporateOrSelf: 9.363,
    households: 9.374,
    corporate: 9.993,
    government: 11.203,
    municipalities: 2.152
  },
  "1986-06-01": {
    corporateOrSelf: 12.56,
    households: 8.965,
    corporate: 11.207,
    government: 16.409,
    municipalities: 20.132
  },
  "1986-09-01": {
    corporateOrSelf: 11.917,
    households: 12.861,
    corporate: 11.208,
    government: 13.575,
    municipalities: 7.016
  },
  "1986-12-01": {
    corporateOrSelf: 12.002,
    households: 12.449,
    corporate: 11.423,
    government: 11.874,
    municipalities: 13.009
  },
  "1987-03-01": {
    corporateOrSelf: 7.601,
    households: 7.26,
    corporate: 5.556,
    government: 7.307,
    municipalities: 17.351
  },
  "1987-06-01": {
    corporateOrSelf: 10.005,
    households: 12.642,
    corporate: 7.192,
    government: 11.202,
    municipalities: 8.559
  },
  "1987-09-01": {
    corporateOrSelf: 8.721,
    households: 10.525,
    corporate: 8.729,
    government: 5.883,
    municipalities: 10.041
  },
  "1987-12-01": {
    corporateOrSelf: 8.421,
    households: 7.14,
    corporate: 8.528,
    government: 9.256,
    municipalities: 10.178
  },
  "1988-03-01": {
    corporateOrSelf: 8.982,
    households: 9.278,
    corporate: 9.74,
    government: 9.161,
    municipalities: 4.732
  },
  "1988-06-01": {
    corporateOrSelf: 9.101,
    households: 10.081,
    corporate: 9.739,
    government: 8.233,
    municipalities: 5.664
  },
  "1988-09-01": {
    corporateOrSelf: 9.027,
    households: 9.817,
    corporate: 9.764,
    government: 8.313,
    municipalities: 5.416
  },
  "1988-12-01": {
    corporateOrSelf: 8.455,
    households: 8.402,
    corporate: 9.264,
    government: 7.684,
    municipalities: 7.608
  },
  "1989-03-01": {
    corporateOrSelf: 6.953,
    households: 7.036,
    corporate: 7.035,
    government: 8.018,
    municipalities: 3.53
  },
  "1989-06-01": {
    corporateOrSelf: 7.715,
    households: 9.443,
    corporate: 8.231,
    government: 6.156,
    municipalities: 3.827
  },
  "1989-09-01": {
    corporateOrSelf: 6.676,
    households: 8.881,
    corporate: 4.491,
    government: 7.45,
    municipalities: 5.265
  },
  "1989-12-01": {
    corporateOrSelf: 7.809,
    households: 10.83,
    corporate: 4.734,
    government: 8.079,
    municipalities: 8.205
  },
  "1990-03-01": {
    corporateOrSelf: 7.799,
    households: 7.647,
    corporate: 5.916,
    government: 10.686,
    municipalities: 7.827
  },
  "1990-06-01": {
    corporateOrSelf: 6.354,
    households: 7.135,
    corporate: 3.833,
    government: 9.634,
    municipalities: 4.317
  },
  "1990-09-01": {
    corporateOrSelf: 5.729,
    households: 5.88,
    corporate: 2.824,
    government: 10.769,
    municipalities: 2.44
  },
  "1990-12-01": {
    corporateOrSelf: 5.695,
    households: 5.848,
    corporate: 1.711,
    government: 11.161,
    municipalities: 5.085
  },
  "1991-03-01": {
    corporateOrSelf: 3.926,
    households: 6.12,
    corporate: -1.696,
    government: 8.249,
    municipalities: 4.974
  },
  "1991-06-01": {
    corporateOrSelf: 5.067,
    households: 6.059,
    corporate: -0.004,
    government: 10.003,
    municipalities: 6.196
  },
  "1991-09-01": {
    corporateOrSelf: 4.399,
    households: 3.28,
    corporate: -3.298,
    government: 13.686,
    municipalities: 9.955
  },
  "1991-12-01": {
    corporateOrSelf: 5.247,
    households: 6.611,
    corporate: -3.536,
    government: 11.054,
    municipalities: 14.665
  },
  "1992-03-01": {
    corporateOrSelf: 4.782,
    households: 5.372,
    corporate: -0.144,
    government: 11.523,
    municipalities: -0.114
  },
  "1992-06-01": {
    corporateOrSelf: 4.256,
    households: 3.814,
    corporate: -0.719,
    government: 11.221,
    municipalities: 2.008
  },
  "1992-09-01": {
    corporateOrSelf: 5.416,
    households: 5.321,
    corporate: 0.192,
    government: 11.4,
    municipalities: 5.18
  },
  "1992-12-01": {
    corporateOrSelf: 3.901,
    households: 5.933,
    corporate: -0.533,
    government: 7.873,
    municipalities: -0.961
  },
  "1993-03-01": {
    corporateOrSelf: 4.338,
    households: 4.162,
    corporate: 1.777,
    government: 6.747,
    municipalities: 5.939
  },
  "1993-06-01": {
    corporateOrSelf: 6.62,
    households: 5.952,
    corporate: 3.702,
    government: 10.27,
    municipalities: 7.147
  },
  "1993-09-01": {
    corporateOrSelf: 6.18,
    households: 6.843,
    corporate: 3.852,
    government: 7.523,
    municipalities: 7.121
  },
  "1993-12-01": {
    corporateOrSelf: 5.465,
    households: 6.889,
    corporate: 2.814,
    government: 8.054,
    municipalities: 0.587
  },
  "1994-03-01": {
    corporateOrSelf: 4.521,
    households: 6.72,
    corporate: 5.738,
    government: 2.707,
    municipalities: -1.761
  },
  "1994-06-01": {
    corporateOrSelf: 5.338,
    households: 7.158,
    corporate: 4.815,
    government: 7.311,
    municipalities: -6.38
  },
  "1994-09-01": {
    corporateOrSelf: 5.159,
    households: 6.437,
    corporate: 5.235,
    government: 5.283,
    municipalities: -0.562
  },
  "1994-12-01": {
    corporateOrSelf: 5.364,
    households: 8.988,
    corporate: 5.404,
    government: 4.783,
    municipalities: -7.293
  },
  "1995-03-01": {
    corporateOrSelf: 4.812,
    households: 6.37,
    corporate: 6.968,
    government: 3.714,
    municipalities: -5.596
  },
  "1995-06-01": {
    corporateOrSelf: 7.029,
    households: 7.001,
    corporate: 7.191,
    government: 10.274,
    municipalities: -5.396
  },
  "1995-09-01": {
    corporateOrSelf: 4.485,
    households: 7.833,
    corporate: 4.54,
    government: 3.046,
    municipalities: -5.087
  },
  "1995-12-01": {
    corporateOrSelf: 2.925,
    households: 6.014,
    corporate: 4.306,
    government: 0.265,
    municipalities: -6.334
  },
  "1996-03-01": {
    corporateOrSelf: 6.269,
    households: 8.818,
    corporate: 5.522,
    government: 6.096,
    municipalities: -2.046
  },
  "1996-06-01": {
    corporateOrSelf: 6.176,
    households: 7.138,
    corporate: 6.9,
    government: 6.129,
    municipalities: -1.332
  },
  "1996-09-01": {
    corporateOrSelf: 4.632,
    households: 5.465,
    corporate: 5.716,
    government: 4.466,
    municipalities: -3.427
  },
  "1996-12-01": {
    corporateOrSelf: 3.479,
    households: 6.027,
    corporate: 3.537,
    government: 1.427,
    municipalities: -1.099
  },
  "1997-03-01": {
    corporateOrSelf: 5.134,
    households: 6.597,
    corporate: 6.69,
    government: 2.455,
    municipalities: 2.066
  },
  "1997-06-01": {
    corporateOrSelf: 5.384,
    households: 6.104,
    corporate: 7.946,
    government: 2.39,
    municipalities: 2.936
  },
  "1997-09-01": {
    corporateOrSelf: 6.672,
    households: 7.14,
    corporate: 10.605,
    government: 1.821,
    municipalities: 7.05
  },
  "1997-12-01": {
    corporateOrSelf: 4.653,
    households: 5.734,
    corporate: 7.857,
    government: -0.848,
    municipalities: 7.404
  },
  "1998-03-01": {
    corporateOrSelf: 6.735,
    households: 7.723,
    corporate: 11.774,
    government: 0.107,
    municipalities: 5.802
  },
  "1998-06-01": {
    corporateOrSelf: 8.176,
    households: 8.568,
    corporate: 13.052,
    government: 2.507,
    municipalities: 6.541
  },
  "1998-09-01": {
    corporateOrSelf: 4.945,
    households: 6.14,
    corporate: 9.947,
    government: -2.687,
    municipalities: 5.692
  },
  "1998-12-01": {
    corporateOrSelf: 6.475,
    households: 8.811,
    corporate: 10.044,
    government: -0.946,
    municipalities: 6.222
  },
  "1999-03-01": {
    corporateOrSelf: 7.106,
    households: 9.265,
    corporate: 11.853,
    government: -1.356,
    municipalities: 5.441
  },
  "1999-06-01": {
    corporateOrSelf: 5.866,
    households: 9.104,
    corporate: 7.762,
    government: -0.12,
    municipalities: 1.811
  },
  "1999-09-01": {
    corporateOrSelf: 6.815,
    households: 8.605,
    corporate: 11.979,
    government: -2.005,
    municipalities: 4.742
  },
  "1999-12-01": {
    corporateOrSelf: 6.338,
    households: 9.301,
    corporate: 8.263,
    government: 0.741,
    municipalities: 0.898
  },
  "2000-03-01": {
    corporateOrSelf: 6.697,
    households: 11.176,
    corporate: 10.89,
    government: -4.607,
    municipalities: 1.608
  },
  "2000-06-01": {
    corporateOrSelf: 4.162,
    households: 7.705,
    corporate: 10.786,
    government: -9.274,
    municipalities: -2.23
  },
  "2000-09-01": {
    corporateOrSelf: 4.15,
    households: 8.818,
    corporate: 6.293,
    government: -6.72,
    municipalities: 3.66
  },
  "2000-12-01": {
    corporateOrSelf: 3.776,
    households: 6.485,
    corporate: 6.717,
    government: -5.199,
    municipalities: 2.674
  },
  "2001-03-01": {
    corporateOrSelf: 4.968,
    households: 6.188,
    corporate: 3.688,
    government: 3.216,
    municipalities: 10.571
  },
  "2001-06-01": {
    corporateOrSelf: 4.721,
    households: 9.544,
    corporate: 7.599,
    government: -8.985,
    municipalities: 6.111
  },
  "2001-09-01": {
    corporateOrSelf: 8.266,
    households: 12.876,
    corporate: 4.284,
    government: 6.609,
    municipalities: 7.213
  },
  "2001-12-01": {
    corporateOrSelf: 4.664,
    households: 4.876,
    corporate: 3.354,
    government: 4.721,
    municipalities: 10.231
  },
  "2002-03-01": {
    corporateOrSelf: 5.561,
    households: 9.717,
    corporate: 3.025,
    government: 1.083,
    municipalities: 7.979
  },
  "2002-06-01": {
    corporateOrSelf: 6.222,
    households: 7.502,
    corporate: 1.638,
    government: 9.999,
    municipalities: 10.564
  },
  "2002-09-01": {
    corporateOrSelf: 6.934,
    households: 9.583,
    corporate: 1.684,
    government: 9.137,
    municipalities: 10.857
  },
  "2002-12-01": {
    corporateOrSelf: 7.428,
    households: 11.121,
    corporate: 2.45,
    government: 6.393,
    municipalities: 13.246
  },
  "2003-03-01": {
    corporateOrSelf: 5.891,
    households: 10.454,
    corporate: 1.043,
    government: 4.136,
    municipalities: 7.461
  },
  "2003-06-01": {
    corporateOrSelf: 10.666,
    households: 16.116,
    corporate: 0.905,
    government: 14.799,
    municipalities: 11.946
  },
  "2003-09-01": {
    corporateOrSelf: 7.704,
    households: 10.486,
    corporate: 2.974,
    government: 9.833,
    municipalities: 6.262
  },
  "2003-12-01": {
    corporateOrSelf: 6.2,
    households: 10.115,
    corporate: -0.343,
    government: 8.03,
    municipalities: 6.664
  },
  "2004-03-01": {
    corporateOrSelf: 7.877,
    households: 9.813,
    corporate: 6.137,
    government: 5.279,
    municipalities: 11.763
  },
  "2004-06-01": {
    corporateOrSelf: 9.477,
    households: 11.718,
    corporate: 3.414,
    government: 13.788,
    municipalities: 9.678
  },
  "2004-09-01": {
    corporateOrSelf: 8.038,
    households: 9.369,
    corporate: 6.675,
    government: 7.254,
    municipalities: 8.141
  },
  "2004-12-01": {
    corporateOrSelf: 9.083,
    households: 13.481,
    corporate: 6.034,
    government: 5.985,
    municipalities: 5.922
  },
  "2005-03-01": {
    corporateOrSelf: 8.169,
    households: 8.931,
    corporate: 7.303,
    government: 7.772,
    municipalities: 8.291
  },
  "2005-06-01": {
    corporateOrSelf: 9.337,
    households: 11.597,
    corporate: 7.754,
    government: 7.995,
    municipalities: 7.02
  },
  "2005-09-01": {
    corporateOrSelf: 7.89,
    households: 10.944,
    corporate: 6.533,
    government: 4.05,
    municipalities: 6.511
  },
  "2005-12-01": {
    corporateOrSelf: 8.468,
    households: 9.894,
    corporate: 9.471,
    government: 6.012,
    municipalities: 4.143
  },
  "2006-03-01": {
    corporateOrSelf: 9.309,
    households: 11.537,
    corporate: 10.146,
    government: 7.596,
    municipalities: 0.174
  },
  "2006-06-01": {
    corporateOrSelf: 9.45,
    households: 11.699,
    corporate: 9.977,
    government: 5.537,
    municipalities: 5.574
  },
  "2006-09-01": {
    corporateOrSelf: 6.458,
    households: 9.223,
    corporate: 6.737,
    government: 0.276,
    municipalities: 5.613
  },
  "2006-12-01": {
    corporateOrSelf: 7.96,
    households: 8.447,
    corporate: 11.734,
    government: 2.101,
    municipalities: 5.915
  },
  "2007-03-01": {
    corporateOrSelf: 8.037,
    households: 5.975,
    corporate: 10.796,
    government: 8.378,
    municipalities: 8.343
  },
  "2007-06-01": {
    corporateOrSelf: 8.758,
    households: 9.359,
    corporate: 13.122,
    government: 0.928,
    municipalities: 7.995
  },
  "2007-09-01": {
    corporateOrSelf: 7.832,
    households: 6.42,
    corporate: 13.428,
    government: 3.708,
    municipalities: 4.62
  },
  "2007-12-01": {
    corporateOrSelf: 7.117,
    households: 6.09,
    corporate: 10.669,
    government: 5.34,
    municipalities: 3.73
  },
  "2008-03-01": {
    corporateOrSelf: 6.84,
    households: 4.934,
    corporate: 8.017,
    government: 11.112,
    municipalities: 3.277
  },
  "2008-06-01": {
    corporateOrSelf: 3.224,
    households: -0.999,
    corporate: 8.004,
    government: 5.43,
    municipalities: 2.58
  },
  "2008-09-01": {
    corporateOrSelf: 9.249,
    households: 4.135,
    corporate: 5.481,
    government: 31.201,
    municipalities: 0.781
  },
  "2008-12-01": {
    corporateOrSelf: 3.23,
    households: -8.11,
    corporate: 1.15,
    government: 32.569,
    municipalities: -1.048
  },
  "2009-03-01": {
    corporateOrSelf: 3.752,
    households: -0.614,
    corporate: -3.482,
    government: 22.269,
    municipalities: 4.74
  },
  "2009-06-01": {
    corporateOrSelf: 5.06,
    households: 0.836,
    corporate: -3.259,
    government: 23.879,
    municipalities: 5.589
  },
  "2009-09-01": {
    corporateOrSelf: 3.367,
    households: 0.638,
    corporate: -4.95,
    government: 18.699,
    municipalities: 3.255
  },
  "2009-12-01": {
    corporateOrSelf: 2.248,
    households: 0.868,
    corporate: -4.29,
    government: 11.32,
    municipalities: 5.182
  },
  "2010-03-01": {
    corporateOrSelf: 3.403,
    households: -2.094,
    corporate: -2.626,
    government: 19.118,
    municipalities: 3.123
  },
  "2010-06-01": {
    corporateOrSelf: 4.993,
    households: -0.269,
    corporate: -2.586,
    government: 22.625,
    municipalities: 0.774
  },
  "2010-09-01": {
    corporateOrSelf: 4.69,
    households: -1.354,
    corporate: 1.586,
    government: 17.45,
    municipalities: 1.586
  },
  "2010-12-01": {
    corporateOrSelf: 4.016,
    households: 1.291,
    corporate: 0.751,
    government: 10.365,
    municipalities: 5.804
  },
  "2011-03-01": {
    corporateOrSelf: 2.604,
    households: 1.827,
    corporate: -0.178,
    government: 7.908,
    municipalities: -2.692
  },
  "2011-06-01": {
    corporateOrSelf: 3.63,
    households: -0.294,
    corporate: 3.069,
    government: 11.117,
    municipalities: -2.68
  },
  "2011-09-01": {
    corporateOrSelf: 4.581,
    households: -1.599,
    corporate: 2.886,
    government: 15.078,
    municipalities: 0.458
  },
  "2011-12-01": {
    corporateOrSelf: 3.542,
    households: 0.575,
    corporate: 3.822,
    government: 7.551,
    municipalities: 1.037
  },
  "2012-03-01": {
    corporateOrSelf: 4.866,
    households: 1.093,
    corporate: 3.391,
    government: 11.9,
    municipalities: 0.152
  },
  "2012-06-01": {
    corporateOrSelf: 5.394,
    households: -0.223,
    corporate: 4.242,
    government: 13.558,
    municipalities: 2.587
  },
  "2012-09-01": {
    corporateOrSelf: 4.767,
    households: 0.736,
    corporate: 6.515,
    government: 8.941,
    municipalities: 0.108
  },
  "2012-12-01": {
    corporateOrSelf: 3.511,
    households: 0.521,
    corporate: 8.001,
    government: 4.68,
    municipalities: -3.252
  },
  "2013-03-01": {
    corporateOrSelf: 4.394,
    households: 1.383,
    corporate: 2.626,
    government: 10.133,
    municipalities: 0.195
  },
  "2013-06-01": {
    corporateOrSelf: 2.727,
    households: 0.89,
    corporate: 5.038,
    government: 3.9,
    municipalities: -2.128
  },
  "2013-09-01": {
    corporateOrSelf: 3.995,
    households: 1.608,
    corporate: 7.134,
    government: 5.289,
    municipalities: -2.045
  },
  "2013-12-01": {
    corporateOrSelf: 5.432,
    households: 5.469,
    corporate: 4.403,
    government: 6.752,
    municipalities: 3.297
  },
  "2014-03-01": {
    corporateOrSelf: 4.616,
    households: 1.66,
    corporate: 6.397,
    government: 7.31,
    municipalities: -0.424
  },
  "2014-06-01": {
    corporateOrSelf: 4.055,
    households: 2.606,
    corporate: 5.786,
    government: 5.45,
    municipalities: -1.981
  },
  "2014-09-01": {
    corporateOrSelf: 3.066,
    households: -1.152,
    corporate: 6.5,
    government: 6.818,
    municipalities: -7.699
  },
  "2014-12-01": {
    corporateOrSelf: 3.226,
    households: 1.585,
    corporate: 7.842,
    government: 1.507,
    municipalities: 0.912
  },
  "2015-03-01": {
    corporateOrSelf: 3.783,
    households: 1.728,
    corporate: 7.377,
    government: 3.126,
    municipalities: 2.092
  },
  "2015-06-01": {
    corporateOrSelf: 5.63,
    households: 3.545,
    corporate: 8.536,
    government: 6.297,
    municipalities: 0.451
  },
  "2015-09-01": {
    corporateOrSelf: 2.479,
    households: 0.643,
    corporate: 5.412,
    government: 2.143,
    municipalities: 0.582
  },
  "2015-12-01": {
    corporateOrSelf: 5.289,
    households: 3.046,
    corporate: 6.051,
    government: 8.165,
    municipalities: -1.294
  },
  "2016-03-01": {
    corporateOrSelf: 5.903,
    households: 2.241,
    corporate: 8.474,
    government: 8.075,
    municipalities: 1.351
  },
  "2016-06-01": {
    corporateOrSelf: 4.715,
    households: 3.461,
    corporate: 4.318,
    government: 6.7,
    municipalities: 2.305
  },
  "2016-09-01": {
    corporateOrSelf: 4.658,
    households: 3.981,
    corporate: 5.711,
    government: 5.16,
    municipalities: 0.788
  },
  "2016-12-01": {
    corporateOrSelf: 1.876,
    households: 2.606,
    corporate: 1.66,
    government: 1.868,
    municipalities: -0.509
  },
  "2017-03-01": {
    corporateOrSelf: 3.506,
    households: 3.611,
    corporate: 5.797,
    government: 2.585,
    municipalities: -2.165
  },
  "2017-06-01": {
    corporateOrSelf: 4.914,
    households: 3.952,
    corporate: 6.89,
    government: 5.147,
    municipalities: -0.476
  },
  "2017-09-01": {
    corporateOrSelf: 4.103,
    households: 2.67,
    corporate: 5.561,
    government: 5.033,
    municipalities: -0.487
  },
  "2017-12-01": {
    corporateOrSelf: 4.033,
    households: 5.197,
    corporate: 5.372,
    government: 1.999,
    municipalities: 3.098
  },
  "2018-03-01": {
    corporateOrSelf: 6.994,
    households: 3.239,
    corporate: 4.599,
    government: 14.364,
    municipalities: -2.715
  },
  "2018-06-01": {
    corporateOrSelf: 4.255,
    households: 3.269,
    corporate: 3.35,
    government: 6.655,
    municipalities: 0.137
  },
  "2018-09-01": {
    corporateOrSelf: 3.96,
    households: 3.131,
    corporate: 5.01,
    government: 4.442,
    municipalities: 0.258
  },
  "2018-12-01": {
    corporateOrSelf: 3.404,
    households: 2.638,
    corporate: 4.602,
    government: 4.1,
    municipalities: -2.595
  },
  "2019-03-01": {
    corporateOrSelf: 5.781,
    households: 2.111,
    corporate: 6.735,
    government: 9.557,
    municipalities: -2.23
  },
  "2019-06-01": {
    corporateOrSelf: 3.494,
    households: 4.195,
    corporate: 4.23,
    government: 3.145,
    municipalities: -1.734
  },
  "2019-09-01": {
    corporateOrSelf: 5.807,
    households: 3.186,
    corporate: 5.851,
    government: 8.863,
    municipalities: 0.783
  },
  "2019-12-01": {
    corporateOrSelf: 3.33,
    households: 3.506,
    corporate: 2.252,
    government: 4.154,
    municipalities: 3.042
  },
  "2020-03-01": {
    corporateOrSelf: 11.665,
    households: 3.569,
    corporate: 18.883,
    government: 14.089,
    municipalities: 0.967
  },
  "2020-06-01": {
    corporateOrSelf: 26.737,
    households: -0.102,
    corporate: 15.22,
    government: 62.502,
    municipalities: 3.528
  },
  "2020-09-01": {
    corporateOrSelf: 4.133,
    households: 5.551,
    corporate: 0.131,
    government: 6.032,
    municipalities: 5.571
  },
  "2020-12-01": {
    corporateOrSelf: 5.101,
    households: 6.08,
    corporate: 0.497,
    government: 8.42,
    municipalities: 1.539
  },
  "2021-03-01": {
    corporateOrSelf: 6.8,
    households: 6.711,
    corporate: 4.585,
    government: 8.985,
    municipalities: 3.415
  },
  "2021-06-01": {
    corporateOrSelf: 6.517,
    households: 7.736,
    corporate: 1.834,
    government: 9.585,
    municipalities: 3.181
  },
  "2021-09-01": {
    corporateOrSelf: 2.609,
    households: 6.162,
    corporate: 4.728,
    government: -1.323,
    municipalities: 1.855
  },
  "2021-12-01": {
    corporateOrSelf: 8.24,
    households: 7.978,
    corporate: 6.668,
    government: 10.804,
    municipalities: -0.893
  }
};

export const loansofdebtoutstanding = {
  "1952-03-01": {
    corporate: 79983,
    selfemployed: 25446,
    selfemployedNotes: 4707,
    selfemployedMortgages: 16343,
    selfemployedOther: 4396,
    corporateBonds: 40873,
    corporateDepositaryLoansExmortgage: 22234,
    corporateMortgages: 13385,
    corporateCommercialPaper: 289,
    CorporateOther: 3202
  },
  "1952-06-01": {
    corporate: 81411,
    selfemployed: 26301,
    selfemployedNotes: 4831,
    selfemployedMortgages: 17030,
    selfemployedOther: 4440,
    corporateBonds: 42343,
    corporateDepositaryLoansExmortgage: 22169,
    corporateMortgages: 13260,
    corporateCommercialPaper: 328,
    CorporateOther: 3311
  },
  "1952-09-01": {
    corporate: 83193,
    selfemployed: 26925,
    selfemployedNotes: 4895,
    selfemployedMortgages: 17548,
    selfemployedOther: 4482,
    corporateBonds: 43470,
    corporateDepositaryLoansExmortgage: 22552,
    corporateMortgages: 13251,
    corporateCommercialPaper: 335,
    CorporateOther: 3585
  },
  "1952-12-01": {
    corporate: 85098,
    selfemployed: 27439,
    selfemployedNotes: 4944,
    selfemployedMortgages: 17814,
    selfemployedOther: 4681,
    corporateBonds: 44497,
    corporateDepositaryLoansExmortgage: 22979,
    corporateMortgages: 13464,
    corporateCommercialPaper: 331,
    CorporateOther: 3827
  },
  "1953-03-01": {
    corporate: 86711,
    selfemployed: 27586,
    selfemployedNotes: 4924,
    selfemployedMortgages: 17974,
    selfemployedOther: 4687,
    corporateBonds: 45257,
    corporateDepositaryLoansExmortgage: 23388,
    corporateMortgages: 13756,
    corporateCommercialPaper: 334,
    CorporateOther: 3976
  },
  "1953-06-01": {
    corporate: 87961,
    selfemployed: 28144,
    selfemployedNotes: 4876,
    selfemployedMortgages: 18609,
    selfemployedOther: 4659,
    corporateBonds: 46270,
    corporateDepositaryLoansExmortgage: 23352,
    corporateMortgages: 13696,
    corporateCommercialPaper: 324,
    CorporateOther: 4319
  },
  "1953-09-01": {
    corporate: 89017,
    selfemployed: 28736,
    selfemployedNotes: 5040,
    selfemployedMortgages: 19002,
    selfemployedOther: 4694,
    corporateBonds: 46800,
    corporateDepositaryLoansExmortgage: 23598,
    corporateMortgages: 13761,
    corporateCommercialPaper: 334,
    CorporateOther: 4524
  },
  "1953-12-01": {
    corporate: 89252,
    selfemployed: 28357,
    selfemployedNotes: 4434,
    selfemployedMortgages: 19148,
    selfemployedOther: 4775,
    corporateBonds: 48012,
    corporateDepositaryLoansExmortgage: 22417,
    corporateMortgages: 14142,
    corporateCommercialPaper: 402,
    CorporateOther: 4279
  },
  "1954-03-01": {
    corporate: 90351,
    selfemployed: 28786,
    selfemployedNotes: 4613,
    selfemployedMortgages: 19328,
    selfemployedOther: 4846,
    corporateBonds: 48789,
    corporateDepositaryLoansExmortgage: 22398,
    corporateMortgages: 14418,
    corporateCommercialPaper: 495,
    CorporateOther: 4251
  },
  "1954-06-01": {
    corporate: 90725,
    selfemployed: 29588,
    selfemployedNotes: 4632,
    selfemployedMortgages: 20145,
    selfemployedOther: 4811,
    corporateBonds: 49724,
    corporateDepositaryLoansExmortgage: 22054,
    corporateMortgages: 14285,
    corporateCommercialPaper: 517,
    CorporateOther: 4145
  },
  "1954-09-01": {
    corporate: 91873,
    selfemployed: 30609,
    selfemployedNotes: 4903,
    selfemployedMortgages: 20808,
    selfemployedOther: 4898,
    corporateBonds: 50999,
    corporateDepositaryLoansExmortgage: 21875,
    corporateMortgages: 14216,
    corporateCommercialPaper: 574,
    CorporateOther: 4209
  },
  "1954-12-01": {
    corporate: 92843,
    selfemployed: 30915,
    selfemployedNotes: 4661,
    selfemployedMortgages: 21176,
    selfemployedOther: 5078,
    corporateBonds: 51547,
    corporateDepositaryLoansExmortgage: 21761,
    corporateMortgages: 14636,
    corporateCommercialPaper: 531,
    CorporateOther: 4368
  },
  "1955-03-01": {
    corporate: 93897,
    selfemployed: 31588,
    selfemployedNotes: 4732,
    selfemployedMortgages: 21643,
    selfemployedOther: 5213,
    corporateBonds: 52055,
    corporateDepositaryLoansExmortgage: 22441,
    corporateMortgages: 14935,
    corporateCommercialPaper: 483,
    CorporateOther: 3983
  },
  "1955-06-01": {
    corporate: 95914,
    selfemployed: 32964,
    selfemployedNotes: 5136,
    selfemployedMortgages: 22536,
    selfemployedOther: 5293,
    corporateBonds: 52779,
    corporateDepositaryLoansExmortgage: 23441,
    corporateMortgages: 15128,
    corporateCommercialPaper: 416,
    CorporateOther: 4150
  },
  "1955-09-01": {
    corporate: 97630,
    selfemployed: 34176,
    selfemployedNotes: 5482,
    selfemployedMortgages: 23247,
    selfemployedOther: 5446,
    corporateBonds: 53253,
    corporateDepositaryLoansExmortgage: 24440,
    corporateMortgages: 15355,
    corporateCommercialPaper: 360,
    CorporateOther: 4222
  },
  "1955-12-01": {
    corporate: 101216,
    selfemployed: 34838,
    selfemployedNotes: 5737,
    selfemployedMortgages: 23526,
    selfemployedOther: 5575,
    corporateBonds: 54564,
    corporateDepositaryLoansExmortgage: 25662,
    corporateMortgages: 16195,
    corporateCommercialPaper: 362,
    CorporateOther: 4433
  },
  "1956-03-01": {
    corporate: 103790,
    selfemployed: 35433,
    selfemployedNotes: 5832,
    selfemployedMortgages: 24036,
    selfemployedOther: 5565,
    corporateBonds: 54986,
    corporateDepositaryLoansExmortgage: 27469,
    corporateMortgages: 16428,
    corporateCommercialPaper: 352,
    CorporateOther: 4555
  },
  "1956-06-01": {
    corporate: 106470,
    selfemployed: 36531,
    selfemployedNotes: 6026,
    selfemployedMortgages: 24898,
    selfemployedOther: 5606,
    corporateBonds: 55946,
    corporateDepositaryLoansExmortgage: 29178,
    corporateMortgages: 16531,
    corporateCommercialPaper: 323,
    CorporateOther: 4492
  },
  "1956-09-01": {
    corporate: 108573,
    selfemployed: 37559,
    selfemployedNotes: 6343,
    selfemployedMortgages: 25529,
    selfemployedOther: 5686,
    corporateBonds: 57013,
    corporateDepositaryLoansExmortgage: 29999,
    corporateMortgages: 16614,
    corporateCommercialPaper: 383,
    CorporateOther: 4564
  },
  "1956-12-01": {
    corporate: 110961,
    selfemployed: 37929,
    selfemployedNotes: 6279,
    selfemployedMortgages: 25715,
    selfemployedOther: 5935,
    corporateBonds: 58040,
    corporateDepositaryLoansExmortgage: 30747,
    corporateMortgages: 17126,
    corporateCommercialPaper: 378,
    CorporateOther: 4670
  },
  "1957-03-01": {
    corporate: 113755,
    selfemployed: 38221,
    selfemployedNotes: 6276,
    selfemployedMortgages: 25933,
    selfemployedOther: 6012,
    corporateBonds: 59510,
    corporateDepositaryLoansExmortgage: 31286,
    corporateMortgages: 17628,
    corporateCommercialPaper: 344,
    CorporateOther: 4987
  },
  "1957-06-01": {
    corporate: 116775,
    selfemployed: 38919,
    selfemployedNotes: 6361,
    selfemployedMortgages: 26587,
    selfemployedOther: 5971,
    corporateBonds: 60975,
    corporateDepositaryLoansExmortgage: 32699,
    corporateMortgages: 17697,
    corporateCommercialPaper: 343,
    CorporateOther: 5061
  },
  "1957-09-01": {
    corporate: 118733,
    selfemployed: 39712,
    selfemployedNotes: 6415,
    selfemployedMortgages: 27228,
    selfemployedOther: 6069,
    corporateBonds: 62488,
    corporateDepositaryLoansExmortgage: 32571,
    corporateMortgages: 17806,
    corporateCommercialPaper: 324,
    CorporateOther: 5544
  },
  "1957-12-01": {
    corporate: 120632,
    selfemployed: 40378,
    selfemployedNotes: 6732,
    selfemployedMortgages: 27478,
    selfemployedOther: 6168,
    corporateBonds: 64072,
    corporateDepositaryLoansExmortgage: 31971,
    corporateMortgages: 18437,
    corporateCommercialPaper: 422,
    CorporateOther: 5730
  },
  "1958-03-01": {
    corporate: 122618,
    selfemployed: 40799,
    selfemployedNotes: 6654,
    selfemployedMortgages: 27849,
    selfemployedOther: 6296,
    corporateBonds: 65937,
    corporateDepositaryLoansExmortgage: 31308,
    corporateMortgages: 18711,
    corporateCommercialPaper: 687,
    CorporateOther: 5975
  },
  "1958-06-01": {
    corporate: 123711,
    selfemployed: 41782,
    selfemployedNotes: 6857,
    selfemployedMortgages: 28626,
    selfemployedOther: 6299,
    corporateBonds: 67017,
    corporateDepositaryLoansExmortgage: 31316,
    corporateMortgages: 18935,
    corporateCommercialPaper: 800,
    CorporateOther: 5643
  },
  "1958-09-01": {
    corporate: 125300,
    selfemployed: 42930,
    selfemployedNotes: 6919,
    selfemployedMortgages: 29599,
    selfemployedOther: 6412,
    corporateBonds: 68463,
    corporateDepositaryLoansExmortgage: 31355,
    corporateMortgages: 19211,
    corporateCommercialPaper: 732,
    CorporateOther: 5539
  },
  "1958-12-01": {
    corporate: 127965,
    selfemployed: 44180,
    selfemployedNotes: 7288,
    selfemployedMortgages: 30306,
    selfemployedOther: 6586,
    corporateBonds: 69715,
    corporateDepositaryLoansExmortgage: 31650,
    corporateMortgages: 20184,
    corporateCommercialPaper: 658,
    CorporateOther: 5758
  },
  "1959-03-01": {
    corporate: 129227,
    selfemployed: 45593,
    selfemployedNotes: 7410,
    selfemployedMortgages: 31345,
    selfemployedOther: 6837,
    corporateBonds: 70307,
    corporateDepositaryLoansExmortgage: 32075,
    corporateMortgages: 20206,
    corporateCommercialPaper: 605,
    CorporateOther: 6034
  },
  "1959-06-01": {
    corporate: 132391,
    selfemployed: 47546,
    selfemployedNotes: 7733,
    selfemployedMortgages: 32829,
    selfemployedOther: 6985,
    corporateBonds: 71169,
    corporateDepositaryLoansExmortgage: 34103,
    corporateMortgages: 20235,
    corporateCommercialPaper: 456,
    CorporateOther: 6428
  },
  "1959-09-01": {
    corporate: 134054,
    selfemployed: 49224,
    selfemployedNotes: 8037,
    selfemployedMortgages: 34114,
    selfemployedOther: 7072,
    corporateBonds: 71737,
    corporateDepositaryLoansExmortgage: 35042,
    corporateMortgages: 20208,
    corporateCommercialPaper: 434,
    CorporateOther: 6633
  },
  "1959-12-01": {
    corporate: 136645,
    selfemployed: 50249,
    selfemployedNotes: 8343,
    selfemployedMortgages: 34800,
    selfemployedOther: 7106,
    corporateBonds: 72794,
    corporateDepositaryLoansExmortgage: 35439,
    corporateMortgages: 21009,
    corporateCommercialPaper: 315,
    CorporateOther: 7088
  },
  "1960-03-01": {
    corporate: 139269,
    selfemployed: 51351,
    selfemployedNotes: 8480,
    selfemployedMortgages: 35638,
    selfemployedOther: 7233,
    corporateBonds: 73216,
    corporateDepositaryLoansExmortgage: 36775,
    corporateMortgages: 20971,
    corporateCommercialPaper: 356,
    CorporateOther: 7951
  },
  "1960-06-01": {
    corporate: 141908,
    selfemployed: 52691,
    selfemployedNotes: 8579,
    selfemployedMortgages: 36854,
    selfemployedOther: 7257,
    corporateBonds: 74107,
    corporateDepositaryLoansExmortgage: 37611,
    corporateMortgages: 21080,
    corporateCommercialPaper: 548,
    CorporateOther: 8562
  },
  "1960-09-01": {
    corporate: 143163,
    selfemployed: 53962,
    selfemployedNotes: 8631,
    selfemployedMortgages: 37982,
    selfemployedOther: 7349,
    corporateBonds: 74889,
    corporateDepositaryLoansExmortgage: 37653,
    corporateMortgages: 21305,
    corporateCommercialPaper: 671,
    CorporateOther: 8645
  },
  "1960-12-01": {
    corporate: 145592,
    selfemployed: 55024,
    selfemployedNotes: 8858,
    selfemployedMortgages: 38742,
    selfemployedOther: 7424,
    corporateBonds: 76216,
    corporateDepositaryLoansExmortgage: 37651,
    corporateMortgages: 21825,
    corporateCommercialPaper: 769,
    CorporateOther: 9131
  },
  "1961-03-01": {
    corporate: 146194,
    selfemployed: 56812,
    selfemployedNotes: 8968,
    selfemployedMortgages: 40225,
    selfemployedOther: 7619,
    corporateBonds: 76577,
    corporateDepositaryLoansExmortgage: 38570,
    corporateMortgages: 21744,
    corporateCommercialPaper: 548,
    CorporateOther: 8755
  },
  "1961-06-01": {
    corporate: 148192,
    selfemployed: 58165,
    selfemployedNotes: 8927,
    selfemployedMortgages: 41704,
    selfemployedOther: 7534,
    corporateBonds: 78530,
    corporateDepositaryLoansExmortgage: 38092,
    corporateMortgages: 22031,
    corporateCommercialPaper: 740,
    CorporateOther: 8799
  },
  "1961-09-01": {
    corporate: 149680,
    selfemployed: 60194,
    selfemployedNotes: 9145,
    selfemployedMortgages: 43325,
    selfemployedOther: 7724,
    corporateBonds: 79237,
    corporateDepositaryLoansExmortgage: 38319,
    corporateMortgages: 22266,
    corporateCommercialPaper: 892,
    CorporateOther: 8966
  },
  "1961-12-01": {
    corporate: 152987,
    selfemployed: 61717,
    selfemployedNotes: 9459,
    selfemployedMortgages: 44478,
    selfemployedOther: 7780,
    corporateBonds: 80611,
    corporateDepositaryLoansExmortgage: 38585,
    corporateMortgages: 23243,
    corporateCommercialPaper: 1094,
    CorporateOther: 9454
  },
  "1962-03-01": {
    corporate: 154533,
    selfemployed: 63404,
    selfemployedNotes: 9596,
    selfemployedMortgages: 45796,
    selfemployedOther: 8012,
    corporateBonds: 81437,
    corporateDepositaryLoansExmortgage: 39123,
    corporateMortgages: 23576,
    corporateCommercialPaper: 1055,
    CorporateOther: 9342
  },
  "1962-06-01": {
    corporate: 157690,
    selfemployed: 65383,
    selfemployedNotes: 9847,
    selfemployedMortgages: 47513,
    selfemployedOther: 8023,
    corporateBonds: 82981,
    corporateDepositaryLoansExmortgage: 40189,
    corporateMortgages: 23993,
    corporateCommercialPaper: 1124,
    CorporateOther: 9403
  },
  "1962-09-01": {
    corporate: 160110,
    selfemployed: 67274,
    selfemployedNotes: 10084,
    selfemployedMortgages: 49060,
    selfemployedOther: 8130,
    corporateBonds: 83558,
    corporateDepositaryLoansExmortgage: 41282,
    corporateMortgages: 24488,
    corporateCommercialPaper: 1240,
    CorporateOther: 9542
  },
  "1962-12-01": {
    corporate: 163466,
    selfemployed: 69302,
    selfemployedNotes: 10573,
    selfemployedMortgages: 50491,
    selfemployedOther: 8238,
    corporateBonds: 84643,
    corporateDepositaryLoansExmortgage: 42226,
    corporateMortgages: 25563,
    corporateCommercialPaper: 1198,
    CorporateOther: 9836
  },
  "1963-03-01": {
    corporate: 165589,
    selfemployed: 71070,
    selfemployedNotes: 10602,
    selfemployedMortgages: 51836,
    selfemployedOther: 8632,
    corporateBonds: 85579,
    corporateDepositaryLoansExmortgage: 42848,
    corporateMortgages: 25950,
    corporateCommercialPaper: 1158,
    CorporateOther: 10054
  },
  "1963-06-01": {
    corporate: 167940,
    selfemployed: 73426,
    selfemployedNotes: 10910,
    selfemployedMortgages: 53879,
    selfemployedOther: 8637,
    corporateBonds: 86695,
    corporateDepositaryLoansExmortgage: 43403,
    corporateMortgages: 26527,
    corporateCommercialPaper: 1030,
    CorporateOther: 10285
  },
  "1963-09-01": {
    corporate: 170148,
    selfemployed: 75562,
    selfemployedNotes: 11113,
    selfemployedMortgages: 55671,
    selfemployedOther: 8778,
    corporateBonds: 87225,
    corporateDepositaryLoansExmortgage: 44298,
    corporateMortgages: 27162,
    corporateCommercialPaper: 976,
    CorporateOther: 10487
  },
  "1963-12-01": {
    corporate: 174184,
    selfemployed: 77972,
    selfemployedNotes: 11731,
    selfemployedMortgages: 57179,
    selfemployedOther: 9063,
    corporateBonds: 88414,
    corporateDepositaryLoansExmortgage: 45623,
    corporateMortgages: 28236,
    corporateCommercialPaper: 968,
    CorporateOther: 10943
  },
  "1964-03-01": {
    corporate: 176585,
    selfemployed: 79657,
    selfemployedNotes: 11860,
    selfemployedMortgages: 58397,
    selfemployedOther: 9400,
    corporateBonds: 89091,
    corporateDepositaryLoansExmortgage: 46209,
    corporateMortgages: 28826,
    corporateCommercialPaper: 882,
    CorporateOther: 11577
  },
  "1964-06-01": {
    corporate: 179976,
    selfemployed: 82055,
    selfemployedNotes: 12073,
    selfemployedMortgages: 60461,
    selfemployedOther: 9521,
    corporateBonds: 90362,
    corporateDepositaryLoansExmortgage: 47208,
    corporateMortgages: 29346,
    corporateCommercialPaper: 917,
    CorporateOther: 12143
  },
  "1964-09-01": {
    corporate: 183446,
    selfemployed: 84341,
    selfemployedNotes: 12309,
    selfemployedMortgages: 62330,
    selfemployedOther: 9703,
    corporateBonds: 91058,
    corporateDepositaryLoansExmortgage: 48697,
    corporateMortgages: 30170,
    corporateCommercialPaper: 914,
    CorporateOther: 12607
  },
  "1964-12-01": {
    corporate: 187925,
    selfemployed: 86267,
    selfemployedNotes: 12477,
    selfemployedMortgages: 63886,
    selfemployedOther: 9904,
    corporateBonds: 92408,
    corporateDepositaryLoansExmortgage: 50264,
    corporateMortgages: 31443,
    corporateCommercialPaper: 1125,
    CorporateOther: 12685
  },
  "1965-03-01": {
    corporate: 193186,
    selfemployed: 88197,
    selfemployedNotes: 12903,
    selfemployedMortgages: 65087,
    selfemployedOther: 10208,
    corporateBonds: 93342,
    corporateDepositaryLoansExmortgage: 53724,
    corporateMortgages: 32181,
    corporateCommercialPaper: 746,
    CorporateOther: 13193
  },
  "1965-06-01": {
    corporate: 197972,
    selfemployed: 90876,
    selfemployedNotes: 13271,
    selfemployedMortgages: 67287,
    selfemployedOther: 10318,
    corporateBonds: 94941,
    corporateDepositaryLoansExmortgage: 55882,
    corporateMortgages: 32609,
    corporateCommercialPaper: 700,
    CorporateOther: 13840
  },
  "1965-09-01": {
    corporate: 202867,
    selfemployed: 93314,
    selfemployedNotes: 13599,
    selfemployedMortgages: 69263,
    selfemployedOther: 10451,
    corporateBonds: 96634,
    corporateDepositaryLoansExmortgage: 58284,
    corporateMortgages: 33139,
    corporateCommercialPaper: 706,
    CorporateOther: 14104
  },
  "1965-12-01": {
    corporate: 208193,
    selfemployed: 95594,
    selfemployedNotes: 14046,
    selfemployedMortgages: 70853,
    selfemployedOther: 10695,
    corporateBonds: 97260,
    corporateDepositaryLoansExmortgage: 61141,
    corporateMortgages: 34101,
    corporateCommercialPaper: 797,
    CorporateOther: 14894
  },
  "1966-03-01": {
    corporate: 214562,
    selfemployed: 98787,
    selfemployedNotes: 14587,
    selfemployedMortgages: 72943,
    selfemployedOther: 11256,
    corporateBonds: 100223,
    corporateDepositaryLoansExmortgage: 63528,
    corporateMortgages: 34688,
    corporateCommercialPaper: 643,
    CorporateOther: 15480
  },
  "1966-06-01": {
    corporate: 222357,
    selfemployed: 101512,
    selfemployedNotes: 15288,
    selfemployedMortgages: 74938,
    selfemployedOther: 11286,
    corporateBonds: 102790,
    corporateDepositaryLoansExmortgage: 66966,
    corporateMortgages: 35819,
    corporateCommercialPaper: 771,
    CorporateOther: 16011
  },
  "1966-09-01": {
    corporate: 228547,
    selfemployed: 103324,
    selfemployedNotes: 15456,
    selfemployedMortgages: 76413,
    selfemployedOther: 11455,
    corporateBonds: 105223,
    corporateDepositaryLoansExmortgage: 69646,
    corporateMortgages: 36783,
    corporateCommercialPaper: 1178,
    CorporateOther: 15717
  },
  "1966-12-01": {
    corporate: 232924,
    selfemployed: 105278,
    selfemployedNotes: 16296,
    selfemployedMortgages: 77361,
    selfemployedOther: 11621,
    corporateBonds: 107474,
    corporateDepositaryLoansExmortgage: 69715,
    corporateMortgages: 37691,
    corporateCommercialPaper: 1622,
    CorporateOther: 16422
  },
  "1967-03-01": {
    corporate: 239687,
    selfemployed: 106876,
    selfemployedNotes: 16394,
    selfemployedMortgages: 78441,
    selfemployedOther: 12041,
    corporateBonds: 110617,
    corporateDepositaryLoansExmortgage: 71735,
    corporateMortgages: 38277,
    corporateCommercialPaper: 2425,
    CorporateOther: 16633
  },
  "1967-06-01": {
    corporate: 246045,
    selfemployed: 109828,
    selfemployedNotes: 16892,
    selfemployedMortgages: 80769,
    selfemployedOther: 12167,
    corporateBonds: 114236,
    corporateDepositaryLoansExmortgage: 73714,
    corporateMortgages: 38646,
    corporateCommercialPaper: 3091,
    CorporateOther: 16358
  },
  "1967-09-01": {
    corporate: 251640,
    selfemployed: 112438,
    selfemployedNotes: 16992,
    selfemployedMortgages: 83140,
    selfemployedOther: 12306,
    corporateBonds: 118745,
    corporateDepositaryLoansExmortgage: 74595,
    corporateMortgages: 38941,
    corporateCommercialPaper: 2906,
    CorporateOther: 16453
  },
  "1967-12-01": {
    corporate: 255245,
    selfemployed: 115611,
    selfemployedNotes: 17657,
    selfemployedMortgages: 85175,
    selfemployedOther: 12779,
    corporateBonds: 122132,
    corporateDepositaryLoansExmortgage: 76269,
    corporateMortgages: 37235,
    corporateCommercialPaper: 3034,
    CorporateOther: 16575
  },
  "1968-03-01": {
    corporate: 263575,
    selfemployed: 117247,
    selfemployedNotes: 17773,
    selfemployedMortgages: 86430,
    selfemployedOther: 13044,
    corporateBonds: 124734,
    corporateDepositaryLoansExmortgage: 77683,
    corporateMortgages: 40657,
    corporateCommercialPaper: 3159,
    CorporateOther: 17342
  },
  "1968-06-01": {
    corporate: 270242,
    selfemployed: 120662,
    selfemployedNotes: 18398,
    selfemployedMortgages: 89129,
    selfemployedOther: 13134,
    corporateBonds: 128262,
    corporateDepositaryLoansExmortgage: 80129,
    corporateMortgages: 40874,
    corporateCommercialPaper: 3208,
    CorporateOther: 17769
  },
  "1968-09-01": {
    corporate: 277803,
    selfemployed: 123268,
    selfemployedNotes: 18490,
    selfemployedMortgages: 91517,
    selfemployedOther: 13261,
    corporateBonds: 131563,
    corporateDepositaryLoansExmortgage: 82460,
    corporateMortgages: 41400,
    corporateCommercialPaper: 4510,
    CorporateOther: 17870
  },
  "1968-12-01": {
    corporate: 286074,
    selfemployed: 126582,
    selfemployedNotes: 19120,
    selfemployedMortgages: 93888,
    selfemployedOther: 13573,
    corporateBonds: 135025,
    corporateDepositaryLoansExmortgage: 86106,
    corporateMortgages: 42399,
    corporateCommercialPaper: 4249,
    CorporateOther: 18295
  },
  "1969-03-01": {
    corporate: 294256,
    selfemployed: 129368,
    selfemployedNotes: 19856,
    selfemployedMortgages: 95515,
    selfemployedOther: 13997,
    corporateBonds: 138462,
    corporateDepositaryLoansExmortgage: 89062,
    corporateMortgages: 43212,
    corporateCommercialPaper: 4517,
    CorporateOther: 19003
  },
  "1969-06-01": {
    corporate: 303762,
    selfemployed: 134045,
    selfemployedNotes: 20658,
    selfemployedMortgages: 99044,
    selfemployedOther: 14343,
    corporateBonds: 141743,
    corporateDepositaryLoansExmortgage: 92201,
    corporateMortgages: 42775,
    corporateCommercialPaper: 4692,
    CorporateOther: 22351
  },
  "1969-09-01": {
    corporate: 312057,
    selfemployed: 138182,
    selfemployedNotes: 21394,
    selfemployedMortgages: 102116,
    selfemployedOther: 14672,
    corporateBonds: 144467,
    corporateDepositaryLoansExmortgage: 93481,
    corporateMortgages: 42572,
    corporateCommercialPaper: 5334,
    CorporateOther: 26203
  },
  "1969-12-01": {
    corporate: 318879,
    selfemployed: 141905,
    selfemployedNotes: 21874,
    selfemployedMortgages: 104907,
    selfemployedOther: 15125,
    corporateBonds: 147000,
    corporateDepositaryLoansExmortgage: 95655,
    corporateMortgages: 43292,
    corporateCommercialPaper: 5356,
    CorporateOther: 27576
  },
  "1970-03-01": {
    corporate: 338845,
    selfemployed: 134905,
    selfemployedNotes: 21859,
    selfemployedMortgages: 97384,
    selfemployedOther: 15661,
    corporateBonds: 150069,
    corporateDepositaryLoansExmortgage: 95559,
    corporateMortgages: 56458,
    corporateCommercialPaper: 6341,
    CorporateOther: 30418
  },
  "1970-06-01": {
    corporate: 349440,
    selfemployed: 139263,
    selfemployedNotes: 22178,
    selfemployedMortgages: 101120,
    selfemployedOther: 15965,
    corporateBonds: 155797,
    corporateDepositaryLoansExmortgage: 97340,
    corporateMortgages: 57300,
    corporateCommercialPaper: 6258,
    CorporateOther: 32745
  },
  "1970-09-01": {
    corporate: 357036,
    selfemployed: 144061,
    selfemployedNotes: 22142,
    selfemployedMortgages: 105704,
    selfemployedOther: 16215,
    corporateBonds: 161157,
    corporateDepositaryLoansExmortgage: 100537,
    corporateMortgages: 58499,
    corporateCommercialPaper: 6374,
    CorporateOther: 30469
  },
  "1970-12-01": {
    corporate: 363384,
    selfemployed: 148557,
    selfemployedNotes: 22287,
    selfemployedMortgages: 109636,
    selfemployedOther: 16635,
    corporateBonds: 166756,
    corporateDepositaryLoansExmortgage: 101697,
    corporateMortgages: 59762,
    corporateCommercialPaper: 7133,
    CorporateOther: 28036
  },
  "1971-03-01": {
    corporate: 370450,
    selfemployed: 152549,
    selfemployedNotes: 22865,
    selfemployedMortgages: 112435,
    selfemployedOther: 17249,
    corporateBonds: 172222,
    corporateDepositaryLoansExmortgage: 101652,
    corporateMortgages: 60905,
    corporateCommercialPaper: 7677,
    CorporateOther: 27994
  },
  "1971-06-01": {
    corporate: 378234,
    selfemployed: 159613,
    selfemployedNotes: 23502,
    selfemployedMortgages: 118555,
    selfemployedOther: 17556,
    corporateBonds: 177621,
    corporateDepositaryLoansExmortgage: 103119,
    corporateMortgages: 62021,
    corporateCommercialPaper: 6246,
    CorporateOther: 29227
  },
  "1971-09-01": {
    corporate: 386805,
    selfemployed: 165084,
    selfemployedNotes: 23517,
    selfemployedMortgages: 124028,
    selfemployedOther: 17540,
    corporateBonds: 181204,
    corporateDepositaryLoansExmortgage: 106349,
    corporateMortgages: 62941,
    corporateCommercialPaper: 6384,
    CorporateOther: 29927
  },
  "1971-12-01": {
    corporate: 392236,
    selfemployed: 171162,
    selfemployedNotes: 24461,
    selfemployedMortgages: 128891,
    selfemployedOther: 17809,
    corporateBonds: 185563,
    corporateDepositaryLoansExmortgage: 106157,
    corporateMortgages: 64936,
    corporateCommercialPaper: 6247,
    CorporateOther: 29333
  },
  "1972-03-01": {
    corporate: 403333,
    selfemployed: 175943,
    selfemployedNotes: 24960,
    selfemployedMortgages: 132687,
    selfemployedOther: 18297,
    corporateBonds: 188338,
    corporateDepositaryLoansExmortgage: 109082,
    corporateMortgages: 68012,
    corporateCommercialPaper: 7509,
    CorporateOther: 30392
  },
  "1972-06-01": {
    corporate: 411260,
    selfemployed: 185445,
    selfemployedNotes: 25983,
    selfemployedMortgages: 141093,
    selfemployedOther: 18368,
    corporateBonds: 192150,
    corporateDepositaryLoansExmortgage: 110586,
    corporateMortgages: 69436,
    corporateCommercialPaper: 8220,
    CorporateOther: 30868
  },
  "1972-09-01": {
    corporate: 420540,
    selfemployed: 192676,
    selfemployedNotes: 25902,
    selfemployedMortgages: 148145,
    selfemployedOther: 18629,
    corporateBonds: 195187,
    corporateDepositaryLoansExmortgage: 114881,
    corporateMortgages: 71312,
    corporateCommercialPaper: 7469,
    CorporateOther: 31691
  },
  "1972-12-01": {
    corporate: 431815,
    selfemployed: 202550,
    selfemployedNotes: 27222,
    selfemployedMortgages: 156321,
    selfemployedOther: 19007,
    corporateBonds: 197750,
    corporateDepositaryLoansExmortgage: 119174,
    corporateMortgages: 74173,
    corporateCommercialPaper: 6968,
    CorporateOther: 33750
  },
  "1973-03-01": {
    corporate: 446413,
    selfemployed: 213028,
    selfemployedNotes: 30767,
    selfemployedMortgages: 161240,
    selfemployedOther: 21021,
    corporateBonds: 199202,
    corporateDepositaryLoansExmortgage: 129165,
    corporateMortgages: 76383,
    corporateCommercialPaper: 5716,
    CorporateOther: 35947
  },
  "1973-06-01": {
    corporate: 461449,
    selfemployed: 223961,
    selfemployedNotes: 35636,
    selfemployedMortgages: 166920,
    selfemployedOther: 21404,
    corporateBonds: 202249,
    corporateDepositaryLoansExmortgage: 131934,
    corporateMortgages: 82945,
    corporateCommercialPaper: 5981,
    CorporateOther: 38340
  },
  "1973-09-01": {
    corporate: 481202,
    selfemployed: 229104,
    selfemployedNotes: 40710,
    selfemployedMortgages: 166518,
    selfemployedOther: 21877,
    corporateBonds: 204906,
    corporateDepositaryLoansExmortgage: 134614,
    corporateMortgages: 93859,
    corporateCommercialPaper: 5971,
    CorporateOther: 41852
  },
  "1973-12-01": {
    corporate: 497437,
    selfemployed: 230141,
    selfemployedNotes: 44887,
    selfemployedMortgages: 162906,
    selfemployedOther: 22349,
    corporateBonds: 206909,
    corporateDepositaryLoansExmortgage: 134126,
    corporateMortgages: 104837,
    corporateCommercialPaper: 8382,
    CorporateOther: 43183
  },
  "1974-03-01": {
    corporate: 512155,
    selfemployed: 239163,
    selfemployedNotes: 48051,
    selfemployedMortgages: 167824,
    selfemployedOther: 23289,
    corporateBonds: 210614,
    corporateDepositaryLoansExmortgage: 140193,
    corporateMortgages: 106810,
    corporateCommercialPaper: 8930,
    CorporateOther: 45608
  },
  "1974-06-01": {
    corporate: 529291,
    selfemployed: 250161,
    selfemployedNotes: 50303,
    selfemployedMortgages: 175975,
    selfemployedOther: 23883,
    corporateBonds: 216000,
    corporateDepositaryLoansExmortgage: 148337,
    corporateMortgages: 107401,
    corporateCommercialPaper: 9721,
    CorporateOther: 47832
  },
  "1974-09-01": {
    corporate: 541863,
    selfemployed: 260293,
    selfemployedNotes: 51876,
    selfemployedMortgages: 183403,
    selfemployedOther: 25013,
    corporateBonds: 220192,
    corporateDepositaryLoansExmortgage: 154156,
    corporateMortgages: 104607,
    corporateCommercialPaper: 12070,
    CorporateOther: 50838
  },
  "1974-12-01": {
    corporate: 553066,
    selfemployed: 267249,
    selfemployedNotes: 53190,
    selfemployedMortgages: 188132,
    selfemployedOther: 25928,
    corporateBonds: 226579,
    corporateDepositaryLoansExmortgage: 156066,
    corporateMortgages: 103738,
    corporateCommercialPaper: 12513,
    CorporateOther: 54170
  },
  "1975-03-01": {
    corporate: 558834,
    selfemployed: 272967,
    selfemployedNotes: 54321,
    selfemployedMortgages: 192097,
    selfemployedOther: 26549,
    corporateBonds: 236009,
    corporateDepositaryLoansExmortgage: 150518,
    corporateMortgages: 103900,
    corporateCommercialPaper: 13539,
    CorporateOther: 54868
  },
  "1975-06-01": {
    corporate: 562445,
    selfemployed: 279098,
    selfemployedNotes: 54780,
    selfemployedMortgages: 196561,
    selfemployedOther: 27757,
    corporateBonds: 244281,
    corporateDepositaryLoansExmortgage: 145849,
    corporateMortgages: 105015,
    corporateCommercialPaper: 11157,
    CorporateOther: 56143
  },
  "1975-09-01": {
    corporate: 568024,
    selfemployed: 283501,
    selfemployedNotes: 55599,
    selfemployedMortgages: 199138,
    selfemployedOther: 28764,
    corporateBonds: 247958,
    corporateDepositaryLoansExmortgage: 144000,
    corporateMortgages: 106682,
    corporateCommercialPaper: 11112,
    CorporateOther: 58272
  },
  "1975-12-01": {
    corporate: 572665,
    selfemployed: 287601,
    selfemployedNotes: 56659,
    selfemployedMortgages: 200956,
    selfemployedOther: 29985,
    corporateBonds: 253783,
    corporateDepositaryLoansExmortgage: 141944,
    corporateMortgages: 107945,
    corporateCommercialPaper: 9616,
    CorporateOther: 59377
  },
  "1976-03-01": {
    corporate: 577948,
    selfemployed: 295948,
    selfemployedNotes: 57957,
    selfemployedMortgages: 206831,
    selfemployedOther: 31160,
    corporateBonds: 260254,
    corporateDepositaryLoansExmortgage: 137265,
    corporateMortgages: 107760,
    corporateCommercialPaper: 10286,
    CorporateOther: 62383
  },
  "1976-06-01": {
    corporate: 588825,
    selfemployed: 302510,
    selfemployedNotes: 59230,
    selfemployedMortgages: 211126,
    selfemployedOther: 32154,
    corporateBonds: 265211,
    corporateDepositaryLoansExmortgage: 137508,
    corporateMortgages: 110113,
    corporateCommercialPaper: 10994,
    CorporateOther: 64999
  },
  "1976-09-01": {
    corporate: 600862,
    selfemployed: 309817,
    selfemployedNotes: 60416,
    selfemployedMortgages: 215554,
    selfemployedOther: 33847,
    corporateBonds: 270908,
    corporateDepositaryLoansExmortgage: 138604,
    corporateMortgages: 112793,
    corporateCommercialPaper: 9899,
    CorporateOther: 68658
  },
  "1976-12-01": {
    corporate: 614346,
    selfemployed: 317082,
    selfemployedNotes: 62117,
    selfemployedMortgages: 220013,
    selfemployedOther: 34953,
    corporateBonds: 276614,
    corporateDepositaryLoansExmortgage: 138761,
    corporateMortgages: 114892,
    corporateCommercialPaper: 11049,
    CorporateOther: 73030
  },
  "1977-03-01": {
    corporate: 632300,
    selfemployed: 328088,
    selfemployedNotes: 64106,
    selfemployedMortgages: 226212,
    selfemployedOther: 37770,
    corporateBonds: 282016,
    corporateDepositaryLoansExmortgage: 144208,
    corporateMortgages: 116098,
    corporateCommercialPaper: 11232,
    CorporateOther: 78746
  },
  "1977-06-01": {
    corporate: 648519,
    selfemployed: 339503,
    selfemployedNotes: 66364,
    selfemployedMortgages: 234407,
    selfemployedOther: 38731,
    corporateBonds: 285712,
    corporateDepositaryLoansExmortgage: 147691,
    corporateMortgages: 119022,
    corporateCommercialPaper: 11631,
    CorporateOther: 84463
  },
  "1977-09-01": {
    corporate: 665925,
    selfemployed: 349469,
    selfemployedNotes: 68359,
    selfemployedMortgages: 241151,
    selfemployedOther: 39960,
    corporateBonds: 291816,
    corporateDepositaryLoansExmortgage: 150579,
    corporateMortgages: 123313,
    corporateCommercialPaper: 11771,
    CorporateOther: 88446
  },
  "1977-12-01": {
    corporate: 690322,
    selfemployed: 359811,
    selfemployedNotes: 70439,
    selfemployedMortgages: 247987,
    selfemployedOther: 41385,
    corporateBonds: 299491,
    corporateDepositaryLoansExmortgage: 153766,
    corporateMortgages: 129232,
    corporateCommercialPaper: 12808,
    CorporateOther: 95025
  },
  "1978-03-01": {
    corporate: 710785,
    selfemployed: 371135,
    selfemployedNotes: 73416,
    selfemployedMortgages: 254235,
    selfemployedOther: 43485,
    corporateBonds: 303936,
    corporateDepositaryLoansExmortgage: 164606,
    corporateMortgages: 132625,
    corporateCommercialPaper: 12634,
    CorporateOther: 96984
  },
  "1978-06-01": {
    corporate: 731047,
    selfemployed: 386285,
    selfemployedNotes: 77458,
    selfemployedMortgages: 262485,
    selfemployedOther: 46341,
    corporateBonds: 310353,
    corporateDepositaryLoansExmortgage: 168644,
    corporateMortgages: 136999,
    corporateCommercialPaper: 12575,
    CorporateOther: 102476
  },
  "1978-09-01": {
    corporate: 745665,
    selfemployed: 402607,
    selfemployedNotes: 81907,
    selfemployedMortgages: 271396,
    selfemployedOther: 49304,
    corporateBonds: 315522,
    corporateDepositaryLoansExmortgage: 168909,
    corporateMortgages: 140123,
    corporateCommercialPaper: 12914,
    CorporateOther: 108197
  },
  "1978-12-01": {
    corporate: 766056,
    selfemployed: 418206,
    selfemployedNotes: 85961,
    selfemployedMortgages: 280351,
    selfemployedOther: 51893,
    corporateBonds: 320607,
    corporateDepositaryLoansExmortgage: 173563,
    corporateMortgages: 141797,
    corporateCommercialPaper: 15499,
    CorporateOther: 114590
  },
  "1979-03-01": {
    corporate: 789844,
    selfemployed: 431499,
    selfemployedNotes: 89874,
    selfemployedMortgages: 287333,
    selfemployedOther: 54292,
    corporateBonds: 324975,
    corporateDepositaryLoansExmortgage: 182328,
    corporateMortgages: 145185,
    corporateCommercialPaper: 16201,
    CorporateOther: 121155
  },
  "1979-06-01": {
    corporate: 811542,
    selfemployed: 454916,
    selfemployedNotes: 93987,
    selfemployedMortgages: 302432,
    selfemployedOther: 58497,
    corporateBonds: 330267,
    corporateDepositaryLoansExmortgage: 189485,
    corporateMortgages: 145589,
    corporateCommercialPaper: 18063,
    CorporateOther: 128138
  },
  "1979-09-01": {
    corporate: 833769,
    selfemployed: 475215,
    selfemployedNotes: 98100,
    selfemployedMortgages: 316501,
    selfemployedOther: 60614,
    corporateBonds: 334133,
    corporateDepositaryLoansExmortgage: 198287,
    corporateMortgages: 146354,
    corporateCommercialPaper: 20944,
    CorporateOther: 134051
  },
  "1979-12-01": {
    corporate: 851772,
    selfemployed: 492017,
    selfemployedNotes: 101694,
    selfemployedMortgages: 326101,
    selfemployedOther: 64222,
    corporateBonds: 337915,
    corporateDepositaryLoansExmortgage: 204440,
    corporateMortgages: 143988,
    corporateCommercialPaper: 24518,
    CorporateOther: 140911
  },
  "1980-03-01": {
    corporate: 873559,
    selfemployed: 510055,
    selfemployedNotes: 102596,
    selfemployedMortgages: 339372,
    selfemployedOther: 68087,
    corporateBonds: 343860,
    corporateDepositaryLoansExmortgage: 213068,
    corporateMortgages: 141876,
    corporateCommercialPaper: 27315,
    CorporateOther: 147440
  },
  "1980-06-01": {
    corporate: 880652,
    selfemployed: 523607,
    selfemployedNotes: 101827,
    selfemployedMortgages: 352576,
    selfemployedOther: 69204,
    corporateBonds: 355030,
    corporateDepositaryLoansExmortgage: 206340,
    corporateMortgages: 136753,
    corporateCommercialPaper: 30447,
    CorporateOther: 152082
  },
  "1980-09-01": {
    corporate: 892677,
    selfemployed: 538802,
    selfemployedNotes: 102329,
    selfemployedMortgages: 365345,
    selfemployedOther: 71127,
    corporateBonds: 362374,
    corporateDepositaryLoansExmortgage: 215655,
    corporateMortgages: 133885,
    corporateCommercialPaper: 25635,
    CorporateOther: 155128
  },
  "1980-12-01": {
    corporate: 919612,
    selfemployed: 555061,
    selfemployedNotes: 104594,
    selfemployedMortgages: 376266,
    selfemployedOther: 74201,
    corporateBonds: 365570,
    corporateDepositaryLoansExmortgage: 231595,
    corporateMortgages: 134320,
    corporateCommercialPaper: 28026,
    CorporateOther: 160101
  },
  "1981-03-01": {
    corporate: 935220,
    selfemployed: 573808,
    selfemployedNotes: 106925,
    selfemployedMortgages: 388119,
    selfemployedOther: 78765,
    corporateBonds: 371608,
    corporateDepositaryLoansExmortgage: 231141,
    corporateMortgages: 135097,
    corporateCommercialPaper: 28157,
    CorporateOther: 169217
  },
  "1981-06-01": {
    corporate: 976520,
    selfemployed: 589782,
    selfemployedNotes: 110378,
    selfemployedMortgages: 397220,
    selfemployedOther: 82184,
    corporateBonds: 379240,
    corporateDepositaryLoansExmortgage: 245482,
    corporateMortgages: 141064,
    corporateCommercialPaper: 31960,
    CorporateOther: 178774
  },
  "1981-09-01": {
    corporate: 1014381,
    selfemployed: 603261,
    selfemployedNotes: 113625,
    selfemployedMortgages: 405446,
    selfemployedOther: 84190,
    corporateBonds: 383712,
    corporateDepositaryLoansExmortgage: 257718,
    corporateMortgages: 146456,
    corporateCommercialPaper: 37514,
    CorporateOther: 188981
  },
  "1981-12-01": {
    corporate: 1038680,
    selfemployed: 619777,
    selfemployedNotes: 115997,
    selfemployedMortgages: 418465,
    selfemployedOther: 85315,
    corporateBonds: 390331,
    corporateDepositaryLoansExmortgage: 263884,
    corporateMortgages: 141354,
    corporateCommercialPaper: 42722,
    CorporateOther: 200389
  },
  "1982-03-01": {
    corporate: 1067949,
    selfemployed: 632175,
    selfemployedNotes: 115148,
    selfemployedMortgages: 430697,
    selfemployedOther: 86330,
    corporateBonds: 398241,
    corporateDepositaryLoansExmortgage: 279759,
    corporateMortgages: 139894,
    corporateCommercialPaper: 45954,
    CorporateOther: 204101
  },
  "1982-06-01": {
    corporate: 1098089,
    selfemployed: 648103,
    selfemployedNotes: 112948,
    selfemployedMortgages: 447392,
    selfemployedOther: 87764,
    corporateBonds: 403341,
    corporateDepositaryLoansExmortgage: 302372,
    corporateMortgages: 136335,
    corporateCommercialPaper: 45469,
    CorporateOther: 210572
  },
  "1982-09-01": {
    corporate: 1124827,
    selfemployed: 665046,
    selfemployedNotes: 110824,
    selfemployedMortgages: 465341,
    selfemployedOther: 88880,
    corporateBonds: 411321,
    corporateDepositaryLoansExmortgage: 320838,
    corporateMortgages: 129479,
    corporateCommercialPaper: 42634,
    CorporateOther: 220555
  },
  "1982-12-01": {
    corporate: 1129934,
    selfemployed: 677654,
    selfemployedNotes: 109439,
    selfemployedMortgages: 479636,
    selfemployedOther: 88580,
    corporateBonds: 420997,
    corporateDepositaryLoansExmortgage: 323733,
    corporateMortgages: 127196,
    corporateCommercialPaper: 37614,
    CorporateOther: 220394
  },
  "1983-03-01": {
    corporate: 1161482,
    selfemployed: 696559,
    selfemployedNotes: 109504,
    selfemployedMortgages: 497536,
    selfemployedOther: 89520,
    corporateBonds: 427194,
    corporateDepositaryLoansExmortgage: 328572,
    corporateMortgages: 143413,
    corporateCommercialPaper: 38688,
    CorporateOther: 223615
  },
  "1983-06-01": {
    corporate: 1185231,
    selfemployed: 711242,
    selfemployedNotes: 109152,
    selfemployedMortgages: 511736,
    selfemployedOther: 90354,
    corporateBonds: 438317,
    corporateDepositaryLoansExmortgage: 330818,
    corporateMortgages: 148074,
    corporateCommercialPaper: 33441,
    CorporateOther: 234581
  },
  "1983-09-01": {
    corporate: 1217535,
    selfemployed: 728215,
    selfemployedNotes: 109398,
    selfemployedMortgages: 527434,
    selfemployedOther: 91382,
    corporateBonds: 442938,
    corporateDepositaryLoansExmortgage: 341055,
    corporateMortgages: 153029,
    corporateCommercialPaper: 34459,
    CorporateOther: 246054
  },
  "1983-12-01": {
    corporate: 1247243,
    selfemployed: 748980,
    selfemployedNotes: 111560,
    selfemployedMortgages: 545258,
    selfemployedOther: 92163,
    corporateBonds: 446980,
    corporateDepositaryLoansExmortgage: 352715,
    corporateMortgages: 158266,
    corporateCommercialPaper: 36775,
    CorporateOther: 252507
  },
  "1984-03-01": {
    corporate: 1295249,
    selfemployed: 776276,
    selfemployedNotes: 116412,
    selfemployedMortgages: 565599,
    selfemployedOther: 94265,
    corporateBonds: 455202,
    corporateDepositaryLoansExmortgage: 371766,
    corporateMortgages: 160941,
    corporateCommercialPaper: 40668,
    CorporateOther: 266672
  },
  "1984-06-01": {
    corporate: 1352745,
    selfemployed: 805933,
    selfemployedNotes: 120590,
    selfemployedMortgages: 590806,
    selfemployedOther: 94537,
    corporateBonds: 463644,
    corporateDepositaryLoansExmortgage: 394504,
    corporateMortgages: 167617,
    corporateCommercialPaper: 48130,
    CorporateOther: 278850
  },
  "1984-09-01": {
    corporate: 1400349,
    selfemployed: 834859,
    selfemployedNotes: 125102,
    selfemployedMortgages: 613738,
    selfemployedOther: 96019,
    corporateBonds: 477439,
    corporateDepositaryLoansExmortgage: 401294,
    corporateMortgages: 171960,
    corporateCommercialPaper: 55733,
    CorporateOther: 293923
  },
  "1984-12-01": {
    corporate: 1457968,
    selfemployed: 862648,
    selfemployedNotes: 129331,
    selfemployedMortgages: 637510,
    selfemployedOther: 95807,
    corporateBonds: 495058,
    corporateDepositaryLoansExmortgage: 411929,
    corporateMortgages: 173213,
    corporateCommercialPaper: 58490,
    CorporateOther: 319278
  },
  "1985-03-01": {
    corporate: 1489462,
    selfemployed: 888467,
    selfemployedNotes: 130853,
    selfemployedMortgages: 661596,
    selfemployedOther: 96018,
    corporateBonds: 513558,
    corporateDepositaryLoansExmortgage: 426940,
    corporateMortgages: 163918,
    corporateCommercialPaper: 62353,
    CorporateOther: 322693
  },
  "1985-06-01": {
    corporate: 1529106,
    selfemployed: 914774,
    selfemployedNotes: 131578,
    selfemployedMortgages: 687188,
    selfemployedOther: 96008,
    corporateBonds: 535693,
    corporateDepositaryLoansExmortgage: 431164,
    corporateMortgages: 166539,
    corporateCommercialPaper: 65706,
    CorporateOther: 330004
  },
  "1985-09-01": {
    corporate: 1572170,
    selfemployed: 928189,
    selfemployedNotes: 131533,
    selfemployedMortgages: 699794,
    selfemployedOther: 96862,
    corporateBonds: 554766,
    corporateDepositaryLoansExmortgage: 443504,
    corporateMortgages: 163598,
    corporateCommercialPaper: 67905,
    CorporateOther: 342397
  },
  "1985-12-01": {
    corporate: 1638943,
    selfemployed: 933047,
    selfemployedNotes: 131383,
    selfemployedMortgages: 707816,
    selfemployedOther: 93849,
    corporateBonds: 578234,
    corporateDepositaryLoansExmortgage: 453929,
    corporateMortgages: 169299,
    corporateCommercialPaper: 72207,
    CorporateOther: 365274
  },
  "1986-03-01": {
    corporate: 1683411,
    selfemployed: 952836,
    selfemployedNotes: 132222,
    selfemployedMortgages: 726217,
    selfemployedOther: 94397,
    corporateBonds: 614192,
    corporateDepositaryLoansExmortgage: 465051,
    corporateMortgages: 172345,
    corporateCommercialPaper: 68753,
    CorporateOther: 363070
  },
  "1986-06-01": {
    corporate: 1737156,
    selfemployed: 971951,
    selfemployedNotes: 131351,
    selfemployedMortgages: 745873,
    selfemployedOther: 94728,
    corporateBonds: 653462,
    corporateDepositaryLoansExmortgage: 473321,
    corporateMortgages: 176144,
    corporateCommercialPaper: 64902,
    CorporateOther: 369327
  },
  "1986-09-01": {
    corporate: 1797227,
    selfemployed: 987791,
    selfemployedNotes: 131302,
    selfemployedMortgages: 762792,
    selfemployedOther: 93697,
    corporateBonds: 681794,
    corporateDepositaryLoansExmortgage: 479813,
    corporateMortgages: 181623,
    corporateCommercialPaper: 71136,
    CorporateOther: 382861
  },
  "1986-12-01": {
    corporate: 1864487,
    selfemployed: 1000195,
    selfemployedNotes: 131977,
    selfemployedMortgages: 775197,
    selfemployedOther: 93021,
    corporateBonds: 705350,
    corporateDepositaryLoansExmortgage: 519640,
    corporateMortgages: 185294,
    corporateCommercialPaper: 62865,
    CorporateOther: 391338
  },
  "1987-03-01": {
    corporate: 1908814,
    selfemployed: 1018847,
    selfemployedNotes: 133571,
    selfemployedMortgages: 791904,
    selfemployedOther: 93371,
    corporateBonds: 730491,
    corporateDepositaryLoansExmortgage: 509615,
    corporateMortgages: 216917,
    corporateCommercialPaper: 61834,
    CorporateOther: 389957
  },
  "1987-06-01": {
    corporate: 1948373,
    selfemployed: 1030716,
    selfemployedNotes: 135824,
    selfemployedMortgages: 801138,
    selfemployedOther: 93753,
    corporateBonds: 748212,
    corporateDepositaryLoansExmortgage: 510150,
    corporateMortgages: 227918,
    corporateCommercialPaper: 63064,
    CorporateOther: 399029
  },
  "1987-09-01": {
    corporate: 1999842,
    selfemployed: 1044259,
    selfemployedNotes: 138548,
    selfemployedMortgages: 811442,
    selfemployedOther: 94269,
    corporateBonds: 768763,
    corporateDepositaryLoansExmortgage: 519128,
    corporateMortgages: 238040,
    corporateCommercialPaper: 65072,
    CorporateOther: 408839
  },
  "1987-12-01": {
    corporate: 2051545,
    selfemployed: 1064863,
    selfemployedNotes: 140669,
    selfemployedMortgages: 822733,
    selfemployedOther: 101461,
    corporateBonds: 784115,
    corporateDepositaryLoansExmortgage: 524047,
    corporateMortgages: 246046,
    corporateCommercialPaper: 73768,
    CorporateOther: 423569
  },
  "1988-03-01": {
    corporate: 2100786,
    selfemployed: 1085864,
    selfemployedNotes: 143680,
    selfemployedMortgages: 840084,
    selfemployedOther: 102100,
    corporateBonds: 809612,
    corporateDepositaryLoansExmortgage: 531179,
    corporateMortgages: 250252,
    corporateCommercialPaper: 68369,
    CorporateOther: 441374
  },
  "1988-06-01": {
    corporate: 2155210,
    selfemployed: 1109030,
    selfemployedNotes: 145620,
    selfemployedMortgages: 859790,
    selfemployedOther: 103620,
    corporateBonds: 840675,
    corporateDepositaryLoansExmortgage: 545223,
    corporateMortgages: 251258,
    corporateCommercialPaper: 70254,
    CorporateOther: 447800
  },
  "1988-09-01": {
    corporate: 2196461,
    selfemployed: 1133385,
    selfemployedNotes: 147500,
    selfemployedMortgages: 879181,
    selfemployedOther: 106703,
    corporateBonds: 865654,
    corporateDepositaryLoansExmortgage: 549937,
    corporateMortgages: 247441,
    corporateCommercialPaper: 74551,
    CorporateOther: 458878
  },
  "1988-12-01": {
    corporate: 2265205,
    selfemployed: 1149256,
    selfemployedNotes: 149960,
    selfemployedMortgages: 898139,
    selfemployedOther: 101157,
    corporateBonds: 887232,
    corporateDepositaryLoansExmortgage: 565416,
    corporateMortgages: 257203,
    corporateCommercialPaper: 85676,
    CorporateOther: 469678
  },
  "1989-03-01": {
    corporate: 2318457,
    selfemployed: 1162722,
    selfemployedNotes: 153119,
    selfemployedMortgages: 905411,
    selfemployedOther: 104192,
    corporateBonds: 901803,
    corporateDepositaryLoansExmortgage: 569906,
    corporateMortgages: 269804,
    corporateCommercialPaper: 89602,
    CorporateOther: 487342
  },
  "1989-06-01": {
    corporate: 2377544,
    selfemployed: 1175270,
    selfemployedNotes: 156336,
    selfemployedMortgages: 913665,
    selfemployedOther: 105270,
    corporateBonds: 923201,
    corporateDepositaryLoansExmortgage: 580795,
    corporateMortgages: 271671,
    corporateCommercialPaper: 100163,
    CorporateOther: 501714
  },
  "1989-09-01": {
    corporate: 2405997,
    selfemployed: 1186707,
    selfemployedNotes: 159566,
    selfemployedMortgages: 922950,
    selfemployedOther: 104191,
    corporateBonds: 939038,
    corporateDepositaryLoansExmortgage: 586813,
    corporateMortgages: 273097,
    corporateCommercialPaper: 105927,
    CorporateOther: 501122
  },
  "1989-12-01": {
    corporate: 2455221,
    selfemployed: 1197882,
    selfemployedNotes: 162723,
    selfemployedMortgages: 929594,
    selfemployedOther: 105565,
    corporateBonds: 961079,
    corporateDepositaryLoansExmortgage: 604541,
    corporateMortgages: 264568,
    corporateCommercialPaper: 107112,
    CorporateOther: 517921
  },
  "1990-03-01": {
    corporate: 2502902,
    selfemployed: 1203720,
    selfemployedNotes: 164488,
    selfemployedMortgages: 931544,
    selfemployedOther: 107688,
    corporateBonds: 967962,
    corporateDepositaryLoansExmortgage: 599238,
    corporateMortgages: 280648,
    corporateCommercialPaper: 117583,
    CorporateOther: 537471
  },
  "1990-06-01": {
    corporate: 2539035,
    selfemployed: 1201790,
    selfemployedNotes: 165121,
    selfemployedMortgages: 930412,
    selfemployedOther: 106257,
    corporateBonds: 984418,
    corporateDepositaryLoansExmortgage: 601414,
    corporateMortgages: 283351,
    corporateCommercialPaper: 116594,
    CorporateOther: 553258
  },
  "1990-09-01": {
    corporate: 2565707,
    selfemployed: 1201528,
    selfemployedNotes: 165630,
    selfemployedMortgages: 929283,
    selfemployedOther: 106615,
    corporateBonds: 991867,
    corporateDepositaryLoansExmortgage: 597356,
    corporateMortgages: 287417,
    corporateCommercialPaper: 123191,
    CorporateOther: 565876
  },
  "1990-12-01": {
    corporate: 2585389,
    selfemployed: 1197957,
    selfemployedNotes: 166680,
    selfemployedMortgages: 924888,
    selfemployedOther: 106389,
    corporateBonds: 1008167,
    corporateDepositaryLoansExmortgage: 594058,
    corporateMortgages: 288117,
    corporateCommercialPaper: 116852,
    CorporateOther: 578195
  },
  "1991-03-01": {
    corporate: 2566605,
    selfemployed: 1193779,
    selfemployedNotes: 167539,
    selfemployedMortgages: 920592,
    selfemployedOther: 105648,
    corporateBonds: 1027331,
    corporateDepositaryLoansExmortgage: 580046,
    corporateMortgages: 294314,
    corporateCommercialPaper: 110555,
    CorporateOther: 554359
  },
  "1991-06-01": {
    corporate: 2566563,
    selfemployed: 1193787,
    selfemployedNotes: 166816,
    selfemployedMortgages: 920884,
    selfemployedOther: 106088,
    corporateBonds: 1051467,
    corporateDepositaryLoansExmortgage: 565212,
    corporateMortgages: 293987,
    corporateCommercialPaper: 108000,
    CorporateOther: 547897
  },
  "1991-09-01": {
    corporate: 2552199,
    selfemployed: 1177150,
    selfemployedNotes: 166295,
    selfemployedMortgages: 904710,
    selfemployedOther: 106145,
    corporateBonds: 1071876,
    corporateDepositaryLoansExmortgage: 555802,
    corporateMortgages: 286928,
    corporateCommercialPaper: 99420,
    CorporateOther: 538173
  },
  "1991-12-01": {
    corporate: 2526640,
    selfemployed: 1169744,
    selfemployedNotes: 166737,
    selfemployedMortgages: 897631,
    selfemployedOther: 105376,
    corporateBonds: 1086935,
    corporateDepositaryLoansExmortgage: 542316,
    corporateMortgages: 279284,
    corporateCommercialPaper: 98477,
    CorporateOther: 519628
  },
  "1992-03-01": {
    corporate: 2533028,
    selfemployed: 1165195,
    selfemployedNotes: 167593,
    selfemployedMortgages: 891303,
    selfemployedOther: 106299,
    corporateBonds: 1106050,
    corporateDepositaryLoansExmortgage: 535258,
    corporateMortgages: 273565,
    corporateCommercialPaper: 102477,
    CorporateOther: 515678
  },
  "1992-06-01": {
    corporate: 2533896,
    selfemployed: 1157679,
    selfemployedNotes: 167979,
    selfemployedMortgages: 883955,
    selfemployedOther: 105745,
    corporateBonds: 1125491,
    corporateDepositaryLoansExmortgage: 523884,
    corporateMortgages: 266898,
    corporateCommercialPaper: 101215,
    CorporateOther: 516408
  },
  "1992-09-01": {
    corporate: 2544135,
    selfemployed: 1149208,
    selfemployedNotes: 168388,
    selfemployedMortgages: 875059,
    selfemployedOther: 105761,
    corporateBonds: 1140928,
    corporateDepositaryLoansExmortgage: 520691,
    corporateMortgages: 257737,
    corporateCommercialPaper: 100597,
    CorporateOther: 524182
  },
  "1992-12-01": {
    corporate: 2545000,
    selfemployed: 1143421,
    selfemployedNotes: 169474,
    selfemployedMortgages: 867627,
    selfemployedOther: 106320,
    corporateBonds: 1154504,
    corporateDepositaryLoansExmortgage: 513956,
    corporateMortgages: 239192,
    corporateCommercialPaper: 107106,
    CorporateOther: 530242
  },
  "1993-03-01": {
    corporate: 2571565,
    selfemployed: 1133952,
    selfemployedNotes: 167095,
    selfemployedMortgages: 861177,
    selfemployedOther: 105680,
    corporateBonds: 1212362,
    corporateDepositaryLoansExmortgage: 506285,
    corporateMortgages: 229459,
    corporateCommercialPaper: 105480,
    CorporateOther: 517979
  },
  "1993-06-01": {
    corporate: 2609706,
    selfemployed: 1130109,
    selfemployedNotes: 169412,
    selfemployedMortgages: 855575,
    selfemployedOther: 105122,
    corporateBonds: 1258846,
    corporateDepositaryLoansExmortgage: 505021,
    corporateMortgages: 219919,
    corporateCommercialPaper: 112468,
    CorporateOther: 513452
  },
  "1993-09-01": {
    corporate: 2651018,
    selfemployed: 1124813,
    selfemployedNotes: 168635,
    selfemployedMortgages: 850249,
    selfemployedOther: 105928,
    corporateBonds: 1302923,
    corporateDepositaryLoansExmortgage: 503781,
    corporateMortgages: 216888,
    corporateCommercialPaper: 115358,
    CorporateOther: 512068
  },
  "1993-12-01": {
    corporate: 2690672,
    selfemployed: 1123465,
    selfemployedNotes: 173860,
    selfemployedMortgages: 845522,
    selfemployedOther: 104083,
    corporateBonds: 1337881,
    corporateDepositaryLoansExmortgage: 506662,
    corporateMortgages: 218772,
    corporateCommercialPaper: 117829,
    CorporateOther: 509528
  },
  "1994-03-01": {
    corporate: 2750353,
    selfemployed: 1124844,
    selfemployedNotes: 177923,
    selfemployedMortgages: 841673,
    selfemployedOther: 105248,
    corporateBonds: 1374071,
    corporateDepositaryLoansExmortgage: 510017,
    corporateMortgages: 213997,
    corporateCommercialPaper: 121229,
    CorporateOther: 531039
  },
  "1994-06-01": {
    corporate: 2793535,
    selfemployed: 1128312,
    selfemployedNotes: 181479,
    selfemployedMortgages: 841634,
    selfemployedOther: 105198,
    corporateBonds: 1396331,
    corporateDepositaryLoansExmortgage: 522252,
    corporateMortgages: 214288,
    corporateCommercialPaper: 122893,
    CorporateOther: 537771
  },
  "1994-09-01": {
    corporate: 2838904,
    selfemployed: 1134269,
    selfemployedNotes: 185501,
    selfemployedMortgages: 842577,
    selfemployedOther: 106191,
    corporateBonds: 1410226,
    corporateDepositaryLoansExmortgage: 536943,
    corporateMortgages: 217432,
    corporateCommercialPaper: 129653,
    CorporateOther: 544650
  },
  "1994-12-01": {
    corporate: 2888046,
    selfemployed: 1138806,
    selfemployedNotes: 189177,
    selfemployedMortgages: 840947,
    selfemployedOther: 108682,
    corporateBonds: 1428460,
    corporateDepositaryLoansExmortgage: 554175,
    corporateMortgages: 212350,
    corporateCommercialPaper: 139238,
    CorporateOther: 553823
  },
  "1995-03-01": {
    corporate: 2946112,
    selfemployed: 1150441,
    selfemployedNotes: 194004,
    selfemployedMortgages: 845643,
    selfemployedOther: 110794,
    corporateBonds: 1440558,
    corporateDepositaryLoansExmortgage: 585072,
    corporateMortgages: 214795,
    corporateCommercialPaper: 139978,
    CorporateOther: 565709
  },
  "1995-06-01": {
    corporate: 3010682,
    selfemployed: 1159515,
    selfemployedNotes: 196318,
    selfemployedMortgages: 852642,
    selfemployedOther: 110555,
    corporateBonds: 1465559,
    corporateDepositaryLoansExmortgage: 607204,
    corporateMortgages: 213823,
    corporateCommercialPaper: 148168,
    CorporateOther: 575928
  },
  "1995-09-01": {
    corporate: 3044548,
    selfemployed: 1172979,
    selfemployedNotes: 198880,
    selfemployedMortgages: 861574,
    selfemployedOther: 112525,
    corporateBonds: 1475183,
    corporateDepositaryLoansExmortgage: 619897,
    corporateMortgages: 216782,
    corporateCommercialPaper: 152903,
    CorporateOther: 579783
  },
  "1995-12-01": {
    corporate: 3094015,
    selfemployed: 1185165,
    selfemployedNotes: 200634,
    selfemployedMortgages: 869926,
    selfemployedOther: 114605,
    corporateBonds: 1493095,
    corporateDepositaryLoansExmortgage: 631493,
    corporateMortgages: 210025,
    corporateCommercialPaper: 157351,
    CorporateOther: 602051
  },
  "1996-03-01": {
    corporate: 3123920,
    selfemployed: 1201319,
    selfemployedNotes: 206694,
    selfemployedMortgages: 877661,
    selfemployedOther: 116964,
    corporateBonds: 1515784,
    corporateDepositaryLoansExmortgage: 629847,
    corporateMortgages: 214069,
    corporateCommercialPaper: 163799,
    CorporateOther: 600421
  },
  "1996-06-01": {
    corporate: 3180580,
    selfemployed: 1219272,
    selfemployedNotes: 211804,
    selfemployedMortgages: 890104,
    selfemployedOther: 117364,
    corporateBonds: 1542035,
    corporateDepositaryLoansExmortgage: 639794,
    corporateMortgages: 217914,
    corporateCommercialPaper: 166058,
    CorporateOther: 614779
  },
  "1996-09-01": {
    corporate: 3222778,
    selfemployed: 1239946,
    selfemployedNotes: 224468,
    selfemployedMortgages: 899926,
    selfemployedOther: 115552,
    corporateBonds: 1558127,
    corporateDepositaryLoansExmortgage: 656586,
    corporateMortgages: 220085,
    corporateCommercialPaper: 162430,
    CorporateOther: 625550
  },
  "1996-12-01": {
    corporate: 3244561,
    selfemployed: 1258226,
    selfemployedNotes: 229829,
    selfemployedMortgages: 910112,
    selfemployedOther: 118284,
    corporateBonds: 1581906,
    corporateDepositaryLoansExmortgage: 662307,
    corporateMortgages: 221455,
    corporateCommercialPaper: 156405,
    CorporateOther: 622488
  },
  "1997-03-01": {
    corporate: 3295756,
    selfemployed: 1281814,
    selfemployedNotes: 244636,
    selfemployedMortgages: 916852,
    selfemployedOther: 120327,
    corporateBonds: 1609404,
    corporateDepositaryLoansExmortgage: 684391,
    corporateMortgages: 223649,
    corporateCommercialPaper: 158159,
    CorporateOther: 620153
  },
  "1997-06-01": {
    corporate: 3362431,
    selfemployed: 1306068,
    selfemployedNotes: 255502,
    selfemployedMortgages: 930962,
    selfemployedOther: 119604,
    corporateBonds: 1644886,
    corporateDepositaryLoansExmortgage: 698107,
    corporateMortgages: 229237,
    corporateCommercialPaper: 163294,
    CorporateOther: 626907
  },
  "1997-09-01": {
    corporate: 3458062,
    selfemployed: 1332680,
    selfemployedNotes: 263179,
    selfemployedMortgages: 943393,
    selfemployedOther: 126107,
    corporateBonds: 1695804,
    corporateDepositaryLoansExmortgage: 707318,
    corporateMortgages: 244547,
    corporateCommercialPaper: 165437,
    CorporateOther: 644956
  },
  "1997-12-01": {
    corporate: 3522070,
    selfemployed: 1362776,
    selfemployedNotes: 276569,
    selfemployedMortgages: 961058,
    selfemployedOther: 125148,
    corporateBonds: 1710564,
    corporateDepositaryLoansExmortgage: 716874,
    corporateMortgages: 249125,
    corporateCommercialPaper: 168598,
    CorporateOther: 676909
  },
  "1998-03-01": {
    corporate: 3622723,
    selfemployed: 1405860,
    selfemployedNotes: 279725,
    selfemployedMortgages: 997910,
    selfemployedOther: 128225,
    corporateBonds: 1782917,
    corporateDepositaryLoansExmortgage: 726796,
    corporateMortgages: 245790,
    corporateCommercialPaper: 181365,
    CorporateOther: 685855
  },
  "1998-06-01": {
    corporate: 3738611,
    selfemployed: 1454050,
    selfemployedNotes: 288911,
    selfemployedMortgages: 1034790,
    selfemployedOther: 130348,
    corporateBonds: 1866328,
    corporateDepositaryLoansExmortgage: 757867,
    corporateMortgages: 239860,
    corporateCommercialPaper: 182305,
    CorporateOther: 692251
  },
  "1998-09-01": {
    corporate: 3822477,
    selfemployed: 1499308,
    selfemployedNotes: 296276,
    selfemployedMortgages: 1071740,
    selfemployedOther: 131293,
    corporateBonds: 1891282,
    corporateDepositaryLoansExmortgage: 782277,
    corporateMortgages: 238531,
    corporateCommercialPaper: 203697,
    CorporateOther: 706690
  },
  "1998-12-01": {
    corporate: 3909949,
    selfemployed: 1545465,
    selfemployedNotes: 302100,
    selfemployedMortgages: 1108788,
    selfemployedOther: 134578,
    corporateBonds: 1926016,
    corporateDepositaryLoansExmortgage: 797481,
    corporateMortgages: 250635,
    corporateCommercialPaper: 192958,
    CorporateOther: 742859
  },
  "1999-03-01": {
    corporate: 4024904,
    selfemployed: 1593632,
    selfemployedNotes: 315416,
    selfemployedMortgages: 1141159,
    selfemployedOther: 137057,
    corporateBonds: 1975002,
    corporateDepositaryLoansExmortgage: 808412,
    corporateMortgages: 263011,
    corporateCommercialPaper: 207538,
    CorporateOther: 770941
  },
  "1999-06-01": {
    corporate: 4097092,
    selfemployed: 1630470,
    selfemployedNotes: 320032,
    selfemployedMortgages: 1171643,
    selfemployedOther: 138795,
    corporateBonds: 2037287,
    corporateDepositaryLoansExmortgage: 816736,
    corporateMortgages: 276132,
    corporateCommercialPaper: 206878,
    CorporateOther: 760059
  },
  "1999-09-01": {
    corporate: 4247966,
    selfemployed: 1684044,
    selfemployedNotes: 334640,
    selfemployedMortgages: 1208160,
    selfemployedOther: 141244,
    corporateBonds: 2104331,
    corporateDepositaryLoansExmortgage: 834969,
    corporateMortgages: 321168,
    corporateCommercialPaper: 219317,
    CorporateOther: 768181
  },
  "1999-12-01": {
    corporate: 4311849,
    selfemployed: 1742734,
    selfemployedNotes: 351696,
    selfemployedMortgages: 1246913,
    selfemployedOther: 144124,
    corporateBonds: 2128093,
    corporateDepositaryLoansExmortgage: 846082,
    corporateMortgages: 331346,
    corporateCommercialPaper: 230326,
    CorporateOther: 776002
  },
  "2000-03-01": {
    corporate: 4429347,
    selfemployed: 1790078,
    selfemployedNotes: 362953,
    selfemployedMortgages: 1278529,
    selfemployedOther: 148597,
    corporateBonds: 2171263,
    corporateDepositaryLoansExmortgage: 859228,
    corporateMortgages: 342014,
    corporateCommercialPaper: 237764,
    CorporateOther: 819078
  },
  "2000-06-01": {
    corporate: 4537191,
    selfemployed: 1849947,
    selfemployedNotes: 384772,
    selfemployedMortgages: 1314866,
    selfemployedOther: 150308,
    corporateBonds: 2202751,
    corporateDepositaryLoansExmortgage: 890316,
    corporateMortgages: 349590,
    corporateCommercialPaper: 265376,
    CorporateOther: 829158
  },
  "2000-09-01": {
    corporate: 4599160,
    selfemployed: 1888467,
    selfemployedNotes: 390064,
    selfemployedMortgages: 1345746,
    selfemployedOther: 152657,
    corporateBonds: 2242060,
    corporateDepositaryLoansExmortgage: 897104,
    corporateMortgages: 355149,
    corporateCommercialPaper: 279408,
    CorporateOther: 825439
  },
  "2000-12-01": {
    corporate: 4657924,
    selfemployed: 1938641,
    selfemployedNotes: 402120,
    selfemployedMortgages: 1381475,
    selfemployedOther: 155047,
    corporateBonds: 2267543,
    corporateDepositaryLoansExmortgage: 901298,
    corporateMortgages: 355561,
    corporateCommercialPaper: 278402,
    CorporateOther: 855120
  },
  "2001-03-01": {
    corporate: 4661009,
    selfemployed: 1978822,
    selfemployedNotes: 416943,
    selfemployedMortgages: 1407296,
    selfemployedOther: 154583,
    corporateBonds: 2366256,
    corporateDepositaryLoansExmortgage: 884993,
    corporateMortgages: 331327,
    corporateCommercialPaper: 211055,
    CorporateOther: 867378
  },
  "2001-06-01": {
    corporate: 4744732,
    selfemployed: 2021821,
    selfemployedNotes: 427881,
    selfemployedMortgages: 1437350,
    selfemployedOther: 156590,
    corporateBonds: 2487868,
    corporateDepositaryLoansExmortgage: 860198,
    corporateMortgages: 340231,
    corporateCommercialPaper: 178174,
    CorporateOther: 878261
  },
  "2001-09-01": {
    corporate: 4779717,
    selfemployed: 2059309,
    selfemployedNotes: 436183,
    selfemployedMortgages: 1470520,
    selfemployedOther: 152606,
    corporateBonds: 2521528,
    corporateDepositaryLoansExmortgage: 832985,
    corporateMortgages: 367036,
    corporateCommercialPaper: 167068,
    CorporateOther: 891100
  },
  "2001-12-01": {
    corporate: 4793956,
    selfemployed: 2102417,
    selfemployedNotes: 445636,
    selfemployedMortgages: 1505036,
    selfemployedOther: 151745,
    corporateBonds: 2563772,
    corporateDepositaryLoansExmortgage: 796819,
    corporateMortgages: 394915,
    corporateCommercialPaper: 177726,
    CorporateOther: 860724
  },
  "2002-03-01": {
    corporate: 4806088,
    selfemployed: 2142433,
    selfemployedNotes: 452630,
    selfemployedMortgages: 1535378,
    selfemployedOther: 154424,
    corporateBonds: 2604849,
    corporateDepositaryLoansExmortgage: 783203,
    corporateMortgages: 396522,
    corporateCommercialPaper: 158284,
    CorporateOther: 863230
  },
  "2002-06-01": {
    corporate: 4794936,
    selfemployed: 2182047,
    selfemployedNotes: 458504,
    selfemployedMortgages: 1567134,
    selfemployedOther: 156409,
    corporateBonds: 2635778,
    corporateDepositaryLoansExmortgage: 740028,
    corporateMortgages: 404984,
    corporateCommercialPaper: 144073,
    CorporateOther: 870073
  },
  "2002-09-01": {
    corporate: 4785753,
    selfemployed: 2220608,
    selfemployedNotes: 463680,
    selfemployedMortgages: 1598868,
    selfemployedOther: 158059,
    corporateBonds: 2621138,
    corporateDepositaryLoansExmortgage: 718634,
    corporateMortgages: 423058,
    corporateCommercialPaper: 132126,
    CorporateOther: 890797
  },
  "2002-12-01": {
    corporate: 4789505,
    selfemployed: 2259770,
    selfemployedNotes: 469317,
    selfemployedMortgages: 1630587,
    selfemployedOther: 159866,
    corporateBonds: 2629821,
    corporateDepositaryLoansExmortgage: 693430,
    corporateMortgages: 450915,
    corporateCommercialPaper: 119813,
    CorporateOther: 895526
  },
  "2003-03-01": {
    corporate: 4787038,
    selfemployed: 2280617,
    selfemployedNotes: 473059,
    selfemployedMortgages: 1646668,
    selfemployedOther: 160891,
    corporateBonds: 2636834,
    corporateDepositaryLoansExmortgage: 668762,
    corporateMortgages: 465496,
    corporateCommercialPaper: 114330,
    CorporateOther: 901616
  },
  "2003-06-01": {
    corporate: 4786367,
    selfemployed: 2297278,
    selfemployedNotes: 474719,
    selfemployedMortgages: 1662684,
    selfemployedOther: 159875,
    corporateBonds: 2682369,
    corporateDepositaryLoansExmortgage: 637720,
    corporateMortgages: 474984,
    corporateCommercialPaper: 98006,
    CorporateOther: 893288
  },
  "2003-09-01": {
    corporate: 4821933,
    selfemployed: 2314381,
    selfemployedNotes: 476621,
    selfemployedMortgages: 1678707,
    selfemployedOther: 159053,
    corporateBonds: 2712323,
    corporateDepositaryLoansExmortgage: 625647,
    corporateMortgages: 494141,
    corporateCommercialPaper: 93346,
    CorporateOther: 896476
  },
  "2003-12-01": {
    corporate: 4809708,
    selfemployed: 2334573,
    selfemployedNotes: 479686,
    selfemployedMortgages: 1694724,
    selfemployedOther: 160163,
    corporateBonds: 2717248,
    corporateDepositaryLoansExmortgage: 614049,
    corporateMortgages: 494222,
    corporateCommercialPaper: 82481,
    CorporateOther: 901708
  },
  "2004-03-01": {
    corporate: 4873515,
    selfemployed: 2404430,
    selfemployedNotes: 495815,
    selfemployedMortgages: 1743043,
    selfemployedOther: 165572,
    corporateBonds: 2725498,
    corporateDepositaryLoansExmortgage: 582204,
    corporateMortgages: 533028,
    corporateCommercialPaper: 85428,
    CorporateOther: 947357
  },
  "2004-06-01": {
    corporate: 4876058,
    selfemployed: 2464009,
    selfemployedNotes: 501209,
    selfemployedMortgages: 1800437,
    selfemployedOther: 162362,
    corporateBonds: 2707998,
    corporateDepositaryLoansExmortgage: 584001,
    corporateMortgages: 545270,
    corporateCommercialPaper: 90139,
    CorporateOther: 948650
  },
  "2004-09-01": {
    corporate: 4936680,
    selfemployed: 2525881,
    selfemployedNotes: 506838,
    selfemployedMortgages: 1857888,
    selfemployedOther: 161156,
    corporateBonds: 2704071,
    corporateDepositaryLoansExmortgage: 597454,
    corporateMortgages: 575154,
    corporateCommercialPaper: 88258,
    CorporateOther: 971743
  },
  "2004-12-01": {
    corporate: 4986603,
    selfemployed: 2588536,
    selfemployedNotes: 512638,
    selfemployedMortgages: 1915334,
    selfemployedOther: 160564,
    corporateBonds: 2696808,
    corporateDepositaryLoansExmortgage: 618002,
    corporateMortgages: 580957,
    corporateCommercialPaper: 97782,
    CorporateOther: 993054
  },
  "2005-03-01": {
    corporate: 5046224,
    selfemployed: 2667214,
    selfemployedNotes: 551756,
    selfemployedMortgages: 1959604,
    selfemployedOther: 155854,
    corporateBonds: 2691178,
    corporateDepositaryLoansExmortgage: 618003,
    corporateMortgages: 598716,
    corporateCommercialPaper: 105194,
    CorporateOther: 1033133
  },
  "2005-06-01": {
    corporate: 5111041,
    selfemployed: 2751914,
    selfemployedNotes: 590594,
    selfemployedMortgages: 2003922,
    selfemployedOther: 157399,
    corporateBonds: 2671393,
    corporateDepositaryLoansExmortgage: 631867,
    corporateMortgages: 650707,
    corporateCommercialPaper: 96668,
    CorporateOther: 1060406
  },
  "2005-09-01": {
    corporate: 5152772,
    selfemployed: 2838609,
    selfemployedNotes: 630606,
    selfemployedMortgages: 2048366,
    selfemployedOther: 159638,
    corporateBonds: 2667716,
    corporateDepositaryLoansExmortgage: 596593,
    corporateMortgages: 698706,
    corporateCommercialPaper: 93471,
    CorporateOther: 1096286
  },
  "2005-12-01": {
    corporate: 5253518,
    selfemployed: 2927083,
    selfemployedNotes: 670528,
    selfemployedMortgages: 2092868,
    selfemployedOther: 163688,
    corporateBonds: 2659502,
    corporateDepositaryLoansExmortgage: 590882,
    corporateMortgages: 766386,
    corporateCommercialPaper: 90071,
    CorporateOther: 1146677
  },
  "2006-03-01": {
    corporate: 5360010,
    selfemployed: 3030166,
    selfemployedNotes: 700021,
    selfemployedMortgages: 2163634,
    selfemployedOther: 166511,
    corporateBonds: 2672344,
    corporateDepositaryLoansExmortgage: 614702,
    corporateMortgages: 795794,
    corporateCommercialPaper: 84380,
    CorporateOther: 1192790
  },
  "2006-06-01": {
    corporate: 5463968,
    selfemployed: 3135485,
    selfemployedNotes: 728299,
    selfemployedMortgages: 2234469,
    selfemployedOther: 172717,
    corporateBonds: 2698065,
    corporateDepositaryLoansExmortgage: 623343,
    corporateMortgages: 811413,
    corporateCommercialPaper: 90114,
    CorporateOther: 1241033
  },
  "2006-09-01": {
    corporate: 5505570,
    selfemployed: 3238717,
    selfemployedNotes: 757362,
    selfemployedMortgages: 2305284,
    selfemployedOther: 176071,
    corporateBonds: 2710934,
    corporateDepositaryLoansExmortgage: 619412,
    corporateMortgages: 836781,
    corporateCommercialPaper: 84744,
    CorporateOther: 1253699
  },
  "2006-12-01": {
    corporate: 5658388,
    selfemployed: 3342403,
    selfemployedNotes: 785821,
    selfemployedMortgages: 2376134,
    selfemployedOther: 180449,
    corporateBonds: 2736284,
    corporateDepositaryLoansExmortgage: 612332,
    corporateMortgages: 871797,
    corporateCommercialPaper: 112517,
    CorporateOther: 1325458
  },
  "2007-03-01": {
    corporate: 5790482,
    selfemployed: 3459542,
    selfemployedNotes: 822972,
    selfemployedMortgages: 2452954,
    selfemployedOther: 183616,
    corporateBonds: 2762600,
    corporateDepositaryLoansExmortgage: 623918,
    corporateMortgages: 879501,
    corporateCommercialPaper: 117252,
    CorporateOther: 1407211
  },
  "2007-06-01": {
    corporate: 5978377,
    selfemployed: 3574817,
    selfemployedNotes: 857313,
    selfemployedMortgages: 2529743,
    selfemployedOther: 187761,
    corporateBonds: 2812726,
    corporateDepositaryLoansExmortgage: 637117,
    corporateMortgages: 898258,
    corporateCommercialPaper: 127487,
    CorporateOther: 1502789
  },
  "2007-09-01": {
    corporate: 6182917,
    selfemployed: 3690547,
    selfemployedNotes: 891653,
    selfemployedMortgages: 2606544,
    selfemployedOther: 192350,
    corporateBonds: 2834893,
    corporateDepositaryLoansExmortgage: 687472,
    corporateMortgages: 931826,
    corporateCommercialPaper: 119826,
    CorporateOther: 1608900
  },
  "2007-12-01": {
    corporate: 6329327,
    selfemployed: 3806854,
    selfemployedNotes: 926671,
    selfemployedMortgages: 2683283,
    selfemployedOther: 196900,
    corporateBonds: 2861113,
    corporateDepositaryLoansExmortgage: 714425,
    corporateMortgages: 946352,
    corporateCommercialPaper: 123814,
    CorporateOther: 1683623
  },
  "2008-03-01": {
    corporate: 6445212,
    selfemployed: 3893115,
    selfemployedNotes: 957805,
    selfemployedMortgages: 2730247,
    selfemployedOther: 205063,
    corporateBonds: 2881548,
    corporateDepositaryLoansExmortgage: 761302,
    corporateMortgages: 953506,
    corporateCommercialPaper: 134532,
    CorporateOther: 1714324
  },
  "2008-06-01": {
    corporate: 6563456,
    selfemployed: 3980160,
    selfemployedNotes: 987880,
    selfemployedMortgages: 2777088,
    selfemployedOther: 215192,
    corporateBonds: 2954630,
    corporateDepositaryLoansExmortgage: 774130,
    corporateMortgages: 950771,
    corporateCommercialPaper: 115214,
    CorporateOther: 1768711
  },
  "2008-09-01": {
    corporate: 6629884,
    selfemployed: 4051336,
    selfemployedNotes: 1016983,
    selfemployedMortgages: 2823818,
    selfemployedOther: 210534,
    corporateBonds: 2970293,
    corporateDepositaryLoansExmortgage: 798328,
    corporateMortgages: 913732,
    corporateCommercialPaper: 130923,
    CorporateOther: 1816608
  },
  "2008-12-01": {
    corporate: 6584352,
    selfemployed: 4125384,
    selfemployedNotes: 1046270,
    selfemployedMortgages: 2870362,
    selfemployedOther: 208751,
    corporateBonds: 2986970,
    corporateDepositaryLoansExmortgage: 777496,
    corporateMortgages: 871877,
    corporateCommercialPaper: 131477,
    CorporateOther: 1816532
  },
  "2009-03-01": {
    corporate: 6510785,
    selfemployed: 4112791,
    selfemployedNotes: 1036319,
    selfemployedMortgages: 2871425,
    selfemployedOther: 205047,
    corporateBonds: 3077982,
    corporateDepositaryLoansExmortgage: 721385,
    corporateMortgages: 855631,
    corporateCommercialPaper: 96783,
    CorporateOther: 1759004
  },
  "2009-06-01": {
    corporate: 6451310,
    selfemployed: 4082831,
    selfemployedNotes: 1005145,
    selfemployedMortgages: 2870564,
    selfemployedOther: 207122,
    corporateBonds: 3144946,
    corporateDepositaryLoansExmortgage: 665428,
    corporateMortgages: 840789,
    corporateCommercialPaper: 63586,
    CorporateOther: 1736561
  },
  "2009-09-01": {
    corporate: 6351092,
    selfemployed: 4049485,
    selfemployedNotes: 975075,
    selfemployedMortgages: 2869722,
    selfemployedOther: 204688,
    corporateBonds: 3167974,
    corporateDepositaryLoansExmortgage: 598522,
    corporateMortgages: 805305,
    corporateCommercialPaper: 64093,
    CorporateOther: 1715198
  },
  "2009-12-01": {
    corporate: 6175892,
    selfemployed: 4016244,
    selfemployedNotes: 944299,
    selfemployedMortgages: 2869138,
    selfemployedOther: 202807,
    corporateBonds: 3197060,
    corporateDepositaryLoansExmortgage: 549098,
    corporateMortgages: 762492,
    corporateCommercialPaper: 58410,
    CorporateOther: 1608832
  },
  "2010-03-01": {
    corporate: 6157000,
    selfemployed: 4010068,
    selfemployedNotes: 943299,
    selfemployedMortgages: 2865072,
    selfemployedOther: 201697,
    corporateBonds: 3244612,
    corporateDepositaryLoansExmortgage: 507370,
    corporateMortgages: 743756,
    corporateCommercialPaper: 70974,
    CorporateOther: 1590288
  },
  "2010-06-01": {
    corporate: 6096360,
    selfemployed: 4002556,
    selfemployedNotes: 937682,
    selfemployedMortgages: 2861024,
    selfemployedOther: 203850,
    corporateBonds: 3270441,
    corporateDepositaryLoansExmortgage: 473908,
    corporateMortgages: 703110,
    corporateCommercialPaper: 86651,
    CorporateOther: 1562250
  },
  "2010-09-01": {
    corporate: 6138109,
    selfemployed: 3998548,
    selfemployedNotes: 932627,
    selfemployedMortgages: 2856994,
    selfemployedOther: 208927,
    corporateBonds: 3339218,
    corporateDepositaryLoansExmortgage: 469486,
    corporateMortgages: 675092,
    corporateCommercialPaper: 97592,
    CorporateOther: 1556721
  },
  "2010-12-01": {
    corporate: 6073388,
    selfemployed: 3986717,
    selfemployedNotes: 927538,
    selfemployedMortgages: 2852938,
    selfemployedOther: 206242,
    corporateBonds: 3385239,
    corporateDepositaryLoansExmortgage: 482473,
    corporateMortgages: 673336,
    corporateCommercialPaper: 82928,
    CorporateOther: 1449412
  },
  "2011-03-01": {
    corporate: 6085209,
    selfemployed: 3968509,
    selfemployedNotes: 924426,
    selfemployedMortgages: 2841118,
    selfemployedOther: 202966,
    corporateBonds: 3408834,
    corporateDepositaryLoansExmortgage: 505089,
    corporateMortgages: 627950,
    corporateCommercialPaper: 89378,
    CorporateOther: 1453958
  },
  "2011-06-01": {
    corporate: 6181049,
    selfemployed: 3947890,
    selfemployedNotes: 915708,
    selfemployedMortgages: 2831654,
    selfemployedOther: 200528,
    corporateBonds: 3464113,
    corporateDepositaryLoansExmortgage: 529849,
    corporateMortgages: 597338,
    corporateCommercialPaper: 101762,
    CorporateOther: 1487987
  },
  "2011-09-01": {
    corporate: 6273637,
    selfemployed: 3926657,
    selfemployedNotes: 908528,
    selfemployedMortgages: 2822140,
    selfemployedOther: 195989,
    corporateBonds: 3506543,
    corporateDepositaryLoansExmortgage: 584095,
    corporateMortgages: 574179,
    corporateCommercialPaper: 109177,
    CorporateOther: 1499643
  },
  "2011-12-01": {
    corporate: 6383624,
    selfemployed: 3912415,
    selfemployedNotes: 901515,
    selfemployedMortgages: 2812490,
    selfemployedOther: 198410,
    corporateBonds: 3544036,
    corporateDepositaryLoansExmortgage: 629420,
    corporateMortgages: 576157,
    corporateCommercialPaper: 116307,
    CorporateOther: 1517704
  },
  "2012-03-01": {
    corporate: 6420477,
    selfemployed: 3955327,
    selfemployedNotes: 921225,
    selfemployedMortgages: 2836406,
    selfemployedOther: 197697,
    corporateBonds: 3620952,
    corporateDepositaryLoansExmortgage: 640558,
    corporateMortgages: 536186,
    corporateCommercialPaper: 114324,
    CorporateOther: 1508457
  },
  "2012-06-01": {
    corporate: 6483930,
    selfemployed: 4000124,
    selfemployedNotes: 937240,
    selfemployedMortgages: 2860209,
    selfemployedOther: 202675,
    corporateBonds: 3680901,
    corporateDepositaryLoansExmortgage: 669144,
    corporateMortgages: 504640,
    corporateCommercialPaper: 121582,
    CorporateOther: 1507663
  },
  "2012-09-01": {
    corporate: 6610699,
    selfemployed: 4042242,
    selfemployedNotes: 952146,
    selfemployedMortgages: 2883874,
    selfemployedOther: 206223,
    corporateBonds: 3783621,
    corporateDepositaryLoansExmortgage: 684709,
    corporateMortgages: 479098,
    corporateCommercialPaper: 112715,
    CorporateOther: 1550556
  },
  "2012-12-01": {
    corporate: 6753909,
    selfemployed: 4085205,
    selfemployedNotes: 967444,
    selfemployedMortgages: 2907477,
    selfemployedOther: 210284,
    corporateBonds: 3897060,
    corporateDepositaryLoansExmortgage: 711877,
    corporateMortgages: 479607,
    corporateCommercialPaper: 130329,
    CorporateOther: 1535036
  },
  "2013-03-01": {
    corporate: 6801645,
    selfemployed: 4107208,
    selfemployedNotes: 973180,
    selfemployedMortgages: 2926627,
    selfemployedOther: 207402,
    corporateBonds: 3941302,
    corporateDepositaryLoansExmortgage: 697785,
    corporateMortgages: 463469,
    corporateCommercialPaper: 135344,
    CorporateOther: 1563745
  },
  "2013-06-01": {
    corporate: 6910471,
    selfemployed: 4134432,
    selfemployedNotes: 978068,
    selfemployedMortgages: 2945871,
    selfemployedOther: 210494,
    corporateBonds: 3997291,
    corporateDepositaryLoansExmortgage: 711246,
    corporateMortgages: 466882,
    corporateCommercialPaper: 130314,
    CorporateOther: 1604738
  },
  "2013-09-01": {
    corporate: 7080284,
    selfemployed: 4160444,
    selfemployedNotes: 983512,
    selfemployedMortgages: 2965032,
    selfemployedOther: 211900,
    corporateBonds: 4108728,
    corporateDepositaryLoansExmortgage: 731257,
    corporateMortgages: 490878,
    corporateCommercialPaper: 153315,
    CorporateOther: 1596106
  },
  "2013-12-01": {
    corporate: 7162493,
    selfemployed: 4189634,
    selfemployedNotes: 988998,
    selfemployedMortgages: 2984083,
    selfemployedOther: 216553,
    corporateBonds: 4153094,
    corporateDepositaryLoansExmortgage: 765461,
    corporateMortgages: 487475,
    corporateCommercialPaper: 144511,
    CorporateOther: 1611952
  },
  "2014-03-01": {
    corporate: 7275427,
    selfemployed: 4257349,
    selfemployedNotes: 1011341,
    selfemployedMortgages: 3030360,
    selfemployedOther: 215649,
    corporateBonds: 4217347,
    corporateDepositaryLoansExmortgage: 779946,
    corporateMortgages: 472166,
    corporateCommercialPaper: 163148,
    CorporateOther: 1642820
  },
  "2014-06-01": {
    corporate: 7372643,
    selfemployed: 4326220,
    selfemployedNotes: 1030991,
    selfemployedMortgages: 3076434,
    selfemployedOther: 218795,
    corporateBonds: 4291063,
    corporateDepositaryLoansExmortgage: 812121,
    corporateMortgages: 467487,
    corporateCommercialPaper: 177492,
    CorporateOther: 1624480
  },
  "2014-09-01": {
    corporate: 7486604,
    selfemployed: 4396727,
    selfemployedNotes: 1052045,
    selfemployedMortgages: 3122700,
    selfemployedOther: 221982,
    corporateBonds: 4340523,
    corporateDepositaryLoansExmortgage: 833693,
    corporateMortgages: 461382,
    corporateCommercialPaper: 182924,
    CorporateOther: 1668082
  },
  "2014-12-01": {
    corporate: 7648777,
    selfemployed: 4472411,
    selfemployedNotes: 1075202,
    selfemployedMortgages: 3169445,
    selfemployedOther: 227764,
    corporateBonds: 4436975,
    corporateDepositaryLoansExmortgage: 865484,
    corporateMortgages: 470369,
    corporateCommercialPaper: 182012,
    CorporateOther: 1693937
  },
  "2015-03-01": {
    corporate: 7801319,
    selfemployed: 4528935,
    selfemployedNotes: 1091840,
    selfemployedMortgages: 3213410,
    selfemployedOther: 223685,
    corporateBonds: 4544814,
    corporateDepositaryLoansExmortgage: 884657,
    corporateMortgages: 483767,
    corporateCommercialPaper: 167312,
    CorporateOther: 1720769
  },
  "2015-06-01": {
    corporate: 8000316,
    selfemployed: 4592387,
    selfemployedNotes: 1106649,
    selfemployedMortgages: 3257889,
    selfemployedOther: 227849,
    corporateBonds: 4706070,
    corporateDepositaryLoansExmortgage: 913675,
    corporateMortgages: 507945,
    corporateCommercialPaper: 154605,
    CorporateOther: 1718021
  },
  "2015-09-01": {
    corporate: 8105107,
    selfemployed: 4657408,
    selfemployedNotes: 1122401,
    selfemployedMortgages: 3302799,
    selfemployedOther: 232208,
    corporateBonds: 4790083,
    corporateDepositaryLoansExmortgage: 925245,
    corporateMortgages: 518988,
    corporateCommercialPaper: 172778,
    CorporateOther: 1698013
  },
  "2015-12-01": {
    corporate: 8210988,
    selfemployed: 4720068,
    selfemployedNotes: 1139319,
    selfemployedMortgages: 3347768,
    selfemployedOther: 232981,
    corporateBonds: 4836709,
    corporateDepositaryLoansExmortgage: 956679,
    corporateMortgages: 568482,
    corporateCommercialPaper: 179017,
    CorporateOther: 1670101
  },
  "2016-03-01": {
    corporate: 8390674,
    selfemployed: 4813778,
    selfemployedNotes: 1171858,
    selfemployedMortgages: 3414552,
    selfemployedOther: 227368,
    corporateBonds: 4967229,
    corporateDepositaryLoansExmortgage: 985721,
    corporateMortgages: 555094,
    corporateCommercialPaper: 185234,
    CorporateOther: 1697396
  },
  "2016-06-01": {
    corporate: 8435157,
    selfemployed: 4911365,
    selfemployedNotes: 1199556,
    selfemployedMortgages: 3481535,
    selfemployedOther: 230274,
    corporateBonds: 5046011,
    corporateDepositaryLoansExmortgage: 981060,
    corporateMortgages: 549510,
    corporateCommercialPaper: 186629,
    CorporateOther: 1671947
  },
  "2016-09-01": {
    corporate: 8529677,
    selfemployed: 5006944,
    selfemployedNotes: 1229176,
    selfemployedMortgages: 3548450,
    selfemployedOther: 229319,
    corporateBonds: 5125173,
    corporateDepositaryLoansExmortgage: 987651,
    corporateMortgages: 575786,
    corporateCommercialPaper: 151010,
    CorporateOther: 1690057
  },
  "2016-12-01": {
    corporate: 8480356,
    selfemployed: 5107511,
    selfemployedNotes: 1258441,
    selfemployedMortgages: 3615247,
    selfemployedOther: 233823,
    corporateBonds: 5109003,
    corporateDepositaryLoansExmortgage: 951779,
    corporateMortgages: 570100,
    corporateCommercialPaper: 180783,
    CorporateOther: 1668691
  },
  "2017-03-01": {
    corporate: 8627712,
    selfemployed: 5221464,
    selfemployedNotes: 1292154,
    selfemployedMortgages: 3697802,
    selfemployedOther: 231508,
    corporateBonds: 5219968,
    corporateDepositaryLoansExmortgage: 932172,
    corporateMortgages: 544025,
    corporateCommercialPaper: 183634,
    CorporateOther: 1747913
  },
  "2017-06-01": {
    corporate: 8750396,
    selfemployed: 5336896,
    selfemployedNotes: 1323611,
    selfemployedMortgages: 3780232,
    selfemployedOther: 233053,
    corporateBonds: 5293124,
    corporateDepositaryLoansExmortgage: 919658,
    corporateMortgages: 535205,
    corporateCommercialPaper: 191769,
    CorporateOther: 1810640
  },
  "2017-09-01": {
    corporate: 8830239,
    selfemployed: 5452508,
    selfemployedNotes: 1355327,
    selfemployedMortgages: 3862660,
    selfemployedOther: 234521,
    corporateBonds: 5361906,
    corporateDepositaryLoansExmortgage: 907881,
    corporateMortgages: 515363,
    corporateCommercialPaper: 208973,
    CorporateOther: 1836116
  },
  "2017-12-01": {
    corporate: 8975798,
    selfemployed: 5571415,
    selfemployedNotes: 1387291,
    selfemployedMortgages: 3945158,
    selfemployedOther: 238965,
    corporateBonds: 5394151,
    corporateDepositaryLoansExmortgage: 899629,
    corporateMortgages: 536211,
    corporateCommercialPaper: 206670,
    CorporateOther: 1939137
  },
  "2018-03-01": {
    corporate: 9072716,
    selfemployed: 5641521,
    selfemployedNotes: 1407350,
    selfemployedMortgages: 3996347,
    selfemployedOther: 237825,
    corporateBonds: 5464154,
    corporateDepositaryLoansExmortgage: 900104,
    corporateMortgages: 553584,
    corporateCommercialPaper: 227846,
    CorporateOther: 1927028
  },
  "2018-06-01": {
    corporate: 9450758,
    selfemployed: 5712892,
    selfemployedNotes: 1425863,
    selfemployedMortgages: 4047777,
    selfemployedOther: 239252,
    corporateBonds: 5465717,
    corporateDepositaryLoansExmortgage: 926197,
    corporateMortgages: 591321,
    corporateCommercialPaper: 233228,
    CorporateOther: 2234295
  },
  "2018-09-01": {
    corporate: 9565251,
    selfemployed: 5787943,
    selfemployedNotes: 1445730,
    selfemployedMortgages: 4099222,
    selfemployedOther: 242991,
    corporateBonds: 5529203,
    corporateDepositaryLoansExmortgage: 924209,
    corporateMortgages: 603278,
    corporateCommercialPaper: 232290,
    CorporateOther: 2276271
  },
  "2018-12-01": {
    corporate: 9665937,
    selfemployed: 5863601,
    selfemployedNotes: 1465184,
    selfemployedMortgages: 4150657,
    selfemployedOther: 247761,
    corporateBonds: 5559140,
    corporateDepositaryLoansExmortgage: 1003050,
    corporateMortgages: 626489,
    corporateCommercialPaper: 196467,
    CorporateOther: 2280791
  },
  "2019-03-01": {
    corporate: 9864577,
    selfemployed: 5928014,
    selfemployedNotes: 1477818,
    selfemployedMortgages: 4202874,
    selfemployedOther: 247323,
    corporateBonds: 5648129,
    corporateDepositaryLoansExmortgage: 1023713,
    corporateMortgages: 645514,
    corporateCommercialPaper: 218420,
    CorporateOther: 2328801
  },
  "2019-06-01": {
    corporate: 9971381,
    selfemployed: 5987965,
    selfemployedNotes: 1485335,
    selfemployedMortgages: 4251805,
    selfemployedOther: 250825,
    corporateBonds: 5725163,
    corporateDepositaryLoansExmortgage: 1034663,
    corporateMortgages: 653313,
    corporateCommercialPaper: 203010,
    CorporateOther: 2355232
  },
  "2019-09-01": {
    corporate: 10128152,
    selfemployed: 6064409,
    selfemployedNotes: 1478526,
    selfemployedMortgages: 4334541,
    selfemployedOther: 251342,
    corporateBonds: 5816955,
    corporateDepositaryLoansExmortgage: 1041411,
    corporateMortgages: 674393,
    corporateCommercialPaper: 200412,
    CorporateOther: 2394981
  },
  "2019-12-01": {
    corporate: 10146999,
    selfemployed: 6136501,
    selfemployedNotes: 1481208,
    selfemployedMortgages: 4398904,
    selfemployedOther: 256388,
    corporateBonds: 5815713,
    corporateDepositaryLoansExmortgage: 1033990,
    corporateMortgages: 678620,
    corporateCommercialPaper: 194530,
    CorporateOther: 2424146
  },
  "2020-03-01": {
    corporate: 10756185,
    selfemployed: 6295849,
    selfemployedNotes: 1573440,
    selfemployedMortgages: 4462267,
    selfemployedOther: 260143,
    corporateBonds: 5998461,
    corporateDepositaryLoansExmortgage: 1367638,
    corporateMortgages: 692952,
    corporateCommercialPaper: 189173,
    CorporateOther: 2507961
  },
  "2020-06-01": {
    corporate: 11168728,
    selfemployed: 6532011,
    selfemployedNotes: 1709705,
    selfemployedMortgages: 4518915,
    selfemployedOther: 303392,
    corporateBonds: 6433686,
    corporateDepositaryLoansExmortgage: 1366931,
    corporateMortgages: 703235,
    corporateCommercialPaper: 121359,
    CorporateOther: 2543517
  },
  "2020-09-01": {
    corporate: 11080080,
    selfemployed: 6626302,
    selfemployedNotes: 1716276,
    selfemployedMortgages: 4575561,
    selfemployedOther: 334465,
    corporateBonds: 6492671,
    corporateDepositaryLoansExmortgage: 1178190,
    corporateMortgages: 713735,
    corporateCommercialPaper: 103707,
    CorporateOther: 2591777
  },
  "2020-12-01": {
    corporate: 11074946,
    selfemployed: 6653346,
    selfemployedNotes: 1654539,
    selfemployedMortgages: 4646361,
    selfemployedOther: 352447,
    corporateBonds: 6490923,
    corporateDepositaryLoansExmortgage: 1111758,
    corporateMortgages: 724039,
    corporateCommercialPaper: 132202,
    CorporateOther: 2616024
  },
  "2021-03-01": {
    corporate: 11215097,
    selfemployed: 6716323,
    selfemployedNotes: 1654623,
    selfemployedMortgages: 4699068,
    selfemployedOther: 362632,
    corporateBonds: 6604261,
    corporateDepositaryLoansExmortgage: 1119953,
    corporateMortgages: 745489,
    corporateCommercialPaper: 79093,
    CorporateOther: 2666301
  },
  "2021-06-01": {
    corporate: 11260712,
    selfemployed: 6752849,
    selfemployedNotes: 1607599,
    selfemployedMortgages: 4757266,
    selfemployedOther: 387984,
    corporateBonds: 6622345,
    corporateDepositaryLoansExmortgage: 1038852,
    corporateMortgages: 773906,
    corporateCommercialPaper: 110147,
    CorporateOther: 2715462
  },
  "2021-09-01": {
    corporate: 11458088,
    selfemployed: 6778996,
    selfemployedNotes: 1548391,
    selfemployedMortgages: 4827513,
    selfemployedOther: 403093,
    corporateBonds: 6690997,
    corporateDepositaryLoansExmortgage: 1005716,
    corporateMortgages: 812894,
    corporateCommercialPaper: 123108,
    CorporateOther: 2825373
  },
  "2021-12-01": {
    corporate: 11650471,
    selfemployed: 6890532,
    selfemployedNotes: 1569229,
    selfemployedMortgages: 4911439,
    selfemployedOther: 409864,
    corporateBonds: 6652249,
    corporateDepositaryLoansExmortgage: 1066571,
    corporateMortgages: 859285,
    corporateCommercialPaper: 138243,
    CorporateOther: 2934123
  }
};

/**Bond (collateralized, corporateOrSelf), notes (financial) */

export const creditsofdebtoutstanding = {
  "1952-03-01": {
    households: 0.193,
    corporateOrSelf: 0.238,
    government: 0.511,
    municipalities: 0.058,
    corporateOrSelfCorporate: 0.759,
    corporateOrSelfSelf: 0.241,
    selfemployedNotes: 0.185,
    selfemployedMortgages: 0.642,
    selfemployedOther: 0.173,
    corporateCorporate: 0.511,
    corporateDepositaryLoansExMortgage: 0.278,
    corporateMortgages: 0.167,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.04
  },
  "1952-06-01": {
    households: 0.197,
    corporateOrSelf: 0.238,
    government: 0.503,
    municipalities: 0.063,
    corporateOrSelfCorporate: 0.756,
    corporateOrSelfSelf: 0.244,
    selfemployedNotes: 0.184,
    selfemployedMortgages: 0.648,
    selfemployedOther: 0.169,
    corporateCorporate: 0.52,
    corporateDepositaryLoansExMortgage: 0.272,
    corporateMortgages: 0.163,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.041
  },
  "1952-09-01": {
    households: 0.199,
    corporateOrSelf: 0.239,
    government: 0.497,
    municipalities: 0.064,
    corporateOrSelfCorporate: 0.755,
    corporateOrSelfSelf: 0.245,
    selfemployedNotes: 0.182,
    selfemployedMortgages: 0.652,
    selfemployedOther: 0.166,
    corporateCorporate: 0.523,
    corporateDepositaryLoansExMortgage: 0.271,
    corporateMortgages: 0.159,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.043
  },
  "1952-12-01": {
    households: 0.204,
    corporateOrSelf: 0.24,
    government: 0.49,
    municipalities: 0.066,
    corporateOrSelfCorporate: 0.756,
    corporateOrSelfSelf: 0.244,
    selfemployedNotes: 0.18,
    selfemployedMortgages: 0.649,
    selfemployedOther: 0.171,
    corporateCorporate: 0.523,
    corporateDepositaryLoansExMortgage: 0.27,
    corporateMortgages: 0.158,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.045
  },
  "1953-03-01": {
    households: 0.209,
    corporateOrSelf: 0.24,
    government: 0.484,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.759,
    corporateOrSelfSelf: 0.241,
    selfemployedNotes: 0.178,
    selfemployedMortgages: 0.652,
    selfemployedOther: 0.17,
    corporateCorporate: 0.522,
    corporateDepositaryLoansExMortgage: 0.27,
    corporateMortgages: 0.159,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.046
  },
  "1953-06-01": {
    households: 0.213,
    corporateOrSelf: 0.239,
    government: 0.479,
    municipalities: 0.069,
    corporateOrSelfCorporate: 0.758,
    corporateOrSelfSelf: 0.242,
    selfemployedNotes: 0.173,
    selfemployedMortgages: 0.661,
    selfemployedOther: 0.166,
    corporateCorporate: 0.526,
    corporateDepositaryLoansExMortgage: 0.265,
    corporateMortgages: 0.156,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.049
  },
  "1953-09-01": {
    households: 0.213,
    corporateOrSelf: 0.238,
    government: 0.479,
    municipalities: 0.07,
    corporateOrSelfCorporate: 0.756,
    corporateOrSelfSelf: 0.244,
    selfemployedNotes: 0.175,
    selfemployedMortgages: 0.661,
    selfemployedOther: 0.163,
    corporateCorporate: 0.526,
    corporateDepositaryLoansExMortgage: 0.265,
    corporateMortgages: 0.155,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.051
  },
  "1953-12-01": {
    households: 0.217,
    corporateOrSelf: 0.236,
    government: 0.476,
    municipalities: 0.072,
    corporateOrSelfCorporate: 0.759,
    corporateOrSelfSelf: 0.241,
    selfemployedNotes: 0.156,
    selfemployedMortgages: 0.675,
    selfemployedOther: 0.168,
    corporateCorporate: 0.538,
    corporateDepositaryLoansExMortgage: 0.251,
    corporateMortgages: 0.158,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.048
  },
  "1954-03-01": {
    households: 0.218,
    corporateOrSelf: 0.237,
    government: 0.471,
    municipalities: 0.074,
    corporateOrSelfCorporate: 0.758,
    corporateOrSelfSelf: 0.242,
    selfemployedNotes: 0.16,
    selfemployedMortgages: 0.671,
    selfemployedOther: 0.168,
    corporateCorporate: 0.54,
    corporateDepositaryLoansExMortgage: 0.248,
    corporateMortgages: 0.16,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.047
  },
  "1954-06-01": {
    households: 0.221,
    corporateOrSelf: 0.236,
    government: 0.467,
    municipalities: 0.075,
    corporateOrSelfCorporate: 0.754,
    corporateOrSelfSelf: 0.246,
    selfemployedNotes: 0.157,
    selfemployedMortgages: 0.681,
    selfemployedOther: 0.163,
    corporateCorporate: 0.548,
    corporateDepositaryLoansExMortgage: 0.243,
    corporateMortgages: 0.157,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.046
  },
  "1954-09-01": {
    households: 0.225,
    corporateOrSelf: 0.237,
    government: 0.461,
    municipalities: 0.077,
    corporateOrSelfCorporate: 0.75,
    corporateOrSelfSelf: 0.25,
    selfemployedNotes: 0.16,
    selfemployedMortgages: 0.68,
    selfemployedOther: 0.16,
    corporateCorporate: 0.555,
    corporateDepositaryLoansExMortgage: 0.238,
    corporateMortgages: 0.155,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.046
  },
  "1954-12-01": {
    households: 0.229,
    corporateOrSelf: 0.236,
    government: 0.457,
    municipalities: 0.078,
    corporateOrSelfCorporate: 0.75,
    corporateOrSelfSelf: 0.25,
    selfemployedNotes: 0.151,
    selfemployedMortgages: 0.685,
    selfemployedOther: 0.164,
    corporateCorporate: 0.555,
    corporateDepositaryLoansExMortgage: 0.234,
    corporateMortgages: 0.158,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.047
  },
  "1955-03-01": {
    households: 0.236,
    corporateOrSelf: 0.235,
    government: 0.449,
    municipalities: 0.08,
    corporateOrSelfCorporate: 0.748,
    corporateOrSelfSelf: 0.252,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.685,
    selfemployedOther: 0.165,
    corporateCorporate: 0.554,
    corporateDepositaryLoansExMortgage: 0.239,
    corporateMortgages: 0.159,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.042
  },
  "1955-06-01": {
    households: 0.242,
    corporateOrSelf: 0.237,
    government: 0.441,
    municipalities: 0.08,
    corporateOrSelfCorporate: 0.744,
    corporateOrSelfSelf: 0.256,
    selfemployedNotes: 0.156,
    selfemployedMortgages: 0.684,
    selfemployedOther: 0.161,
    corporateCorporate: 0.55,
    corporateDepositaryLoansExMortgage: 0.244,
    corporateMortgages: 0.158,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.043
  },
  "1955-09-01": {
    households: 0.247,
    corporateOrSelf: 0.238,
    government: 0.433,
    municipalities: 0.081,
    corporateOrSelfCorporate: 0.741,
    corporateOrSelfSelf: 0.259,
    selfemployedNotes: 0.16,
    selfemployedMortgages: 0.68,
    selfemployedOther: 0.159,
    corporateCorporate: 0.545,
    corporateDepositaryLoansExMortgage: 0.25,
    corporateMortgages: 0.157,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.043
  },
  "1955-12-01": {
    households: 0.251,
    corporateOrSelf: 0.241,
    government: 0.426,
    municipalities: 0.082,
    corporateOrSelfCorporate: 0.744,
    corporateOrSelfSelf: 0.256,
    selfemployedNotes: 0.165,
    selfemployedMortgages: 0.675,
    selfemployedOther: 0.16,
    corporateCorporate: 0.539,
    corporateDepositaryLoansExMortgage: 0.254,
    corporateMortgages: 0.16,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.044
  },
  "1956-03-01": {
    households: 0.255,
    corporateOrSelf: 0.244,
    government: 0.418,
    municipalities: 0.083,
    corporateOrSelfCorporate: 0.745,
    corporateOrSelfSelf: 0.255,
    selfemployedNotes: 0.165,
    selfemployedMortgages: 0.678,
    selfemployedOther: 0.157,
    corporateCorporate: 0.53,
    corporateDepositaryLoansExMortgage: 0.265,
    corporateMortgages: 0.158,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.044
  },
  "1956-06-01": {
    households: 0.259,
    corporateOrSelf: 0.248,
    government: 0.408,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.745,
    corporateOrSelfSelf: 0.255,
    selfemployedNotes: 0.165,
    selfemployedMortgages: 0.682,
    selfemployedOther: 0.153,
    corporateCorporate: 0.525,
    corporateDepositaryLoansExMortgage: 0.274,
    corporateMortgages: 0.155,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.042
  },
  "1956-09-01": {
    households: 0.262,
    corporateOrSelf: 0.251,
    government: 0.403,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.743,
    corporateOrSelfSelf: 0.257,
    selfemployedNotes: 0.169,
    selfemployedMortgages: 0.68,
    selfemployedOther: 0.151,
    corporateCorporate: 0.525,
    corporateDepositaryLoansExMortgage: 0.276,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.042
  },
  "1956-12-01": {
    households: 0.265,
    corporateOrSelf: 0.252,
    government: 0.398,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.745,
    corporateOrSelfSelf: 0.255,
    selfemployedNotes: 0.166,
    selfemployedMortgages: 0.678,
    selfemployedOther: 0.156,
    corporateCorporate: 0.523,
    corporateDepositaryLoansExMortgage: 0.277,
    corporateMortgages: 0.154,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.042
  },
  "1957-03-01": {
    households: 0.267,
    corporateOrSelf: 0.253,
    government: 0.394,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.749,
    corporateOrSelfSelf: 0.251,
    selfemployedNotes: 0.164,
    selfemployedMortgages: 0.679,
    selfemployedOther: 0.157,
    corporateCorporate: 0.523,
    corporateDepositaryLoansExMortgage: 0.275,
    corporateMortgages: 0.155,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.044
  },
  "1957-06-01": {
    households: 0.271,
    corporateOrSelf: 0.258,
    government: 0.384,
    municipalities: 0.087,
    corporateOrSelfCorporate: 0.75,
    corporateOrSelfSelf: 0.25,
    selfemployedNotes: 0.163,
    selfemployedMortgages: 0.683,
    selfemployedOther: 0.153,
    corporateCorporate: 0.522,
    corporateDepositaryLoansExMortgage: 0.28,
    corporateMortgages: 0.152,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.043
  },
  "1957-09-01": {
    households: 0.272,
    corporateOrSelf: 0.259,
    government: 0.382,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.749,
    corporateOrSelfSelf: 0.251,
    selfemployedNotes: 0.162,
    selfemployedMortgages: 0.686,
    selfemployedOther: 0.153,
    corporateCorporate: 0.526,
    corporateDepositaryLoansExMortgage: 0.274,
    corporateMortgages: 0.15,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.047
  },
  "1957-12-01": {
    households: 0.273,
    corporateOrSelf: 0.261,
    government: 0.377,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.749,
    corporateOrSelfSelf: 0.251,
    selfemployedNotes: 0.167,
    selfemployedMortgages: 0.681,
    selfemployedOther: 0.153,
    corporateCorporate: 0.531,
    corporateDepositaryLoansExMortgage: 0.265,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.047
  },
  "1958-03-01": {
    households: 0.272,
    corporateOrSelf: 0.261,
    government: 0.376,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.75,
    corporateOrSelfSelf: 0.25,
    selfemployedNotes: 0.163,
    selfemployedMortgages: 0.683,
    selfemployedOther: 0.154,
    corporateCorporate: 0.538,
    corporateDepositaryLoansExMortgage: 0.255,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.049
  },
  "1958-06-01": {
    households: 0.271,
    corporateOrSelf: 0.261,
    government: 0.377,
    municipalities: 0.091,
    corporateOrSelfCorporate: 0.748,
    corporateOrSelfSelf: 0.252,
    selfemployedNotes: 0.164,
    selfemployedMortgages: 0.685,
    selfemployedOther: 0.151,
    corporateCorporate: 0.542,
    corporateDepositaryLoansExMortgage: 0.253,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.046
  },
  "1958-09-01": {
    households: 0.274,
    corporateOrSelf: 0.262,
    government: 0.372,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.745,
    corporateOrSelfSelf: 0.255,
    selfemployedNotes: 0.161,
    selfemployedMortgages: 0.689,
    selfemployedOther: 0.149,
    corporateCorporate: 0.546,
    corporateDepositaryLoansExMortgage: 0.25,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.044
  },
  "1958-12-01": {
    households: 0.275,
    corporateOrSelf: 0.262,
    government: 0.371,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.743,
    corporateOrSelfSelf: 0.257,
    selfemployedNotes: 0.165,
    selfemployedMortgages: 0.686,
    selfemployedOther: 0.149,
    corporateCorporate: 0.545,
    corporateDepositaryLoansExMortgage: 0.247,
    corporateMortgages: 0.158,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.045
  },
  "1959-03-01": {
    households: 0.277,
    corporateOrSelf: 0.262,
    government: 0.368,
    municipalities: 0.093,
    corporateOrSelfCorporate: 0.739,
    corporateOrSelfSelf: 0.261,
    selfemployedNotes: 0.163,
    selfemployedMortgages: 0.687,
    selfemployedOther: 0.15,
    corporateCorporate: 0.544,
    corporateDepositaryLoansExMortgage: 0.248,
    corporateMortgages: 0.156,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.047
  },
  "1959-06-01": {
    households: 0.28,
    corporateOrSelf: 0.264,
    government: 0.363,
    municipalities: 0.093,
    corporateOrSelfCorporate: 0.736,
    corporateOrSelfSelf: 0.264,
    selfemployedNotes: 0.163,
    selfemployedMortgages: 0.69,
    selfemployedOther: 0.147,
    corporateCorporate: 0.538,
    corporateDepositaryLoansExMortgage: 0.258,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.049
  },
  "1959-09-01": {
    households: 0.282,
    corporateOrSelf: 0.263,
    government: 0.36,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.731,
    corporateOrSelfSelf: 0.269,
    selfemployedNotes: 0.163,
    selfemployedMortgages: 0.693,
    selfemployedOther: 0.144,
    corporateCorporate: 0.535,
    corporateDepositaryLoansExMortgage: 0.261,
    corporateMortgages: 0.151,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.049
  },
  "1959-12-01": {
    households: 0.286,
    corporateOrSelf: 0.264,
    government: 0.355,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.731,
    corporateOrSelfSelf: 0.269,
    selfemployedNotes: 0.166,
    selfemployedMortgages: 0.693,
    selfemployedOther: 0.141,
    corporateCorporate: 0.533,
    corporateDepositaryLoansExMortgage: 0.259,
    corporateMortgages: 0.154,
    corporateCommercialPaper: 0.002,
    CorporateOther: 0.052
  },
  "1960-03-01": {
    households: 0.288,
    corporateOrSelf: 0.267,
    government: 0.35,
    municipalities: 0.095,
    corporateOrSelfCorporate: 0.731,
    corporateOrSelfSelf: 0.269,
    selfemployedNotes: 0.165,
    selfemployedMortgages: 0.694,
    selfemployedOther: 0.141,
    corporateCorporate: 0.526,
    corporateDepositaryLoansExMortgage: 0.264,
    corporateMortgages: 0.151,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.057
  },
  "1960-06-01": {
    households: 0.291,
    corporateOrSelf: 0.268,
    government: 0.344,
    municipalities: 0.096,
    corporateOrSelfCorporate: 0.729,
    corporateOrSelfSelf: 0.271,
    selfemployedNotes: 0.163,
    selfemployedMortgages: 0.699,
    selfemployedOther: 0.138,
    corporateCorporate: 0.522,
    corporateDepositaryLoansExMortgage: 0.265,
    corporateMortgages: 0.149,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.06
  },
  "1960-09-01": {
    households: 0.294,
    corporateOrSelf: 0.269,
    government: 0.341,
    municipalities: 0.097,
    corporateOrSelfCorporate: 0.726,
    corporateOrSelfSelf: 0.274,
    selfemployedNotes: 0.16,
    selfemployedMortgages: 0.704,
    selfemployedOther: 0.136,
    corporateCorporate: 0.523,
    corporateDepositaryLoansExMortgage: 0.263,
    corporateMortgages: 0.149,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.06
  },
  "1960-12-01": {
    households: 0.296,
    corporateOrSelf: 0.27,
    government: 0.337,
    municipalities: 0.097,
    corporateOrSelfCorporate: 0.726,
    corporateOrSelfSelf: 0.274,
    selfemployedNotes: 0.161,
    selfemployedMortgages: 0.704,
    selfemployedOther: 0.135,
    corporateCorporate: 0.523,
    corporateDepositaryLoansExMortgage: 0.259,
    corporateMortgages: 0.15,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.063
  },
  "1961-03-01": {
    households: 0.298,
    corporateOrSelf: 0.271,
    government: 0.334,
    municipalities: 0.098,
    corporateOrSelfCorporate: 0.72,
    corporateOrSelfSelf: 0.28,
    selfemployedNotes: 0.158,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.134,
    corporateCorporate: 0.524,
    corporateDepositaryLoansExMortgage: 0.264,
    corporateMortgages: 0.149,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.06
  },
  "1961-06-01": {
    households: 0.299,
    corporateOrSelf: 0.271,
    government: 0.332,
    municipalities: 0.098,
    corporateOrSelfCorporate: 0.718,
    corporateOrSelfSelf: 0.282,
    selfemployedNotes: 0.153,
    selfemployedMortgages: 0.717,
    selfemployedOther: 0.13,
    corporateCorporate: 0.53,
    corporateDepositaryLoansExMortgage: 0.257,
    corporateMortgages: 0.149,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.059
  },
  "1961-09-01": {
    households: 0.299,
    corporateOrSelf: 0.271,
    government: 0.331,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.713,
    corporateOrSelfSelf: 0.287,
    selfemployedNotes: 0.152,
    selfemployedMortgages: 0.72,
    selfemployedOther: 0.128,
    corporateCorporate: 0.529,
    corporateDepositaryLoansExMortgage: 0.256,
    corporateMortgages: 0.149,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.06
  },
  "1961-12-01": {
    households: 0.302,
    corporateOrSelf: 0.272,
    government: 0.327,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.713,
    corporateOrSelfSelf: 0.287,
    selfemployedNotes: 0.153,
    selfemployedMortgages: 0.721,
    selfemployedOther: 0.126,
    corporateCorporate: 0.527,
    corporateDepositaryLoansExMortgage: 0.252,
    corporateMortgages: 0.152,
    corporateCommercialPaper: 0.007,
    CorporateOther: 0.062
  },
  "1962-03-01": {
    households: 0.302,
    corporateOrSelf: 0.272,
    government: 0.326,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.709,
    corporateOrSelfSelf: 0.291,
    selfemployedNotes: 0.151,
    selfemployedMortgages: 0.722,
    selfemployedOther: 0.126,
    corporateCorporate: 0.527,
    corporateDepositaryLoansExMortgage: 0.253,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.007,
    CorporateOther: 0.06
  },
  "1962-06-01": {
    households: 0.304,
    corporateOrSelf: 0.274,
    government: 0.323,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.707,
    corporateOrSelfSelf: 0.293,
    selfemployedNotes: 0.151,
    selfemployedMortgages: 0.727,
    selfemployedOther: 0.123,
    corporateCorporate: 0.526,
    corporateDepositaryLoansExMortgage: 0.255,
    corporateMortgages: 0.152,
    corporateCommercialPaper: 0.007,
    CorporateOther: 0.06
  },
  "1962-09-01": {
    households: 0.307,
    corporateOrSelf: 0.276,
    government: 0.318,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.704,
    corporateOrSelfSelf: 0.296,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.729,
    selfemployedOther: 0.121,
    corporateCorporate: 0.522,
    corporateDepositaryLoansExMortgage: 0.258,
    corporateMortgages: 0.153,
    corporateCommercialPaper: 0.008,
    CorporateOther: 0.06
  },
  "1962-12-01": {
    households: 0.308,
    corporateOrSelf: 0.276,
    government: 0.316,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.702,
    corporateOrSelfSelf: 0.298,
    selfemployedNotes: 0.153,
    selfemployedMortgages: 0.729,
    selfemployedOther: 0.119,
    corporateCorporate: 0.518,
    corporateDepositaryLoansExMortgage: 0.258,
    corporateMortgages: 0.156,
    corporateCommercialPaper: 0.007,
    CorporateOther: 0.06
  },
  "1963-03-01": {
    households: 0.31,
    corporateOrSelf: 0.277,
    government: 0.313,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.7,
    corporateOrSelfSelf: 0.3,
    selfemployedNotes: 0.149,
    selfemployedMortgages: 0.729,
    selfemployedOther: 0.121,
    corporateCorporate: 0.517,
    corporateDepositaryLoansExMortgage: 0.259,
    corporateMortgages: 0.157,
    corporateCommercialPaper: 0.007,
    CorporateOther: 0.061
  },
  "1963-06-01": {
    households: 0.313,
    corporateOrSelf: 0.277,
    government: 0.31,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.696,
    corporateOrSelfSelf: 0.304,
    selfemployedNotes: 0.149,
    selfemployedMortgages: 0.734,
    selfemployedOther: 0.118,
    corporateCorporate: 0.516,
    corporateDepositaryLoansExMortgage: 0.258,
    corporateMortgages: 0.158,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.061
  },
  "1963-09-01": {
    households: 0.317,
    corporateOrSelf: 0.278,
    government: 0.305,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.692,
    corporateOrSelfSelf: 0.308,
    selfemployedNotes: 0.147,
    selfemployedMortgages: 0.737,
    selfemployedOther: 0.116,
    corporateCorporate: 0.513,
    corporateDepositaryLoansExMortgage: 0.26,
    corporateMortgages: 0.16,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.062
  },
  "1963-12-01": {
    households: 0.32,
    corporateOrSelf: 0.28,
    government: 0.301,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.691,
    corporateOrSelfSelf: 0.309,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.733,
    selfemployedOther: 0.116,
    corporateCorporate: 0.508,
    corporateDepositaryLoansExMortgage: 0.262,
    corporateMortgages: 0.162,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.063
  },
  "1964-03-01": {
    households: 0.323,
    corporateOrSelf: 0.28,
    government: 0.298,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.689,
    corporateOrSelfSelf: 0.311,
    selfemployedNotes: 0.149,
    selfemployedMortgages: 0.733,
    selfemployedOther: 0.118,
    corporateCorporate: 0.505,
    corporateDepositaryLoansExMortgage: 0.262,
    corporateMortgages: 0.163,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.066
  },
  "1964-06-01": {
    households: 0.325,
    corporateOrSelf: 0.282,
    government: 0.294,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.687,
    corporateOrSelfSelf: 0.313,
    selfemployedNotes: 0.147,
    selfemployedMortgages: 0.737,
    selfemployedOther: 0.116,
    corporateCorporate: 0.502,
    corporateDepositaryLoansExMortgage: 0.262,
    corporateMortgages: 0.163,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.067
  },
  "1964-09-01": {
    households: 0.327,
    corporateOrSelf: 0.283,
    government: 0.291,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.685,
    corporateOrSelfSelf: 0.315,
    selfemployedNotes: 0.146,
    selfemployedMortgages: 0.739,
    selfemployedOther: 0.115,
    corporateCorporate: 0.496,
    corporateDepositaryLoansExMortgage: 0.265,
    corporateMortgages: 0.164,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.069
  },
  "1964-12-01": {
    households: 0.329,
    corporateOrSelf: 0.284,
    government: 0.288,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.685,
    corporateOrSelfSelf: 0.315,
    selfemployedNotes: 0.145,
    selfemployedMortgages: 0.741,
    selfemployedOther: 0.115,
    corporateCorporate: 0.492,
    corporateDepositaryLoansExMortgage: 0.267,
    corporateMortgages: 0.167,
    corporateCommercialPaper: 0.006,
    CorporateOther: 0.068
  },
  "1965-03-01": {
    households: 0.33,
    corporateOrSelf: 0.287,
    government: 0.284,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.687,
    corporateOrSelfSelf: 0.313,
    selfemployedNotes: 0.146,
    selfemployedMortgages: 0.738,
    selfemployedOther: 0.116,
    corporateCorporate: 0.483,
    corporateDepositaryLoansExMortgage: 0.278,
    corporateMortgages: 0.167,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.068
  },
  "1965-06-01": {
    households: 0.332,
    corporateOrSelf: 0.289,
    government: 0.28,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.685,
    corporateOrSelfSelf: 0.315,
    selfemployedNotes: 0.146,
    selfemployedMortgages: 0.74,
    selfemployedOther: 0.114,
    corporateCorporate: 0.48,
    corporateDepositaryLoansExMortgage: 0.282,
    corporateMortgages: 0.165,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.07
  },
  "1965-09-01": {
    households: 0.334,
    corporateOrSelf: 0.292,
    government: 0.274,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.685,
    corporateOrSelfSelf: 0.315,
    selfemployedNotes: 0.146,
    selfemployedMortgages: 0.742,
    selfemployedOther: 0.112,
    corporateCorporate: 0.476,
    corporateDepositaryLoansExMortgage: 0.287,
    corporateMortgages: 0.163,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.07
  },
  "1965-12-01": {
    households: 0.334,
    corporateOrSelf: 0.294,
    government: 0.272,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.685,
    corporateOrSelfSelf: 0.315,
    selfemployedNotes: 0.147,
    selfemployedMortgages: 0.741,
    selfemployedOther: 0.112,
    corporateCorporate: 0.467,
    corporateDepositaryLoansExMortgage: 0.294,
    corporateMortgages: 0.164,
    corporateCommercialPaper: 0.004,
    CorporateOther: 0.072
  },
  "1966-03-01": {
    households: 0.335,
    corporateOrSelf: 0.297,
    government: 0.268,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.685,
    corporateOrSelfSelf: 0.315,
    selfemployedNotes: 0.148,
    selfemployedMortgages: 0.738,
    selfemployedOther: 0.114,
    corporateCorporate: 0.467,
    corporateDepositaryLoansExMortgage: 0.296,
    corporateMortgages: 0.162,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.072
  },
  "1966-06-01": {
    households: 0.335,
    corporateOrSelf: 0.302,
    government: 0.263,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.687,
    corporateOrSelfSelf: 0.313,
    selfemployedNotes: 0.151,
    selfemployedMortgages: 0.738,
    selfemployedOther: 0.111,
    corporateCorporate: 0.462,
    corporateDepositaryLoansExMortgage: 0.301,
    corporateMortgages: 0.161,
    corporateCommercialPaper: 0.003,
    CorporateOther: 0.072
  },
  "1966-09-01": {
    households: 0.335,
    corporateOrSelf: 0.306,
    government: 0.26,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.689,
    corporateOrSelfSelf: 0.311,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.74,
    selfemployedOther: 0.111,
    corporateCorporate: 0.46,
    corporateDepositaryLoansExMortgage: 0.305,
    corporateMortgages: 0.161,
    corporateCommercialPaper: 0.005,
    CorporateOther: 0.069
  },
  "1966-12-01": {
    households: 0.334,
    corporateOrSelf: 0.307,
    government: 0.259,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.689,
    corporateOrSelfSelf: 0.311,
    selfemployedNotes: 0.155,
    selfemployedMortgages: 0.735,
    selfemployedOther: 0.11,
    corporateCorporate: 0.461,
    corporateDepositaryLoansExMortgage: 0.299,
    corporateMortgages: 0.162,
    corporateCommercialPaper: 0.007,
    CorporateOther: 0.071
  },
  "1967-03-01": {
    households: 0.332,
    corporateOrSelf: 0.31,
    government: 0.259,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.692,
    corporateOrSelfSelf: 0.308,
    selfemployedNotes: 0.153,
    selfemployedMortgages: 0.734,
    selfemployedOther: 0.113,
    corporateCorporate: 0.462,
    corporateDepositaryLoansExMortgage: 0.299,
    corporateMortgages: 0.16,
    corporateCommercialPaper: 0.01,
    CorporateOther: 0.069
  },
  "1967-06-01": {
    households: 0.333,
    corporateOrSelf: 0.314,
    government: 0.253,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.691,
    corporateOrSelfSelf: 0.309,
    selfemployedNotes: 0.154,
    selfemployedMortgages: 0.735,
    selfemployedOther: 0.111,
    corporateCorporate: 0.464,
    corporateDepositaryLoansExMortgage: 0.3,
    corporateMortgages: 0.157,
    corporateCommercialPaper: 0.013,
    CorporateOther: 0.066
  },
  "1967-09-01": {
    households: 0.331,
    corporateOrSelf: 0.315,
    government: 0.253,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.691,
    corporateOrSelfSelf: 0.309,
    selfemployedNotes: 0.151,
    selfemployedMortgages: 0.739,
    selfemployedOther: 0.109,
    corporateCorporate: 0.472,
    corporateDepositaryLoansExMortgage: 0.296,
    corporateMortgages: 0.155,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.065
  },
  "1967-12-01": {
    households: 0.338,
    corporateOrSelf: 0.311,
    government: 0.252,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.688,
    corporateOrSelfSelf: 0.312,
    selfemployedNotes: 0.153,
    selfemployedMortgages: 0.737,
    selfemployedOther: 0.111,
    corporateCorporate: 0.478,
    corporateDepositaryLoansExMortgage: 0.299,
    corporateMortgages: 0.146,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.065
  },
  "1968-03-01": {
    households: 0.33,
    corporateOrSelf: 0.316,
    government: 0.255,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.692,
    corporateOrSelfSelf: 0.308,
    selfemployedNotes: 0.152,
    selfemployedMortgages: 0.737,
    selfemployedOther: 0.111,
    corporateCorporate: 0.473,
    corporateDepositaryLoansExMortgage: 0.295,
    corporateMortgages: 0.154,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.066
  },
  "1968-06-01": {
    households: 0.332,
    corporateOrSelf: 0.318,
    government: 0.251,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.691,
    corporateOrSelfSelf: 0.309,
    selfemployedNotes: 0.152,
    selfemployedMortgages: 0.739,
    selfemployedOther: 0.109,
    corporateCorporate: 0.475,
    corporateDepositaryLoansExMortgage: 0.297,
    corporateMortgages: 0.151,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.066
  },
  "1968-09-01": {
    households: 0.331,
    corporateOrSelf: 0.32,
    government: 0.25,
    municipalities: 0.098,
    corporateOrSelfCorporate: 0.693,
    corporateOrSelfSelf: 0.307,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.742,
    selfemployedOther: 0.108,
    corporateCorporate: 0.474,
    corporateDepositaryLoansExMortgage: 0.297,
    corporateMortgages: 0.149,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.064
  },
  "1968-12-01": {
    households: 0.333,
    corporateOrSelf: 0.322,
    government: 0.246,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.693,
    corporateOrSelfSelf: 0.307,
    selfemployedNotes: 0.151,
    selfemployedMortgages: 0.742,
    selfemployedOther: 0.107,
    corporateCorporate: 0.472,
    corporateDepositaryLoansExMortgage: 0.301,
    corporateMortgages: 0.148,
    corporateCommercialPaper: 0.015,
    CorporateOther: 0.064
  },
  "1969-03-01": {
    households: 0.331,
    corporateOrSelf: 0.326,
    government: 0.243,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.695,
    corporateOrSelfSelf: 0.305,
    selfemployedNotes: 0.153,
    selfemployedMortgages: 0.738,
    selfemployedOther: 0.108,
    corporateCorporate: 0.471,
    corporateDepositaryLoansExMortgage: 0.303,
    corporateMortgages: 0.147,
    corporateCommercialPaper: 0.015,
    CorporateOther: 0.065
  },
  "1969-06-01": {
    households: 0.333,
    corporateOrSelf: 0.332,
    government: 0.233,
    municipalities: 0.101,
    corporateOrSelfCorporate: 0.694,
    corporateOrSelfSelf: 0.306,
    selfemployedNotes: 0.154,
    selfemployedMortgages: 0.739,
    selfemployedOther: 0.107,
    corporateCorporate: 0.467,
    corporateDepositaryLoansExMortgage: 0.304,
    corporateMortgages: 0.141,
    corporateCommercialPaper: 0.015,
    CorporateOther: 0.074
  },
  "1969-09-01": {
    households: 0.331,
    corporateOrSelf: 0.336,
    government: 0.231,
    municipalities: 0.102,
    corporateOrSelfCorporate: 0.693,
    corporateOrSelfSelf: 0.307,
    selfemployedNotes: 0.155,
    selfemployedMortgages: 0.739,
    selfemployedOther: 0.106,
    corporateCorporate: 0.463,
    corporateDepositaryLoansExMortgage: 0.3,
    corporateMortgages: 0.136,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.084
  },
  "1969-12-01": {
    households: 0.332,
    corporateOrSelf: 0.338,
    government: 0.229,
    municipalities: 0.101,
    corporateOrSelfCorporate: 0.692,
    corporateOrSelfSelf: 0.308,
    selfemployedNotes: 0.154,
    selfemployedMortgages: 0.739,
    selfemployedOther: 0.107,
    corporateCorporate: 0.461,
    corporateDepositaryLoansExMortgage: 0.3,
    corporateMortgages: 0.136,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.086
  },
  "1970-03-01": {
    households: 0.327,
    corporateOrSelf: 0.343,
    government: 0.228,
    municipalities: 0.102,
    corporateOrSelfCorporate: 0.715,
    corporateOrSelfSelf: 0.285,
    selfemployedNotes: 0.162,
    selfemployedMortgages: 0.722,
    selfemployedOther: 0.116,
    corporateCorporate: 0.443,
    corporateDepositaryLoansExMortgage: 0.282,
    corporateMortgages: 0.167,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.09
  },
  "1970-06-01": {
    households: 0.323,
    corporateOrSelf: 0.348,
    government: 0.226,
    municipalities: 0.102,
    corporateOrSelfCorporate: 0.715,
    corporateOrSelfSelf: 0.285,
    selfemployedNotes: 0.159,
    selfemployedMortgages: 0.726,
    selfemployedOther: 0.115,
    corporateCorporate: 0.446,
    corporateDepositaryLoansExMortgage: 0.279,
    corporateMortgages: 0.164,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.094
  },
  "1970-09-01": {
    households: 0.321,
    corporateOrSelf: 0.35,
    government: 0.226,
    municipalities: 0.102,
    corporateOrSelfCorporate: 0.713,
    corporateOrSelfSelf: 0.287,
    selfemployedNotes: 0.154,
    selfemployedMortgages: 0.734,
    selfemployedOther: 0.113,
    corporateCorporate: 0.451,
    corporateDepositaryLoansExMortgage: 0.282,
    corporateMortgages: 0.164,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.085
  },
  "1970-12-01": {
    households: 0.321,
    corporateOrSelf: 0.351,
    government: 0.225,
    municipalities: 0.103,
    corporateOrSelfCorporate: 0.71,
    corporateOrSelfSelf: 0.29,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.738,
    selfemployedOther: 0.112,
    corporateCorporate: 0.459,
    corporateDepositaryLoansExMortgage: 0.28,
    corporateMortgages: 0.164,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.077
  },
  "1971-03-01": {
    households: 0.321,
    corporateOrSelf: 0.353,
    government: 0.222,
    municipalities: 0.104,
    corporateOrSelfCorporate: 0.708,
    corporateOrSelfSelf: 0.292,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.737,
    selfemployedOther: 0.113,
    corporateCorporate: 0.465,
    corporateDepositaryLoansExMortgage: 0.274,
    corporateMortgages: 0.164,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.076
  },
  "1971-06-01": {
    households: 0.319,
    corporateOrSelf: 0.354,
    government: 0.223,
    municipalities: 0.104,
    corporateOrSelfCorporate: 0.703,
    corporateOrSelfSelf: 0.297,
    selfemployedNotes: 0.147,
    selfemployedMortgages: 0.743,
    selfemployedOther: 0.11,
    corporateCorporate: 0.47,
    corporateDepositaryLoansExMortgage: 0.273,
    corporateMortgages: 0.164,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.077
  },
  "1971-09-01": {
    households: 0.318,
    corporateOrSelf: 0.354,
    government: 0.223,
    municipalities: 0.104,
    corporateOrSelfCorporate: 0.701,
    corporateOrSelfSelf: 0.299,
    selfemployedNotes: 0.142,
    selfemployedMortgages: 0.751,
    selfemployedOther: 0.106,
    corporateCorporate: 0.468,
    corporateDepositaryLoansExMortgage: 0.275,
    corporateMortgages: 0.163,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.077
  },
  "1971-12-01": {
    households: 0.32,
    corporateOrSelf: 0.353,
    government: 0.222,
    municipalities: 0.104,
    corporateOrSelfCorporate: 0.696,
    corporateOrSelfSelf: 0.304,
    selfemployedNotes: 0.143,
    selfemployedMortgages: 0.753,
    selfemployedOther: 0.104,
    corporateCorporate: 0.473,
    corporateDepositaryLoansExMortgage: 0.271,
    corporateMortgages: 0.166,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.075
  },
  "1972-03-01": {
    households: 0.322,
    corporateOrSelf: 0.355,
    government: 0.218,
    municipalities: 0.105,
    corporateOrSelfCorporate: 0.696,
    corporateOrSelfSelf: 0.304,
    selfemployedNotes: 0.142,
    selfemployedMortgages: 0.754,
    selfemployedOther: 0.104,
    corporateCorporate: 0.467,
    corporateDepositaryLoansExMortgage: 0.27,
    corporateMortgages: 0.169,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.075
  },
  "1972-06-01": {
    households: 0.324,
    corporateOrSelf: 0.357,
    government: 0.215,
    municipalities: 0.104,
    corporateOrSelfCorporate: 0.689,
    corporateOrSelfSelf: 0.311,
    selfemployedNotes: 0.14,
    selfemployedMortgages: 0.761,
    selfemployedOther: 0.099,
    corporateCorporate: 0.467,
    corporateDepositaryLoansExMortgage: 0.269,
    corporateMortgages: 0.169,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.075
  },
  "1972-09-01": {
    households: 0.324,
    corporateOrSelf: 0.359,
    government: 0.213,
    municipalities: 0.104,
    corporateOrSelfCorporate: 0.686,
    corporateOrSelfSelf: 0.314,
    selfemployedNotes: 0.134,
    selfemployedMortgages: 0.769,
    selfemployedOther: 0.097,
    corporateCorporate: 0.464,
    corporateDepositaryLoansExMortgage: 0.273,
    corporateMortgages: 0.17,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.075
  },
  "1972-12-01": {
    households: 0.325,
    corporateOrSelf: 0.361,
    government: 0.212,
    municipalities: 0.103,
    corporateOrSelfCorporate: 0.681,
    corporateOrSelfSelf: 0.319,
    selfemployedNotes: 0.134,
    selfemployedMortgages: 0.772,
    selfemployedOther: 0.094,
    corporateCorporate: 0.458,
    corporateDepositaryLoansExMortgage: 0.276,
    corporateMortgages: 0.172,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.078
  },
  "1973-03-01": {
    households: 0.326,
    corporateOrSelf: 0.364,
    government: 0.209,
    municipalities: 0.102,
    corporateOrSelfCorporate: 0.677,
    corporateOrSelfSelf: 0.323,
    selfemployedNotes: 0.144,
    selfemployedMortgages: 0.757,
    selfemployedOther: 0.099,
    corporateCorporate: 0.446,
    corporateDepositaryLoansExMortgage: 0.289,
    corporateMortgages: 0.171,
    corporateCommercialPaper: 0.013,
    CorporateOther: 0.081
  },
  "1973-06-01": {
    households: 0.326,
    corporateOrSelf: 0.369,
    government: 0.204,
    municipalities: 0.101,
    corporateOrSelfCorporate: 0.673,
    corporateOrSelfSelf: 0.327,
    selfemployedNotes: 0.159,
    selfemployedMortgages: 0.745,
    selfemployedOther: 0.096,
    corporateCorporate: 0.438,
    corporateDepositaryLoansExMortgage: 0.286,
    corporateMortgages: 0.18,
    corporateCommercialPaper: 0.013,
    CorporateOther: 0.083
  },
  "1973-09-01": {
    households: 0.327,
    corporateOrSelf: 0.374,
    government: 0.199,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.677,
    corporateOrSelfSelf: 0.323,
    selfemployedNotes: 0.178,
    selfemployedMortgages: 0.727,
    selfemployedOther: 0.095,
    corporateCorporate: 0.426,
    corporateDepositaryLoansExMortgage: 0.28,
    corporateMortgages: 0.195,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.087
  },
  "1973-12-01": {
    households: 0.328,
    corporateOrSelf: 0.375,
    government: 0.197,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.684,
    corporateOrSelfSelf: 0.316,
    selfemployedNotes: 0.195,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.097,
    corporateCorporate: 0.416,
    corporateDepositaryLoansExMortgage: 0.27,
    corporateMortgages: 0.211,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.087
  },
  "1974-03-01": {
    households: 0.328,
    corporateOrSelf: 0.379,
    government: 0.193,
    municipalities: 0.101,
    corporateOrSelfCorporate: 0.682,
    corporateOrSelfSelf: 0.318,
    selfemployedNotes: 0.201,
    selfemployedMortgages: 0.702,
    selfemployedOther: 0.097,
    corporateCorporate: 0.411,
    corporateDepositaryLoansExMortgage: 0.274,
    corporateMortgages: 0.209,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.089
  },
  "1974-06-01": {
    households: 0.327,
    corporateOrSelf: 0.384,
    government: 0.19,
    municipalities: 0.1,
    corporateOrSelfCorporate: 0.679,
    corporateOrSelfSelf: 0.321,
    selfemployedNotes: 0.201,
    selfemployedMortgages: 0.703,
    selfemployedOther: 0.095,
    corporateCorporate: 0.408,
    corporateDepositaryLoansExMortgage: 0.28,
    corporateMortgages: 0.203,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.09
  },
  "1974-09-01": {
    households: 0.327,
    corporateOrSelf: 0.386,
    government: 0.188,
    municipalities: 0.099,
    corporateOrSelfCorporate: 0.676,
    corporateOrSelfSelf: 0.324,
    selfemployedNotes: 0.199,
    selfemployedMortgages: 0.705,
    selfemployedOther: 0.096,
    corporateCorporate: 0.406,
    corporateDepositaryLoansExMortgage: 0.284,
    corporateMortgages: 0.193,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.094
  },
  "1974-12-01": {
    households: 0.327,
    corporateOrSelf: 0.388,
    government: 0.187,
    municipalities: 0.098,
    corporateOrSelfCorporate: 0.674,
    corporateOrSelfSelf: 0.326,
    selfemployedNotes: 0.199,
    selfemployedMortgages: 0.704,
    selfemployedOther: 0.097,
    corporateCorporate: 0.41,
    corporateDepositaryLoansExMortgage: 0.282,
    corporateMortgages: 0.188,
    corporateCommercialPaper: 0.023,
    CorporateOther: 0.098
  },
  "1975-03-01": {
    households: 0.325,
    corporateOrSelf: 0.386,
    government: 0.192,
    municipalities: 0.098,
    corporateOrSelfCorporate: 0.672,
    corporateOrSelfSelf: 0.328,
    selfemployedNotes: 0.199,
    selfemployedMortgages: 0.704,
    selfemployedOther: 0.097,
    corporateCorporate: 0.422,
    corporateDepositaryLoansExMortgage: 0.269,
    corporateMortgages: 0.186,
    corporateCommercialPaper: 0.024,
    CorporateOther: 0.098
  },
  "1975-06-01": {
    households: 0.322,
    corporateOrSelf: 0.382,
    government: 0.2,
    municipalities: 0.097,
    corporateOrSelfCorporate: 0.668,
    corporateOrSelfSelf: 0.332,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.704,
    selfemployedOther: 0.099,
    corporateCorporate: 0.434,
    corporateDepositaryLoansExMortgage: 0.259,
    corporateMortgages: 0.187,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.1
  },
  "1975-09-01": {
    households: 0.321,
    corporateOrSelf: 0.378,
    government: 0.206,
    municipalities: 0.095,
    corporateOrSelfCorporate: 0.667,
    corporateOrSelfSelf: 0.333,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.702,
    selfemployedOther: 0.101,
    corporateCorporate: 0.437,
    corporateDepositaryLoansExMortgage: 0.254,
    corporateMortgages: 0.188,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.103
  },
  "1975-12-01": {
    households: 0.323,
    corporateOrSelf: 0.372,
    government: 0.21,
    municipalities: 0.095,
    corporateOrSelfCorporate: 0.666,
    corporateOrSelfSelf: 0.334,
    selfemployedNotes: 0.197,
    selfemployedMortgages: 0.699,
    selfemployedOther: 0.104,
    corporateCorporate: 0.443,
    corporateDepositaryLoansExMortgage: 0.248,
    corporateMortgages: 0.188,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.104
  },
  "1976-03-01": {
    households: 0.324,
    corporateOrSelf: 0.368,
    government: 0.213,
    municipalities: 0.095,
    corporateOrSelfCorporate: 0.661,
    corporateOrSelfSelf: 0.339,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.699,
    selfemployedOther: 0.105,
    corporateCorporate: 0.45,
    corporateDepositaryLoansExMortgage: 0.238,
    corporateMortgages: 0.186,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.108
  },
  "1976-06-01": {
    households: 0.323,
    corporateOrSelf: 0.365,
    government: 0.217,
    municipalities: 0.095,
    corporateOrSelfCorporate: 0.661,
    corporateOrSelfSelf: 0.339,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.698,
    selfemployedOther: 0.106,
    corporateCorporate: 0.45,
    corporateDepositaryLoansExMortgage: 0.234,
    corporateMortgages: 0.187,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.11
  },
  "1976-09-01": {
    households: 0.323,
    corporateOrSelf: 0.364,
    government: 0.219,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.66,
    corporateOrSelfSelf: 0.34,
    selfemployedNotes: 0.195,
    selfemployedMortgages: 0.696,
    selfemployedOther: 0.109,
    corporateCorporate: 0.451,
    corporateDepositaryLoansExMortgage: 0.231,
    corporateMortgages: 0.188,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.114
  },
  "1976-12-01": {
    households: 0.326,
    corporateOrSelf: 0.363,
    government: 0.218,
    municipalities: 0.093,
    corporateOrSelfCorporate: 0.66,
    corporateOrSelfSelf: 0.34,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.694,
    selfemployedOther: 0.11,
    corporateCorporate: 0.45,
    corporateDepositaryLoansExMortgage: 0.226,
    corporateMortgages: 0.187,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.119
  },
  "1977-03-01": {
    households: 0.328,
    corporateOrSelf: 0.364,
    government: 0.217,
    municipalities: 0.091,
    corporateOrSelfCorporate: 0.658,
    corporateOrSelfSelf: 0.342,
    selfemployedNotes: 0.195,
    selfemployedMortgages: 0.689,
    selfemployedOther: 0.115,
    corporateCorporate: 0.446,
    corporateDepositaryLoansExMortgage: 0.228,
    corporateMortgages: 0.184,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.125
  },
  "1977-06-01": {
    households: 0.331,
    corporateOrSelf: 0.364,
    government: 0.214,
    municipalities: 0.091,
    corporateOrSelfCorporate: 0.656,
    corporateOrSelfSelf: 0.344,
    selfemployedNotes: 0.195,
    selfemployedMortgages: 0.69,
    selfemployedOther: 0.114,
    corporateCorporate: 0.441,
    corporateDepositaryLoansExMortgage: 0.228,
    corporateMortgages: 0.184,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.13
  },
  "1977-09-01": {
    households: 0.332,
    corporateOrSelf: 0.362,
    government: 0.217,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.656,
    corporateOrSelfSelf: 0.344,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.69,
    selfemployedOther: 0.114,
    corporateCorporate: 0.438,
    corporateDepositaryLoansExMortgage: 0.226,
    corporateMortgages: 0.185,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.133
  },
  "1977-12-01": {
    households: 0.334,
    corporateOrSelf: 0.363,
    government: 0.215,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.657,
    corporateOrSelfSelf: 0.343,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.689,
    selfemployedOther: 0.115,
    corporateCorporate: 0.434,
    corporateDepositaryLoansExMortgage: 0.223,
    corporateMortgages: 0.187,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.138
  },
  "1978-03-01": {
    households: 0.336,
    corporateOrSelf: 0.362,
    government: 0.213,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.657,
    corporateOrSelfSelf: 0.343,
    selfemployedNotes: 0.198,
    selfemployedMortgages: 0.685,
    selfemployedOther: 0.117,
    corporateCorporate: 0.428,
    corporateDepositaryLoansExMortgage: 0.232,
    corporateMortgages: 0.187,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.136
  },
  "1978-06-01": {
    households: 0.339,
    corporateOrSelf: 0.362,
    government: 0.211,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.654,
    corporateOrSelfSelf: 0.346,
    selfemployedNotes: 0.201,
    selfemployedMortgages: 0.68,
    selfemployedOther: 0.12,
    corporateCorporate: 0.425,
    corporateDepositaryLoansExMortgage: 0.231,
    corporateMortgages: 0.187,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.14
  },
  "1978-09-01": {
    households: 0.341,
    corporateOrSelf: 0.359,
    government: 0.211,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.649,
    corporateOrSelfSelf: 0.351,
    selfemployedNotes: 0.203,
    selfemployedMortgages: 0.674,
    selfemployedOther: 0.122,
    corporateCorporate: 0.423,
    corporateDepositaryLoansExMortgage: 0.227,
    corporateMortgages: 0.188,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.145
  },
  "1978-12-01": {
    households: 0.343,
    corporateOrSelf: 0.36,
    government: 0.207,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.647,
    corporateOrSelfSelf: 0.353,
    selfemployedNotes: 0.206,
    selfemployedMortgages: 0.67,
    selfemployedOther: 0.124,
    corporateCorporate: 0.419,
    corporateDepositaryLoansExMortgage: 0.227,
    corporateMortgages: 0.185,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.15
  },
  "1979-03-01": {
    households: 0.346,
    corporateOrSelf: 0.361,
    government: 0.203,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.647,
    corporateOrSelfSelf: 0.353,
    selfemployedNotes: 0.208,
    selfemployedMortgages: 0.666,
    selfemployedOther: 0.126,
    corporateCorporate: 0.411,
    corporateDepositaryLoansExMortgage: 0.231,
    corporateMortgages: 0.184,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.153
  },
  "1979-06-01": {
    households: 0.348,
    corporateOrSelf: 0.364,
    government: 0.199,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.641,
    corporateOrSelfSelf: 0.359,
    selfemployedNotes: 0.207,
    selfemployedMortgages: 0.665,
    selfemployedOther: 0.129,
    corporateCorporate: 0.407,
    corporateDepositaryLoansExMortgage: 0.233,
    corporateMortgages: 0.179,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.158
  },
  "1979-09-01": {
    households: 0.349,
    corporateOrSelf: 0.364,
    government: 0.199,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.637,
    corporateOrSelfSelf: 0.363,
    selfemployedNotes: 0.206,
    selfemployedMortgages: 0.666,
    selfemployedOther: 0.128,
    corporateCorporate: 0.401,
    corporateDepositaryLoansExMortgage: 0.238,
    corporateMortgages: 0.176,
    corporateCommercialPaper: 0.025,
    CorporateOther: 0.161
  },
  "1979-12-01": {
    households: 0.352,
    corporateOrSelf: 0.364,
    government: 0.197,
    municipalities: 0.087,
    corporateOrSelfCorporate: 0.634,
    corporateOrSelfSelf: 0.366,
    selfemployedNotes: 0.207,
    selfemployedMortgages: 0.663,
    selfemployedOther: 0.131,
    corporateCorporate: 0.397,
    corporateDepositaryLoansExMortgage: 0.24,
    corporateMortgages: 0.169,
    corporateCommercialPaper: 0.029,
    CorporateOther: 0.165
  },
  "1980-03-01": {
    households: 0.353,
    corporateOrSelf: 0.365,
    government: 0.195,
    municipalities: 0.087,
    corporateOrSelfCorporate: 0.631,
    corporateOrSelfSelf: 0.369,
    selfemployedNotes: 0.201,
    selfemployedMortgages: 0.665,
    selfemployedOther: 0.133,
    corporateCorporate: 0.394,
    corporateDepositaryLoansExMortgage: 0.244,
    corporateMortgages: 0.162,
    corporateCommercialPaper: 0.031,
    CorporateOther: 0.169
  },
  "1980-06-01": {
    households: 0.351,
    corporateOrSelf: 0.364,
    government: 0.199,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.627,
    corporateOrSelfSelf: 0.373,
    selfemployedNotes: 0.194,
    selfemployedMortgages: 0.673,
    selfemployedOther: 0.132,
    corporateCorporate: 0.403,
    corporateDepositaryLoansExMortgage: 0.234,
    corporateMortgages: 0.155,
    corporateCommercialPaper: 0.035,
    CorporateOther: 0.173
  },
  "1980-09-01": {
    households: 0.35,
    corporateOrSelf: 0.363,
    government: 0.201,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.624,
    corporateOrSelfSelf: 0.376,
    selfemployedNotes: 0.19,
    selfemployedMortgages: 0.678,
    selfemployedOther: 0.132,
    corporateCorporate: 0.406,
    corporateDepositaryLoansExMortgage: 0.242,
    corporateMortgages: 0.15,
    corporateCommercialPaper: 0.029,
    CorporateOther: 0.174
  },
  "1980-12-01": {
    households: 0.351,
    corporateOrSelf: 0.364,
    government: 0.2,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.624,
    corporateOrSelfSelf: 0.376,
    selfemployedNotes: 0.188,
    selfemployedMortgages: 0.678,
    selfemployedOther: 0.134,
    corporateCorporate: 0.398,
    corporateDepositaryLoansExMortgage: 0.252,
    corporateMortgages: 0.146,
    corporateCommercialPaper: 0.03,
    CorporateOther: 0.174
  },
  "1981-03-01": {
    households: 0.348,
    corporateOrSelf: 0.364,
    government: 0.203,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.62,
    corporateOrSelfSelf: 0.38,
    selfemployedNotes: 0.186,
    selfemployedMortgages: 0.676,
    selfemployedOther: 0.137,
    corporateCorporate: 0.397,
    corporateDepositaryLoansExMortgage: 0.247,
    corporateMortgages: 0.144,
    corporateCommercialPaper: 0.03,
    CorporateOther: 0.181
  },
  "1981-06-01": {
    households: 0.346,
    corporateOrSelf: 0.368,
    government: 0.202,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.187,
    selfemployedMortgages: 0.674,
    selfemployedOther: 0.139,
    corporateCorporate: 0.388,
    corporateDepositaryLoansExMortgage: 0.251,
    corporateMortgages: 0.144,
    corporateCommercialPaper: 0.033,
    CorporateOther: 0.183
  },
  "1981-09-01": {
    households: 0.344,
    corporateOrSelf: 0.371,
    government: 0.201,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.627,
    corporateOrSelfSelf: 0.373,
    selfemployedNotes: 0.188,
    selfemployedMortgages: 0.672,
    selfemployedOther: 0.14,
    corporateCorporate: 0.378,
    corporateDepositaryLoansExMortgage: 0.254,
    corporateMortgages: 0.144,
    corporateCommercialPaper: 0.037,
    CorporateOther: 0.186
  },
  "1981-12-01": {
    households: 0.342,
    corporateOrSelf: 0.371,
    government: 0.203,
    municipalities: 0.083,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.187,
    selfemployedMortgages: 0.675,
    selfemployedOther: 0.138,
    corporateCorporate: 0.376,
    corporateDepositaryLoansExMortgage: 0.254,
    corporateMortgages: 0.136,
    corporateCommercialPaper: 0.041,
    CorporateOther: 0.193
  },
  "1982-03-01": {
    households: 0.338,
    corporateOrSelf: 0.372,
    government: 0.206,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.628,
    corporateOrSelfSelf: 0.372,
    selfemployedNotes: 0.182,
    selfemployedMortgages: 0.681,
    selfemployedOther: 0.137,
    corporateCorporate: 0.373,
    corporateDepositaryLoansExMortgage: 0.262,
    corporateMortgages: 0.131,
    corporateCommercialPaper: 0.043,
    CorporateOther: 0.191
  },
  "1982-06-01": {
    households: 0.335,
    corporateOrSelf: 0.374,
    government: 0.207,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.629,
    corporateOrSelfSelf: 0.371,
    selfemployedNotes: 0.174,
    selfemployedMortgages: 0.69,
    selfemployedOther: 0.135,
    corporateCorporate: 0.367,
    corporateDepositaryLoansExMortgage: 0.275,
    corporateMortgages: 0.124,
    corporateCommercialPaper: 0.041,
    CorporateOther: 0.192
  },
  "1982-09-01": {
    households: 0.328,
    corporateOrSelf: 0.374,
    government: 0.214,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.628,
    corporateOrSelfSelf: 0.372,
    selfemployedNotes: 0.167,
    selfemployedMortgages: 0.7,
    selfemployedOther: 0.134,
    corporateCorporate: 0.366,
    corporateDepositaryLoansExMortgage: 0.285,
    corporateMortgages: 0.115,
    corporateCommercialPaper: 0.038,
    CorporateOther: 0.196
  },
  "1982-12-01": {
    households: 0.326,
    corporateOrSelf: 0.369,
    government: 0.221,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.161,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.131,
    corporateCorporate: 0.373,
    corporateDepositaryLoansExMortgage: 0.287,
    corporateMortgages: 0.113,
    corporateCommercialPaper: 0.033,
    CorporateOther: 0.195
  },
  "1983-03-01": {
    households: 0.319,
    corporateOrSelf: 0.369,
    government: 0.228,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.157,
    selfemployedMortgages: 0.714,
    selfemployedOther: 0.129,
    corporateCorporate: 0.368,
    corporateDepositaryLoansExMortgage: 0.283,
    corporateMortgages: 0.123,
    corporateCommercialPaper: 0.033,
    CorporateOther: 0.193
  },
  "1983-06-01": {
    households: 0.318,
    corporateOrSelf: 0.365,
    government: 0.232,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.153,
    selfemployedMortgages: 0.719,
    selfemployedOther: 0.127,
    corporateCorporate: 0.37,
    corporateDepositaryLoansExMortgage: 0.279,
    corporateMortgages: 0.125,
    corporateCommercialPaper: 0.028,
    CorporateOther: 0.198
  },
  "1983-09-01": {
    households: 0.318,
    corporateOrSelf: 0.363,
    government: 0.235,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.724,
    selfemployedOther: 0.125,
    corporateCorporate: 0.364,
    corporateDepositaryLoansExMortgage: 0.28,
    corporateMortgages: 0.126,
    corporateCommercialPaper: 0.028,
    CorporateOther: 0.202
  },
  "1983-12-01": {
    households: 0.32,
    corporateOrSelf: 0.363,
    government: 0.233,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.149,
    selfemployedMortgages: 0.728,
    selfemployedOther: 0.123,
    corporateCorporate: 0.358,
    corporateDepositaryLoansExMortgage: 0.283,
    corporateMortgages: 0.127,
    corporateCommercialPaper: 0.029,
    CorporateOther: 0.202
  },
  "1984-03-01": {
    households: 0.318,
    corporateOrSelf: 0.363,
    government: 0.236,
    municipalities: 0.083,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.729,
    selfemployedOther: 0.121,
    corporateCorporate: 0.351,
    corporateDepositaryLoansExMortgage: 0.287,
    corporateMortgages: 0.124,
    corporateCommercialPaper: 0.031,
    CorporateOther: 0.206
  },
  "1984-06-01": {
    households: 0.317,
    corporateOrSelf: 0.366,
    government: 0.235,
    municipalities: 0.082,
    corporateOrSelfCorporate: 0.627,
    corporateOrSelfSelf: 0.373,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.733,
    selfemployedOther: 0.117,
    corporateCorporate: 0.343,
    corporateDepositaryLoansExMortgage: 0.292,
    corporateMortgages: 0.124,
    corporateCommercialPaper: 0.036,
    CorporateOther: 0.206
  },
  "1984-09-01": {
    households: 0.314,
    corporateOrSelf: 0.366,
    government: 0.238,
    municipalities: 0.083,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.735,
    selfemployedOther: 0.115,
    corporateCorporate: 0.341,
    corporateDepositaryLoansExMortgage: 0.287,
    corporateMortgages: 0.123,
    corporateCommercialPaper: 0.04,
    CorporateOther: 0.21
  },
  "1984-12-01": {
    households: 0.313,
    corporateOrSelf: 0.368,
    government: 0.237,
    municipalities: 0.081,
    corporateOrSelfCorporate: 0.628,
    corporateOrSelfSelf: 0.372,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.739,
    selfemployedOther: 0.111,
    corporateCorporate: 0.34,
    corporateDepositaryLoansExMortgage: 0.283,
    corporateMortgages: 0.119,
    corporateCommercialPaper: 0.04,
    CorporateOther: 0.219
  },
  "1985-03-01": {
    households: 0.317,
    corporateOrSelf: 0.362,
    government: 0.239,
    municipalities: 0.082,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.147,
    selfemployedMortgages: 0.745,
    selfemployedOther: 0.108,
    corporateCorporate: 0.345,
    corporateDepositaryLoansExMortgage: 0.287,
    corporateMortgages: 0.11,
    corporateCommercialPaper: 0.042,
    CorporateOther: 0.217
  },
  "1985-06-01": {
    households: 0.316,
    corporateOrSelf: 0.36,
    government: 0.24,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.144,
    selfemployedMortgages: 0.751,
    selfemployedOther: 0.105,
    corporateCorporate: 0.35,
    corporateDepositaryLoansExMortgage: 0.282,
    corporateMortgages: 0.109,
    corporateCommercialPaper: 0.043,
    CorporateOther: 0.216
  },
  "1985-09-01": {
    households: 0.318,
    corporateOrSelf: 0.357,
    government: 0.241,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.629,
    corporateOrSelfSelf: 0.371,
    selfemployedNotes: 0.142,
    selfemployedMortgages: 0.754,
    selfemployedOther: 0.104,
    corporateCorporate: 0.353,
    corporateDepositaryLoansExMortgage: 0.282,
    corporateMortgages: 0.104,
    corporateCommercialPaper: 0.043,
    CorporateOther: 0.218
  },
  "1985-12-01": {
    households: 0.317,
    corporateOrSelf: 0.35,
    government: 0.24,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.637,
    corporateOrSelfSelf: 0.363,
    selfemployedNotes: 0.141,
    selfemployedMortgages: 0.759,
    selfemployedOther: 0.101,
    corporateCorporate: 0.353,
    corporateDepositaryLoansExMortgage: 0.277,
    corporateMortgages: 0.103,
    corporateCommercialPaper: 0.044,
    CorporateOther: 0.223
  },
  "1986-03-01": {
    households: 0.317,
    corporateOrSelf: 0.351,
    government: 0.241,
    municipalities: 0.091,
    corporateOrSelfCorporate: 0.639,
    corporateOrSelfSelf: 0.361,
    selfemployedNotes: 0.139,
    selfemployedMortgages: 0.762,
    selfemployedOther: 0.099,
    corporateCorporate: 0.365,
    corporateDepositaryLoansExMortgage: 0.276,
    corporateMortgages: 0.102,
    corporateCommercialPaper: 0.041,
    CorporateOther: 0.216
  },
  "1986-06-01": {
    households: 0.314,
    corporateOrSelf: 0.35,
    government: 0.243,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.641,
    corporateOrSelfSelf: 0.359,
    selfemployedNotes: 0.135,
    selfemployedMortgages: 0.767,
    selfemployedOther: 0.097,
    corporateCorporate: 0.376,
    corporateDepositaryLoansExMortgage: 0.272,
    corporateMortgages: 0.101,
    corporateCommercialPaper: 0.037,
    CorporateOther: 0.213
  },
  "1986-09-01": {
    households: 0.315,
    corporateOrSelf: 0.349,
    government: 0.244,
    municipalities: 0.091,
    corporateOrSelfCorporate: 0.645,
    corporateOrSelfSelf: 0.355,
    selfemployedNotes: 0.133,
    selfemployedMortgages: 0.772,
    selfemployedOther: 0.095,
    corporateCorporate: 0.379,
    corporateDepositaryLoansExMortgage: 0.267,
    corporateMortgages: 0.101,
    corporateCommercialPaper: 0.04,
    CorporateOther: 0.213
  },
  "1986-12-01": {
    households: 0.315,
    corporateOrSelf: 0.349,
    government: 0.244,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.651,
    corporateOrSelfSelf: 0.349,
    selfemployedNotes: 0.132,
    selfemployedMortgages: 0.775,
    selfemployedOther: 0.093,
    corporateCorporate: 0.378,
    corporateDepositaryLoansExMortgage: 0.279,
    corporateMortgages: 0.099,
    corporateCommercialPaper: 0.034,
    CorporateOther: 0.21
  },
  "1987-03-01": {
    households: 0.313,
    corporateOrSelf: 0.349,
    government: 0.244,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.652,
    corporateOrSelfSelf: 0.348,
    selfemployedNotes: 0.131,
    selfemployedMortgages: 0.777,
    selfemployedOther: 0.092,
    corporateCorporate: 0.383,
    corporateDepositaryLoansExMortgage: 0.267,
    corporateMortgages: 0.114,
    corporateCommercialPaper: 0.032,
    CorporateOther: 0.204
  },
  "1987-06-01": {
    households: 0.315,
    corporateOrSelf: 0.347,
    government: 0.245,
    municipalities: 0.093,
    corporateOrSelfCorporate: 0.654,
    corporateOrSelfSelf: 0.346,
    selfemployedNotes: 0.132,
    selfemployedMortgages: 0.777,
    selfemployedOther: 0.091,
    corporateCorporate: 0.384,
    corporateDepositaryLoansExMortgage: 0.262,
    corporateMortgages: 0.117,
    corporateCommercialPaper: 0.032,
    CorporateOther: 0.205
  },
  "1987-09-01": {
    households: 0.316,
    corporateOrSelf: 0.347,
    government: 0.243,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.657,
    corporateOrSelfSelf: 0.343,
    selfemployedNotes: 0.133,
    selfemployedMortgages: 0.777,
    selfemployedOther: 0.09,
    corporateCorporate: 0.384,
    corporateDepositaryLoansExMortgage: 0.26,
    corporateMortgages: 0.119,
    corporateCommercialPaper: 0.033,
    CorporateOther: 0.204
  },
  "1987-12-01": {
    households: 0.315,
    corporateOrSelf: 0.348,
    government: 0.244,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.658,
    corporateOrSelfSelf: 0.342,
    selfemployedNotes: 0.132,
    selfemployedMortgages: 0.773,
    selfemployedOther: 0.095,
    corporateCorporate: 0.382,
    corporateDepositaryLoansExMortgage: 0.255,
    corporateMortgages: 0.12,
    corporateCommercialPaper: 0.036,
    CorporateOther: 0.206
  },
  "1988-03-01": {
    households: 0.316,
    corporateOrSelf: 0.348,
    government: 0.244,
    municipalities: 0.093,
    corporateOrSelfCorporate: 0.659,
    corporateOrSelfSelf: 0.341,
    selfemployedNotes: 0.132,
    selfemployedMortgages: 0.774,
    selfemployedOther: 0.094,
    corporateCorporate: 0.385,
    corporateDepositaryLoansExMortgage: 0.253,
    corporateMortgages: 0.119,
    corporateCommercialPaper: 0.033,
    CorporateOther: 0.21
  },
  "1988-06-01": {
    households: 0.316,
    corporateOrSelf: 0.348,
    government: 0.243,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.66,
    corporateOrSelfSelf: 0.34,
    selfemployedNotes: 0.131,
    selfemployedMortgages: 0.775,
    selfemployedOther: 0.093,
    corporateCorporate: 0.39,
    corporateDepositaryLoansExMortgage: 0.253,
    corporateMortgages: 0.117,
    corporateCommercialPaper: 0.033,
    CorporateOther: 0.208
  },
  "1988-09-01": {
    households: 0.317,
    corporateOrSelf: 0.348,
    government: 0.243,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.66,
    corporateOrSelfSelf: 0.34,
    selfemployedNotes: 0.13,
    selfemployedMortgages: 0.776,
    selfemployedOther: 0.094,
    corporateCorporate: 0.394,
    corporateDepositaryLoansExMortgage: 0.25,
    corporateMortgages: 0.113,
    corporateCommercialPaper: 0.034,
    CorporateOther: 0.209
  },
  "1988-12-01": {
    households: 0.317,
    corporateOrSelf: 0.349,
    government: 0.242,
    municipalities: 0.091,
    corporateOrSelfCorporate: 0.663,
    corporateOrSelfSelf: 0.337,
    selfemployedNotes: 0.13,
    selfemployedMortgages: 0.781,
    selfemployedOther: 0.088,
    corporateCorporate: 0.392,
    corporateDepositaryLoansExMortgage: 0.25,
    corporateMortgages: 0.114,
    corporateCommercialPaper: 0.038,
    CorporateOther: 0.207
  },
  "1989-03-01": {
    households: 0.318,
    corporateOrSelf: 0.349,
    government: 0.243,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.666,
    corporateOrSelfSelf: 0.334,
    selfemployedNotes: 0.132,
    selfemployedMortgages: 0.779,
    selfemployedOther: 0.09,
    corporateCorporate: 0.389,
    corporateDepositaryLoansExMortgage: 0.246,
    corporateMortgages: 0.116,
    corporateCommercialPaper: 0.039,
    CorporateOther: 0.21
  },
  "1989-06-01": {
    households: 0.319,
    corporateOrSelf: 0.35,
    government: 0.242,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.669,
    corporateOrSelfSelf: 0.331,
    selfemployedNotes: 0.133,
    selfemployedMortgages: 0.777,
    selfemployedOther: 0.09,
    corporateCorporate: 0.388,
    corporateDepositaryLoansExMortgage: 0.244,
    corporateMortgages: 0.114,
    corporateCommercialPaper: 0.042,
    CorporateOther: 0.211
  },
  "1989-09-01": {
    households: 0.321,
    corporateOrSelf: 0.348,
    government: 0.242,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.67,
    corporateOrSelfSelf: 0.33,
    selfemployedNotes: 0.134,
    selfemployedMortgages: 0.778,
    selfemployedOther: 0.088,
    corporateCorporate: 0.39,
    corporateDepositaryLoansExMortgage: 0.244,
    corporateMortgages: 0.114,
    corporateCommercialPaper: 0.044,
    CorporateOther: 0.208
  },
  "1989-12-01": {
    households: 0.321,
    corporateOrSelf: 0.347,
    government: 0.242,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.672,
    corporateOrSelfSelf: 0.328,
    selfemployedNotes: 0.136,
    selfemployedMortgages: 0.776,
    selfemployedOther: 0.088,
    corporateCorporate: 0.391,
    corporateDepositaryLoansExMortgage: 0.246,
    corporateMortgages: 0.108,
    corporateCommercialPaper: 0.044,
    CorporateOther: 0.211
  },
  "1990-03-01": {
    households: 0.323,
    corporateOrSelf: 0.344,
    government: 0.243,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.675,
    corporateOrSelfSelf: 0.325,
    selfemployedNotes: 0.137,
    selfemployedMortgages: 0.774,
    selfemployedOther: 0.089,
    corporateCorporate: 0.387,
    corporateDepositaryLoansExMortgage: 0.239,
    corporateMortgages: 0.112,
    corporateCommercialPaper: 0.047,
    CorporateOther: 0.215
  },
  "1990-06-01": {
    households: 0.324,
    corporateOrSelf: 0.342,
    government: 0.245,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.679,
    corporateOrSelfSelf: 0.321,
    selfemployedNotes: 0.137,
    selfemployedMortgages: 0.774,
    selfemployedOther: 0.088,
    corporateCorporate: 0.388,
    corporateDepositaryLoansExMortgage: 0.237,
    corporateMortgages: 0.112,
    corporateCommercialPaper: 0.046,
    CorporateOther: 0.218
  },
  "1990-09-01": {
    households: 0.324,
    corporateOrSelf: 0.34,
    government: 0.248,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.681,
    corporateOrSelfSelf: 0.319,
    selfemployedNotes: 0.138,
    selfemployedMortgages: 0.773,
    selfemployedOther: 0.089,
    corporateCorporate: 0.387,
    corporateDepositaryLoansExMortgage: 0.233,
    corporateMortgages: 0.112,
    corporateCommercialPaper: 0.048,
    CorporateOther: 0.221
  },
  "1990-12-01": {
    households: 0.324,
    corporateOrSelf: 0.336,
    government: 0.252,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.683,
    corporateOrSelfSelf: 0.317,
    selfemployedNotes: 0.139,
    selfemployedMortgages: 0.772,
    selfemployedOther: 0.089,
    corporateCorporate: 0.39,
    corporateDepositaryLoansExMortgage: 0.23,
    corporateMortgages: 0.111,
    corporateCommercialPaper: 0.045,
    CorporateOther: 0.224
  },
  "1991-03-01": {
    households: 0.326,
    corporateOrSelf: 0.331,
    government: 0.255,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.683,
    corporateOrSelfSelf: 0.317,
    selfemployedNotes: 0.14,
    selfemployedMortgages: 0.771,
    selfemployedOther: 0.088,
    corporateCorporate: 0.4,
    corporateDepositaryLoansExMortgage: 0.226,
    corporateMortgages: 0.115,
    corporateCommercialPaper: 0.043,
    CorporateOther: 0.216
  },
  "1991-06-01": {
    households: 0.327,
    corporateOrSelf: 0.327,
    government: 0.258,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.683,
    corporateOrSelfSelf: 0.317,
    selfemployedNotes: 0.14,
    selfemployedMortgages: 0.771,
    selfemployedOther: 0.089,
    corporateCorporate: 0.41,
    corporateDepositaryLoansExMortgage: 0.22,
    corporateMortgages: 0.115,
    corporateCommercialPaper: 0.042,
    CorporateOther: 0.213
  },
  "1991-09-01": {
    households: 0.326,
    corporateOrSelf: 0.321,
    government: 0.264,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.684,
    corporateOrSelfSelf: 0.316,
    selfemployedNotes: 0.141,
    selfemployedMortgages: 0.769,
    selfemployedOther: 0.09,
    corporateCorporate: 0.42,
    corporateDepositaryLoansExMortgage: 0.218,
    corporateMortgages: 0.112,
    corporateCommercialPaper: 0.039,
    CorporateOther: 0.211
  },
  "1991-12-01": {
    households: 0.327,
    corporateOrSelf: 0.314,
    government: 0.267,
    municipalities: 0.092,
    corporateOrSelfCorporate: 0.684,
    corporateOrSelfSelf: 0.316,
    selfemployedNotes: 0.143,
    selfemployedMortgages: 0.767,
    selfemployedOther: 0.09,
    corporateCorporate: 0.43,
    corporateDepositaryLoansExMortgage: 0.215,
    corporateMortgages: 0.111,
    corporateCommercialPaper: 0.039,
    CorporateOther: 0.206
  },
  "1992-03-01": {
    households: 0.328,
    corporateOrSelf: 0.31,
    government: 0.272,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.685,
    corporateOrSelfSelf: 0.315,
    selfemployedNotes: 0.144,
    selfemployedMortgages: 0.765,
    selfemployedOther: 0.091,
    corporateCorporate: 0.437,
    corporateDepositaryLoansExMortgage: 0.211,
    corporateMortgages: 0.108,
    corporateCommercialPaper: 0.04,
    CorporateOther: 0.204
  },
  "1992-06-01": {
    households: 0.327,
    corporateOrSelf: 0.306,
    government: 0.276,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.686,
    corporateOrSelfSelf: 0.314,
    selfemployedNotes: 0.145,
    selfemployedMortgages: 0.764,
    selfemployedOther: 0.091,
    corporateCorporate: 0.444,
    corporateDepositaryLoansExMortgage: 0.207,
    corporateMortgages: 0.105,
    corporateCommercialPaper: 0.04,
    CorporateOther: 0.204
  },
  "1992-09-01": {
    households: 0.327,
    corporateOrSelf: 0.302,
    government: 0.28,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.689,
    corporateOrSelfSelf: 0.311,
    selfemployedNotes: 0.147,
    selfemployedMortgages: 0.761,
    selfemployedOther: 0.092,
    corporateCorporate: 0.448,
    corporateDepositaryLoansExMortgage: 0.205,
    corporateMortgages: 0.101,
    corporateCommercialPaper: 0.04,
    CorporateOther: 0.206
  },
  "1992-12-01": {
    households: 0.329,
    corporateOrSelf: 0.299,
    government: 0.283,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.69,
    corporateOrSelfSelf: 0.31,
    selfemployedNotes: 0.148,
    selfemployedMortgages: 0.759,
    selfemployedOther: 0.093,
    corporateCorporate: 0.454,
    corporateDepositaryLoansExMortgage: 0.202,
    corporateMortgages: 0.094,
    corporateCommercialPaper: 0.042,
    CorporateOther: 0.208
  },
  "1993-03-01": {
    households: 0.329,
    corporateOrSelf: 0.297,
    government: 0.285,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.694,
    corporateOrSelfSelf: 0.306,
    selfemployedNotes: 0.147,
    selfemployedMortgages: 0.759,
    selfemployedOther: 0.093,
    corporateCorporate: 0.471,
    corporateDepositaryLoansExMortgage: 0.197,
    corporateMortgages: 0.089,
    corporateCommercialPaper: 0.041,
    CorporateOther: 0.201
  },
  "1993-06-01": {
    households: 0.328,
    corporateOrSelf: 0.295,
    government: 0.287,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.698,
    corporateOrSelfSelf: 0.302,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.757,
    selfemployedOther: 0.093,
    corporateCorporate: 0.482,
    corporateDepositaryLoansExMortgage: 0.194,
    corporateMortgages: 0.084,
    corporateCommercialPaper: 0.043,
    CorporateOther: 0.197
  },
  "1993-09-01": {
    households: 0.329,
    corporateOrSelf: 0.293,
    government: 0.288,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.702,
    corporateOrSelfSelf: 0.298,
    selfemployedNotes: 0.15,
    selfemployedMortgages: 0.756,
    selfemployedOther: 0.094,
    corporateCorporate: 0.491,
    corporateDepositaryLoansExMortgage: 0.19,
    corporateMortgages: 0.082,
    corporateCommercialPaper: 0.044,
    CorporateOther: 0.193
  },
  "1993-12-01": {
    households: 0.33,
    corporateOrSelf: 0.292,
    government: 0.29,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.705,
    corporateOrSelfSelf: 0.295,
    selfemployedNotes: 0.155,
    selfemployedMortgages: 0.753,
    selfemployedOther: 0.093,
    corporateCorporate: 0.497,
    corporateDepositaryLoansExMortgage: 0.188,
    corporateMortgages: 0.081,
    corporateCommercialPaper: 0.044,
    CorporateOther: 0.189
  },
  "1994-03-01": {
    households: 0.331,
    corporateOrSelf: 0.293,
    government: 0.288,
    municipalities: 0.087,
    corporateOrSelfCorporate: 0.71,
    corporateOrSelfSelf: 0.29,
    selfemployedNotes: 0.158,
    selfemployedMortgages: 0.748,
    selfemployedOther: 0.094,
    corporateCorporate: 0.5,
    corporateDepositaryLoansExMortgage: 0.185,
    corporateMortgages: 0.078,
    corporateCommercialPaper: 0.044,
    CorporateOther: 0.193
  },
  "1994-06-01": {
    households: 0.333,
    corporateOrSelf: 0.293,
    government: 0.29,
    municipalities: 0.084,
    corporateOrSelfCorporate: 0.712,
    corporateOrSelfSelf: 0.288,
    selfemployedNotes: 0.161,
    selfemployedMortgages: 0.746,
    selfemployedOther: 0.093,
    corporateCorporate: 0.5,
    corporateDepositaryLoansExMortgage: 0.187,
    corporateMortgages: 0.077,
    corporateCommercialPaper: 0.044,
    CorporateOther: 0.193
  },
  "1994-09-01": {
    households: 0.334,
    corporateOrSelf: 0.293,
    government: 0.29,
    municipalities: 0.083,
    corporateOrSelfCorporate: 0.715,
    corporateOrSelfSelf: 0.285,
    selfemployedNotes: 0.164,
    selfemployedMortgages: 0.743,
    selfemployedOther: 0.094,
    corporateCorporate: 0.497,
    corporateDepositaryLoansExMortgage: 0.189,
    corporateMortgages: 0.077,
    corporateCommercialPaper: 0.046,
    CorporateOther: 0.192
  },
  "1994-12-01": {
    households: 0.337,
    corporateOrSelf: 0.293,
    government: 0.29,
    municipalities: 0.081,
    corporateOrSelfCorporate: 0.717,
    corporateOrSelfSelf: 0.283,
    selfemployedNotes: 0.166,
    selfemployedMortgages: 0.738,
    selfemployedOther: 0.095,
    corporateCorporate: 0.495,
    corporateDepositaryLoansExMortgage: 0.192,
    corporateMortgages: 0.074,
    corporateCommercialPaper: 0.048,
    CorporateOther: 0.192
  },
  "1995-03-01": {
    households: 0.338,
    corporateOrSelf: 0.295,
    government: 0.289,
    municipalities: 0.079,
    corporateOrSelfCorporate: 0.719,
    corporateOrSelfSelf: 0.281,
    selfemployedNotes: 0.169,
    selfemployedMortgages: 0.735,
    selfemployedOther: 0.096,
    corporateCorporate: 0.489,
    corporateDepositaryLoansExMortgage: 0.199,
    corporateMortgages: 0.073,
    corporateCommercialPaper: 0.048,
    CorporateOther: 0.192
  },
  "1995-06-01": {
    households: 0.338,
    corporateOrSelf: 0.295,
    government: 0.291,
    municipalities: 0.076,
    corporateOrSelfCorporate: 0.722,
    corporateOrSelfSelf: 0.278,
    selfemployedNotes: 0.169,
    selfemployedMortgages: 0.735,
    selfemployedOther: 0.095,
    corporateCorporate: 0.487,
    corporateDepositaryLoansExMortgage: 0.202,
    corporateMortgages: 0.071,
    corporateCommercialPaper: 0.049,
    CorporateOther: 0.191
  },
  "1995-09-01": {
    households: 0.341,
    corporateOrSelf: 0.295,
    government: 0.29,
    municipalities: 0.074,
    corporateOrSelfCorporate: 0.722,
    corporateOrSelfSelf: 0.278,
    selfemployedNotes: 0.17,
    selfemployedMortgages: 0.735,
    selfemployedOther: 0.096,
    corporateCorporate: 0.485,
    corporateDepositaryLoansExMortgage: 0.204,
    corporateMortgages: 0.071,
    corporateCommercialPaper: 0.05,
    CorporateOther: 0.19
  },
  "1995-12-01": {
    households: 0.343,
    corporateOrSelf: 0.297,
    government: 0.288,
    municipalities: 0.073,
    corporateOrSelfCorporate: 0.723,
    corporateOrSelfSelf: 0.277,
    selfemployedNotes: 0.169,
    selfemployedMortgages: 0.734,
    selfemployedOther: 0.097,
    corporateCorporate: 0.483,
    corporateDepositaryLoansExMortgage: 0.204,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.051,
    CorporateOther: 0.195
  },
  "1996-03-01": {
    households: 0.346,
    corporateOrSelf: 0.295,
    government: 0.288,
    municipalities: 0.071,
    corporateOrSelfCorporate: 0.722,
    corporateOrSelfSelf: 0.278,
    selfemployedNotes: 0.172,
    selfemployedMortgages: 0.731,
    selfemployedOther: 0.097,
    corporateCorporate: 0.485,
    corporateDepositaryLoansExMortgage: 0.202,
    corporateMortgages: 0.069,
    corporateCommercialPaper: 0.052,
    CorporateOther: 0.192
  },
  "1996-06-01": {
    households: 0.347,
    corporateOrSelf: 0.296,
    government: 0.288,
    municipalities: 0.07,
    corporateOrSelfCorporate: 0.723,
    corporateOrSelfSelf: 0.277,
    selfemployedNotes: 0.174,
    selfemployedMortgages: 0.73,
    selfemployedOther: 0.096,
    corporateCorporate: 0.485,
    corporateDepositaryLoansExMortgage: 0.201,
    corporateMortgages: 0.069,
    corporateCommercialPaper: 0.052,
    CorporateOther: 0.193
  },
  "1996-09-01": {
    households: 0.348,
    corporateOrSelf: 0.296,
    government: 0.287,
    municipalities: 0.068,
    corporateOrSelfCorporate: 0.722,
    corporateOrSelfSelf: 0.278,
    selfemployedNotes: 0.181,
    selfemployedMortgages: 0.726,
    selfemployedOther: 0.093,
    corporateCorporate: 0.483,
    corporateDepositaryLoansExMortgage: 0.204,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.05,
    CorporateOther: 0.194
  },
  "1996-12-01": {
    households: 0.35,
    corporateOrSelf: 0.297,
    government: 0.286,
    municipalities: 0.068,
    corporateOrSelfCorporate: 0.721,
    corporateOrSelfSelf: 0.279,
    selfemployedNotes: 0.183,
    selfemployedMortgages: 0.723,
    selfemployedOther: 0.094,
    corporateCorporate: 0.488,
    corporateDepositaryLoansExMortgage: 0.204,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.048,
    CorporateOther: 0.192
  },
  "1997-03-01": {
    households: 0.351,
    corporateOrSelf: 0.298,
    government: 0.284,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.72,
    corporateOrSelfSelf: 0.28,
    selfemployedNotes: 0.191,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.094,
    corporateCorporate: 0.488,
    corporateDepositaryLoansExMortgage: 0.208,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.048,
    CorporateOther: 0.188
  },
  "1997-06-01": {
    households: 0.352,
    corporateOrSelf: 0.3,
    government: 0.282,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.72,
    corporateOrSelfSelf: 0.28,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.092,
    corporateCorporate: 0.489,
    corporateDepositaryLoansExMortgage: 0.208,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.049,
    CorporateOther: 0.186
  },
  "1997-09-01": {
    households: 0.352,
    corporateOrSelf: 0.302,
    government: 0.279,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.722,
    corporateOrSelfSelf: 0.278,
    selfemployedNotes: 0.197,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.095,
    corporateCorporate: 0.49,
    corporateDepositaryLoansExMortgage: 0.205,
    corporateMortgages: 0.071,
    corporateCommercialPaper: 0.048,
    CorporateOther: 0.187
  },
  "1997-12-01": {
    households: 0.353,
    corporateOrSelf: 0.305,
    government: 0.275,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.721,
    corporateOrSelfSelf: 0.279,
    selfemployedNotes: 0.203,
    selfemployedMortgages: 0.705,
    selfemployedOther: 0.092,
    corporateCorporate: 0.486,
    corporateDepositaryLoansExMortgage: 0.204,
    corporateMortgages: 0.071,
    corporateCommercialPaper: 0.048,
    CorporateOther: 0.192
  },
  "1998-03-01": {
    households: 0.354,
    corporateOrSelf: 0.309,
    government: 0.27,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.72,
    corporateOrSelfSelf: 0.28,
    selfemployedNotes: 0.199,
    selfemployedMortgages: 0.71,
    selfemployedOther: 0.091,
    corporateCorporate: 0.492,
    corporateDepositaryLoansExMortgage: 0.201,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.05,
    CorporateOther: 0.189
  },
  "1998-06-01": {
    households: 0.354,
    corporateOrSelf: 0.312,
    government: 0.267,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.72,
    corporateOrSelfSelf: 0.28,
    selfemployedNotes: 0.199,
    selfemployedMortgages: 0.712,
    selfemployedOther: 0.09,
    corporateCorporate: 0.499,
    corporateDepositaryLoansExMortgage: 0.203,
    corporateMortgages: 0.064,
    corporateCommercialPaper: 0.049,
    CorporateOther: 0.185
  },
  "1998-09-01": {
    households: 0.355,
    corporateOrSelf: 0.316,
    government: 0.262,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.718,
    corporateOrSelfSelf: 0.282,
    selfemployedNotes: 0.198,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.088,
    corporateCorporate: 0.495,
    corporateDepositaryLoansExMortgage: 0.205,
    corporateMortgages: 0.062,
    corporateCommercialPaper: 0.053,
    CorporateOther: 0.185
  },
  "1998-12-01": {
    households: 0.357,
    corporateOrSelf: 0.319,
    government: 0.257,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.717,
    corporateOrSelfSelf: 0.283,
    selfemployedNotes: 0.195,
    selfemployedMortgages: 0.717,
    selfemployedOther: 0.087,
    corporateCorporate: 0.493,
    corporateDepositaryLoansExMortgage: 0.204,
    corporateMortgages: 0.064,
    corporateCommercialPaper: 0.049,
    CorporateOther: 0.19
  },
  "1999-03-01": {
    households: 0.359,
    corporateOrSelf: 0.323,
    government: 0.252,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.716,
    corporateOrSelfSelf: 0.284,
    selfemployedNotes: 0.198,
    selfemployedMortgages: 0.716,
    selfemployedOther: 0.086,
    corporateCorporate: 0.491,
    corporateDepositaryLoansExMortgage: 0.201,
    corporateMortgages: 0.065,
    corporateCommercialPaper: 0.052,
    CorporateOther: 0.192
  },
  "1999-06-01": {
    households: 0.362,
    corporateOrSelf: 0.324,
    government: 0.248,
    municipalities: 0.066,
    corporateOrSelfCorporate: 0.715,
    corporateOrSelfSelf: 0.285,
    selfemployedNotes: 0.196,
    selfemployedMortgages: 0.719,
    selfemployedOther: 0.085,
    corporateCorporate: 0.497,
    corporateDepositaryLoansExMortgage: 0.199,
    corporateMortgages: 0.067,
    corporateCommercialPaper: 0.05,
    CorporateOther: 0.186
  },
  "1999-09-01": {
    households: 0.363,
    corporateOrSelf: 0.329,
    government: 0.242,
    municipalities: 0.065,
    corporateOrSelfCorporate: 0.716,
    corporateOrSelfSelf: 0.284,
    selfemployedNotes: 0.199,
    selfemployedMortgages: 0.717,
    selfemployedOther: 0.084,
    corporateCorporate: 0.495,
    corporateDepositaryLoansExMortgage: 0.197,
    corporateMortgages: 0.076,
    corporateCommercialPaper: 0.052,
    CorporateOther: 0.181
  },
  "1999-12-01": {
    households: 0.366,
    corporateOrSelf: 0.331,
    government: 0.239,
    municipalities: 0.065,
    corporateOrSelfCorporate: 0.712,
    corporateOrSelfSelf: 0.288,
    selfemployedNotes: 0.202,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.083,
    corporateCorporate: 0.494,
    corporateDepositaryLoansExMortgage: 0.196,
    corporateMortgages: 0.077,
    corporateCommercialPaper: 0.053,
    CorporateOther: 0.18
  },
  "2000-03-01": {
    households: 0.37,
    corporateOrSelf: 0.334,
    government: 0.232,
    municipalities: 0.064,
    corporateOrSelfCorporate: 0.712,
    corporateOrSelfSelf: 0.288,
    selfemployedNotes: 0.203,
    selfemployedMortgages: 0.714,
    selfemployedOther: 0.083,
    corporateCorporate: 0.49,
    corporateDepositaryLoansExMortgage: 0.194,
    corporateMortgages: 0.077,
    corporateCommercialPaper: 0.054,
    CorporateOther: 0.185
  },
  "2000-06-01": {
    households: 0.373,
    corporateOrSelf: 0.34,
    government: 0.224,
    municipalities: 0.063,
    corporateOrSelfCorporate: 0.71,
    corporateOrSelfSelf: 0.29,
    selfemployedNotes: 0.208,
    selfemployedMortgages: 0.711,
    selfemployedOther: 0.081,
    corporateCorporate: 0.485,
    corporateDepositaryLoansExMortgage: 0.196,
    corporateMortgages: 0.077,
    corporateCommercialPaper: 0.058,
    CorporateOther: 0.183
  },
  "2000-09-01": {
    households: 0.378,
    corporateOrSelf: 0.342,
    government: 0.218,
    municipalities: 0.063,
    corporateOrSelfCorporate: 0.709,
    corporateOrSelfSelf: 0.291,
    selfemployedNotes: 0.207,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.081,
    corporateCorporate: 0.487,
    corporateDepositaryLoansExMortgage: 0.195,
    corporateMortgages: 0.077,
    corporateCommercialPaper: 0.061,
    CorporateOther: 0.179
  },
  "2000-12-01": {
    households: 0.38,
    corporateOrSelf: 0.344,
    government: 0.213,
    municipalities: 0.062,
    corporateOrSelfCorporate: 0.706,
    corporateOrSelfSelf: 0.294,
    selfemployedNotes: 0.207,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.08,
    corporateCorporate: 0.487,
    corporateDepositaryLoansExMortgage: 0.193,
    corporateMortgages: 0.076,
    corporateCommercialPaper: 0.06,
    CorporateOther: 0.184
  },
  "2001-03-01": {
    households: 0.382,
    corporateOrSelf: 0.342,
    government: 0.213,
    municipalities: 0.063,
    corporateOrSelfCorporate: 0.702,
    corporateOrSelfSelf: 0.298,
    selfemployedNotes: 0.211,
    selfemployedMortgages: 0.711,
    selfemployedOther: 0.078,
    corporateCorporate: 0.508,
    corporateDepositaryLoansExMortgage: 0.19,
    corporateMortgages: 0.071,
    corporateCommercialPaper: 0.045,
    CorporateOther: 0.186
  },
  "2001-06-01": {
    households: 0.386,
    corporateOrSelf: 0.345,
    government: 0.205,
    municipalities: 0.064,
    corporateOrSelfCorporate: 0.701,
    corporateOrSelfSelf: 0.299,
    selfemployedNotes: 0.212,
    selfemployedMortgages: 0.711,
    selfemployedOther: 0.077,
    corporateCorporate: 0.524,
    corporateDepositaryLoansExMortgage: 0.181,
    corporateMortgages: 0.072,
    corporateCommercialPaper: 0.038,
    CorporateOther: 0.185
  },
  "2001-09-01": {
    households: 0.391,
    corporateOrSelf: 0.341,
    government: 0.205,
    municipalities: 0.063,
    corporateOrSelfCorporate: 0.699,
    corporateOrSelfSelf: 0.301,
    selfemployedNotes: 0.212,
    selfemployedMortgages: 0.714,
    selfemployedOther: 0.074,
    corporateCorporate: 0.528,
    corporateDepositaryLoansExMortgage: 0.174,
    corporateMortgages: 0.077,
    corporateCommercialPaper: 0.035,
    CorporateOther: 0.186
  },
  "2001-12-01": {
    households: 0.391,
    corporateOrSelf: 0.34,
    government: 0.205,
    municipalities: 0.064,
    corporateOrSelfCorporate: 0.695,
    corporateOrSelfSelf: 0.305,
    selfemployedNotes: 0.212,
    selfemployedMortgages: 0.716,
    selfemployedOther: 0.072,
    corporateCorporate: 0.535,
    corporateDepositaryLoansExMortgage: 0.166,
    corporateMortgages: 0.082,
    corporateCommercialPaper: 0.037,
    CorporateOther: 0.18
  },
  "2002-03-01": {
    households: 0.395,
    corporateOrSelf: 0.338,
    government: 0.202,
    municipalities: 0.065,
    corporateOrSelfCorporate: 0.692,
    corporateOrSelfSelf: 0.308,
    selfemployedNotes: 0.211,
    selfemployedMortgages: 0.717,
    selfemployedOther: 0.072,
    corporateCorporate: 0.542,
    corporateDepositaryLoansExMortgage: 0.163,
    corporateMortgages: 0.083,
    corporateCommercialPaper: 0.033,
    CorporateOther: 0.18
  },
  "2002-06-01": {
    households: 0.396,
    corporateOrSelf: 0.334,
    government: 0.204,
    municipalities: 0.065,
    corporateOrSelfCorporate: 0.687,
    corporateOrSelfSelf: 0.313,
    selfemployedNotes: 0.21,
    selfemployedMortgages: 0.718,
    selfemployedOther: 0.072,
    corporateCorporate: 0.55,
    corporateDepositaryLoansExMortgage: 0.154,
    corporateMortgages: 0.084,
    corporateCommercialPaper: 0.03,
    CorporateOther: 0.181
  },
  "2002-09-01": {
    households: 0.399,
    corporateOrSelf: 0.33,
    government: 0.205,
    municipalities: 0.066,
    corporateOrSelfCorporate: 0.683,
    corporateOrSelfSelf: 0.317,
    selfemployedNotes: 0.209,
    selfemployedMortgages: 0.72,
    selfemployedOther: 0.071,
    corporateCorporate: 0.548,
    corporateDepositaryLoansExMortgage: 0.15,
    corporateMortgages: 0.088,
    corporateCommercialPaper: 0.028,
    CorporateOther: 0.186
  },
  "2002-12-01": {
    households: 0.402,
    corporateOrSelf: 0.326,
    government: 0.205,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.679,
    corporateOrSelfSelf: 0.321,
    selfemployedNotes: 0.208,
    selfemployedMortgages: 0.722,
    selfemployedOther: 0.071,
    corporateCorporate: 0.549,
    corporateDepositaryLoansExMortgage: 0.145,
    corporateMortgages: 0.094,
    corporateCommercialPaper: 0.025,
    CorporateOther: 0.187
  },
  "2003-03-01": {
    households: 0.407,
    corporateOrSelf: 0.322,
    government: 0.204,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.677,
    corporateOrSelfSelf: 0.323,
    selfemployedNotes: 0.207,
    selfemployedMortgages: 0.722,
    selfemployedOther: 0.071,
    corporateCorporate: 0.551,
    corporateDepositaryLoansExMortgage: 0.14,
    corporateMortgages: 0.097,
    corporateCommercialPaper: 0.024,
    CorporateOther: 0.188
  },
  "2003-06-01": {
    households: 0.412,
    corporateOrSelf: 0.315,
    government: 0.206,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.676,
    corporateOrSelfSelf: 0.324,
    selfemployedNotes: 0.207,
    selfemployedMortgages: 0.724,
    selfemployedOther: 0.07,
    corporateCorporate: 0.56,
    corporateDepositaryLoansExMortgage: 0.133,
    corporateMortgages: 0.099,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.187
  },
  "2003-09-01": {
    households: 0.415,
    corporateOrSelf: 0.311,
    government: 0.207,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.676,
    corporateOrSelfSelf: 0.324,
    selfemployedNotes: 0.206,
    selfemployedMortgages: 0.725,
    selfemployedOther: 0.069,
    corporateCorporate: 0.562,
    corporateDepositaryLoansExMortgage: 0.13,
    corporateMortgages: 0.102,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.186
  },
  "2003-12-01": {
    households: 0.419,
    corporateOrSelf: 0.306,
    government: 0.208,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.673,
    corporateOrSelfSelf: 0.327,
    selfemployedNotes: 0.205,
    selfemployedMortgages: 0.726,
    selfemployedOther: 0.069,
    corporateCorporate: 0.565,
    corporateDepositaryLoansExMortgage: 0.128,
    corporateMortgages: 0.103,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.187
  },
  "2004-03-01": {
    households: 0.408,
    corporateOrSelf: 0.296,
    government: 0.2,
    municipalities: 0.096,
    corporateOrSelfCorporate: 0.67,
    corporateOrSelfSelf: 0.33,
    selfemployedNotes: 0.206,
    selfemployedMortgages: 0.725,
    selfemployedOther: 0.069,
    corporateCorporate: 0.559,
    corporateDepositaryLoansExMortgage: 0.119,
    corporateMortgages: 0.109,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.194
  },
  "2004-06-01": {
    households: 0.411,
    corporateOrSelf: 0.292,
    government: 0.202,
    municipalities: 0.096,
    corporateOrSelfCorporate: 0.664,
    corporateOrSelfSelf: 0.336,
    selfemployedNotes: 0.203,
    selfemployedMortgages: 0.731,
    selfemployedOther: 0.066,
    corporateCorporate: 0.555,
    corporateDepositaryLoansExMortgage: 0.12,
    corporateMortgages: 0.112,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.195
  },
  "2004-09-01": {
    households: 0.412,
    corporateOrSelf: 0.291,
    government: 0.202,
    municipalities: 0.096,
    corporateOrSelfCorporate: 0.662,
    corporateOrSelfSelf: 0.338,
    selfemployedNotes: 0.201,
    selfemployedMortgages: 0.736,
    selfemployedOther: 0.064,
    corporateCorporate: 0.548,
    corporateDepositaryLoansExMortgage: 0.121,
    corporateMortgages: 0.117,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.197
  },
  "2004-12-01": {
    households: 0.417,
    corporateOrSelf: 0.288,
    government: 0.2,
    municipalities: 0.095,
    corporateOrSelfCorporate: 0.658,
    corporateOrSelfSelf: 0.342,
    selfemployedNotes: 0.198,
    selfemployedMortgages: 0.74,
    selfemployedOther: 0.062,
    corporateCorporate: 0.541,
    corporateDepositaryLoansExMortgage: 0.124,
    corporateMortgages: 0.117,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.199
  },
  "2005-03-01": {
    households: 0.417,
    corporateOrSelf: 0.288,
    government: 0.2,
    municipalities: 0.095,
    corporateOrSelfCorporate: 0.654,
    corporateOrSelfSelf: 0.346,
    selfemployedNotes: 0.207,
    selfemployedMortgages: 0.735,
    selfemployedOther: 0.058,
    corporateCorporate: 0.533,
    corporateDepositaryLoansExMortgage: 0.122,
    corporateMortgages: 0.119,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.205
  },
  "2005-06-01": {
    households: 0.42,
    corporateOrSelf: 0.287,
    government: 0.199,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.65,
    corporateOrSelfSelf: 0.35,
    selfemployedNotes: 0.215,
    selfemployedMortgages: 0.728,
    selfemployedOther: 0.057,
    corporateCorporate: 0.523,
    corporateDepositaryLoansExMortgage: 0.124,
    corporateMortgages: 0.127,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.207
  },
  "2005-09-01": {
    households: 0.423,
    corporateOrSelf: 0.286,
    government: 0.197,
    municipalities: 0.094,
    corporateOrSelfCorporate: 0.645,
    corporateOrSelfSelf: 0.355,
    selfemployedNotes: 0.222,
    selfemployedMortgages: 0.722,
    selfemployedOther: 0.056,
    corporateCorporate: 0.518,
    corporateDepositaryLoansExMortgage: 0.116,
    corporateMortgages: 0.136,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.213
  },
  "2005-12-01": {
    households: 0.425,
    corporateOrSelf: 0.287,
    government: 0.196,
    municipalities: 0.093,
    corporateOrSelfCorporate: 0.642,
    corporateOrSelfSelf: 0.358,
    selfemployedNotes: 0.229,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.056,
    corporateCorporate: 0.506,
    corporateDepositaryLoansExMortgage: 0.112,
    corporateMortgages: 0.146,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.218
  },
  "2006-03-01": {
    households: 0.428,
    corporateOrSelf: 0.287,
    government: 0.195,
    municipalities: 0.091,
    corporateOrSelfCorporate: 0.639,
    corporateOrSelfSelf: 0.361,
    selfemployedNotes: 0.231,
    selfemployedMortgages: 0.714,
    selfemployedOther: 0.055,
    corporateCorporate: 0.499,
    corporateDepositaryLoansExMortgage: 0.115,
    corporateMortgages: 0.148,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.223
  },
  "2006-06-01": {
    households: 0.43,
    corporateOrSelf: 0.287,
    government: 0.193,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.635,
    corporateOrSelfSelf: 0.365,
    selfemployedNotes: 0.232,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.055,
    corporateCorporate: 0.494,
    corporateDepositaryLoansExMortgage: 0.114,
    corporateMortgages: 0.149,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.227
  },
  "2006-09-01": {
    households: 0.433,
    corporateOrSelf: 0.288,
    government: 0.19,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.63,
    corporateOrSelfSelf: 0.37,
    selfemployedNotes: 0.234,
    selfemployedMortgages: 0.712,
    selfemployedOther: 0.054,
    corporateCorporate: 0.492,
    corporateDepositaryLoansExMortgage: 0.113,
    corporateMortgages: 0.152,
    corporateCommercialPaper: 0.015,
    CorporateOther: 0.228
  },
  "2006-12-01": {
    households: 0.433,
    corporateOrSelf: 0.29,
    government: 0.187,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.629,
    corporateOrSelfSelf: 0.371,
    selfemployedNotes: 0.235,
    selfemployedMortgages: 0.711,
    selfemployedOther: 0.054,
    corporateCorporate: 0.484,
    corporateDepositaryLoansExMortgage: 0.108,
    corporateMortgages: 0.154,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.234
  },
  "2007-03-01": {
    households: 0.431,
    corporateOrSelf: 0.292,
    government: 0.187,
    municipalities: 0.09,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.238,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.053,
    corporateCorporate: 0.477,
    corporateDepositaryLoansExMortgage: 0.108,
    corporateMortgages: 0.152,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.243
  },
  "2007-06-01": {
    households: 0.431,
    corporateOrSelf: 0.296,
    government: 0.184,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.24,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.053,
    corporateCorporate: 0.47,
    corporateDepositaryLoansExMortgage: 0.107,
    corporateMortgages: 0.15,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.251
  },
  "2007-09-01": {
    households: 0.43,
    corporateOrSelf: 0.3,
    government: 0.182,
    municipalities: 0.089,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.242,
    selfemployedMortgages: 0.706,
    selfemployedOther: 0.052,
    corporateCorporate: 0.459,
    corporateDepositaryLoansExMortgage: 0.111,
    corporateMortgages: 0.151,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.26
  },
  "2007-12-01": {
    households: 0.428,
    corporateOrSelf: 0.302,
    government: 0.181,
    municipalities: 0.088,
    corporateOrSelfCorporate: 0.624,
    corporateOrSelfSelf: 0.376,
    selfemployedNotes: 0.243,
    selfemployedMortgages: 0.705,
    selfemployedOther: 0.052,
    corporateCorporate: 0.452,
    corporateDepositaryLoansExMortgage: 0.113,
    corporateMortgages: 0.15,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.266
  },
  "2008-03-01": {
    households: 0.426,
    corporateOrSelf: 0.304,
    government: 0.183,
    municipalities: 0.087,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.246,
    selfemployedMortgages: 0.701,
    selfemployedOther: 0.053,
    corporateCorporate: 0.447,
    corporateDepositaryLoansExMortgage: 0.118,
    corporateMortgages: 0.148,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.266
  },
  "2008-06-01": {
    households: 0.421,
    corporateOrSelf: 0.307,
    government: 0.184,
    municipalities: 0.087,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.248,
    selfemployedMortgages: 0.698,
    selfemployedOther: 0.054,
    corporateCorporate: 0.45,
    corporateDepositaryLoansExMortgage: 0.118,
    corporateMortgages: 0.145,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.269
  },
  "2008-09-01": {
    households: 0.415,
    corporateOrSelf: 0.305,
    government: 0.195,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.621,
    corporateOrSelfSelf: 0.379,
    selfemployedNotes: 0.251,
    selfemployedMortgages: 0.697,
    selfemployedOther: 0.052,
    corporateCorporate: 0.448,
    corporateDepositaryLoansExMortgage: 0.12,
    corporateMortgages: 0.138,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.274
  },
  "2008-12-01": {
    households: 0.403,
    corporateOrSelf: 0.303,
    government: 0.209,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.615,
    corporateOrSelfSelf: 0.385,
    selfemployedNotes: 0.254,
    selfemployedMortgages: 0.696,
    selfemployedOther: 0.051,
    corporateCorporate: 0.454,
    corporateDepositaryLoansExMortgage: 0.118,
    corporateMortgages: 0.132,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.276
  },
  "2009-03-01": {
    households: 0.398,
    corporateOrSelf: 0.299,
    government: 0.219,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.613,
    corporateOrSelfSelf: 0.387,
    selfemployedNotes: 0.252,
    selfemployedMortgages: 0.698,
    selfemployedOther: 0.05,
    corporateCorporate: 0.473,
    corporateDepositaryLoansExMortgage: 0.111,
    corporateMortgages: 0.131,
    corporateCommercialPaper: 0.015,
    CorporateOther: 0.27
  },
  "2009-06-01": {
    households: 0.393,
    corporateOrSelf: 0.293,
    government: 0.229,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.612,
    corporateOrSelfSelf: 0.388,
    selfemployedNotes: 0.246,
    selfemployedMortgages: 0.703,
    selfemployedOther: 0.051,
    corporateCorporate: 0.487,
    corporateDepositaryLoansExMortgage: 0.103,
    corporateMortgages: 0.13,
    corporateCommercialPaper: 0.01,
    CorporateOther: 0.269
  },
  "2009-09-01": {
    households: 0.389,
    corporateOrSelf: 0.287,
    government: 0.238,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.611,
    corporateOrSelfSelf: 0.389,
    selfemployedNotes: 0.241,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.051,
    corporateCorporate: 0.499,
    corporateDepositaryLoansExMortgage: 0.094,
    corporateMortgages: 0.127,
    corporateCommercialPaper: 0.01,
    CorporateOther: 0.27
  },
  "2009-12-01": {
    households: 0.388,
    corporateOrSelf: 0.281,
    government: 0.245,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.606,
    corporateOrSelfSelf: 0.394,
    selfemployedNotes: 0.235,
    selfemployedMortgages: 0.714,
    selfemployedOther: 0.05,
    corporateCorporate: 0.518,
    corporateDepositaryLoansExMortgage: 0.089,
    corporateMortgages: 0.123,
    corporateCommercialPaper: 0.009,
    CorporateOther: 0.261
  },
  "2010-03-01": {
    households: 0.382,
    corporateOrSelf: 0.277,
    government: 0.254,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.606,
    corporateOrSelfSelf: 0.394,
    selfemployedNotes: 0.235,
    selfemployedMortgages: 0.714,
    selfemployedOther: 0.05,
    corporateCorporate: 0.527,
    corporateDepositaryLoansExMortgage: 0.082,
    corporateMortgages: 0.121,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.258
  },
  "2010-06-01": {
    households: 0.376,
    corporateOrSelf: 0.273,
    government: 0.265,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.604,
    corporateOrSelfSelf: 0.396,
    selfemployedNotes: 0.234,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.051,
    corporateCorporate: 0.536,
    corporateDepositaryLoansExMortgage: 0.078,
    corporateMortgages: 0.115,
    corporateCommercialPaper: 0.014,
    CorporateOther: 0.256
  },
  "2010-09-01": {
    households: 0.37,
    corporateOrSelf: 0.271,
    government: 0.274,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.606,
    corporateOrSelfSelf: 0.394,
    selfemployedNotes: 0.233,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.052,
    corporateCorporate: 0.544,
    corporateDepositaryLoansExMortgage: 0.076,
    corporateMortgages: 0.11,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.254
  },
  "2010-12-01": {
    households: 0.368,
    corporateOrSelf: 0.267,
    government: 0.279,
    municipalities: 0.086,
    corporateOrSelfCorporate: 0.604,
    corporateOrSelfSelf: 0.396,
    selfemployedNotes: 0.233,
    selfemployedMortgages: 0.716,
    selfemployedOther: 0.052,
    corporateCorporate: 0.557,
    corporateDepositaryLoansExMortgage: 0.079,
    corporateMortgages: 0.111,
    corporateCommercialPaper: 0.014,
    CorporateOther: 0.239
  },
  "2011-03-01": {
    households: 0.367,
    corporateOrSelf: 0.265,
    government: 0.283,
    municipalities: 0.085,
    corporateOrSelfCorporate: 0.605,
    corporateOrSelfSelf: 0.395,
    selfemployedNotes: 0.233,
    selfemployedMortgages: 0.716,
    selfemployedOther: 0.051,
    corporateCorporate: 0.56,
    corporateDepositaryLoansExMortgage: 0.083,
    corporateMortgages: 0.103,
    corporateCommercialPaper: 0.015,
    CorporateOther: 0.239
  },
  "2011-06-01": {
    households: 0.362,
    corporateOrSelf: 0.265,
    government: 0.289,
    municipalities: 0.083,
    corporateOrSelfCorporate: 0.61,
    corporateOrSelfSelf: 0.39,
    selfemployedNotes: 0.232,
    selfemployedMortgages: 0.717,
    selfemployedOther: 0.051,
    corporateCorporate: 0.56,
    corporateDepositaryLoansExMortgage: 0.086,
    corporateMortgages: 0.097,
    corporateCommercialPaper: 0.016,
    CorporateOther: 0.241
  },
  "2011-09-01": {
    households: 0.356,
    corporateOrSelf: 0.264,
    government: 0.297,
    municipalities: 0.083,
    corporateOrSelfCorporate: 0.615,
    corporateOrSelfSelf: 0.385,
    selfemployedNotes: 0.231,
    selfemployedMortgages: 0.719,
    selfemployedOther: 0.05,
    corporateCorporate: 0.559,
    corporateDepositaryLoansExMortgage: 0.093,
    corporateMortgages: 0.092,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.239
  },
  "2011-12-01": {
    households: 0.353,
    corporateOrSelf: 0.265,
    government: 0.3,
    municipalities: 0.082,
    corporateOrSelfCorporate: 0.62,
    corporateOrSelfSelf: 0.38,
    selfemployedNotes: 0.23,
    selfemployedMortgages: 0.719,
    selfemployedOther: 0.051,
    corporateCorporate: 0.555,
    corporateDepositaryLoansExMortgage: 0.099,
    corporateMortgages: 0.09,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.238
  },
  "2012-03-01": {
    households: 0.349,
    corporateOrSelf: 0.264,
    government: 0.305,
    municipalities: 0.081,
    corporateOrSelfCorporate: 0.619,
    corporateOrSelfSelf: 0.381,
    selfemployedNotes: 0.233,
    selfemployedMortgages: 0.717,
    selfemployedOther: 0.05,
    corporateCorporate: 0.564,
    corporateDepositaryLoansExMortgage: 0.1,
    corporateMortgages: 0.084,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.235
  },
  "2012-06-01": {
    households: 0.344,
    corporateOrSelf: 0.263,
    government: 0.312,
    municipalities: 0.081,
    corporateOrSelfCorporate: 0.618,
    corporateOrSelfSelf: 0.382,
    selfemployedNotes: 0.234,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.051,
    corporateCorporate: 0.568,
    corporateDepositaryLoansExMortgage: 0.103,
    corporateMortgages: 0.078,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.233
  },
  "2012-09-01": {
    households: 0.34,
    corporateOrSelf: 0.265,
    government: 0.315,
    municipalities: 0.08,
    corporateOrSelfCorporate: 0.621,
    corporateOrSelfSelf: 0.379,
    selfemployedNotes: 0.236,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.051,
    corporateCorporate: 0.572,
    corporateDepositaryLoansExMortgage: 0.104,
    corporateMortgages: 0.072,
    corporateCommercialPaper: 0.017,
    CorporateOther: 0.235
  },
  "2012-12-01": {
    households: 0.337,
    corporateOrSelf: 0.267,
    government: 0.317,
    municipalities: 0.079,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.237,
    selfemployedMortgages: 0.712,
    selfemployedOther: 0.051,
    corporateCorporate: 0.577,
    corporateDepositaryLoansExMortgage: 0.105,
    corporateMortgages: 0.071,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.227
  },
  "2013-03-01": {
    households: 0.334,
    corporateOrSelf: 0.266,
    government: 0.322,
    municipalities: 0.078,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.237,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.05,
    corporateCorporate: 0.579,
    corporateDepositaryLoansExMortgage: 0.103,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.23
  },
  "2013-06-01": {
    households: 0.332,
    corporateOrSelf: 0.268,
    government: 0.323,
    municipalities: 0.077,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.237,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.051,
    corporateCorporate: 0.578,
    corporateDepositaryLoansExMortgage: 0.103,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.232
  },
  "2013-09-01": {
    households: 0.33,
    corporateOrSelf: 0.27,
    government: 0.324,
    municipalities: 0.076,
    corporateOrSelfCorporate: 0.63,
    corporateOrSelfSelf: 0.37,
    selfemployedNotes: 0.236,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.051,
    corporateCorporate: 0.58,
    corporateDepositaryLoansExMortgage: 0.103,
    corporateMortgages: 0.069,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.225
  },
  "2013-12-01": {
    households: 0.33,
    corporateOrSelf: 0.269,
    government: 0.325,
    municipalities: 0.076,
    corporateOrSelfCorporate: 0.631,
    corporateOrSelfSelf: 0.369,
    selfemployedNotes: 0.236,
    selfemployedMortgages: 0.712,
    selfemployedOther: 0.052,
    corporateCorporate: 0.58,
    corporateDepositaryLoansExMortgage: 0.107,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.225
  },
  "2014-03-01": {
    households: 0.327,
    corporateOrSelf: 0.271,
    government: 0.327,
    municipalities: 0.075,
    corporateOrSelfCorporate: 0.631,
    corporateOrSelfSelf: 0.369,
    selfemployedNotes: 0.238,
    selfemployedMortgages: 0.712,
    selfemployedOther: 0.051,
    corporateCorporate: 0.58,
    corporateDepositaryLoansExMortgage: 0.107,
    corporateMortgages: 0.065,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.226
  },
  "2014-06-01": {
    households: 0.326,
    corporateOrSelf: 0.272,
    government: 0.329,
    municipalities: 0.074,
    corporateOrSelfCorporate: 0.63,
    corporateOrSelfSelf: 0.37,
    selfemployedNotes: 0.238,
    selfemployedMortgages: 0.711,
    selfemployedOther: 0.051,
    corporateCorporate: 0.582,
    corporateDepositaryLoansExMortgage: 0.11,
    corporateMortgages: 0.063,
    corporateCommercialPaper: 0.024,
    CorporateOther: 0.22
  },
  "2014-09-01": {
    households: 0.322,
    corporateOrSelf: 0.274,
    government: 0.332,
    municipalities: 0.072,
    corporateOrSelfCorporate: 0.63,
    corporateOrSelfSelf: 0.37,
    selfemployedNotes: 0.239,
    selfemployedMortgages: 0.71,
    selfemployedOther: 0.05,
    corporateCorporate: 0.58,
    corporateDepositaryLoansExMortgage: 0.111,
    corporateMortgages: 0.062,
    corporateCommercialPaper: 0.024,
    CorporateOther: 0.223
  },
  "2014-12-01": {
    households: 0.321,
    corporateOrSelf: 0.277,
    government: 0.33,
    municipalities: 0.071,
    corporateOrSelfCorporate: 0.631,
    corporateOrSelfSelf: 0.369,
    selfemployedNotes: 0.24,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.051,
    corporateCorporate: 0.58,
    corporateDepositaryLoansExMortgage: 0.113,
    corporateMortgages: 0.061,
    corporateCommercialPaper: 0.024,
    CorporateOther: 0.221
  },
  "2015-03-01": {
    households: 0.319,
    corporateOrSelf: 0.28,
    government: 0.33,
    municipalities: 0.071,
    corporateOrSelfCorporate: 0.633,
    corporateOrSelfSelf: 0.367,
    selfemployedNotes: 0.241,
    selfemployedMortgages: 0.71,
    selfemployedOther: 0.049,
    corporateCorporate: 0.583,
    corporateDepositaryLoansExMortgage: 0.113,
    corporateMortgages: 0.062,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.221
  },
  "2015-06-01": {
    households: 0.317,
    corporateOrSelf: 0.282,
    government: 0.331,
    municipalities: 0.07,
    corporateOrSelfCorporate: 0.635,
    corporateOrSelfSelf: 0.365,
    selfemployedNotes: 0.241,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.05,
    corporateCorporate: 0.588,
    corporateDepositaryLoansExMortgage: 0.114,
    corporateMortgages: 0.063,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.215
  },
  "2015-09-01": {
    households: 0.316,
    corporateOrSelf: 0.284,
    government: 0.331,
    municipalities: 0.07,
    corporateOrSelfCorporate: 0.635,
    corporateOrSelfSelf: 0.365,
    selfemployedNotes: 0.241,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.05,
    corporateCorporate: 0.591,
    corporateDepositaryLoansExMortgage: 0.114,
    corporateMortgages: 0.064,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.209
  },
  "2015-12-01": {
    households: 0.312,
    corporateOrSelf: 0.285,
    government: 0.334,
    municipalities: 0.069,
    corporateOrSelfCorporate: 0.635,
    corporateOrSelfSelf: 0.365,
    selfemployedNotes: 0.241,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.049,
    corporateCorporate: 0.589,
    corporateDepositaryLoansExMortgage: 0.117,
    corporateMortgages: 0.069,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.203
  },
  "2016-03-01": {
    households: 0.31,
    corporateOrSelf: 0.287,
    government: 0.336,
    municipalities: 0.068,
    corporateOrSelfCorporate: 0.635,
    corporateOrSelfSelf: 0.365,
    selfemployedNotes: 0.243,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.047,
    corporateCorporate: 0.592,
    corporateDepositaryLoansExMortgage: 0.117,
    corporateMortgages: 0.066,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.202
  },
  "2016-06-01": {
    households: 0.308,
    corporateOrSelf: 0.286,
    government: 0.337,
    municipalities: 0.068,
    corporateOrSelfCorporate: 0.632,
    corporateOrSelfSelf: 0.368,
    selfemployedNotes: 0.244,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.047,
    corporateCorporate: 0.598,
    corporateDepositaryLoansExMortgage: 0.116,
    corporateMortgages: 0.065,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.198
  },
  "2016-09-01": {
    households: 0.308,
    corporateOrSelf: 0.287,
    government: 0.338,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.63,
    corporateOrSelfSelf: 0.37,
    selfemployedNotes: 0.245,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.046,
    corporateCorporate: 0.601,
    corporateDepositaryLoansExMortgage: 0.116,
    corporateMortgages: 0.068,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.198
  },
  "2016-12-01": {
    households: 0.308,
    corporateOrSelf: 0.287,
    government: 0.338,
    municipalities: 0.067,
    corporateOrSelfCorporate: 0.624,
    corporateOrSelfSelf: 0.376,
    selfemployedNotes: 0.246,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.046,
    corporateCorporate: 0.602,
    corporateDepositaryLoansExMortgage: 0.112,
    corporateMortgages: 0.067,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.197
  },
  "2017-03-01": {
    households: 0.308,
    corporateOrSelf: 0.29,
    government: 0.337,
    municipalities: 0.066,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.247,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.044,
    corporateCorporate: 0.605,
    corporateDepositaryLoansExMortgage: 0.108,
    corporateMortgages: 0.063,
    corporateCommercialPaper: 0.021,
    CorporateOther: 0.203
  },
  "2017-06-01": {
    households: 0.307,
    corporateOrSelf: 0.291,
    government: 0.337,
    municipalities: 0.065,
    corporateOrSelfCorporate: 0.621,
    corporateOrSelfSelf: 0.379,
    selfemployedNotes: 0.248,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.044,
    corporateCorporate: 0.605,
    corporateDepositaryLoansExMortgage: 0.105,
    corporateMortgages: 0.061,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.207
  },
  "2017-09-01": {
    households: 0.306,
    corporateOrSelf: 0.292,
    government: 0.338,
    municipalities: 0.064,
    corporateOrSelfCorporate: 0.618,
    corporateOrSelfSelf: 0.382,
    selfemployedNotes: 0.249,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.043,
    corporateCorporate: 0.607,
    corporateDepositaryLoansExMortgage: 0.103,
    corporateMortgages: 0.058,
    corporateCommercialPaper: 0.024,
    CorporateOther: 0.208
  },
  "2017-12-01": {
    households: 0.306,
    corporateOrSelf: 0.294,
    government: 0.336,
    municipalities: 0.064,
    corporateOrSelfCorporate: 0.617,
    corporateOrSelfSelf: 0.383,
    selfemployedNotes: 0.249,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.043,
    corporateCorporate: 0.601,
    corporateDepositaryLoansExMortgage: 0.1,
    corporateMortgages: 0.06,
    corporateCommercialPaper: 0.023,
    CorporateOther: 0.216
  },
  "2018-03-01": {
    households: 0.303,
    corporateOrSelf: 0.292,
    government: 0.342,
    municipalities: 0.062,
    corporateOrSelfCorporate: 0.617,
    corporateOrSelfSelf: 0.383,
    selfemployedNotes: 0.249,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.042,
    corporateCorporate: 0.602,
    corporateDepositaryLoansExMortgage: 0.099,
    corporateMortgages: 0.061,
    corporateCommercialPaper: 0.025,
    CorporateOther: 0.212
  },
  "2018-06-01": {
    households: 0.301,
    corporateOrSelf: 0.296,
    government: 0.342,
    municipalities: 0.061,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.25,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.042,
    corporateCorporate: 0.578,
    corporateDepositaryLoansExMortgage: 0.098,
    corporateMortgages: 0.063,
    corporateCommercialPaper: 0.025,
    CorporateOther: 0.236
  },
  "2018-09-01": {
    households: 0.3,
    corporateOrSelf: 0.297,
    government: 0.342,
    municipalities: 0.061,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.25,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.042,
    corporateCorporate: 0.578,
    corporateDepositaryLoansExMortgage: 0.097,
    corporateMortgages: 0.063,
    corporateCommercialPaper: 0.024,
    CorporateOther: 0.238
  },
  "2018-12-01": {
    households: 0.299,
    corporateOrSelf: 0.298,
    government: 0.343,
    municipalities: 0.06,
    corporateOrSelfCorporate: 0.622,
    corporateOrSelfSelf: 0.378,
    selfemployedNotes: 0.25,
    selfemployedMortgages: 0.708,
    selfemployedOther: 0.042,
    corporateCorporate: 0.575,
    corporateDepositaryLoansExMortgage: 0.104,
    corporateMortgages: 0.065,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.236
  },
  "2019-03-01": {
    households: 0.297,
    corporateOrSelf: 0.299,
    government: 0.346,
    municipalities: 0.059,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.249,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.042,
    corporateCorporate: 0.573,
    corporateDepositaryLoansExMortgage: 0.104,
    corporateMortgages: 0.065,
    corporateCommercialPaper: 0.022,
    CorporateOther: 0.236
  },
  "2019-06-01": {
    households: 0.297,
    corporateOrSelf: 0.299,
    government: 0.346,
    municipalities: 0.058,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.248,
    selfemployedMortgages: 0.71,
    selfemployedOther: 0.042,
    corporateCorporate: 0.574,
    corporateDepositaryLoansExMortgage: 0.104,
    corporateMortgages: 0.066,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.236
  },
  "2019-09-01": {
    households: 0.295,
    corporateOrSelf: 0.299,
    government: 0.348,
    municipalities: 0.057,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.244,
    selfemployedMortgages: 0.715,
    selfemployedOther: 0.041,
    corporateCorporate: 0.574,
    corporateDepositaryLoansExMortgage: 0.103,
    corporateMortgages: 0.067,
    corporateCommercialPaper: 0.02,
    CorporateOther: 0.236
  },
  "2019-12-01": {
    households: 0.295,
    corporateOrSelf: 0.299,
    government: 0.349,
    municipalities: 0.057,
    corporateOrSelfCorporate: 0.623,
    corporateOrSelfSelf: 0.377,
    selfemployedNotes: 0.241,
    selfemployedMortgages: 0.717,
    selfemployedOther: 0.042,
    corporateCorporate: 0.573,
    corporateDepositaryLoansExMortgage: 0.102,
    corporateMortgages: 0.067,
    corporateCommercialPaper: 0.019,
    CorporateOther: 0.239
  },
  "2020-03-01": {
    households: 0.289,
    corporateOrSelf: 0.304,
    government: 0.351,
    municipalities: 0.056,
    corporateOrSelfCorporate: 0.631,
    corporateOrSelfSelf: 0.369,
    selfemployedNotes: 0.25,
    selfemployedMortgages: 0.709,
    selfemployedOther: 0.041,
    corporateCorporate: 0.558,
    corporateDepositaryLoansExMortgage: 0.127,
    corporateMortgages: 0.064,
    corporateCommercialPaper: 0.018,
    CorporateOther: 0.233
  },
  "2020-06-01": {
    households: 0.271,
    corporateOrSelf: 0.296,
    government: 0.381,
    municipalities: 0.053,
    corporateOrSelfCorporate: 0.631,
    corporateOrSelfSelf: 0.369,
    selfemployedNotes: 0.262,
    selfemployedMortgages: 0.692,
    selfemployedOther: 0.046,
    corporateCorporate: 0.576,
    corporateDepositaryLoansExMortgage: 0.122,
    corporateMortgages: 0.063,
    corporateCommercialPaper: 0.011,
    CorporateOther: 0.228
  },
  "2020-09-01": {
    households: 0.272,
    corporateOrSelf: 0.293,
    government: 0.382,
    municipalities: 0.053,
    corporateOrSelfCorporate: 0.626,
    corporateOrSelfSelf: 0.374,
    selfemployedNotes: 0.259,
    selfemployedMortgages: 0.691,
    selfemployedOther: 0.05,
    corporateCorporate: 0.586,
    corporateDepositaryLoansExMortgage: 0.106,
    corporateMortgages: 0.064,
    corporateCommercialPaper: 0.009,
    CorporateOther: 0.234
  },
  "2020-12-01": {
    households: 0.273,
    corporateOrSelf: 0.289,
    government: 0.386,
    municipalities: 0.052,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.249,
    selfemployedMortgages: 0.698,
    selfemployedOther: 0.053,
    corporateCorporate: 0.586,
    corporateDepositaryLoansExMortgage: 0.1,
    corporateMortgages: 0.065,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.236
  },
  "2021-03-01": {
    households: 0.273,
    corporateOrSelf: 0.288,
    government: 0.388,
    municipalities: 0.052,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.246,
    selfemployedMortgages: 0.7,
    selfemployedOther: 0.054,
    corporateCorporate: 0.589,
    corporateDepositaryLoansExMortgage: 0.1,
    corporateMortgages: 0.066,
    corporateCommercialPaper: 0.007,
    CorporateOther: 0.238
  },
  "2021-06-01": {
    households: 0.273,
    corporateOrSelf: 0.284,
    government: 0.391,
    municipalities: 0.052,
    corporateOrSelfCorporate: 0.625,
    corporateOrSelfSelf: 0.375,
    selfemployedNotes: 0.238,
    selfemployedMortgages: 0.704,
    selfemployedOther: 0.057,
    corporateCorporate: 0.588,
    corporateDepositaryLoansExMortgage: 0.092,
    corporateMortgages: 0.069,
    corporateCommercialPaper: 0.01,
    CorporateOther: 0.241
  },
  "2021-09-01": {
    households: 0.276,
    corporateOrSelf: 0.286,
    government: 0.387,
    municipalities: 0.051,
    corporateOrSelfCorporate: 0.628,
    corporateOrSelfSelf: 0.372,
    selfemployedNotes: 0.228,
    selfemployedMortgages: 0.712,
    selfemployedOther: 0.059,
    corporateCorporate: 0.584,
    corporateDepositaryLoansExMortgage: 0.088,
    corporateMortgages: 0.071,
    corporateCommercialPaper: 0.011,
    CorporateOther: 0.247
  },
  "2021-12-01": {
    households: 0.276,
    corporateOrSelf: 0.285,
    government: 0.389,
    municipalities: 0.05,
    corporateOrSelfCorporate: 0.628,
    corporateOrSelfSelf: 0.372,
    selfemployedNotes: 0.228,
    selfemployedMortgages: 0.713,
    selfemployedOther: 0.059,
    corporateCorporate: 0.571,
    corporateDepositaryLoansExMortgage: 0.092,
    corporateMortgages: 0.074,
    corporateCommercialPaper: 0.012,
    CorporateOther: 0.252
  }
};

export const householdbalance = {
  "1945-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 12189,
    householddebt: 0,
    householdliabilitiesother: 3330,
    householdlending: 12883,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 197385,
    hngoods: 53996,
    householdgoods: 170045,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 61172,
    householdestate: 116.049,
    hncredit: 6.8,
    life: 0.638,
    hnmortgate: 18.706,
    hnlifeinsurance: 39.56,
    hnmiscellaneous: 6.251,
    hncash: 0.694,
    hnassets: 837.197,
    hnliabilities: 30.584,
    hnnet: 806.613
  },
  "1946-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1946-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1946-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1946-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 13711,
    householddebt: 0,
    householdliabilitiesother: 2602,
    householdlending: 14444,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 234182,
    hngoods: 61756,
    householdgoods: 195178,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 69381,
    householdestate: 133.422,
    hncredit: 9.777,
    life: 0.703,
    hnmortgate: 23.1,
    hnlifeinsurance: 43.36,
    hnmiscellaneous: 7.054,
    hncash: 0.733,
    hnassets: 922.654,
    hnliabilities: 37.441,
    hnnet: 885.213
  },
  "1947-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1947-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1947-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1947-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 15070,
    householddebt: 0,
    householdliabilitiesother: 2686,
    householdlending: 15730,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 267381,
    hngoods: 73350,
    householdgoods: 250823,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 77939,
    householdestate: 177.473,
    hncredit: 13.298,
    life: 0.784,
    hnmortgate: 28.267,
    hnlifeinsurance: 46.51,
    hnmiscellaneous: 7.592,
    hncash: 0.66,
    hnassets: 1030.66,
    hnliabilities: 46.468,
    hnnet: 984.192
  },
  "1948-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1948-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1948-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1948-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 16268,
    householddebt: 0,
    householdliabilitiesother: 2787,
    householdlending: 16922,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 283080,
    hngoods: 83063,
    householdgoods: 282389,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 86476,
    householdestate: 199.326,
    hncredit: 16.332,
    life: 0.835,
    hnmortgate: 33.422,
    hnlifeinsurance: 49.353,
    hnmiscellaneous: 7.987,
    hncash: 0.654,
    hnassets: 1092.118,
    hnliabilities: 55.109,
    hnnet: 1037.009
  },
  "1949-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1949-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1949-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1949-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 16993,
    householddebt: 0,
    householdliabilitiesother: 3350,
    householdlending: 17695,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 284819,
    hngoods: 91766,
    householdgoods: 308301,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 95413,
    householdestate: 216.535,
    hncredit: 19.374,
    life: 0.904,
    hnmortgate: 37.406,
    hnlifeinsurance: 52.104,
    hnmiscellaneous: 8.357,
    hncash: 0.702,
    hnassets: 1142.467,
    hnliabilities: 63.061,
    hnnet: 1079.406
  },
  "1950-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1950-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1950-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1950-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 17689,
    householddebt: 0,
    householdliabilitiesother: 4063,
    householdlending: 18660,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 301720,
    hngoods: 107528,
    householdgoods: 350843,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 104563,
    householdestate: 243.315,
    hncredit: 23.947,
    life: 1.007,
    hnmortgate: 45.25,
    hnlifeinsurance: 55.049,
    hnmiscellaneous: 8.692,
    hncash: 0.971,
    hnassets: 1248.299,
    hnliabilities: 76.803,
    hnnet: 1171.496
  },
  "1951-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1951-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1951-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 0,
    householddebt: 0,
    householdliabilitiesother: 0,
    householdlending: 0,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 0,
    hngoods: 0,
    householdgoods: 0,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 0,
    householdestate: 0,
    hncredit: 0,
    life: 0,
    hnmortgate: 0,
    hnlifeinsurance: 0,
    hnmiscellaneous: 0,
    hncash: 0,
    hnassets: 0,
    hnliabilities: 0,
    hnnet: 0
  },
  "1951-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 18623,
    householddebt: 0,
    householdliabilitiesother: 4186,
    householdlending: 19516,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 328513,
    hngoods: 120060,
    householdgoods: 390934,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 118836,
    householdestate: 270.874,
    hncredit: 25.35,
    life: 1.086,
    hnmortgate: 51.792,
    hnlifeinsurance: 57.777,
    hnmiscellaneous: 9.276,
    hncash: 0.893,
    hnassets: 1366.145,
    hnliabilities: 85.567,
    hnnet: 1280.578
  },
  "1952-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 18652,
    householddebt: 0,
    householdliabilitiesother: 4229,
    householdlending: 19400,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 331583,
    hngoods: 123241,
    householdgoods: 396411,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 122763,
    householdestate: 273.17,
    hncredit: 24.597,
    life: 1.105,
    hnmortgate: 53.066,
    hnlifeinsurance: 58.445,
    hnmiscellaneous: 9.456,
    hncash: 0.748,
    hnassets: 1384.719,
    hnliabilities: 86.224,
    hnnet: 1298.495
  },
  "1952-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 18913,
    householddebt: 0,
    householdliabilitiesother: 4300,
    householdlending: 19608,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 332500,
    hngoods: 124058,
    householdgoods: 407563,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 126900,
    householdestate: 283.505,
    hncredit: 26.878,
    life: 1.123,
    hnmortgate: 54.798,
    hnlifeinsurance: 59.153,
    hnmiscellaneous: 9.633,
    hncash: 0.695,
    hnassets: 1411.453,
    hnliabilities: 90.411,
    hnnet: 1321.042
  },
  "1952-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 19126,
    householddebt: 0,
    householdliabilitiesother: 4347,
    householdlending: 19803,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 332818,
    hngoods: 127233,
    householdgoods: 411817,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 131197,
    householdestate: 284.584,
    hncredit: 27.965,
    life: 1.142,
    hnmortgate: 56.64,
    hnlifeinsurance: 59.892,
    hnmiscellaneous: 9.811,
    hncash: 0.677,
    hnassets: 1427.479,
    hnliabilities: 93.441,
    hnnet: 1334.038
  },
  "1952-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 19289,
    householddebt: 0,
    householdliabilitiesother: 4404,
    householdlending: 20003,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 330657,
    hngoods: 126974,
    householdgoods: 421726,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 135627,
    householdestate: 294.752,
    hncredit: 30.522,
    life: 1.162,
    hnmortgate: 58.416,
    hnlifeinsurance: 60.665,
    hnmiscellaneous: 10.648,
    hncash: 0.714,
    hnassets: 1436.102,
    hnliabilities: 97.89,
    hnnet: 1338.212
  },
  "1953-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 19619,
    householddebt: 0,
    householdliabilitiesother: 4618,
    householdlending: 20356,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 329100,
    hngoods: 130376,
    householdgoods: 430558,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 139902,
    householdestate: 300.182,
    hncredit: 30.901,
    life: 1.184,
    hnmortgate: 60.006,
    hnlifeinsurance: 61.439,
    hnmiscellaneous: 10.829,
    hncash: 0.737,
    hnassets: 1448.807,
    hnliabilities: 100.207,
    hnnet: 1348.6
  },
  "1953-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 19886,
    householddebt: 0,
    householdliabilitiesother: 4864,
    householdlending: 20525,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 327734,
    hngoods: 132485,
    householdgoods: 439165,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 144293,
    householdestate: 306.68,
    hncredit: 32.672,
    life: 1.207,
    hnmortgate: 62.225,
    hnlifeinsurance: 62.199,
    hnmiscellaneous: 10.996,
    hncash: 0.639,
    hnassets: 1455.568,
    hnliabilities: 104.699,
    hnnet: 1350.869
  },
  "1953-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 20020,
    householddebt: 0,
    householdliabilitiesother: 4850,
    householdlending: 20683,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 328684,
    hngoods: 133383,
    householdgoods: 449731,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 148610,
    householdestate: 316.348,
    hncredit: 33.354,
    life: 1.231,
    hnmortgate: 64.205,
    hnlifeinsurance: 62.937,
    hnmiscellaneous: 11.146,
    hncash: 0.663,
    hnassets: 1472.718,
    hnliabilities: 107.229,
    hnnet: 1365.489
  },
  "1953-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 20285,
    householddebt: 0,
    householdliabilitiesother: 4996,
    householdlending: 20993,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 330217,
    hngoods: 135127,
    householdgoods: 450182,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 152853,
    householdestate: 315.055,
    hncredit: 34.614,
    life: 1.254,
    hnmortgate: 65.938,
    hnlifeinsurance: 63.647,
    hnmiscellaneous: 11.293,
    hncash: 0.708,
    hnassets: 1496.861,
    hnliabilities: 110.624,
    hnnet: 1386.237
  },
  "1954-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 20492,
    householddebt: 0,
    householdliabilitiesother: 5086,
    householdlending: 21278,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 330437,
    hngoods: 138371,
    householdgoods: 454963,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 157071,
    householdestate: 316.592,
    hncredit: 32.744,
    life: 1.275,
    hnmortgate: 67.351,
    hnlifeinsurance: 64.339,
    hnmiscellaneous: 11.408,
    hncash: 0.786,
    hnassets: 1519.48,
    hnliabilities: 110.496,
    hnnet: 1408.984
  },
  "1954-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 20817,
    householddebt: 0,
    householdliabilitiesother: 5311,
    householdlending: 21650,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 330343,
    hngoods: 137066,
    householdgoods: 457798,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 161350,
    householdestate: 320.732,
    hncredit: 34.006,
    life: 1.294,
    hnmortgate: 69.614,
    hnlifeinsurance: 65.018,
    hnmiscellaneous: 11.487,
    hncash: 0.833,
    hnassets: 1544.971,
    hnliabilities: 114.312,
    hnnet: 1430.659
  },
  "1954-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 21107,
    householddebt: 0,
    householdliabilitiesother: 5615,
    householdlending: 22026,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 331712,
    hngoods: 136936,
    householdgoods: 466717,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 165558,
    householdestate: 329.781,
    hncredit: 34.242,
    life: 1.315,
    hnmortgate: 72.336,
    hnlifeinsurance: 65.687,
    hnmiscellaneous: 11.573,
    hncash: 0.919,
    hnassets: 1584.67,
    hnliabilities: 117.763,
    hnnet: 1466.907
  },
  "1954-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 21423,
    householddebt: 0,
    householdliabilitiesother: 6047,
    householdlending: 22443,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 333828,
    hngoods: 138705,
    householdgoods: 476259,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 169935,
    householdestate: 337.554,
    hncredit: 35.963,
    life: 1.341,
    hnmortgate: 75.356,
    hnlifeinsurance: 66.349,
    hnmiscellaneous: 11.668,
    hncash: 1.02,
    hnassets: 1607.203,
    hnliabilities: 123.092,
    hnnet: 1484.111
  },
  "1955-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 21796,
    householddebt: 0,
    householdliabilitiesother: 6442,
    householdlending: 22819,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 335800,
    hngoods: 140758,
    householdgoods: 482359,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 174318,
    householdestate: 341.601,
    hncredit: 35.702,
    life: 1.371,
    hnmortgate: 78.237,
    hnlifeinsurance: 67.031,
    hnmiscellaneous: 11.81,
    hncash: 1.023,
    hnassets: 1632.076,
    hnliabilities: 126.315,
    hnnet: 1505.761
  },
  "1955-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 22089,
    householddebt: 0,
    householdliabilitiesother: 6552,
    householdlending: 23002,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 336409,
    hngoods: 143048,
    householdgoods: 493908,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 179028,
    householdestate: 350.86,
    hncredit: 38.755,
    life: 1.405,
    hnmortgate: 81.697,
    hnlifeinsurance: 67.74,
    hnmiscellaneous: 11.918,
    hncash: 0.913,
    hnassets: 1674.231,
    hnliabilities: 133.426,
    hnnet: 1540.805
  },
  "1955-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 22315,
    householddebt: 0,
    householdliabilitiesother: 6701,
    householdlending: 23300,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 339574,
    hngoods: 146277,
    householdgoods: 506965,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 183687,
    householdestate: 360.688,
    hncredit: 40.543,
    life: 1.442,
    hnmortgate: 85.032,
    hnlifeinsurance: 68.498,
    hnmiscellaneous: 12.009,
    hncash: 0.985,
    hnassets: 1718.907,
    hnliabilities: 138.787,
    hnnet: 1580.12
  },
  "1955-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 22725,
    householddebt: 0,
    householdliabilitiesother: 6713,
    householdlending: 23624,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 343802,
    hngoods: 148105,
    householdgoods: 515488,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 188255,
    householdestate: 367.383,
    hncredit: 42.949,
    life: 1.482,
    hnmortgate: 87.936,
    hnlifeinsurance: 69.254,
    hnmiscellaneous: 12.173,
    hncash: 0.899,
    hnassets: 1744.973,
    hnliabilities: 144.426,
    hnnet: 1600.547
  },
  "1956-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 23199,
    householddebt: 0,
    householdliabilitiesother: 6770,
    householdlending: 24171,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 349606,
    hngoods: 150201,
    householdgoods: 525529,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 192884,
    householdestate: 375.328,
    hncredit: 42.251,
    life: 1.525,
    hnmortgate: 90.356,
    hnlifeinsurance: 70.104,
    hnmiscellaneous: 12.247,
    hncash: 0.972,
    hnassets: 1789.489,
    hnliabilities: 146.185,
    hnnet: 1643.304
  },
  "1956-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 23674,
    householddebt: 0,
    householdliabilitiesother: 6856,
    householdlending: 24509,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 354355,
    hngoods: 151976,
    householdgoods: 535736,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 197560,
    householdestate: 383.76,
    hncredit: 44.281,
    life: 1.573,
    hnmortgate: 93.327,
    hnlifeinsurance: 71.013,
    hnmiscellaneous: 12.356,
    hncash: 0.835,
    hnassets: 1804.893,
    hnliabilities: 151.446,
    hnnet: 1653.447
  },
  "1956-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 23976,
    householddebt: 0,
    householdliabilitiesother: 6905,
    householdlending: 24843,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 357938,
    hngoods: 155496,
    householdgoods: 545714,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 202441,
    householdestate: 390.218,
    hncredit: 44.978,
    life: 1.622,
    hnmortgate: 96.174,
    hnlifeinsurance: 71.904,
    hnmiscellaneous: 12.433,
    hncash: 0.867,
    hnassets: 1826.896,
    hnliabilities: 154.833,
    hnnet: 1672.063
  },
  "1956-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 24327,
    householddebt: 0,
    householdliabilitiesother: 7034,
    householdlending: 25209,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 360825,
    hngoods: 159090,
    householdgoods: 553353,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 207775,
    householdestate: 394.263,
    hncredit: 46.58,
    life: 1.669,
    hnmortgate: 98.745,
    hnlifeinsurance: 72.724,
    hnmiscellaneous: 12.548,
    hncash: 0.882,
    hnassets: 1870.105,
    hnliabilities: 159.6,
    hnnet: 1710.505
  },
  "1957-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 24871,
    householddebt: 0,
    householdliabilitiesother: 7006,
    householdlending: 25686,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 364546,
    hngoods: 162126,
    householdgoods: 563348,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 215119,
    householdestate: 401.222,
    hncredit: 45.242,
    life: 1.714,
    hnmortgate: 100.851,
    hnlifeinsurance: 73.468,
    hnmiscellaneous: 12.672,
    hncash: 0.815,
    hnassets: 1884.197,
    hnliabilities: 160.761,
    hnnet: 1723.436
  },
  "1957-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 25333,
    householddebt: 0,
    householdliabilitiesother: 7317,
    householdlending: 26147,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 367266,
    hngoods: 165861,
    householdgoods: 573878,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 223051,
    householdestate: 408.017,
    hncredit: 47.223,
    life: 1.76,
    hnmortgate: 103.039,
    hnlifeinsurance: 74.135,
    hnmiscellaneous: 12.753,
    hncash: 0.814,
    hnassets: 1926.432,
    hnliabilities: 165.828,
    hnnet: 1760.604
  },
  "1957-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 25843,
    householddebt: 0,
    householdliabilitiesother: 7327,
    householdlending: 26676,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 371022,
    hngoods: 167082,
    householdgoods: 581752,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 230768,
    householdestate: 414.67,
    hncredit: 47.971,
    life: 1.804,
    hnmortgate: 105.397,
    hnlifeinsurance: 74.784,
    hnmiscellaneous: 12.828,
    hncash: 0.833,
    hnassets: 1928.042,
    hnliabilities: 168.971,
    hnnet: 1759.071
  },
  "1957-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 26283,
    householddebt: 0,
    householdliabilitiesother: 7160,
    householdlending: 27177,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 373178,
    hngoods: 168481,
    householdgoods: 585643,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 238362,
    householdestate: 417.162,
    hncredit: 49.217,
    life: 1.843,
    hnmortgate: 107.374,
    hnlifeinsurance: 75.462,
    hnmiscellaneous: 12.911,
    hncash: 0.894,
    hnassets: 1947.933,
    hnliabilities: 172.076,
    hnnet: 1775.857
  },
  "1958-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 26750,
    householddebt: 0,
    householdliabilitiesother: 7550,
    householdlending: 27701,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 379184,
    hngoods: 172242,
    householdgoods: 586707,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 244139,
    householdestate: 414.465,
    hncredit: 46.775,
    life: 1.877,
    hnmortgate: 108.975,
    hnlifeinsurance: 76.183,
    hnmiscellaneous: 13.027,
    hncash: 0.951,
    hnassets: 1980.46,
    hnliabilities: 171.212,
    hnnet: 1809.248
  },
  "1958-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 27539,
    householddebt: 0,
    householdliabilitiesother: 8111,
    householdlending: 28584,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 382510,
    hngoods: 171947,
    householdgoods: 591788,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 249929,
    householdestate: 419.841,
    hncredit: 47.704,
    life: 1.903,
    hnmortgate: 111.142,
    hnlifeinsurance: 76.958,
    hnmiscellaneous: 13.045,
    hncash: 1.045,
    hnassets: 2011.214,
    hnliabilities: 174.783,
    hnnet: 1836.431
  },
  "1958-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 28168,
    householddebt: 0,
    householdliabilitiesother: 8280,
    householdlending: 29284,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 385537,
    hngoods: 172354,
    householdgoods: 601681,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 255861,
    householdestate: 429.327,
    hncredit: 47.94,
    life: 1.931,
    hnmortgate: 114.035,
    hnlifeinsurance: 77.752,
    hnmiscellaneous: 13.164,
    hncash: 1.116,
    hnassets: 2068.893,
    hnliabilities: 178.359,
    hnnet: 1890.534
  },
  "1958-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 28887,
    householddebt: 0,
    householdliabilitiesother: 8591,
    householdlending: 30043,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 387265,
    hngoods: 172608,
    householdgoods: 611048,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 261909,
    householdestate: 438.44,
    hncredit: 49.49,
    life: 1.969,
    hnmortgate: 117.177,
    hnlifeinsurance: 78.535,
    hnmiscellaneous: 13.242,
    hncash: 1.156,
    hnassets: 2104.93,
    hnliabilities: 184.149,
    hnnet: 1920.781
  },
  "1959-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 29330,
    householddebt: 0,
    householdliabilitiesother: 8742,
    householdlending: 30602,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 387979,
    hngoods: 176561,
    householdgoods: 614613,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 266743,
    householdestate: 438.052,
    hncredit: 48.991,
    life: 2.017,
    hnmortgate: 119.935,
    hnlifeinsurance: 79.331,
    hnmiscellaneous: 13.379,
    hncash: 1.272,
    hnassets: 2127.741,
    hnliabilities: 186.644,
    hnnet: 1941.097
  },
  "1959-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 29939,
    householddebt: 0,
    householdliabilitiesother: 8985,
    householdlending: 31057,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 387553,
    hngoods: 178396,
    householdgoods: 626068,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 272118,
    householdestate: 447.672,
    hncredit: 51.878,
    life: 2.074,
    hnmortgate: 123.346,
    hnlifeinsurance: 80.133,
    hnmiscellaneous: 13.513,
    hncash: 1.118,
    hnassets: 2162.509,
    hnliabilities: 193.679,
    hnnet: 1968.83
  },
  "1959-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 30314,
    householddebt: 0,
    householdliabilitiesother: 8984,
    householdlending: 31345,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 387255,
    hngoods: 179627,
    householdgoods: 635989,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 277575,
    householdestate: 456.362,
    hncredit: 53.918,
    life: 2.133,
    hnmortgate: 127.007,
    hnlifeinsurance: 80.994,
    hnmiscellaneous: 13.661,
    hncash: 1.031,
    hnassets: 2181.977,
    hnliabilities: 199.894,
    hnnet: 1982.083
  },
  "1959-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 30801,
    householddebt: 0,
    householdliabilitiesother: 9206,
    householdlending: 30443,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 388616,
    hngoods: 180221,
    householdgoods: 643868,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 282854,
    householdestate: 463.647,
    hncredit: 57.213,
    life: 2.187,
    hnmortgate: 130.099,
    hnlifeinsurance: 81.956,
    hnmiscellaneous: 13.801,
    hncash: -0.358,
    hnassets: 2222.459,
    hnliabilities: 206.702,
    hnnet: 2015.757
  },
  "1960-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 31342,
    householddebt: 0,
    householdliabilitiesother: 9090,
    householdlending: 30621,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 391557,
    hngoods: 181679,
    householdgoods: 657259,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 288952,
    householdestate: 475.58,
    hncredit: 55.757,
    life: 2.238,
    hnmortgate: 132.58,
    hnlifeinsurance: 82.895,
    hnmiscellaneous: 13.927,
    hncash: -0.721,
    hnassets: 2231.778,
    hnliabilities: 207.626,
    hnnet: 2024.152
  },
  "1960-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 32110,
    householddebt: 0,
    householdliabilitiesother: 9401,
    householdlending: 31213,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 392612,
    hngoods: 183333,
    householdgoods: 662971,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 295534,
    householdestate: 479.638,
    hncredit: 58.622,
    life: 2.288,
    hnmortgate: 135.366,
    hnlifeinsurance: 83.772,
    hnmiscellaneous: 14.049,
    hncash: -0.897,
    hnassets: 2256.656,
    hnliabilities: 214.485,
    hnnet: 2042.171
  },
  "1960-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 32820,
    householddebt: 0,
    householdliabilitiesother: 9679,
    householdlending: 32280,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 395557,
    hngoods: 184433,
    householdgoods: 670087,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 301375,
    householdestate: 485.654,
    hncredit: 59.59,
    life: 2.336,
    hnmortgate: 138.582,
    hnlifeinsurance: 84.539,
    hnmiscellaneous: 14.183,
    hncash: -0.54,
    hnassets: 2256.628,
    hnliabilities: 219.067,
    hnnet: 2037.561
  },
  "1960-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 33575,
    householddebt: 0,
    householdliabilitiesother: 9901,
    householdlending: 33623,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 398552,
    hngoods: 185421,
    householdgoods: 672318,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 307674,
    householdestate: 486.897,
    hncredit: 61.248,
    life: 2.379,
    hnmortgate: 141.378,
    hnlifeinsurance: 85.184,
    hnmiscellaneous: 14.332,
    hncash: 0.048,
    hnassets: 2315.344,
    hnliabilities: 224.55,
    hnnet: 2090.794
  },
  "1961-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 34425,
    householddebt: 0,
    householdliabilitiesother: 10472,
    householdlending: 33818,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 400869,
    hngoods: 185416,
    householdgoods: 669904,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 313859,
    householdestate: 484.488,
    hncredit: 59.339,
    life: 2.417,
    hnmortgate: 143.645,
    hnlifeinsurance: 85.872,
    hnmiscellaneous: 14.481,
    hncash: -0.607,
    hnassets: 2366.555,
    hnliabilities: 225.311,
    hnnet: 2141.244
  },
  "1961-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 35253,
    householddebt: 0,
    householdliabilitiesother: 11139,
    householdlending: 35336,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 404523,
    hngoods: 186497,
    householdgoods: 680633,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 320202,
    householdestate: 494.136,
    hncredit: 60.597,
    life: 2.448,
    hnmortgate: 146.603,
    hnlifeinsurance: 86.665,
    hnmiscellaneous: 14.643,
    hncash: 0.083,
    hnassets: 2395.455,
    hnliabilities: 231.512,
    hnnet: 2163.943
  },
  "1961-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 36010,
    householddebt: 0,
    householdliabilitiesother: 11279,
    householdlending: 35408,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 409730,
    hngoods: 187779,
    householdgoods: 690758,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 326229,
    householdestate: 502.979,
    hncredit: 61.082,
    life: 2.478,
    hnmortgate: 150.179,
    hnlifeinsurance: 87.588,
    hnmiscellaneous: 14.803,
    hncash: -0.602,
    hnassets: 2436.182,
    hnliabilities: 235.787,
    hnnet: 2200.395
  },
  "1961-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 36834,
    householddebt: 0,
    householdliabilitiesother: 11700,
    householdlending: 36094,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 415779,
    hngoods: 188388,
    householdgoods: 699442,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 332393,
    householdestate: 511.054,
    hncredit: 63.435,
    life: 2.512,
    hnmortgate: 154.027,
    hnlifeinsurance: 88.631,
    hnmiscellaneous: 14.966,
    hncash: -0.74,
    hnassets: 2499.235,
    hnliabilities: 242.913,
    hnnet: 2256.322
  },
  "1962-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 37377,
    householddebt: 0,
    householdliabilitiesother: 11525,
    householdlending: 36277,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 420714,
    hngoods: 189692,
    householdgoods: 707825,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 335857,
    householdestate: 518.133,
    hncredit: 62.038,
    life: 2.549,
    hnmortgate: 156.488,
    hnlifeinsurance: 89.663,
    hnmiscellaneous: 15.102,
    hncash: -1.1,
    hnassets: 2513.53,
    hnliabilities: 244.24,
    hnnet: 2269.29
  },
  "1962-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 37975,
    householddebt: 0,
    householdliabilitiesother: 11362,
    householdlending: 37525,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 423947,
    hngoods: 191450,
    householdgoods: 713562,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 338921,
    householdestate: 522.112,
    hncredit: 65.435,
    life: 2.587,
    hnmortgate: 160.237,
    hnlifeinsurance: 90.651,
    hnmiscellaneous: 15.245,
    hncash: -0.45,
    hnassets: 2425.84,
    hnliabilities: 251.339,
    hnnet: 2174.501
  },
  "1962-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 38406,
    householddebt: 0,
    householdliabilitiesother: 11883,
    householdlending: 38068,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 428809,
    hngoods: 193610,
    householdgoods: 719484,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 342790,
    householdestate: 525.874,
    hncredit: 66.626,
    life: 2.626,
    hnmortgate: 164.345,
    hnlifeinsurance: 91.563,
    hnmiscellaneous: 15.388,
    hncash: -0.338,
    hnassets: 2471.507,
    hnliabilities: 257.554,
    hnnet: 2213.953
  },
  "1962-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 39006,
    householddebt: 0,
    householdliabilitiesother: 12308,
    householdlending: 38142,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 432848,
    hngoods: 194719,
    householdgoods: 727881,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 347129,
    householdestate: 533.162,
    hncredit: 69.344,
    life: 2.667,
    hnmortgate: 168.307,
    hnlifeinsurance: 92.398,
    hnmiscellaneous: 15.545,
    hncash: -0.864,
    hnassets: 2584.007,
    hnliabilities: 265.049,
    hnnet: 2318.958
  },
  "1963-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 39324,
    householddebt: 0,
    householdliabilitiesother: 12575,
    householdlending: 37860,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 434808,
    hngoods: 196663,
    householdgoods: 736288,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 352974,
    householdestate: 539.625,
    hncredit: 68.935,
    life: 2.711,
    hnmortgate: 171.352,
    hnlifeinsurance: 93.355,
    hnmiscellaneous: 15.7,
    hncash: -1.464,
    hnassets: 2621.257,
    hnliabilities: 267.959,
    hnnet: 2353.298
  },
  "1963-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 40093,
    householddebt: 0,
    householdliabilitiesother: 13531,
    householdlending: 39185,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 434919,
    hngoods: 199334,
    householdgoods: 739338,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 359556,
    householdestate: 540.004,
    hncredit: 72.217,
    life: 2.758,
    hnmortgate: 175.801,
    hnlifeinsurance: 94.378,
    hnmiscellaneous: 15.862,
    hncash: -0.908,
    hnassets: 2662.962,
    hnliabilities: 277.476,
    hnnet: 2385.486
  },
  "1963-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 40319,
    householddebt: 0,
    householdliabilitiesother: 14161,
    householdlending: 39325,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 434892,
    hngoods: 202006,
    householdgoods: 751328,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 365855,
    householdestate: 549.322,
    hncredit: 74.362,
    life: 2.806,
    hnmortgate: 180.662,
    hnlifeinsurance: 95.473,
    hnmiscellaneous: 16.018,
    hncash: -0.994,
    hnassets: 2712.366,
    hnliabilities: 284.993,
    hnnet: 2427.373
  },
  "1963-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 40528,
    householddebt: 0,
    householdliabilitiesother: 14502,
    householdlending: 39382,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 438463,
    hngoods: 204841,
    householdgoods: 758089,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 372399,
    householdestate: 553.248,
    hncredit: 77.87,
    life: 2.853,
    hnmortgate: 185.106,
    hnlifeinsurance: 96.635,
    hnmiscellaneous: 16.178,
    hncash: -1.146,
    hnassets: 2716.308,
    hnliabilities: 294.004,
    hnnet: 2422.304
  },
  "1964-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 40880,
    householddebt: 0,
    householdliabilitiesother: 14538,
    householdlending: 40727,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 439360,
    hngoods: 207779,
    householdgoods: 761623,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 379389,
    householdestate: 553.844,
    hncredit: 78.041,
    life: 2.898,
    hnmortgate: 188.776,
    hnlifeinsurance: 97.719,
    hnmiscellaneous: 16.389,
    hncash: -0.153,
    hnassets: 2766.801,
    hnliabilities: 297.969,
    hnnet: 2468.832
  },
  "1964-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 41158,
    householddebt: 0,
    householdliabilitiesother: 14718,
    householdlending: 39781,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 444667,
    hngoods: 210473,
    householdgoods: 775518,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 386971,
    householdestate: 565.045,
    hncredit: 81.812,
    life: 2.941,
    hnmortgate: 193.368,
    hnlifeinsurance: 98.806,
    hnmiscellaneous: 16.611,
    hncash: -1.377,
    hnassets: 2820.612,
    hnliabilities: 307.566,
    hnnet: 2513.046
  },
  "1964-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 41613,
    householddebt: 0,
    householdliabilitiesother: 14749,
    householdlending: 41040,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 449036,
    hngoods: 213350,
    householdgoods: 783014,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 394349,
    householdestate: 569.664,
    hncredit: 84.142,
    life: 2.986,
    hnmortgate: 198.076,
    hnlifeinsurance: 99.913,
    hnmiscellaneous: 16.863,
    hncash: -0.573,
    hnassets: 2873.88,
    hnliabilities: 314.378,
    hnnet: 2559.502
  },
  "1964-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 42098,
    householddebt: 0,
    householdliabilitiesother: 14765,
    householdlending: 41275,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 458148,
    hngoods: 215400,
    householdgoods: 795230,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 401876,
    householdestate: 579.83,
    hncredit: 87.352,
    life: 3.039,
    hnmortgate: 202.335,
    hnlifeinsurance: 101.051,
    hnmiscellaneous: 17.163,
    hncash: -0.823,
    hnassets: 2927.135,
    hnliabilities: 323.369,
    hnnet: 2603.766
  },
  "1965-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 42175,
    householddebt: 0,
    householdliabilitiesother: 14930,
    householdlending: 41419,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 463179,
    hngoods: 219247,
    householdgoods: 804682,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 409725,
    householdestate: 585.435,
    hncredit: 87.645,
    life: 3.097,
    hnmortgate: 205.891,
    hnlifeinsurance: 102.149,
    hnmiscellaneous: 17.481,
    hncash: -0.756,
    hnassets: 2974.347,
    hnliabilities: 326.864,
    hnnet: 2647.483
  },
  "1965-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 42480,
    householddebt: 0,
    householdliabilitiesother: 15156,
    householdlending: 41802,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 468063,
    hngoods: 222137,
    householdgoods: 811279,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 418006,
    householdestate: 589.142,
    hncredit: 92.243,
    life: 3.16,
    hnmortgate: 210.329,
    hnlifeinsurance: 103.382,
    hnmiscellaneous: 17.824,
    hncash: -0.678,
    hnassets: 2980.888,
    hnliabilities: 336.469,
    hnnet: 2644.419
  },
  "1965-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 42391,
    householddebt: 0,
    householdliabilitiesother: 15215,
    householdlending: 41843,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 471930,
    hngoods: 224873,
    householdgoods: 818416,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 426926,
    householdestate: 593.543,
    hncredit: 94.391,
    life: 3.228,
    hnmortgate: 215.042,
    hnlifeinsurance: 104.656,
    hnmiscellaneous: 18.184,
    hncash: -0.548,
    hnassets: 3065.63,
    hnliabilities: 343.385,
    hnnet: 2722.245
  },
  "1965-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 42611,
    householddebt: 0,
    householdliabilitiesother: 15956,
    householdlending: 42301,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 485038,
    hngoods: 227877,
    householdgoods: 833470,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 435926,
    householdestate: 605.593,
    hncredit: 97.489,
    life: 3.303,
    hnmortgate: 219.449,
    hnlifeinsurance: 105.876,
    hnmiscellaneous: 18.54,
    hncash: -0.31,
    hnassets: 3155.441,
    hnliabilities: 352.786,
    hnnet: 2802.655
  },
  "1966-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 42600,
    householddebt: 0,
    householdliabilitiesother: 16409,
    householdlending: 41956,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 487739,
    hngoods: 232807,
    householdgoods: 836934,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 445277,
    householdestate: 604.127,
    hncredit: 97.096,
    life: 3.385,
    hnmortgate: 223.358,
    hnlifeinsurance: 107.049,
    hnmiscellaneous: 18.897,
    hncash: -0.644,
    hnassets: 3165.224,
    hnliabilities: 355.964,
    hnnet: 2809.26
  },
  "1966-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 43427,
    householddebt: 0,
    householdliabilitiesother: 17016,
    householdlending: 43122,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 501720,
    hngoods: 238181,
    householdgoods: 868699,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 455364,
    householdestate: 630.518,
    hncredit: 100.205,
    life: 3.475,
    hnmortgate: 227.232,
    hnlifeinsurance: 108.19,
    hnmiscellaneous: 19.161,
    hncash: -0.305,
    hnassets: 3207.097,
    hnliabilities: 365.66,
    hnnet: 2841.437
  },
  "1966-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 43990,
    householddebt: 0,
    householdliabilitiesother: 17321,
    householdlending: 43937,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 507723,
    hngoods: 243936,
    householdgoods: 875368,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 464810,
    householdestate: 631.432,
    hncredit: 100.989,
    life: 3.568,
    hnmortgate: 230.272,
    hnlifeinsurance: 109.347,
    hnmiscellaneous: 19.495,
    hncash: -0.053,
    hnassets: 3182.656,
    hnliabilities: 369.551,
    hnnet: 2813.105
  },
  "1966-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 44617,
    householddebt: 0,
    householdliabilitiesother: 17891,
    householdlending: 44552,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 518534,
    hngoods: 249526,
    householdgoods: 898505,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 475695,
    householdestate: 648.979,
    hncredit: 103.422,
    life: 3.659,
    hnmortgate: 232.742,
    hnlifeinsurance: 110.568,
    hnmiscellaneous: 19.863,
    hncash: -0.065,
    hnassets: 3278.162,
    hnliabilities: 376.022,
    hnnet: 2902.14
  },
  "1967-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 44959,
    householddebt: 0,
    householdliabilitiesother: 18796,
    householdlending: 45721,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 522811,
    hngoods: 252772,
    householdgoods: 904481,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 486809,
    householdestate: 651.709,
    hncredit: 101.348,
    life: 3.736,
    hnmortgate: 234.221,
    hnlifeinsurance: 111.833,
    hnmiscellaneous: 20.434,
    hncash: 0.762,
    hnassets: 3384.083,
    hnliabilities: 375.812,
    hnnet: 3008.271
  },
  "1967-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 45344,
    householddebt: 0,
    householdliabilitiesother: 19647,
    householdlending: 46643,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 528319,
    hngoods: 258256,
    householdgoods: 917498,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 497998,
    householdestate: 659.242,
    hncredit: 104.131,
    life: 3.807,
    hnmortgate: 237.076,
    hnlifeinsurance: 113.07,
    hnmiscellaneous: 20.833,
    hncash: 1.299,
    hnassets: 3432.446,
    hnliabilities: 383.147,
    hnnet: 3049.299
  },
  "1967-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 45877,
    householddebt: 0,
    householdliabilitiesother: 20891,
    householdlending: 47453,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 533651,
    hngoods: 265245,
    householdgoods: 934116,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 509312,
    householdestate: 668.871,
    hncredit: 105.544,
    life: 3.876,
    hnmortgate: 241.547,
    hnlifeinsurance: 114.312,
    hnmiscellaneous: 21.217,
    hncash: 1.576,
    hnassets: 3525.142,
    hnliabilities: 389.209,
    hnnet: 3135.933
  },
  "1967-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 46607,
    householddebt: 0,
    householdliabilitiesother: 22426,
    householdlending: 48766,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 543197,
    hngoods: 271925,
    householdgoods: 957614,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 520252,
    householdestate: 685.689,
    hncredit: 108.567,
    life: 3.947,
    hnmortgate: 245.974,
    hnlifeinsurance: 115.533,
    hnmiscellaneous: 21.624,
    hncash: 2.159,
    hnassets: 3614.46,
    hnliabilities: 410.869,
    hnnet: 3203.591
  },
  "1968-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47312,
    householddebt: 0,
    householdliabilitiesother: 22257,
    householdlending: 48881,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 556272,
    hngoods: 278699,
    householdgoods: 984880,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 532409,
    householdestate: 706.181,
    hncredit: 107.272,
    life: 4.031,
    hnmortgate: 249.378,
    hnlifeinsurance: 116.735,
    hnmiscellaneous: 22.057,
    hncash: 1.569,
    hnassets: 3628.436,
    hnliabilities: 402.468,
    hnnet: 3225.968
  },
  "1968-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47757,
    householddebt: 0,
    householdliabilitiesother: 24930,
    householdlending: 51479,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 564493,
    hngoods: 285579,
    householdgoods: 1007270,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 547540,
    householdestate: 721.691,
    hncredit: 111.404,
    life: 4.121,
    hnmortgate: 253.675,
    hnlifeinsurance: 117.919,
    hnmiscellaneous: 22.525,
    hncash: 3.722,
    hnassets: 3779.036,
    hnliabilities: 415.182,
    hnnet: 3363.854
  },
  "1968-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 48735,
    householddebt: 0,
    householdliabilitiesother: 25654,
    householdlending: 53888,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 571546,
    hngoods: 293832,
    householdgoods: 1026406,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 561505,
    householdestate: 732.574,
    hncredit: 114.16,
    life: 4.213,
    hnmortgate: 258.183,
    hnlifeinsurance: 119.108,
    hnmiscellaneous: 23.009,
    hncash: 5.153,
    hnassets: 3848.646,
    hnliabilities: 422.781,
    hnnet: 3425.865
  },
  "1968-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 49082,
    householddebt: 0,
    householdliabilitiesother: 27320,
    householdlending: 53960,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 588293,
    hngoods: 302030,
    householdgoods: 1070194,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 576542,
    householdestate: 768.164,
    hncredit: 119.324,
    life: 4.302,
    hnmortgate: 262.934,
    hnlifeinsurance: 120.321,
    hnmiscellaneous: 23.517,
    hncash: 4.878,
    hnassets: 4031.534,
    hnliabilities: 436.144,
    hnnet: 3595.39
  },
  "1969-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47321,
    householddebt: 0,
    householdliabilitiesother: 26142,
    householdlending: 48804,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 599729,
    hngoods: 309289,
    householdgoods: 1098957,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 590724,
    householdestate: 789.668,
    hncredit: 118.884,
    life: 4.39,
    hnmortgate: 264.857,
    hnlifeinsurance: 121.558,
    hnmiscellaneous: 24.122,
    hncash: 1.483,
    hnassets: 4047.693,
    hnliabilities: 434.92,
    hnnet: 3612.773
  },
  "1969-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47038,
    householddebt: 0,
    householdliabilitiesother: 26400,
    householdlending: 50309,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 608676,
    hngoods: 316838,
    householdgoods: 1121033,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 606475,
    householdestate: 804.195,
    hncredit: 123.675,
    life: 4.478,
    hnmortgate: 269.738,
    hnlifeinsurance: 122.811,
    hnmiscellaneous: 24.664,
    hncash: 3.271,
    hnassets: 4066.523,
    hnliabilities: 446.332,
    hnnet: 3620.191
  },
  "1969-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47429,
    householddebt: 0,
    householdliabilitiesother: 25754,
    householdlending: 47642,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 612644,
    hngoods: 323236,
    householdgoods: 1136209,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 622258,
    householdestate: 812.973,
    hncredit: 125.813,
    life: 4.568,
    hnmortgate: 274.512,
    hnlifeinsurance: 124.08,
    hnmiscellaneous: 25.052,
    hncash: 0.213,
    hnassets: 4094.323,
    hnliabilities: 452.181,
    hnnet: 3642.142
  },
  "1969-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 49237,
    householddebt: 0,
    householdliabilitiesother: 26775,
    householdlending: 51888,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 623263,
    hngoods: 329836,
    householdgoods: 1162277,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 638886,
    householdestate: 832.441,
    hncredit: 129.231,
    life: 4.688,
    hnmortgate: 278.689,
    hnlifeinsurance: 125.359,
    hnmiscellaneous: 25.752,
    hncash: 2.651,
    hnassets: 4124.022,
    hnliabilities: 463.78,
    hnnet: 3660.242
  },
  "1970-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47109,
    householddebt: 0,
    householdliabilitiesother: 26458,
    householdlending: 48591,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 632629,
    hngoods: 334334,
    householdgoods: 1168649,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 659467,
    householdestate: 834.315,
    hncredit: 126.663,
    life: 4.792,
    hnmortgate: 275.814,
    hnlifeinsurance: 126.7,
    hnmiscellaneous: 26.393,
    hncash: 1.482,
    hnassets: 4144.211,
    hnliabilities: 456.241,
    hnnet: 3687.97
  },
  "1970-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47969,
    householddebt: 0,
    householdliabilitiesother: 26434,
    householdlending: 49323,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 650391,
    hngoods: 339663,
    householdgoods: 1200971,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 675310,
    householdestate: 861.308,
    hncredit: 129.421,
    life: 4.899,
    hnmortgate: 278.674,
    hnlifeinsurance: 127.975,
    hnmiscellaneous: 27.083,
    hncash: 1.354,
    hnassets: 4087.421,
    hnliabilities: 462.8,
    hnnet: 3624.621
  },
  "1970-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 49830,
    householddebt: 0,
    householdliabilitiesother: 27288,
    householdlending: 51592,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 645880,
    hngoods: 346138,
    householdgoods: 1205145,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 695984,
    householdestate: 859.007,
    hncredit: 131.424,
    life: 5.008,
    hnmortgate: 282.347,
    hnlifeinsurance: 129.291,
    hnmiscellaneous: 27.782,
    hncash: 1.762,
    hnassets: 4222.09,
    hnliabilities: 469.739,
    hnnet: 3752.351
  },
  "1970-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 50108,
    householddebt: 0,
    householdliabilitiesother: 28088,
    householdlending: 53672,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 654582,
    hngoods: 354452,
    householdgoods: 1228921,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 716279,
    householdestate: 874.469,
    hncredit: 133.66,
    life: 5.118,
    hnmortgate: 286.015,
    hnlifeinsurance: 130.699,
    hnmiscellaneous: 28.452,
    hncash: 3.564,
    hnassets: 4354.356,
    hnliabilities: 478.741,
    hnnet: 3875.615
  },
  "1971-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 49360,
    householddebt: 0,
    householdliabilitiesother: 28635,
    householdlending: 51838,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 674669,
    hngoods: 363713,
    householdgoods: 1260097,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 738457,
    householdestate: 896.384,
    hncredit: 134.072,
    life: 5.206,
    hnmortgate: 289.592,
    hnlifeinsurance: 132.198,
    hnmiscellaneous: 29.108,
    hncash: 2.478,
    hnassets: 4519.761,
    hnliabilities: 481.495,
    hnnet: 4038.266
  },
  "1971-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 49791,
    householddebt: 0,
    householdliabilitiesother: 29794,
    householdlending: 52070,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 689717,
    hngoods: 370738,
    householdgoods: 1288585,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 759971,
    householdestate: 917.847,
    hncredit: 138.912,
    life: 5.282,
    hnmortgate: 295.363,
    hnlifeinsurance: 133.782,
    hnmiscellaneous: 29.728,
    hncash: 2.279,
    hnassets: 4607.295,
    hnliabilities: 495.146,
    hnnet: 4112.149
  },
  "1971-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 48634,
    householddebt: 0,
    householdliabilitiesother: 30737,
    householdlending: 50141,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 705146,
    hngoods: 374553,
    householdgoods: 1313035,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 781999,
    householdestate: 938.482,
    hncredit: 143.465,
    life: 5.356,
    hnmortgate: 302.822,
    hnlifeinsurance: 135.412,
    hnmiscellaneous: 30.348,
    hncash: 1.507,
    hnassets: 4680.407,
    hnliabilities: 507.891,
    hnnet: 4172.516
  },
  "1971-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47341,
    householddebt: 0,
    householdliabilitiesother: 32037,
    householdlending: 50938,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 720885,
    hngoods: 377950,
    householdgoods: 1335185,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 805219,
    householdestate: 957.235,
    hncredit: 149.243,
    life: 5.436,
    hnmortgate: 309.454,
    hnlifeinsurance: 137.05,
    hnmiscellaneous: 30.995,
    hncash: 3.597,
    hnassets: 4818.244,
    hnliabilities: 524.086,
    hnnet: 4294.158
  },
  "1972-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47660,
    householddebt: 0,
    householdliabilitiesother: 34295,
    householdlending: 49756,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 742592,
    hngoods: 386967,
    householdgoods: 1381966,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 830991,
    householdestate: 994.999,
    hncredit: 148.847,
    life: 5.556,
    hnmortgate: 316.038,
    hnlifeinsurance: 138.719,
    hnmiscellaneous: 31.681,
    hncash: 2.096,
    hnassets: 4983.811,
    hnliabilities: 531.333,
    hnnet: 4452.478
  },
  "1972-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 48176,
    householddebt: 0,
    householdliabilitiesother: 35949,
    householdlending: 50502,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 756530,
    hngoods: 395008,
    householdgoods: 1406658,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 857232,
    householdestate: 1011.65,
    hncredit: 156.222,
    life: 5.701,
    hnmortgate: 324.462,
    hnlifeinsurance: 140.425,
    hnmiscellaneous: 32.384,
    hncash: 2.326,
    hnassets: 5068.608,
    hnliabilities: 551.272,
    hnnet: 4517.336
  },
  "1972-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 48384,
    householddebt: 0,
    householdliabilitiesother: 36811,
    householdlending: 48996,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 776772,
    hngoods: 403464,
    householdgoods: 1450117,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 883637,
    householdestate: 1046.653,
    hncredit: 161.529,
    life: 5.849,
    hnmortgate: 334.408,
    hnlifeinsurance: 142.16,
    hnmiscellaneous: 33.117,
    hncash: 0.612,
    hnassets: 5209.383,
    hnliabilities: 566.724,
    hnnet: 4642.659
  },
  "1972-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 48260,
    householddebt: 0,
    householdliabilitiesother: 37093,
    householdlending: 50969,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 802061,
    hngoods: 409500,
    householdgoods: 1508124,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 911037,
    householdestate: 1098.624,
    hncredit: 168.757,
    life: 5.976,
    hnmortgate: 343.552,
    hnlifeinsurance: 143.924,
    hnmiscellaneous: 33.898,
    hncash: 2.709,
    hnassets: 5474.707,
    hnliabilities: 585.423,
    hnnet: 4889.284
  },
  "1973-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47831,
    householddebt: 0,
    householdliabilitiesother: 36512,
    householdlending: 48140,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 829893,
    hngoods: 421431,
    householdgoods: 1546565,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 924593,
    householdestate: 1125.134,
    hncredit: 171.528,
    life: 6.087,
    hnmortgate: 351.55,
    hnlifeinsurance: 145.728,
    hnmiscellaneous: 34.647,
    hncash: 0.309,
    hnassets: 5510.828,
    hnliabilities: 597.546,
    hnnet: 4913.282
  },
  "1973-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 48215,
    householddebt: 0,
    householdliabilitiesother: 35401,
    householdlending: 49631,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 863382,
    hngoods: 434273,
    householdgoods: 1599126,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 938527,
    householdestate: 1164.853,
    hncredit: 180.398,
    life: 6.185,
    hnmortgate: 361.814,
    hnlifeinsurance: 147.582,
    hnmiscellaneous: 35.411,
    hncash: 1.416,
    hnassets: 5569.376,
    hnliabilities: 616.455,
    hnnet: 4952.921
  },
  "1973-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47607,
    householddebt: 0,
    householdliabilitiesother: 35633,
    householdlending: 48255,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 904991,
    hngoods: 445192,
    householdgoods: 1662089,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 956046,
    householdestate: 1216.897,
    hncredit: 186.695,
    life: 6.278,
    hnmortgate: 372.134,
    hnlifeinsurance: 149.445,
    hnmiscellaneous: 36.176,
    hncash: 0.648,
    hnassets: 5769.558,
    hnliabilities: 635.549,
    hnnet: 5134.009
  },
  "1973-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 0,
    householdmortgages: 47231,
    householddebt: 0,
    householdliabilitiesother: 35323,
    householdlending: 50804,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 938100,
    hngoods: 454237,
    householdgoods: 1705647,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 969401,
    householdestate: 1251.41,
    hncredit: 192.98,
    life: 6.376,
    hnmortgate: 382.218,
    hnlifeinsurance: 151.271,
    hnmiscellaneous: 36.934,
    hncash: 3.573,
    hnassets: 5750.511,
    hnliabilities: 651.963,
    hnnet: 5098.548
  },
  "1974-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 263,
    householdmortgages: 48171,
    householddebt: 0,
    householdliabilitiesother: 35216,
    householdlending: 49251,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 957695,
    hngoods: 464819,
    householdgoods: 1699683,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 992808,
    householdestate: 1234.864,
    hncredit: 190.46,
    life: 6.53,
    hnmortgate: 389.858,
    hnlifeinsurance: 153.053,
    hnmiscellaneous: 37.667,
    hncash: 1.08,
    hnassets: 5784.349,
    hnliabilities: 656.214,
    hnnet: 5128.135
  },
  "1974-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 570,
    householdmortgages: 48950,
    householddebt: 0,
    householdliabilitiesother: 35614,
    householdlending: 52085,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 977985,
    hngoods: 482477,
    householdgoods: 1707385,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1014934,
    householdestate: 1224.908,
    hncredit: 196.777,
    life: 6.715,
    hnmortgate: 400.439,
    hnlifeinsurance: 154.785,
    hnmiscellaneous: 38.384,
    hncash: 3.135,
    hnassets: 5787.999,
    hnliabilities: 677.007,
    hnnet: 5110.992
  },
  "1974-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 1386,
    householdmortgages: 49938,
    householddebt: 0,
    householdliabilitiesother: 36432,
    householdlending: 52982,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1020052,
    hngoods: 507100,
    householdgoods: 1735679,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1033504,
    householdestate: 1228.579,
    hncredit: 201.241,
    life: 6.908,
    hnmortgate: 411.381,
    hnlifeinsurance: 156.539,
    hnmiscellaneous: 39.103,
    hncash: 3.044,
    hnassets: 5766.593,
    hnliabilities: 695.427,
    hnnet: 5071.166
  },
  "1974-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 2379,
    householdmortgages: 50594,
    householddebt: 0,
    householdliabilitiesother: 36967,
    householdlending: 54027,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1051035,
    hngoods: 525757,
    householdgoods: 1786898,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1060356,
    householdestate: 1261.141,
    hncredit: 201.926,
    life: 7.086,
    hnmortgate: 419.339,
    hnlifeinsurance: 158.39,
    hnmiscellaneous: 39.837,
    hncash: 3.433,
    hnassets: 5918.373,
    hnliabilities: 708.618,
    hnnet: 5209.755
  },
  "1975-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3620,
    householdmortgages: 44702,
    householddebt: 0,
    householdliabilitiesother: 37620,
    householdlending: 47484,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1079878,
    hngoods: 537395,
    householdgoods: 1858036,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1092874,
    householdestate: 1320.641,
    hncredit: 195.912,
    life: 7.258,
    hnmortgate: 424.313,
    hnlifeinsurance: 161.437,
    hnmiscellaneous: 40.699,
    hncash: 2.782,
    hnassets: 6169.758,
    hnliabilities: 707.942,
    hnnet: 5461.816
  },
  "1975-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3661,
    householdmortgages: 47107,
    householddebt: 0,
    householdliabilitiesother: 38305,
    householdlending: 50693,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1109649,
    hngoods: 551000,
    householdgoods: 1927129,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1126023,
    householdestate: 1376.129,
    hncredit: 195.933,
    life: 7.431,
    hnmortgate: 434.575,
    hnlifeinsurance: 163.786,
    hnmiscellaneous: 41.563,
    hncash: 3.586,
    hnassets: 6416.525,
    hnliabilities: 721.972,
    hnnet: 5694.553
  },
  "1975-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3708,
    householdmortgages: 49475,
    householddebt: 0,
    householdliabilitiesother: 39157,
    householdlending: 50994,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1128199,
    hngoods: 562642,
    householdgoods: 1937210,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1151659,
    householdestate: 1374.568,
    hncredit: 201.183,
    life: 7.596,
    hnmortgate: 446.324,
    hnlifeinsurance: 165.845,
    hnmiscellaneous: 42.562,
    hncash: 1.519,
    hnassets: 6432.689,
    hnliabilities: 742.057,
    hnnet: 5690.632
  },
  "1975-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3645,
    householdmortgages: 50481,
    householddebt: 0,
    householdliabilitiesother: 40149,
    householdlending: 55203,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1149585,
    hngoods: 577817,
    householdgoods: 1991475,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1182138,
    householdestate: 1413.658,
    hncredit: 206.996,
    life: 7.744,
    hnmortgate: 459.087,
    hnlifeinsurance: 168.615,
    hnmiscellaneous: 43.806,
    hncash: 4.722,
    hnassets: 6624.406,
    hnliabilities: 765.776,
    hnnet: 5858.63
  },
  "1976-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3557,
    householdmortgages: 51356,
    householddebt: 0,
    householdliabilitiesother: 41915,
    householdlending: 56153,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1169734,
    hngoods: 591888,
    householdgoods: 2036274,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1205619,
    householdestate: 1444.386,
    hncredit: 204.908,
    life: 7.902,
    hnmortgate: 469.994,
    hnlifeinsurance: 170.603,
    hnmiscellaneous: 45.136,
    hncash: 4.797,
    hnassets: 6833.265,
    hnliabilities: 775.307,
    hnnet: 6057.958
  },
  "1976-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3341,
    householdmortgages: 52099,
    householddebt: 0,
    householdliabilitiesother: 43672,
    householdlending: 55980,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1212591,
    hngoods: 604583,
    householdgoods: 2114732,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1225423,
    householdestate: 1510.149,
    hncredit: 213.148,
    life: 8.045,
    hnmortgate: 483.99,
    hnlifeinsurance: 173.063,
    hnmiscellaneous: 46.621,
    hncash: 3.881,
    hnassets: 7036.991,
    hnliabilities: 802.059,
    hnnet: 6234.932
  },
  "1976-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3322,
    householdmortgages: 52928,
    householddebt: 0,
    householdliabilitiesother: 44704,
    householdlending: 56093,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1241282,
    hngoods: 618235,
    householdgoods: 2159999,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1246444,
    householdestate: 1541.764,
    hncredit: 220.688,
    life: 8.196,
    hnmortgate: 501.121,
    hnlifeinsurance: 175.284,
    hnmiscellaneous: 48.164,
    hncash: 3.165,
    hnassets: 7178.403,
    hnliabilities: 827.219,
    hnnet: 6351.184
  },
  "1976-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3099,
    householdmortgages: 52839,
    householddebt: 0,
    householdliabilitiesother: 45896,
    householdlending: 60263,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1279516,
    hngoods: 635565,
    householdgoods: 2225542,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1268980,
    householdestate: 1589.977,
    hncredit: 228.961,
    life: 8.375,
    hnmortgate: 517.073,
    hnlifeinsurance: 177.81,
    hnmiscellaneous: 49.675,
    hncash: 7.424,
    hnassets: 7375.329,
    hnliabilities: 855.353,
    hnnet: 6519.976
  },
  "1977-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 2939,
    householdmortgages: 53628,
    householddebt: 0,
    householdliabilitiesother: 46514,
    householdlending: 55059,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1314418,
    hngoods: 652348,
    householdgoods: 2302350,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1295455,
    householdestate: 1650.002,
    hncredit: 229.155,
    life: 8.582,
    hnmortgate: 531.31,
    hnlifeinsurance: 180.11,
    hnmiscellaneous: 51.407,
    hncash: 1.431,
    hnassets: 7500.52,
    hnliabilities: 872.133,
    hnnet: 6628.387
  },
  "1977-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3017,
    householdmortgages: 54453,
    householddebt: 0,
    householdliabilitiesother: 48103,
    householdlending: 56724,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1347783,
    hngoods: 666296,
    householdgoods: 2406929,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1324260,
    householdestate: 1740.633,
    hncredit: 242.048,
    life: 8.818,
    hnmortgate: 554.091,
    hnlifeinsurance: 182.542,
    hnmiscellaneous: 53.295,
    hncash: 2.271,
    hnassets: 7718.253,
    hnliabilities: 910.866,
    hnnet: 6807.387
  },
  "1977-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 2956,
    householdmortgages: 55295,
    householddebt: 0,
    householdliabilitiesother: 48680,
    householdlending: 55013,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1392177,
    hngoods: 684250,
    householdgoods: 2493603,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1352772,
    householdestate: 1809.353,
    hncredit: 252.953,
    life: 9.06,
    hnmortgate: 579.911,
    hnlifeinsurance: 184.977,
    hnmiscellaneous: 55.213,
    hncash: -0.282,
    hnassets: 7896.784,
    hnliabilities: 949.05,
    hnnet: 6947.734
  },
  "1977-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 3197,
    householdmortgages: 55378,
    householddebt: 0,
    householdliabilitiesother: 49728,
    householdlending: 60722,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1443281,
    hngoods: 705080,
    householdgoods: 2591886,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1382302,
    householdestate: 1886.806,
    hncredit: 264.892,
    life: 9.284,
    hnmortgate: 602.997,
    hnlifeinsurance: 187.791,
    hnmiscellaneous: 57.037,
    hncash: 5.344,
    hnassets: 8093.307,
    hnliabilities: 986.885,
    hnnet: 7106.422
  },
  "1978-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 4734,
    householdmortgages: 56421,
    householddebt: 0,
    householdliabilitiesother: 52193,
    householdlending: 58327,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1505366,
    hngoods: 724153,
    householdgoods: 2677318,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1415193,
    householdestate: 1953.165,
    hncredit: 266.971,
    life: 9.534,
    hnmortgate: 621.021,
    hnlifeinsurance: 190.447,
    hnmiscellaneous: 58.821,
    hncash: 1.906,
    hnassets: 8289.727,
    hnliabilities: 1009.796,
    hnnet: 7279.931
  },
  "1978-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 6050,
    householdmortgages: 59018,
    householddebt: 0,
    householdliabilitiesother: 55477,
    householdlending: 62616,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1562981,
    hngoods: 747349,
    householdgoods: 2795241,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1451956,
    householdestate: 2047.892,
    hncredit: 284.526,
    life: 9.79,
    hnmortgate: 648.036,
    hnlifeinsurance: 193.515,
    hnmiscellaneous: 60.553,
    hncash: 3.598,
    hnassets: 8589.258,
    hnliabilities: 1060.157,
    hnnet: 7529.101
  },
  "1978-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 7215,
    householdmortgages: 61621,
    householddebt: 0,
    householdliabilitiesother: 58223,
    householdlending: 66687,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1616970,
    hngoods: 770522,
    householdgoods: 2899564,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1489718,
    householdestate: 2129.042,
    hncredit: 298.022,
    life: 10.059,
    hnmortgate: 678.951,
    hnlifeinsurance: 196.379,
    hnmiscellaneous: 62.293,
    hncash: 5.066,
    hnassets: 8883.369,
    hnliabilities: 1107.727,
    hnnet: 7775.642
  },
  "1978-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 8928,
    householdmortgages: 62445,
    householddebt: 0,
    householdliabilitiesother: 55754,
    householdlending: 73592,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1675021,
    hngoods: 795147,
    householdgoods: 3006024,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1521778,
    householdestate: 2210.877,
    hncredit: 311.305,
    life: 10.349,
    hnmortgate: 708.641,
    hnlifeinsurance: 199.349,
    hnmiscellaneous: 64.095,
    hncash: 11.147,
    hnassets: 9107.511,
    hnliabilities: 1150.252,
    hnnet: 7957.259
  },
  "1979-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 15693,
    householdmortgages: 64808,
    householddebt: 0,
    householdliabilitiesother: 56035,
    householdlending: 69082,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1750402,
    hngoods: 821174,
    householdgoods: 3146027,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1560261,
    householdestate: 2324.853,
    hncredit: 313.819,
    life: 10.665,
    hnmortgate: 733.605,
    hnlifeinsurance: 201.981,
    hnmiscellaneous: 65.976,
    hncash: 4.274,
    hnassets: 9445.812,
    hnliabilities: 1177.797,
    hnnet: 8268.015
  },
  "1979-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 22681,
    householdmortgages: 68066,
    householddebt: 0,
    householdliabilitiesother: 60075,
    householdlending: 74717,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1816156,
    hngoods: 847192,
    householdgoods: 3285574,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1595727,
    householdestate: 2438.382,
    hncredit: 327.319,
    life: 11.01,
    hnmortgate: 763.421,
    hnlifeinsurance: 204.874,
    hnmiscellaneous: 67.896,
    hncash: 6.651,
    hnassets: 9765.926,
    hnliabilities: 1225.353,
    hnnet: 8540.573
  },
  "1979-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 29822,
    householdmortgages: 71178,
    householddebt: 0,
    householdliabilitiesother: 62614,
    householdlending: 79654,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1883997,
    hngoods: 869277,
    householdgoods: 3396672,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1635416,
    householdestate: 2527.395,
    hncredit: 341.528,
    life: 11.36,
    hnmortgate: 795.927,
    hnlifeinsurance: 207.47,
    hnmiscellaneous: 69.849,
    hncash: 8.476,
    hnassets: 10099.583,
    hnliabilities: 1275.76,
    hnnet: 8823.823
  },
  "1979-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 39459,
    householdmortgages: 71611,
    householddebt: 0,
    householdliabilitiesother: 61871,
    householdlending: 86010,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1944230,
    hngoods: 895778,
    householdgoods: 3499049,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1684319,
    householdestate: 2603.271,
    hncredit: 354.616,
    life: 11.692,
    hnmortgate: 826.724,
    hnlifeinsurance: 210.28,
    hnmiscellaneous: 71.83,
    hncash: 14.399,
    hnassets: 10425.614,
    hnliabilities: 1321.228,
    hnnet: 9104.386
  },
  "1980-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 54525,
    householdmortgages: 80996,
    householddebt: 0,
    householdliabilitiesother: 65448,
    householdlending: 89737,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 1994161,
    hngoods: 928775,
    householdgoods: 3638071,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1720020,
    householdestate: 2709.296,
    hncredit: 349.662,
    life: 12.003,
    hnmortgate: 856.228,
    hnlifeinsurance: 212.738,
    hnmiscellaneous: 73.951,
    hncash: 8.741,
    hnassets: 10647.282,
    hnliabilities: 1350.243,
    hnnet: 9297.039
  },
  "1980-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 69928,
    householdmortgages: 82942,
    householddebt: 0,
    householdliabilitiesother: 67904,
    householdlending: 95007,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2051929,
    hngoods: 951028,
    householdgoods: 3735986,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1775914,
    householdestate: 2784.958,
    hncredit: 345.637,
    life: 12.288,
    hnmortgate: 873.96,
    hnlifeinsurance: 215.426,
    hnmiscellaneous: 75.59,
    hncash: 12.065,
    hnassets: 11012.169,
    hnliabilities: 1368.494,
    hnnet: 9643.675
  },
  "1980-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 70003,
    householdmortgages: 86837,
    householddebt: 0,
    householdliabilitiesother: 70792,
    householdlending: 102933,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2116169,
    hngoods: 970690,
    householdgoods: 3868737,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1832462,
    householdestate: 2898.047,
    hncredit: 350.336,
    life: 12.587,
    hnmortgate: 901.374,
    hnlifeinsurance: 217.843,
    hnmiscellaneous: 77.2,
    hncash: 16.096,
    hnassets: 11442.204,
    hnliabilities: 1404.495,
    hnnet: 10037.709
  },
  "1980-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 64366,
    householdmortgages: 87255,
    householddebt: 0,
    householdliabilitiesother: 76832,
    householdlending: 109476,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2187657,
    hngoods: 991196,
    householdgoods: 3934357,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1884123,
    householdestate: 2943.161,
    hncredit: 358.044,
    life: 12.939,
    hnmortgate: 926.526,
    hnlifeinsurance: 220.528,
    hnmiscellaneous: 78.906,
    hncash: 22.221,
    hnassets: 11808.992,
    hnliabilities: 1446.947,
    hnnet: 10362.045
  },
  "1981-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 98522,
    householdmortgages: 89379,
    householddebt: 0,
    householdliabilitiesother: 76061,
    householdlending: 104005,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2239474,
    hngoods: 1008092,
    householdgoods: 3984865,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1936656,
    householdestate: 2976.773,
    hncredit: 353.902,
    life: 13.358,
    hnmortgate: 940.59,
    hnlifeinsurance: 222.82,
    hnmiscellaneous: 80.908,
    hncash: 14.626,
    hnassets: 12013.534,
    hnliabilities: 1456.193,
    hnnet: 10557.341
  },
  "1981-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 108564,
    householdmortgages: 95636,
    householddebt: 0,
    householdliabilitiesother: 78570,
    householdlending: 108699,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2281109,
    hngoods: 1029290,
    householdgoods: 4174536,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 1986110,
    householdestate: 3145.246,
    hncredit: 361.61,
    life: 13.859,
    hnmortgate: 964.154,
    hnlifeinsurance: 225.469,
    hnmiscellaneous: 82.967,
    hncash: 13.063,
    hnassets: 12336.17,
    hnliabilities: 1493.595,
    hnnet: 10842.575
  },
  "1981-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 137398,
    householdmortgages: 99638,
    householddebt: 0,
    householdliabilitiesother: 79902,
    householdlending: 111927,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2308710,
    hngoods: 1048173,
    householdgoods: 4291542,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2029134,
    householdestate: 3243.369,
    hncredit: 371.928,
    life: 14.345,
    hnmortgate: 981.849,
    hnlifeinsurance: 227.675,
    hnmiscellaneous: 84.927,
    hncash: 12.289,
    hnassets: 12468.422,
    hnliabilities: 1524.585,
    hnnet: 10943.837
  },
  "1981-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 154610,
    householdmortgages: 101407,
    householddebt: 0,
    householdliabilitiesother: 83987,
    householdlending: 119766,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2345345,
    hngoods: 1060997,
    householdgoods: 4353957,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2084376,
    householdestate: 3292.96,
    hncredit: 377.882,
    life: 14.722,
    hnmortgate: 998.261,
    hnlifeinsurance: 230.103,
    hnmiscellaneous: 86.791,
    hncash: 18.359,
    hnassets: 12811.799,
    hnliabilities: 1556.065,
    hnnet: 11255.734
  },
  "1982-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 164885,
    householdmortgages: 105876,
    householddebt: 0,
    householdliabilitiesother: 81708,
    householdlending: 119980,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2378741,
    hngoods: 1073506,
    householdgoods: 4453230,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2133338,
    householdestate: 3379.724,
    hncredit: 373.537,
    life: 15.001,
    hnmortgate: 1007.909,
    hnlifeinsurance: 232.02,
    hnmiscellaneous: 88.241,
    hncash: 14.104,
    hnassets: 12952.918,
    hnliabilities: 1560.136,
    hnnet: 11392.782
  },
  "1982-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 175381,
    householdmortgages: 108742,
    householddebt: 0,
    householdliabilitiesother: 83431,
    householdlending: 125915,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2389915,
    hngoods: 1085457,
    householdgoods: 4488000,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2189682,
    householdestate: 3402.543,
    hncredit: 380.887,
    life: 15.194,
    hnmortgate: 1019.26,
    hnlifeinsurance: 234.054,
    hnmiscellaneous: 89.558,
    hncash: 17.173,
    hnassets: 13109.509,
    hnliabilities: 1584.35,
    hnnet: 11525.159
  },
  "1982-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 201364,
    householdmortgages: 104528,
    householddebt: 0,
    householdliabilitiesother: 86833,
    householdlending: 122082,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2384812,
    hngoods: 1092993,
    householdgoods: 4502832,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2264107,
    householdestate: 3409.839,
    hncredit: 386.815,
    life: 15.349,
    hnmortgate: 1017.736,
    hnlifeinsurance: 235.699,
    hnmiscellaneous: 91.108,
    hncash: 17.554,
    hnassets: 13345.173,
    hnliabilities: 1594.325,
    hnnet: 11750.848
  },
  "1982-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 186712,
    householdmortgages: 110888,
    householddebt: 0,
    householdliabilitiesother: 92616,
    householdlending: 134201,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2388477,
    hngoods: 1102250,
    householdgoods: 4549623,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2331384,
    householdestate: 3447.373,
    hncredit: 396.718,
    life: 15.515,
    hnmortgate: 1031.175,
    hnlifeinsurance: 237.912,
    hnmiscellaneous: 95.31,
    hncash: 23.313,
    hnassets: 13707.046,
    hnliabilities: 1629.03,
    hnnet: 12078.016
  },
  "1983-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 164329,
    householdmortgages: 109724,
    householddebt: 0,
    householdliabilitiesother: 94162,
    householdlending: 127671,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2410887,
    hngoods: 1117146,
    householdgoods: 4617766,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2406659,
    householdestate: 3500.62,
    hncredit: 391.91,
    life: 15.665,
    hnmortgate: 1026.258,
    hnlifeinsurance: 239.825,
    hnmiscellaneous: 98.409,
    hncash: 17.947,
    hnassets: 14036.725,
    hnliabilities: 1622.222,
    hnnet: 12414.503
  },
  "1983-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 150654,
    householdmortgages: 111357,
    householddebt: 0,
    householdliabilitiesother: 100569,
    householdlending: 130213,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2406275,
    hngoods: 1132328,
    householdgoods: 4680502,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2494857,
    householdestate: 3548.174,
    hncredit: 403.838,
    life: 15.81,
    hnmortgate: 1053.751,
    hnlifeinsurance: 242.193,
    hnmiscellaneous: 102.174,
    hncash: 18.856,
    hnassets: 14386.694,
    hnliabilities: 1673.327,
    hnnet: 12713.367
  },
  "1983-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 150913,
    householdmortgages: 110672,
    householddebt: 0,
    householdliabilitiesother: 102047,
    householdlending: 130445,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2415692,
    hngoods: 1151707,
    householdgoods: 4746333,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2554285,
    householdestate: 3594.626,
    hncredit: 420.612,
    life: 15.95,
    hnmortgate: 1087.568,
    hnlifeinsurance: 244.236,
    hnmiscellaneous: 106.79,
    hncash: 19.773,
    hnassets: 14605.36,
    hnliabilities: 1731.147,
    hnnet: 12874.213
  },
  "1983-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 149944,
    householdmortgages: 111313,
    householddebt: 0,
    householdliabilitiesother: 102820,
    householdlending: 133886,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2446913,
    hngoods: 1174964,
    householdgoods: 4814763,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2614619,
    householdestate: 3639.799,
    hncredit: 444.878,
    life: 16.099,
    hnmortgate: 1116.384,
    hnlifeinsurance: 246.68,
    hnmiscellaneous: 111.538,
    hncash: 22.573,
    hnassets: 14768.828,
    hnliabilities: 1793.755,
    hnnet: 12975.073
  },
  "1984-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 163904,
    householdmortgages: 110989,
    householddebt: 0,
    householdliabilitiesother: 100395,
    householdlending: 129801,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2453505,
    hngoods: 1195099,
    householdgoods: 5013732,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2681420,
    householdestate: 3818.633,
    hncredit: 452.01,
    life: 15.949,
    hnmortgate: 1144.101,
    hnlifeinsurance: 247.938,
    hnmiscellaneous: 106.42,
    hncash: 18.812,
    hnassets: 15041.661,
    hnliabilities: 1826.137,
    hnnet: 13215.524
  },
  "1984-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 166718,
    householdmortgages: 112405,
    householddebt: 0,
    householdliabilitiesother: 101637,
    householdlending: 131162,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2443005,
    hngoods: 1223604,
    householdgoods: 5179041,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2733014,
    householdestate: 3955.437,
    hncredit: 479.799,
    life: 15.6,
    hnmortgate: 1177.677,
    hnlifeinsurance: 249.458,
    hnmiscellaneous: 109.02,
    hncash: 18.757,
    hnassets: 15332.429,
    hnliabilities: 1891.15,
    hnnet: 13441.279
  },
  "1984-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 167650,
    householdmortgages: 114667,
    householddebt: 0,
    householdliabilitiesother: 99958,
    householdlending: 135305,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2439225,
    hngoods: 1245881,
    householdgoods: 5331157,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2816453,
    householdestate: 4085.276,
    hncredit: 501.839,
    life: 15.255,
    hnmortgate: 1211.497,
    hnlifeinsurance: 250.814,
    hnmiscellaneous: 111.439,
    hncash: 20.638,
    hnassets: 15719.885,
    hnliabilities: 1946.892,
    hnnet: 13772.993
  },
  "1984-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 194318,
    householdmortgages: 103009,
    householddebt: 0,
    householdliabilitiesother: 101474,
    householdlending: 130906,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2445062,
    hngoods: 1269638,
    householdgoods: 5478283,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2896256,
    householdestate: 4208.645,
    hncredit: 526.584,
    life: 15.108,
    hnmortgate: 1243.294,
    hnlifeinsurance: 252.717,
    hnmiscellaneous: 114.184,
    hncash: 27.897,
    hnassets: 16071.368,
    hnliabilities: 2013.977,
    hnnet: 14057.391
  },
  "1985-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 201589,
    householdmortgages: 123726,
    householddebt: 0,
    householdliabilitiesother: 101102,
    householdlending: 147256,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2448785,
    hngoods: 1299446,
    householdgoods: 5646647,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 2994428,
    householdestate: 4347.201,
    hncredit: 536.023,
    life: 15.071,
    hnmortgate: 1304.981,
    hnlifeinsurance: 254.827,
    hnmiscellaneous: 116.225,
    hncash: 23.53,
    hnassets: 16547.044,
    hnliabilities: 2088.221,
    hnnet: 14458.823
  },
  "1985-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 207124,
    householdmortgages: 125750,
    householddebt: 0,
    householdliabilitiesother: 105626,
    householdlending: 157818,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2434439,
    hngoods: 1325529,
    householdgoods: 5834681,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3064338,
    householdestate: 4509.152,
    hncredit: 559.592,
    life: 15.18,
    hnmortgate: 1343.287,
    hnlifeinsurance: 257.67,
    hnmiscellaneous: 122.968,
    hncash: 32.068,
    hnassets: 16960.29,
    hnliabilities: 2163.818,
    hnnet: 14796.472
  },
  "1985-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 203542,
    householdmortgages: 122083,
    householddebt: 0,
    householdliabilitiesother: 112573,
    householdlending: 151605,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2446727,
    hngoods: 1350434,
    householdgoods: 6032257,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3117095,
    householdestate: 4681.823,
    hncredit: 585.905,
    life: 15.279,
    hnmortgate: 1402.164,
    hnlifeinsurance: 260.483,
    hnmiscellaneous: 133.392,
    hncash: 29.522,
    hnassets: 17258.68,
    hnliabilities: 2255.833,
    hnnet: 15002.847
  },
  "1985-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 204559,
    householdmortgages: 116174,
    householddebt: 0,
    householdliabilitiesother: 122698,
    householdlending: 165942,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2508609,
    hngoods: 1376882,
    householdgoods: 6227096,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3184551,
    householdestate: 4850.214,
    hncredit: 610.574,
    life: 15.209,
    hnmortgate: 1450.249,
    hnlifeinsurance: 264.25,
    hnmiscellaneous: 145.234,
    hncash: 49.768,
    hnassets: 17871.059,
    hnliabilities: 2369.31,
    hnnet: 15501.749
  },
  "1986-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 221645,
    householdmortgages: 115115,
    householddebt: 0,
    householdliabilitiesother: 125566,
    householdlending: 167507,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2544888,
    hngoods: 1402013,
    householdgoods: 6360262,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3277160,
    householdestate: 4958.249,
    hncredit: 606.799,
    life: 14.968,
    hnmortgate: 1476.952,
    hnlifeinsurance: 268.176,
    hnmiscellaneous: 149.386,
    hncash: 52.392,
    hnassets: 18399.196,
    hnliabilities: 2393.408,
    hnnet: 16005.788
  },
  "1986-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 232607,
    householdmortgages: 115335,
    householddebt: 0,
    householdliabilitiesother: 126201,
    householdlending: 161641,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2565459,
    hngoods: 1435734,
    householdgoods: 6535678,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3352890,
    householdestate: 5099.944,
    hncredit: 627.891,
    life: 14.548,
    hnmortgate: 1525.857,
    hnlifeinsurance: 272.767,
    hnmiscellaneous: 154.172,
    hncash: 46.306,
    hnassets: 18841.532,
    hnliabilities: 2459.114,
    hnnet: 16382.418
  },
  "1986-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 248549,
    householdmortgages: 112936,
    householddebt: 0,
    householdliabilitiesother: 129505,
    householdlending: 162446,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2599510,
    hngoods: 1482372,
    householdgoods: 6721415,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3402031,
    householdestate: 5239.043,
    hncredit: 649.888,
    life: 14.129,
    hnmortgate: 1587.994,
    hnlifeinsurance: 277.335,
    hnmiscellaneous: 160.129,
    hncash: 49.51,
    hnassets: 19108.771,
    hnliabilities: 2545.61,
    hnnet: 16563.161
  },
  "1986-12-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 245010,
    householdmortgages: 112602,
    householddebt: 0,
    householdliabilitiesother: 132153,
    householdlending: 171599,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2626877,
    hngoods: 1522048,
    householdgoods: 6893161,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3496575,
    householdestate: 5371.113,
    hncredit: 666.355,
    life: 13.885,
    hnmortgate: 1649.024,
    hnlifeinsurance: 282.494,
    hnmiscellaneous: 165.65,
    hncash: 58.997,
    hnassets: 19768.236,
    hnliabilities: 2632.766,
    hnnet: 17135.47
  },
  "1987-03-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 257208,
    householdmortgages: 118284,
    householddebt: 0,
    householdliabilitiesother: 125794,
    householdlending: 172468,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2657069,
    hngoods: 1553918,
    householdgoods: 7065070,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3603243,
    householdestate: 5511.152,
    hncredit: 644.317,
    life: 13.853,
    hnmortgate: 1681.564,
    hnlifeinsurance: 289.493,
    hnmiscellaneous: 173.185,
    hncash: 54.184,
    hnassets: 20397.342,
    hnliabilities: 2638.258,
    hnnet: 17759.084
  },
  "1987-06-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 255246,
    householdmortgages: 118370,
    householddebt: 0,
    householdliabilitiesother: 129129,
    householdlending: 170858,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2675848,
    hngoods: 1588289,
    householdgoods: 7243427,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3683857,
    householdestate: 5655.138,
    hncredit: 660.313,
    life: 14.193,
    hnmortgate: 1752.706,
    hnlifeinsurance: 295.874,
    hnmiscellaneous: 180.235,
    hncash: 52.488,
    hnassets: 20781.219,
    hnliabilities: 2731.548,
    hnnet: 18049.671
  },
  "1987-09-01": {
    nonprofitassets: 0,
    nonprofitmiscellaneousassets: 0,
    nonprofitmiscellaneous: 0,
    nonprofitnotes: 0,
    nonprofitliabilities: 0,
    householdassets: 0,
    householdnet: 0,
    householdcheckable: 0,
    householddeposits: 0,
    householdfunds: 260676,
    householdmortgages: 118254,
    householddebt: 0,
    householdliabilitiesother: 131267,
    householdlending: 171409,
    householdliabilities: 0,
    householdborrowing: 0,
    householdfinance: 0,
    householdself: 2696526,
    hngoods: 1625091,
    householdgoods: 7414770,
    householdnotes: 0,
    householdbonds: 0,
    householdequities: 0,
    householdcredits: 0,
    hnpension: 3791416,
    householdestate: 5789.679,
    hncredit: 679.637,
    life: 14.503,
    hnmortgate: 1802.929,
    hnlifeinsurance: 303.181,
    hnmiscellaneous: 186.856,
    hncash: 53.155,
    hnassets: 21269.167,
    hnliabilities: 2809.325,
    hnnet: 18459.842
  },
  "1987-12-01": {
    nonprofitassets: 1345412,
    nonprofitmiscellaneousassets: 148893,
    nonprofitmiscellaneous: 108203,
    nonprofitnotes: 22107,
    nonprofitliabilities: 305842,
    householdassets: 20082733,
    householdnet: 17404562,
    householdcheckable: 434835,
    householddeposits: 2269313,
    householdfunds: 270671,
    householdmortgages: 117347,
    householddebt: 4569,
    householdliabilitiesother: 131261,
    householdlending: 158435,
    householdliabilities: 12543553,
    householdborrowing: 2663078,
    householdfinance: 2678171,
    householdself: 2733335,
    hngoods: 1652722,
    householdgoods: 7539180,
    householdnotes: 832230,
    householdbonds: 125645,
    householdequities: 1424642,
    householdcredits: 957875,
    hnpension: 3793453,
    householdestate: 5886.458,
    hncredit: 698.64,
    life: 15.093,
    hnmortgate: 1828.608,
    hnlifeinsurance: 307.411,
    hnmiscellaneous: 193.583,
    hncash: 41.088,
    hnassets: 21279.252,
    hnliabilities: 2875.81,
    hnnet: 18403.442
  },
  "1988-03-01": {
    nonprofitassets: 1373894,
    nonprofitmiscellaneousassets: 153856,
    nonprofitmiscellaneous: 110401,
    nonprofitnotes: 22507,
    nonprofitliabilities: 310087,
    householdassets: 20611890,
    householdnet: 17890849,
    householdcheckable: 425900,
    householddeposits: 2336552,
    householdfunds: 294494,
    householdmortgages: 117514,
    householddebt: 6839,
    householdliabilitiesother: 129909,
    householdlending: 160452,
    householdliabilities: 12901092,
    householdborrowing: 2706078,
    householdfinance: 2721041,
    householdself: 2777698,
    hngoods: 1680168,
    householdgoods: 7710798,
    householdnotes: 837707,
    householdbonds: 126208,
    householdequities: 1540339,
    householdcredits: 963915,
    hnpension: 3889951,
    householdestate: 6030.63,
    hncredit: 693.732,
    life: 14.963,
    hnmortgate: 1875.598,
    hnlifeinsurance: 313.598,
    hnmiscellaneous: 198.192,
    hncash: 42.938,
    hnassets: 21831.928,
    hnliabilities: 2920.727,
    hnnet: 18911.201
  },
  "1988-06-01": {
    nonprofitassets: 1401283,
    nonprofitmiscellaneousassets: 158917,
    nonprofitmiscellaneous: 112508,
    nonprofitnotes: 22907,
    nonprofitliabilities: 314496,
    householdassets: 21108203,
    householdnet: 18303731,
    householdcheckable: 430322,
    householddeposits: 2375155,
    householdfunds: 276483,
    householdmortgages: 118021,
    householddebt: 5899,
    householdliabilitiesother: 131318,
    householdlending: 163390,
    householdliabilities: 13174208,
    householdborrowing: 2789449,
    householdfinance: 2804472,
    householdself: 2812757,
    hngoods: 1719131,
    householdgoods: 7933995,
    householdnotes: 875293,
    householdbonds: 123220,
    householdequities: 1618484,
    householdcredits: 998513,
    hnpension: 3975667,
    householdestate: 6214.864,
    hncredit: 710.128,
    life: 15.023,
    hnmortgate: 1942.104,
    hnlifeinsurance: 320.976,
    hnmiscellaneous: 202.462,
    hncash: 45.369,
    hnassets: 22350.569,
    hnliabilities: 3006.46,
    hnnet: 19344.109
  },
  "1988-09-01": {
    nonprofitassets: 1424846,
    nonprofitmiscellaneousassets: 163927,
    nonprofitmiscellaneous: 113658,
    nonprofitnotes: 23307,
    nonprofitliabilities: 318913,
    householdassets: 21489986,
    householdnet: 18607794,
    householdcheckable: 428980,
    householddeposits: 2421037,
    householdfunds: 274183,
    householdmortgages: 118795,
    householddebt: 8697,
    householdliabilitiesother: 132881,
    householdlending: 163877,
    householdliabilities: 13393748,
    householdborrowing: 2867005,
    householdfinance: 2882192,
    householdself: 2842807,
    hngoods: 1758495,
    householdgoods: 8096238,
    householdnotes: 927784,
    householdbonds: 120883,
    householdequities: 1637483,
    householdcredits: 1048667,
    hnpension: 4041678,
    householdestate: 6337.743,
    hncredit: 725.838,
    life: 15.187,
    hnmortgate: 1999.589,
    hnlifeinsurance: 327.23,
    hnmiscellaneous: 207.806,
    hncash: 45.082,
    hnassets: 22750.905,
    hnliabilities: 3087.447,
    hnnet: 19663.458
  },
  "1988-12-01": {
    nonprofitassets: 1447543,
    nonprofitmiscellaneousassets: 168737,
    nonprofitmiscellaneous: 115247,
    nonprofitnotes: 23707,
    nonprofitliabilities: 323183,
    householdassets: 22049722,
    householdnet: 19095716,
    householdcheckable: 444077,
    householddeposits: 2467202,
    householdfunds: 283346,
    householdmortgages: 119902,
    householddebt: 3164,
    householdliabilitiesother: 135262,
    householdlending: 164974,
    householdliabilities: 13800547,
    householdborrowing: 2938464,
    householdfinance: 2954006,
    householdself: 2877596,
    hngoods: 1800452,
    householdgoods: 8249175,
    householdnotes: 993363,
    householdbonds: 145787,
    householdequities: 1752042,
    householdcredits: 1139150,
    hnpension: 4122627,
    householdestate: 6448.723,
    hncredit: 745.206,
    life: 15.542,
    hnmortgate: 2054.832,
    hnlifeinsurance: 335.493,
    hnmiscellaneous: 214.041,
    hncash: 45.072,
    hnassets: 23328.528,
    hnliabilities: 3161.942,
    hnnet: 20166.586
  },
  "1989-03-01": {
    nonprofitassets: 1483100,
    nonprofitmiscellaneousassets: 175013,
    nonprofitmiscellaneous: 119769,
    nonprofitnotes: 24289,
    nonprofitliabilities: 334467,
    householdassets: 22492981,
    householdnet: 19501141,
    householdcheckable: 424772,
    householddeposits: 2489393,
    householdfunds: 309098,
    householdmortgages: 120382,
    householddebt: 6959,
    householdliabilitiesother: 127360,
    householdlending: 166828,
    householdliabilities: 14090552,
    householdborrowing: 2976389,
    householdfinance: 2991839,
    householdself: 2918625,
    hngoods: 1836212,
    householdgoods: 8402429,
    householdnotes: 1000195,
    householdbonds: 174405,
    householdequities: 1813827,
    householdcredits: 1174599,
    hnpension: 4236703,
    householdestate: 6566.217,
    hncredit: 753.193,
    life: 15.45,
    hnmortgate: 2088.877,
    hnlifeinsurance: 339.256,
    hnmiscellaneous: 217.45,
    hncash: 46.446,
    hnassets: 23801.068,
    hnliabilities: 3206.537,
    hnnet: 20594.53
  },
  "1989-06-01": {
    nonprofitassets: 1541100,
    nonprofitmiscellaneousassets: 181036,
    nonprofitmiscellaneous: 124155,
    nonprofitnotes: 24871,
    nonprofitliabilities: 345631,
    householdassets: 22952117,
    householdnet: 19878667,
    householdcheckable: 421235,
    householddeposits: 2491050,
    householdfunds: 330500,
    householdmortgages: 122668,
    householddebt: 7371,
    householdliabilitiesother: 134404,
    householdlending: 175031,
    householdliabilities: 14391212,
    householdborrowing: 3057885,
    householdfinance: 3073450,
    householdself: 2954344,
    hngoods: 1864191,
    householdgoods: 8560905,
    householdnotes: 1015299,
    householdbonds: 175477,
    householdequities: 1896102,
    householdcredits: 1190776,
    hnpension: 4363111,
    householdestate: 6696.714,
    hncredit: 769.617,
    life: 15.565,
    hnmortgate: 2146.493,
    hnlifeinsurance: 347.913,
    hnmiscellaneous: 221.15,
    hncash: 52.363,
    hnassets: 24312.181,
    hnliabilities: 3294.926,
    hnnet: 21017.255
  },
  "1989-09-01": {
    nonprofitassets: 1564119,
    nonprofitmiscellaneousassets: 187063,
    nonprofitmiscellaneous: 127977,
    nonprofitnotes: 25453,
    nonprofitliabilities: 356776,
    householdassets: 23559012,
    householdnet: 20408577,
    householdcheckable: 418768,
    householddeposits: 2520313,
    householdfunds: 362643,
    householdmortgages: 125004,
    householddebt: 4526,
    householdliabilitiesother: 135319,
    householdlending: 169214,
    householdliabilities: 14771719,
    householdborrowing: 3134742,
    householdfinance: 3150435,
    householdself: 2969626,
    hngoods: 1896350,
    householdgoods: 8787293,
    householdnotes: 1036220,
    householdbonds: 177844,
    householdequities: 2051748,
    householdcredits: 1214064,
    hnpension: 4485774,
    householdestate: 6890.943,
    hncredit: 784.207,
    life: 15.693,
    hnmortgate: 2210.69,
    hnlifeinsurance: 353.699,
    hnmiscellaneous: 225.87,
    hncash: 44.21,
    hnassets: 24936.068,
    hnliabilities: 3379.234,
    hnnet: 21556.834
  },
  "1989-12-01": {
    nonprofitassets: 1598252,
    nonprofitmiscellaneousassets: 193346,
    nonprofitmiscellaneous: 130397,
    nonprofitnotes: 26035,
    nonprofitliabilities: 368001,
    householdassets: 24016922,
    householdnet: 20791842,
    householdcheckable: 436255,
    householddeposits: 2548151,
    householdfunds: 377237,
    householdmortgages: 127840,
    householddebt: 4383,
    householdliabilitiesother: 134926,
    householdlending: 183105,
    householdliabilities: 15096830,
    householdborrowing: 3208709,
    householdfinance: 3225080,
    householdself: 2996830,
    hngoods: 1925775,
    householdgoods: 8920092,
    householdnotes: 1048393,
    householdbonds: 183591,
    householdequities: 2113551,
    householdcredits: 1231984,
    hnpension: 4614121,
    householdestate: 6994.317,
    hncredit: 809.285,
    life: 16.371,
    hnmortgate: 2260.115,
    hnlifeinsurance: 365.056,
    hnmiscellaneous: 230.542,
    hncash: 55.265,
    hnassets: 25421.828,
    hnliabilities: 3462.684,
    hnnet: 21959.144
  },
  "1990-03-01": {
    nonprofitassets: 1601658,
    nonprofitmiscellaneousassets: 199493,
    nonprofitmiscellaneous: 134191,
    nonprofitnotes: 26480,
    nonprofitliabilities: 377144,
    householdassets: 24178971,
    householdnet: 20891052,
    householdcheckable: 421541,
    householddeposits: 2563157,
    householdfunds: 403659,
    householdmortgages: 131050,
    householddebt: 5450,
    householdliabilitiesother: 139226,
    householdlending: 181770,
    householdliabilities: 15162980,
    householdborrowing: 3271908,
    householdfinance: 3287919,
    householdself: 3027627,
    hngoods: 1967129,
    householdgoods: 9015991,
    householdnotes: 1087100,
    householdbonds: 180099,
    householdequities: 1994665,
    householdcredits: 1267198,
    hnpension: 4693199,
    householdestate: 7048.862,
    hncredit: 792.32,
    life: 16.011,
    hnmortgate: 2334.912,
    hnlifeinsurance: 371.121,
    hnmiscellaneous: 239.043,
    hncash: 50.72,
    hnassets: 25581.136,
    hnliabilities: 3530.872,
    hnnet: 22050.264
  },
  "1990-06-01": {
    nonprofitassets: 1623696,
    nonprofitmiscellaneousassets: 205699,
    nonprofitmiscellaneous: 137517,
    nonprofitnotes: 26925,
    nonprofitliabilities: 386303,
    householdassets: 24507275,
    householdnet: 21152090,
    householdcheckable: 429921,
    householddeposits: 2559667,
    householdfunds: 388955,
    householdmortgages: 133554,
    householddebt: 4426,
    householdliabilitiesother: 140614,
    householdlending: 185942,
    householdliabilities: 15408997,
    householdborrowing: 3339163,
    householdfinance: 3355185,
    householdself: 3049055,
    hngoods: 1992511,
    householdgoods: 9098278,
    householdnotes: 1125951,
    householdbonds: 185519,
    householdequities: 2064553,
    householdcredits: 1311470,
    hnpension: 4799831,
    householdestate: 7105.767,
    hncredit: 798.712,
    life: 16.022,
    hnmortgate: 2395.411,
    hnlifeinsurance: 379.039,
    hnmiscellaneous: 240.563,
    hncash: 52.388,
    hnassets: 25925.272,
    hnliabilities: 3603.971,
    hnnet: 22321.301
  },
  "1990-09-01": {
    nonprofitassets: 1622848,
    nonprofitmiscellaneousassets: 212106,
    nonprofitmiscellaneous: 140930,
    nonprofitnotes: 27370,
    nonprofitliabilities: 395506,
    householdassets: 24417112,
    householdnet: 21004058,
    householdcheckable: 429280,
    householddeposits: 2575307,
    householdfunds: 411581,
    householdmortgages: 136237,
    householddebt: 1907,
    householdliabilitiesother: 136136,
    householdlending: 191597,
    householdliabilities: 15242915,
    householdborrowing: 3397082,
    householdfinance: 3413054,
    householdself: 3071736,
    hngoods: 2016246,
    householdgoods: 9174197,
    householdnotes: 1180456,
    householdbonds: 185019,
    householdequities: 1716135,
    householdcredits: 1365475,
    hnpension: 4851462,
    householdestate: 7157.951,
    hncredit: 812.302,
    life: 15.972,
    hnmortgate: 2446.737,
    hnlifeinsurance: 385.062,
    hnmiscellaneous: 245.28,
    hncash: 55.36,
    hnassets: 25827.854,
    hnliabilities: 3667.63,
    hnnet: 22160.224
  },
  "1990-12-01": {
    nonprofitassets: 1649458,
    nonprofitmiscellaneousassets: 218851,
    nonprofitmiscellaneous: 143018,
    nonprofitnotes: 27815,
    nonprofitliabilities: 404782,
    householdassets: 24938602,
    householdnet: 21466069,
    householdcheckable: 445748,
    householddeposits: 2597503,
    householdfunds: 418006,
    householdmortgages: 138568,
    householddebt: 2126,
    householdliabilitiesother: 140302,
    householdlending: 203419,
    householdliabilities: 15739029,
    householdborrowing: 3456074,
    householdfinance: 3472533,
    householdself: 3094665,
    hngoods: 2039120,
    householdgoods: 9199573,
    householdnotes: 1222521,
    householdbonds: 215773,
    householdequities: 1930097,
    householdcredits: 1438294,
    hnpension: 4970059,
    householdestate: 7160.453,
    hncredit: 824.391,
    life: 16.459,
    hnmortgate: 2489.255,
    hnlifeinsurance: 391.499,
    hnmiscellaneous: 249.739,
    hncash: 64.851,
    hnassets: 26369.209,
    hnliabilities: 3734.297,
    hnnet: 22634.912
  },
  "1991-03-01": {
    nonprofitassets: 1694293,
    nonprofitmiscellaneousassets: 230677,
    nonprofitmiscellaneous: 151026,
    nonprofitnotes: 28071,
    nonprofitliabilities: 417843,
    householdassets: 25474534,
    householdnet: 21981211,
    householdcheckable: 455819,
    householddeposits: 2599571,
    householdfunds: 464869,
    householdmortgages: 139664,
    householddebt: 3716,
    householdliabilitiesother: 141917,
    householdlending: 203026,
    householdliabilities: 16220585,
    householdborrowing: 3476992,
    householdfinance: 3493323,
    householdself: 3093285,
    hngoods: 2067829,
    householdgoods: 9253949,
    householdnotes: 1191862,
    householdbonds: 226881,
    householdequities: 2239688,
    householdcredits: 1418743,
    hnpension: 5096058,
    householdestate: 7186.12,
    hncredit: 802.207,
    life: 16.331,
    hnmortgate: 2529.152,
    hnlifeinsurance: 398.156,
    hnmiscellaneous: 251.371,
    hncash: 63.362,
    hnassets: 26938.15,
    hnliabilities: 3760.14,
    hnnet: 23178.01
  },
  "1991-06-01": {
    nonprofitassets: 1720802,
    nonprofitmiscellaneousassets: 242953,
    nonprofitmiscellaneous: 157467,
    nonprofitnotes: 28327,
    nonprofitliabilities: 431011,
    householdassets: 25583462,
    householdnet: 22031522,
    householdcheckable: 456699,
    householddeposits: 2547906,
    householdfunds: 443615,
    householdmortgages: 141507,
    householddebt: 1109,
    householdliabilitiesother: 143308,
    householdlending: 202628,
    householdliabilities: 16277805,
    householdborrowing: 3535836,
    householdfinance: 3551940,
    householdself: 3087788,
    hngoods: 2083707,
    householdgoods: 9305657,
    householdnotes: 1223881,
    householdbonds: 237382,
    householdequities: 2223328,
    householdcredits: 1461262,
    hnpension: 5194232,
    householdestate: 7221.95,
    hncredit: 801.306,
    life: 16.104,
    hnmortgate: 2590.113,
    hnlifeinsurance: 406.828,
    hnmiscellaneous: 253.519,
    hncash: 61.121,
    hnassets: 27061.311,
    hnliabilities: 3825.484,
    hnnet: 23235.827
  },
  "1991-09-01": {
    nonprofitassets: 1740318,
    nonprofitmiscellaneousassets: 255317,
    nonprofitmiscellaneous: 163231,
    nonprofitnotes: 28583,
    nonprofitliabilities: 444189,
    householdassets: 25924125,
    householdnet: 22337448,
    householdcheckable: 460999,
    householddeposits: 2526421,
    householdfunds: 439333,
    householdmortgages: 142129,
    householddebt: 907,
    householdliabilitiesother: 153427,
    householdlending: 214763,
    householdliabilities: 16548919,
    householdborrowing: 3570802,
    householdfinance: 3586677,
    householdself: 3071083,
    hngoods: 2103443,
    householdgoods: 9375206,
    householdnotes: 1251329,
    householdbonds: 222614,
    householdequities: 2380400,
    householdcredits: 1473942,
    hnpension: 5314792,
    householdestate: 7271.763,
    hncredit: 802.5,
    life: 15.875,
    hnmortgate: 2613.968,
    hnlifeinsurance: 411.498,
    hnmiscellaneous: 255.688,
    hncash: 72.634,
    hnassets: 27409.126,
    hnliabilities: 3867.635,
    hnnet: 23541.491
  },
  "1991-12-01": {
    nonprofitassets: 1742476,
    nonprofitmiscellaneousassets: 267414,
    nonprofitmiscellaneous: 170557,
    nonprofitnotes: 28839,
    nonprofitliabilities: 457278,
    householdassets: 26596215,
    householdnet: 22931498,
    householdcheckable: 489087,
    householddeposits: 2505733,
    householdfunds: 441898,
    householdmortgages: 140324,
    householddebt: 1762,
    householdliabilitiesother: 164281,
    householdlending: 235785,
    householdliabilities: 17138278,
    householdborrowing: 3648980,
    householdfinance: 3664717,
    householdself: 3049449,
    hngoods: 2117389,
    householdgoods: 9457937,
    householdnotes: 1260799,
    householdbonds: 219941,
    householdequities: 2809928,
    householdcredits: 1480740,
    hnpension: 5447838,
    householdestate: 7340.548,
    hncredit: 815.581,
    life: 15.737,
    hnmortgate: 2667.356,
    hnlifeinsurance: 418.284,
    hnmiscellaneous: 259.535,
    hncash: 95.461,
    hnassets: 28071.277,
    hnliabilities: 3951.438,
    hnnet: 24119.839
  },
  "1992-03-01": {
    nonprofitassets: 1747379,
    nonprofitmiscellaneousassets: 272998,
    nonprofitmiscellaneous: 175625,
    nonprofitnotes: 29269,
    nonprofitliabilities: 466897,
    householdassets: 26666462,
    householdnet: 22985996,
    householdcheckable: 502051,
    householddeposits: 2349611,
    householdfunds: 463251,
    householdmortgages: 138845,
    householddebt: 2069,
    householdliabilitiesother: 161650,
    householdlending: 217299,
    householdliabilities: 17102985,
    householdborrowing: 3664793,
    householdfinance: 3680466,
    householdself: 3044060,
    hngoods: 2132095,
    householdgoods: 9563477,
    householdnotes: 1302373,
    householdbonds: 228629,
    householdequities: 2751960,
    householdcredits: 1531001,
    hnpension: 5555013,
    householdestate: 7431.382,
    hncredit: 793.353,
    life: 15.673,
    hnmortgate: 2707.721,
    hnlifeinsurance: 425.297,
    hnmiscellaneous: 263.441,
    hncash: 78.454,
    hnassets: 28140.843,
    hnliabilities: 3971.738,
    hnnet: 24169.105
  },
  "1992-06-01": {
    nonprofitassets: 1753752,
    nonprofitmiscellaneousassets: 278581,
    nonprofitmiscellaneous: 180536,
    nonprofitnotes: 29699,
    nonprofitliabilities: 476516,
    householdassets: 26821178,
    householdnet: 23101325,
    householdcheckable: 509591,
    householddeposits: 2319080,
    householdfunds: 444281,
    householdmortgages: 137267,
    householddebt: 2653,
    householdliabilitiesother: 168521,
    householdlending: 224167,
    householdliabilities: 17191510,
    householdborrowing: 3704201,
    householdfinance: 3719853,
    householdself: 3033546,
    hngoods: 2153079,
    householdgoods: 9629668,
    householdnotes: 1289653,
    householdbonds: 230187,
    householdequities: 2754588,
    householdcredits: 1519840,
    hnpension: 5688835,
    householdestate: 7476.589,
    hncredit: 792.332,
    life: 15.652,
    hnmortgate: 2740.695,
    hnlifeinsurance: 429.671,
    hnmiscellaneous: 267.91,
    hncash: 86.9,
    hnassets: 28296.349,
    hnliabilities: 4015.833,
    hnnet: 24280.516
  },
  "1992-09-01": {
    nonprofitassets: 1764466,
    nonprofitmiscellaneousassets: 284165,
    nonprofitmiscellaneous: 187057,
    nonprofitnotes: 30129,
    nonprofitliabilities: 486135,
    householdassets: 27231314,
    householdnet: 23446732,
    householdcheckable: 532790,
    householddeposits: 2292519,
    householdfunds: 435381,
    householdmortgages: 135655,
    householddebt: 1572,
    householdliabilitiesother: 170071,
    householdlending: 222504,
    householdliabilities: 17495784,
    householdborrowing: 3768902,
    householdfinance: 3784582,
    householdself: 3033615,
    hngoods: 2171542,
    householdgoods: 9735530,
    householdnotes: 1329736,
    householdbonds: 217190,
    householdequities: 2879809,
    householdcredits: 1546926,
    hnpension: 5840576,
    householdestate: 7563.988,
    hncredit: 801.933,
    life: 15.68,
    hnmortgate: 2795.327,
    hnlifeinsurance: 439.062,
    hnmiscellaneous: 272.602,
    hncash: 86.849,
    hnassets: 28711.615,
    hnliabilities: 4083.66,
    hnnet: 24627.955
  },
  "1992-12-01": {
    nonprofitassets: 1773238,
    nonprofitmiscellaneousassets: 289750,
    nonprofitmiscellaneous: 192740,
    nonprofitnotes: 30559,
    nonprofitliabilities: 495754,
    householdassets: 27901272,
    householdnet: 24047430,
    householdcheckable: 595766,
    householddeposits: 2265403,
    householdfunds: 420263,
    householdmortgages: 133450,
    householddebt: 2288,
    householdliabilitiesother: 170671,
    householdlending: 218998,
    householdliabilities: 18068061,
    householdborrowing: 3838080,
    householdfinance: 3853842,
    householdself: 3028470,
    hngoods: 2193255,
    householdgoods: 9833211,
    householdnotes: 1328777,
    householdbonds: 227423,
    householdequities: 3252708,
    householdcredits: 1556200,
    hnpension: 6006234,
    householdestate: 7639.956,
    hncredit: 824.769,
    life: 15.762,
    hnmortgate: 2840.353,
    hnlifeinsurance: 447.35,
    hnmiscellaneous: 276.669,
    hncash: 85.548,
    hnassets: 29384.76,
    hnliabilities: 4156.856,
    hnnet: 25227.904
  },
  "1993-03-01": {
    nonprofitassets: 1799958,
    nonprofitmiscellaneousassets: 297610,
    nonprofitmiscellaneous: 200414,
    nonprofitnotes: 31291,
    nonprofitliabilities: 505955,
    householdassets: 28222853,
    householdnet: 24363346,
    householdcheckable: 571812,
    householddeposits: 2213488,
    householdfunds: 428567,
    householdmortgages: 131417,
    householddebt: 3898,
    householdliabilitiesother: 178269,
    householdlending: 232477,
    householdliabilities: 18368503,
    householdborrowing: 3843617,
    householdfinance: 3859506,
    householdself: 3074201,
    hngoods: 2206400,
    householdgoods: 9854350,
    householdnotes: 1326877,
    householdbonds: 263510,
    householdequities: 3377043,
    householdcredits: 1590387,
    hnpension: 6144933,
    householdestate: 7647.95,
    hncredit: 807.262,
    life: 15.889,
    hnmortgate: 2854.188,
    hnlifeinsurance: 455.592,
    hnmiscellaneous: 280.003,
    hncash: 101.06,
    hnassets: 29725.201,
    hnliabilities: 4165.047,
    hnnet: 25560.153
  },
  "1993-06-01": {
    nonprofitassets: 1819679,
    nonprofitmiscellaneousassets: 305472,
    nonprofitmiscellaneous: 207572,
    nonprofitnotes: 32023,
    nonprofitliabilities: 516155,
    householdassets: 28543104,
    householdnet: 24618198,
    householdcheckable: 591163,
    householddeposits: 2194153,
    householdfunds: 419304,
    householdmortgages: 129324,
    householddebt: 3605,
    householdliabilitiesother: 178795,
    householdlending: 241849,
    householdliabilities: 18568359,
    householdborrowing: 3908890,
    householdfinance: 3924905,
    householdself: 3100536,
    hngoods: 2236252,
    householdgoods: 9974745,
    householdnotes: 1288378,
    householdbonds: 286712,
    householdequities: 3443953,
    householdcredits: 1575090,
    hnpension: 6254746,
    householdestate: 7738.493,
    hncredit: 818.662,
    life: 16.015,
    hnmortgate: 2907.828,
    hnlifeinsurance: 464.817,
    hnmiscellaneous: 282.748,
    hncash: 112.525,
    hnassets: 30057.311,
    hnliabilities: 4233.488,
    hnnet: 25823.822
  },
  "1993-09-01": {
    nonprofitassets: 1849867,
    nonprofitmiscellaneousassets: 313333,
    nonprofitmiscellaneous: 216064,
    nonprofitnotes: 32755,
    nonprofitliabilities: 526356,
    householdassets: 29006916,
    householdnet: 24996675,
    householdcheckable: 616049,
    householddeposits: 2174554,
    householdfunds: 416064,
    householdmortgages: 127192,
    householddebt: 4254,
    householdliabilitiesother: 189161,
    householdlending: 236850,
    householdliabilities: 18912993,
    householdborrowing: 3994100,
    householdfinance: 4010241,
    householdself: 3137978,
    hngoods: 2265879,
    householdgoods: 10093923,
    householdnotes: 1268494,
    householdbonds: 312019,
    householdequities: 3612190,
    householdcredits: 1580513,
    hnpension: 6377592,
    householdestate: 7828.044,
    hncredit: 842.668,
    life: 16.141,
    hnmortgate: 2958.017,
    hnlifeinsurance: 474.544,
    hnmiscellaneous: 286.658,
    hncash: 109.658,
    hnassets: 30543.45,
    hnliabilities: 4320.533,
    hnnet: 26222.917
  },
  "1993-12-01": {
    nonprofitassets: 1877714,
    nonprofitmiscellaneousassets: 321195,
    nonprofitmiscellaneous: 224550,
    nonprofitnotes: 33487,
    nonprofitliabilities: 536557,
    householdassets: 29524101,
    householdnet: 25421336,
    householdcheckable: 647849,
    householddeposits: 2135461,
    householdfunds: 425283,
    householdmortgages: 125070,
    householddebt: 4366,
    householdliabilitiesother: 196784,
    householdlending: 237381,
    householdliabilities: 19302872,
    householdborrowing: 4086497,
    householdfinance: 4102765,
    householdself: 3182168,
    hngoods: 2302078,
    householdgoods: 10221229,
    householdnotes: 1273315,
    householdbonds: 362628,
    householdequities: 3789952,
    householdcredits: 1635942,
    hnpension: 6475892,
    householdestate: 7919.151,
    hncredit: 886.169,
    life: 16.268,
    hnmortgate: 2999.178,
    hnlifeinsurance: 484.371,
    hnmiscellaneous: 288.574,
    hncash: 112.311,
    hnassets: 31080.62,
    hnliabilities: 4414.772,
    hnnet: 26665.848
  },
  "1994-03-01": {
    nonprofitassets: 1900389,
    nonprofitmiscellaneousassets: 323928,
    nonprofitmiscellaneous: 232943,
    nonprofitnotes: 34549,
    nonprofitliabilities: 546473,
    householdassets: 29694823,
    householdnet: 25560857,
    householdcheckable: 623938,
    householddeposits: 2134897,
    householdfunds: 438784,
    householdmortgages: 123094,
    householddebt: 6123,
    householdliabilitiesother: 198098,
    householdlending: 239887,
    householdliabilities: 19391951,
    householdborrowing: 4117566,
    householdfinance: 4133966,
    householdself: 3248318,
    hngoods: 2326037,
    householdgoods: 10302872,
    householdnotes: 1359127,
    householdbonds: 383019,
    householdequities: 3623588,
    householdcredits: 1742145,
    hnpension: 6558224,
    householdestate: 7976.835,
    hncredit: 883.876,
    life: 16.4,
    hnmortgate: 3029.469,
    hnlifeinsurance: 490.401,
    hnmiscellaneous: 291.768,
    hncash: 116.793,
    hnassets: 31271.284,
    hnliabilities: 4447.496,
    hnnet: 26823.788
  },
  "1994-06-01": {
    nonprofitassets: 1930115,
    nonprofitmiscellaneousassets: 326662,
    nonprofitmiscellaneous: 240896,
    nonprofitnotes: 35611,
    nonprofitliabilities: 556390,
    householdassets: 29929852,
    householdnet: 25712005,
    householdcheckable: 613385,
    householddeposits: 2096762,
    householdfunds: 432858,
    householdmortgages: 120986,
    householddebt: 8202,
    householdliabilitiesother: 199910,
    householdlending: 236018,
    householdliabilities: 19509289,
    householdborrowing: 4201314,
    householdfinance: 4217847,
    householdself: 3311081,
    hngoods: 2359548,
    householdgoods: 10420563,
    householdnotes: 1418423,
    householdbonds: 407145,
    householdequities: 3544204,
    householdcredits: 1825568,
    hnpension: 6658386,
    householdestate: 8061.015,
    hncredit: 918.58,
    life: 16.533,
    hnmortgate: 3074.622,
    hnlifeinsurance: 496.976,
    hnmiscellaneous: 294.052,
    hncash: 115.032,
    hnassets: 31533.305,
    hnliabilities: 4533.341,
    hnnet: 26999.964
  },
  "1994-09-01": {
    nonprofitassets: 1968044,
    nonprofitmiscellaneousassets: 329395,
    nonprofitmiscellaneous: 249486,
    nonprofitnotes: 36673,
    nonprofitliabilities: 566309,
    householdassets: 30350430,
    householdnet: 26044375,
    householdcheckable: 602566,
    householddeposits: 2117261,
    householdfunds: 442705,
    householdmortgages: 118853,
    householddebt: 8785,
    householdliabilitiesother: 199603,
    householdlending: 234273,
    householdliabilities: 19815974,
    householdborrowing: 4289390,
    householdfinance: 4306055,
    householdself: 3351100,
    hngoods: 2396499,
    householdgoods: 10534456,
    householdnotes: 1439660,
    householdbonds: 416628,
    householdequities: 3613217,
    householdcredits: 1856288,
    hnpension: 6785910,
    householdestate: 8137.957,
    hncredit: 959.953,
    life: 16.665,
    hnmortgate: 3121.049,
    hnlifeinsurance: 514.31,
    hnmiscellaneous: 298.343,
    hncash: 115.42,
    hnassets: 31989.079,
    hnliabilities: 4622.878,
    hnnet: 27366.201
  },
  "1994-12-01": {
    nonprofitassets: 1995784,
    nonprofitmiscellaneousassets: 332129,
    nonprofitmiscellaneous: 257596,
    nonprofitnotes: 37735,
    nonprofitliabilities: 576220,
    householdassets: 30660798,
    householdnet: 26235729,
    householdcheckable: 616916,
    householddeposits: 2101777,
    householdfunds: 463146,
    householdmortgages: 116345,
    householddebt: 11128,
    householdliabilitiesother: 210036,
    householdlending: 232090,
    householdliabilities: 20040036,
    householdborrowing: 4408272,
    householdfinance: 4425069,
    householdself: 3406836,
    hngoods: 2424163,
    householdgoods: 10620762,
    householdnotes: 1528886,
    householdbonds: 454893,
    householdequities: 3573910,
    householdcredits: 1983779,
    hnpension: 6840261,
    householdestate: 8196.599,
    hncredit: 1021.168,
    life: 16.797,
    hnmortgate: 3165.94,
    hnlifeinsurance: 519.806,
    hnmiscellaneous: 301.515,
    hncash: 115.745,
    hnassets: 32324.453,
    hnliabilities: 4743.693,
    hnnet: 27580.76
  },
  "1995-03-01": {
    nonprofitassets: 2062870,
    nonprofitmiscellaneousassets: 350351,
    nonprofitmiscellaneous: 270003,
    nonprofitnotes: 39064,
    nonprofitliabilities: 591483,
    householdassets: 31205982,
    householdnet: 26752848,
    householdcheckable: 596264,
    householddeposits: 2148023,
    householdfunds: 483017,
    householdmortgages: 114271,
    householddebt: 12471,
    householdliabilitiesother: 207215,
    householdlending: 235229,
    householdliabilities: 20499277,
    householdborrowing: 4436158,
    householdfinance: 4453135,
    householdself: 3449800,
    hngoods: 2460699,
    householdgoods: 10706705,
    householdnotes: 1481377,
    householdbonds: 468880,
    householdequities: 3799015,
    householdcredits: 1950257,
    hnpension: 6997471,
    householdestate: 8246.006,
    hncredit: 1024.546,
    life: 16.977,
    hnmortgate: 3191.926,
    hnlifeinsurance: 532.123,
    hnmiscellaneous: 308.078,
    hncash: 120.958,
    hnassets: 32918.501,
    hnliabilities: 4774.615,
    hnnet: 28143.887
  },
  "1995-06-01": {
    nonprofitassets: 2128151,
    nonprofitmiscellaneousassets: 367822,
    nonprofitmiscellaneous: 282299,
    nonprofitnotes: 40393,
    nonprofitliabilities: 606746,
    householdassets: 31820654,
    householdnet: 27277590,
    householdcheckable: 600223,
    householddeposits: 2186960,
    householdfunds: 511172,
    householdmortgages: 112161,
    householddebt: 15157,
    householdliabilitiesother: 209323,
    householdlending: 235388,
    householdliabilities: 21014054,
    householdborrowing: 4525908,
    householdfinance: 4543064,
    householdself: 3485003,
    hngoods: 2484319,
    householdgoods: 10806600,
    householdnotes: 1454712,
    householdbonds: 458994,
    householdequities: 4047040,
    householdcredits: 1913707,
    hnpension: 7172590,
    householdestate: 8322.281,
    hncredit: 1063.126,
    life: 17.156,
    hnmortgate: 3238.302,
    hnlifeinsurance: 547.402,
    hnmiscellaneous: 314.568,
    hncash: 123.227,
    hnassets: 33580.983,
    hnliabilities: 4867.511,
    hnnet: 28713.472
  },
  "1995-09-01": {
    nonprofitassets: 2199009,
    nonprofitmiscellaneousassets: 385293,
    nonprofitmiscellaneous: 295723,
    nonprofitnotes: 41722,
    nonprofitliabilities: 622009,
    householdassets: 32519476,
    householdnet: 27863842,
    householdcheckable: 565848,
    householddeposits: 2214986,
    householdfunds: 523966,
    householdmortgages: 110053,
    householddebt: 17694,
    householdliabilitiesother: 221235,
    householdlending: 235566,
    householdliabilities: 21601350,
    householdborrowing: 4638299,
    householdfinance: 4655635,
    householdself: 3535535,
    hngoods: 2504989,
    householdgoods: 10918126,
    householdnotes: 1456803,
    householdbonds: 489031,
    householdequities: 4353929,
    householdcredits: 1945834,
    hnpension: 7355913,
    householdestate: 8413.137,
    hncredit: 1109.599,
    life: 17.336,
    hnmortgate: 3289.771,
    hnlifeinsurance: 556.073,
    hnmiscellaneous: 313.701,
    hncash: 125.513,
    hnassets: 34333.192,
    hnliabilities: 4981.921,
    hnnet: 29351.272
  },
  "1995-12-01": {
    nonprofitassets: 2262703,
    nonprofitmiscellaneousassets: 402764,
    nonprofitmiscellaneous: 308513,
    nonprofitnotes: 42918,
    nonprofitliabilities: 637272,
    householdassets: 33294947,
    householdnet: 28546210,
    householdcheckable: 567798,
    householddeposits: 2234190,
    householdfunds: 549742,
    householdmortgages: 108233,
    householddebt: 24427,
    householdliabilitiesother: 218710,
    householdlending: 253623,
    householdliabilities: 22272827,
    householdborrowing: 4731222,
    householdfinance: 4748737,
    householdself: 3586731,
    hngoods: 2529254,
    householdgoods: 11022120,
    householdnotes: 1445843,
    householdbonds: 546528,
    householdequities: 4692238,
    householdcredits: 1992371,
    hnpension: 7513624,
    householdestate: 8492.866,
    hncredit: 1168.16,
    life: 17.515,
    hnmortgate: 3319.925,
    hnlifeinsurance: 565.522,
    hnmiscellaneous: 316.987,
    hncash: 145.39,
    hnassets: 35155.019,
    hnliabilities: 5077.496,
    hnnet: 30077.523
  },
  "1996-03-01": {
    nonprofitassets: 2322007,
    nonprofitmiscellaneousassets: 414538,
    nonprofitmiscellaneous: 319589,
    nonprofitnotes: 44168,
    nonprofitliabilities: 652958,
    householdassets: 33913622,
    householdnet: 29098021,
    householdcheckable: 553382,
    householddeposits: 2305601,
    householdfunds: 601519,
    householdmortgages: 106300,
    householddebt: 17209,
    householdliabilitiesother: 240167,
    householdlending: 262298,
    householdliabilities: 22758452,
    householdborrowing: 4797888,
    householdfinance: 4815601,
    householdself: 3614143,
    hngoods: 2562423,
    householdgoods: 11155170,
    householdnotes: 1424364,
    householdbonds: 556833,
    householdequities: 4889063,
    householdcredits: 1981197,
    hnpension: 7662701,
    householdestate: 8592.747,
    hncredit: 1162.207,
    life: 17.713,
    hnmortgate: 3378.306,
    hnlifeinsurance: 570.593,
    hnmiscellaneous: 317.955,
    hncash: 155.998,
    hnassets: 35821.21,
    hnliabilities: 5148.97,
    hnnet: 30672.24
  },
  "1996-06-01": {
    nonprofitassets: 2369297,
    nonprofitmiscellaneousassets: 426492,
    nonprofitmiscellaneous: 330330,
    nonprofitnotes: 45419,
    nonprofitliabilities: 668644,
    householdassets: 34403519,
    householdnet: 29491908,
    householdcheckable: 534467,
    householddeposits: 2322031,
    householdfunds: 590535,
    householdmortgages: 104266,
    householddebt: 23546,
    householdliabilitiesother: 241915,
    householdlending: 257485,
    householdliabilities: 23133140,
    householdborrowing: 4893701,
    householdfinance: 4911612,
    householdself: 3618108,
    hngoods: 2578831,
    householdgoods: 11270379,
    householdnotes: 1471710,
    householdbonds: 583655,
    householdequities: 5071835,
    householdcredits: 2055365,
    hnpension: 7785211,
    householdestate: 8691.548,
    hncredit: 1196.579,
    life: 17.911,
    hnmortgate: 3431.661,
    hnlifeinsurance: 579.028,
    hnmiscellaneous: 319.076,
    hncash: 153.219,
    hnassets: 36346.428,
    hnliabilities: 5249.926,
    hnnet: 31096.503
  },
  "1996-09-01": {
    nonprofitassets: 2418573,
    nonprofitmiscellaneousassets: 438448,
    nonprofitmiscellaneous: 341500,
    nonprofitnotes: 46670,
    nonprofitliabilities: 684331,
    householdassets: 34782466,
    householdnet: 29776383,
    householdcheckable: 516582,
    householddeposits: 2357838,
    householdfunds: 611722,
    householdmortgages: 102188,
    householddebt: 26079,
    householdliabilitiesother: 243211,
    householdlending: 254600,
    householdliabilities: 23383494,
    householdborrowing: 4987975,
    householdfinance: 5006083,
    householdself: 3646705,
    hngoods: 2605219,
    householdgoods: 11398972,
    householdnotes: 1506693,
    householdbonds: 560982,
    householdequities: 5093444,
    householdcredits: 2067675,
    hnpension: 7918686,
    householdestate: 8793.753,
    hncredit: 1229.847,
    life: 18.108,
    hnmortgate: 3488.838,
    hnlifeinsurance: 593.814,
    hnmiscellaneous: 322.427,
    hncash: 152.412,
    hnassets: 36762.68,
    hnliabilities: 5348.914,
    hnnet: 31413.766
  },
  "1996-12-01": {
    nonprofitassets: 2466539,
    nonprofitmiscellaneousassets: 430706,
    nonprofitmiscellaneous: 350826,
    nonprofitnotes: 47995,
    nonprofitliabilities: 698498,
    householdassets: 35544500,
    householdnet: 30441844,
    householdcheckable: 506393,
    householddeposits: 2362768,
    householdfunds: 641564,
    householdmortgages: 100119,
    householddebt: 20359,
    householdliabilitiesother: 251982,
    householdlending: 290056,
    householdliabilities: 24031408,
    householdborrowing: 5084350,
    householdfinance: 5102656,
    householdself: 3712739,
    hngoods: 2627993,
    householdgoods: 11513092,
    householdnotes: 1576463,
    householdbonds: 539372,
    householdequities: 5325462,
    householdcredits: 2115834,
    hnpension: 8141805,
    householdestate: 8885.099,
    hncredit: 1273.878,
    life: 18.306,
    hnmortgate: 3538.132,
    hnlifeinsurance: 609.828,
    hnmiscellaneous: 324.96,
    hncash: 189.937,
    hnassets: 37581.585,
    hnliabilities: 5450.328,
    hnnet: 32131.257
  },
  "1997-03-01": {
    nonprofitassets: 2589667,
    nonprofitmiscellaneousassets: 450446,
    nonprofitmiscellaneous: 362382,
    nonprofitnotes: 49231,
    nonprofitliabilities: 714614,
    householdassets: 35860211,
    householdnet: 30719633,
    householdcheckable: 478935,
    householddeposits: 2405790,
    householdfunds: 682240,
    householdmortgages: 98098,
    householddebt: 28447,
    householdliabilitiesother: 259094,
    householdlending: 279360,
    householdliabilities: 24235949,
    householdborrowing: 5122188,
    householdfinance: 5140578,
    householdself: 3846669,
    hngoods: 2657101,
    householdgoods: 11624262,
    householdnotes: 1523371,
    householdbonds: 463281,
    householdequities: 5370880,
    householdcredits: 1986651,
    hnpension: 8235356,
    householdestate: 8967.161,
    hncredit: 1254.175,
    life: 18.39,
    hnmortgate: 3580.473,
    hnlifeinsurance: 621.756,
    hnmiscellaneous: 328.311,
    hncash: 181.262,
    hnassets: 38000.704,
    hnliabilities: 5492.81,
    hnnet: 32507.894
  },
  "1997-06-01": {
    nonprofitassets: 2681822,
    nonprofitmiscellaneousassets: 470186,
    nonprofitmiscellaneous: 372015,
    nonprofitnotes: 50467,
    nonprofitliabilities: 730730,
    householdassets: 37227963,
    householdnet: 31996982,
    householdcheckable: 473268,
    householddeposits: 2424678,
    householdfunds: 674044,
    householdmortgages: 96255,
    householddebt: 32166,
    householdliabilitiesother: 270380,
    householdlending: 295324,
    householdliabilities: 25471847,
    householdborrowing: 5212508,
    householdfinance: 5230981,
    householdself: 3891411,
    hngoods: 2667040,
    householdgoods: 11756116,
    householdnotes: 1513921,
    householdbonds: 480797,
    householdequities: 6184851,
    householdcredits: 1994718,
    hnpension: 8562468,
    householdestate: 9089.076,
    hncredit: 1278.038,
    life: 18.473,
    hnmortgate: 3631.924,
    hnlifeinsurance: 639.332,
    hnmiscellaneous: 331.753,
    hncash: 199.069,
    hnassets: 39440.891,
    hnliabilities: 5589.696,
    hnnet: 33851.195
  },
  "1997-09-01": {
    nonprofitassets: 2783676,
    nonprofitmiscellaneousassets: 489926,
    nonprofitmiscellaneous: 383939,
    nonprofitnotes: 51703,
    nonprofitliabilities: 746846,
    householdassets: 38292503,
    householdnet: 32944219,
    householdcheckable: 438946,
    householddeposits: 2482893,
    householdfunds: 702007,
    householdmortgages: 94515,
    householddebt: 18534,
    householdliabilitiesother: 288382,
    householdlending: 296469,
    householdliabilities: 26399961,
    householdborrowing: 5329728,
    householdfinance: 5348285,
    householdself: 3964934,
    hngoods: 2690060,
    householdgoods: 11892542,
    householdnotes: 1512060,
    householdbonds: 503651,
    householdequities: 6666423,
    householdcredits: 2015711,
    hnpension: 8838969,
    householdestate: 9202.482,
    hncredit: 1305.805,
    life: 18.557,
    hnmortgate: 3717.007,
    hnlifeinsurance: 656.347,
    hnmiscellaneous: 337.262,
    hncash: 201.954,
    hnassets: 40587.565,
    hnliabilities: 5711.192,
    hnnet: 34876.374
  },
  "1997-12-01": {
    nonprofitassets: 2886949,
    nonprofitmiscellaneousassets: 509666,
    nonprofitmiscellaneous: 394251,
    nonprofitnotes: 52938,
    nonprofitliabilities: 762962,
    householdassets: 38918456,
    householdnet: 33479062,
    householdcheckable: 467691,
    householddeposits: 2496623,
    householdfunds: 732173,
    householdmortgages: 92406,
    householddebt: 30384,
    householdliabilitiesother: 291966,
    householdlending: 312228,
    householdliabilities: 26870523,
    householdborrowing: 5420752,
    householdfinance: 5439393,
    householdself: 4079212,
    hngoods: 2718027,
    householdgoods: 12047933,
    householdnotes: 1555756,
    householdbonds: 505011,
    householdequities: 6812876,
    householdcredits: 2060767,
    hnpension: 8907665,
    householdestate: 9329.906,
    hncredit: 1344.165,
    life: 18.641,
    hnmortgate: 3754.238,
    hnlifeinsurance: 664.058,
    hnmiscellaneous: 337.229,
    hncash: 219.822,
    hnassets: 41297.072,
    hnliabilities: 5808.104,
    hnnet: 35488.967
  },
  "1998-03-01": {
    nonprofitassets: 2970071,
    nonprofitmiscellaneousassets: 503933,
    nonprofitmiscellaneous: 346365,
    nonprofitnotes: 53931,
    nonprofitliabilities: 724142,
    householdassets: 40640773,
    householdnet: 35153511,
    householdcheckable: 431946,
    householddeposits: 2523502,
    householdfunds: 791991,
    householdmortgages: 90155,
    householddebt: 33328,
    householdliabilitiesother: 301794,
    householdlending: 335441,
    householdliabilities: 28390937,
    householdborrowing: 5468655,
    householdfinance: 5487262,
    householdself: 4206072,
    hngoods: 2740136,
    householdgoods: 12249836,
    householdnotes: 1525885,
    householdbonds: 545975,
    householdequities: 7739822,
    householdcredits: 2071860,
    hnpension: 9274601,
    householdestate: 9509.7,
    hncredit: 1324.043,
    life: 18.607,
    hnmortgate: 3809.49,
    hnlifeinsurance: 676.162,
    hnmiscellaneous: 339.541,
    hncash: 245.286,
    hnassets: 43108.256,
    hnliabilities: 5865.039,
    hnnet: 37243.217
  },
  "1998-06-01": {
    nonprofitassets: 2960213,
    nonprofitmiscellaneousassets: 498200,
    nonprofitmiscellaneous: 298540,
    nonprofitnotes: 54924,
    nonprofitliabilities: 685322,
    householdassets: 41368967,
    householdnet: 35750311,
    householdcheckable: 436231,
    householddeposits: 2513360,
    householdfunds: 805921,
    householdmortgages: 87839,
    householddebt: 30537,
    householdliabilitiesother: 313345,
    householdlending: 347759,
    householdliabilities: 28858550,
    householdborrowing: 5599764,
    householdfinance: 5618656,
    householdself: 4210355,
    hngoods: 2767755,
    householdgoods: 12510417,
    householdnotes: 1584232,
    householdbonds: 610111,
    householdequities: 7902079,
    householdcredits: 2194343,
    hnpension: 9422029,
    householdestate: 9742.662,
    hncredit: 1367.744,
    life: 18.892,
    hnmortgate: 3888.138,
    hnlifeinsurance: 686.806,
    hnmiscellaneous: 339.667,
    hncash: 259.92,
    hnassets: 43832.337,
    hnliabilities: 6005.438,
    hnnet: 37826.899
  },
  "1998-09-01": {
    nonprofitassets: 2957944,
    nonprofitmiscellaneousassets: 492467,
    nonprofitmiscellaneous: 253529,
    nonprofitnotes: 55917,
    nonprofitliabilities: 646502,
    householdassets: 40905124,
    householdnet: 35175827,
    householdcheckable: 424785,
    householddeposits: 2527038,
    householdfunds: 875662,
    householdmortgages: 85654,
    householddebt: 33773,
    householdliabilitiesother: 311587,
    householdlending: 384795,
    householdliabilities: 28139097,
    householdborrowing: 5710918,
    householdfinance: 5729297,
    householdself: 4292634,
    hngoods: 2796770,
    householdgoods: 12766027,
    householdnotes: 1677636,
    householdbonds: 593861,
    householdequities: 7029990,
    householdcredits: 2271497,
    hnpension: 9301580,
    householdestate: 9969.257,
    hncredit: 1400.718,
    life: 18.379,
    hnmortgate: 3964.84,
    hnlifeinsurance: 688.394,
    hnmiscellaneous: 342.721,
    hncash: 299.141,
    hnassets: 43371.97,
    hnliabilities: 6122.27,
    hnnet: 37249.7
  },
  "1998-12-01": {
    nonprofitassets: 3018205,
    nonprofitmiscellaneousassets: 486734,
    nonprofitmiscellaneous: 207001,
    nonprofitnotes: 56910,
    nonprofitliabilities: 607682,
    householdassets: 43051046,
    householdnet: 37182144,
    householdcheckable: 482937,
    householddeposits: 2574902,
    householdfunds: 933594,
    householdmortgages: 87235,
    householddebt: 39347,
    householdliabilitiesother: 313614,
    householdlending: 379574,
    householdliabilities: 30003932,
    householdborrowing: 5851675,
    householdfinance: 5868902,
    householdself: 4333716,
    hngoods: 2833799,
    householdgoods: 13047114,
    householdnotes: 1623535,
    householdbonds: 540682,
    householdequities: 8284237,
    householdcredits: 2164217,
    hnpension: 9790792,
    householdestate: 10213.315,
    hncredit: 1441.272,
    life: 17.227,
    hnmortgate: 4057.442,
    hnlifeinsurance: 716.983,
    hnmiscellaneous: 342.98,
    hncash: 292.339,
    hnassets: 45583.898,
    hnliabilities: 6269.583,
    hnnet: 39314.315
  },
  "1999-03-01": {
    nonprofitassets: 3050061,
    nonprofitmiscellaneousassets: 498695,
    nonprofitmiscellaneous: 211690,
    nonprofitnotes: 58934,
    nonprofitliabilities: 616859,
    householdassets: 43444796,
    householdnet: 37491717,
    householdcheckable: 470494,
    householddeposits: 2572614,
    householdfunds: 991007,
    householdmortgages: 89014,
    householddebt: 36893,
    householdliabilitiesother: 318588,
    householdlending: 376675,
    householdliabilities: 30161321,
    householdborrowing: 5934555,
    householdfinance: 5953078,
    householdself: 4329937,
    hngoods: 2861307,
    householdgoods: 13283475,
    householdnotes: 1555724,
    householdbonds: 573896,
    householdequities: 8247549,
    householdcredits: 2129620,
    hnpension: 9970504,
    householdestate: 10422.168,
    hncredit: 1442.398,
    life: 18.523,
    hnmortgate: 4136.676,
    hnlifeinsurance: 729.587,
    hnmiscellaneous: 343.333,
    hncash: 287.661,
    hnassets: 45997.64,
    hnliabilities: 6358.247,
    hnnet: 39639.392
  },
  "1999-06-01": {
    nonprofitassets: 3091326,
    nonprofitmiscellaneousassets: 510656,
    nonprofitmiscellaneous: 215579,
    nonprofitnotes: 60957,
    nonprofitliabilities: 626036,
    householdassets: 44607456,
    householdnet: 38503295,
    householdcheckable: 441655,
    householddeposits: 2572212,
    householdfunds: 971426,
    householdmortgages: 90443,
    householddebt: 38780,
    householdliabilitiesother: 341997,
    householdlending: 378986,
    householdliabilities: 31029007,
    householdborrowing: 6085557,
    householdfinance: 6104161,
    householdself: 4359571,
    hngoods: 2904870,
    householdgoods: 13578449,
    householdnotes: 1514422,
    householdbonds: 544932,
    householdequities: 8919065,
    householdcredits: 2059354,
    hnpension: 10233818,
    householdestate: 10673.579,
    hncredit: 1471.581,
    life: 18.604,
    hnmortgate: 4233.199,
    hnlifeinsurance: 748.612,
    hnmiscellaneous: 344.309,
    hncash: 288.543,
    hnassets: 47189.702,
    hnliabilities: 6514.618,
    hnnet: 40675.084
  },
  "1999-09-01": {
    nonprofitassets: 3161120,
    nonprofitmiscellaneousassets: 522617,
    nonprofitmiscellaneous: 219586,
    nonprofitnotes: 62978,
    nonprofitliabilities: 635213,
    householdassets: 44673442,
    householdnet: 38412709,
    householdcheckable: 425213,
    householddeposits: 2614076,
    householdfunds: 1004340,
    householdmortgages: 92184,
    householddebt: 32988,
    householdliabilitiesother: 345981,
    householdlending: 399448,
    householdliabilities: 30768051,
    householdborrowing: 6242088,
    householdfinance: 6260733,
    householdself: 4507640,
    hngoods: 2950733,
    householdgoods: 13905391,
    householdnotes: 1478341,
    householdbonds: 529173,
    householdequities: 8494335,
    householdcredits: 2007514,
    hnpension: 10213581,
    householdestate: 10954.658,
    hncredit: 1511.895,
    life: 18.645,
    hnmortgate: 4351.224,
    hnlifeinsurance: 751.383,
    hnmiscellaneous: 350.521,
    hncash: 307.264,
    hnassets: 47313.621,
    hnliabilities: 6676.36,
    hnnet: 40637.261
  },
  "1999-12-01": {
    nonprofitassets: 3275510,
    nonprofitmiscellaneousassets: 534578,
    nonprofitmiscellaneous: 223428,
    nonprofitnotes: 65000,
    nonprofitliabilities: 644390,
    householdassets: 47354901,
    householdnet: 40926802,
    householdcheckable: 459290,
    householddeposits: 2646979,
    householdfunds: 1099612,
    householdmortgages: 93715,
    householddebt: 31677,
    householdliabilitiesother: 388890,
    householdlending: 443376,
    householdliabilities: 33147893,
    householdborrowing: 6408664,
    householdfinance: 6428099,
    householdself: 4593536,
    hngoods: 2991121,
    householdgoods: 14207008,
    householdnotes: 1522752,
    householdbonds: 319878,
    householdequities: 10164090,
    householdcredits: 1842630,
    hnpension: 10681406,
    householdestate: 11215.887,
    hncredit: 1553.622,
    life: 19.435,
    hnmortgate: 4434.475,
    hnlifeinsurance: 802.712,
    hnmiscellaneous: 414.262,
    hncash: 349.661,
    hnassets: 50097.608,
    hnliabilities: 6849.061,
    hnnet: 43248.547
  },
  "2000-03-01": {
    nonprofitassets: 3297885,
    nonprofitmiscellaneousassets: 543716,
    nonprofitmiscellaneous: 234716,
    nonprofitnotes: 66910,
    nonprofitliabilities: 661499,
    householdassets: 48687466,
    householdnet: 42136269,
    householdcheckable: 421581,
    householddeposits: 2738779,
    householdfunds: 1164107,
    householdmortgages: 93811,
    householddebt: 33324,
    householdliabilitiesother: 448675,
    householdlending: 501984,
    householdliabilities: 34017656,
    householdborrowing: 6532495,
    householdfinance: 6551197,
    householdself: 4613443,
    hngoods: 3047928,
    householdgoods: 14669810,
    householdnotes: 1451417,
    householdbonds: 255623,
    householdequities: 10702927,
    householdcredits: 1707040,
    hnpension: 10927434,
    householdestate: 11621.882,
    hncredit: 1553.765,
    life: 18.702,
    hnmortgate: 4496.731,
    hnlifeinsurance: 820.796,
    hnmiscellaneous: 419.565,
    hncash: 408.173,
    hnassets: 51443.438,
    hnliabilities: 6977.98,
    hnnet: 44465.458
  },
  "2000-06-01": {
    nonprofitassets: 3309436,
    nonprofitmiscellaneousassets: 552854,
    nonprofitmiscellaneous: 243379,
    nonprofitnotes: 68820,
    nonprofitliabilities: 678608,
    householdassets: 48600476,
    householdnet: 41907269,
    householdcheckable: 383920,
    householddeposits: 2779241,
    householdfunds: 1136745,
    householdmortgages: 93873,
    householddebt: 29758,
    householdliabilitiesother: 433677,
    householdlending: 486170,
    householdliabilities: 33447844,
    householdborrowing: 6674311,
    householdfinance: 6693207,
    householdself: 4647433,
    hngoods: 3104712,
    householdgoods: 15152632,
    householdnotes: 1393939,
    householdbonds: 279526,
    householdequities: 10122097,
    householdcredits: 1673465,
    hnpension: 10967400,
    householdestate: 12047.92,
    hncredit: 1601.771,
    life: 18.896,
    hnmortgate: 4609.105,
    hnlifeinsurance: 824.415,
    hnmiscellaneous: 426.958,
    hncash: 392.297,
    hnassets: 51358.889,
    hnliabilities: 7128.436,
    hnnet: 44230.453
  },
  "2000-09-01": {
    nonprofitassets: 3444913,
    nonprofitmiscellaneousassets: 561992,
    nonprofitmiscellaneous: 255029,
    nonprofitnotes: 70730,
    nonprofitliabilities: 695717,
    householdassets: 49448602,
    householdnet: 42578274,
    householdcheckable: 344710,
    householddeposits: 2817201,
    householdfunds: 1184947,
    householdmortgages: 93884,
    householddebt: 23704,
    householdliabilitiesother: 438991,
    householdlending: 505930,
    householdliabilities: 33861762,
    householdborrowing: 6851579,
    householdfinance: 6870329,
    householdself: 4863289,
    hngoods: 3148184,
    householdgoods: 15586840,
    householdnotes: 1355247,
    householdbonds: 320381,
    householdequities: 10085439,
    householdcredits: 1675627,
    hnpension: 11113294,
    householdestate: 12438.656,
    hncredit: 1667.073,
    life: 18.75,
    hnmortgate: 4721.811,
    hnlifeinsurance: 836.446,
    hnmiscellaneous: 434.879,
    hncash: 412.046,
    hnassets: 52333.382,
    hnliabilities: 7311.017,
    hnnet: 45022.366
  },
  "2000-12-01": {
    nonprofitassets: 3487306,
    nonprofitmiscellaneousassets: 571130,
    nonprofitmiscellaneous: 265921,
    nonprofitnotes: 72627,
    nonprofitliabilities: 712826,
    householdassets: 49092921,
    householdnet: 42084618,
    householdcheckable: 352717,
    householddeposits: 2897970,
    householdfunds: 1271718,
    householdmortgages: 93909,
    householddebt: 28699,
    householdliabilitiesother: 401993,
    householdlending: 554647,
    householdliabilities: 33039137,
    householdborrowing: 6988743,
    householdfinance: 7008303,
    householdself: 4987508,
    hngoods: 3201757,
    householdgoods: 16053784,
    householdnotes: 1481086,
    householdbonds: 331727,
    householdequities: 8855384,
    householdcredits: 1812812,
    hnpension: 11030931,
    householdestate: 12852.027,
    hncredit: 1741.267,
    life: 19.56,
    hnmortgate: 4816.784,
    hnlifeinsurance: 838.831,
    hnmiscellaneous: 436.617,
    hncash: 460.738,
    hnassets: 52010.997,
    hnliabilities: 7455.208,
    hnnet: 44555.789
  },
  "2001-03-01": {
    nonprofitassets: 3467836,
    nonprofitmiscellaneousassets: 575341,
    nonprofitmiscellaneous: 284861,
    nonprofitnotes: 73993,
    nonprofitliabilities: 735856,
    householdassets: 49069079,
    householdnet: 42017431,
    householdcheckable: 374465,
    householddeposits: 3058504,
    householdfunds: 1402976,
    householdmortgages: 95534,
    householddebt: 17047,
    householdliabilitiesother: 364794,
    householdlending: 543795,
    householdliabilities: 32249347,
    householdborrowing: 7032925,
    householdfinance: 7051648,
    householdself: 5060923,
    hngoods: 3246733,
    householdgoods: 16819732,
    householdnotes: 1329572,
    householdbonds: 421409,
    householdequities: 7900361,
    householdcredits: 1750980,
    hnpension: 10861690,
    householdestate: 13572.999,
    hncredit: 1749.724,
    life: 18.723,
    hnmortgate: 4901.36,
    hnlifeinsurance: 853.23,
    hnmiscellaneous: 442.422,
    hncash: 448.261,
    hnassets: 51963.687,
    hnliabilities: 7502.643,
    hnnet: 44461.044
  },
  "2001-06-01": {
    nonprofitassets: 3496087,
    nonprofitmiscellaneousassets: 579552,
    nonprofitmiscellaneous: 301288,
    nonprofitnotes: 75316,
    nonprofitliabilities: 758886,
    householdassets: 50461207,
    householdnet: 43219498,
    householdcheckable: 349620,
    householddeposits: 3109881,
    householdfunds: 1399774,
    householdmortgages: 97023,
    householddebt: 10733,
    householdliabilitiesother: 366065,
    householdlending: 551109,
    householdliabilities: 33245796,
    householdborrowing: 7223248,
    householdfinance: 7241709,
    householdself: 5121908,
    hngoods: 3278779,
    householdgoods: 17215411,
    householdnotes: 1253759,
    householdbonds: 434266,
    householdequities: 8559695,
    householdcredits: 1688024,
    hnpension: 11122594,
    householdestate: 13936.632,
    hncredit: 1784.249,
    life: 18.461,
    hnmortgate: 5062.201,
    hnlifeinsurance: 884.115,
    hnmiscellaneous: 459.075,
    hncash: 454.086,
    hnassets: 53380.111,
    hnliabilities: 7699.307,
    hnnet: 45680.804
  },
  "2001-09-01": {
    nonprofitassets: 3485719,
    nonprofitmiscellaneousassets: 583763,
    nonprofitmiscellaneous: 319245,
    nonprofitnotes: 76757,
    nonprofitliabilities: 781916,
    householdassets: 49700505,
    householdnet: 42207869,
    householdcheckable: 346370,
    householddeposits: 3175756,
    householdfunds: 1464510,
    householdmortgages: 98568,
    householddebt: 17729,
    householdliabilitiesother: 430791,
    householdlending: 644177,
    householdliabilities: 32085894,
    householdborrowing: 7473372,
    householdfinance: 7492636,
    householdself: 5189789,
    hngoods: 3311313,
    householdgoods: 17614611,
    householdnotes: 1327428,
    householdbonds: 455915,
    householdequities: 7182876,
    householdcredits: 1783342,
    hnpension: 10946202,
    householdestate: 14303.298,
    hncredit: 1814.351,
    life: 19.264,
    hnmortgate: 5210.501,
    hnlifeinsurance: 873.233,
    hnmiscellaneous: 479.639,
    hncash: 545.609,
    hnassets: 52604.968,
    hnliabilities: 7955.307,
    hnnet: 44649.661
  },
  "2001-12-01": {
    nonprofitassets: 3489447,
    nonprofitmiscellaneousassets: 587974,
    nonprofitmiscellaneous: 332474,
    nonprofitnotes: 78236,
    nonprofitliabilities: 804946,
    householdassets: 51372061,
    householdnet: 43762220,
    householdcheckable: 423919,
    householddeposits: 3177458,
    householdfunds: 1538276,
    householdmortgages: 99861,
    householddebt: 3759,
    householdliabilitiesother: 370203,
    householdlending: 602007,
    householdliabilities: 33352485,
    householdborrowing: 7590724,
    householdfinance: 7609841,
    householdself: 5192715,
    hngoods: 3364260,
    householdgoods: 18019576,
    householdnotes: 1168356,
    householdbonds: 503090,
    householdequities: 8114621,
    householdcredits: 1671446,
    hnpension: 11241123,
    householdestate: 14655.316,
    hncredit: 1891.827,
    life: 19.117,
    hnmortgate: 5324.935,
    hnlifeinsurance: 903.747,
    hnmiscellaneous: 487.174,
    hncash: 502.146,
    hnassets: 54276.141,
    hnliabilities: 8082.313,
    hnnet: 46193.828
  },
  "2002-03-01": {
    nonprofitassets: 3523542,
    nonprofitmiscellaneousassets: 590347,
    nonprofitmiscellaneous: 345587,
    nonprofitnotes: 82654,
    nonprofitliabilities: 827556,
    householdassets: 52109227,
    householdnet: 44373932,
    householdcheckable: 362599,
    householddeposits: 3334637,
    householdfunds: 1507844,
    householdmortgages: 101645,
    householddebt: 1086,
    householdliabilitiesother: 368848,
    householdlending: 589073,
    householdliabilities: 33674686,
    householdborrowing: 7715858,
    householdfinance: 7735294,
    householdself: 5222831,
    hngoods: 3398272,
    householdgoods: 18434541,
    householdnotes: 1144429,
    householdbonds: 500568,
    householdequities: 8144656,
    householdcredits: 1644997,
    hnpension: 11444040,
    householdestate: 15036.269,
    hncredit: 1883.516,
    life: 19.436,
    hnmortgate: 5462.408,
    hnlifeinsurance: 929.528,
    hnmiscellaneous: 494.48,
    hncash: 487.428,
    hnassets: 55045.061,
    hnliabilities: 8217.263,
    hnnet: 46827.797
  },
  "2002-06-01": {
    nonprofitassets: 3530162,
    nonprofitmiscellaneousassets: 592720,
    nonprofitmiscellaneous: 357627,
    nonprofitnotes: 87065,
    nonprofitliabilities: 850166,
    householdassets: 51684167,
    householdnet: 43767854,
    householdcheckable: 354681,
    householddeposits: 3347649,
    householdfunds: 1457198,
    householdmortgages: 102952,
    householddebt: -10236,
    householdliabilitiesother: 350470,
    householdlending: 554786,
    householdliabilities: 32855787,
    householdborrowing: 7897007,
    householdfinance: 7916313,
    householdself: 5276973,
    hngoods: 3442973,
    householdgoods: 18828380,
    householdnotes: 1159462,
    householdbonds: 599466,
    householdequities: 7360030,
    householdcredits: 1758928,
    hnpension: 11294187,
    householdestate: 15385.407,
    hncredit: 1917.644,
    life: 19.306,
    hnmortgate: 5639.129,
    hnlifeinsurance: 941.762,
    hnmiscellaneous: 509.594,
    hncash: 451.834,
    hnassets: 54624.287,
    hnliabilities: 8408.852,
    hnnet: 46215.435
  },
  "2002-09-01": {
    nonprofitassets: 3533540,
    nonprofitmiscellaneousassets: 595093,
    nonprofitmiscellaneous: 371418,
    nonprofitnotes: 91488,
    nonprofitliabilities: 872776,
    householdassets: 50805574,
    householdnet: 42679515,
    householdcheckable: 355575,
    householddeposits: 3466498,
    householdfunds: 1429070,
    householdmortgages: 104762,
    householddebt: -12103,
    householdliabilitiesother: 332905,
    householdlending: 550826,
    householdliabilities: 31565636,
    householdborrowing: 8106031,
    householdfinance: 8126059,
    householdself: 5346067,
    hngoods: 3490768,
    householdgoods: 19239938,
    householdnotes: 1158368,
    householdbonds: 674317,
    householdequities: 5996123,
    householdcredits: 1832685,
    hnpension: 11123054,
    householdestate: 15749.17,
    hncredit: 1951.608,
    life: 20.028,
    hnmortgate: 5833.621,
    hnlifeinsurance: 943.522,
    hnmiscellaneous: 522.216,
    hncash: 446.064,
    hnassets: 53746.726,
    hnliabilities: 8627.417,
    hnnet: 45119.309
  },
  "2002-12-01": {
    nonprofitassets: 3611321,
    nonprofitmiscellaneousassets: 597466,
    nonprofitmiscellaneous: 380805,
    nonprofitnotes: 95879,
    nonprofitliabilities: 895386,
    householdassets: 51937330,
    householdnet: 43573819,
    householdcheckable: 397323,
    householddeposits: 3462286,
    householdfunds: 1500694,
    householdmortgages: 106522,
    householddebt: -19065,
    householdliabilitiesother: 334438,
    householdlending: 548372,
    householdliabilities: 32290146,
    householdborrowing: 8343508,
    householdfinance: 8363511,
    householdself: 5209011,
    hngoods: 3526205,
    householdgoods: 19647184,
    householdnotes: 1087152,
    householdbonds: 847478,
    householdequities: 6362284,
    householdcredits: 1934630,
    hnpension: 11382616,
    householdestate: 16120.979,
    hncredit: 1997.008,
    life: 20.003,
    hnmortgate: 6031.127,
    hnlifeinsurance: 948.395,
    hnmiscellaneous: 544.535,
    hncash: 441.85,
    hnassets: 54953.949,
    hnliabilities: 8878.092,
    hnnet: 46075.857
  },
  "2003-03-01": {
    nonprofitassets: 3735248,
    nonprofitmiscellaneousassets: 614387,
    nonprofitmiscellaneous: 385081,
    nonprofitnotes: 97790,
    nonprofitliabilities: 907271,
    householdassets: 52280985,
    householdnet: 43736722,
    householdcheckable: 389912,
    householddeposits: 3661541,
    householdfunds: 1451699,
    householdmortgages: 107592,
    householddebt: -18413,
    householdliabilitiesother: 346853,
    householdlending: 561706,
    householdliabilities: 32230941,
    householdborrowing: 8522896,
    householdfinance: 8544264,
    householdself: 5369950,
    hngoods: 3549593,
    householdgoods: 20050044,
    householdnotes: 1066803,
    householdbonds: 748335,
    householdequities: 5988228,
    householdcredits: 1815138,
    hnpension: 11470673,
    householdestate: 16500.451,
    hncredit: 1989.123,
    life: 21.368,
    hnmortgate: 6205.332,
    hnlifeinsurance: 964.965,
    hnmiscellaneous: 557.13,
    hncash: 454.114,
    hnassets: 55404.686,
    hnliabilities: 9066.454,
    hnnet: 46338.233
  },
  "2003-06-01": {
    nonprofitassets: 3836628,
    nonprofitmiscellaneousassets: 631308,
    nonprofitmiscellaneous: 389677,
    nonprofitnotes: 99703,
    nonprofitliabilities: 919156,
    householdassets: 54479442,
    householdnet: 45550327,
    householdcheckable: 397963,
    householddeposits: 3726467,
    householdfunds: 1419811,
    householdmortgages: 109274,
    householddebt: -15840,
    householdliabilitiesother: 437818,
    householdlending: 662850,
    householdliabilities: 33994072,
    householdborrowing: 8907407,
    householdfinance: 8929115,
    householdself: 5476941,
    hngoods: 3586094,
    householdgoods: 20485370,
    householdnotes: 1076395,
    householdbonds: 726948,
    householdequities: 7067136,
    householdcredits: 1803343,
    hnpension: 11874660,
    householdestate: 16899.276,
    hncredit: 2021.93,
    life: 21.708,
    hnmortgate: 6463.499,
    hnlifeinsurance: 996.998,
    hnmiscellaneous: 567.903,
    hncash: 553.576,
    hnassets: 57687.676,
    hnliabilities: 9458.594,
    hnnet: 48229.082
  },
  "2003-09-01": {
    nonprofitassets: 3900933,
    nonprofitmiscellaneousassets: 648229,
    nonprofitmiscellaneous: 395277,
    nonprofitnotes: 101618,
    nonprofitliabilities: 931041,
    householdassets: 55603028,
    householdnet: 46418811,
    householdcheckable: 355459,
    householddeposits: 3777214,
    householdfunds: 1368737,
    householdmortgages: 109528,
    householddebt: -23120,
    householdliabilitiesother: 418076,
    householdlending: 649214,
    householdliabilities: 34660067,
    householdborrowing: 9162495,
    householdfinance: 9184218,
    householdself: 5592283,
    hngoods: 3630871,
    householdgoods: 20942961,
    householdnotes: 1152685,
    householdbonds: 634574,
    householdequities: 7479517,
    householdcredits: 1787259,
    hnpension: 12041321,
    householdestate: 17312.09,
    hncredit: 2059.675,
    life: 21.723,
    hnmortgate: 6707.863,
    hnlifeinsurance: 1022.294,
    hnmiscellaneous: 586.769,
    hncash: 539.686,
    hnassets: 58858.718,
    hnliabilities: 9719.982,
    hnnet: 49138.737
  },
  "2003-12-01": {
    nonprofitassets: 3985532,
    nonprofitmiscellaneousassets: 665150,
    nonprofitmiscellaneous: 400463,
    nonprofitnotes: 103488,
    nonprofitliabilities: 942926,
    householdassets: 57738340,
    householdnet: 48305552,
    householdcheckable: 384455,
    householddeposits: 3804859,
    householdfunds: 1330561,
    householdmortgages: 110589,
    householddebt: 18958,
    householdliabilitiesother: 374405,
    householdlending: 631664,
    householdliabilities: 36312132,
    householdborrowing: 9411170,
    householdfinance: 9432789,
    householdself: 5667793,
    hngoods: 3677665,
    householdgoods: 21426208,
    householdnotes: 1258766,
    householdbonds: 758388,
    householdequities: 8394260,
    householdcredits: 2017154,
    hnpension: 12428322,
    householdestate: 17748.543,
    hncredit: 2102.932,
    life: 21.619,
    hnmortgate: 6914.875,
    hnlifeinsurance: 1045.956,
    hnmiscellaneous: 607.109,
    hncash: 521.075,
    hnassets: 61061.825,
    hnliabilities: 9975.252,
    hnnet: 51086.574
  },
  "2004-03-01": {
    nonprofitassets: 4105207,
    nonprofitmiscellaneousassets: 682349,
    nonprofitmiscellaneous: 396421,
    nonprofitnotes: 105173,
    nonprofitliabilities: 958092,
    householdassets: 60324912,
    householdnet: 50705250,
    householdcheckable: 370056,
    householddeposits: 3953302,
    householdfunds: 1291165,
    householdmortgages: 112736,
    householddebt: 17170,
    householdliabilitiesother: 394765,
    householdlending: 671476,
    householdliabilities: 38143543,
    householdborrowing: 9598107,
    householdfinance: 9619662,
    householdself: 5835522,
    hngoods: 3737758,
    householdgoods: 22181369,
    householdnotes: 2030127,
    householdbonds: 794238,
    householdequities: 8848574,
    householdcredits: 2824365,
    hnpension: 12682293,
    householdestate: 18443.611,
    hncredit: 2096.472,
    life: 21.555,
    hnmortgate: 7089.7,
    hnlifeinsurance: 1051.992,
    hnmiscellaneous: 614.797,
    hncash: 558.74,
    hnassets: 63751.31,
    hnliabilities: 10181.333,
    hnnet: 53569.977
  },
  "2004-06-01": {
    nonprofitassets: 4245408,
    nonprofitmiscellaneousassets: 699548,
    nonprofitmiscellaneous: 400094,
    nonprofitnotes: 106456,
    nonprofitliabilities: 973258,
    householdassets: 61310844,
    householdnet: 51384070,
    householdcheckable: 344761,
    householddeposits: 4058174,
    householdfunds: 1248918,
    householdmortgages: 114988,
    householddebt: 16648,
    householdliabilitiesother: 413021,
    householdlending: 697752,
    householdliabilities: 38467260,
    householdborrowing: 9904448,
    householdfinance: 9926775,
    householdself: 6142294,
    hngoods: 3795111,
    householdgoods: 22843584,
    householdnotes: 1902454,
    householdbonds: 753047,
    householdequities: 8732163,
    householdcredits: 2655500,
    hnpension: 12899512,
    householdestate: 19048.473,
    hncredit: 2114.539,
    life: 22.327,
    hnmortgate: 7360.24,
    hnlifeinsurance: 1065.829,
    hnmiscellaneous: 622.357,
    hncash: 582.764,
    hnassets: 64861.083,
    hnliabilities: 10499.939,
    hnnet: 54361.145
  },
  "2004-09-01": {
    nonprofitassets: 4398138,
    nonprofitmiscellaneousassets: 716747,
    nonprofitmiscellaneous: 407936,
    nonprofitnotes: 107846,
    nonprofitliabilities: 988424,
    householdassets: 62775736,
    householdnet: 52578248,
    householdcheckable: 327663,
    householddeposits: 4111938,
    householdfunds: 1207598,
    householdmortgages: 117012,
    householddebt: -4722,
    householdliabilitiesother: 409271,
    householdlending: 687762,
    householdliabilities: 39174823,
    householdborrowing: 10174829,
    householdfinance: 10197488,
    householdself: 6477724,
    hngoods: 3827837,
    householdgoods: 23600913,
    householdnotes: 2006793,
    householdbonds: 815517,
    householdequities: 8733333,
    householdcredits: 2822309,
    hnpension: 13090120,
    householdestate: 19773.076,
    hncredit: 2164.875,
    life: 22.659,
    hnmortgate: 7605.404,
    hnlifeinsurance: 1076.559,
    hnmiscellaneous: 639.816,
    hncash: 570.75,
    hnassets: 66462.238,
    hnliabilities: 10777.976,
    hnnet: 55684.262
  },
  "2004-12-01": {
    nonprofitassets: 4526679,
    nonprofitmiscellaneousassets: 733946,
    nonprofitmiscellaneous: 413033,
    nonprofitnotes: 109142,
    nonprofitliabilities: 1003590,
    householdassets: 65354418,
    householdnet: 54792604,
    householdcheckable: 380692,
    householddeposits: 4186018,
    householdfunds: 1209337,
    householdmortgages: 119722,
    householddebt: -6427,
    householdliabilitiesother: 465848,
    householdlending: 754922,
    householdliabilities: 40975548,
    householdborrowing: 10538689,
    householdfinance: 10561814,
    householdself: 6699334,
    hngoods: 3898171,
    householdgoods: 24378870,
    householdnotes: 2249553,
    householdbonds: 689543,
    householdequities: 9462328,
    householdcredits: 2939096,
    hnpension: 13579752,
    householdestate: 20480.699,
    hncredit: 2220.119,
    life: 23.125,
    hnmortgate: 7859.149,
    hnlifeinsurance: 1102.447,
    hnmiscellaneous: 661.622,
    hncash: 635.2,
    hnassets: 69153.088,
    hnliabilities: 11152.371,
    hnnet: 58000.717
  },
  "2005-03-01": {
    nonprofitassets: 4586437,
    nonprofitmiscellaneousassets: 759308,
    nonprofitmiscellaneous: 415627,
    nonprofitnotes: 108011,
    nonprofitliabilities: 1015277,
    householdassets: 66344681,
    householdnet: 55578772,
    householdcheckable: 373811,
    householddeposits: 4306382,
    householdfunds: 1166455,
    householdmortgages: 122536,
    householddebt: -19124,
    householdliabilitiesother: 467627,
    householdlending: 745222,
    householdliabilities: 41231731,
    householdborrowing: 10742496,
    householdfinance: 10765909,
    householdself: 6825603,
    hngoods: 3955084,
    householdgoods: 25112950,
    householdnotes: 2162745,
    householdbonds: 703451,
    householdequities: 9483772,
    householdcredits: 2866196,
    hnpension: 13684590,
    householdestate: 21157.866,
    hncredit: 2204.974,
    life: 23.413,
    hnmortgate: 8089.019,
    hnlifeinsurance: 1104.356,
    hnmiscellaneous: 675.343,
    hncash: 622.686,
    hnassets: 70178.328,
    hnliabilities: 11365.559,
    hnnet: 58812.769
  },
  "2005-06-01": {
    nonprofitassets: 4707353,
    nonprofitmiscellaneousassets: 784670,
    nonprofitmiscellaneous: 419079,
    nonprofitnotes: 106762,
    nonprofitliabilities: 1026964,
    householdassets: 68121803,
    householdnet: 57031554,
    householdcheckable: 333664,
    householddeposits: 4402887,
    householdfunds: 1142027,
    householdmortgages: 124805,
    householddebt: -11889,
    householdliabilitiesother: 465912,
    householdlending: 757453,
    householdliabilities: 42258127,
    householdborrowing: 11066111,
    householdfinance: 11090249,
    householdself: 7092651,
    hngoods: 4018293,
    householdgoods: 25863676,
    householdnotes: 2272812,
    householdbonds: 738614,
    householdequities: 9777392,
    householdcredits: 3011426,
    hnpension: 13944456,
    householdestate: 21845.383,
    hncredit: 2232.389,
    life: 24.138,
    hnmortgate: 8379.699,
    hnlifeinsurance: 1111.676,
    hnmiscellaneous: 684.495,
    hncash: 632.648,
    hnassets: 72051.703,
    hnliabilities: 11698.134,
    hnnet: 60353.569
  },
  "2005-09-01": {
    nonprofitassets: 4841773,
    nonprofitmiscellaneousassets: 810032,
    nonprofitmiscellaneous: 418824,
    nonprofitnotes: 105648,
    nonprofitliabilities: 1038651,
    householdassets: 70004310,
    householdnet: 58575839,
    householdcheckable: 276602,
    householddeposits: 4568732,
    householdfunds: 1158263,
    householdmortgages: 126030,
    householddebt: -11544,
    householdliabilitiesother: 460225,
    householdlending: 752186,
    householdliabilities: 43329635,
    householdborrowing: 11405057,
    householdfinance: 11428471,
    householdself: 7389680,
    hngoods: 4057909,
    householdgoods: 26674675,
    householdnotes: 2190051,
    householdbonds: 613077,
    householdequities: 10300786,
    householdcredits: 2803128,
    hnpension: 14239419,
    householdestate: 22616.766,
    hncredit: 2278.385,
    life: 23.414,
    hnmortgate: 8677.991,
    hnlifeinsurance: 1129.054,
    hnmiscellaneous: 711.785,
    hncash: 626.156,
    hnassets: 74043.832,
    hnliabilities: 12048.298,
    hnnet: 61995.534
  },
  "2005-12-01": {
    nonprofitassets: 4971577,
    nonprofitmiscellaneousassets: 835394,
    nonprofitmiscellaneous: 421221,
    nonprofitnotes: 104215,
    nonprofitliabilities: 1050338,
    householdassets: 71767975,
    householdnet: 60054872,
    householdcheckable: 288504,
    householddeposits: 4623792,
    householdfunds: 1226482,
    householdmortgages: 125839,
    householddebt: -6196,
    householdliabilitiesother: 435361,
    householdlending: 749281,
    householdliabilities: 44346256,
    householdborrowing: 11690118,
    householdfinance: 11713103,
    householdself: 7635949,
    hngoods: 4106226,
    householdgoods: 27421719,
    householdnotes: 2205761,
    householdbonds: 746147,
    householdequities: 10473641,
    householdcredits: 2951908,
    hnpension: 14516017,
    householdestate: 23315.493,
    hncredit: 2320.555,
    life: 22.985,
    hnmortgate: 8940.398,
    hnlifeinsurance: 1136.367,
    hnmiscellaneous: 744.315,
    hncash: 623.442,
    hnassets: 75912.822,
    hnliabilities: 12342.22,
    hnnet: 63570.602
  },
  "2006-03-01": {
    nonprofitassets: 5143670,
    nonprofitmiscellaneousassets: 800929,
    nonprofitmiscellaneous: 438919,
    nonprofitnotes: 109064,
    nonprofitliabilities: 1079725,
    householdassets: 73756410,
    householdnet: 61692641,
    householdcheckable: 288711,
    householddeposits: 4837978,
    householdfunds: 1228284,
    householdmortgages: 122992,
    householddebt: 5267,
    householdliabilitiesother: 458191,
    householdlending: 774605,
    householdliabilities: 46182474,
    householdborrowing: 12040584,
    householdfinance: 12063769,
    householdself: 7741119,
    hngoods: 4160856,
    householdgoods: 27573936,
    householdnotes: 2255579,
    householdbonds: 712083,
    householdequities: 11735229,
    householdcredits: 2967662,
    hnpension: 14795187,
    householdestate: 23413.08,
    hncredit: 2337.585,
    life: 23.185,
    hnmortgate: 9239.541,
    hnlifeinsurance: 1067.102,
    hnmiscellaneous: 746.598,
    hncash: 651.613,
    hnassets: 78107.893,
    hnliabilities: 12704.575,
    hnnet: 65403.318
  },
  "2006-06-01": {
    nonprofitassets: 5308253,
    nonprofitmiscellaneousassets: 832502,
    nonprofitmiscellaneous: 450188,
    nonprofitnotes: 113886,
    nonprofitliabilities: 1109112,
    householdassets: 74137978,
    householdnet: 61744091,
    householdcheckable: 270809,
    householddeposits: 4904747,
    householdfunds: 1266016,
    householdmortgages: 117962,
    householddebt: 14943,
    householdliabilitiesother: 459328,
    householdlending: 810304,
    householdliabilities: 46150157,
    householdborrowing: 12370531,
    householdfinance: 12393887,
    householdself: 7902067,
    hngoods: 4209342,
    householdgoods: 27987821,
    householdnotes: 2134486,
    householdbonds: 768406,
    householdequities: 11384931,
    householdcredits: 2902892,
    hnpension: 14892149,
    householdestate: 23778.479,
    hncredit: 2349.611,
    life: 23.356,
    hnmortgate: 9546.649,
    hnlifeinsurance: 1067.542,
    hnmiscellaneous: 748.7,
    hncash: 692.342,
    hnassets: 78622.576,
    hnliabilities: 13052.811,
    hnnet: 65569.765
  },
  "2006-09-01": {
    nonprofitassets: 5507325,
    nonprofitmiscellaneousassets: 860851,
    nonprofitmiscellaneous: 469836,
    nonprofitnotes: 118733,
    nonprofitliabilities: 1138499,
    householdassets: 75516790,
    householdnet: 62797100,
    householdcheckable: 228027,
    householddeposits: 5024655,
    householdfunds: 1331900,
    householdmortgages: 111174,
    householddebt: 21292,
    householdliabilitiesother: 476892,
    householdlending: 812184,
    householdliabilities: 47209551,
    householdborrowing: 12696287,
    householdfinance: 12719690,
    householdself: 8127121,
    hngoods: 4258448,
    householdgoods: 28307239,
    householdnotes: 2194440,
    householdbonds: 721568,
    householdequities: 11760673,
    householdcredits: 2916008,
    hnpension: 15170207,
    householdestate: 24048.791,
    hncredit: 2411.441,
    life: 23.403,
    hnmortgate: 9786.662,
    hnlifeinsurance: 1089.09,
    hnmiscellaneous: 749.686,
    hncash: 701.01,
    hnassets: 80172.191,
    hnliabilities: 13388.353,
    hnnet: 66783.838
  },
  "2006-12-01": {
    nonprofitassets: 5667781,
    nonprofitmiscellaneousassets: 892290,
    nonprofitmiscellaneous: 484054,
    nonprofitnotes: 123503,
    nonprofitliabilities: 1167886,
    householdassets: 77138338,
    householdnet: 64157668,
    householdcheckable: 243440,
    householddeposits: 5093683,
    householdfunds: 1426029,
    householdmortgages: 106538,
    householddebt: 40593,
    householdliabilitiesother: 519557,
    householdlending: 807132,
    householdliabilities: 48690826,
    householdborrowing: 12957117,
    householdfinance: 12980670,
    householdself: 8274180,
    hngoods: 4298508,
    householdgoods: 28447512,
    householdnotes: 1961349,
    householdbonds: 791318,
    householdequities: 12671024,
    householdcredits: 2752667,
    hnpension: 15537275,
    householdestate: 24149.004,
    hncredit: 2456.716,
    life: 23.553,
    hnmortgate: 9940.251,
    hnlifeinsurance: 1118.908,
    hnmiscellaneous: 766.487,
    hncash: 700.594,
    hnassets: 81922.913,
    hnliabilities: 13664.502,
    hnnet: 68258.411
  },
  "2007-03-01": {
    nonprofitassets: 5758821,
    nonprofitmiscellaneousassets: 900287,
    nonprofitmiscellaneous: 495190,
    nonprofitnotes: 123313,
    nonprofitliabilities: 1190151,
    householdassets: 78301331,
    householdnet: 65155466,
    householdcheckable: 254436,
    householddeposits: 5292599,
    householdfunds: 1474241,
    householdmortgages: 102485,
    householddebt: 30413,
    householdliabilitiesother: 508732,
    householdlending: 844246,
    householdliabilities: 50039262,
    householdborrowing: 13121763,
    householdfinance: 13145865,
    householdself: 8347988,
    hngoods: 4344563,
    householdgoods: 28262069,
    householdnotes: 2031961,
    householdbonds: 828370,
    householdequities: 13315003,
    householdcredits: 2860331,
    hnpension: 15746303,
    householdestate: 23917.506,
    hncredit: 2440.172,
    life: 24.102,
    hnmortgate: 10142.446,
    hnlifeinsurance: 1132.363,
    hnmiscellaneous: 771.751,
    hncash: 741.761,
    hnassets: 83171.493,
    hnliabilities: 13840.826,
    hnnet: 69330.667
  },
  "2007-06-01": {
    nonprofitassets: 5873613,
    nonprofitmiscellaneousassets: 911285,
    nonprofitmiscellaneous: 501540,
    nonprofitnotes: 122961,
    nonprofitliabilities: 1212416,
    householdassets: 79053363,
    householdnet: 65618327,
    householdcheckable: 177033,
    householddeposits: 5352427,
    householdfunds: 1533664,
    householdmortgages: 97882,
    householddebt: 30051,
    householdliabilitiesother: 566594,
    householdlending: 903405,
    householdliabilities: 50869526,
    householdborrowing: 13410904,
    householdfinance: 13435036,
    householdself: 8416877,
    hngoods: 4391315,
    householdgoods: 28183837,
    householdnotes: 1994345,
    householdbonds: 719909,
    householdequities: 13687464,
    householdcredits: 2714254,
    hnpension: 16129813,
    householdestate: 23792.522,
    hncredit: 2476.966,
    life: 24.132,
    hnmortgate: 10337.293,
    hnlifeinsurance: 1158.642,
    hnmiscellaneous: 795.947,
    hncash: 805.523,
    hnassets: 84030.025,
    hnliabilities: 14145.912,
    hnnet: 69884.113
  },
  "2007-09-01": {
    nonprofitassets: 5960147,
    nonprofitmiscellaneousassets: 918273,
    nonprofitmiscellaneous: 513567,
    nonprofitnotes: 123644,
    nonprofitliabilities: 1234681,
    householdassets: 80054090,
    householdnet: 66377807,
    householdcheckable: 80323,
    householddeposits: 5479149,
    householdfunds: 1741151,
    householdmortgages: 94447,
    householddebt: 44566,
    householdliabilitiesother: 532118,
    householdlending: 899461,
    householdliabilities: 52021840,
    householdborrowing: 13651684,
    householdfinance: 13676283,
    householdself: 8470025,
    hngoods: 4427357,
    householdgoods: 28032250,
    householdnotes: 2194029,
    householdbonds: 982254,
    householdequities: 13821723,
    householdcredits: 3176283,
    hnpension: 16374238,
    householdestate: 23604.893,
    hncredit: 2555.552,
    life: 24.599,
    hnmortgate: 10519.448,
    hnlifeinsurance: 1170.606,
    hnmiscellaneous: 808.881,
    hncash: 805.014,
    hnassets: 85111.969,
    hnliabilities: 14397.397,
    hnnet: 70714.572
  },
  "2007-12-01": {
    nonprofitassets: 5964100,
    nonprofitmiscellaneousassets: 928430,
    nonprofitmiscellaneous: 516232,
    nonprofitnotes: 124442,
    nonprofitliabilities: 1256946,
    householdassets: 79632420,
    householdnet: 65759873,
    householdcheckable: 129826,
    householddeposits: 5607058,
    householdfunds: 1907327,
    householdmortgages: 91590,
    householddebt: 50630,
    householdliabilitiesother: 562860,
    householdlending: 1008603,
    householdliabilities: 51866794,
    householdborrowing: 13847951,
    householdfinance: 13872547,
    householdself: 8404333,
    hngoods: 4473942,
    householdgoods: 27765626,
    householdnotes: 2181490,
    householdbonds: 1084250,
    householdequities: 13123309,
    householdcredits: 3265740,
    hnpension: 16414908,
    householdestate: 23291.684,
    hncredit: 2609.477,
    life: 24.596,
    hnmortgate: 10624.984,
    hnlifeinsurance: 1177.859,
    hnmiscellaneous: 827.832,
    hncash: 917.013,
    hnassets: 84685.651,
    hnliabilities: 14613.261,
    hnnet: 70072.39
  },
  "2008-03-01": {
    nonprofitassets: 5733425,
    nonprofitmiscellaneousassets: 897396,
    nonprofitmiscellaneous: 526323,
    nonprofitnotes: 120992,
    nonprofitliabilities: 1275515,
    householdassets: 77967291,
    householdnet: 63980493,
    householdcheckable: 142893,
    householddeposits: 5781809,
    householdfunds: 2119142,
    householdmortgages: 89360,
    householddebt: 58831,
    householdliabilitiesother: 603433,
    householdlending: 1132125,
    householdliabilities: 50824806,
    householdborrowing: 13960870,
    householdfinance: 13986798,
    householdself: 8028889,
    hngoods: 4522484,
    householdgoods: 27142485,
    householdnotes: 2248654,
    householdbonds: 1057671,
    householdequities: 12096135,
    householdcredits: 3306325,
    hnpension: 16207759,
    householdestate: 22620.001,
    hncredit: 2602.008,
    life: 25.928,
    hnmortgate: 10696.598,
    hnlifeinsurance: 1169.605,
    hnmiscellaneous: 840.124,
    hncash: 1042.765,
    hnassets: 82824.001,
    hnliabilities: 14735.99,
    hnnet: 68088.011
  },
  "2008-06-01": {
    nonprofitassets: 5546999,
    nonprofitmiscellaneousassets: 869902,
    nonprofitmiscellaneous: 529300,
    nonprofitnotes: 118113,
    nonprofitliabilities: 1294084,
    householdassets: 76596084,
    householdnet: 62691730,
    householdcheckable: 128781,
    householddeposits: 5635851,
    householdfunds: 2046527,
    householdmortgages: 96333,
    householddebt: 43621,
    householdliabilitiesother: 531083,
    householdlending: 1115645,
    householdliabilities: 50003417,
    householdborrowing: 13878028,
    householdfinance: 13904354,
    householdself: 7715090,
    hngoods: 4547808,
    householdgoods: 26592667,
    householdnotes: 2312917,
    householdbonds: 1106874,
    householdequities: 11614614,
    householdcredits: 3419791,
    hnpension: 16298536,
    householdestate: 22044.859,
    hncredit: 2620.453,
    life: 26.326,
    hnmortgate: 10682.871,
    hnlifeinsurance: 1180.679,
    hnmiscellaneous: 847.902,
    hncash: 1019.312,
    hnassets: 81296.411,
    hnliabilities: 14669.138,
    hnnet: 66627.273
  },
  "2008-09-01": {
    nonprofitassets: 5478909,
    nonprofitmiscellaneousassets: 839422,
    nonprofitmiscellaneous: 544795,
    nonprofitnotes: 111706,
    nonprofitliabilities: 1312653,
    householdassets: 74550025,
    householdnet: 60504533,
    householdcheckable: 148007,
    householddeposits: 5803433,
    householdfunds: 2045216,
    householdmortgages: 104628,
    householddebt: 78948,
    householdliabilitiesother: 642703,
    householdlending: 1123307,
    householdliabilities: 48494107,
    householdborrowing: 14019342,
    householdfinance: 14045492,
    householdself: 7553226,
    hngoods: 4576769,
    householdgoods: 26055918,
    householdnotes: 2449946,
    householdbonds: 1170994,
    householdequities: 10107213,
    householdcredits: 3620940,
    hnpension: 16048025,
    householdestate: 21479.149,
    hncredit: 2643.813,
    life: 26.15,
    hnmortgate: 10653.878,
    hnlifeinsurance: 1179.203,
    hnmiscellaneous: 865.538,
    hncash: 1018.679,
    hnassets: 79218.819,
    hnliabilities: 14813.35,
    hnnet: 64405.469
  },
  "2008-12-01": {
    nonprofitassets: 5215268,
    nonprofitmiscellaneousassets: 812472,
    nonprofitmiscellaneous: 558943,
    nonprofitnotes: 110948,
    nonprofitliabilities: 1331222,
    householdassets: 71294402,
    householdnet: 57557971,
    householdcheckable: 407776,
    householddeposits: 5755878,
    householdfunds: 2204095,
    householdmortgages: 107302,
    householddebt: 80134,
    householdliabilitiesother: 407466,
    householdlending: 844735,
    householdliabilities: 45781954,
    householdborrowing: 13708649,
    householdfinance: 13736431,
    householdself: 7128451,
    hngoods: 4565257,
    householdgoods: 25512448,
    householdnotes: 2721456,
    householdbonds: 1242114,
    householdequities: 7925123,
    householdcredits: 3963570,
    hnpension: 15499095,
    householdestate: 20947.191,
    hncredit: 2643.789,
    life: 27.782,
    hnmortgate: 10577.26,
    hnlifeinsurance: 1170.538,
    hnmiscellaneous: 882.693,
    hncash: 737.433,
    hnassets: 75726.933,
    hnliabilities: 14508.71,
    hnnet: 61218.223
  },
  "2009-03-01": {
    nonprofitassets: 5186116,
    nonprofitmiscellaneousassets: 839309,
    nonprofitmiscellaneous: 569880,
    nonprofitnotes: 112419,
    nonprofitliabilities: 1353504,
    householdassets: 69517717,
    householdnet: 55895317,
    householdcheckable: 386711,
    householddeposits: 5723822,
    householdfunds: 2200989,
    householdmortgages: 112195,
    householddebt: 63437,
    householdliabilitiesother: 376525,
    householdlending: 776258,
    householdliabilities: 44538646,
    householdborrowing: 13595131,
    householdfinance: 13622400,
    householdself: 6802409,
    hngoods: 4551280,
    householdgoods: 24979071,
    householdnotes: 2756303,
    householdbonds: 1415474,
    householdequities: 7041172,
    householdcredits: 4171777,
    hnpension: 15376057,
    householdestate: 20427.791,
    hncredit: 2578.142,
    life: 27.269,
    hnmortgate: 10577.027,
    hnlifeinsurance: 1177.327,
    hnmiscellaneous: 882.125,
    hncash: 664.063,
    hnassets: 73891.905,
    hnliabilities: 14406.024,
    hnnet: 59485.881
  },
  "2009-06-01": {
    nonprofitassets: 5010724,
    nonprofitmiscellaneousassets: 870453,
    nonprofitmiscellaneous: 577914,
    nonprofitnotes: 113347,
    nonprofitliabilities: 1375786,
    householdassets: 70139017,
    householdnet: 56552564,
    householdcheckable: 430240,
    householddeposits: 5651756,
    householdfunds: 2109086,
    householdmortgages: 116838,
    householddebt: 75530,
    householdliabilitiesother: 389632,
    householdlending: 792611,
    householdliabilities: 45601760,
    householdborrowing: 13560848,
    householdfinance: 13586453,
    householdself: 6272332,
    hngoods: 4547974,
    householdgoods: 24537257,
    householdnotes: 2323409,
    householdbonds: 1509283,
    householdequities: 8537103,
    householdcredits: 3832692,
    hnpension: 15891791,
    householdestate: 19989.283,
    hncredit: 2548.873,
    life: 25.605,
    hnmortgate: 10546.813,
    hnlifeinsurance: 1197.622,
    hnmiscellaneous: 886.527,
    hncash: 675.773,
    hnassets: 74304.858,
    hnliabilities: 14384.325,
    hnnet: 59920.533
  },
  "2009-09-01": {
    nonprofitassets: 4948977,
    nonprofitmiscellaneousassets: 899481,
    nonprofitmiscellaneous: 587760,
    nonprofitnotes: 113885,
    nonprofitliabilities: 1398068,
    householdassets: 71701698,
    householdnet: 58123694,
    householdcheckable: 396474,
    householddeposits: 5641266,
    householdfunds: 1974364,
    householdmortgages: 121233,
    householddebt: 90593,
    householdliabilitiesother: 414035,
    householdlending: 824036,
    householdliabilities: 47496816,
    householdborrowing: 13552788,
    householdfinance: 13578004,
    householdself: 5953639,
    hngoods: 4530910,
    householdgoods: 24204882,
    householdnotes: 2626691,
    householdbonds: 1477199,
    householdequities: 10036462,
    householdcredits: 4103890,
    hnpension: 16450489,
    householdestate: 19673.972,
    hncredit: 2558.711,
    life: 25.216,
    hnmortgate: 10489.449,
    hnlifeinsurance: 1220.531,
    hnmiscellaneous: 895.665,
    hncash: 702.803,
    hnassets: 75775.343,
    hnliabilities: 14388.312,
    hnnet: 61387.031
  },
  "2009-12-01": {
    nonprofitassets: 5004298,
    nonprofitmiscellaneousassets: 932030,
    nonprofitmiscellaneous: 598196,
    nonprofitnotes: 110365,
    nonprofitliabilities: 1420350,
    householdassets: 71984259,
    householdnet: 58422810,
    householdcheckable: 447925,
    householddeposits: 5809013,
    householdfunds: 1892318,
    householdmortgages: 125760,
    householddebt: 99033,
    householdliabilitiesother: 443243,
    householdlending: 827490,
    householdliabilities: 47960053,
    householdborrowing: 13538595,
    householdfinance: 13561449,
    householdself: 5877573,
    hngoods: 4560020,
    householdgoods: 24024206,
    householdnotes: 2642908,
    householdbonds: 1266993,
    householdequities: 10327295,
    householdcredits: 3909901,
    hnpension: 16717090,
    householdestate: 19464.186,
    hncredit: 2555.017,
    life: 22.854,
    hnmortgate: 10441.302,
    hnlifeinsurance: 1237.589,
    hnmiscellaneous: 913.858,
    hncash: 701.73,
    hnassets: 76083.313,
    hnliabilities: 14383.603,
    hnnet: 61699.71
  },
  "2010-03-01": {
    nonprofitassets: 5064382,
    nonprofitmiscellaneousassets: 971423,
    nonprofitmiscellaneous: 608184,
    nonprofitnotes: 110888,
    nonprofitliabilities: 1424006,
    householdassets: 72984558,
    householdnet: 59524522,
    householdcheckable: 347843,
    householddeposits: 5870239,
    householdfunds: 1688791,
    householdmortgages: 128987,
    householddebt: 166803,
    householdliabilitiesother: 464200,
    householdlending: 830062,
    householdliabilities: 49062246,
    householdborrowing: 13434677,
    householdfinance: 13460036,
    householdself: 5884095,
    hngoods: 4550703,
    householdgoods: 23922312,
    householdnotes: 2807586,
    householdbonds: 1403110,
    householdequities: 10942132,
    householdcredits: 4210696,
    hnpension: 17141304,
    householdestate: 19371.609,
    hncredit: 2493.057,
    life: 25.359,
    hnmortgate: 10310.617,
    hnlifeinsurance: 1244.973,
    hnmiscellaneous: 902.111,
    hncash: 701.075,
    hnassets: 77103.203,
    hnliabilities: 14275.858,
    hnnet: 62827.345
  },
  "2010-06-01": {
    nonprofitassets: 5243495,
    nonprofitmiscellaneousassets: 1014406,
    nonprofitmiscellaneous: 621171,
    nonprofitnotes: 110122,
    nonprofitliabilities: 1427662,
    householdassets: 72339746,
    householdnet: 58932680,
    householdcheckable: 363575,
    householddeposits: 5831325,
    householdfunds: 1586399,
    householdmortgages: 130677,
    householddebt: 175539,
    householdliabilitiesother: 483747,
    householdlending: 834405,
    householdliabilities: 48529152,
    householdborrowing: 13380813,
    householdfinance: 13407066,
    householdself: 6187865,
    hngoods: 4530808,
    householdgoods: 23810594,
    householdnotes: 3078466,
    householdbonds: 1411909,
    householdequities: 10011674,
    householdcredits: 4490375,
    hnpension: 17092594,
    householdestate: 19279.786,
    hncredit: 2478.453,
    life: 26.253,
    hnmortgate: 10243.074,
    hnlifeinsurance: 1231.644,
    hnmiscellaneous: 899.296,
    hncash: 703.728,
    hnassets: 76594.71,
    hnliabilities: 14213.557,
    hnnet: 62381.153
  },
  "2010-09-01": {
    nonprofitassets: 5374823,
    nonprofitmiscellaneousassets: 1061159,
    nonprofitmiscellaneous: 634727,
    nonprofitnotes: 109048,
    nonprofitliabilities: 1431318,
    householdassets: 74209364,
    householdnet: 60854537,
    householdcheckable: 327947,
    householddeposits: 5932687,
    householdfunds: 1581133,
    householdmortgages: 132676,
    householddebt: 162219,
    householdliabilitiesother: 500843,
    householdlending: 849312,
    householdliabilities: 50557259,
    householdborrowing: 13331477,
    householdfinance: 13354827,
    householdself: 6350846,
    hngoods: 4520281,
    householdgoods: 23652105,
    householdnotes: 3228482,
    householdbonds: 1164912,
    householdequities: 11226899,
    householdcredits: 4393394,
    hnpension: 17735347,
    householdestate: 19131.824,
    hncredit: 2503.726,
    life: 23.35,
    hnmortgate: 10164.689,
    hnlifeinsurance: 1260.04,
    hnmiscellaneous: 899.653,
    hncash: 716.636,
    hnassets: 78549.4,
    hnliabilities: 14151.418,
    hnnet: 64397.982
  },
  "2010-12-01": {
    nonprofitassets: 5505573,
    nonprofitmiscellaneousassets: 1108431,
    nonprofitmiscellaneous: 644164,
    nonprofitnotes: 108670,
    nonprofitliabilities: 1434974,
    householdassets: 75700964,
    householdnet: 62326226,
    householdcheckable: 430039,
    householddeposits: 5786664,
    householdfunds: 1723441,
    householdmortgages: 131135,
    householddebt: 164873,
    householdliabilitiesother: 545275,
    householdlending: 856381,
    householdliabilities: 52291079,
    householdborrowing: 13349239,
    householdfinance: 13374738,
    householdself: 6469400,
    hngoods: 4535441,
    householdgoods: 23409885,
    householdnotes: 2952023,
    householdbonds: 1413816,
    householdequities: 12180560,
    householdcredits: 4365839,
    hnpension: 18300753,
    householdestate: 18874.444,
    hncredit: 2646.811,
    life: 25.499,
    hnmortgate: 9992.28,
    hnlifeinsurance: 1272.784,
    hnmiscellaneous: 905.218,
    hncash: 725.246,
    hnassets: 80124.279,
    hnliabilities: 14165.548,
    hnnet: 65958.731
  },
  "2011-03-01": {
    nonprofitassets: 5585988,
    nonprofitmiscellaneousassets: 1119337,
    nonprofitmiscellaneous: 658703,
    nonprofitnotes: 107003,
    nonprofitliabilities: 1456126,
    householdassets: 76706451,
    householdnet: 63359609,
    householdcheckable: 509927,
    householddeposits: 5901558,
    householdfunds: 1674728,
    householdmortgages: 132670,
    householddebt: 156334,
    householdliabilitiesother: 584565,
    householdlending: 864484,
    householdliabilities: 53586502,
    householdborrowing: 13320970,
    householdfinance: 13346842,
    householdself: 6549217,
    hngoods: 4568240,
    householdgoods: 23119949,
    householdnotes: 2967274,
    householdbonds: 1286415,
    householdequities: 13025115,
    householdcredits: 4253689,
    hnpension: 18604350,
    householdestate: 18551.709,
    hncredit: 2628.309,
    life: 25.872,
    hnmortgate: 9951.762,
    hnlifeinsurance: 1292.601,
    hnmiscellaneous: 910.832,
    hncash: 731.814,
    hnassets: 81198.325,
    hnliabilities: 14144.265,
    hnnet: 67054.06
  },
  "2011-06-01": {
    nonprofitassets: 5611142,
    nonprofitmiscellaneousassets: 1130098,
    nonprofitmiscellaneous: 673919,
    nonprofitnotes: 103957,
    nonprofitliabilities: 1477278,
    householdassets: 77048787,
    householdnet: 63751984,
    householdcheckable: 502102,
    householddeposits: 6061176,
    householdfunds: 1650506,
    householdmortgages: 133631,
    householddebt: 140218,
    householdliabilitiesother: 588454,
    householdlending: 912262,
    householdliabilities: 54013347,
    householdborrowing: 13270451,
    householdfinance: 13296803,
    householdself: 6602221,
    hngoods: 4609049,
    householdgoods: 23035440,
    householdnotes: 2973622,
    householdbonds: 1370393,
    householdequities: 12984097,
    householdcredits: 4344015,
    hnpension: 18731020,
    householdestate: 18426.391,
    hncredit: 2653.077,
    life: 26.352,
    hnmortgate: 9888.702,
    hnlifeinsurance: 1310.435,
    hnmiscellaneous: 915.513,
    hncash: 778.631,
    hnassets: 81555.483,
    hnliabilities: 14100.162,
    hnnet: 67455.321
  },
  "2011-09-01": {
    nonprofitassets: 5549342,
    nonprofitmiscellaneousassets: 1140676,
    nonprofitmiscellaneous: 693152,
    nonprofitnotes: 101447,
    nonprofitliabilities: 1498430,
    householdassets: 75307190,
    householdnet: 62059999,
    householdcheckable: 632876,
    householddeposits: 6077521,
    householdfunds: 1624629,
    householdmortgages: 136197,
    householddebt: 148389,
    householdliabilitiesother: 536949,
    householdlending: 923426,
    householdliabilities: 52343870,
    householdborrowing: 13222085,
    householdfinance: 13247191,
    householdself: 6657580,
    hngoods: 4630629,
    householdgoods: 22963320,
    householdnotes: 3289006,
    householdbonds: 1517101,
    householdequities: 11060121,
    householdcredits: 4806107,
    hnpension: 18306231,
    householdestate: 18332.691,
    hncredit: 2702.175,
    life: 25.106,
    hnmortgate: 9834.572,
    hnlifeinsurance: 1318.767,
    hnmiscellaneous: 936.612,
    hncash: 787.229,
    hnassets: 79741.401,
    hnliabilities: 14052.469,
    hnnet: 65688.932
  },
  "2011-12-01": {
    nonprofitassets: 5677273,
    nonprofitmiscellaneousassets: 1151392,
    nonprofitmiscellaneous: 708932,
    nonprofitnotes: 101011,
    nonprofitliabilities: 1519582,
    householdassets: 76463803,
    householdnet: 63219967,
    householdcheckable: 712364,
    householddeposits: 6182007,
    householdfunds: 1651616,
    householdmortgages: 137183,
    householddebt: 159385,
    householdliabilitiesother: 517697,
    householdlending: 863307,
    householdliabilities: 53640449,
    householdborrowing: 13218803,
    householdfinance: 13243836,
    householdself: 6797492,
    hngoods: 4644698,
    householdgoods: 22823354,
    householdnotes: 3165433,
    householdbonds: 1520739,
    householdequities: 11735233,
    householdcredits: 4686172,
    hnpension: 18697286,
    householdestate: 18178.656,
    hncredit: 2756.225,
    life: 25.033,
    hnmortgate: 9785.496,
    hnlifeinsurance: 1350.369,
    hnmiscellaneous: 964.605,
    hncash: 726.124,
    hnassets: 81013.048,
    hnliabilities: 14054.486,
    hnnet: 66958.562
  },
  "2012-03-01": {
    nonprofitassets: 5838391,
    nonprofitmiscellaneousassets: 1183483,
    nonprofitmiscellaneous: 722499,
    nonprofitnotes: 97746,
    nonprofitliabilities: 1537019,
    householdassets: 78291347,
    householdnet: 65096718,
    householdcheckable: 732941,
    householddeposits: 6260085,
    householdfunds: 1591587,
    householdmortgages: 134076,
    householddebt: 151264,
    householdliabilitiesother: 554178,
    householdlending: 857791,
    householdliabilities: 55618016,
    householdborrowing: 13167766,
    householdfinance: 13194629,
    householdself: 6875458,
    hngoods: 4679040,
    householdgoods: 22673331,
    householdnotes: 2961615,
    householdbonds: 1539047,
    householdequities: 13215733,
    householdcredits: 4500662,
    hnpension: 19271267,
    householdestate: 17994.291,
    hncredit: 2745.051,
    life: 26.863,
    hnmortgate: 9717.273,
    hnlifeinsurance: 1353.417,
    hnmiscellaneous: 959.075,
    hncash: 723.715,
    hnassets: 82972.78,
    hnliabilities: 14009.149,
    hnnet: 68963.631
  },
  "2012-06-01": {
    nonprofitassets: 5886687,
    nonprofitmiscellaneousassets: 1213063,
    nonprofitmiscellaneous: 739193,
    nonprofitnotes: 100966,
    nonprofitliabilities: 1554456,
    householdassets: 78233285,
    householdnet: 65084817,
    householdcheckable: 686854,
    householddeposits: 6318814,
    householdfunds: 1544769,
    householdmortgages: 130422,
    householddebt: 149771,
    householdliabilitiesother: 529792,
    householdlending: 873150,
    householdliabilities: 55380346,
    householdborrowing: 13119746,
    householdfinance: 13148468,
    householdself: 6951714,
    hngoods: 4689094,
    householdgoods: 22852939,
    householdnotes: 3179888,
    householdbonds: 1498264,
    householdequities: 12808874,
    householdcredits: 4678152,
    hnpension: 19228866,
    householdestate: 18163.845,
    hncredit: 2790.451,
    life: 28.722,
    hnmortgate: 9649.732,
    hnlifeinsurance: 1331.822,
    hnmiscellaneous: 957.331,
    hncash: 742.728,
    hnassets: 82930.11,
    hnliabilities: 13963.731,
    hnnet: 68966.379
  },
  "2012-09-01": {
    nonprofitassets: 6027733,
    nonprofitmiscellaneousassets: 1245592,
    nonprofitmiscellaneous: 754552,
    nonprofitnotes: 100981,
    nonprofitliabilities: 1571893,
    householdassets: 80044712,
    householdnet: 66864880,
    householdcheckable: 683534,
    householddeposits: 6341806,
    householdfunds: 1592268,
    householdmortgages: 127285,
    householddebt: 148640,
    householdliabilitiesother: 553957,
    householdlending: 878586,
    householdliabilities: 56947982,
    householdborrowing: 13150707,
    householdfinance: 13179832,
    householdself: 7166834,
    hngoods: 4700734,
    householdgoods: 23096730,
    householdnotes: 3132518,
    householdbonds: 1662101,
    householdequities: 13607862,
    householdcredits: 4794619,
    hnpension: 19580403,
    householdestate: 18395.996,
    hncredit: 2850.437,
    life: 29.125,
    hnmortgate: 9597.673,
    hnlifeinsurance: 1344.364,
    hnmiscellaneous: 957.706,
    hncash: 751.301,
    hnassets: 84849.935,
    hnliabilities: 13997.173,
    hnnet: 70852.762
  },
  "2012-12-01": {
    nonprofitassets: 6127478,
    nonprofitmiscellaneousassets: 1276655,
    nonprofitmiscellaneous: 770469,
    nonprofitnotes: 48371,
    nonprofitliabilities: 1589330,
    householdassets: 80979098,
    householdnet: 67803291,
    householdcheckable: 813771,
    householddeposits: 6481886,
    householdfunds: 1694200,
    householdmortgages: 124064,
    householddebt: 149153,
    householdliabilitiesother: 551012,
    householdlending: 882128,
    householdliabilities: 57668675,
    householdborrowing: 13150142,
    householdfinance: 13175807,
    householdself: 7321380,
    hngoods: 4727486,
    householdgoods: 23310423,
    householdnotes: 3224370,
    householdbonds: 1490885,
    householdequities: 13705689,
    householdcredits: 4715255,
    hnpension: 19730293,
    householdestate: 18582.937,
    hncredit: 2912.905,
    life: 25.665,
    hnmortgate: 9537.072,
    hnlifeinsurance: 1352.602,
    hnmiscellaneous: 971.47,
    hncash: 758.064,
    hnassets: 85905.509,
    hnliabilities: 13994.668,
    hnnet: 71910.841
  },
  "2013-03-01": {
    nonprofitassets: 6289653,
    nonprofitmiscellaneousassets: 1310351,
    nonprofitmiscellaneous: 776648,
    nonprofitnotes: 53018,
    nonprofitliabilities: 1594388,
    householdassets: 83628943,
    householdnet: 70493315,
    householdcheckable: 857898,
    householddeposits: 6473733,
    householdfunds: 1619121,
    householdmortgages: 123804,
    householddebt: 149361,
    householdliabilitiesother: 557699,
    householdlending: 865276,
    householdliabilities: 59874643,
    householdborrowing: 13108951,
    householdfinance: 13135628,
    householdself: 7469749,
    hngoods: 4750954,
    householdgoods: 23754300,
    householdnotes: 3124174,
    householdbonds: 1345999,
    householdequities: 15332093,
    householdcredits: 4470173,
    hnpension: 20443921,
    householdestate: 19003.346,
    hncredit: 2913.804,
    life: 26.677,
    hnmortgate: 9488.087,
    hnlifeinsurance: 1366.396,
    hnmiscellaneous: 976.283,
    hncash: 741.472,
    hnassets: 88681.488,
    hnliabilities: 13953.368,
    hnnet: 74728.12
  },
  "2013-06-01": {
    nonprofitassets: 6472031,
    nonprofitmiscellaneousassets: 1344036,
    nonprofitmiscellaneous: 784578,
    nonprofitnotes: 48019,
    nonprofitliabilities: 1599446,
    householdassets: 84706765,
    householdnet: 71557305,
    householdcheckable: 877972,
    householddeposits: 6543634,
    householdfunds: 1583462,
    householdmortgages: 123250,
    householddebt: 154614,
    householdliabilitiesother: 556160,
    householdlending: 922321,
    householdliabilities: 60465587,
    householdborrowing: 13120880,
    householdfinance: 13149460,
    householdself: 7749011,
    hngoods: 4755644,
    householdgoods: 24241178,
    householdnotes: 2986455,
    householdbonds: 1070571,
    householdequities: 15529059,
    householdcredits: 4057026,
    hnpension: 20845209,
    householdestate: 19485.534,
    hncredit: 2960.184,
    life: 28.58,
    hnmortgate: 9449.922,
    hnlifeinsurance: 1371.825,
    hnmiscellaneous: 986.067,
    hncash: 799.071,
    hnassets: 89915.304,
    hnliabilities: 13964.328,
    hnnet: 75950.976
  },
  "2013-09-01": {
    nonprofitassets: 6696054,
    nonprofitmiscellaneousassets: 1377772,
    nonprofitmiscellaneous: 793615,
    nonprofitnotes: 51975,
    nonprofitliabilities: 1604504,
    householdassets: 87083972,
    householdnet: 73854275,
    householdcheckable: 885288,
    householddeposits: 6625570,
    householdfunds: 1608599,
    householdmortgages: 122652,
    householddebt: 146012,
    householdliabilitiesother: 569538,
    householdlending: 913228,
    householdliabilities: 62316921,
    householdborrowing: 13202119,
    householdfinance: 13229697,
    householdself: 8073158,
    hngoods: 4768937,
    householdgoods: 24767051,
    householdnotes: 2949297,
    householdbonds: 1012094,
    householdequities: 16342627,
    householdcredits: 3961391,
    hnpension: 21516587,
    householdestate: 19998.114,
    hncredit: 3030.291,
    life: 27.578,
    hnmortgate: 9456.278,
    hnlifeinsurance: 1390.139,
    hnmiscellaneous: 1000.333,
    hncash: 790.576,
    hnassets: 92481.144,
    hnliabilities: 14040.586,
    hnnet: 78440.558
  },
  "2013-12-01": {
    nonprofitassets: 6867336,
    nonprofitmiscellaneousassets: 1410471,
    nonprofitmiscellaneous: 802954,
    nonprofitnotes: 42044,
    nonprofitliabilities: 1609562,
    householdassets: 89201091,
    householdnet: 75783325,
    householdcheckable: 994472,
    householddeposits: 6623182,
    householdfunds: 1621740,
    householdmortgages: 120870,
    householddebt: 161389,
    householdliabilitiesother: 700170,
    householdlending: 939119,
    householdliabilities: 64070887,
    householdborrowing: 13389076,
    householdfinance: 13417766,
    householdself: 8301072,
    hngoods: 4779573,
    householdgoods: 25130204,
    householdnotes: 2665432,
    householdbonds: 959170,
    householdequities: 17259049,
    householdcredits: 3624602,
    hnpension: 22275007,
    householdestate: 20350.631,
    hncredit: 3090.468,
    life: 28.69,
    hnmortgate: 9437.049,
    hnlifeinsurance: 1407.79,
    hnmiscellaneous: 1024.854,
    hncash: 818.249,
    hnassets: 94749.079,
    hnliabilities: 14224.374,
    hnnet: 80524.705
  },
  "2014-03-01": {
    nonprofitassets: 6896839,
    nonprofitmiscellaneousassets: 1430061,
    nonprofitmiscellaneous: 822017,
    nonprofitnotes: 38288,
    nonprofitliabilities: 1638006,
    householdassets: 90929804,
    householdnet: 77540564,
    householdcheckable: 1018601,
    householddeposits: 6811533,
    householdfunds: 1617843,
    householdmortgages: 118719,
    householddebt: 161854,
    householdliabilitiesother: 706435,
    householdlending: 983913,
    householdliabilities: 65488754,
    householdborrowing: 13359566,
    householdfinance: 13389240,
    householdself: 8319259,
    hngoods: 4785009,
    householdgoods: 25441050,
    householdnotes: 2695987,
    householdbonds: 978595,
    householdequities: 18129858,
    householdcredits: 3674582,
    hnpension: 22484379,
    householdestate: 20656.041,
    hncredit: 3096.86,
    life: 29.674,
    hnmortgate: 9394.417,
    hnlifeinsurance: 1421.148,
    hnmiscellaneous: 1027.637,
    hncash: 865.194,
    hnassets: 96490.969,
    hnliabilities: 14205.229,
    hnnet: 82285.74
  },
  "2014-06-01": {
    nonprofitassets: 7019902,
    nonprofitmiscellaneousassets: 1449615,
    nonprofitmiscellaneous: 840536,
    nonprofitnotes: 47136,
    nonprofitliabilities: 1666450,
    householdassets: 92828586,
    householdnet: 79351212,
    householdcheckable: 1027374,
    householddeposits: 6876952,
    householdfunds: 1555527,
    householdmortgages: 116688,
    householddebt: 168002,
    householdliabilitiesother: 736956,
    householdlending: 983195,
    householdliabilities: 66955903,
    householdborrowing: 13446328,
    householdfinance: 13477374,
    householdself: 8451427,
    hngoods: 4809362,
    householdgoods: 25872683,
    householdnotes: 2608378,
    householdbonds: 1048299,
    householdequities: 19118158,
    householdcredits: 3656677,
    hnpension: 22810211,
    householdestate: 21063.321,
    hncredit: 3164.01,
    life: 31.046,
    hnmortgate: 9377.36,
    hnlifeinsurance: 1440.524,
    hnmiscellaneous: 1035.858,
    hncash: 866.507,
    hnassets: 98483.92,
    hnliabilities: 14303.288,
    hnnet: 84180.632
  },
  "2014-09-01": {
    nonprofitassets: 7136710,
    nonprofitmiscellaneousassets: 1469956,
    nonprofitmiscellaneous: 861337,
    nonprofitnotes: 40311,
    nonprofitliabilities: 1694894,
    householdassets: 93353400,
    householdnet: 79882745,
    householdcheckable: 998515,
    householddeposits: 6994082,
    householdfunds: 1584053,
    householdmortgages: 114422,
    householddebt: 166358,
    householdliabilitiesother: 649843,
    householdlending: 1007348,
    householdliabilities: 67066461,
    householdborrowing: 13440269,
    householdfinance: 13470655,
    householdself: 8715040,
    hngoods: 4839845,
    householdgoods: 26286939,
    householdnotes: 2630782,
    householdbonds: 1029843,
    householdequities: 18766526,
    householdcredits: 3660625,
    hnpension: 22851723,
    householdestate: 21447.094,
    hncredit: 3242.975,
    life: 30.386,
    hnmortgate: 9381.093,
    hnlifeinsurance: 1450.602,
    hnmiscellaneous: 1037.946,
    hncash: 892.926,
    hnassets: 99111.534,
    hnliabilities: 14304.212,
    hnnet: 84807.322
  },
  "2014-12-01": {
    nonprofitassets: 7283989,
    nonprofitmiscellaneousassets: 1488291,
    nonprofitmiscellaneous: 881202,
    nonprofitnotes: 17988,
    nonprofitliabilities: 1723338,
    householdassets: 94986999,
    householdnet: 81458313,
    householdcheckable: 951112,
    householddeposits: 7152740,
    householdfunds: 1607560,
    householdmortgages: 112365,
    householddebt: 172439,
    householdliabilitiesother: 629534,
    householdlending: 982685,
    householdliabilities: 68416029,
    householdborrowing: 13498770,
    householdfinance: 13528686,
    householdself: 8966485,
    hngoods: 4859326,
    householdgoods: 26570970,
    householdnotes: 2691566,
    householdbonds: 1024552,
    householdequities: 19421568,
    householdcredits: 3716118,
    hnpension: 23102834,
    householdestate: 21711.644,
    hncredit: 3309.54,
    life: 29.916,
    hnmortgate: 9387.257,
    hnlifeinsurance: 1472.138,
    hnmiscellaneous: 1042.789,
    hncash: 870.32,
    hnassets: 100895.908,
    hnliabilities: 14370.822,
    hnnet: 86525.086
  },
  "2015-03-01": {
    nonprofitassets: 7440577,
    nonprofitmiscellaneousassets: 1500342,
    nonprofitmiscellaneous: 895493,
    nonprofitnotes: 18772,
    nonprofitliabilities: 1744643,
    householdassets: 96669393,
    householdnet: 83182776,
    householdcheckable: 1040660,
    householddeposits: 7383945,
    householdfunds: 1574005,
    householdmortgages: 108793,
    householddebt: 180781,
    householdliabilitiesother: 616245,
    householdlending: 979389,
    householdliabilities: 69735145,
    householdborrowing: 13455596,
    householdfinance: 13486617,
    householdself: 9197108,
    hngoods: 4886055,
    householdgoods: 26934248,
    householdnotes: 2786598,
    householdbonds: 916798,
    householdequities: 19997833,
    householdcredits: 3703396,
    hnpension: 23320846,
    householdestate: 22048.193,
    hncredit: 3307.109,
    life: 31.021,
    hnmortgate: 9351.461,
    hnlifeinsurance: 1492.391,
    hnmiscellaneous: 1045.572,
    hncash: 870.596,
    hnassets: 102723.599,
    hnliabilities: 14335.767,
    hnnet: 88387.832
  },
  "2015-06-01": {
    nonprofitassets: 7501096,
    nonprofitmiscellaneousassets: 1512410,
    nonprofitmiscellaneous: 913162,
    nonprofitnotes: 23157,
    nonprofitliabilities: 1765948,
    householdassets: 97407120,
    householdnet: 83789537,
    householdcheckable: 1109161,
    householddeposits: 7390541,
    householdfunds: 1552906,
    householdmortgages: 105453,
    householddebt: 190907,
    householdliabilitiesother: 629179,
    householdlending: 947967,
    householdliabilities: 69987116,
    householdborrowing: 13585699,
    householdfinance: 13617583,
    householdself: 9376460,
    hngoods: 4944962,
    householdgoods: 27420004,
    householdnotes: 2823048,
    householdbonds: 764803,
    householdequities: 20065183,
    householdcredits: 3587851,
    hnpension: 23407031,
    householdestate: 22475.042,
    hncredit: 3377.763,
    life: 31.884,
    hnmortgate: 9387.85,
    hnlifeinsurance: 1499.37,
    hnmiscellaneous: 1050.646,
    hncash: 842.514,
    hnassets: 103506.936,
    hnliabilities: 14470.369,
    hnnet: 89036.567
  },
  "2015-09-01": {
    nonprofitassets: 7504563,
    nonprofitmiscellaneousassets: 1524293,
    nonprofitmiscellaneous: 929468,
    nonprofitnotes: 32224,
    nonprofitliabilities: 1787253,
    householdassets: 96537936,
    householdnet: 82859628,
    householdcheckable: 971773,
    householddeposits: 7574730,
    householdfunds: 1619840,
    householdmortgages: 101343,
    householddebt: 197731,
    householdliabilitiesother: 556523,
    householdlending: 944939,
    householdliabilities: 68660410,
    householdborrowing: 13646640,
    householdfinance: 13678308,
    householdself: 9516765,
    hngoods: 4974343,
    householdgoods: 27877526,
    householdnotes: 3117348,
    householdbonds: 778960,
    householdequities: 18469879,
    householdcredits: 3896308,
    hnpension: 23104454,
    householdestate: 22903.183,
    hncredit: 3463.129,
    life: 31.668,
    hnmortgate: 9429.257,
    hnlifeinsurance: 1496.968,
    hnmiscellaneous: 1064.754,
    hncash: 843.596,
    hnassets: 102621.813,
    hnliabilities: 14536.093,
    hnnet: 88085.72
  },
  "2015-12-01": {
    nonprofitassets: 7560546,
    nonprofitmiscellaneousassets: 1536142,
    nonprofitmiscellaneous: 944865,
    nonprofitnotes: 31290,
    nonprofitliabilities: 1808558,
    householdassets: 98055178,
    householdnet: 84354615,
    householdcheckable: 1053907,
    householddeposits: 7728141,
    householdfunds: 1649023,
    householdmortgages: 97853,
    householddebt: 217190,
    householdliabilitiesother: 558667,
    householdlending: 917096,
    householdliabilities: 69807074,
    householdborrowing: 13668927,
    householdfinance: 13700563,
    householdself: 9630456,
    hngoods: 5007034,
    householdgoods: 28248104,
    householdnotes: 3157865,
    householdbonds: 465481,
    householdequities: 19181289,
    householdcredits: 3623346,
    hnpension: 23430846,
    householdestate: 23241.07,
    hncredit: 3400.223,
    life: 31.636,
    hnmortgate: 9492.847,
    hnlifeinsurance: 1510.917,
    hnmiscellaneous: 1082.054,
    hncash: 819.243,
    hnassets: 104185.667,
    hnliabilities: 14564.256,
    hnnet: 89621.411
  },
  "2016-03-01": {
    nonprofitassets: 7592611,
    nonprofitmiscellaneousassets: 1565458,
    nonprofitmiscellaneous: 943616,
    nonprofitnotes: 19608,
    nonprofitliabilities: 1814748,
    householdassets: 99188636,
    householdnet: 85502049,
    householdcheckable: 1131802,
    householddeposits: 7887104,
    householdfunds: 1622861,
    householdmortgages: 95864,
    householddebt: 223967,
    householdliabilitiesother: 534484,
    householdlending: 954971,
    householdliabilities: 70596833,
    householdborrowing: 13653773,
    householdfinance: 13686587,
    householdself: 9597922,
    hngoods: 5057149,
    householdgoods: 28591803,
    householdnotes: 3194933,
    householdbonds: 474531,
    householdequities: 19487468,
    householdcredits: 3669464,
    hnpension: 23626782,
    householdestate: 23534.654,
    hncredit: 3412.241,
    life: 32.814,
    hnmortgate: 9483.081,
    hnlifeinsurance: 1525.6,
    hnmiscellaneous: 1092.859,
    hncash: 859.107,
    hnassets: 105333.23,
    hnliabilities: 14557.719,
    hnnet: 90775.511
  },
  "2016-06-01": {
    nonprofitassets: 7714040,
    nonprofitmiscellaneousassets: 1594866,
    nonprofitmiscellaneous: 943205,
    nonprofitnotes: 18432,
    nonprofitliabilities: 1820938,
    householdassets: 100591743,
    householdnet: 86776576,
    householdcheckable: 1089092,
    householddeposits: 7928892,
    householdfunds: 1553735,
    householdmortgages: 94495,
    householddebt: 224831,
    householdliabilitiesother: 544784,
    householdlending: 956528,
    householdliabilities: 71429433,
    householdborrowing: 13781527,
    householdfinance: 13815167,
    householdself: 9700060,
    hngoods: 5109383,
    householdgoods: 29162310,
    householdnotes: 3277175,
    householdbonds: 383377,
    householdequities: 20009867,
    householdcredits: 3660552,
    hnpension: 23869799,
    householdestate: 24052.927,
    hncredit: 3476.821,
    life: 33.64,
    hnmortgate: 9535.091,
    hnlifeinsurance: 1552.162,
    hnmiscellaneous: 1108.746,
    hncash: 862.033,
    hnassets: 106829.208,
    hnliabilities: 14692.9,
    hnnet: 92136.308
  },
  "2016-09-01": {
    nonprofitassets: 7943218,
    nonprofitmiscellaneousassets: 1624254,
    nonprofitmiscellaneous: 944800,
    nonprofitnotes: 21816,
    nonprofitliabilities: 1827128,
    householdassets: 102457363,
    householdnet: 88461712,
    householdcheckable: 975442,
    householddeposits: 8269450,
    householdfunds: 1518341,
    householdmortgages: 92305,
    householddebt: 226286,
    householdliabilitiesother: 566262,
    householdlending: 927764,
    householdliabilities: 72769126,
    householdborrowing: 13962977,
    householdfinance: 13995651,
    householdself: 9922873,
    hngoods: 5131288,
    householdgoods: 29688237,
    householdnotes: 3232106,
    householdbonds: 437478,
    householdequities: 20586169,
    householdcredits: 3669584,
    hnpension: 24209278,
    householdestate: 24556.949,
    hncredit: 3561.635,
    life: 32.674,
    hnmortgate: 9608.794,
    hnlifeinsurance: 1570.048,
    hnmiscellaneous: 1120.177,
    hncash: 835.459,
    hnassets: 108890.908,
    hnliabilities: 14877.979,
    hnnet: 94012.929
  },
  "2016-12-01": {
    nonprofitassets: 8065222,
    nonprofitmiscellaneousassets: 1654885,
    nonprofitmiscellaneous: 945976,
    nonprofitnotes: 22526,
    nonprofitliabilities: 1833318,
    householdassets: 103165649,
    householdnet: 89070371,
    householdcheckable: 973191,
    householddeposits: 8446082,
    householdfunds: 1494361,
    householdmortgages: 92265,
    householddebt: 223394,
    householdliabilitiesother: 545322,
    householdlending: 907549,
    householdliabilities: 73028101,
    householdborrowing: 14063974,
    householdfinance: 14095278,
    householdself: 9977186,
    hngoods: 5162507,
    householdgoods: 30137548,
    householdnotes: 3166353,
    householdbonds: 222303,
    householdequities: 20816602,
    householdcredits: 3388656,
    hnpension: 24317593,
    householdestate: 24975.041,
    hncredit: 3636.436,
    life: 31.304,
    hnmortgate: 9658.822,
    hnlifeinsurance: 1568.114,
    hnmiscellaneous: 1138.767,
    hncash: 815.284,
    hnassets: 109689.531,
    hnliabilities: 14982.62,
    hnnet: 94706.911
  },
  "2017-03-01": {
    nonprofitassets: 8175126,
    nonprofitmiscellaneousassets: 1702801,
    nonprofitmiscellaneous: 964812,
    nonprofitnotes: 30667,
    nonprofitliabilities: 1856504,
    householdassets: 105554611,
    householdnet: 91417527,
    householdcheckable: 1142368,
    householddeposits: 8515770,
    householdfunds: 1452338,
    householdmortgages: 91417,
    householddebt: 217448,
    householdliabilitiesother: 550211,
    householdlending: 924418,
    householdliabilities: 74932567,
    householdborrowing: 14104402,
    householdfinance: 14137084,
    householdself: 10029769,
    hngoods: 5218977,
    householdgoods: 30622044,
    householdnotes: 3152183,
    householdbonds: 189395,
    householdequities: 22018633,
    householdcredits: 3341578,
    hnpension: 24778823,
    householdestate: 25403.067,
    hncredit: 3630.415,
    life: 32.682,
    hnmortgate: 9706.328,
    hnlifeinsurance: 1586.233,
    hnmiscellaneous: 1142.637,
    hncash: 833.001,
    hnassets: 112134.323,
    hnliabilities: 15028.776,
    hnnet: 97105.547
  },
  "2017-06-01": {
    nonprofitassets: 8390982,
    nonprofitmiscellaneousassets: 1750182,
    nonprofitmiscellaneous: 980271,
    nonprofitnotes: 26029,
    nonprofitliabilities: 1879690,
    householdassets: 107271031,
    householdnet: 92985791,
    householdcheckable: 1129589,
    householddeposits: 8424155,
    householdfunds: 1434340,
    householdmortgages: 92328,
    householddebt: 227956,
    householdliabilitiesother: 567494,
    householdlending: 923549,
    householdliabilities: 76118033,
    householdborrowing: 14252139,
    householdfinance: 14285240,
    householdself: 10297327,
    hngoods: 5242651,
    householdgoods: 31152998,
    householdnotes: 3218875,
    householdbonds: 153960,
    householdequities: 22674626,
    householdcredits: 3372835,
    hnpension: 25115340,
    householdestate: 25910.347,
    hncredit: 3682.716,
    life: 33.101,
    hnmortgate: 9773.973,
    hnlifeinsurance: 1594.598,
    hnmiscellaneous: 1151.673,
    hncash: 831.221,
    hnassets: 114025.839,
    hnliabilities: 15184.659,
    hnnet: 98841.18
  },
  "2017-09-01": {
    nonprofitassets: 8595026,
    nonprofitmiscellaneousassets: 1798488,
    nonprofitmiscellaneous: 1002296,
    nonprofitnotes: 31842,
    nonprofitliabilities: 1902876,
    householdassets: 109165305,
    householdnet: 94739680,
    householdcheckable: 1035566,
    householddeposits: 8551474,
    householdfunds: 1491867,
    householdmortgages: 90258,
    householddebt: 223255,
    householdliabilitiesother: 564795,
    householdlending: 895994,
    householdliabilities: 77488889,
    householdborrowing: 14392469,
    householdfinance: 14425625,
    householdself: 10508895,
    hngoods: 5258269,
    householdgoods: 31676416,
    householdnotes: 3122166,
    householdbonds: 165130,
    householdequities: 23424999,
    householdcredits: 3287296,
    hnpension: 25500100,
    householdestate: 26418.147,
    hncredit: 3749.213,
    life: 33.156,
    hnmortgate: 9855.206,
    hnlifeinsurance: 1610.315,
    hnmiscellaneous: 1182.383,
    hncash: 805.736,
    hnassets: 116072.021,
    hnliabilities: 15326.205,
    hnnet: 100745.816
  },
  "2017-12-01": {
    nonprofitassets: 8833404,
    nonprofitmiscellaneousassets: 1845140,
    nonprofitmiscellaneous: 1018886,
    nonprofitnotes: 36734,
    nonprofitliabilities: 1926062,
    householdassets: 111855631,
    householdnet: 97226819,
    householdcheckable: 1139463,
    householddeposits: 8574234,
    householdfunds: 1566041,
    householdmortgages: 89344,
    householddebt: 225715,
    householdliabilitiesother: 610471,
    householdlending: 849770,
    householdliabilities: 79716905,
    householdborrowing: 14595731,
    householdfinance: 14628812,
    householdself: 10796516,
    hngoods: 5299620,
    householdgoods: 32138726,
    householdnotes: 3221078,
    householdbonds: 135208,
    householdequities: 24683452,
    householdcredits: 3356286,
    hnpension: 25931585,
    householdestate: 26839.106,
    hncredit: 3830.752,
    life: 33.081,
    hnmortgate: 9928.793,
    hnlifeinsurance: 1626.562,
    hnmiscellaneous: 1192.996,
    hncash: 760.426,
    hnassets: 118951.164,
    hnliabilities: 15535.988,
    hnnet: 103415.176
  },
  "2018-03-01": {
    nonprofitassets: 8793042,
    nonprofitmiscellaneousassets: 1833542,
    nonprofitmiscellaneous: 1014295,
    nonprofitnotes: 46720,
    nonprofitliabilities: 1922636,
    householdassets: 113025000,
    householdnet: 98357157,
    householdcheckable: 1238252,
    householddeposits: 8780067,
    householdfunds: 1535893,
    householdmortgages: 88094,
    householddebt: 230878,
    householdliabilitiesother: 615621,
    householdlending: 850165,
    householdliabilities: 80195499,
    householdborrowing: 14632323,
    householdfinance: 14667843,
    householdself: 10861566,
    hngoods: 5354137,
    householdgoods: 32829501,
    householdnotes: 3348820,
    householdbonds: 141356,
    householdequities: 24631975,
    householdcredits: 3490176,
    hnpension: 25964880,
    householdestate: 27475.364,
    hncredit: 3819.874,
    life: 35.52,
    hnmortgate: 9965.95,
    hnlifeinsurance: 1637.498,
    hnmiscellaneous: 1205.026,
    hncash: 762.071,
    hnassets: 120082.922,
    hnliabilities: 15576.184,
    hnnet: 104506.738
  },
  "2018-06-01": {
    nonprofitassets: 8934206,
    nonprofitmiscellaneousassets: 1821945,
    nonprofitmiscellaneous: 1011482,
    nonprofitnotes: 46661,
    nonprofitliabilities: 1919210,
    householdassets: 114490776,
    householdnet: 99691172,
    householdcheckable: 1103638,
    householddeposits: 8833821,
    householdfunds: 1571783,
    householdmortgages: 86510,
    householddebt: 248130,
    householdliabilitiesother: 610099,
    householdlending: 841192,
    householdliabilities: 81216579,
    householdborrowing: 14764228,
    householdfinance: 14799604,
    householdself: 11142373,
    hngoods: 5415672,
    householdgoods: 33274197,
    householdnotes: 3518007,
    householdbonds: 139962,
    householdequities: 24998700,
    householdcredits: 3657969,
    hnpension: 26200095,
    householdestate: 27858.525,
    hncredit: 3858.156,
    life: 35.376,
    hnmortgate: 10047.843,
    hnlifeinsurance: 1655.465,
    hnmiscellaneous: 1211.543,
    hncash: 754.682,
    hnassets: 121702.657,
    hnliabilities: 15707.332,
    hnnet: 105995.325
  },
  "2018-09-01": {
    nonprofitassets: 8915571,
    nonprofitmiscellaneousassets: 1810568,
    nonprofitmiscellaneous: 1011305,
    nonprofitnotes: 44711,
    nonprofitliabilities: 1915784,
    householdassets: 116516284,
    householdnet: 101543515,
    householdcheckable: 922521,
    householddeposits: 8880183,
    householdfunds: 1568666,
    householdmortgages: 84926,
    householddebt: 246340,
    householdliabilitiesother: 612485,
    householdlending: 845263,
    householdliabilities: 82839228,
    householdborrowing: 14936541,
    householdfinance: 14972769,
    householdself: 11146162,
    hngoods: 5461414,
    householdgoods: 33677056,
    householdnotes: 3800743,
    householdbonds: 106833,
    householdequities: 26076003,
    householdcredits: 3907576,
    hnpension: 26591412,
    householdestate: 28215.642,
    hncredit: 3934.581,
    life: 36.228,
    hnmortgate: 10143.135,
    hnlifeinsurance: 1675.931,
    hnmiscellaneous: 1225.512,
    hncash: 760.337,
    hnassets: 123723.996,
    hnliabilities: 15877.248,
    hnnet: 107846.748
  },
  "2018-12-01": {
    nonprofitassets: 8827759,
    nonprofitmiscellaneousassets: 1798991,
    nonprofitmiscellaneous: 1008975,
    nonprofitnotes: 45900,
    nonprofitliabilities: 1912358,
    householdassets: 112997803,
    householdnet: 97901465,
    householdcheckable: 984417,
    householddeposits: 9007843,
    householdfunds: 1726330,
    householdmortgages: 83497,
    householddebt: 270363,
    householdliabilitiesother: 580017,
    householdlending: 841880,
    householdliabilities: 78977600,
    householdborrowing: 15061337,
    householdfinance: 15096338,
    householdself: 11218872,
    hngoods: 5512703,
    householdgoods: 34020203,
    householdnotes: 3897182,
    householdbonds: 154716,
    householdequities: 22429285,
    householdcredits: 4051898,
    hnpension: 25812856,
    householdestate: 28507.5,
    hncredit: 4007.042,
    life: 35.001,
    hnmortgate: 10203.915,
    hnlifeinsurance: 1659.093,
    hnmiscellaneous: 1245.126,
    hncash: 758.383,
    hnassets: 120129.23,
    hnliabilities: 15999.721,
    hnnet: 104129.509
  },
  "2019-03-01": {
    nonprofitassets: 9036314,
    nonprofitmiscellaneousassets: 1813872,
    nonprofitmiscellaneous: 1014579,
    nonprofitnotes: 46604,
    nonprofitliabilities: 1919405,
    householdassets: 118645068,
    householdnet: 103554322,
    householdcheckable: 1062578,
    householddeposits: 9173466,
    householdfunds: 1849429,
    householdmortgages: 82815,
    householddebt: 256106,
    householdliabilitiesother: 570079,
    householdlending: 833118,
    householdliabilities: 84057256,
    householdborrowing: 15054299,
    householdfinance: 15090746,
    householdself: 11385733,
    hngoods: 5577304,
    householdgoods: 34587812,
    householdnotes: 4010904,
    householdbonds: -12282,
    householdequities: 26097193,
    householdcredits: 3998622,
    hnpension: 26722404,
    householdestate: 29010.508,
    hncredit: 3994.469,
    life: 36.447,
    hnmortgate: 10233.645,
    hnlifeinsurance: 1688.989,
    hnmiscellaneous: 1245.724,
    hncash: 750.303,
    hnassets: 125970.641,
    hnliabilities: 15995.572,
    hnnet: 109975.069
  },
  "2019-06-01": {
    nonprofitassets: 9254312,
    nonprofitmiscellaneousassets: 1828708,
    nonprofitmiscellaneous: 1019765,
    nonprofitnotes: 48184,
    nonprofitliabilities: 1926452,
    householdassets: 120924272,
    householdnet: 105660605,
    householdcheckable: 941138,
    householddeposits: 9180062,
    householdfunds: 1807543,
    householdmortgages: 82093,
    householddebt: 270297,
    householdliabilitiesother: 588705,
    householdlending: 839736,
    householdliabilities: 85904041,
    householdborrowing: 15227918,
    householdfinance: 15263667,
    householdself: 11676084,
    hngoods: 5645610,
    householdgoods: 35020231,
    householdnotes: 4175024,
    householdbonds: 189914,
    householdequities: 26988826,
    householdcredits: 4364938,
    hnpension: 27149356,
    householdestate: 29374.621,
    hncredit: 4052.047,
    life: 35.749,
    hnmortgate: 10316.869,
    hnlifeinsurance: 1702.538,
    hnmiscellaneous: 1253.82,
    hncash: 757.643,
    hnassets: 128452.603,
    hnliabilities: 16170.354,
    hnnet: 112282.249
  },
  "2019-09-01": {
    nonprofitassets: 9394223,
    nonprofitmiscellaneousassets: 1843497,
    nonprofitmiscellaneous: 1024925,
    nonprofitnotes: 39879,
    nonprofitliabilities: 1933499,
    householdassets: 121691422,
    householdnet: 106262049,
    householdcheckable: 736858,
    householddeposits: 9379095,
    householdfunds: 2041590,
    householdmortgages: 81408,
    householddebt: 268121,
    householdliabilitiesother: 587628,
    householdlending: 870258,
    householdliabilities: 86341698,
    householdborrowing: 15392750,
    householdfinance: 15429373,
    householdself: 11897895,
    hngoods: 5692177,
    householdgoods: 35349724,
    householdnotes: 3992400,
    householdbonds: 198044,
    householdequities: 26877179,
    householdcredits: 4190444,
    hnpension: 27372377,
    householdestate: 29657.547,
    hncredit: 4124.149,
    life: 36.623,
    hnmortgate: 10412.852,
    hnlifeinsurance: 1718.751,
    hnmiscellaneous: 1257.25,
    hncash: 788.85,
    hnassets: 129354.356,
    hnliabilities: 16337.947,
    hnnet: 113016.409
  },
  "2019-12-01": {
    nonprofitassets: 9452725,
    nonprofitmiscellaneousassets: 1858538,
    nonprofitmiscellaneous: 1030264,
    nonprofitnotes: 36713,
    nonprofitliabilities: 1940546,
    householdassets: 125571076,
    householdnet: 109976252,
    householdcheckable: 992649,
    householddeposits: 9560709,
    householdfunds: 2156491,
    householdmortgages: 80901,
    householddebt: 281279,
    householdliabilitiesother: 603897,
    householdlending: 864845,
    householdliabilities: 89847447,
    householdborrowing: 15558329,
    householdfinance: 15594824,
    householdself: 11939529,
    hngoods: 5730542,
    householdgoods: 35723629,
    householdnotes: 4084017,
    householdbonds: 300293,
    householdequities: 28936649,
    householdcredits: 4384310,
    hnpension: 27994081,
    householdestate: 29993.087,
    hncredit: 4192.191,
    life: 36.495,
    hnmortgate: 10480.962,
    hnlifeinsurance: 1731.284,
    hnmiscellaneous: 1286.901,
    hncash: 783.944,
    hnassets: 133281.813,
    hnliabilities: 16505.106,
    hnnet: 116776.707
  },
  "2020-03-01": {
    nonprofitassets: 9386295,
    nonprofitmiscellaneousassets: 1870129,
    nonprofitmiscellaneous: 1037546,
    nonprofitnotes: 25505,
    nonprofitliabilities: 1946792,
    householdassets: 119373879,
    householdnet: 103712804,
    householdcheckable: 1106991,
    householddeposits: 9876041,
    householdfunds: 2339618,
    householdmortgages: 80599,
    householddebt: 315179,
    householdliabilitiesother: 617666,
    householdlending: 1009970,
    householdliabilities: 82990805,
    householdborrowing: 15622026,
    householdfinance: 15661075,
    householdself: 12078615,
    hngoods: 5758714,
    householdgoods: 36383074,
    householdnotes: 3634166,
    householdbonds: 512435,
    householdequities: 22658643,
    householdcredits: 4146601,
    hnpension: 26750974,
    householdestate: 30624.36,
    hncredit: 4147.821,
    life: 39.049,
    hnmortgate: 10541.36,
    hnlifeinsurance: 1733.659,
    hnmiscellaneous: 1289.694,
    hncash: 929.371,
    hnassets: 127019.101,
    hnliabilities: 16570.321,
    hnnet: 110448.78
  },
  "2020-06-01": {
    nonprofitassets: 9610046,
    nonprofitmiscellaneousassets: 1881566,
    nonprofitmiscellaneous: 1044159,
    nonprofitnotes: 32388,
    nonprofitliabilities: 1953038,
    householdassets: 127154370,
    householdnet: 111486461,
    householdcheckable: 1362397,
    householddeposits: 10408840,
    householdfunds: 2633362,
    householdmortgages: 79830,
    householddebt: 307166,
    householdliabilitiesother: 602994,
    householdlending: 989054,
    householdliabilities: 90073934,
    householdborrowing: 15629816,
    householdfinance: 15667908,
    householdself: 12234373,
    hngoods: 5760661,
    householdgoods: 37080436,
    householdnotes: 3262752,
    householdbonds: 579875,
    householdequities: 27489569,
    householdcredits: 3842627,
    hnpension: 28039379,
    householdestate: 31319.775,
    hncredit: 4097.271,
    life: 38.092,
    hnmortgate: 10622.385,
    hnlifeinsurance: 1776.109,
    hnmiscellaneous: 1312.523,
    hncash: 909.224,
    hnassets: 135006.321,
    hnliabilities: 16576.787,
    hnnet: 118429.533
  },
  "2020-09-01": {
    nonprofitassets: 9739425,
    nonprofitmiscellaneousassets: 1892862,
    nonprofitmiscellaneous: 1049840,
    nonprofitnotes: 38340,
    nonprofitliabilities: 1959284,
    householdassets: 131650283,
    householdnet: 115718464,
    householdcheckable: 1475208,
    householddeposits: 10510217,
    householdfunds: 2629567,
    householdmortgages: 79652,
    householddebt: 296253,
    householdliabilitiesother: 674736,
    householdlending: 956497,
    householdliabilities: 93624834,
    householdborrowing: 15894139,
    householdfinance: 15931818,
    householdself: 12476004,
    hngoods: 5977776,
    householdgoods: 38025449,
    householdnotes: 3291912,
    householdbonds: 353434,
    householdequities: 30109224,
    householdcredits: 3645346,
    hnpension: 28695544,
    householdestate: 32047.673,
    hncredit: 4142.683,
    life: 37.679,
    hnmortgate: 10780.467,
    hnlifeinsurance: 1811.913,
    hnmiscellaneous: 1349.914,
    hncash: 876.845,
    hnassets: 139615.663,
    hnliabilities: 16841.262,
    hnnet: 122774.4
  },
  "2020-12-01": {
    nonprofitassets: 10033822,
    nonprofitmiscellaneousassets: 1903460,
    nonprofitmiscellaneous: 1058076,
    nonprofitnotes: 44089,
    nonprofitliabilities: 1965530,
    householdassets: 140222261,
    householdnet: 124006208,
    householdcheckable: 2689142,
    householddeposits: 10069492,
    householdfunds: 2535022,
    householdmortgages: 79267,
    householddebt: 302412,
    householdliabilitiesother: 772390,
    householdlending: 984023,
    householdliabilities: 101152127,
    householdborrowing: 16179462,
    householdfinance: 16216053,
    householdself: 12891194,
    hngoods: 6081015,
    householdgoods: 39070134,
    householdnotes: 3638008,
    householdbonds: 436727,
    householdequities: 34908973,
    householdcredits: 4074735,
    hnpension: 29799896,
    householdestate: 32989.119,
    hncredit: 4184.927,
    life: 36.591,
    hnmortgate: 10919.733,
    hnlifeinsurance: 1866.971,
    hnmiscellaneous: 1368.179,
    hncash: 904.756,
    hnassets: 148466.989,
    hnliabilities: 17123.507,
    hnnet: 131343.482
  },
  "2021-03-01": {
    nonprofitassets: 10136618,
    nonprofitmiscellaneousassets: 1911501,
    nonprofitmiscellaneous: 1060555,
    nonprofitnotes: 52635,
    nonprofitliabilities: 1970431,
    householdassets: 145624719,
    householdnet: 129213402,
    householdcheckable: 3063777,
    householddeposits: 10553592,
    householdfunds: 2739531,
    householdmortgages: 78511,
    householddebt: 302304,
    householdliabilitiesother: 862038,
    householdlending: 1003199,
    householdliabilities: 105178616,
    householdborrowing: 16372605,
    householdfinance: 16411317,
    householdself: 13174978,
    hngoods: 6267076,
    householdgoods: 40446103,
    householdnotes: 3252948,
    householdbonds: 214956,
    householdequities: 37772469,
    householdcredits: 3467904,
    hnpension: 30199676,
    householdestate: 34179.027,
    hncredit: 4167.218,
    life: 38.712,
    hnmortgate: 11041.045,
    hnlifeinsurance: 1854.246,
    hnmiscellaneous: 1380.077,
    hncash: 924.688,
    hnassets: 153956.887,
    hnliabilities: 17321.193,
    hnnet: 136635.694
  },
  "2021-06-01": {
    nonprofitassets: 10359636,
    nonprofitmiscellaneousassets: 1919505,
    nonprofitmiscellaneous: 1063983,
    nonprofitnotes: 0,
    nonprofitliabilities: 1975332,
    householdassets: 151420011,
    householdnet: 134670679,
    householdcheckable: 3426248,
    householddeposits: 10366219,
    householdfunds: 2785327,
    householdmortgages: 77115,
    householddebt: 303898,
    householdliabilitiesother: 884157,
    householdlending: 1018147,
    householdliabilities: 109181391,
    householdborrowing: 16711439,
    householdfinance: 16749332,
    householdself: 13810196,
    hngoods: 6688916,
    householdgoods: 42238620,
    householdnotes: 2758515,
    householdbonds: 142258,
    householdequities: 40679992,
    householdcredits: 2900773,
    hnpension: 30943294,
    householdestate: 35549.704,
    hncredit: 4259.601,
    life: 37.893,
    hnmortgate: 11263.783,
    hnlifeinsurance: 1886.531,
    hnmiscellaneous: 1390.633,
    hncash: 941.032,
    hnassets: 159967.47,
    hnliabilities: 17660.681,
    hnnet: 142306.789
  },
  "2021-09-01": {
    nonprofitassets: 10591409,
    nonprofitmiscellaneousassets: 1927539,
    nonprofitmiscellaneous: 1068167,
    nonprofitnotes: 0,
    nonprofitliabilities: 1980233,
    householdassets: 154185299,
    householdnet: 137131750,
    householdcheckable: 3547099,
    householddeposits: 10532858,
    householdfunds: 2702128,
    householdmortgages: 75851,
    householddebt: 317365,
    householdliabilitiesother: 862954,
    householdlending: 1066545,
    householdliabilities: 110398948,
    householdborrowing: 17015682,
    householdfinance: 17053549,
    householdself: 14592181,
    hngoods: 6974190,
    householdgoods: 43786351,
    householdnotes: 2752975,
    householdbonds: 289775,
    householdequities: 40597130,
    householdcredits: 3042750,
    hnpension: 31021611,
    householdestate: 36812.161,
    hncredit: 4338.066,
    life: 37.867,
    hnmortgate: 11497.297,
    hnlifeinsurance: 1897.139,
    hnmiscellaneous: 1418.897,
    hncash: 990.694,
    hnassets: 162957.885,
    hnliabilities: 17965.615,
    hnnet: 144992.27
  },
  "2021-12-01": {
    nonprofitassets: 10845915,
    nonprofitmiscellaneousassets: 1935598,
    nonprofitmiscellaneous: 1072213,
    nonprofitnotes: 0,
    nonprofitliabilities: 1985134,
    householdassets: 159621787,
    householdnet: 142182076,
    householdcheckable: 3887533,
    householddeposits: 10837660,
    householdfunds: 2789564,
    householdmortgages: 74537,
    householddebt: 356481,
    householdliabilitiesother: 869598,
    householdlending: 1116734,
    householdliabilities: 114228619,
    householdborrowing: 17402971,
    householdfinance: 17439712,
    householdself: 15125070,
    hngoods: 7287687,
    householdgoods: 45393168,
    householdnotes: 2558526,
    householdbonds: 242625,
    householdequities: 42572882,
    householdcredits: 2801151,
    hnpension: 31751872,
    householdestate: 38105.481,
    hncredit: 4434.378,
    life: 36.74,
    hnmortgate: 11742.514,
    hnlifeinsurance: 1929.4,
    hnmiscellaneous: 1420.219,
    hncash: 1042.198,
    hnassets: 168642.144,
    hnliabilities: 18352.633,
    hnnet: 150289.512
  }
};
