/**FBAPLIA027N	FBCFBAA027N	FBEIPYA027N	FBIPSEA027N	FBMPLIA027N	FBOAREA027N	GSEGSLA027N	ICPFIPA027N	MAFRBIA027N	MMMFFAA027N	REITMIA027N
otherpayables	bonds	equities	pensions	paper	otherreceivables	notes	insurance	currency	assets	estates */
export const financialbalance = {
  "1945-12-01": {
    deposits: 205441,
    fundshares: 1245,
    self: 0,
    equities: 5297,
    funds: 0,
    foreign: 237,
    shares: 48216,
    domestic: 959,
    equipment: 857,
    structures: 4489,
    intellectual: 5,
    otherpayables: 4684,
    bonds: 131,
    treasury: 0,
    equityloan: 0,
    homeloan: 72,
    pensions: 49787,
    moneyfunds: 0,
    paper: 220,
    otherreceivables: 5748,
    repurchaseshares: 4688,
    repurchases: 24810,
    interbank: 25413,
    mortgages: 22275,
    notes: 700,
    bondnotes: 873,
    insurance: 102105,
    monetarypaper: 177,
    monetarygold: 20065,
    assets: 0,
    estates: 0
  },
  "1946-03-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1946-06-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1946-09-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1946-12-01": {
    deposits: 196903,
    fundshares: 1298,
    self: 0,
    equities: 5451,
    funds: 0,
    foreign: 256,
    shares: 48353,
    domestic: 967,
    equipment: 1035,
    structures: 5784,
    intellectual: 6,
    otherpayables: 5041,
    bonds: 193,
    treasury: 0,
    equityloan: 0,
    homeloan: 84,
    pensions: 56821,
    moneyfunds: 0,
    paper: 436,
    otherreceivables: 3545,
    repurchaseshares: 3487,
    repurchases: 29644,
    interbank: 26497,
    mortgages: 27248,
    notes: 613,
    bondnotes: 1168,
    insurance: 113881,
    monetarypaper: 187,
    monetarygold: 20529,
    assets: 0,
    estates: 0
  },
  "1947-03-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1947-06-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1947-09-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1947-12-01": {
    deposits: 203743,
    fundshares: 1413,
    self: 0,
    equities: 5944,
    funds: 0,
    foreign: 278,
    shares: 48741,
    domestic: 976,
    equipment: 1317,
    structures: 6861,
    intellectual: 7,
    otherpayables: 5953,
    bonds: 324,
    treasury: 0,
    equityloan: 0,
    homeloan: 101,
    pensions: 63297,
    moneyfunds: 0,
    paper: 593,
    otherreceivables: 3576,
    repurchaseshares: 3118,
    repurchases: 36065,
    interbank: 28430,
    mortgages: 33117,
    notes: 583,
    bondnotes: 1284,
    insurance: 125924,
    monetarypaper: 196,
    monetarygold: 22754,
    assets: 0,
    estates: 0
  },
  "1948-03-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1948-06-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1948-09-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1948-12-01": {
    deposits: 208055,
    fundshares: 1516,
    self: 0,
    equities: 6217,
    funds: 0,
    foreign: 304,
    shares: 48923,
    domestic: 981,
    equipment: 1576,
    structures: 7250,
    intellectual: 8,
    otherpayables: 6620,
    bonds: 760,
    treasury: 0,
    equityloan: 0,
    homeloan: 117,
    pensions: 69520,
    moneyfunds: 0,
    paper: 770,
    otherreceivables: 3550,
    repurchaseshares: 4260,
    repurchases: 40454,
    interbank: 30902,
    mortgages: 39175,
    notes: 604,
    bondnotes: 1563,
    insurance: 138024,
    monetarypaper: 201,
    monetarygold: 24244,
    assets: 0,
    estates: 0
  },
  "1949-03-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1949-06-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1949-09-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1949-12-01": {
    deposits: 207821,
    fundshares: 3071,
    self: 0,
    equities: 8839,
    funds: 0,
    foreign: 333,
    shares: 49254,
    domestic: 991,
    equipment: 1731,
    structures: 6483,
    intellectual: 8,
    otherpayables: 7706,
    bonds: 1067,
    treasury: 0,
    equityloan: 0,
    homeloan: 133,
    pensions: 75822,
    moneyfunds: 0,
    paper: 951,
    otherreceivables: 3807,
    repurchaseshares: 5166,
    repurchases: 42015,
    interbank: 27307,
    mortgages: 44369,
    notes: 599,
    bondnotes: 1454,
    insurance: 150502,
    monetarypaper: 211,
    monetarygold: 24427,
    assets: 0,
    estates: 0
  },
  "1950-03-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1950-06-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1950-09-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1950-12-01": {
    deposits: 218205,
    fundshares: 3344,
    self: 0,
    equities: 9907,
    funds: 0,
    foreign: 369,
    shares: 49672,
    domestic: 991,
    equipment: 2041,
    structures: 7138,
    intellectual: 8,
    otherpayables: 9323,
    bonds: 1281,
    treasury: 0,
    equityloan: 0,
    homeloan: 177,
    pensions: 82380,
    moneyfunds: 0,
    paper: 1112,
    otherreceivables: 4566,
    repurchaseshares: 7171,
    repurchases: 52699,
    interbank: 30232,
    mortgages: 53484,
    notes: 606,
    bondnotes: 1860,
    insurance: 163045,
    monetarypaper: 225,
    monetarygold: 22706,
    assets: 0,
    estates: 0
  },
  "1951-03-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1951-06-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1951-09-01": {
    deposits: 0,
    fundshares: 0,
    self: 0,
    equities: 0,
    funds: 0,
    foreign: 0,
    shares: 0,
    domestic: 0,
    equipment: 0,
    structures: 0,
    intellectual: 0,
    otherpayables: 0,
    bonds: 0,
    treasury: 0,
    equityloan: 0,
    homeloan: 0,
    pensions: 0,
    moneyfunds: 0,
    paper: 0,
    otherreceivables: 0,
    repurchaseshares: 0,
    repurchases: 0,
    interbank: 0,
    mortgages: 0,
    notes: 0,
    bondnotes: 0,
    insurance: 0,
    monetarypaper: 0,
    monetarygold: 0,
    assets: 0,
    estates: 0
  },
  "1951-12-01": {
    deposits: 232893,
    fundshares: 3482,
    self: 0,
    equities: 10934,
    funds: 0,
    foreign: 412,
    shares: 50063,
    domestic: 998,
    equipment: 2309,
    structures: 8313,
    intellectual: 9,
    otherpayables: 9750,
    bonds: 1667,
    treasury: 0,
    equityloan: 0,
    homeloan: 271,
    pensions: 93136,
    moneyfunds: 0,
    paper: 1559,
    otherreceivables: 4390,
    repurchaseshares: 6862,
    repurchases: 59245,
    interbank: 33347,
    mortgages: 61315,
    notes: 649,
    bondnotes: 2098,
    insurance: 181014,
    monetarypaper: 237,
    monetarygold: 22695,
    assets: 0,
    estates: 0
  },
  "1952-03-01": {
    deposits: 232768,
    fundshares: 3612,
    self: 0,
    equities: 12211,
    funds: 0,
    foreign: 412,
    shares: 50222,
    domestic: 1000,
    equipment: 2347,
    structures: 8448,
    intellectual: 9,
    otherpayables: 10125,
    bonds: 1558,
    treasury: 0,
    equityloan: 0,
    homeloan: 290,
    pensions: 94424,
    moneyfunds: 0,
    paper: 1781,
    otherreceivables: 4993,
    repurchaseshares: 6595,
    repurchases: 59058,
    interbank: 33224,
    mortgages: 62834,
    notes: 669,
    bondnotes: 1986,
    insurance: 185906,
    monetarypaper: 242,
    monetarygold: 23291,
    assets: 0,
    estates: 0
  },
  "1952-06-01": {
    deposits: 235674,
    fundshares: 3696,
    self: 0,
    equities: 12664,
    funds: 0,
    foreign: 424,
    shares: 50363,
    domestic: 1008,
    equipment: 2393,
    structures: 8484,
    intellectual: 9,
    otherpayables: 10277,
    bonds: 1640,
    treasury: 0,
    equityloan: 0,
    homeloan: 300,
    pensions: 97308,
    moneyfunds: 0,
    paper: 1923,
    otherreceivables: 5190,
    repurchaseshares: 7331,
    repurchases: 61677,
    interbank: 32360,
    mortgages: 64880,
    notes: 681,
    bondnotes: 2085,
    insurance: 191060,
    monetarypaper: 245,
    monetarygold: 23346,
    assets: 0,
    estates: 0
  },
  "1952-09-01": {
    deposits: 241008,
    fundshares: 3837,
    self: 0,
    equities: 13012,
    funds: 0,
    foreign: 437,
    shares: 50476,
    domestic: 1016,
    equipment: 2393,
    structures: 8531,
    intellectual: 10,
    otherpayables: 10867,
    bonds: 1612,
    treasury: 0,
    equityloan: 0,
    homeloan: 306,
    pensions: 100198,
    moneyfunds: 0,
    paper: 2090,
    otherreceivables: 5494,
    repurchaseshares: 7556,
    repurchases: 63502,
    interbank: 33857,
    mortgages: 67000,
    notes: 688,
    bondnotes: 2178,
    insurance: 196419,
    monetarypaper: 247,
    monetarygold: 23342,
    assets: 0,
    estates: 0
  },
  "1952-12-01": {
    deposits: 247584,
    fundshares: 3932,
    self: 0,
    equities: 13866,
    funds: 0,
    foreign: 460,
    shares: 50591,
    domestic: 1055,
    equipment: 2413,
    structures: 8616,
    intellectual: 10,
    otherpayables: 10574,
    bonds: 1682,
    treasury: 0,
    equityloan: 0,
    homeloan: 315,
    pensions: 103473,
    moneyfunds: 0,
    paper: 1915,
    otherreceivables: 5421,
    repurchaseshares: 8452,
    repurchases: 67292,
    interbank: 33877,
    mortgages: 68939,
    notes: 698,
    bondnotes: 2155,
    insurance: 202583,
    monetarypaper: 253,
    monetarygold: 23187,
    assets: 0,
    estates: 0
  },
  "1953-03-01": {
    deposits: 245255,
    fundshares: 3867,
    self: 0,
    equities: 13823,
    funds: 0,
    foreign: 473,
    shares: 50705,
    domestic: 1064,
    equipment: 2462,
    structures: 8572,
    intellectual: 10,
    otherpayables: 10876,
    bonds: 2075,
    treasury: 0,
    equityloan: 0,
    homeloan: 338,
    pensions: 106667,
    moneyfunds: 0,
    paper: 2070,
    otherreceivables: 5514,
    repurchaseshares: 8736,
    repurchases: 68922,
    interbank: 33664,
    mortgages: 70518,
    notes: 723,
    bondnotes: 2051,
    insurance: 207940,
    monetarypaper: 257,
    monetarygold: 22563,
    assets: 0,
    estates: 0
  },
  "1953-06-01": {
    deposits: 245505,
    fundshares: 3778,
    self: 0,
    equities: 13964,
    funds: 0,
    foreign: 487,
    shares: 50807,
    domestic: 1077,
    equipment: 2522,
    structures: 8629,
    intellectual: 11,
    otherpayables: 10864,
    bonds: 2624,
    treasury: 0,
    equityloan: 0,
    homeloan: 354,
    pensions: 109551,
    moneyfunds: 0,
    paper: 1951,
    otherreceivables: 5430,
    repurchaseshares: 7815,
    repurchases: 69711,
    interbank: 32979,
    mortgages: 72949,
    notes: 739,
    bondnotes: 2066,
    insurance: 213388,
    monetarypaper: 260,
    monetarygold: 22463,
    assets: 0,
    estates: 0
  },
  "1953-09-01": {
    deposits: 250983,
    fundshares: 3753,
    self: 0,
    equities: 14207,
    funds: 0,
    foreign: 502,
    shares: 50921,
    domestic: 1089,
    equipment: 2588,
    structures: 8679,
    intellectual: 12,
    otherpayables: 11976,
    bonds: 2911,
    treasury: 0,
    equityloan: 0,
    homeloan: 360,
    pensions: 112634,
    moneyfunds: 0,
    paper: 2229,
    otherreceivables: 5608,
    repurchaseshares: 8727,
    repurchases: 71521,
    interbank: 33004,
    mortgages: 75207,
    notes: 748,
    bondnotes: 2185,
    insurance: 218721,
    monetarypaper: 262,
    monetarygold: 22128,
    assets: 0,
    estates: 0
  },
  "1953-12-01": {
    deposits: 256706,
    fundshares: 4146,
    self: 0,
    equities: 15181,
    funds: 0,
    foreign: 518,
    shares: 50992,
    domestic: 1094,
    equipment: 2627,
    structures: 8601,
    intellectual: 12,
    otherpayables: 11909,
    bonds: 3057,
    treasury: 0,
    equityloan: 0,
    homeloan: 368,
    pensions: 115793,
    moneyfunds: 0,
    paper: 2222,
    otherreceivables: 5823,
    repurchaseshares: 8635,
    repurchases: 70559,
    interbank: 34293,
    mortgages: 77293,
    notes: 758,
    bondnotes: 2159,
    insurance: 223933,
    monetarypaper: 265,
    monetarygold: 22030,
    assets: 0,
    estates: 0
  },
  "1954-03-01": {
    deposits: 252919,
    fundshares: 4512,
    self: 0,
    equities: 16251,
    funds: 0,
    foreign: 533,
    shares: 51146,
    domestic: 1107,
    equipment: 2672,
    structures: 8502,
    intellectual: 12,
    otherpayables: 12162,
    bonds: 3092,
    treasury: 0,
    equityloan: 0,
    homeloan: 403,
    pensions: 118781,
    moneyfunds: 0,
    paper: 2454,
    otherreceivables: 5698,
    repurchaseshares: 7735,
    repurchases: 68858,
    interbank: 33100,
    mortgages: 79093,
    notes: 786,
    bondnotes: 1932,
    insurance: 229109,
    monetarypaper: 270,
    monetarygold: 21965,
    assets: 0,
    estates: 0
  },
  "1954-06-01": {
    deposits: 258631,
    fundshares: 4895,
    self: 0,
    equities: 17283,
    funds: 0,
    foreign: 550,
    shares: 51314,
    domestic: 1118,
    equipment: 2713,
    structures: 8435,
    intellectual: 13,
    otherpayables: 12057,
    bonds: 3124,
    treasury: 0,
    equityloan: 0,
    homeloan: 420,
    pensions: 121645,
    moneyfunds: 0,
    paper: 2355,
    otherreceivables: 6252,
    repurchaseshares: 8525,
    repurchases: 69970,
    interbank: 33162,
    mortgages: 81800,
    notes: 783,
    bondnotes: 2029,
    insurance: 234347,
    monetarypaper: 272,
    monetarygold: 21927,
    assets: 0,
    estates: 0
  },
  "1954-09-01": {
    deposits: 261696,
    fundshares: 5469,
    self: 0,
    equities: 18618,
    funds: 0,
    foreign: 567,
    shares: 51552,
    domestic: 1129,
    equipment: 2682,
    structures: 8456,
    intellectual: 13,
    otherpayables: 12864,
    bonds: 2930,
    treasury: 0,
    equityloan: 0,
    homeloan: 427,
    pensions: 124739,
    moneyfunds: 0,
    paper: 2331,
    otherreceivables: 6594,
    repurchaseshares: 9173,
    repurchases: 71080,
    interbank: 32608,
    mortgages: 84741,
    notes: 792,
    bondnotes: 2162,
    insurance: 239543,
    monetarypaper: 276,
    monetarygold: 21810,
    assets: 0,
    estates: 0
  },
  "1954-12-01": {
    deposits: 270130,
    fundshares: 6110,
    self: 0,
    equities: 20208,
    funds: 0,
    foreign: 595,
    shares: 51649,
    domestic: 1234,
    equipment: 2690,
    structures: 8484,
    intellectual: 14,
    otherpayables: 12867,
    bonds: 2759,
    treasury: 0,
    equityloan: 0,
    homeloan: 438,
    pensions: 127642,
    moneyfunds: 0,
    paper: 2373,
    otherreceivables: 7112,
    repurchaseshares: 9393,
    repurchases: 72923,
    interbank: 33255,
    mortgages: 88117,
    notes: 898,
    bondnotes: 2142,
    insurance: 244959,
    monetarypaper: 288,
    monetarygold: 21713,
    assets: 0,
    estates: 0
  },
  "1955-03-01": {
    deposits: 267404,
    fundshares: 6525,
    self: 0,
    equities: 21065,
    funds: 0,
    foreign: 613,
    shares: 51628,
    domestic: 1245,
    equipment: 2701,
    structures: 8631,
    intellectual: 14,
    otherpayables: 13452,
    bonds: 2852,
    treasury: 0,
    equityloan: 0,
    homeloan: 471,
    pensions: 130584,
    moneyfunds: 0,
    paper: 2612,
    otherreceivables: 7029,
    repurchaseshares: 10364,
    repurchases: 75493,
    interbank: 32811,
    mortgages: 91368,
    notes: 933,
    bondnotes: 2131,
    insurance: 250399,
    monetarypaper: 294,
    monetarygold: 21719,
    assets: 0,
    estates: 0
  },
  "1955-06-01": {
    deposits: 271405,
    fundshares: 7186,
    self: 0,
    equities: 23700,
    funds: 0,
    foreign: 632,
    shares: 51869,
    domestic: 1259,
    equipment: 2762,
    structures: 8831,
    intellectual: 14,
    otherpayables: 15070,
    bonds: 2775,
    treasury: 0,
    equityloan: 0,
    homeloan: 496,
    pensions: 132874,
    moneyfunds: 0,
    paper: 2667,
    otherreceivables: 7588,
    repurchaseshares: 10034,
    repurchases: 80151,
    interbank: 32714,
    mortgages: 95363,
    notes: 960,
    bondnotes: 2456,
    insurance: 256168,
    monetarypaper: 295,
    monetarygold: 21678,
    assets: 0,
    estates: 0
  },
  "1955-09-01": {
    deposits: 274219,
    fundshares: 7257,
    self: 0,
    equities: 25119,
    funds: 0,
    foreign: 652,
    shares: 52052,
    domestic: 1273,
    equipment: 2874,
    structures: 9088,
    intellectual: 15,
    otherpayables: 16488,
    bonds: 2944,
    treasury: 0,
    equityloan: 0,
    homeloan: 504,
    pensions: 135223,
    moneyfunds: 0,
    paper: 2649,
    otherreceivables: 8098,
    repurchaseshares: 11498,
    repurchases: 84806,
    interbank: 33337,
    mortgages: 99339,
    notes: 972,
    bondnotes: 2707,
    insurance: 261889,
    monetarypaper: 298,
    monetarygold: 21684,
    assets: 0,
    estates: 0
  },
  "1955-12-01": {
    deposits: 281921,
    fundshares: 7839,
    self: 0,
    equities: 26944,
    funds: 0,
    foreign: 675,
    shares: 52012,
    domestic: 1351,
    equipment: 3002,
    structures: 9434,
    intellectual: 15,
    otherpayables: 17080,
    bonds: 3156,
    treasury: 0,
    equityloan: 0,
    homeloan: 516,
    pensions: 138066,
    moneyfunds: 0,
    paper: 2387,
    otherreceivables: 7867,
    repurchaseshares: 11352,
    repurchases: 88923,
    interbank: 34599,
    mortgages: 102788,
    notes: 990,
    bondnotes: 3121,
    insurance: 267523,
    monetarypaper: 303,
    monetarygold: 21690,
    assets: 0,
    estates: 0
  },
  "1956-03-01": {
    deposits: 279697,
    fundshares: 8555,
    self: 0,
    equities: 28817,
    funds: 0,
    foreign: 695,
    shares: 52241,
    domestic: 1365,
    equipment: 3091,
    structures: 10013,
    intellectual: 15,
    otherpayables: 17356,
    bonds: 3724,
    treasury: 0,
    equityloan: 0,
    homeloan: 569,
    pensions: 140834,
    moneyfunds: 0,
    paper: 2964,
    otherreceivables: 7952,
    repurchaseshares: 11971,
    repurchases: 91393,
    interbank: 33466,
    mortgages: 105728,
    notes: 1048,
    bondnotes: 3238,
    insurance: 273234,
    monetarypaper: 310,
    monetarygold: 21716,
    assets: 0,
    estates: 0
  },
  "1956-06-01": {
    deposits: 282534,
    fundshares: 8613,
    self: 0,
    equities: 28541,
    funds: 0,
    foreign: 718,
    shares: 52394,
    domestic: 1374,
    equipment: 3174,
    structures: 10146,
    intellectual: 16,
    otherpayables: 17243,
    bonds: 3954,
    treasury: 0,
    equityloan: 0,
    homeloan: 590,
    pensions: 144355,
    moneyfunds: 0,
    paper: 2932,
    otherreceivables: 8497,
    repurchaseshares: 10892,
    repurchases: 94060,
    interbank: 33141,
    mortgages: 109296,
    notes: 1072,
    bondnotes: 3454,
    insurance: 279008,
    monetarypaper: 316,
    monetarygold: 21799,
    assets: 0,
    estates: 0
  },
  "1956-09-01": {
    deposits: 285662,
    fundshares: 8506,
    self: 0,
    equities: 27872,
    funds: 0,
    foreign: 741,
    shares: 52524,
    domestic: 1383,
    equipment: 3287,
    structures: 10591,
    intellectual: 16,
    otherpayables: 17455,
    bonds: 4192,
    treasury: 0,
    equityloan: 0,
    homeloan: 596,
    pensions: 147707,
    moneyfunds: 0,
    paper: 3000,
    otherreceivables: 8898,
    repurchaseshares: 11785,
    repurchases: 95774,
    interbank: 33989,
    mortgages: 112756,
    notes: 1085,
    bondnotes: 3702,
    insurance: 284980,
    monetarypaper: 320,
    monetarygold: 21884,
    assets: 0,
    estates: 0
  },
  "1956-12-01": {
    deposits: 294069,
    fundshares: 9046,
    self: 0,
    equities: 29066,
    funds: 0,
    foreign: 765,
    shares: 52620,
    domestic: 1374,
    equipment: 3375,
    structures: 10867,
    intellectual: 16,
    otherpayables: 17028,
    bonds: 4459,
    treasury: 0,
    equityloan: 0,
    homeloan: 607,
    pensions: 151542,
    moneyfunds: 0,
    paper: 2849,
    otherreceivables: 8872,
    repurchaseshares: 11084,
    repurchases: 97591,
    interbank: 35438,
    mortgages: 115701,
    notes: 1102,
    bondnotes: 3597,
    insurance: 291384,
    monetarypaper: 326,
    monetarygold: 21949,
    assets: 0,
    estates: 0
  },
  "1957-03-01": {
    deposits: 291562,
    fundshares: 9106,
    self: 0,
    equities: 28582,
    funds: 0,
    foreign: 788,
    shares: 52767,
    domestic: 1385,
    equipment: 3476,
    structures: 11068,
    intellectual: 16,
    otherpayables: 17707,
    bonds: 4893,
    treasury: 0,
    equityloan: 0,
    homeloan: 651,
    pensions: 157581,
    moneyfunds: 0,
    paper: 3440,
    otherreceivables: 8471,
    repurchaseshares: 11245,
    repurchases: 98925,
    interbank: 33356,
    mortgages: 117904,
    notes: 1230,
    bondnotes: 4002,
    insurance: 299778,
    monetarypaper: 331,
    monetarygold: 22306,
    assets: 0,
    estates: 0
  },
  "1957-06-01": {
    deposits: 294677,
    fundshares: 9687,
    self: 0,
    equities: 31190,
    funds: 0,
    foreign: 814,
    shares: 52954,
    domestic: 1392,
    equipment: 3509,
    structures: 11291,
    intellectual: 16,
    otherpayables: 17563,
    bonds: 5107,
    treasury: 0,
    equityloan: 0,
    homeloan: 668,
    pensions: 162915,
    moneyfunds: 0,
    paper: 3195,
    otherreceivables: 8955,
    repurchaseshares: 11329,
    repurchases: 103243,
    interbank: 34082,
    mortgages: 120299,
    notes: 1252,
    bondnotes: 4612,
    insurance: 308743,
    monetarypaper: 333,
    monetarygold: 22623,
    assets: 0,
    estates: 0
  },
  "1957-09-01": {
    deposits: 298719,
    fundshares: 9001,
    self: 0,
    equities: 29693,
    funds: 0,
    foreign: 840,
    shares: 53190,
    domestic: 1399,
    equipment: 3572,
    structures: 11436,
    intellectual: 16,
    otherpayables: 18419,
    bonds: 5532,
    treasury: 0,
    equityloan: 0,
    homeloan: 674,
    pensions: 169539,
    moneyfunds: 0,
    paper: 3481,
    otherreceivables: 9138,
    repurchaseshares: 11547,
    repurchases: 103938,
    interbank: 34046,
    mortgages: 123007,
    notes: 1264,
    bondnotes: 4805,
    insurance: 317486,
    monetarypaper: 338,
    monetarygold: 22635,
    assets: 0,
    estates: 0
  },
  "1957-12-01": {
    deposits: 304867,
    fundshares: 8714,
    self: 0,
    equities: 29050,
    funds: 0,
    foreign: 870,
    shares: 53395,
    domestic: 1359,
    equipment: 3651,
    structures: 11487,
    intellectual: 16,
    otherpayables: 18032,
    bonds: 5696,
    treasury: 0,
    equityloan: 0,
    homeloan: 685,
    pensions: 175993,
    moneyfunds: 0,
    paper: 3644,
    otherreceivables: 9291,
    repurchaseshares: 10656,
    repurchases: 104213,
    interbank: 35576,
    mortgages: 125438,
    notes: 1283,
    bondnotes: 5021,
    insurance: 326108,
    monetarypaper: 345,
    monetarygold: 22781,
    assets: 0,
    estates: 0
  },
  "1958-03-01": {
    deposits: 304561,
    fundshares: 9463,
    self: 0,
    equities: 30537,
    funds: 0,
    foreign: 893,
    shares: 53612,
    domestic: 1362,
    equipment: 3672,
    structures: 11310,
    intellectual: 16,
    otherpayables: 18023,
    bonds: 5976,
    treasury: 0,
    equityloan: 0,
    homeloan: 734,
    pensions: 179711,
    moneyfunds: 0,
    paper: 4436,
    otherreceivables: 9345,
    repurchaseshares: 10724,
    repurchases: 102338,
    interbank: 34076,
    mortgages: 127542,
    notes: 1338,
    bondnotes: 4903,
    insurance: 332924,
    monetarypaper: 350,
    monetarygold: 22394,
    assets: 0,
    estates: 0
  },
  "1958-06-01": {
    deposits: 314666,
    fundshares: 10610,
    self: 0,
    equities: 33280,
    funds: 0,
    foreign: 916,
    shares: 53829,
    domestic: 1363,
    equipment: 3704,
    structures: 11508,
    intellectual: 15,
    otherpayables: 18444,
    bonds: 5874,
    treasury: 0,
    equityloan: 0,
    homeloan: 748,
    pensions: 183135,
    moneyfunds: 0,
    paper: 4082,
    otherreceivables: 10440,
    repurchaseshares: 12359,
    repurchases: 104503,
    interbank: 34733,
    mortgages: 130214,
    notes: 1348,
    bondnotes: 4802,
    insurance: 339770,
    monetarypaper: 353,
    monetarygold: 21356,
    assets: 0,
    estates: 0
  },
  "1958-09-01": {
    deposits: 315293,
    fundshares: 11791,
    self: 0,
    equities: 36288,
    funds: 0,
    foreign: 940,
    shares: 54089,
    domestic: 1364,
    equipment: 3712,
    structures: 11675,
    intellectual: 15,
    otherpayables: 19578,
    bonds: 5542,
    treasury: 0,
    equityloan: 0,
    homeloan: 756,
    pensions: 186260,
    moneyfunds: 0,
    paper: 3778,
    otherreceivables: 9873,
    repurchaseshares: 11271,
    repurchases: 102821,
    interbank: 34309,
    mortgages: 133782,
    notes: 1361,
    bondnotes: 5023,
    insurance: 346775,
    monetarypaper: 357,
    monetarygold: 20874,
    assets: 0,
    estates: 0
  },
  "1958-12-01": {
    deposits: 326468,
    fundshares: 13242,
    self: 0,
    equities: 40345,
    funds: 0,
    foreign: 966,
    shares: 54286,
    domestic: 1480,
    equipment: 3731,
    structures: 11835,
    intellectual: 15,
    otherpayables: 19768,
    bonds: 5554,
    treasury: 0,
    equityloan: 0,
    homeloan: 769,
    pensions: 189400,
    moneyfunds: 0,
    paper: 3395,
    otherreceivables: 10522,
    repurchaseshares: 11684,
    repurchases: 106491,
    interbank: 35471,
    mortgages: 137629,
    notes: 1379,
    bondnotes: 5115,
    insurance: 353915,
    monetarypaper: 363,
    monetarygold: 20534,
    assets: 0,
    estates: 0
  },
  "1959-03-01": {
    deposits: 322405,
    fundshares: 14132,
    self: 0,
    equities: 41566,
    funds: 0,
    foreign: 992,
    shares: 54528,
    domestic: 1480,
    equipment: 3784,
    structures: 11855,
    intellectual: 15,
    otherpayables: 20412,
    bonds: 5716,
    treasury: 0,
    equityloan: 0,
    homeloan: 827,
    pensions: 192237,
    moneyfunds: 0,
    paper: 3792,
    otherreceivables: 10747,
    repurchaseshares: 12967,
    repurchases: 108032,
    interbank: 34306,
    mortgages: 140826,
    notes: 1446,
    bondnotes: 5236,
    insurance: 359889,
    monetarypaper: 374,
    monetarygold: 20442,
    assets: 0,
    estates: 0
  },
  "1959-06-01": {
    deposits: 327855,
    fundshares: 14973,
    self: 0,
    equities: 44318,
    funds: 0,
    foreign: 1019,
    shares: 54738,
    domestic: 1488,
    equipment: 3841,
    structures: 12030,
    intellectual: 16,
    otherpayables: 20211,
    bonds: 5917,
    treasury: 0,
    equityloan: 0,
    homeloan: 844,
    pensions: 194620,
    moneyfunds: 0,
    paper: 4036,
    otherreceivables: 11544,
    repurchaseshares: 14540,
    repurchases: 115359,
    interbank: 34772,
    mortgages: 144875,
    notes: 1471,
    bondnotes: 6111,
    insurance: 366440,
    monetarypaper: 380,
    monetarygold: 19705,
    assets: 0,
    estates: 0
  },
  "1959-09-01": {
    deposits: 331576,
    fundshares: 14747,
    self: 0,
    equities: 43942,
    funds: 0,
    foreign: 1046,
    shares: 54920,
    domestic: 1496,
    equipment: 3904,
    structures: 12192,
    intellectual: 15,
    otherpayables: 21536,
    bonds: 6238,
    treasury: 0,
    equityloan: 0,
    homeloan: 852,
    pensions: 197994,
    moneyfunds: 0,
    paper: 3885,
    otherreceivables: 12275,
    repurchaseshares: 14276,
    repurchases: 118606,
    interbank: 34160,
    mortgages: 149305,
    notes: 1488,
    bondnotes: 6924,
    insurance: 373143,
    monetarypaper: 383,
    monetarygold: 19491,
    assets: 0,
    estates: 0
  },
  "1959-12-01": {
    deposits: 337822,
    fundshares: 15818,
    self: 0,
    equities: 47015,
    funds: 0,
    foreign: 1075,
    shares: 54974,
    domestic: 1539,
    equipment: 3958,
    structures: 12316,
    intellectual: 18,
    otherpayables: 20985,
    bonds: 6544,
    treasury: 0,
    equityloan: 0,
    homeloan: 866,
    pensions: 200555,
    moneyfunds: 0,
    paper: 4050,
    otherreceivables: 10585,
    repurchaseshares: 14993,
    repurchases: 124541,
    interbank: 35406,
    mortgages: 153311,
    notes: 1514,
    bondnotes: 7323,
    insurance: 379751,
    monetarypaper: 387,
    monetarygold: 19456,
    assets: 0,
    estates: 0
  },
  "1960-03-01": {
    deposits: 330967,
    fundshares: 15312,
    self: 0,
    equities: 44930,
    funds: 0,
    foreign: 1104,
    shares: 54977,
    domestic: 1549,
    equipment: 3990,
    structures: 12513,
    intellectual: 22,
    otherpayables: 21396,
    bonds: 7812,
    treasury: 0,
    equityloan: 0,
    homeloan: 953,
    pensions: 205480,
    moneyfunds: 0,
    paper: 5448,
    otherreceivables: 10203,
    repurchaseshares: 15295,
    repurchases: 126613,
    interbank: 34334,
    mortgages: 156614,
    notes: 1613,
    bondnotes: 7160,
    insurance: 387148,
    monetarypaper: 395,
    monetarygold: 19408,
    assets: 0,
    estates: 0
  },
  "1960-06-01": {
    deposits: 338399,
    fundshares: 16097,
    self: 0,
    equities: 47604,
    funds: 0,
    foreign: 1132,
    shares: 55414,
    domestic: 1568,
    equipment: 4055,
    structures: 12594,
    intellectual: 27,
    otherpayables: 21329,
    bonds: 8436,
    treasury: 0,
    equityloan: 0,
    homeloan: 966,
    pensions: 209098,
    moneyfunds: 0,
    paper: 5476,
    otherreceivables: 10547,
    repurchaseshares: 14727,
    repurchases: 131985,
    interbank: 34688,
    mortgages: 160104,
    notes: 1634,
    bondnotes: 7816,
    insurance: 394965,
    monetarypaper: 400,
    monetarygold: 19322,
    assets: 0,
    estates: 0
  },
  "1960-09-01": {
    deposits: 345707,
    fundshares: 15640,
    self: 0,
    equities: 45860,
    funds: 0,
    foreign: 1162,
    shares: 55330,
    domestic: 1587,
    equipment: 4095,
    structures: 12692,
    intellectual: 36,
    otherpayables: 21688,
    bonds: 9052,
    treasury: 0,
    equityloan: 0,
    homeloan: 974,
    pensions: 214124,
    moneyfunds: 0,
    paper: 5631,
    otherreceivables: 10904,
    repurchaseshares: 14882,
    repurchases: 133133,
    interbank: 35426,
    mortgages: 164059,
    notes: 1663,
    bondnotes: 7930,
    insurance: 401926,
    monetarypaper: 403,
    monetarygold: 18685,
    assets: 0,
    estates: 0
  },
  "1960-12-01": {
    deposits: 355508,
    fundshares: 17026,
    self: 0,
    equities: 51203,
    funds: 0,
    foreign: 1195,
    shares: 55908,
    domestic: 1607,
    equipment: 4113,
    structures: 12770,
    intellectual: 45,
    otherpayables: 20636,
    bonds: 9780,
    treasury: 0,
    equityloan: 0,
    homeloan: 989,
    pensions: 216801,
    moneyfunds: 0,
    paper: 5775,
    otherreceivables: 11487,
    repurchaseshares: 14226,
    repurchases: 135738,
    interbank: 35670,
    mortgages: 167428,
    notes: 1690,
    bondnotes: 8061,
    insurance: 409214,
    monetarypaper: 409,
    monetarygold: 17767,
    assets: 0,
    estates: 0
  },
  "1961-03-01": {
    deposits: 351646,
    fundshares: 19440,
    self: 0,
    equities: 57384,
    funds: 0,
    foreign: 1227,
    shares: 56180,
    domestic: 1625,
    equipment: 4116,
    structures: 12969,
    intellectual: 51,
    otherpayables: 22381,
    bonds: 10230,
    treasury: 0,
    equityloan: 0,
    homeloan: 1079,
    pensions: 219035,
    moneyfunds: 0,
    paper: 6708,
    otherreceivables: 9686,
    repurchaseshares: 12713,
    repurchases: 135431,
    interbank: 33880,
    mortgages: 170274,
    notes: 1788,
    bondnotes: 7579,
    insurance: 416435,
    monetarypaper: 423,
    monetarygold: 17388,
    assets: 0,
    estates: 0
  },
  "1961-06-01": {
    deposits: 361743,
    fundshares: 20003,
    self: 0,
    equities: 58511,
    funds: 0,
    foreign: 1261,
    shares: 56081,
    domestic: 1633,
    equipment: 4126,
    structures: 13189,
    intellectual: 57,
    otherpayables: 21535,
    bonds: 10462,
    treasury: 0,
    equityloan: 0,
    homeloan: 1092,
    pensions: 222516,
    moneyfunds: 0,
    paper: 6504,
    otherreceivables: 12664,
    repurchaseshares: 12688,
    repurchases: 137168,
    interbank: 34639,
    mortgages: 174186,
    notes: 1807,
    bondnotes: 7915,
    insurance: 423911,
    monetarypaper: 425,
    monetarygold: 17550,
    assets: 0,
    estates: 0
  },
  "1961-09-01": {
    deposits: 369947,
    fundshares: 21007,
    self: 0,
    equities: 60687,
    funds: 0,
    foreign: 1295,
    shares: 56070,
    domestic: 1641,
    equipment: 4137,
    structures: 13399,
    intellectual: 63,
    otherpayables: 22611,
    bonds: 10382,
    treasury: 0,
    equityloan: 0,
    homeloan: 1100,
    pensions: 225981,
    moneyfunds: 0,
    paper: 6304,
    otherreceivables: 11531,
    repurchaseshares: 14619,
    repurchases: 139788,
    interbank: 35577,
    mortgages: 178860,
    notes: 1820,
    bondnotes: 8457,
    insurance: 431196,
    monetarypaper: 432,
    monetarygold: 17376,
    assets: 0,
    estates: 0
  },
  "1961-12-01": {
    deposits: 383318,
    fundshares: 22875,
    self: 0,
    equities: 66069,
    funds: 0,
    foreign: 1330,
    shares: 78518,
    domestic: 1807,
    equipment: 4162,
    structures: 13639,
    intellectual: 70,
    otherpayables: 21626,
    bonds: 10502,
    treasury: 0,
    equityloan: 0,
    homeloan: 1107,
    pensions: 228430,
    moneyfunds: 0,
    paper: 6302,
    otherreceivables: 12286,
    repurchaseshares: 15761,
    repurchases: 145343,
    interbank: 38016,
    mortgages: 184004,
    notes: 1835,
    bondnotes: 8764,
    insurance: 438733,
    monetarypaper: 445,
    monetarygold: 16889,
    assets: 0,
    estates: 0
  },
  "1962-03-01": {
    deposits: 380925,
    fundshares: 23048,
    self: 0,
    equities: 65852,
    funds: 36,
    foreign: 1367,
    shares: 77641,
    domestic: 1818,
    equipment: 4214,
    structures: 13867,
    intellectual: 76,
    otherpayables: 24119,
    bonds: 10672,
    treasury: 0,
    equityloan: 0,
    homeloan: 1112,
    pensions: 230166,
    moneyfunds: 0,
    paper: 7107,
    otherreceivables: 11433,
    repurchaseshares: 14830,
    repurchases: 144994,
    interbank: 36123,
    mortgages: 187673,
    notes: 1849,
    bondnotes: 9163,
    insurance: 443551,
    monetarypaper: 453,
    monetarygold: 16608,
    assets: 0,
    estates: 0
  },
  "1962-06-01": {
    deposits: 392838,
    fundshares: 18436,
    self: 0,
    equities: 51636,
    funds: 97,
    foreign: 1404,
    shares: 59789,
    domestic: 1828,
    equipment: 4273,
    structures: 14130,
    intellectual: 82,
    otherpayables: 24343,
    bonds: 10513,
    treasury: 0,
    equityloan: 0,
    homeloan: 1116,
    pensions: 235876,
    moneyfunds: 0,
    paper: 7138,
    otherreceivables: 14112,
    repurchaseshares: 14732,
    repurchases: 148315,
    interbank: 37000,
    mortgages: 193139,
    notes: 1860,
    bondnotes: 9524,
    insurance: 447915,
    monetarypaper: 458,
    monetarygold: 16435,
    assets: 0,
    estates: 0
  },
  "1962-09-01": {
    deposits: 398219,
    fundshares: 19088,
    self: 0,
    equities: 54998,
    funds: 158,
    foreign: 1444,
    shares: 63125,
    domestic: 1837,
    equipment: 4330,
    structures: 14339,
    intellectual: 91,
    otherpayables: 26108,
    bonds: 10314,
    treasury: 0,
    equityloan: 0,
    homeloan: 1118,
    pensions: 236861,
    moneyfunds: 0,
    paper: 7518,
    otherreceivables: 14349,
    repurchaseshares: 17210,
    repurchases: 152586,
    interbank: 38015,
    mortgages: 198999,
    notes: 1868,
    bondnotes: 10113,
    insurance: 453006,
    monetarypaper: 462,
    monetarygold: 16067,
    assets: 0,
    estates: 0
  },
  "1962-12-01": {
    deposits: 417055,
    fundshares: 21271,
    self: 0,
    equities: 62257,
    funds: 194,
    foreign: 1483,
    shares: 74795,
    domestic: 1718,
    equipment: 4367,
    structures: 14596,
    intellectual: 102,
    otherpayables: 25129,
    bonds: 10833,
    treasury: 0,
    equityloan: 0,
    homeloan: 1126,
    pensions: 236833,
    moneyfunds: 0,
    paper: 7478,
    otherreceivables: 14719,
    repurchaseshares: 18341,
    repurchases: 159485,
    interbank: 40225,
    mortgages: 204957,
    notes: 1884,
    bondnotes: 10401,
    insurance: 458495,
    monetarypaper: 467,
    monetarygold: 15978,
    assets: 0,
    estates: 0
  },
  "1963-03-01": {
    deposits: 414319,
    fundshares: 22549,
    self: 0,
    equities: 66239,
    funds: 206,
    foreign: 1525,
    shares: 79906,
    domestic: 1727,
    equipment: 4388,
    structures: 14831,
    intellectual: 107,
    otherpayables: 27532,
    bonds: 11245,
    treasury: 0,
    equityloan: 0,
    homeloan: 1130,
    pensions: 239367,
    moneyfunds: 0,
    paper: 8502,
    otherreceivables: 13264,
    repurchaseshares: 17065,
    repurchases: 159598,
    interbank: 36988,
    mortgages: 209637,
    notes: 1895,
    bondnotes: 9576,
    insurance: 465628,
    monetarypaper: 478,
    monetarygold: 15878,
    assets: 0,
    estates: 0
  },
  "1963-06-01": {
    deposits: 427899,
    fundshares: 23693,
    self: 0,
    equities: 70164,
    funds: 194,
    foreign: 1566,
    shares: 84913,
    domestic: 1726,
    equipment: 4404,
    structures: 15062,
    intellectual: 115,
    otherpayables: 28845,
    bonds: 11227,
    treasury: 0,
    equityloan: 0,
    homeloan: 1137,
    pensions: 241734,
    moneyfunds: 0,
    paper: 8990,
    otherreceivables: 16019,
    repurchaseshares: 18110,
    repurchases: 166432,
    interbank: 37393,
    mortgages: 216366,
    notes: 1910,
    bondnotes: 10523,
    insurance: 473590,
    monetarypaper: 481,
    monetarygold: 15733,
    assets: 0,
    estates: 0
  },
  "1963-09-01": {
    deposits: 432321,
    fundshares: 24496,
    self: 0,
    equities: 72830,
    funds: 183,
    foreign: 1610,
    shares: 89501,
    domestic: 1724,
    equipment: 4435,
    structures: 15305,
    intellectual: 128,
    otherpayables: 31678,
    bonds: 11402,
    treasury: 0,
    equityloan: 0,
    homeloan: 1153,
    pensions: 244882,
    moneyfunds: 0,
    paper: 8811,
    otherreceivables: 15897,
    repurchaseshares: 21220,
    repurchases: 171756,
    interbank: 37705,
    mortgages: 223573,
    notes: 1932,
    bondnotes: 11209,
    insurance: 481320,
    monetarypaper: 487,
    monetarygold: 15582,
    assets: 0,
    estates: 0
  },
  "1963-12-01": {
    deposits: 450564,
    fundshares: 25215,
    self: 0,
    equities: 76095,
    funds: 193,
    foreign: 1655,
    shares: 87551,
    domestic: 1811,
    equipment: 4471,
    structures: 15527,
    intellectual: 144,
    otherpayables: 30217,
    bonds: 12384,
    treasury: 0,
    equityloan: 0,
    homeloan: 1171,
    pensions: 248183,
    moneyfunds: 0,
    paper: 8741,
    otherreceivables: 16707,
    repurchaseshares: 22004,
    repurchases: 179984,
    interbank: 39420,
    mortgages: 230449,
    notes: 1902,
    bondnotes: 12049,
    insurance: 489292,
    monetarypaper: 497,
    monetarygold: 15513,
    assets: 0,
    estates: 0
  },
  "1964-03-01": {
    deposits: 448809,
    fundshares: 26881,
    self: 0,
    equities: 80636,
    funds: 234,
    foreign: 1702,
    shares: 92888,
    domestic: 1809,
    equipment: 4590,
    structures: 15740,
    intellectual: 167,
    otherpayables: 31495,
    bonds: 13475,
    treasury: 0,
    equityloan: 0,
    homeloan: 1189,
    pensions: 250703,
    moneyfunds: 0,
    paper: 9975,
    otherreceivables: 17006,
    repurchaseshares: 20696,
    repurchases: 179615,
    interbank: 37980,
    mortgages: 235645,
    notes: 1912,
    bondnotes: 11504,
    insurance: 497744,
    monetarypaper: 508,
    monetarygold: 15461,
    assets: 0,
    estates: 0
  },
  "1964-06-01": {
    deposits: 462005,
    fundshares: 27682,
    self: 0,
    equities: 83703,
    funds: 301,
    foreign: 1748,
    shares: 95826,
    domestic: 1816,
    equipment: 4714,
    structures: 16167,
    intellectual: 190,
    otherpayables: 33018,
    bonds: 14040,
    treasury: 0,
    equityloan: 0,
    homeloan: 1201,
    pensions: 253486,
    moneyfunds: 0,
    paper: 10387,
    otherreceivables: 17748,
    repurchaseshares: 22656,
    repurchases: 190098,
    interbank: 38668,
    mortgages: 242683,
    notes: 1927,
    bondnotes: 12266,
    insurance: 506807,
    monetarypaper: 513,
    monetarygold: 15461,
    assets: 0,
    estates: 0
  },
  "1964-09-01": {
    deposits: 471437,
    fundshares: 29131,
    self: 0,
    equities: 87139,
    funds: 370,
    foreign: 1798,
    shares: 98923,
    domestic: 1823,
    equipment: 4838,
    structures: 16446,
    intellectual: 211,
    otherpayables: 33760,
    bonds: 14625,
    treasury: 0,
    equityloan: 0,
    homeloan: 1212,
    pensions: 257098,
    moneyfunds: 0,
    paper: 10654,
    otherreceivables: 19036,
    repurchaseshares: 22592,
    repurchases: 191849,
    interbank: 38920,
    mortgages: 249736,
    notes: 1915,
    bondnotes: 12397,
    insurance: 515721,
    monetarypaper: 518,
    monetarygold: 15463,
    assets: 0,
    estates: 0
  },
  "1964-12-01": {
    deposits: 490009,
    fundshares: 29116,
    self: 0,
    equities: 89284,
    funds: 416,
    foreign: 1848,
    shares: 96021,
    domestic: 1934,
    equipment: 4964,
    structures: 16961,
    intellectual: 230,
    otherpayables: 33727,
    bonds: 15525,
    treasury: 0,
    equityloan: 0,
    homeloan: 1227,
    pensions: 261192,
    moneyfunds: 0,
    paper: 10736,
    otherreceivables: 19210,
    repurchaseshares: 24291,
    repurchases: 200510,
    interbank: 40946,
    mortgages: 256455,
    notes: 1930,
    bondnotes: 12561,
    insurance: 524867,
    monetarypaper: 524,
    monetarygold: 15388,
    assets: 0,
    estates: 0
  },
  "1965-03-01": {
    deposits: 490821,
    fundshares: 30465,
    self: 0,
    equities: 91519,
    funds: 444,
    foreign: 1900,
    shares: 98249,
    domestic: 1941,
    equipment: 5130,
    structures: 17184,
    intellectual: 247,
    otherpayables: 36382,
    bonds: 16015,
    treasury: 0,
    equityloan: 0,
    homeloan: 1247,
    pensions: 265250,
    moneyfunds: 0,
    paper: 11688,
    otherreceivables: 19194,
    repurchaseshares: 22995,
    repurchases: 204166,
    interbank: 38827,
    mortgages: 262057,
    notes: 1960,
    bondnotes: 12732,
    insurance: 534311,
    monetarypaper: 536,
    monetarygold: 14563,
    assets: 0,
    estates: 0
  },
  "1965-06-01": {
    deposits: 506116,
    fundshares: 30037,
    self: 0,
    equities: 90008,
    funds: 457,
    foreign: 1953,
    shares: 93594,
    domestic: 1926,
    equipment: 5284,
    structures: 17521,
    intellectual: 269,
    otherpayables: 36883,
    bonds: 16081,
    treasury: 0,
    equityloan: 0,
    homeloan: 1260,
    pensions: 270834,
    moneyfunds: 0,
    paper: 12164,
    otherreceivables: 20981,
    repurchaseshares: 27221,
    repurchases: 216457,
    interbank: 40511,
    mortgages: 269096,
    notes: 1991,
    bondnotes: 13964,
    insurance: 544356,
    monetarypaper: 541,
    monetarygold: 13934,
    assets: 0,
    estates: 0
  },
  "1965-09-01": {
    deposits: 510612,
    fundshares: 32825,
    self: 0,
    equities: 98337,
    funds: 466,
    foreign: 2009,
    shares: 100749,
    domestic: 1910,
    equipment: 5477,
    structures: 17778,
    intellectual: 295,
    otherpayables: 38542,
    bonds: 15885,
    treasury: 0,
    equityloan: 0,
    homeloan: 1270,
    pensions: 273770,
    moneyfunds: 0,
    paper: 12284,
    otherreceivables: 20481,
    repurchaseshares: 26449,
    repurchases: 217769,
    interbank: 40946,
    mortgages: 276494,
    notes: 2012,
    bondnotes: 14522,
    insurance: 555105,
    monetarypaper: 544,
    monetarygold: 13858,
    assets: 0,
    estates: 0
  },
  "1965-12-01": {
    deposits: 531178,
    fundshares: 35220,
    self: 0,
    equities: 104228,
    funds: 480,
    foreign: 2066,
    shares: 106063,
    domestic: 1908,
    equipment: 5639,
    structures: 18362,
    intellectual: 328,
    otherpayables: 37965,
    bonds: 16730,
    treasury: 0,
    equityloan: 0,
    homeloan: 1277,
    pensions: 278433,
    moneyfunds: 0,
    paper: 11929,
    otherreceivables: 21001,
    repurchaseshares: 29330,
    repurchases: 229740,
    interbank: 42461,
    mortgages: 283244,
    notes: 2029,
    bondnotes: 14715,
    insurance: 565896,
    monetarypaper: 551,
    monetarygold: 13733,
    assets: 0,
    estates: 0
  },
  "1966-03-01": {
    deposits: 528461,
    fundshares: 36173,
    self: 0,
    equities: 103831,
    funds: 496,
    foreign: 2125,
    shares: 103306,
    domestic: 1895,
    equipment: 5812,
    structures: 18565,
    intellectual: 343,
    otherpayables: 40393,
    bonds: 17121,
    treasury: 0,
    equityloan: 0,
    homeloan: 1303,
    pensions: 284694,
    moneyfunds: 0,
    paper: 13636,
    otherreceivables: 21121,
    repurchaseshares: 27294,
    repurchases: 230957,
    interbank: 40979,
    mortgages: 289739,
    notes: 2127,
    bondnotes: 15759,
    insurance: 577015,
    monetarypaper: 560,
    monetarygold: 13634,
    assets: 0,
    estates: 0
  },
  "1966-06-01": {
    deposits: 545517,
    fundshares: 35429,
    self: 0,
    equities: 101632,
    funds: 512,
    foreign: 2185,
    shares: 100041,
    domestic: 1911,
    equipment: 6019,
    structures: 19216,
    intellectual: 365,
    otherpayables: 38842,
    bonds: 17438,
    treasury: 0,
    equityloan: 0,
    homeloan: 1339,
    pensions: 292627,
    moneyfunds: 0,
    paper: 13684,
    otherreceivables: 22346,
    repurchaseshares: 30384,
    repurchases: 244978,
    interbank: 42748,
    mortgages: 295811,
    notes: 2223,
    bondnotes: 18413,
    insurance: 588861,
    monetarypaper: 562,
    monetarygold: 13433,
    assets: 0,
    estates: 0
  },
  "1966-09-01": {
    deposits: 543441,
    fundshares: 32224,
    self: 0,
    equities: 91507,
    funds: 530,
    foreign: 2247,
    shares: 88360,
    domestic: 1927,
    equipment: 6222,
    structures: 19489,
    intellectual: 391,
    otherpayables: 42076,
    bonds: 17862,
    treasury: 0,
    equityloan: 0,
    homeloan: 1360,
    pensions: 302766,
    moneyfunds: 0,
    paper: 14061,
    otherreceivables: 23107,
    repurchaseshares: 29011,
    repurchases: 243829,
    interbank: 42609,
    mortgages: 300552,
    notes: 2270,
    bondnotes: 19278,
    insurance: 600103,
    monetarypaper: 566,
    monetarygold: 13258,
    assets: 0,
    estates: 0
  },
  "1966-12-01": {
    deposits: 562400,
    fundshares: 34829,
    self: 0,
    equities: 99517,
    funds: 554,
    foreign: 2311,
    shares: 108209,
    domestic: 1804,
    equipment: 6457,
    structures: 19977,
    intellectual: 422,
    otherpayables: 37534,
    bonds: 18330,
    treasury: 0,
    equityloan: 0,
    homeloan: 1369,
    pensions: 308116,
    moneyfunds: 0,
    paper: 15662,
    otherreceivables: 21777,
    repurchaseshares: 30719,
    repurchases: 252374,
    interbank: 46286,
    mortgages: 304027,
    notes: 2293,
    bondnotes: 20218,
    insurance: 612866,
    monetarypaper: 570,
    monetarygold: 13159,
    assets: 0,
    estates: 0
  },
  "1967-03-01": {
    deposits: 564356,
    fundshares: 39280,
    self: 0,
    equities: 113819,
    funds: 585,
    foreign: 2372,
    shares: 116897,
    domestic: 1816,
    equipment: 6716,
    structures: 20294,
    intellectual: 456,
    otherpayables: 40720,
    bonds: 18630,
    treasury: 0,
    equityloan: 0,
    homeloan: 1387,
    pensions: 310817,
    moneyfunds: 0,
    paper: 17557,
    otherreceivables: 21845,
    repurchaseshares: 27845,
    repurchases: 249066,
    interbank: 44360,
    mortgages: 306813,
    notes: 2325,
    bondnotes: 19738,
    insurance: 626131,
    monetarypaper: 578,
    monetarygold: 13107,
    assets: 0,
    estates: 0
  },
  "1967-06-01": {
    deposits: 582618,
    fundshares: 40429,
    self: 0,
    equities: 117411,
    funds: 622,
    foreign: 2436,
    shares: 113318,
    domestic: 1817,
    equipment: 6976,
    structures: 20627,
    intellectual: 490,
    otherpayables: 39273,
    bonds: 18263,
    treasury: 0,
    equityloan: 0,
    homeloan: 1392,
    pensions: 317515,
    moneyfunds: 0,
    paper: 17537,
    otherreceivables: 23157,
    repurchaseshares: 28815,
    repurchases: 256904,
    interbank: 45468,
    mortgages: 311890,
    notes: 2331,
    bondnotes: 19273,
    insurance: 639266,
    monetarypaper: 585,
    monetarygold: 13110,
    assets: 0,
    estates: 0
  },
  "1967-09-01": {
    deposits: 596943,
    fundshares: 43249,
    self: 0,
    equities: 126186,
    funds: 662,
    foreign: 2503,
    shares: 125944,
    domestic: 1818,
    equipment: 7231,
    structures: 20996,
    intellectual: 519,
    otherpayables: 40612,
    bonds: 18471,
    treasury: 0,
    equityloan: 0,
    homeloan: 1394,
    pensions: 322397,
    moneyfunds: 0,
    paper: 17677,
    otherreceivables: 24580,
    repurchaseshares: 30810,
    repurchases: 257848,
    interbank: 47325,
    mortgages: 318337,
    notes: 2352,
    bondnotes: 19231,
    insurance: 652555,
    monetarypaper: 589,
    monetarygold: 13006,
    assets: 0,
    estates: 0
  },
  "1967-12-01": {
    deposits: 621424,
    fundshares: 44308,
    self: 0,
    equities: 127468,
    funds: 700,
    foreign: 2573,
    shares: 117648,
    domestic: 1898,
    equipment: 7504,
    structures: 21513,
    intellectual: 551,
    otherpayables: 38733,
    bonds: 19298,
    treasury: 0,
    equityloan: 0,
    homeloan: 1395,
    pensions: 330040,
    moneyfunds: 0,
    paper: 18411,
    otherreceivables: 25372,
    repurchaseshares: 33026,
    repurchases: 277665,
    interbank: 50747,
    mortgages: 324516,
    notes: 2371,
    bondnotes: 20338,
    insurance: 665452,
    monetarypaper: 598,
    monetarygold: 11982,
    assets: 0,
    estates: 0
  },
  "1968-03-01": {
    deposits: 617495,
    fundshares: 41949,
    self: 0,
    equities: 118552,
    funds: 734,
    foreign: 2648,
    shares: 118342,
    domestic: 1900,
    equipment: 7833,
    structures: 22187,
    intellectual: 586,
    otherpayables: 44307,
    bonds: 19448,
    treasury: 0,
    equityloan: 0,
    homeloan: 1417,
    pensions: 342621,
    moneyfunds: 0,
    paper: 20024,
    otherreceivables: 25690,
    repurchaseshares: 31783,
    repurchases: 269402,
    interbank: 49864,
    mortgages: 329341,
    notes: 2414,
    bondnotes: 21631,
    insurance: 679621,
    monetarypaper: 612,
    monetarygold: 10484,
    assets: 0,
    estates: 40
  },
  "1968-06-01": {
    deposits: 632217,
    fundshares: 47493,
    self: 0,
    equities: 137616,
    funds: 762,
    foreign: 2723,
    shares: 147639,
    domestic: 1910,
    equipment: 8179,
    structures: 22833,
    intellectual: 623,
    otherpayables: 43541,
    bonds: 19447,
    treasury: 0,
    equityloan: 0,
    homeloan: 1426,
    pensions: 345997,
    moneyfunds: 0,
    paper: 20440,
    otherreceivables: 27091,
    repurchaseshares: 35249,
    repurchases: 282761,
    interbank: 49627,
    mortgages: 335898,
    notes: 2473,
    bondnotes: 23215,
    insurance: 696769,
    monetarypaper: 617,
    monetarygold: 10367,
    assets: 0,
    estates: 80
  },
  "1968-09-01": {
    deposits: 648514,
    fundshares: 49914,
    self: 0,
    equities: 144671,
    funds: 798,
    foreign: 2805,
    shares: 166789,
    domestic: 1928,
    equipment: 8533,
    structures: 23378,
    intellectual: 664,
    otherpayables: 47588,
    bonds: 19385,
    treasury: 0,
    equityloan: 0,
    homeloan: 1401,
    pensions: 354560,
    moneyfunds: 0,
    paper: 20418,
    otherreceivables: 28333,
    repurchaseshares: 39553,
    repurchases: 288809,
    interbank: 52714,
    mortgages: 342173,
    notes: 2301,
    bondnotes: 23455,
    insurance: 712802,
    monetarypaper: 619,
    monetarygold: 10367,
    assets: 0,
    estates: 160
  },
  "1968-12-01": {
    deposits: 676111,
    fundshares: 51232,
    self: 0,
    equities: 151234,
    funds: 858,
    foreign: 2889,
    shares: 146483,
    domestic: 1984,
    equipment: 8923,
    structures: 24341,
    intellectual: 708,
    otherpayables: 47085,
    bonds: 19895,
    treasury: 0,
    equityloan: 0,
    homeloan: 1403,
    pensions: 363794,
    moneyfunds: 0,
    paper: 21396,
    otherreceivables: 29201,
    repurchaseshares: 41829,
    repurchases: 305414,
    interbank: 56022,
    mortgages: 349743,
    notes: 2218,
    bondnotes: 24105,
    insurance: 729999,
    monetarypaper: 630,
    monetarygold: 10367,
    assets: 0,
    estates: 200
  },
  "1969-03-01": {
    deposits: 665627,
    fundshares: 50339,
    self: 0,
    equities: 147176,
    funds: 940,
    foreign: 2979,
    shares: 136385,
    domestic: 1993,
    equipment: 9311,
    structures: 24985,
    intellectual: 742,
    otherpayables: 53206,
    bonds: 19255,
    treasury: 1000,
    equityloan: 1000,
    homeloan: 1442,
    pensions: 376500,
    moneyfunds: 0,
    paper: 24053,
    otherreceivables: 28952,
    repurchaseshares: 39349,
    repurchases: 306386,
    interbank: 53754,
    mortgages: 355873,
    notes: 2281,
    bondnotes: 25344,
    insurance: 746506,
    monetarypaper: 641,
    monetarygold: 10367,
    assets: 0,
    estates: 357
  },
  "1969-06-01": {
    deposits: 680123,
    fundshares: 48052,
    self: 0,
    equities: 146117,
    funds: 1046,
    foreign: 3073,
    shares: 126673,
    domestic: 2010,
    equipment: 9713,
    structures: 25700,
    intellectual: 784,
    otherpayables: 53840,
    bonds: 18802,
    treasury: 2000,
    equityloan: 2000,
    homeloan: 1451,
    pensions: 388799,
    moneyfunds: 0,
    paper: 26817,
    otherreceivables: 32991,
    repurchaseshares: 45917,
    repurchases: 324841,
    interbank: 51440,
    mortgages: 363842,
    notes: 2332,
    bondnotes: 27760,
    insurance: 764585,
    monetarypaper: 659,
    monetarygold: 10367,
    assets: 0,
    estates: 368
  },
  "1969-09-01": {
    deposits: 669936,
    fundshares: 47940,
    self: 0,
    equities: 142899,
    funds: 1159,
    foreign: 3171,
    shares: 131889,
    domestic: 2026,
    equipment: 10155,
    structures: 26380,
    intellectual: 843,
    otherpayables: 68100,
    bonds: 19644,
    treasury: 3000,
    equityloan: 3000,
    homeloan: 1444,
    pensions: 402220,
    moneyfunds: 0,
    paper: 29985,
    otherreceivables: 30558,
    repurchaseshares: 44985,
    repurchases: 330408,
    interbank: 55310,
    mortgages: 370805,
    notes: 2357,
    bondnotes: 30705,
    insurance: 782579,
    monetarypaper: 666,
    monetarygold: 10367,
    assets: 0,
    estates: 378
  },
  "1969-12-01": {
    deposits: 691428,
    fundshares: 47588,
    self: 0,
    equities: 146492,
    funds: 1265,
    foreign: 3274,
    shares: 128581,
    domestic: 1838,
    equipment: 10584,
    structures: 27208,
    intellectual: 912,
    otherpayables: 61284,
    bonds: 20927,
    treasury: 4000,
    equityloan: 4000,
    homeloan: 1478,
    pensions: 414348,
    moneyfunds: 0,
    paper: 32750,
    otherreceivables: 30698,
    repurchaseshares: 51194,
    repurchases: 346499,
    interbank: 56572,
    mortgages: 376255,
    notes: 2426,
    bondnotes: 33849,
    insurance: 801737,
    monetarypaper: 669,
    monetarygold: 10367,
    assets: 0,
    estates: 430
  },
  "1970-03-01": {
    deposits: 679003,
    fundshares: 48369,
    self: 0,
    equities: 145475,
    funds: 1371,
    foreign: 3383,
    shares: 130509,
    domestic: 1851,
    equipment: 10967,
    structures: 27817,
    intellectual: 989,
    otherpayables: 71105,
    bonds: 20527,
    treasury: 5000,
    equityloan: 5000,
    homeloan: 1558,
    pensions: 432137,
    moneyfunds: 0,
    paper: 36646,
    otherreceivables: 33751,
    repurchaseshares: 49974,
    repurchases: 344663,
    interbank: 54559,
    mortgages: 380881,
    notes: 2295,
    bondnotes: 37777,
    insurance: 824743,
    monetarypaper: 681,
    monetarygold: 11367,
    assets: 0,
    estates: 433
  },
  "1970-06-01": {
    deposits: 700016,
    fundshares: 39201,
    self: 0,
    equities: 117436,
    funds: 1486,
    foreign: 3497,
    shares: 101132,
    domestic: 1860,
    equipment: 11329,
    structures: 29028,
    intellectual: 1061,
    otherpayables: 67026,
    bonds: 20813,
    treasury: 6000,
    equityloan: 6000,
    homeloan: 1586,
    pensions: 455980,
    moneyfunds: 0,
    paper: 38090,
    otherreceivables: 35793,
    repurchaseshares: 51059,
    repurchases: 355371,
    interbank: 54556,
    mortgages: 387289,
    notes: 2342,
    bondnotes: 39535,
    insurance: 843027,
    monetarypaper: 685,
    monetarygold: 11367,
    assets: 0,
    estates: 448
  },
  "1970-09-01": {
    deposits: 722146,
    fundshares: 44172,
    self: 0,
    equities: 136915,
    funds: 1591,
    foreign: 3618,
    shares: 115214,
    domestic: 1869,
    equipment: 11669,
    structures: 29447,
    intellectual: 1128,
    otherpayables: 62025,
    bonds: 21484,
    treasury: 8000,
    equityloan: 8000,
    homeloan: 1599,
    pensions: 461508,
    moneyfunds: 0,
    paper: 33786,
    otherreceivables: 32242,
    repurchaseshares: 53841,
    repurchases: 361576,
    interbank: 56854,
    mortgages: 394828,
    notes: 2362,
    bondnotes: 41547,
    insurance: 866194,
    monetarypaper: 691,
    monetarygold: 11117,
    assets: 0,
    estates: 520
  },
  "1970-12-01": {
    deposits: 756452,
    fundshares: 46812,
    self: 0,
    equities: 153811,
    funds: 1666,
    foreign: 3743,
    shares: 122471,
    domestic: 1862,
    equipment: 12012,
    structures: 30267,
    intellectual: 1185,
    otherpayables: 57861,
    bonds: 23468,
    treasury: 10000,
    equityloan: 10000,
    homeloan: 1607,
    pensions: 469506,
    moneyfunds: 0,
    paper: 33067,
    otherreceivables: 34011,
    repurchaseshares: 57835,
    repurchases: 370209,
    interbank: 60260,
    mortgages: 403240,
    notes: 2396,
    bondnotes: 43634,
    insurance: 889067,
    monetarypaper: 702,
    monetarygold: 10732,
    assets: 0,
    estates: 546
  },
  "1971-03-01": {
    deposits: 771183,
    fundshares: 52687,
    self: 0,
    equities: 176729,
    funds: 1713,
    foreign: 3878,
    shares: 138321,
    domestic: 1878,
    equipment: 12440,
    structures: 31338,
    intellectual: 1235,
    otherpayables: 62349,
    bonds: 25053,
    treasury: 11069,
    equityloan: 11069,
    homeloan: 1629,
    pensions: 477835,
    moneyfunds: 0,
    paper: 31607,
    otherreceivables: 37006,
    repurchaseshares: 57456,
    repurchases: 367636,
    interbank: 61685,
    mortgages: 411311,
    notes: 2436,
    bondnotes: 43805,
    insurance: 913921,
    monetarypaper: 717,
    monetarygold: 10732,
    assets: 0,
    estates: 582
  },
  "1971-06-01": {
    deposits: 799468,
    fundshares: 52961,
    self: 0,
    equities: 180756,
    funds: 1731,
    foreign: 4020,
    shares: 133110,
    domestic: 1898,
    equipment: 12828,
    structures: 32500,
    intellectual: 1293,
    otherpayables: 58156,
    bonds: 25524,
    treasury: 12134,
    equityloan: 12134,
    homeloan: 1602,
    pensions: 492868,
    moneyfunds: 0,
    paper: 31767,
    otherreceivables: 39828,
    repurchaseshares: 58105,
    repurchases: 379314,
    interbank: 59874,
    mortgages: 423434,
    notes: 2430,
    bondnotes: 44496,
    insurance: 938156,
    monetarypaper: 729,
    monetarygold: 10332,
    assets: 0,
    estates: 616
  },
  "1971-09-01": {
    deposits: 811889,
    fundshares: 52214,
    self: 0,
    equities: 187561,
    funds: 1739,
    foreign: 4167,
    shares: 138552,
    domestic: 1918,
    equipment: 13148,
    structures: 33671,
    intellectual: 1360,
    otherpayables: 67733,
    bonds: 26530,
    treasury: 14273,
    equityloan: 14273,
    homeloan: 1600,
    pensions: 507021,
    moneyfunds: 0,
    paper: 32477,
    otherreceivables: 41018,
    repurchaseshares: 58741,
    repurchases: 387482,
    interbank: 61241,
    mortgages: 438098,
    notes: 2434,
    bondnotes: 47004,
    insurance: 962994,
    monetarypaper: 734,
    monetarygold: 10132,
    assets: 0,
    estates: 633
  },
  "1971-12-01": {
    deposits: 852987,
    fundshares: 55446,
    self: 0,
    equities: 200336,
    funds: 1750,
    foreign: 4324,
    shares: 143477,
    domestic: 2139,
    equipment: 13465,
    structures: 34716,
    intellectual: 1432,
    otherpayables: 62906,
    bonds: 28205,
    treasury: 15565,
    equityloan: 15565,
    homeloan: 1618,
    pensions: 519362,
    moneyfunds: 0,
    paper: 33847,
    otherreceivables: 42614,
    repurchaseshares: 65567,
    repurchases: 403041,
    interbank: 65863,
    mortgages: 452613,
    notes: 2490,
    bondnotes: 49537,
    insurance: 989156,
    monetarypaper: 742,
    monetarygold: 10132,
    assets: 0,
    estates: 680
  },
  "1972-03-01": {
    deposits: 861656,
    fundshares: 57548,
    self: 0,
    equities: 215216,
    funds: 1763,
    foreign: 4490,
    shares: 153995,
    domestic: 2157,
    equipment: 13976,
    structures: 35828,
    intellectual: 1477,
    otherpayables: 71309,
    bonds: 29941,
    treasury: 17035,
    equityloan: 17035,
    homeloan: 1709,
    pensions: 530622,
    moneyfunds: 0,
    paper: 34142,
    otherreceivables: 45328,
    repurchaseshares: 68035,
    repurchases: 409475,
    interbank: 65671,
    mortgages: 465269,
    notes: 2591,
    bondnotes: 50993,
    insurance: 1018027,
    monetarypaper: 761,
    monetarygold: 9588,
    assets: 0,
    estates: 729
  },
  "1972-06-01": {
    deposits: 891151,
    fundshares: 56656,
    self: 0,
    equities: 217732,
    funds: 1765,
    foreign: 4664,
    shares: 148937,
    domestic: 2171,
    equipment: 14468,
    structures: 36712,
    intellectual: 1527,
    otherpayables: 72916,
    bonds: 31703,
    treasury: 18707,
    equityloan: 18707,
    homeloan: 1721,
    pensions: 546092,
    moneyfunds: 0,
    paper: 33380,
    otherreceivables: 51292,
    repurchaseshares: 72273,
    repurchases: 426751,
    interbank: 64640,
    mortgages: 482782,
    notes: 2639,
    bondnotes: 53633,
    insurance: 1047522,
    monetarypaper: 772,
    monetarygold: 10410,
    assets: 0,
    estates: 931
  },
  "1972-09-01": {
    deposits: 911776,
    fundshares: 56209,
    self: 0,
    equities: 221859,
    funds: 1776,
    foreign: 4850,
    shares: 155678,
    domestic: 2185,
    equipment: 14929,
    structures: 37629,
    intellectual: 1581,
    otherpayables: 73702,
    bonds: 33141,
    treasury: 21404,
    equityloan: 21404,
    homeloan: 1729,
    pensions: 560928,
    moneyfunds: 0,
    paper: 32919,
    otherreceivables: 53484,
    repurchaseshares: 76128,
    repurchases: 438930,
    interbank: 66864,
    mortgages: 501230,
    notes: 2654,
    bondnotes: 55368,
    insurance: 1077262,
    monetarypaper: 778,
    monetarygold: 10410,
    assets: 0,
    estates: 1043
  },
  "1972-12-01": {
    deposits: 957905,
    fundshares: 58926,
    self: 0,
    equities: 242838,
    funds: 1811,
    foreign: 5045,
    shares: 160080,
    domestic: 2468,
    equipment: 15380,
    structures: 38810,
    intellectual: 1631,
    otherpayables: 74424,
    bonds: 35622,
    treasury: 23950,
    equityloan: 23950,
    homeloan: 1756,
    pensions: 570406,
    moneyfunds: 0,
    paper: 34720,
    otherreceivables: 58728,
    repurchaseshares: 88243,
    repurchases: 468450,
    interbank: 67512,
    mortgages: 521805,
    notes: 2730,
    bondnotes: 57946,
    insurance: 1108016,
    monetarypaper: 793,
    monetarygold: 10410,
    assets: 0,
    estates: 1151
  },
  "1973-03-01": {
    deposits: 972445,
    fundshares: 52843,
    self: 257,
    equities: 226741,
    funds: 1876,
    foreign: 5252,
    shares: 135000,
    domestic: 2454,
    equipment: 16059,
    structures: 39818,
    intellectual: 1734,
    otherpayables: 79259,
    bonds: 37331,
    treasury: 26385,
    equityloan: 25385,
    homeloan: 1943,
    pensions: 584102,
    moneyfunds: 0,
    paper: 35109,
    otherreceivables: 63933,
    repurchaseshares: 90550,
    repurchases: 489577,
    interbank: 66823,
    mortgages: 537414,
    notes: 2946,
    bondnotes: 60706,
    insurance: 1124931,
    monetarypaper: 807,
    monetarygold: 10410,
    assets: 0,
    estates: 1274
  },
  "1973-06-01": {
    deposits: 998669,
    fundshares: 48013,
    self: 506,
    equities: 212021,
    funds: 1969,
    foreign: 5471,
    shares: 117816,
    domestic: 2476,
    equipment: 16880,
    structures: 41131,
    intellectual: 1838,
    otherpayables: 84964,
    bonds: 37868,
    treasury: 27749,
    equityloan: 26749,
    homeloan: 2008,
    pensions: 598265,
    moneyfunds: 0,
    paper: 37063,
    otherreceivables: 70689,
    repurchaseshares: 99082,
    repurchases: 515776,
    interbank: 64321,
    mortgages: 560311,
    notes: 3076,
    bondnotes: 67199,
    insurance: 1142276,
    monetarypaper: 820,
    monetarygold: 10410,
    assets: 0,
    estates: 1412
  },
  "1973-09-01": {
    deposits: 1016284,
    fundshares: 51927,
    self: 735,
    equities: 232736,
    funds: 2072,
    foreign: 5700,
    shares: 138868,
    domestic: 2508,
    equipment: 17674,
    structures: 42764,
    intellectual: 1942,
    otherpayables: 90419,
    bonds: 39093,
    treasury: 30559,
    equityloan: 28059,
    homeloan: 2089,
    pensions: 598653,
    moneyfunds: 0,
    paper: 39558,
    otherreceivables: 69527,
    repurchaseshares: 107428,
    repurchases: 538207,
    interbank: 74145,
    mortgages: 580528,
    notes: 3213,
    bondnotes: 73728,
    insurance: 1163225,
    monetarypaper: 829,
    monetarygold: 10410,
    assets: 0,
    estates: 1417
  },
  "1973-12-01": {
    deposits: 1053207,
    fundshares: 46635,
    self: 950,
    equities: 210951,
    funds: 2165,
    foreign: 5944,
    shares: 118935,
    domestic: 2820,
    equipment: 18370,
    structures: 44299,
    intellectual: 2052,
    otherpayables: 94746,
    bonds: 41014,
    treasury: 31834,
    equityloan: 29334,
    homeloan: 2122,
    pensions: 620023,
    moneyfunds: 0,
    paper: 41672,
    otherreceivables: 76319,
    repurchaseshares: 114101,
    repurchases: 554529,
    interbank: 73261,
    mortgages: 598097,
    notes: 3276,
    bondnotes: 77877,
    insurance: 1179996,
    monetarypaper: 844,
    monetarygold: 11567,
    assets: 0,
    estates: 1451
  },
  "1974-03-01": {
    deposits: 1061512,
    fundshares: 44521,
    self: 1146,
    equities: 213134,
    funds: 2246,
    foreign: 6204,
    shares: 116993,
    domestic: 2759,
    equipment: 19341,
    structures: 45912,
    intellectual: 2194,
    otherpayables: 100125,
    bonds: 41321,
    treasury: 34514,
    equityloan: 31514,
    homeloan: 2306,
    pensions: 634453,
    moneyfunds: 0,
    paper: 45708,
    otherreceivables: 74237,
    repurchaseshares: 114979,
    repurchases: 566357,
    interbank: 73091,
    mortgages: 611506,
    notes: 3484,
    bondnotes: 79586,
    insurance: 1206785,
    monetarypaper: 872,
    monetarygold: 11567,
    assets: 263,
    estates: 1518
  },
  "1974-06-01": {
    deposits: 1091921,
    fundshares: 40662,
    self: 1343,
    equities: 195024,
    funds: 2311,
    foreign: 6477,
    shares: 84398,
    domestic: 2750,
    equipment: 20566,
    structures: 48367,
    intellectual: 2362,
    otherpayables: 98429,
    bonds: 42922,
    treasury: 36542,
    equityloan: 33542,
    homeloan: 2413,
    pensions: 656686,
    moneyfunds: 0,
    paper: 48184,
    otherreceivables: 74623,
    repurchaseshares: 127590,
    repurchases: 601110,
    interbank: 74721,
    mortgages: 628603,
    notes: 3701,
    bondnotes: 85523,
    insurance: 1232262,
    monetarypaper: 878,
    monetarygold: 11567,
    assets: 570,
    estates: 1527
  },
  "1974-09-01": {
    deposits: 1106142,
    fundshares: 32320,
    self: 1530,
    equities: 145878,
    funds: 2393,
    foreign: 6767,
    shares: 61894,
    domestic: 2740,
    equipment: 22178,
    structures: 51071,
    intellectual: 2554,
    otherpayables: 101275,
    bonds: 45069,
    treasury: 39133,
    equityloan: 35633,
    homeloan: 2543,
    pensions: 694735,
    moneyfunds: 0,
    paper: 52702,
    otherreceivables: 66766,
    repurchaseshares: 133287,
    repurchases: 623644,
    interbank: 83336,
    mortgages: 641417,
    notes: 3883,
    bondnotes: 93585,
    insurance: 1254209,
    monetarypaper: 886,
    monetarygold: 11567,
    assets: 1386,
    estates: 1555
  },
  "1974-12-01": {
    deposits: 1136615,
    fundshares: 35152,
    self: 1706,
    equities: 163536,
    funds: 2523,
    foreign: 7074,
    shares: 65900,
    domestic: 2723,
    equipment: 24003,
    structures: 53686,
    intellectual: 2762,
    otherpayables: 82932,
    bonds: 48741,
    treasury: 40501,
    equityloan: 37001,
    homeloan: 2624,
    pensions: 702614,
    moneyfunds: 0,
    paper: 55119,
    otherreceivables: 68171,
    repurchaseshares: 132487,
    repurchases: 636657,
    interbank: 78087,
    mortgages: 649854,
    notes: 4018,
    bondnotes: 97897,
    insurance: 1284519,
    monetarypaper: 897,
    monetarygold: 11652,
    assets: 2379,
    estates: 1580
  },
  "1975-03-01": {
    deposits: 1143450,
    fundshares: 40968,
    self: 1829,
    equities: 197753,
    funds: 2700,
    foreign: 7399,
    shares: 79711,
    domestic: 2892,
    equipment: 25598,
    structures: 55463,
    intellectual: 2997,
    otherpayables: 94933,
    bonds: 49930,
    treasury: 43590,
    equityloan: 40090,
    homeloan: 2677,
    pensions: 703017,
    moneyfunds: 0,
    paper: 55480,
    otherreceivables: 69382,
    repurchaseshares: 125475,
    repurchases: 619708,
    interbank: 83798,
    mortgages: 655850,
    notes: 4113,
    bondnotes: 100492,
    insurance: 1320789,
    monetarypaper: 902,
    monetarygold: 11620,
    assets: 3620,
    estates: 1578
  },
  "1975-06-01": {
    deposits: 1170801,
    fundshares: 46061,
    self: 1921,
    equities: 232450,
    funds: 2927,
    foreign: 7745,
    shares: 90807,
    domestic: 2998,
    equipment: 26879,
    structures: 56641,
    intellectual: 3179,
    otherpayables: 87439,
    bonds: 50381,
    treasury: 46342,
    equityloan: 43342,
    homeloan: 2653,
    pensions: 695283,
    moneyfunds: 0,
    paper: 54637,
    otherreceivables: 72050,
    repurchaseshares: 125689,
    repurchases: 622073,
    interbank: 79628,
    mortgages: 666597,
    notes: 4123,
    bondnotes: 101625,
    insurance: 1357785,
    monetarypaper: 909,
    monetarygold: 11620,
    assets: 3661,
    estates: 1551
  },
  "1975-09-01": {
    deposits: 1194639,
    fundshares: 40876,
    self: 2015,
    equities: 206010,
    funds: 3170,
    foreign: 8110,
    shares: 72011,
    domestic: 3104,
    equipment: 27761,
    structures: 57318,
    intellectual: 3343,
    otherpayables: 96385,
    bonds: 52110,
    treasury: 49271,
    equityloan: 46771,
    homeloan: 2679,
    pensions: 719588,
    moneyfunds: 0,
    paper: 53028,
    otherreceivables: 87080,
    repurchaseshares: 112779,
    repurchases: 612550,
    interbank: 84708,
    mortgages: 677995,
    notes: 4184,
    bondnotes: 103741,
    insurance: 1387485,
    monetarypaper: 914,
    monetarygold: 11599,
    assets: 3708,
    estates: 1508
  },
  "1975-12-01": {
    deposits: 1232380,
    fundshares: 43034,
    self: 2135,
    equities: 221379,
    funds: 3395,
    foreign: 8497,
    shares: 74976,
    domestic: 3152,
    equipment: 28677,
    structures: 58173,
    intellectual: 3502,
    otherpayables: 86747,
    bonds: 58064,
    treasury: 54000,
    equityloan: 52500,
    homeloan: 2705,
    pensions: 726549,
    moneyfunds: 0,
    paper: 56429,
    otherreceivables: 89619,
    repurchaseshares: 115162,
    repurchases: 630657,
    interbank: 87390,
    mortgages: 690183,
    notes: 4376,
    bondnotes: 107292,
    insurance: 1422364,
    monetarypaper: 929,
    monetarygold: 11599,
    assets: 3645,
    estates: 1510
  },
  "1976-03-01": {
    deposits: 1245613,
    fundshares: 46920,
    self: 1837,
    equities: 251071,
    funds: 3389,
    foreign: 8908,
    shares: 90498,
    domestic: 3261,
    equipment: 29849,
    structures: 59069,
    intellectual: 3652,
    otherpayables: 95532,
    bonds: 58398,
    treasury: 56000,
    equityloan: 54750,
    homeloan: 2827,
    pensions: 721269,
    moneyfunds: 155,
    paper: 56795,
    otherreceivables: 94458,
    repurchaseshares: 116359,
    repurchases: 623322,
    interbank: 87947,
    mortgages: 705260,
    notes: 4561,
    bondnotes: 110181,
    insurance: 1450731,
    monetarypaper: 945,
    monetarygold: 11599,
    assets: 3712,
    estates: 1571
  },
  "1976-06-01": {
    deposits: 1282530,
    fundshares: 47003,
    self: 1861,
    equities: 251747,
    funds: 3620,
    foreign: 9345,
    shares: 90703,
    domestic: 3458,
    equipment: 30884,
    structures: 60672,
    intellectual: 3826,
    otherpayables: 99160,
    bonds: 60762,
    treasury: 58000,
    equityloan: 56900,
    homeloan: 2839,
    pensions: 724930,
    moneyfunds: 89,
    paper: 57290,
    otherreceivables: 90409,
    repurchaseshares: 119593,
    repurchases: 644957,
    interbank: 91203,
    mortgages: 725798,
    notes: 4635,
    bondnotes: 113572,
    insurance: 1475840,
    monetarypaper: 959,
    monetarygold: 11598,
    assets: 3430,
    estates: 1693
  },
  "1976-09-01": {
    deposits: 1305060,
    fundshares: 45799,
    self: 1799,
    equities: 251641,
    funds: 3878,
    foreign: 9781,
    shares: 92302,
    domestic: 3655,
    equipment: 32065,
    structures: 62115,
    intellectual: 4022,
    otherpayables: 104255,
    bonds: 62644,
    treasury: 60000,
    equityloan: 59250,
    homeloan: 2864,
    pensions: 737186,
    moneyfunds: 99,
    paper: 57618,
    otherreceivables: 91864,
    repurchaseshares: 127740,
    repurchases: 655494,
    interbank: 92865,
    mortgages: 747794,
    notes: 4698,
    bondnotes: 117855,
    insurance: 1502360,
    monetarypaper: 965,
    monetarygold: 11598,
    assets: 3421,
    estates: 1798
  },
  "1976-12-01": {
    deposits: 1353402,
    fundshares: 46510,
    self: 1943,
    equities: 255588,
    funds: 4110,
    foreign: 10299,
    shares: 97475,
    domestic: 3944,
    equipment: 33392,
    structures: 63531,
    intellectual: 4232,
    otherpayables: 91491,
    bonds: 65347,
    treasury: 62761,
    equityloan: 62161,
    homeloan: 2889,
    pensions: 746965,
    moneyfunds: 305,
    paper: 62458,
    otherreceivables: 93805,
    repurchaseshares: 142099,
    repurchases: 692730,
    interbank: 90277,
    mortgages: 769536,
    notes: 4857,
    bondnotes: 121934,
    insurance: 1530374,
    monetarypaper: 983,
    monetarygold: 11598,
    assets: 3404,
    estates: 2000
  },
  "1977-03-01": {
    deposits: 1375125,
    fundshares: 43037,
    self: 1803,
    equities: 239646,
    funds: 4270,
    foreign: 10821,
    shares: 91016,
    domestic: 4153,
    equipment: 34547,
    structures: 65989,
    intellectual: 4376,
    otherpayables: 108038,
    bonds: 66850,
    treasury: 64000,
    equityloan: 63650,
    homeloan: 3175,
    pensions: 770310,
    moneyfunds: 490,
    paper: 63901,
    otherreceivables: 104743,
    repurchaseshares: 136387,
    repurchases: 699963,
    interbank: 91968,
    mortgages: 789152,
    notes: 5224,
    bondnotes: 127536,
    insurance: 1562770,
    monetarypaper: 990,
    monetarygold: 11636,
    assets: 3460,
    estates: 1980
  },
  "1977-06-01": {
    deposits: 1417178,
    fundshares: 45901,
    self: 1927,
    equities: 249074,
    funds: 4355,
    foreign: 11374,
    shares: 94814,
    domestic: 4299,
    equipment: 35709,
    structures: 67669,
    intellectual: 4476,
    otherpayables: 119423,
    bonds: 71373,
    treasury: 66000,
    equityloan: 65900,
    homeloan: 3198,
    pensions: 780063,
    moneyfunds: 352,
    paper: 68316,
    otherreceivables: 109557,
    repurchaseshares: 143723,
    repurchases: 733740,
    interbank: 93348,
    mortgages: 820321,
    notes: 5302,
    bondnotes: 132651,
    insurance: 1597772,
    monetarypaper: 1000,
    monetarygold: 11620,
    assets: 3443,
    estates: 1888
  },
  "1977-09-01": {
    deposits: 1449144,
    fundshares: 45617,
    self: 1877,
    equities: 242097,
    funds: 4363,
    foreign: 11962,
    shares: 92543,
    domestic: 4445,
    equipment: 36985,
    structures: 69718,
    intellectual: 4542,
    otherpayables: 126607,
    bonds: 73016,
    treasury: 68000,
    equityloan: 68150,
    homeloan: 3226,
    pensions: 792259,
    moneyfunds: 377,
    paper: 68365,
    otherreceivables: 113859,
    repurchaseshares: 152423,
    repurchases: 757210,
    interbank: 91174,
    mortgages: 853619,
    notes: 5350,
    bondnotes: 138434,
    insurance: 1632690,
    monetarypaper: 1016,
    monetarygold: 11595,
    assets: 3463,
    estates: 1831
  },
  "1977-12-01": {
    deposits: 1502021,
    fundshares: 45491,
    self: 1711,
    equities: 246479,
    funds: 4346,
    foreign: 12587,
    shares: 93415,
    domestic: 4544,
    equipment: 38418,
    structures: 71723,
    intellectual: 4582,
    otherpayables: 120858,
    bonds: 77863,
    treasury: 71041,
    equityloan: 71341,
    homeloan: 3295,
    pensions: 806188,
    moneyfunds: 490,
    paper: 75134,
    otherreceivables: 109250,
    repurchaseshares: 165505,
    repurchases: 796559,
    interbank: 101798,
    mortgages: 886382,
    notes: 5554,
    bondnotes: 145000,
    insurance: 1668770,
    monetarypaper: 1029,
    monetarygold: 11718,
    assets: 3887,
    estates: 1810
  },
  "1978-03-01": {
    deposits: 1511279,
    fundshares: 44493,
    self: 1858,
    equities: 239675,
    funds: 4355,
    foreign: 13053,
    shares: 91684,
    domestic: 4639,
    equipment: 40122,
    structures: 73804,
    intellectual: 4622,
    otherpayables: 133170,
    bonds: 79688,
    treasury: 72500,
    equityloan: 73200,
    homeloan: 3738,
    pensions: 827166,
    moneyfunds: 631,
    paper: 75985,
    otherreceivables: 120981,
    repurchaseshares: 172542,
    repurchases: 810810,
    interbank: 101569,
    mortgages: 910893,
    notes: 6083,
    bondnotes: 152133,
    insurance: 1708455,
    monetarypaper: 1048,
    monetarygold: 11718,
    assets: 5620,
    estates: 1504
  },
  "1978-06-01": {
    deposits: 1567488,
    fundshares: 46687,
    self: 1937,
    equities: 264996,
    funds: 4356,
    foreign: 13540,
    shares: 99893,
    domestic: 4835,
    equipment: 42066,
    structures: 76545,
    intellectual: 4730,
    otherpayables: 150901,
    bonds: 81845,
    treasury: 74000,
    equityloan: 76265,
    homeloan: 3818,
    pensions: 830849,
    moneyfunds: 641,
    paper: 80809,
    otherreceivables: 124573,
    repurchaseshares: 183420,
    repurchases: 857688,
    interbank: 105966,
    mortgages: 945895,
    notes: 6283,
    bondnotes: 161890,
    insurance: 1752290,
    monetarypaper: 1056,
    monetarygold: 11706,
    assets: 6959,
    estates: 1649
  },
  "1978-09-01": {
    deposits: 1610630,
    fundshares: 48619,
    self: 2082,
    equities: 293998,
    funds: 4321,
    foreign: 14053,
    shares: 109740,
    domestic: 5020,
    equipment: 44093,
    structures: 79210,
    intellectual: 4866,
    otherpayables: 164711,
    bonds: 82523,
    treasury: 75500,
    equityloan: 78004,
    homeloan: 3925,
    pensions: 826325,
    moneyfunds: 843,
    paper: 79545,
    otherreceivables: 139114,
    repurchaseshares: 191435,
    repurchases: 880415,
    interbank: 109917,
    mortgages: 982640,
    notes: 6490,
    bondnotes: 171023,
    insurance: 1797215,
    monetarypaper: 1062,
    monetarygold: 11668,
    assets: 8407,
    estates: 1807
  },
  "1978-12-01": {
    deposits: 1662264,
    fundshares: 46050,
    self: 1973,
    equities: 281630,
    funds: 4352,
    foreign: 14590,
    shares: 102170,
    domestic: 5230,
    equipment: 46185,
    structures: 82243,
    intellectual: 5050,
    otherpayables: 177322,
    bonds: 85133,
    treasury: 78596,
    equityloan: 81210,
    homeloan: 4120,
    pensions: 845997,
    moneyfunds: 1270,
    paper: 89077,
    otherreceivables: 146269,
    repurchaseshares: 199864,
    repurchases: 926210,
    interbank: 126061,
    mortgages: 1019494,
    notes: 6777,
    bondnotes: 181673,
    insurance: 1836333,
    monetarypaper: 1078,
    monetarygold: 11671,
    assets: 10808,
    estates: 1960
  },
  "1979-03-01": {
    deposits: 1674146,
    fundshares: 47697,
    self: 2065,
    equities: 301453,
    funds: 4541,
    foreign: 15154,
    shares: 109103,
    domestic: 5556,
    equipment: 48860,
    structures: 85939,
    intellectual: 5290,
    otherpayables: 187710,
    bonds: 86805,
    treasury: 80500,
    equityloan: 82715,
    homeloan: 4470,
    pensions: 856199,
    moneyfunds: 1299,
    paper: 94963,
    otherreceivables: 147512,
    repurchaseshares: 211921,
    repurchases: 947371,
    interbank: 127397,
    mortgages: 1047855,
    notes: 7195,
    bondnotes: 192981,
    insurance: 1881855,
    monetarypaper: 1113,
    monetarygold: 11479,
    assets: 18020,
    estates: 2017
  },
  "1979-06-01": {
    deposits: 1715150,
    fundshares: 48943,
    self: 2315,
    equities: 307007,
    funds: 4891,
    foreign: 15744,
    shares: 118678,
    domestic: 6153,
    equipment: 51499,
    structures: 90027,
    intellectual: 5503,
    otherpayables: 199047,
    bonds: 87912,
    treasury: 82500,
    equityloan: 84213,
    homeloan: 4597,
    pensions: 866433,
    moneyfunds: 1653,
    paper: 104595,
    otherreceivables: 156405,
    repurchaseshares: 240193,
    repurchases: 1007130,
    interbank: 132383,
    mortgages: 1085214,
    notes: 7455,
    bondnotes: 203065,
    insurance: 1924370,
    monetarypaper: 1126,
    monetarygold: 11323,
    assets: 25915,
    estates: 2004
  },
  "1979-09-01": {
    deposits: 1762375,
    fundshares: 51166,
    self: 2495,
    equities: 329108,
    funds: 5390,
    foreign: 16365,
    shares: 125951,
    domestic: 6750,
    equipment: 54144,
    structures: 94382,
    intellectual: 5738,
    otherpayables: 209057,
    bonds: 88166,
    treasury: 85000,
    equityloan: 87837,
    homeloan: 4767,
    pensions: 867614,
    moneyfunds: 2126,
    paper: 109697,
    otherreceivables: 152900,
    repurchaseshares: 250253,
    repurchases: 1053936,
    interbank: 135328,
    mortgages: 1124858,
    notes: 7720,
    bondnotes: 214285,
    insurance: 1971086,
    monetarypaper: 1135,
    monetarygold: 11228,
    assets: 34194,
    estates: 1966
  },
  "1979-12-01": {
    deposits: 1812855,
    fundshares: 51789,
    self: 2694,
    equities: 323928,
    funds: 12341,
    foreign: 17016,
    shares: 117825,
    domestic: 7575,
    equipment: 56805,
    structures: 98030,
    intellectual: 5989,
    otherpayables: 204225,
    bonds: 92073,
    treasury: 87411,
    equityloan: 91936,
    homeloan: 5149,
    pensions: 894669,
    moneyfunds: 2755,
    paper: 115914,
    otherreceivables: 153652,
    repurchaseshares: 247442,
    repurchases: 1090940,
    interbank: 153767,
    mortgages: 1157473,
    notes: 8328,
    bondnotes: 230300,
    insurance: 2026959,
    monetarypaper: 1145,
    monetarygold: 11172,
    assets: 45214,
    estates: 1951
  },
  "1980-03-01": {
    deposits: 1815594,
    fundshares: 53772,
    self: 1435,
    equities: 310605,
    funds: 13139,
    foreign: 17699,
    shares: 98920,
    domestic: 8281,
    equipment: 60291,
    structures: 101896,
    intellectual: 6202,
    otherpayables: 214655,
    bonds: 94459,
    treasury: 90000,
    equityloan: 93858,
    homeloan: 4827,
    pensions: 918621,
    moneyfunds: 3010,
    paper: 119384,
    otherreceivables: 159118,
    repurchaseshares: 247345,
    repurchases: 1097725,
    interbank: 154943,
    mortgages: 1182236,
    notes: 8162,
    bondnotes: 244249,
    insurance: 2069524,
    monetarypaper: 1159,
    monetarygold: 11172,
    assets: 61292,
    estates: 1942
  },
  "1980-06-01": {
    deposits: 1830393,
    fundshares: 59434,
    self: 1832,
    equities: 348661,
    funds: 14000,
    foreign: 18415,
    shares: 123317,
    domestic: 8985,
    equipment: 63532,
    structures: 106093,
    intellectual: 6451,
    otherpayables: 213474,
    bonds: 98588,
    treasury: 105699,
    equityloan: 110180,
    homeloan: 4975,
    pensions: 919479,
    moneyfunds: 3467,
    paper: 122437,
    otherreceivables: 174220,
    repurchaseshares: 267016,
    repurchases: 1103079,
    interbank: 162960,
    mortgages: 1199647,
    notes: 8444,
    bondnotes: 252892,
    insurance: 2131665,
    monetarypaper: 1169,
    monetarygold: 11172,
    assets: 77827,
    estates: 1930
  },
  "1980-09-01": {
    deposits: 1864661,
    fundshares: 66091,
    self: 1820,
    equities: 383883,
    funds: 15541,
    foreign: 19166,
    shares: 128490,
    domestic: 10059,
    equipment: 66655,
    structures: 110507,
    intellectual: 6687,
    otherpayables: 212324,
    bonds: 100927,
    treasury: 109418,
    equityloan: 113767,
    homeloan: 5035,
    pensions: 919032,
    moneyfunds: 4509,
    paper: 123806,
    otherreceivables: 182794,
    repurchaseshares: 275671,
    repurchases: 1123514,
    interbank: 162306,
    mortgages: 1226798,
    notes: 8600,
    bondnotes: 260435,
    insurance: 2194290,
    monetarypaper: 1184,
    monetarygold: 11168,
    assets: 79901,
    estates: 1919
  },
  "1980-12-01": {
    deposits: 1937851,
    fundshares: 70395,
    self: 1955,
    equities: 409866,
    funds: 16828,
    foreign: 19954,
    shares: 129662,
    domestic: 12027,
    equipment: 69415,
    structures: 116220,
    intellectual: 6940,
    otherpayables: 219421,
    bonds: 102283,
    treasury: 112507,
    equityloan: 116016,
    homeloan: 5160,
    pensions: 934973,
    moneyfunds: 5056,
    paper: 128839,
    otherreceivables: 190022,
    repurchaseshares: 294148,
    repurchases: 1192269,
    interbank: 172160,
    mortgages: 1255532,
    notes: 9010,
    bondnotes: 273885,
    insurance: 2251974,
    monetarypaper: 1203,
    monetarygold: 11160,
    assets: 76415,
    estates: 1910
  },
  "1981-03-01": {
    deposits: 1934622,
    fundshares: 71227,
    self: 2116,
    equities: 415692,
    funds: 17327,
    foreign: 20781,
    shares: 137798,
    domestic: 11907,
    equipment: 72840,
    structures: 121247,
    intellectual: 7211,
    otherpayables: 224954,
    bonds: 98819,
    treasury: 112088,
    equityloan: 115768,
    homeloan: 5348,
    pensions: 968940,
    moneyfunds: 6264,
    paper: 136284,
    otherreceivables: 197165,
    repurchaseshares: 307164,
    repurchases: 1185986,
    interbank: 181299,
    mortgages: 1272905,
    notes: 9308,
    bondnotes: 280643,
    insurance: 2310686,
    monetarypaper: 1227,
    monetarygold: 11154,
    assets: 114223,
    estates: 1910
  },
  "1981-06-01": {
    deposits: 1970318,
    fundshares: 71535,
    self: 2240,
    equities: 410217,
    funds: 16878,
    foreign: 21647,
    shares: 147183,
    domestic: 12772,
    equipment: 76300,
    structures: 125766,
    intellectual: 7440,
    otherpayables: 234251,
    bonds: 101129,
    treasury: 118427,
    equityloan: 120118,
    homeloan: 5568,
    pensions: 999841,
    moneyfunds: 8392,
    paper: 147180,
    otherreceivables: 205280,
    repurchaseshares: 319138,
    repurchases: 1240085,
    interbank: 186440,
    mortgages: 1297805,
    notes: 9697,
    bondnotes: 294249,
    insurance: 2366244,
    monetarypaper: 1246,
    monetarygold: 11154,
    assets: 129871,
    estates: 1912
  },
  "1981-09-01": {
    deposits: 2013736,
    fundshares: 65157,
    self: 2033,
    equities: 373360,
    funds: 14917,
    foreign: 22554,
    shares: 128370,
    domestic: 13649,
    equipment: 79319,
    structures: 129825,
    intellectual: 7688,
    otherpayables: 233988,
    bonds: 102884,
    treasury: 127269,
    equityloan: 125677,
    homeloan: 5682,
    pensions: 1033677,
    moneyfunds: 10784,
    paper: 158401,
    otherreceivables: 207526,
    repurchaseshares: 332289,
    repurchases: 1290940,
    interbank: 208166,
    mortgages: 1318578,
    notes: 9977,
    bondnotes: 311089,
    insurance: 2415112,
    monetarypaper: 1257,
    monetarygold: 11152,
    assets: 164470,
    estates: 1918
  },
  "1981-12-01": {
    deposits: 2075206,
    fundshares: 68834,
    self: 1972,
    equities: 402197,
    funds: 14570,
    foreign: 23508,
    shares: 140116,
    domestic: 14747,
    equipment: 82267,
    structures: 135161,
    intellectual: 7954,
    otherpayables: 232213,
    bonds: 108687,
    treasury: 134173,
    equityloan: 132640,
    homeloan: 5827,
    pensions: 1043531,
    moneyfunds: 13279,
    paper: 161519,
    otherreceivables: 206746,
    repurchaseshares: 384262,
    repurchases: 1387126,
    interbank: 213214,
    mortgages: 1333801,
    notes: 10391,
    bondnotes: 319417,
    insurance: 2475912,
    monetarypaper: 1278,
    monetarygold: 11151,
    assets: 186305,
    estates: 1930
  },
  "1982-03-01": {
    deposits: 2090454,
    fundshares: 66089,
    self: 1973,
    equities: 394587,
    funds: 14226,
    foreign: 15492,
    shares: 133970,
    domestic: 10504,
    equipment: 85234,
    structures: 141758,
    intellectual: 8353,
    otherpayables: 239250,
    bonds: 111555,
    treasury: 137428,
    equityloan: 133735,
    homeloan: 5882,
    pensions: 1063738,
    moneyfunds: 14300,
    paper: 161243,
    otherreceivables: 220312,
    repurchaseshares: 376159,
    repurchases: 1380925,
    interbank: 217261,
    mortgages: 1346810,
    notes: 10652,
    bondnotes: 329842,
    insurance: 2529856,
    monetarypaper: 1298,
    monetarygold: 11150,
    assets: 198732,
    estates: 1952
  },
  "1982-06-01": {
    deposits: 2120765,
    fundshares: 67525,
    self: 2058,
    equities: 396225,
    funds: 15229,
    foreign: 16156,
    shares: 122169,
    domestic: 11210,
    equipment: 88298,
    structures: 146704,
    intellectual: 8715,
    otherpayables: 267502,
    bonds: 112845,
    treasury: 142059,
    equityloan: 133724,
    homeloan: 6102,
    pensions: 1079516,
    moneyfunds: 14369,
    paper: 174378,
    otherreceivables: 239500,
    repurchaseshares: 378902,
    repurchases: 1428705,
    interbank: 226268,
    mortgages: 1364800,
    notes: 10829,
    bondnotes: 347960,
    insurance: 2590912,
    monetarypaper: 1327,
    monetarygold: 11149,
    assets: 209680,
    estates: 1986
  },
  "1982-09-01": {
    deposits: 2173053,
    fundshares: 75941,
    self: 2279,
    equities: 455591,
    funds: 17586,
    foreign: 16697,
    shares: 139765,
    domestic: 11603,
    equipment: 90698,
    structures: 150411,
    intellectual: 9051,
    otherpayables: 248186,
    bonds: 117498,
    treasury: 154355,
    equityloan: 151415,
    homeloan: 6094,
    pensions: 1056950,
    moneyfunds: 14171,
    paper: 167826,
    otherreceivables: 255849,
    repurchaseshares: 399001,
    repurchases: 1470674,
    interbank: 233935,
    mortgages: 1375804,
    notes: 10914,
    bondnotes: 367333,
    insurance: 2670067,
    monetarypaper: 1341,
    monetarygold: 11148,
    assets: 235246,
    estates: 2020
  },
  "1982-12-01": {
    deposits: 2262297,
    fundshares: 88353,
    self: 2695,
    equities: 507840,
    funds: 20173,
    foreign: 16572,
    shares: 158774,
    domestic: 12653,
    equipment: 92741,
    structures: 152924,
    intellectual: 9327,
    otherpayables: 225520,
    bonds: 123964,
    treasury: 164311,
    equityloan: 161701,
    homeloan: 6269,
    pensions: 1067669,
    moneyfunds: 14185,
    paper: 174175,
    otherreceivables: 264237,
    repurchaseshares: 425881,
    repurchases: 1509818,
    interbank: 239745,
    mortgages: 1391541,
    notes: 11249,
    bondnotes: 383892,
    insurance: 2744429,
    monetarypaper: 1359,
    monetarygold: 11148,
    assets: 219928,
    estates: 2043
  },
  "1983-03-01": {
    deposits: 2285071,
    fundshares: 101710,
    self: 2854,
    equities: 571614,
    funds: 22141,
    foreign: 17656,
    shares: 175658,
    domestic: 12871,
    equipment: 95019,
    structures: 155265,
    intellectual: 9606,
    otherpayables: 268145,
    bonds: 126957,
    treasury: 163861,
    equityloan: 159336,
    homeloan: 6221,
    pensions: 1063063,
    moneyfunds: 15481,
    paper: 168337,
    otherreceivables: 280935,
    repurchaseshares: 405803,
    repurchases: 1500942,
    interbank: 221432,
    mortgages: 1421275,
    notes: 11226,
    bondnotes: 398710,
    insurance: 2824972,
    monetarypaper: 1393,
    monetarygold: 11138,
    assets: 197336,
    estates: 2058
  },
  "1983-06-01": {
    deposits: 2362946,
    fundshares: 119852,
    self: 3062,
    equities: 656522,
    funds: 24088,
    foreign: 18285,
    shares: 187246,
    domestic: 13257,
    equipment: 97172,
    structures: 158448,
    intellectual: 9931,
    otherpayables: 285153,
    bonds: 129311,
    treasury: 165574,
    equityloan: 160959,
    homeloan: 6318,
    pensions: 1047348,
    moneyfunds: 15414,
    paper: 176252,
    otherreceivables: 294295,
    repurchaseshares: 414821,
    repurchases: 1537065,
    interbank: 221406,
    mortgages: 1463386,
    notes: 11301,
    bondnotes: 415730,
    insurance: 2918255,
    monetarypaper: 1421,
    monetarygold: 11131,
    assets: 180893,
    estates: 2067
  },
  "1983-09-01": {
    deposits: 2433533,
    fundshares: 123236,
    self: 3067,
    equities: 658099,
    funds: 24175,
    foreign: 18969,
    shares: 180915,
    domestic: 14173,
    equipment: 99449,
    structures: 162885,
    intellectual: 10321,
    otherpayables: 297779,
    bonds: 136038,
    treasury: 175664,
    equityloan: 171103,
    homeloan: 6255,
    pensions: 1074312,
    moneyfunds: 16418,
    paper: 182450,
    otherreceivables: 307980,
    repurchaseshares: 434261,
    repurchases: 1583056,
    interbank: 217915,
    mortgages: 1516771,
    notes: 11276,
    bondnotes: 435118,
    insurance: 2983811,
    monetarypaper: 1446,
    monetarygold: 11128,
    assets: 179590,
    estates: 2071
  },
  "1983-12-01": {
    deposits: 2519170,
    fundshares: 126973,
    self: 3278,
    equities: 657834,
    funds: 24432,
    foreign: 22143,
    shares: 199615,
    domestic: 15538,
    equipment: 102502,
    structures: 167470,
    intellectual: 10757,
    otherpayables: 314445,
    bonds: 141903,
    treasury: 190878,
    equityloan: 186231,
    homeloan: 6395,
    pensions: 1106920,
    moneyfunds: 18370,
    paper: 195463,
    otherreceivables: 305192,
    repurchaseshares: 456744,
    repurchases: 1633248,
    interbank: 230815,
    mortgages: 1565226,
    notes: 11453,
    bondnotes: 451587,
    insurance: 3051623,
    monetarypaper: 1465,
    monetarygold: 11121,
    assets: 179467,
    estates: 2068
  },
  "1984-03-01": {
    deposits: 2560563,
    fundshares: 129465,
    self: 3317,
    equities: 619835,
    funds: 24391,
    foreign: 22653,
    shares: 192924,
    domestic: 17058,
    equipment: 105669,
    structures: 171387,
    intellectual: 11120,
    otherpayables: 311818,
    bonds: 140762,
    treasury: 190628,
    equityloan: 184078,
    homeloan: 6614,
    pensions: 1188397,
    moneyfunds: 19661,
    paper: 201969,
    otherreceivables: 326157,
    repurchaseshares: 467568,
    repurchases: 1675399,
    interbank: 225249,
    mortgages: 1612350,
    notes: 11679,
    bondnotes: 468253,
    insurance: 3124166,
    monetarypaper: 1499,
    monetarygold: 11111,
    assets: 195074,
    estates: 2151
  },
  "1984-06-01": {
    deposits: 2653543,
    fundshares: 129412,
    self: 2990,
    equities: 594195,
    funds: 23567,
    foreign: 23253,
    shares: 173585,
    domestic: 17742,
    equipment: 109168,
    structures: 177038,
    intellectual: 11558,
    otherpayables: 337656,
    bonds: 150091,
    treasury: 186501,
    equityloan: 177686,
    homeloan: 6821,
    pensions: 1242488,
    moneyfunds: 20060,
    paper: 212570,
    otherreceivables: 330905,
    repurchaseshares: 491555,
    repurchases: 1765682,
    interbank: 229825,
    mortgages: 1670181,
    notes: 11896,
    bondnotes: 484712,
    insurance: 3181416,
    monetarypaper: 1541,
    monetarygold: 11100,
    assets: 200096,
    estates: 2287
  },
  "1984-09-01": {
    deposits: 2724847,
    fundshares: 145397,
    self: 3439,
    equities: 652921,
    funds: 25764,
    foreign: 23706,
    shares: 200848,
    domestic: 19007,
    equipment: 112594,
    structures: 182237,
    intellectual: 11985,
    otherpayables: 360928,
    bonds: 162113,
    treasury: 207826,
    equityloan: 204175,
    homeloan: 6940,
    pensions: 1215239,
    moneyfunds: 22347,
    paper: 213566,
    otherreceivables: 366870,
    repurchaseshares: 523491,
    repurchases: 1809891,
    interbank: 237544,
    mortgages: 1723059,
    notes: 12055,
    bondnotes: 509362,
    insurance: 3270705,
    monetarypaper: 1597,
    monetarygold: 11097,
    assets: 205269,
    estates: 2417
  },
  "1984-12-01": {
    deposits: 2836377,
    fundshares: 153337,
    self: 3569,
    equities: 674344,
    funds: 27126,
    foreign: 23713,
    shares: 209891,
    domestic: 19186,
    equipment: 116153,
    structures: 187397,
    intellectual: 12479,
    otherpayables: 365377,
    bonds: 184044,
    treasury: 221763,
    equityloan: 217630,
    homeloan: 7200,
    pensions: 1216822,
    moneyfunds: 23174,
    paper: 223490,
    otherreceivables: 374375,
    repurchaseshares: 571187,
    repurchases: 1920885,
    interbank: 250970,
    mortgages: 1782363,
    notes: 12340,
    bondnotes: 526197,
    insurance: 3357053,
    monetarypaper: 1626,
    monetarygold: 11096,
    assets: 233554,
    estates: 2479
  },
  "1985-03-01": {
    deposits: 2865127,
    fundshares: 174257,
    self: 3856,
    equities: 753452,
    funds: 29766,
    foreign: 23661,
    shares: 234682,
    domestic: 19071,
    equipment: 119554,
    structures: 193763,
    intellectual: 12972,
    otherpayables: 398226,
    bonds: 193221,
    treasury: 229166,
    equityloan: 224292,
    homeloan: 7544,
    pensions: 1216990,
    moneyfunds: 23591,
    paper: 230243,
    otherreceivables: 391851,
    repurchaseshares: 534498,
    repurchases: 1917500,
    interbank: 250748,
    mortgages: 1832575,
    notes: 12704,
    bondnotes: 545165,
    insurance: 3462569,
    monetarypaper: 1687,
    monetarygold: 11093,
    assets: 241000,
    estates: 2489
  },
  "1985-06-01": {
    deposits: 2939299,
    fundshares: 205015,
    self: 4097,
    equities: 792642,
    funds: 32137,
    foreign: 24304,
    shares: 268531,
    domestic: 19551,
    equipment: 123217,
    structures: 199117,
    intellectual: 13381,
    otherpayables: 410691,
    bonds: 209467,
    treasury: 234984,
    equityloan: 232812,
    homeloan: 7884,
    pensions: 1218121,
    moneyfunds: 25296,
    paper: 232196,
    otherreceivables: 412819,
    repurchaseshares: 558708,
    repurchases: 1978908,
    interbank: 261445,
    mortgages: 1891681,
    notes: 12916,
    bondnotes: 571026,
    insurance: 3544920,
    monetarypaper: 1721,
    monetarygold: 11091,
    assets: 247095,
    estates: 2501
  },
  "1985-09-01": {
    deposits: 2995018,
    fundshares: 221441,
    self: 4252,
    equities: 757727,
    funds: 31887,
    foreign: 24484,
    shares: 244169,
    domestic: 20629,
    equipment: 126825,
    structures: 204916,
    intellectual: 13777,
    otherpayables: 445561,
    bonds: 221089,
    treasury: 249225,
    equityloan: 249622,
    homeloan: 8097,
    pensions: 1273636,
    moneyfunds: 25461,
    paper: 247014,
    otherreceivables: 410377,
    repurchaseshares: 596131,
    repurchases: 2040815,
    interbank: 263485,
    mortgages: 1957936,
    notes: 13016,
    bondnotes: 596864,
    insurance: 3613892,
    monetarypaper: 1753,
    monetarygold: 11090,
    assets: 243416,
    estates: 2516
  },
  "1985-12-01": {
    deposits: 3110638,
    fundshares: 272965,
    self: 4246,
    equities: 906736,
    funds: 42724,
    foreign: 31764,
    shares: 309114,
    domestic: 25270,
    equipment: 130766,
    structures: 210920,
    intellectual: 14113,
    otherpayables: 482637,
    bonds: 230941,
    treasury: 261949,
    equityloan: 262044,
    homeloan: 8313,
    pensions: 1261673,
    moneyfunds: 23224,
    paper: 267071,
    otherreceivables: 449860,
    repurchaseshares: 685826,
    repurchases: 2173902,
    interbank: 283139,
    mortgages: 2023861,
    notes: 13141,
    bondnotes: 625659,
    insurance: 3828393,
    monetarypaper: 1781,
    monetarygold: 11090,
    assets: 243803,
    estates: 2534
  },
  "1986-03-01": {
    deposits: 3117116,
    fundshares: 340693,
    self: 4756,
    equities: 1019718,
    funds: 52847,
    foreign: 33761,
    shares: 377221,
    domestic: 17145,
    equipment: 134944,
    structures: 217663,
    intellectual: 14492,
    otherpayables: 484566,
    bonds: 261596,
    treasury: 270931,
    equityloan: 274504,
    homeloan: 8687,
    pensions: 1243034,
    moneyfunds: 24085,
    paper: 265389,
    otherreceivables: 454354,
    repurchaseshares: 702459,
    repurchases: 2178269,
    interbank: 283231,
    mortgages: 2070117,
    notes: 13299,
    bondnotes: 651140,
    insurance: 3942363,
    monetarypaper: 1821,
    monetarygold: 11090,
    assets: 266529,
    estates: 2668
  },
  "1986-06-01": {
    deposits: 3183227,
    fundshares: 382490,
    self: 5541,
    equities: 1055134,
    funds: 56050,
    foreign: 34909,
    shares: 377009,
    domestic: 16097,
    equipment: 140169,
    structures: 224053,
    intellectual: 14942,
    otherpayables: 480311,
    bonds: 287797,
    treasury: 295169,
    equityloan: 301912,
    homeloan: 8908,
    pensions: 1263192,
    moneyfunds: 23718,
    paper: 276457,
    otherreceivables: 460132,
    repurchaseshares: 745073,
    repurchases: 2248909,
    interbank: 288843,
    mortgages: 2143328,
    notes: 13544,
    bondnotes: 690373,
    insurance: 4035280,
    monetarypaper: 1807,
    monetarygold: 11084,
    assets: 278099,
    estates: 2895
  },
  "1986-09-01": {
    deposits: 3244433,
    fundshares: 407139,
    self: 5522,
    equities: 964270,
    funds: 54291,
    foreign: 34378,
    shares: 350369,
    domestic: 17216,
    equipment: 145302,
    structures: 230091,
    intellectual: 15412,
    otherpayables: 506003,
    bonds: 308104,
    treasury: 303544,
    equityloan: 307961,
    homeloan: 9177,
    pensions: 1343241,
    moneyfunds: 24370,
    paper: 283177,
    otherreceivables: 467733,
    repurchaseshares: 781041,
    repurchases: 2298973,
    interbank: 304320,
    mortgages: 2230146,
    notes: 13604,
    bondnotes: 743119,
    insurance: 4102196,
    monetarypaper: 1849,
    monetarygold: 11084,
    assets: 296048,
    estates: 2881
  },
  "1986-12-01": {
    deposits: 3390646,
    fundshares: 450367,
    self: 5758,
    equities: 1003631,
    funds: 58948,
    foreign: 45133,
    shares: 368965,
    domestic: 21087,
    equipment: 149822,
    structures: 236333,
    intellectual: 15930,
    otherpayables: 504974,
    bonds: 328188,
    treasury: 312312,
    equityloan: 315754,
    homeloan: 9485,
    pensions: 1349317,
    moneyfunds: 24156,
    paper: 298632,
    otherreceivables: 498877,
    repurchaseshares: 825332,
    repurchases: 2415485,
    interbank: 331624,
    mortgages: 2307791,
    notes: 13672,
    bondnotes: 804601,
    insurance: 4218671,
    monetarypaper: 1874,
    monetarygold: 11064,
    assets: 292152,
    estates: 2790
  },
  "1987-03-01": {
    deposits: 3333540,
    fundshares: 539434,
    self: 6473,
    equities: 1230213,
    funds: 70368,
    foreign: 46145,
    shares: 428124,
    domestic: 20005,
    equipment: 154190,
    structures: 241049,
    intellectual: 16553,
    otherpayables: 549783,
    bonds: 360692,
    treasury: 321165,
    equityloan: 321737,
    homeloan: 10120,
    pensions: 1253697,
    moneyfunds: 23583,
    paper: 309351,
    otherreceivables: 557770,
    repurchaseshares: 814886,
    repurchases: 2375585,
    interbank: 323000,
    mortgages: 2382408,
    notes: 14085,
    bondnotes: 843811,
    insurance: 4358413,
    monetarypaper: 1916,
    monetarygold: 11081,
    assets: 304475,
    estates: 2881
  },
  "1987-06-01": {
    deposits: 3402147,
    fundshares: 550400,
    self: 6350,
    equities: 1298639,
    funds: 76868,
    foreign: 46048,
    shares: 415313,
    domestic: 18863,
    equipment: 157885,
    structures: 246761,
    intellectual: 17162,
    otherpayables: 563328,
    bonds: 382573,
    treasury: 315790,
    equityloan: 314800,
    homeloan: 10517,
    pensions: 1258710,
    moneyfunds: 23010,
    paper: 325098,
    otherreceivables: 549617,
    repurchaseshares: 829430,
    repurchases: 2437529,
    interbank: 309589,
    mortgages: 2473885,
    notes: 14383,
    bondnotes: 890913,
    insurance: 4467233,
    monetarypaper: 1961,
    monetarygold: 11069,
    assets: 301489,
    estates: 2885
  },
  "1987-09-01": {
    deposits: 3459661,
    fundshares: 556224,
    self: 6550,
    equities: 1373162,
    funds: 82672,
    foreign: 46768,
    shares: 418931,
    domestic: 19495,
    equipment: 161597,
    structures: 252960,
    intellectual: 17820,
    otherpayables: 577940,
    bonds: 410447,
    treasury: 324031,
    equityloan: 319045,
    homeloan: 10798,
    pensions: 1255308,
    moneyfunds: 22279,
    paper: 326477,
    otherreceivables: 598997,
    repurchaseshares: 865214,
    repurchases: 2492277,
    interbank: 308927,
    mortgages: 2549648,
    notes: 14508,
    bondnotes: 930809,
    insurance: 4605966,
    monetarypaper: 2009,
    monetarygold: 11075,
    assets: 306182,
    estates: 2922
  },
  "1987-12-01": {
    deposits: 3559333,
    fundshares: 481466,
    self: 5289,
    equities: 1054427,
    funds: 64536,
    foreign: 53641,
    shares: 328947,
    domestic: 12604,
    equipment: 167135,
    structures: 260376,
    intellectual: 18482,
    otherpayables: 616767,
    bonds: 406210,
    treasury: 332158,
    equityloan: 332839,
    homeloan: 11281,
    pensions: 1508261,
    moneyfunds: 21897,
    paper: 337909,
    otherreceivables: 570080,
    repurchaseshares: 877809,
    repurchases: 2556663,
    interbank: 313884,
    mortgages: 2603239,
    notes: 14966,
    bondnotes: 972608,
    insurance: 4624979,
    monetarypaper: 2047,
    monetarygold: 11078,
    assets: 316096,
    estates: 3110
  },
  "1988-03-01": {
    deposits: 3595944,
    fundshares: 502572,
    self: 5710,
    equities: 1115103,
    funds: 68365,
    foreign: 55807,
    shares: 353134,
    domestic: 7751,
    equipment: 172519,
    structures: 267373,
    intellectual: 19345,
    otherpayables: 636470,
    bonds: 417737,
    treasury: 340430,
    equityloan: 350059,
    homeloan: 11879,
    pensions: 1536873,
    moneyfunds: 25098,
    paper: 346452,
    otherreceivables: 599643,
    repurchaseshares: 919578,
    repurchases: 2601462,
    interbank: 317742,
    mortgages: 2664415,
    notes: 15481,
    bondnotes: 991360,
    insurance: 4736371,
    monetarypaper: 2095,
    monetarygold: 11063,
    assets: 344684,
    estates: 3097
  },
  "1988-06-01": {
    deposits: 3676332,
    fundshares: 511551,
    self: 5850,
    equities: 1149621,
    funds: 71230,
    foreign: 56011,
    shares: 384723,
    domestic: 1152,
    equipment: 177571,
    structures: 273069,
    intellectual: 20173,
    otherpayables: 684207,
    bonds: 438831,
    treasury: 355523,
    equityloan: 370026,
    homeloan: 12205,
    pensions: 1546382,
    moneyfunds: 27641,
    paper: 367867,
    otherreceivables: 646648,
    repurchaseshares: 937851,
    repurchases: 2655353,
    interbank: 320394,
    mortgages: 2744752,
    notes: 15819,
    bondnotes: 1013197,
    insurance: 4840507,
    monetarypaper: 2110,
    monetarygold: 11063,
    assets: 327808,
    estates: 3115
  },
  "1988-09-01": {
    deposits: 3715530,
    fundshares: 504894,
    self: 5998,
    equities: 1107320,
    funds: 69672,
    foreign: 57052,
    shares: 404318,
    domestic: -1187,
    equipment: 182399,
    structures: 278712,
    intellectual: 20944,
    otherpayables: 702349,
    bonds: 448162,
    treasury: 367284,
    equityloan: 378256,
    homeloan: 12636,
    pensions: 1584515,
    moneyfunds: 30066,
    paper: 372934,
    otherreceivables: 663147,
    repurchaseshares: 980316,
    repurchases: 2719410,
    interbank: 327712,
    mortgages: 2810800,
    notes: 16360,
    bondnotes: 1049624,
    insurance: 4916820,
    monetarypaper: 2097,
    monetarygold: 11062,
    assets: 327157,
    estates: 3091
  },
  "1988-12-01": {
    deposits: 3798165,
    fundshares: 503097,
    self: 5920,
    equities: 1102840,
    funds: 71987,
    foreign: 68182,
    shares: 388881,
    domestic: -151,
    equipment: 189084,
    structures: 284665,
    intellectual: 21725,
    otherpayables: 741041,
    bonds: 472901,
    treasury: 373455,
    equityloan: 387393,
    homeloan: 13177,
    pensions: 1605766,
    moneyfunds: 32839,
    paper: 377740,
    otherreceivables: 694577,
    repurchaseshares: 958712,
    repurchases: 2750363,
    interbank: 337088,
    mortgages: 2888932,
    notes: 16930,
    bondnotes: 1093384,
    insurance: 5017591,
    monetarypaper: 2113,
    monetarygold: 11060,
    assets: 338862,
    estates: 3383
  },
  "1989-03-01": {
    deposits: 3754296,
    fundshares: 515301,
    self: 5984,
    equities: 1161989,
    funds: 77114,
    foreign: 70471,
    shares: 423037,
    domestic: 1047,
    equipment: 194327,
    structures: 291227,
    intellectual: 22493,
    otherpayables: 706957,
    bonds: 511716,
    treasury: 383534,
    equityloan: 391261,
    homeloan: 13735,
    pensions: 1640269,
    moneyfunds: 32255,
    paper: 392484,
    otherreceivables: 660460,
    repurchaseshares: 1033092,
    repurchases: 2831764,
    interbank: 345345,
    mortgages: 2946647,
    notes: 17118,
    bondnotes: 1134775,
    insurance: 5150212,
    monetarypaper: 2131,
    monetarygold: 11061,
    assets: 365061,
    estates: 3431
  },
  "1989-06-01": {
    deposits: 3811928,
    fundshares: 549881,
    self: 5569,
    equities: 1256630,
    funds: 81462,
    foreign: 75261,
    shares: 468111,
    domestic: 3412,
    equipment: 199120,
    structures: 298746,
    intellectual: 23458,
    otherpayables: 726217,
    bonds: 521341,
    treasury: 391218,
    equityloan: 404626,
    homeloan: 13801,
    pensions: 1644059,
    moneyfunds: 31591,
    paper: 399406,
    otherreceivables: 688407,
    repurchaseshares: 1118800,
    repurchases: 2944502,
    interbank: 366254,
    mortgages: 3020067,
    notes: 16755,
    bondnotes: 1163468,
    insurance: 5297849,
    monetarypaper: 2145,
    monetarygold: 11063,
    assets: 385856,
    estates: 3451
  },
  "1989-09-01": {
    deposits: 3823652,
    fundshares: 576895,
    self: 5827,
    equities: 1367212,
    funds: 87516,
    foreign: 79202,
    shares: 508653,
    domestic: 693,
    equipment: 204886,
    structures: 305372,
    intellectual: 24532,
    otherpayables: 750775,
    bonds: 528228,
    treasury: 390594,
    equityloan: 413461,
    homeloan: 13828,
    pensions: 1637717,
    moneyfunds: 30046,
    paper: 398820,
    otherreceivables: 714565,
    repurchaseshares: 1062828,
    repurchases: 2913387,
    interbank: 386803,
    mortgages: 3099913,
    notes: 16709,
    bondnotes: 1197441,
    insurance: 5440570,
    monetarypaper: 2198,
    monetarygold: 11065,
    assets: 416782,
    estates: 3580
  },
  "1989-12-01": {
    deposits: 3904603,
    fundshares: 591345,
    self: 5209,
    equities: 1363457,
    funds: 109384,
    foreign: 108233,
    shares: 477010,
    domestic: 12679,
    equipment: 210110,
    structures: 311980,
    intellectual: 25676,
    otherpayables: 722641,
    bonds: 540872,
    treasury: 391280,
    equityloan: 417498,
    homeloan: 13385,
    pensions: 1692551,
    moneyfunds: 29018,
    paper: 409088,
    otherreceivables: 693157,
    repurchaseshares: 1056603,
    repurchases: 2946770,
    interbank: 414815,
    mortgages: 3153448,
    notes: 16335,
    bondnotes: 1242876,
    insurance: 5591736,
    monetarypaper: 2243,
    monetarygold: 11059,
    assets: 430007,
    estates: 3710
  },
  "1990-03-01": {
    deposits: 3868817,
    fundshares: 585636,
    self: 5152,
    equities: 1300768,
    funds: 110408,
    foreign: 106443,
    shares: 444898,
    domestic: 13037,
    equipment: 214566,
    structures: 318503,
    intellectual: 26791,
    otherpayables: 762445,
    bonds: 548012,
    treasury: 390960,
    equityloan: 423863,
    homeloan: 12213,
    pensions: 1780151,
    moneyfunds: 33735,
    paper: 409060,
    otherreceivables: 653843,
    repurchaseshares: 1033531,
    repurchases: 2923131,
    interbank: 400676,
    mortgages: 3237698,
    notes: 14963,
    bondnotes: 1283235,
    insurance: 5687068,
    monetarypaper: 2313,
    monetarygold: 11060,
    assets: 465696,
    estates: 3840
  },
  "1990-06-01": {
    deposits: 3889825,
    fundshares: 620236,
    self: 4947,
    equities: 1383925,
    funds: 121827,
    foreign: 105609,
    shares: 453758,
    domestic: 14343,
    equipment: 217770,
    structures: 324600,
    intellectual: 27975,
    otherpayables: 769963,
    bonds: 564343,
    treasury: 388687,
    equityloan: 423124,
    homeloan: 11772,
    pensions: 1771314,
    moneyfunds: 36005,
    paper: 400256,
    otherreceivables: 653529,
    repurchaseshares: 1028004,
    repurchases: 2929724,
    interbank: 392056,
    mortgages: 3288941,
    notes: 14447,
    bondnotes: 1325172,
    insurance: 5810310,
    monetarypaper: 2344,
    monetarygold: 11065,
    assets: 455295,
    estates: 3970
  },
  "1990-09-01": {
    deposits: 3907345,
    fundshares: 568041,
    self: 4702,
    equities: 1186379,
    funds: 110446,
    foreign: 109033,
    shares: 357095,
    domestic: 3838,
    equipment: 222132,
    structures: 331147,
    intellectual: 29109,
    otherpayables: 767016,
    bonds: 567552,
    treasury: 397129,
    equityloan: 436356,
    homeloan: 11366,
    pensions: 1926737,
    moneyfunds: 40816,
    paper: 409590,
    otherreceivables: 668945,
    repurchaseshares: 1014172,
    repurchases: 2927439,
    interbank: 377973,
    mortgages: 3328242,
    notes: 13966,
    bondnotes: 1362943,
    insurance: 5875489,
    monetarypaper: 2399,
    monetarygold: 11063,
    assets: 486898,
    estates: 4200
  },
  "1990-12-01": {
    deposits: 3971391,
    fundshares: 603053,
    self: 4072,
    equities: 1307564,
    funds: 117546,
    foreign: 86976,
    shares: 377896,
    domestic: -8109,
    equipment: 226922,
    structures: 336719,
    intellectual: 30413,
    otherpayables: 734262,
    bonds: 593161,
    treasury: 388925,
    equityloan: 421105,
    homeloan: 11104,
    pensions: 1923180,
    moneyfunds: 46307,
    paper: 417681,
    otherreceivables: 680002,
    repurchaseshares: 1009568,
    repurchases: 2934241,
    interbank: 378350,
    mortgages: 3363320,
    notes: 13679,
    bondnotes: 1413555,
    insurance: 6011855,
    monetarypaper: 2423,
    monetarygold: 11058,
    assets: 501268,
    estates: 4330
  },
  "1991-03-01": {
    deposits: 3949150,
    fundshares: 669138,
    self: 4504,
    equities: 1485853,
    funds: 135313,
    foreign: 93557,
    shares: 456032,
    domestic: -9461,
    equipment: 230212,
    structures: 343143,
    intellectual: 32052,
    otherpayables: 746894,
    bonds: 609246,
    treasury: 390728,
    equityloan: 433039,
    homeloan: 10855,
    pensions: 1878083,
    moneyfunds: 49393,
    paper: 400578,
    otherreceivables: 692552,
    repurchaseshares: 993003,
    repurchases: 2913672,
    interbank: 368369,
    mortgages: 3394868,
    notes: 13355,
    bondnotes: 1447279,
    insurance: 6155940,
    monetarypaper: 2501,
    monetarygold: 11058,
    assets: 555411,
    estates: 4400
  },
  "1991-06-01": {
    deposits: 3927714,
    fundshares: 700414,
    self: 4667,
    equities: 1492260,
    funds: 143421,
    foreign: 88944,
    shares: 469921,
    domestic: 3938,
    equipment: 230909,
    structures: 346980,
    intellectual: 33124,
    otherpayables: 731275,
    bonds: 628215,
    treasury: 389872,
    equityloan: 435340,
    homeloan: 10700,
    pensions: 1934005,
    moneyfunds: 50349,
    paper: 389898,
    otherreceivables: 707918,
    repurchaseshares: 1027204,
    repurchases: 2922967,
    interbank: 362456,
    mortgages: 3448036,
    notes: 13263,
    bondnotes: 1475585,
    insurance: 6271659,
    monetarypaper: 2545,
    monetarygold: 11062,
    assets: 535657,
    estates: 4482
  },
  "1991-09-01": {
    deposits: 3951568,
    fundshares: 769566,
    self: 5080,
    equities: 1602763,
    funds: 159768,
    foreign: 89845,
    shares: 511591,
    domestic: 6810,
    equipment: 231488,
    structures: 349773,
    intellectual: 34028,
    otherpayables: 725347,
    bonds: 640732,
    treasury: 395561,
    equityloan: 436203,
    homeloan: 10634,
    pensions: 1931579,
    moneyfunds: 53388,
    paper: 386663,
    otherreceivables: 727475,
    repurchaseshares: 1068181,
    repurchases: 2948914,
    interbank: 369834,
    mortgages: 3446537,
    notes: 13260,
    bondnotes: 1518054,
    insurance: 6408382,
    monetarypaper: 2565,
    monetarygold: 11062,
    assets: 536008,
    estates: 4578
  },
  "1991-12-01": {
    deposits: 3995875,
    fundshares: 850744,
    self: 5112,
    equities: 1741647,
    funds: 173939,
    foreign: 98988,
    shares: 570316,
    domestic: 35923,
    equipment: 232918,
    structures: 349108,
    intellectual: 34722,
    otherpayables: 752814,
    bonds: 648406,
    treasury: 406047,
    equityloan: 451520,
    homeloan: 10200,
    pensions: 1922232,
    moneyfunds: 55258,
    paper: 397840,
    otherreceivables: 750435,
    repurchaseshares: 1030619,
    repurchases: 2895590,
    interbank: 377981,
    mortgages: 3492546,
    notes: 12974,
    bondnotes: 1559392,
    insurance: 6561937,
    monetarypaper: 2652,
    monetarygold: 11059,
    assets: 542441,
    estates: 4793
  },
  "1992-03-01": {
    deposits: 3956480,
    fundshares: 889980,
    self: 5116,
    equities: 1759264,
    funds: 184054,
    foreign: 105445,
    shares: 593016,
    domestic: 26797,
    equipment: 234853,
    structures: 352227,
    intellectual: 35015,
    otherpayables: 791887,
    bonds: 679598,
    treasury: 397479,
    equityloan: 438454,
    homeloan: 10123,
    pensions: 2057523,
    moneyfunds: 58987,
    paper: 396338,
    otherreceivables: 756731,
    repurchaseshares: 1040674,
    repurchases: 2881131,
    interbank: 392182,
    mortgages: 3520587,
    notes: 12823,
    bondnotes: 1585464,
    insurance: 6684102,
    monetarypaper: 2745,
    monetarygold: 11057,
    assets: 574836,
    estates: 5008
  },
  "1992-06-01": {
    deposits: 3935294,
    fundshares: 952243,
    self: 4969,
    equities: 1795694,
    funds: 196813,
    foreign: 105152,
    shares: 613979,
    domestic: 26457,
    equipment: 235447,
    structures: 356741,
    intellectual: 35969,
    otherpayables: 808720,
    bonds: 698098,
    treasury: 412702,
    equityloan: 448721,
    homeloan: 9897,
    pensions: 2125763,
    moneyfunds: 59311,
    paper: 393647,
    otherreceivables: 763900,
    repurchaseshares: 1099367,
    repurchases: 2918589,
    interbank: 369203,
    mortgages: 3549035,
    notes: 12497,
    bondnotes: 1637277,
    insurance: 6830887,
    monetarypaper: 2832,
    monetarygold: 11060,
    assets: 559738,
    estates: 5029
  },
  "1992-09-01": {
    deposits: 3973856,
    fundshares: 1019928,
    self: 5443,
    equities: 1849157,
    funds: 206100,
    foreign: 101691,
    shares: 637772,
    domestic: 30625,
    equipment: 236655,
    structures: 361617,
    intellectual: 37222,
    otherpayables: 839135,
    bonds: 719898,
    treasury: 428699,
    equityloan: 468737,
    homeloan: 10118,
    pensions: 2181662,
    moneyfunds: 61017,
    paper: 397840,
    otherreceivables: 838731,
    repurchaseshares: 1178505,
    repurchases: 2973958,
    interbank: 381717,
    mortgages: 3595792,
    notes: 12818,
    bondnotes: 1678861,
    insurance: 7001567,
    monetarypaper: 2977,
    monetarygold: 11058,
    assets: 556864,
    estates: 5139
  },
  "1992-12-01": {
    deposits: 3990959,
    fundshares: 1096342,
    self: 5300,
    equities: 2006505,
    funds: 227878,
    foreign: 105462,
    shares: 746524,
    domestic: 31331,
    equipment: 237029,
    structures: 367552,
    intellectual: 38816,
    otherpayables: 820766,
    bonds: 739496,
    treasury: 442594,
    equityloan: 487964,
    homeloan: 10102,
    pensions: 2183477,
    moneyfunds: 61430,
    paper: 401535,
    otherreceivables: 792697,
    repurchaseshares: 1168123,
    repurchases: 2989244,
    interbank: 371883,
    mortgages: 3621244,
    notes: 12802,
    bondnotes: 1715656,
    insurance: 7180909,
    monetarypaper: 3054,
    monetarygold: 11056,
    assets: 546194,
    estates: 5355
  },
  "1993-03-01": {
    deposits: 3939504,
    fundshares: 1201180,
    self: 6187,
    equities: 2155556,
    funds: 249384,
    foreign: 104423,
    shares: 823185,
    domestic: 28256,
    equipment: 239953,
    structures: 372451,
    intellectual: 40002,
    otherpayables: 818835,
    bonds: 766097,
    treasury: 448444,
    equityloan: 477179,
    homeloan: 10302,
    pensions: 2176215,
    moneyfunds: 66197,
    paper: 387031,
    otherreceivables: 843591,
    repurchaseshares: 1257948,
    repurchases: 2975148,
    interbank: 379497,
    mortgages: 3632225,
    notes: 13110,
    bondnotes: 1751473,
    insurance: 7341251,
    monetarypaper: 3187,
    monetarygold: 11054,
    assets: 559524,
    estates: 5700
  },
  "1993-06-01": {
    deposits: 3984280,
    fundshares: 1302226,
    self: 5864,
    equities: 2236268,
    funds: 270783,
    foreign: 114205,
    shares: 829477,
    domestic: 36442,
    equipment: 242717,
    structures: 377810,
    intellectual: 40893,
    otherpayables: 845288,
    bonds: 787332,
    treasury: 462992,
    equityloan: 504763,
    homeloan: 10639,
    pensions: 2213460,
    moneyfunds: 69195,
    paper: 383566,
    otherreceivables: 853727,
    repurchaseshares: 1282183,
    repurchases: 3007520,
    interbank: 362735,
    mortgages: 3687766,
    notes: 13509,
    bondnotes: 1770141,
    insurance: 7467391,
    monetarypaper: 3288,
    monetarygold: 11057,
    assets: 552412,
    estates: 6038
  },
  "1993-09-01": {
    deposits: 3979193,
    fundshares: 1419318,
    self: 6529,
    equities: 2380015,
    funds: 297132,
    foreign: 113465,
    shares: 904893,
    domestic: 51949,
    equipment: 245417,
    structures: 383043,
    intellectual: 41642,
    otherpayables: 881444,
    bonds: 824881,
    treasury: 480353,
    equityloan: 519775,
    homeloan: 11150,
    pensions: 2205298,
    moneyfunds: 70766,
    paper: 380939,
    otherreceivables: 879935,
    repurchaseshares: 1374554,
    repurchases: 3086291,
    interbank: 350949,
    mortgages: 3742018,
    notes: 14088,
    bondnotes: 1841319,
    insurance: 7609913,
    monetarypaper: 3331,
    monetarygold: 11057,
    assets: 550522,
    estates: 7580
  },
  "1993-12-01": {
    deposits: 4056715,
    fundshares: 1504644,
    self: 6269,
    equities: 2485423,
    funds: 324531,
    foreign: 170601,
    shares: 910666,
    domestic: 71642,
    equipment: 248913,
    structures: 388719,
    intellectual: 42471,
    otherpayables: 889698,
    bonds: 862345,
    treasury: 499846,
    equityloan: 537700,
    homeloan: 11450,
    pensions: 2210457,
    moneyfunds: 75677,
    paper: 399881,
    otherreceivables: 878998,
    repurchaseshares: 1359799,
    repurchases: 3140175,
    interbank: 358947,
    mortgages: 3777754,
    notes: 14447,
    bondnotes: 1880937,
    insurance: 7727852,
    monetarypaper: 3401,
    monetarygold: 11053,
    assets: 565319,
    estates: 8944
  },
  "1994-03-01": {
    deposits: 4053713,
    fundshares: 1495186,
    self: 6284,
    equities: 2448694,
    funds: 337269,
    foreign: 173334,
    shares: 878751,
    domestic: 61808,
    equipment: 254097,
    structures: 394278,
    intellectual: 42770,
    otherpayables: 1101858,
    bonds: 901184,
    treasury: 510151,
    equityloan: 540462,
    homeloan: 11641,
    pensions: 2332758,
    moneyfunds: 76193,
    paper: 414447,
    otherreceivables: 1040443,
    repurchaseshares: 1414910,
    repurchases: 3213968,
    interbank: 359639,
    mortgages: 3808987,
    notes: 14718,
    bondnotes: 1963461,
    insurance: 7825892,
    monetarypaper: 3445,
    monetarygold: 11052,
    assets: 582288,
    estates: 11400
  },
  "1994-06-01": {
    deposits: 4061151,
    fundshares: 1504264,
    self: 5717,
    equities: 2480388,
    funds: 353628,
    foreign: 173882,
    shares: 892626,
    domestic: 64917,
    equipment: 258803,
    structures: 399444,
    intellectual: 43311,
    otherpayables: 1130920,
    bonds: 928908,
    treasury: 516430,
    equityloan: 537142,
    homeloan: 12279,
    pensions: 2368465,
    moneyfunds: 73904,
    paper: 415693,
    otherreceivables: 1098551,
    repurchaseshares: 1446788,
    repurchases: 3291355,
    interbank: 361329,
    mortgages: 3858409,
    notes: 15420,
    bondnotes: 2033210,
    insurance: 7942398,
    monetarypaper: 3523,
    monetarygold: 11052,
    assets: 576632,
    estates: 14500
  },
  "1994-09-01": {
    deposits: 4039897,
    fundshares: 1580774,
    self: 5805,
    equities: 2658124,
    funds: 384181,
    foreign: 174909,
    shares: 882993,
    domestic: 58792,
    equipment: 262637,
    structures: 407055,
    intellectual: 44003,
    otherpayables: 1124565,
    bonds: 955216,
    treasury: 527103,
    equityloan: 547104,
    homeloan: 12650,
    pensions: 2334506,
    moneyfunds: 71478,
    paper: 425696,
    otherreceivables: 1089221,
    repurchaseshares: 1496377,
    repurchases: 3374741,
    interbank: 352188,
    mortgages: 3910069,
    notes: 15858,
    bondnotes: 2093724,
    insurance: 8098051,
    monetarypaper: 3608,
    monetarygold: 11054,
    assets: 586195,
    estates: 17500
  },
  "1994-12-01": {
    deposits: 4120141,
    fundshares: 1544320,
    self: 5281,
    equities: 2624576,
    funds: 424030,
    foreign: 142971,
    shares: 893902,
    domestic: 34345,
    equipment: 265912,
    structures: 415585,
    intellectual: 44816,
    otherpayables: 1128418,
    bonds: 987587,
    treasury: 538269,
    equityloan: 552596,
    homeloan: 13095,
    pensions: 2337154,
    moneyfunds: 70806,
    paper: 441556,
    otherreceivables: 1082291,
    repurchaseshares: 1410382,
    repurchases: 3411701,
    interbank: 369409,
    mortgages: 3956595,
    notes: 16273,
    bondnotes: 2173449,
    insurance: 8177338,
    monetarypaper: 3683,
    monetarygold: 11051,
    assets: 611005,
    estates: 18726
  },
  "1995-03-01": {
    deposits: 4106696,
    fundshares: 1649397,
    self: 5557,
    equities: 2833107,
    funds: 479625,
    foreign: 144930,
    shares: 987881,
    domestic: 27518,
    equipment: 269627,
    structures: 421173,
    intellectual: 45303,
    otherpayables: 1218551,
    bonds: 1026503,
    treasury: 569068,
    equityloan: 581870,
    homeloan: 13493,
    pensions: 2302227,
    moneyfunds: 75738,
    paper: 453054,
    otherreceivables: 1116943,
    repurchaseshares: 1485589,
    repurchases: 3504978,
    interbank: 380866,
    mortgages: 3992689,
    notes: 16643,
    bondnotes: 2197029,
    insurance: 8363772,
    monetarypaper: 3786,
    monetarygold: 11053,
    assets: 641874,
    estates: 19567
  },
  "1995-06-01": {
    deposits: 4197814,
    fundshares: 1800550,
    self: 5396,
    equities: 3098103,
    funds: 551107,
    foreign: 146156,
    shares: 1083764,
    domestic: 24602,
    equipment: 274746,
    structures: 424657,
    intellectual: 45453,
    otherpayables: 1160147,
    bonds: 1068149,
    treasury: 589261,
    equityloan: 605668,
    homeloan: 13976,
    pensions: 2249271,
    moneyfunds: 83439,
    paper: 461821,
    otherreceivables: 1065354,
    repurchaseshares: 1556266,
    repurchases: 3674635,
    interbank: 381446,
    mortgages: 4052439,
    notes: 17097,
    bondnotes: 2247962,
    insurance: 8570394,
    monetarypaper: 3815,
    monetarygold: 11054,
    assets: 686673,
    estates: 21529
  },
  "1995-09-01": {
    deposits: 4200165,
    fundshares: 1954042,
    self: 6210,
    equities: 3367582,
    funds: 620267,
    foreign: 147972,
    shares: 1204125,
    domestic: 25972,
    equipment: 278446,
    structures: 430063,
    intellectual: 45668,
    otherpayables: 1205281,
    bonds: 1122317,
    treasury: 608627,
    equityloan: 623972,
    homeloan: 14659,
    pensions: 2209337,
    moneyfunds: 88620,
    paper: 472623,
    otherreceivables: 1093050,
    repurchaseshares: 1590158,
    repurchases: 3756677,
    interbank: 407032,
    mortgages: 4118599,
    notes: 17756,
    bondnotes: 2301123,
    insurance: 8767197,
    monetarypaper: 3915,
    monetarygold: 11051,
    assets: 710873,
    estates: 22651
  },
  "1995-12-01": {
    deposits: 4276509,
    fundshares: 2058275,
    self: 6304,
    equities: 3502503,
    funds: 676952,
    foreign: 164389,
    shares: 1283957,
    domestic: 77740,
    equipment: 281776,
    structures: 434402,
    intellectual: 45579,
    otherpayables: 1246447,
    bonds: 1167673,
    treasury: 614265,
    equityloan: 624933,
    homeloan: 15133,
    pensions: 2214165,
    moneyfunds: 96016,
    paper: 486868,
    otherreceivables: 1094517,
    repurchaseshares: 1647768,
    repurchases: 3932031,
    interbank: 416904,
    mortgages: 4160501,
    notes: 18256,
    bondnotes: 2377738,
    insurance: 8946358,
    monetarypaper: 3966,
    monetarygold: 11050,
    assets: 753018,
    estates: 24051
  },
  "1996-03-01": {
    deposits: 4284134,
    fundshares: 2201626,
    self: 6929,
    equities: 3718762,
    funds: 732066,
    foreign: 166508,
    shares: 1376805,
    domestic: 81441,
    equipment: 284408,
    structures: 437795,
    intellectual: 45692,
    otherpayables: 1292987,
    bonds: 1208010,
    treasury: 636271,
    equityloan: 648930,
    homeloan: 15287,
    pensions: 2205414,
    moneyfunds: 106371,
    paper: 490922,
    otherreceivables: 1175518,
    repurchaseshares: 1709621,
    repurchases: 3976723,
    interbank: 412608,
    mortgages: 4236460,
    notes: 18382,
    bondnotes: 2415003,
    insurance: 9107552,
    monetarypaper: 4037,
    monetarygold: 11053,
    assets: 824202,
    estates: 25453
  },
  "1996-06-01": {
    deposits: 4312139,
    fundshares: 2351651,
    self: 6758,
    equities: 3911425,
    funds: 779085,
    foreign: 169051,
    shares: 1386951,
    domestic: 72419,
    equipment: 286947,
    structures: 442532,
    intellectual: 45755,
    otherpayables: 1284359,
    bonds: 1262174,
    treasury: 661821,
    equityloan: 676007,
    homeloan: 15819,
    pensions: 2193496,
    moneyfunds: 109309,
    paper: 517349,
    otherreceivables: 1235171,
    repurchaseshares: 1754615,
    repurchases: 4081537,
    interbank: 404730,
    mortgages: 4313222,
    notes: 18932,
    bondnotes: 2490273,
    insurance: 9244821,
    monetarypaper: 4138,
    monetarygold: 11050,
    assets: 817748,
    estates: 27415
  },
  "1996-09-01": {
    deposits: 4361866,
    fundshares: 2461780,
    self: 7175,
    equities: 4079383,
    funds: 829404,
    foreign: 172778,
    shares: 1487816,
    domestic: 77409,
    equipment: 291505,
    structures: 451406,
    intellectual: 45901,
    otherpayables: 1358127,
    bonds: 1282024,
    treasury: 676433,
    equityloan: 691780,
    homeloan: 16138,
    pensions: 2208388,
    moneyfunds: 116899,
    paper: 538567,
    otherreceivables: 1284986,
    repurchaseshares: 1759622,
    repurchases: 4144289,
    interbank: 429774,
    mortgages: 4386083,
    notes: 19242,
    bondnotes: 2545969,
    insurance: 9407694,
    monetarypaper: 4535,
    monetarygold: 11050,
    assets: 852063,
    estates: 29756
  },
  "1996-12-01": {
    deposits: 4460700,
    fundshares: 2623994,
    self: 7053,
    equities: 4357023,
    funds: 949380,
    foreign: 200233,
    shares: 1931867,
    domestic: 112065,
    equipment: 295837,
    structures: 458054,
    intellectual: 46184,
    otherpayables: 1361119,
    bonds: 1320106,
    treasury: 700419,
    equityloan: 707624,
    homeloan: 16540,
    pensions: 2234535,
    moneyfunds: 128481,
    paper: 579096,
    otherreceivables: 1304590,
    repurchaseshares: 1819746,
    repurchases: 4246439,
    interbank: 480114,
    mortgages: 4457489,
    notes: 19706,
    bondnotes: 2609151,
    insurance: 9737395,
    monetarypaper: 4602,
    monetarygold: 11048,
    assets: 901807,
    estates: 31908
  },
  "1997-03-01": {
    deposits: 4470778,
    fundshares: 2686387,
    self: 8008,
    equities: 4360986,
    funds: 988840,
    foreign: 204604,
    shares: 1973550,
    domestic: 112329,
    equipment: 297441,
    structures: 466555,
    intellectual: 46297,
    otherpayables: 1495025,
    bonds: 1320136,
    treasury: 737632,
    equityloan: 740830,
    homeloan: 17182,
    pensions: 2302302,
    moneyfunds: 140903,
    paper: 623019,
    otherreceivables: 1433121,
    repurchaseshares: 1904755,
    repurchases: 4365572,
    interbank: 469139,
    mortgages: 4509674,
    notes: 20352,
    bondnotes: 2636172,
    insurance: 9841047,
    monetarypaper: 4762,
    monetarygold: 11050,
    assets: 965433,
    estates: 36057
  },
  "1997-06-01": {
    deposits: 4553250,
    fundshares: 3051401,
    self: 9103,
    equities: 5015421,
    funds: 1153775,
    foreign: 209597,
    shares: 2288189,
    domestic: 119416,
    equipment: 299624,
    structures: 474832,
    intellectual: 46824,
    otherpayables: 1496528,
    bonds: 1373279,
    treasury: 748035,
    equityloan: 740141,
    homeloan: 17654,
    pensions: 2114689,
    moneyfunds: 145012,
    paper: 642458,
    otherreceivables: 1521468,
    repurchaseshares: 1979881,
    repurchases: 4466834,
    interbank: 491728,
    mortgages: 4581140,
    notes: 20827,
    bondnotes: 2708053,
    insurance: 10210912,
    monetarypaper: 5050,
    monetarygold: 11050,
    assets: 963700,
    estates: 40110
  },
  "1997-09-01": {
    deposits: 4572340,
    fundshares: 3368362,
    self: 10043,
    equities: 5514257,
    funds: 1286912,
    foreign: 211826,
    shares: 2570102,
    domestic: 135567,
    equipment: 302758,
    structures: 484496,
    intellectual: 47152,
    otherpayables: 1582132,
    bonds: 1371314,
    treasury: 784986,
    equityloan: 787920,
    homeloan: 18050,
    pensions: 2007174,
    moneyfunds: 159124,
    paper: 684716,
    otherreceivables: 1583068,
    repurchaseshares: 2108376,
    repurchases: 4625672,
    interbank: 500975,
    mortgages: 4689319,
    notes: 21244,
    bondnotes: 2748187,
    insurance: 10527277,
    monetarypaper: 5227,
    monetarygold: 11050,
    assets: 1015127,
    estates: 41781
  },
  "1997-12-01": {
    deposits: 4709071,
    fundshares: 3409315,
    self: 9820,
    equities: 5497919,
    funds: 1307763,
    foreign: 237262,
    shares: 2719852,
    domestic: 206930,
    equipment: 304645,
    structures: 494711,
    intellectual: 47376,
    otherpayables: 1616801,
    bonds: 1429708,
    treasury: 797492,
    equityloan: 791338,
    homeloan: 18302,
    pensions: 2048687,
    moneyfunds: 164330,
    paper: 745741,
    otherreceivables: 1610300,
    repurchaseshares: 2145134,
    repurchases: 4708181,
    interbank: 533151,
    mortgages: 4754559,
    notes: 21468,
    bondnotes: 2822805,
    insurance: 10606288,
    monetarypaper: 5433,
    monetarygold: 11047,
    assets: 1058885,
    estates: 46816
  },
  "1998-03-01": {
    deposits: 4745492,
    fundshares: 3840596,
    self: 10571,
    equities: 6246602,
    funds: 1477457,
    foreign: 235339,
    shares: 3028982,
    domestic: 198612,
    equipment: 309708,
    structures: 505695,
    intellectual: 48394,
    otherpayables: 1665650,
    bonds: 1495982,
    treasury: 849571,
    equityloan: 836796,
    homeloan: 19602,
    pensions: 1921519,
    moneyfunds: 172634,
    paper: 804942,
    otherreceivables: 1739871,
    repurchaseshares: 2300702,
    repurchases: 4818515,
    interbank: 538017,
    mortgages: 4846678,
    notes: 22807,
    bondnotes: 2879811,
    insurance: 11010829,
    monetarypaper: 5471,
    monetarygold: 11049,
    assets: 1142406,
    estates: 49091
  },
  "1998-06-01": {
    deposits: 4822463,
    fundshares: 3977323,
    self: 10295,
    equities: 6325961,
    funds: 1508868,
    foreign: 243376,
    shares: 3071617,
    domestic: 204710,
    equipment: 315482,
    structures: 515485,
    intellectual: 49974,
    otherpayables: 1655757,
    bonds: 1588776,
    treasury: 871892,
    equityloan: 853466,
    homeloan: 19885,
    pensions: 2010408,
    moneyfunds: 175101,
    paper: 838860,
    otherreceivables: 1800871,
    repurchaseshares: 2338134,
    repurchases: 4977839,
    interbank: 541190,
    mortgages: 4967388,
    notes: 23385,
    bondnotes: 2983343,
    insurance: 11165014,
    monetarypaper: 5791,
    monetarygold: 11047,
    assets: 1168511,
    estates: 54054
  },
  "1998-09-01": {
    deposits: 4837429,
    fundshares: 3622978,
    self: 10065,
    equities: 5486811,
    funds: 1369728,
    foreign: 245247,
    shares: 2522351,
    domestic: 220935,
    equipment: 321573,
    structures: 524043,
    intellectual: 51750,
    otherpayables: 1655380,
    bonds: 1627033,
    treasury: 908916,
    equityloan: 901970,
    homeloan: 20701,
    pensions: 2352097,
    moneyfunds: 185073,
    paper: 874206,
    otherreceivables: 1855182,
    repurchaseshares: 2434230,
    repurchases: 5067306,
    interbank: 562218,
    mortgages: 5094317,
    notes: 24201,
    bondnotes: 3123817,
    insurance: 11028137,
    monetarypaper: 5910,
    monetarygold: 11044,
    assets: 1266902,
    estates: 63959
  },
  "1998-12-01": {
    deposits: 4975781,
    fundshares: 4173531,
    self: 10131,
    equities: 6610824,
    funds: 1624645,
    foreign: 295195,
    shares: 2912770,
    domestic: 315734,
    equipment: 327969,
    structures: 532594,
    intellectual: 53849,
    otherpayables: 1614746,
    bonds: 1687391,
    treasury: 938356,
    equityloan: 931849,
    homeloan: 22290,
    pensions: 2035999,
    moneyfunds: 191526,
    paper: 906724,
    otherreceivables: 1802066,
    repurchaseshares: 2301736,
    repurchases: 5106926,
    interbank: 601531,
    mortgages: 5243640,
    notes: 25521,
    bondnotes: 3294394,
    insurance: 11571692,
    monetarypaper: 5952,
    monetarygold: 11046,
    assets: 1351678,
    estates: 71605
  },
  "1999-03-01": {
    deposits: 4947837,
    fundshares: 4327921,
    self: 10250,
    equities: 6790695,
    funds: 1728701,
    foreign: 304328,
    shares: 2999047,
    domestic: 322011,
    equipment: 335973,
    structures: 541337,
    intellectual: 56103,
    otherpayables: 1673036,
    bonds: 1790302,
    treasury: 990919,
    equityloan: 986161,
    homeloan: 23755,
    pensions: 2074484,
    moneyfunds: 206858,
    paper: 926443,
    otherreceivables: 1874707,
    repurchaseshares: 2336316,
    repurchases: 5123075,
    interbank: 601710,
    mortgages: 5373375,
    notes: 26966,
    bondnotes: 3436768,
    insurance: 11770165,
    monetarypaper: 6122,
    monetarygold: 11049,
    assets: 1438367,
    estates: 76636
  },
  "1999-06-01": {
    deposits: 5022065,
    fundshares: 4651052,
    self: 10606,
    equities: 7304600,
    funds: 1878255,
    foreign: 311227,
    shares: 3229268,
    domestic: 391481,
    equipment: 342857,
    structures: 549718,
    intellectual: 58825,
    otherpayables: 1719057,
    bonds: 1856548,
    treasury: 1005352,
    equityloan: 1007227,
    homeloan: 24597,
    pensions: 1986532,
    moneyfunds: 210816,
    paper: 940869,
    otherreceivables: 1933317,
    repurchaseshares: 2356854,
    repurchases: 5223363,
    interbank: 607306,
    mortgages: 5517422,
    notes: 27824,
    bondnotes: 3584099,
    insurance: 12063009,
    monetarypaper: 6282,
    monetarygold: 11046,
    assets: 1420845,
    estates: 73571
  },
  "1999-09-01": {
    deposits: 5075219,
    fundshares: 4498393,
    self: 10933,
    equities: 6845286,
    funds: 1845076,
    foreign: 318508,
    shares: 2775918,
    domestic: 419542,
    equipment: 349647,
    structures: 558342,
    intellectual: 61551,
    otherpayables: 1739675,
    bonds: 1892386,
    treasury: 1017746,
    equityloan: 1023851,
    homeloan: 26335,
    pensions: 2222344,
    moneyfunds: 224708,
    paper: 963430,
    otherreceivables: 1918891,
    repurchaseshares: 2408561,
    repurchases: 5329567,
    interbank: 609754,
    mortgages: 5690216,
    notes: 29561,
    bondnotes: 3749551,
    insurance: 12049938,
    monetarypaper: 6330,
    monetarygold: 11047,
    assets: 1476728,
    estates: 75913
  },
  "1999-12-01": {
    deposits: 5323615,
    fundshares: 5233194,
    self: 12397,
    equities: 8090807,
    funds: 2158155,
    foreign: 325826,
    shares: 2907036,
    domestic: 476057,
    equipment: 357332,
    structures: 567921,
    intellectual: 64626,
    otherpayables: 1881789,
    bonds: 1852645,
    treasury: 1028542,
    equityloan: 1031687,
    homeloan: 28360,
    pensions: 2135194,
    moneyfunds: 242504,
    paper: 1082947,
    otherreceivables: 1912819,
    repurchaseshares: 2531329,
    repurchases: 5662183,
    interbank: 668424,
    mortgages: 5834073,
    notes: 31583,
    bondnotes: 3887741,
    insurance: 12810716,
    monetarypaper: 6432,
    monetarygold: 11048,
    assets: 1613146,
    estates: 78825
  },
  "2000-03-01": {
    deposits: 5216066,
    fundshares: 5600779,
    self: 12610,
    equities: 8679338,
    funds: 2302379,
    foreign: 332238,
    shares: 2945145,
    domestic: 481725,
    equipment: 363929,
    structures: 576340,
    intellectual: 67352,
    otherpayables: 2190640,
    bonds: 1888696,
    treasury: 1069866,
    equityloan: 1078228,
    homeloan: 29380,
    pensions: 2075214,
    moneyfunds: 251561,
    paper: 1115708,
    otherreceivables: 2135389,
    repurchaseshares: 2693063,
    repurchases: 5747041,
    interbank: 637010,
    mortgages: 5938047,
    notes: 32610,
    bondnotes: 3943982,
    insurance: 13091054,
    monetarypaper: 6744,
    monetarygold: 11048,
    assets: 1696149,
    estates: 80967
  },
  "2000-06-01": {
    deposits: 5350395,
    fundshares: 5463631,
    self: 13414,
    equities: 8441136,
    funds: 2292561,
    foreign: 338668,
    shares: 2855392,
    domestic: 475619,
    equipment: 370464,
    structures: 583498,
    intellectual: 70422,
    otherpayables: 2242452,
    bonds: 1943944,
    treasury: 1086850,
    equityloan: 1094731,
    homeloan: 29534,
    pensions: 2228981,
    moneyfunds: 247209,
    paper: 1139171,
    otherreceivables: 2171176,
    repurchaseshares: 2755739,
    repurchases: 5892242,
    interbank: 633574,
    mortgages: 6100251,
    notes: 32747,
    bondnotes: 4039375,
    insurance: 13131785,
    monetarypaper: 6865,
    monetarygold: 11046,
    assets: 1658250,
    estates: 82600
  },
  "2000-09-01": {
    deposits: 5397492,
    fundshares: 5541139,
    self: 13104,
    equities: 8548822,
    funds: 2364361,
    foreign: 347769,
    shares: 3231406,
    domestic: 493222,
    equipment: 376971,
    structures: 591813,
    intellectual: 73661,
    otherpayables: 2281989,
    bonds: 1993058,
    treasury: 1109773,
    equityloan: 1147042,
    homeloan: 29880,
    pensions: 2267339,
    moneyfunds: 256963,
    paper: 1154050,
    otherreceivables: 2234352,
    repurchaseshares: 2804128,
    repurchases: 5949439,
    interbank: 644246,
    mortgages: 6251644,
    notes: 33111,
    bondnotes: 4168498,
    insurance: 13294081,
    monetarypaper: 6933,
    monetarygold: 11046,
    assets: 1727378,
    estates: 81998
  },
  "2000-12-01": {
    deposits: 5548250,
    fundshares: 5119386,
    self: 13148,
    equities: 7844168,
    funds: 2261782,
    foreign: 312494,
    shares: 3269288,
    domestic: 447656,
    equipment: 381698,
    structures: 601318,
    intellectual: 76295,
    otherpayables: 2251465,
    bonds: 2042296,
    treasury: 1153791,
    equityloan: 1190341,
    homeloan: 30537,
    pensions: 2580581,
    moneyfunds: 269965,
    paper: 1214673,
    otherreceivables: 2249737,
    repurchaseshares: 2936413,
    repurchases: 6187193,
    interbank: 702431,
    mortgages: 6392016,
    notes: 33812,
    bondnotes: 4319662,
    insurance: 13190000,
    monetarypaper: 6997,
    monetarygold: 11046,
    assets: 1845248,
    estates: 83550
  },
  "2001-03-01": {
    deposits: 5608037,
    fundshares: 4588723,
    self: 14801,
    equities: 6944823,
    funds: 2009556,
    foreign: 315041,
    shares: 3006313,
    domestic: 464845,
    equipment: 383004,
    structures: 609851,
    intellectual: 78267,
    otherpayables: 2222507,
    bonds: 2168611,
    treasury: 1218308,
    equityloan: 1231255,
    homeloan: 31363,
    pensions: 2968782,
    moneyfunds: 289977,
    paper: 1182516,
    otherreceivables: 2218806,
    repurchaseshares: 3042378,
    repurchases: 6287965,
    interbank: 763702,
    mortgages: 6473858,
    notes: 34589,
    bondnotes: 4428724,
    insurance: 13011881,
    monetarypaper: 7029,
    monetarygold: 11046,
    assets: 2035459,
    estates: 83888
  },
  "2001-06-01": {
    deposits: 5701259,
    fundshares: 4887912,
    self: 16068,
    equities: 7503717,
    funds: 2099060,
    foreign: 315581,
    shares: 3241491,
    domestic: 469500,
    equipment: 385307,
    structures: 619207,
    intellectual: 80279,
    otherpayables: 2267409,
    bonds: 2235235,
    treasury: 1220678,
    equityloan: 1236895,
    homeloan: 31725,
    pensions: 2934708,
    moneyfunds: 294432,
    paper: 1138284,
    otherreceivables: 2353508,
    repurchaseshares: 3079389,
    repurchases: 6317170,
    interbank: 772575,
    mortgages: 6676471,
    notes: 35479,
    bondnotes: 4601999,
    insurance: 13347598,
    monetarypaper: 7143,
    monetarygold: 11044,
    assets: 2052455,
    estates: 86216
  },
  "2001-09-01": {
    deposits: 5832074,
    fundshares: 4252626,
    self: 16508,
    equities: 6420113,
    funds: 1840288,
    foreign: 329388,
    shares: 2921232,
    domestic: 461383,
    equipment: 386432,
    structures: 629528,
    intellectual: 81083,
    otherpayables: 2351729,
    bonds: 2313673,
    treasury: 1219564,
    equityloan: 1249029,
    homeloan: 32441,
    pensions: 3358683,
    moneyfunds: 309404,
    paper: 1116494,
    otherreceivables: 2458893,
    repurchaseshares: 3244573,
    repurchases: 6439645,
    interbank: 811897,
    mortgages: 6883759,
    notes: 36221,
    bondnotes: 4806735,
    insurance: 13145671,
    monetarypaper: 7266,
    monetarygold: 11045,
    assets: 2161638,
    estates: 83955
  },
  "2001-12-01": {
    deposits: 6047883,
    fundshares: 4689603,
    self: 17309,
    equities: 7228539,
    funds: 2017495,
    foreign: 272324,
    shares: 3157263,
    domestic: 400743,
    equipment: 387962,
    structures: 633049,
    intellectual: 82570,
    otherpayables: 2533697,
    bonds: 2397193,
    treasury: 1208240,
    equityloan: 1230300,
    homeloan: 33289,
    pensions: 3226929,
    moneyfunds: 324561,
    paper: 1149792,
    otherreceivables: 2712111,
    repurchaseshares: 3209565,
    repurchases: 6454900,
    interbank: 883917,
    mortgages: 7065316,
    notes: 37088,
    bondnotes: 4962349,
    insurance: 13519522,
    monetarypaper: 7373,
    monetarygold: 11045,
    assets: 2285310,
    estates: 85759
  },
  "2002-03-01": {
    deposits: 6095362,
    fundshares: 4812247,
    self: 16482,
    equities: 7339625,
    funds: 2128529,
    foreign: 283197,
    shares: 3292405,
    domestic: 400573,
    equipment: 391272,
    structures: 638107,
    intellectual: 84027,
    otherpayables: 2526861,
    bonds: 2493043,
    treasury: 1225363,
    equityloan: 1262669,
    homeloan: 34007,
    pensions: 3313647,
    moneyfunds: 320675,
    paper: 1096276,
    otherreceivables: 2818985,
    repurchaseshares: 3226269,
    repurchases: 6440453,
    interbank: 881907,
    mortgages: 7234019,
    notes: 37806,
    bondnotes: 5132043,
    insurance: 13786433,
    monetarypaper: 7648,
    monetarygold: 11044,
    assets: 2247857,
    estates: 87734
  },
  "2002-06-01": {
    deposits: 6133837,
    fundshares: 4433260,
    self: 16377,
    equities: 6538390,
    funds: 1927633,
    foreign: 289648,
    shares: 3141723,
    domestic: 403998,
    equipment: 394564,
    structures: 643916,
    intellectual: 84727,
    otherpayables: 2499642,
    bonds: 2621061,
    treasury: 1249729,
    equityloan: 1284836,
    homeloan: 34260,
    pensions: 3609700,
    moneyfunds: 318643,
    paper: 1033583,
    otherreceivables: 2828264,
    repurchaseshares: 3351497,
    repurchases: 6451178,
    interbank: 892870,
    mortgages: 7461778,
    notes: 38092,
    bondnotes: 5253012,
    insurance: 13641062,
    monetarypaper: 8330,
    monetarygold: 11044,
    assets: 2190769,
    estates: 93493
  },
  "2002-09-01": {
    deposits: 6297591,
    fundshares: 3898456,
    self: 16150,
    equities: 5586437,
    funds: 1726170,
    foreign: 293285,
    shares: 2701041,
    domestic: 406121,
    equipment: 394676,
    structures: 648308,
    intellectual: 86385,
    otherpayables: 2647730,
    bonds: 2689280,
    treasury: 1255656,
    equityloan: 1296569,
    homeloan: 34831,
    pensions: 3870773,
    moneyfunds: 318997,
    paper: 1035827,
    otherreceivables: 2876469,
    repurchaseshares: 3414412,
    repurchases: 6528498,
    interbank: 899650,
    mortgages: 7707745,
    notes: 38697,
    bondnotes: 5367986,
    insurance: 13449207,
    monetarypaper: 8264,
    monetarygold: 11038,
    assets: 2158164,
    estates: 94897
  },
  "2002-12-01": {
    deposits: 6430494,
    fundshares: 4118082,
    self: 16735,
    equities: 5954528,
    funds: 1849124,
    foreign: 267509,
    shares: 2856276,
    domestic: 309871,
    equipment: 397770,
    structures: 654516,
    intellectual: 87008,
    otherpayables: 2551859,
    bonds: 2797746,
    treasury: 1302603,
    equityloan: 1332169,
    homeloan: 35187,
    pensions: 3883438,
    moneyfunds: 332190,
    paper: 1068856,
    otherreceivables: 2838247,
    repurchaseshares: 3436635,
    repurchases: 6583059,
    interbank: 911730,
    mortgages: 7969950,
    notes: 39080,
    bondnotes: 5509001,
    insurance: 13746976,
    monetarypaper: 8380,
    monetarygold: 11039,
    assets: 2265075,
    estates: 96960
  },
  "2003-03-01": {
    deposits: 6516508,
    fundshares: 4057552,
    self: 16169,
    equities: 5901094,
    funds: 1801669,
    foreign: 273031,
    shares: 2727642,
    domestic: 333790,
    equipment: 397964,
    structures: 661405,
    intellectual: 87267,
    otherpayables: 2828149,
    bonds: 2863688,
    treasury: 1336868,
    equityloan: 1326565,
    homeloan: 36358,
    pensions: 3930227,
    moneyfunds: 321733,
    paper: 1144675,
    otherreceivables: 3070530,
    repurchaseshares: 3514906,
    repurchases: 6628108,
    interbank: 912366,
    mortgages: 8172123,
    notes: 40293,
    bondnotes: 5637311,
    insurance: 13870282,
    monetarypaper: 8505,
    monetarygold: 11038,
    assets: 2198864,
    estates: 95798
  },
  "2003-06-01": {
    deposits: 6641317,
    fundshares: 4649056,
    self: 16851,
    equities: 6770195,
    funds: 2048435,
    foreign: 279612,
    shares: 3190718,
    domestic: 333235,
    equipment: 399785,
    structures: 661008,
    intellectual: 88592,
    otherpayables: 3202134,
    bonds: 2966253,
    treasury: 1368256,
    equityloan: 1356939,
    homeloan: 36877,
    pensions: 3816418,
    moneyfunds: 315188,
    paper: 1130168,
    otherreceivables: 3371971,
    repurchaseshares: 3809550,
    repurchases: 6809417,
    interbank: 905397,
    mortgages: 8457879,
    notes: 40859,
    bondnotes: 5747906,
    insurance: 14369797,
    monetarypaper: 8657,
    monetarygold: 11040,
    assets: 2157611,
    estates: 97642
  },
  "2003-09-01": {
    deposits: 6724200,
    fundshares: 4845657,
    self: 16854,
    equities: 7083568,
    funds: 2126377,
    foreign: 285272,
    shares: 3320479,
    domestic: 333064,
    equipment: 400929,
    structures: 665003,
    intellectual: 89716,
    otherpayables: 3247003,
    bonds: 3047553,
    treasury: 1377500,
    equityloan: 1365690,
    homeloan: 37629,
    pensions: 3819697,
    moneyfunds: 303874,
    paper: 1107093,
    otherreceivables: 3384007,
    repurchaseshares: 3716674,
    repurchases: 6687676,
    interbank: 897717,
    mortgages: 8735411,
    notes: 41695,
    bondnotes: 5945074,
    insurance: 14609616,
    monetarypaper: 8746,
    monetarygold: 11039,
    assets: 2090350,
    estates: 100946
  },
  "2003-12-01": {
    deposits: 6868133,
    fundshares: 5362097,
    self: 17168,
    equities: 7909776,
    funds: 2356928,
    foreign: 397646,
    shares: 3707420,
    domestic: 404510,
    equipment: 405718,
    structures: 673121,
    intellectual: 90450,
    otherpayables: 3194255,
    bonds: 3194367,
    treasury: 1415006,
    equityloan: 1402917,
    homeloan: 37702,
    pensions: 3647378,
    moneyfunds: 293843,
    paper: 1116033,
    otherreceivables: 3312185,
    repurchaseshares: 3732417,
    repurchases: 6760515,
    interbank: 902163,
    mortgages: 8961331,
    notes: 42283,
    bondnotes: 5944522,
    insurance: 15072722,
    monetarypaper: 8847,
    monetarygold: 11039,
    assets: 2040021,
    estates: 105855
  },
  "2004-03-01": {
    deposits: 6951178,
    fundshares: 5620835,
    self: 15821,
    equities: 8212493,
    funds: 2429628,
    foreign: 418624,
    shares: 3985061,
    domestic: 410512,
    equipment: 411013,
    structures: 686650,
    intellectual: 91685,
    otherpayables: 3394274,
    bonds: 3345078,
    treasury: 1491036,
    equityloan: 1475487,
    homeloan: 39433,
    pensions: 3733439,
    moneyfunds: 282643,
    paper: 1136597,
    otherreceivables: 3504580,
    repurchaseshares: 3970007,
    repurchases: 6963868,
    interbank: 940723,
    mortgages: 9212858,
    notes: 44099,
    bondnotes: 5949059,
    insurance: 15350828,
    monetarypaper: 8978,
    monetarygold: 11041,
    assets: 1992608,
    estates: 111456
  },
  "2004-06-01": {
    deposits: 7185804,
    fundshares: 5640075,
    self: 15628,
    equities: 8377544,
    funds: 2489960,
    foreign: 431451,
    shares: 3872608,
    domestic: 433281,
    equipment: 419468,
    structures: 702025,
    intellectual: 92776,
    otherpayables: 3482318,
    bonds: 3487133,
    treasury: 1496600,
    equityloan: 1486934,
    homeloan: 40522,
    pensions: 3806427,
    moneyfunds: 272055,
    paper: 1114243,
    otherreceivables: 3601031,
    repurchaseshares: 4120425,
    repurchases: 7165860,
    interbank: 960938,
    mortgages: 9558696,
    notes: 45233,
    bondnotes: 6022906,
    insurance: 15607383,
    monetarypaper: 9226,
    monetarygold: 11040,
    assets: 1941873,
    estates: 117607
  },
  "2004-09-01": {
    deposits: 7245271,
    fundshares: 5663150,
    self: 16374,
    equities: 8307487,
    funds: 2450962,
    foreign: 437882,
    shares: 3933475,
    domestic: 455189,
    equipment: 424514,
    structures: 721238,
    intellectual: 94466,
    otherpayables: 3603173,
    bonds: 3676221,
    treasury: 1600237,
    equityloan: 1611115,
    homeloan: 40522,
    pensions: 4070147,
    moneyfunds: 262290,
    paper: 1113397,
    otherreceivables: 3726179,
    repurchaseshares: 4367404,
    repurchases: 7347351,
    interbank: 951215,
    mortgages: 9872306,
    notes: 45260,
    bondnotes: 6066709,
    insurance: 15831177,
    monetarypaper: 10502,
    monetarygold: 11039,
    assets: 1892172,
    estates: 121564
  },
  "2004-12-01": {
    deposits: 7484623,
    fundshares: 6194101,
    self: 16929,
    equities: 9293754,
    funds: 2701399,
    foreign: 517212,
    shares: 4289654,
    domestic: 485935,
    equipment: 432968,
    structures: 743102,
    intellectual: 95500,
    otherpayables: 3870135,
    bonds: 3834625,
    treasury: 1676882,
    equityloan: 1692938,
    homeloan: 40092,
    pensions: 3832505,
    moneyfunds: 259589,
    paper: 1213393,
    otherreceivables: 3906286,
    repurchaseshares: 4319817,
    repurchases: 7414667,
    interbank: 987428,
    mortgages: 10209820,
    notes: 44788,
    bondnotes: 6060288,
    insurance: 16395241,
    monetarypaper: 11914,
    monetarygold: 11041,
    assets: 1901700,
    estates: 131357
  },
  "2005-03-01": {
    deposits: 7646466,
    fundshares: 6170973,
    self: 17181,
    equities: 9207291,
    funds: 2707307,
    foreign: 524017,
    shares: 4106821,
    domestic: 500120,
    equipment: 440135,
    structures: 759048,
    intellectual: 96363,
    otherpayables: 3885876,
    bonds: 4063008,
    treasury: 1686587,
    equityloan: 1761101,
    homeloan: 40409,
    pensions: 3976837,
    moneyfunds: 263026,
    paper: 1240490,
    otherreceivables: 4030434,
    repurchaseshares: 4500003,
    repurchases: 7554533,
    interbank: 986378,
    mortgages: 10498128,
    notes: 45138,
    bondnotes: 6048072,
    insurance: 16516540,
    monetarypaper: 12560,
    monetarygold: 11037,
    assets: 1865022,
    estates: 136431
  },
  "2005-06-01": {
    deposits: 7764222,
    fundshares: 6352653,
    self: 17045,
    equities: 9419762,
    funds: 2836008,
    foreign: 534354,
    shares: 4321474,
    domestic: 502384,
    equipment: 445204,
    structures: 773539,
    intellectual: 97357,
    otherpayables: 3835559,
    bonds: 4277180,
    treasury: 1747880,
    equityloan: 1816489,
    homeloan: 41372,
    pensions: 4069308,
    moneyfunds: 268762,
    paper: 1297880,
    otherreceivables: 4064623,
    repurchaseshares: 4697181,
    repurchases: 7737566,
    interbank: 998778,
    mortgages: 10881803,
    notes: 45905,
    bondnotes: 6067324,
    insurance: 16809847,
    monetarypaper: 12937,
    monetarygold: 11037,
    assets: 1853916,
    estates: 142572
  },
  "2005-09-01": {
    deposits: 7949342,
    fundshares: 6676786,
    self: 16877,
    equities: 9852700,
    funds: 2991147,
    foreign: 541118,
    shares: 4399330,
    domestic: 498931,
    equipment: 447328,
    structures: 796379,
    intellectual: 98119,
    otherpayables: 3946347,
    bonds: 4309195,
    treasury: 1780754,
    equityloan: 1862010,
    homeloan: 42070,
    pensions: 4107116,
    moneyfunds: 282064,
    paper: 1355402,
    otherreceivables: 4102127,
    repurchaseshares: 4879212,
    repurchases: 7949256,
    interbank: 1005677,
    mortgages: 11257613,
    notes: 46715,
    bondnotes: 6042612,
    insurance: 17177215,
    monetarypaper: 13307,
    monetarygold: 11037,
    assets: 1901890,
    estates: 146348
  },
  "2005-12-01": {
    deposits: 8106135,
    fundshares: 6864553,
    self: 17068,
    equities: 10165932,
    funds: 3121077,
    foreign: 573766,
    shares: 4633709,
    domestic: 492510,
    equipment: 451314,
    structures: 823662,
    intellectual: 99037,
    otherpayables: 3779062,
    bonds: 4517934,
    treasury: 1837773,
    equityloan: 1927655,
    homeloan: 42043,
    pensions: 4072041,
    moneyfunds: 306363,
    paper: 1467099,
    otherreceivables: 4120238,
    repurchaseshares: 4855028,
    repurchases: 8074479,
    interbank: 996021,
    mortgages: 11633376,
    notes: 46697,
    bondnotes: 6140712,
    insurance: 17488465,
    monetarypaper: 13536,
    monetarygold: 11039,
    assets: 2026821,
    estates: 145467
  },
  "2006-03-01": {
    deposits: 8285043,
    fundshares: 7313565,
    self: 17470,
    equities: 10368924,
    funds: 3320039,
    foreign: 611911,
    shares: 4756791,
    domestic: 512870,
    equipment: 456233,
    structures: 842316,
    intellectual: 99909,
    otherpayables: 4122676,
    bonds: 4716759,
    treasury: 1859476,
    equityloan: 1956691,
    homeloan: 42602,
    pensions: 4141719,
    moneyfunds: 306814,
    paper: 1500036,
    otherreceivables: 4754664,
    repurchaseshares: 5036594,
    repurchases: 8151308,
    interbank: 996352,
    mortgages: 12046530,
    notes: 47271,
    bondnotes: 6216933,
    insurance: 17816153,
    monetarypaper: 13759,
    monetarygold: 11039,
    assets: 2033948,
    estates: 149611
  },
  "2006-06-01": {
    deposits: 8449476,
    fundshares: 7227911,
    self: 17071,
    equities: 10158561,
    funds: 3282188,
    foreign: 602332,
    shares: 4785842,
    domestic: 511996,
    equipment: 460939,
    structures: 860992,
    intellectual: 100877,
    otherpayables: 4059842,
    bonds: 4914219,
    treasury: 1926725,
    equityloan: 2009810,
    homeloan: 42607,
    pensions: 4330599,
    moneyfunds: 313850,
    paper: 1552849,
    otherreceivables: 4846921,
    repurchaseshares: 5189291,
    repurchases: 8263766,
    interbank: 1063964,
    mortgages: 12447678,
    notes: 47306,
    bondnotes: 6330316,
    insurance: 17916794,
    monetarypaper: 14970,
    monetarygold: 11037,
    assets: 2094385,
    estates: 153529
  },
  "2006-09-01": {
    deposits: 8584025,
    fundshares: 7517282,
    self: 16895,
    equities: 10505705,
    funds: 3402463,
    foreign: 617289,
    shares: 5062516,
    domestic: 539673,
    equipment: 468376,
    structures: 874628,
    intellectual: 101667,
    otherpayables: 4183722,
    bonds: 5018179,
    treasury: 1963755,
    equityloan: 2039452,
    homeloan: 42592,
    pensions: 4315429,
    moneyfunds: 322577,
    paper: 1637732,
    otherreceivables: 5059266,
    repurchaseshares: 5533172,
    repurchases: 8584998,
    interbank: 1036684,
    mortgages: 12790490,
    notes: 47405,
    bondnotes: 6386355,
    insurance: 18233534,
    monetarypaper: 15069,
    monetarygold: 11037,
    assets: 2195786,
    estates: 154877
  },
  "2006-12-01": {
    deposits: 8763077,
    fundshares: 8059704,
    self: 15279,
    equities: 11323730,
    funds: 3627207,
    foreign: 722430,
    shares: 5414714,
    domestic: 620283,
    equipment: 475297,
    structures: 895397,
    intellectual: 102353,
    otherpayables: 4163512,
    bonds: 5217380,
    treasury: 2023181,
    equityloan: 2094839,
    homeloan: 42001,
    pensions: 4241304,
    moneyfunds: 335582,
    paper: 1739988,
    otherreceivables: 5004887,
    repurchaseshares: 5435698,
    repurchases: 8696983,
    interbank: 1082193,
    mortgages: 13053750,
    notes: 46878,
    bondnotes: 6468930,
    insurance: 18645293,
    monetarypaper: 15325,
    monetarygold: 11037,
    assets: 2338451,
    estates: 152050
  },
  "2007-03-01": {
    deposits: 8818307,
    fundshares: 8335378,
    self: 17916,
    equities: 11625634,
    funds: 3695000,
    foreign: 739593,
    shares: 5400282,
    domestic: 606847,
    equipment: 482389,
    structures: 909915,
    intellectual: 104355,
    otherpayables: 4456466,
    bonds: 5550126,
    treasury: 2066417,
    equityloan: 2075064,
    homeloan: 41677,
    pensions: 4353991,
    moneyfunds: 345431,
    paper: 1779512,
    otherreceivables: 5449586,
    repurchaseshares: 5840696,
    repurchases: 8947195,
    interbank: 1090034,
    mortgages: 13330346,
    notes: 46873,
    bondnotes: 6600939,
    insurance: 18888182,
    monetarypaper: 15846,
    monetarygold: 11037,
    assets: 2417799,
    estates: 148024
  },
  "2007-06-01": {
    deposits: 8906845,
    fundshares: 8846074,
    self: 18445,
    equities: 12437252,
    funds: 3909135,
    foreign: 831236,
    shares: 5362686,
    domestic: 646425,
    equipment: 489022,
    structures: 918139,
    intellectual: 106299,
    otherpayables: 4838721,
    bonds: 5694817,
    treasury: 2123276,
    equityloan: 2121837,
    homeloan: 41427,
    pensions: 4193046,
    moneyfunds: 357462,
    paper: 1851039,
    otherreceivables: 5715161,
    repurchaseshares: 5966196,
    repurchases: 9225186,
    interbank: 1111649,
    mortgages: 13639703,
    notes: 46980,
    bondnotes: 6762658,
    insurance: 19327604,
    monetarypaper: 16102,
    monetarygold: 11037,
    assets: 2514235,
    estates: 151224
  },
  "2007-09-01": {
    deposits: 9183074,
    fundshares: 9078504,
    self: 18993,
    equities: 12703582,
    funds: 4016200,
    foreign: 859160,
    shares: 5076743,
    domestic: 673077,
    equipment: 492248,
    structures: 930507,
    intellectual: 108277,
    otherpayables: 4587983,
    bonds: 6044798,
    treasury: 2190383,
    equityloan: 2164616,
    homeloan: 48176,
    pensions: 4282770,
    moneyfunds: 390920,
    paper: 1626102,
    otherreceivables: 5647532,
    repurchaseshares: 6216449,
    repurchases: 9793371,
    interbank: 1249582,
    mortgages: 13936276,
    notes: 53821,
    bondnotes: 7076602,
    insurance: 19612615,
    monetarypaper: 17274,
    monetarygold: 11037,
    assets: 2830799,
    estates: 154419
  },
  "2007-12-01": {
    deposits: 9307164,
    fundshares: 8914408,
    self: 20007,
    equities: 12340810,
    funds: 3922670,
    foreign: 935381,
    shares: 4618268,
    domestic: 534450,
    equipment: 494349,
    structures: 946244,
    intellectual: 110377,
    otherpayables: 4233926,
    bonds: 6157770,
    treasury: 2250578,
    equityloan: 2221484,
    homeloan: 50253,
    pensions: 4432507,
    moneyfunds: 416303,
    paper: 1560918,
    otherreceivables: 5449749,
    repurchaseshares: 6299387,
    repurchases: 10260250,
    interbank: 1288483,
    mortgages: 14144380,
    notes: 55971,
    bondnotes: 7374610,
    insurance: 19659135,
    monetarypaper: 18450,
    monetarygold: 11037,
    assets: 3085760,
    estates: 156728
  },
  "2008-03-01": {
    deposits: 9325168,
    fundshares: 8266118,
    self: 20497,
    equities: 11190895,
    funds: 3617251,
    foreign: 859307,
    shares: 4135122,
    domestic: 524983,
    equipment: 491599,
    structures: 960586,
    intellectual: 112872,
    otherpayables: 4163004,
    bonds: 6173102,
    treasury: 2282041,
    equityloan: 2250987,
    homeloan: 47246,
    pensions: 4826438,
    moneyfunds: 457347,
    paper: 1522540,
    otherreceivables: 5669115,
    repurchaseshares: 6704059,
    repurchases: 10610540,
    interbank: 1415334,
    mortgages: 14283900,
    notes: 53478,
    bondnotes: 7540355,
    insurance: 19433628,
    monetarypaper: 19549,
    monetarygold: 11037,
    assets: 3442540,
    estates: 161834
  },
  "2008-06-01": {
    deposits: 9313597,
    fundshares: 8280019,
    self: 20624,
    equities: 11131163,
    funds: 3589706,
    foreign: 825823,
    shares: 3688137,
    domestic: 530742,
    equipment: 488529,
    structures: 972246,
    intellectual: 115557,
    otherpayables: 3793499,
    bonds: 6164581,
    treasury: 2306128,
    equityloan: 2291363,
    homeloan: 48966,
    pensions: 4986486,
    moneyfunds: 449139,
    paper: 1489219,
    otherreceivables: 5343527,
    repurchaseshares: 6489547,
    repurchases: 10680598,
    interbank: 1556977,
    mortgages: 14308945,
    notes: 55284,
    bondnotes: 7862319,
    insurance: 19546416,
    monetarypaper: 19874,
    monetarygold: 11037,
    assets: 3377297,
    estates: 161183
  },
  "2008-09-01": {
    deposits: 10167747,
    fundshares: 7276842,
    self: 21623,
    equities: 9782403,
    funds: 3186809,
    foreign: 693227,
    shares: 3709865,
    domestic: 527473,
    equipment: 490947,
    structures: 992144,
    intellectual: 117802,
    otherpayables: 3544427,
    bonds: 6085726,
    treasury: 2240454,
    equityloan: 2251059,
    homeloan: 48111,
    pensions: 5487818,
    moneyfunds: 456032,
    paper: 1313820,
    otherreceivables: 5294975,
    repurchaseshares: 7054652,
    repurchases: 11783314,
    interbank: 1733429,
    mortgages: 14289693,
    notes: 54588,
    bondnotes: 8045333,
    insurance: 19284235,
    monetarypaper: 20312,
    monetarygold: 11037,
    assets: 3420293,
    estates: 162656
  },
  "2008-12-01": {
    deposits: 11257019,
    fundshares: 5788401,
    self: 19606,
    equities: 7551977,
    funds: 2599622,
    foreign: 582205,
    shares: 2893574,
    domestic: 318835,
    equipment: 499565,
    structures: 1014492,
    intellectual: 118960,
    otherpayables: 2645416,
    bonds: 5944659,
    treasury: 2387721,
    equityloan: 2366635,
    homeloan: 44982,
    pensions: 6165929,
    moneyfunds: 543617,
    paper: 1377044,
    otherreceivables: 4497212,
    repurchaseshares: 6157919,
    repurchases: 11518511,
    interbank: 2340151,
    mortgages: 14224631,
    notes: 51767,
    bondnotes: 8143351,
    insurance: 18663283,
    monetarypaper: 21076,
    monetarygold: 11037,
    assets: 3832237,
    estates: 163507
  },
  "2009-03-01": {
    deposits: 11108466,
    fundshares: 5440338,
    self: 22188,
    equities: 6713614,
    funds: 2455332,
    foreign: 532025,
    shares: 2366818,
    domestic: 288665,
    equipment: 494816,
    structures: 1009370,
    intellectual: 120643,
    otherpayables: 2637275,
    bonds: 5818821,
    treasury: 2499030,
    equityloan: 2474948,
    homeloan: 47246,
    pensions: 6523561,
    moneyfunds: 543797,
    paper: 1222291,
    otherreceivables: 4496360,
    repurchaseshares: 5682543,
    repurchases: 10792768,
    interbank: 2402058,
    mortgages: 14216908,
    notes: 53478,
    bondnotes: 8160278,
    insurance: 18552611,
    monetarypaper: 22558,
    monetarygold: 11037,
    assets: 3813876,
    estates: 169424
  },
  "2009-06-01": {
    deposits: 11017295,
    fundshares: 6372319,
    self: 19370,
    equities: 7858200,
    funds: 2816980,
    foreign: 632232,
    shares: 2904354,
    domestic: 338633,
    equipment: 488059,
    structures: 985607,
    intellectual: 122296,
    otherpayables: 2615366,
    bonds: 5657037,
    treasury: 2459026,
    equityloan: 2407832,
    homeloan: 48966,
    pensions: 6409423,
    moneyfunds: 524058,
    paper: 1089064,
    otherreceivables: 4455491,
    repurchaseshares: 5262879,
    repurchases: 9757394,
    interbank: 2353221,
    mortgages: 14147277,
    notes: 55284,
    bondnotes: 8118825,
    insurance: 19141204,
    monetarypaper: 24246,
    monetarygold: 11037,
    assets: 3653101,
    estates: 174739
  },
  "2009-09-01": {
    deposits: 11303549,
    fundshares: 7402832,
    self: 18859,
    equities: 9201510,
    funds: 3241610,
    foreign: 756513,
    shares: 3596429,
    domestic: 394190,
    equipment: 480033,
    structures: 964737,
    intellectual: 124468,
    otherpayables: 2777605,
    bonds: 5535615,
    treasury: 2457613,
    equityloan: 2394515,
    homeloan: 48111,
    pensions: 6117341,
    moneyfunds: 500411,
    paper: 964625,
    otherreceivables: 4439799,
    repurchaseshares: 4985514,
    repurchases: 9269183,
    interbank: 2540122,
    mortgages: 14033394,
    notes: 54588,
    bondnotes: 8098057,
    insurance: 19782729,
    monetarypaper: 24918,
    monetarygold: 11037,
    assets: 3425463,
    estates: 174849
  },
  "2009-12-01": {
    deposits: 11621175,
    fundshares: 7796729,
    self: 20731,
    equities: 9773238,
    funds: 3386997,
    foreign: 856204,
    shares: 3604424,
    domestic: 463484,
    equipment: 470766,
    structures: 967480,
    intellectual: 126739,
    otherpayables: 2804699,
    bonds: 5304965,
    treasury: 2486498,
    equityloan: 2434405,
    homeloan: 44982,
    pensions: 6025663,
    moneyfunds: 460096,
    paper: 923502,
    otherreceivables: 4342642,
    repurchaseshares: 4478594,
    repurchases: 8553322,
    interbank: 2623015,
    mortgages: 13936769,
    notes: 51767,
    bondnotes: 8083298,
    insurance: 20092493,
    monetarypaper: 25640,
    monetarygold: 11037,
    assets: 3315893,
    estates: 175264
  },
  "2010-03-01": {
    deposits: 11686304,
    fundshares: 8215276,
    self: 21496,
    equities: 10217056,
    funds: 3523280,
    foreign: 883540,
    shares: 4001228,
    domestic: 485910,
    equipment: 463911,
    structures: 963625,
    intellectual: 126710,
    otherpayables: 2651793,
    bonds: 5234340,
    treasury: 2428998,
    equityloan: 2396240,
    homeloan: 44182,
    pensions: 6125796,
    moneyfunds: 425152,
    paper: 843432,
    otherreceivables: 4374042,
    repurchaseshares: 4556795,
    repurchases: 8509486,
    interbank: 2655697,
    mortgages: 13775202,
    notes: 50999,
    bondnotes: 7691586,
    insurance: 20533514,
    monetarypaper: 26255,
    monetarygold: 11037,
    assets: 2983807,
    estates: 173759
  },
  "2010-06-01": {
    deposits: 11584758,
    fundshares: 7689974,
    self: 21280,
    equities: 9137249,
    funds: 3275568,
    foreign: 775972,
    shares: 3636633,
    domestic: 437296,
    equipment: 464030,
    structures: 961580,
    intellectual: 127218,
    otherpayables: 2582394,
    bonds: 5082338,
    treasury: 2392550,
    equityloan: 2394378,
    homeloan: 43668,
    pensions: 6547255,
    moneyfunds: 409760,
    paper: 808776,
    otherreceivables: 4337539,
    repurchaseshares: 4508887,
    repurchases: 8500283,
    interbank: 2507442,
    mortgages: 13660249,
    notes: 50531,
    bondnotes: 7672943,
    insurance: 20455211,
    monetarypaper: 26620,
    monetarygold: 11037,
    assets: 2813331,
    estates: 172790
  },
  "2010-09-01": {
    deposits: 11643747,
    fundshares: 8478482,
    self: 22121,
    equities: 10215524,
    funds: 3588091,
    foreign: 876406,
    shares: 3925820,
    domestic: 486935,
    equipment: 466871,
    structures: 959704,
    intellectual: 127783,
    otherpayables: 2694221,
    bonds: 4993047,
    treasury: 2444825,
    equityloan: 2427796,
    homeloan: 43385,
    pensions: 6476958,
    moneyfunds: 415189,
    paper: 801906,
    otherreceivables: 4293253,
    repurchaseshares: 4419581,
    repurchases: 8430877,
    interbank: 2485706,
    mortgages: 13545749,
    notes: 50627,
    bondnotes: 7607082,
    insurance: 21162056,
    monetarypaper: 26686,
    monetarygold: 11037,
    assets: 2796581,
    estates: 176509
  },
  "2010-12-01": {
    deposits: 11976527,
    fundshares: 9029849,
    self: 26277,
    equities: 11245625,
    funds: 3832031,
    foreign: 954361,
    shares: 4346866,
    domestic: 586288,
    equipment: 469875,
    structures: 958929,
    intellectual: 129221,
    otherpayables: 2332833,
    bonds: 5088632,
    treasury: 2411737,
    equityloan: 2346105,
    homeloan: 41735,
    pensions: 6395716,
    moneyfunds: 522042,
    paper: 801478,
    otherreceivables: 4067818,
    repurchaseshares: 4400198,
    repurchases: 8457563,
    interbank: 2695270,
    mortgages: 13361835,
    notes: 49021,
    bondnotes: 7581337,
    insurance: 21757142,
    monetarypaper: 26524,
    monetarygold: 11037,
    assets: 3034465,
    estates: 176091
  },
  "2011-03-01": {
    deposits: 12642697,
    fundshares: 9477390,
    self: 24689,
    equities: 11805136,
    funds: 3999320,
    foreign: 1009065,
    shares: 4596783,
    domestic: 622097,
    equipment: 471722,
    structures: 958199,
    intellectual: 131392,
    otherpayables: 2314455,
    bonds: 4994251,
    treasury: 2461119,
    equityloan: 2411688,
    homeloan: 41278,
    pensions: 6404285,
    moneyfunds: 531359,
    paper: 830240,
    otherreceivables: 4237148,
    repurchaseshares: 4443141,
    repurchases: 8377424,
    interbank: 3141098,
    mortgages: 13273777,
    notes: 48611,
    bondnotes: 7631585,
    insurance: 22156391,
    monetarypaper: 26293,
    monetarygold: 11037,
    assets: 2970327,
    estates: 181637
  },
  "2011-06-01": {
    deposits: 13098163,
    fundshares: 9567658,
    self: 24977,
    equities: 11768232,
    funds: 4009754,
    foreign: 985180,
    shares: 4548224,
    domestic: 629059,
    equipment: 475613,
    structures: 966263,
    intellectual: 133533,
    otherpayables: 2428037,
    bonds: 4920323,
    treasury: 2470786,
    equityloan: 2361843,
    homeloan: 36795,
    pensions: 6561897,
    moneyfunds: 536529,
    paper: 783731,
    otherreceivables: 4459248,
    repurchaseshares: 4471525,
    repurchases: 8506037,
    interbank: 3347208,
    mortgages: 13178146,
    notes: 44188,
    bondnotes: 7560556,
    insurance: 22328283,
    monetarypaper: 26486,
    monetarygold: 11037,
    assets: 2934857,
    estates: 187208
  },
  "2011-09-01": {
    deposits: 13237087,
    fundshares: 8437553,
    self: 23796,
    equities: 9871889,
    funds: 3480404,
    foreign: 802891,
    shares: 3727090,
    domestic: 552545,
    equipment: 478300,
    structures: 973102,
    intellectual: 135960,
    otherpayables: 2382741,
    bonds: 4802300,
    treasury: 2457536,
    equityloan: 2338102,
    homeloan: 35984,
    pensions: 6993786,
    moneyfunds: 516773,
    paper: 710654,
    otherreceivables: 4830205,
    repurchaseshares: 4605207,
    repurchases: 8605465,
    interbank: 3262963,
    mortgages: 13098713,
    notes: 43472,
    bondnotes: 7567240,
    insurance: 21911510,
    monetarypaper: 26005,
    monetarygold: 11037,
    assets: 2876458,
    estates: 188192
  },
  "2011-12-01": {
    deposits: 13416797,
    fundshares: 8941397,
    self: 24701,
    equities: 10731626,
    funds: 3672444,
    foreign: 801564,
    shares: 4054202,
    domestic: 643327,
    equipment: 482422,
    structures: 981469,
    intellectual: 138451,
    otherpayables: 2258162,
    bonds: 4711808,
    treasury: 2459216,
    equityloan: 2378358,
    homeloan: 35542,
    pensions: 6831004,
    moneyfunds: 541283,
    paper: 701800,
    otherreceivables: 4459751,
    repurchaseshares: 4522965,
    repurchases: 8732963,
    interbank: 3166015,
    mortgages: 13036108,
    notes: 43079,
    bondnotes: 7559208,
    insurance: 22377977,
    monetarypaper: 26899,
    monetarygold: 11037,
    assets: 2941890,
    estates: 189545
  },
  "2012-03-01": {
    deposits: 13441931,
    fundshares: 9888562,
    self: 33349,
    equities: 12059455,
    funds: 3975346,
    foreign: 882597,
    shares: 4749776,
    domestic: 690911,
    equipment: 487345,
    structures: 989734,
    intellectual: 141501,
    otherpayables: 2393387,
    bonds: 4732317,
    treasury: 2849179,
    equityloan: 2743886,
    homeloan: 34708,
    pensions: 6587707,
    moneyfunds: 544727,
    paper: 688166,
    otherreceivables: 4555183,
    repurchaseshares: 4556338,
    repurchases: 8650079,
    interbank: 3163567,
    mortgages: 12961284,
    notes: 42432,
    bondnotes: 7515406,
    insurance: 22992316,
    monetarypaper: 27217,
    monetarygold: 11037,
    assets: 2862155,
    estates: 190708
  },
  "2012-06-01": {
    deposits: 13524942,
    fundshares: 9680764,
    self: 33731,
    equities: 11511141,
    funds: 3838305,
    foreign: 821985,
    shares: 4549556,
    domestic: 686612,
    equipment: 491138,
    structures: 996601,
    intellectual: 143964,
    otherpayables: 2314672,
    bonds: 4554135,
    treasury: 2816907,
    equityloan: 2725400,
    homeloan: 34073,
    pensions: 6758092,
    moneyfunds: 528589,
    paper: 649285,
    otherreceivables: 4614345,
    repurchaseshares: 4659768,
    repurchases: 8818961,
    interbank: 3101591,
    mortgages: 12891695,
    notes: 41630,
    bondnotes: 7523678,
    insurance: 22930023,
    monetarypaper: 27342,
    monetarygold: 11037,
    assets: 2785404,
    estates: 190467
  },
  "2012-09-01": {
    deposits: 13609535,
    fundshares: 10213795,
    self: 34279,
    equities: 12149011,
    funds: 4038099,
    foreign: 862876,
    shares: 4825263,
    domestic: 717537,
    equipment: 496338,
    structures: 994031,
    intellectual: 147040,
    otherpayables: 2269862,
    bonds: 4658287,
    treasury: 2936680,
    equityloan: 2857283,
    homeloan: 33594,
    pensions: 6683390,
    moneyfunds: 542893,
    paper: 639687,
    otherreceivables: 4475110,
    repurchaseshares: 4707998,
    repurchases: 9022317,
    interbank: 3085664,
    mortgages: 12842734,
    notes: 41256,
    bondnotes: 7525897,
    insurance: 23323559,
    monetarypaper: 27369,
    monetarygold: 11037,
    assets: 2858215,
    estates: 193083
  },
  "2012-12-01": {
    deposits: 13983364,
    fundshares: 10363508,
    self: 34097,
    equities: 12298434,
    funds: 4086092,
    foreign: 900197,
    shares: 4989183,
    domestic: 704790,
    equipment: 502045,
    structures: 985904,
    intellectual: 149754,
    otherpayables: 2135351,
    bonds: 4508287,
    treasury: 2976116,
    equityloan: 2943799,
    homeloan: 33535,
    pensions: 6684862,
    moneyfunds: 545935,
    paper: 622584,
    otherreceivables: 4358683,
    repurchaseshares: 4762639,
    repurchases: 9192398,
    interbank: 3175548,
    mortgages: 12820362,
    notes: 41249,
    bondnotes: 7535416,
    insurance: 23502957,
    monetarypaper: 27360,
    monetarygold: 11037,
    assets: 2996592,
    estates: 194387
  },
  "2013-03-01": {
    deposits: 14478539,
    fundshares: 11103241,
    self: 34829,
    equities: 13287860,
    funds: 4360194,
    foreign: 960252,
    shares: 5535780,
    domestic: 773953,
    equipment: 508104,
    structures: 981401,
    intellectual: 152484,
    otherpayables: 2277887,
    bonds: 4470640,
    treasury: 2992704,
    equityloan: 2940063,
    homeloan: 32618,
    pensions: 6836767,
    moneyfunds: 553301,
    paper: 636197,
    otherreceivables: 4464194,
    repurchaseshares: 4608486,
    repurchases: 8985999,
    interbank: 3523163,
    mortgages: 12771571,
    notes: 40360,
    bondnotes: 7571534,
    insurance: 24248007,
    monetarypaper: 27564,
    monetarygold: 11037,
    assets: 2922416,
    estates: 199539
  },
  "2013-06-01": {
    deposits: 15010503,
    fundshares: 11089666,
    self: 35312,
    equities: 13439558,
    funds: 4379278,
    foreign: 937216,
    shares: 5763121,
    domestic: 791793,
    equipment: 513457,
    structures: 986800,
    intellectual: 154722,
    otherpayables: 2158521,
    bonds: 4348870,
    treasury: 2968676,
    equityloan: 2957264,
    homeloan: 32680,
    pensions: 7312406,
    moneyfunds: 554679,
    paper: 575746,
    otherreceivables: 4466891,
    repurchaseshares: 4597389,
    repurchases: 9009682,
    interbank: 3686006,
    mortgages: 12773900,
    notes: 40636,
    bondnotes: 7640080,
    insurance: 24665502,
    monetarypaper: 27493,
    monetarygold: 11037,
    assets: 2901526,
    estates: 214490
  },
  "2013-09-01": {
    deposits: 15425008,
    fundshares: 11656492,
    self: 32930,
    equities: 14325790,
    funds: 4631055,
    foreign: 1021880,
    shares: 5974611,
    domestic: 820554,
    equipment: 519081,
    structures: 991783,
    intellectual: 156663,
    otherpayables: 2158999,
    bonds: 4277355,
    treasury: 2922577,
    equityloan: 2887398,
    homeloan: 32285,
    pensions: 7532611,
    moneyfunds: 606293,
    paper: 526814,
    otherreceivables: 4336728,
    repurchaseshares: 4556496,
    repurchases: 9172443,
    interbank: 4004687,
    mortgages: 12816244,
    notes: 40485,
    bondnotes: 7692692,
    insurance: 25378143,
    monetarypaper: 27441,
    monetarygold: 11037,
    assets: 3004474,
    estates: 203968
  },
  "2013-12-01": {
    deposits: 15769682,
    fundshares: 12333012,
    self: 36715,
    equities: 15543783,
    funds: 4908854,
    foreign: 974301,
    shares: 6457620,
    domestic: 815458,
    equipment: 523980,
    structures: 1004757,
    intellectual: 158745,
    otherpayables: 2006265,
    bonds: 4324022,
    treasury: 2980073,
    equityloan: 2975179,
    homeloan: 33375,
    pensions: 7482177,
    moneyfunds: 624603,
    paper: 541406,
    otherreceivables: 4302528,
    repurchaseshares: 4554763,
    repurchases: 9174673,
    interbank: 4010118,
    mortgages: 12818224,
    notes: 41723,
    bondnotes: 7773738,
    insurance: 26177259,
    monetarypaper: 27507,
    monetarygold: 11037,
    assets: 3047282,
    estates: 214251
  },
  "2014-03-01": {
    deposits: 16201864,
    fundshares: 12640668,
    self: 38164,
    equities: 15706575,
    funds: 4992520,
    foreign: 965060,
    shares: 6653923,
    domestic: 822432,
    equipment: 530058,
    structures: 1010021,
    intellectual: 160415,
    otherpayables: 2102825,
    bonds: 4304743,
    treasury: 3027691,
    equityloan: 3038251,
    homeloan: 31773,
    pensions: 7564400,
    moneyfunds: 593214,
    paper: 541674,
    otherreceivables: 4639116,
    repurchaseshares: 4610307,
    repurchases: 9159045,
    interbank: 4219976,
    mortgages: 12808611,
    notes: 40550,
    bondnotes: 7701107,
    insurance: 26412730,
    monetarypaper: 28051,
    monetarygold: 11037,
    assets: 2995782,
    estates: 217096
  },
  "2014-06-01": {
    deposits: 16401768,
    fundshares: 13148562,
    self: 39118,
    equities: 16360164,
    funds: 5165746,
    foreign: 990817,
    shares: 6874853,
    domestic: 846233,
    equipment: 537804,
    structures: 1014261,
    intellectual: 161422,
    otherpayables: 2168876,
    bonds: 4304948,
    treasury: 3091291,
    equityloan: 3095419,
    homeloan: 33268,
    pensions: 7480238,
    moneyfunds: 591738,
    paper: 525129,
    otherreceivables: 4635185,
    repurchaseshares: 4748303,
    repurchases: 9443128,
    interbank: 4237478,
    mortgages: 12841011,
    notes: 42080,
    bondnotes: 7754489,
    insurance: 26782104,
    monetarypaper: 28162,
    monetarygold: 11037,
    assets: 2920671,
    estates: 218733
  },
  "2014-09-01": {
    deposits: 16624346,
    fundshares: 12978937,
    self: 39924,
    equities: 16116350,
    funds: 5084576,
    foreign: 951609,
    shares: 6892599,
    domestic: 844030,
    equipment: 545091,
    structures: 1019688,
    intellectual: 163080,
    otherpayables: 2251101,
    bonds: 4321592,
    treasury: 3095374,
    equityloan: 3104145,
    homeloan: 33439,
    pensions: 7617477,
    moneyfunds: 593940,
    paper: 508952,
    otherreceivables: 4634720,
    repurchaseshares: 4680820,
    repurchases: 9505663,
    interbank: 4350694,
    mortgages: 12899987,
    notes: 42422,
    bondnotes: 7813703,
    insurance: 26845336,
    monetarypaper: 28190,
    monetarygold: 11037,
    assets: 2968844,
    estates: 221268
  },
  "2014-12-01": {
    deposits: 16704731,
    fundshares: 13150628,
    self: 40609,
    equities: 16521313,
    funds: 5160213,
    foreign: 1029730,
    shares: 7355828,
    domestic: 896964,
    equipment: 552748,
    structures: 1021438,
    intellectual: 164662,
    otherpayables: 2228162,
    bonds: 4333979,
    treasury: 3080503,
    equityloan: 3105961,
    homeloan: 33705,
    pensions: 7569890,
    moneyfunds: 631548,
    paper: 473731,
    otherreceivables: 4443400,
    repurchaseshares: 4743688,
    repurchases: 9764230,
    interbank: 4214490,
    mortgages: 12970079,
    notes: 42933,
    bondnotes: 7924009,
    insurance: 27125364,
    monetarypaper: 28572,
    monetarygold: 11037,
    assets: 3072970,
    estates: 231422
  },
  "2015-03-01": {
    deposits: 16906248,
    fundshares: 13519867,
    self: 40876,
    equities: 16903662,
    funds: 5268885,
    foreign: 1060157,
    shares: 7480319,
    domestic: 910872,
    equipment: 561082,
    structures: 1026489,
    intellectual: 166850,
    otherpayables: 2313324,
    bonds: 4304623,
    treasury: 3077426,
    equityloan: 3092166,
    homeloan: 32450,
    pensions: 7656292,
    moneyfunds: 608810,
    paper: 461700,
    otherreceivables: 4556837,
    repurchaseshares: 4730523,
    repurchases: 9793036,
    interbank: 4235853,
    mortgages: 12983590,
    notes: 41848,
    bondnotes: 7880805,
    insurance: 27374688,
    monetarypaper: 28822,
    monetarygold: 11037,
    assets: 3002355,
    estates: 235514
  },
  "2015-06-01": {
    deposits: 16836344,
    fundshares: 13533574,
    self: 40913,
    equities: 16961212,
    funds: 5245439,
    foreign: 1061095,
    shares: 7534341,
    domestic: 912014,
    equipment: 568525,
    structures: 1027011,
    intellectual: 168636,
    otherpayables: 2151064,
    bonds: 4306704,
    treasury: 3186714,
    equityloan: 3209876,
    homeloan: 33414,
    pensions: 7853774,
    moneyfunds: 585832,
    paper: 471316,
    otherreceivables: 4394231,
    repurchaseshares: 4725473,
    repurchases: 10073665,
    interbank: 3974070,
    mortgages: 13094170,
    notes: 42904,
    bondnotes: 7935226,
    insurance: 27479714,
    monetarypaper: 29133,
    monetarygold: 11037,
    assets: 2957534,
    estates: 238839
  },
  "2015-09-01": {
    deposits: 16834532,
    fundshares: 12605717,
    self: 44740,
    equities: 15565546,
    funds: 4892113,
    foreign: 965595,
    shares: 7042121,
    domestic: 855928,
    equipment: 575213,
    structures: 1035734,
    intellectual: 171343,
    otherpayables: 2158460,
    bonds: 4281730,
    treasury: 3226556,
    equityloan: 3249681,
    homeloan: 32653,
    pensions: 8274575,
    moneyfunds: 564327,
    paper: 440951,
    otherreceivables: 4427711,
    repurchaseshares: 4788652,
    repurchases: 10266154,
    interbank: 3902608,
    mortgages: 13199731,
    notes: 42297,
    bondnotes: 8022975,
    insurance: 27180065,
    monetarypaper: 29281,
    monetarygold: 11037,
    assets: 3015801,
    estates: 242427
  },
  "2015-12-01": {
    deposits: 16941824,
    fundshares: 12897215,
    self: 45364,
    equities: 16289888,
    funds: 5022247,
    foreign: 878477,
    shares: 7341301,
    domestic: 928685,
    equipment: 581986,
    structures: 1038876,
    intellectual: 173205,
    otherpayables: 2066887,
    bonds: 4056617,
    treasury: 3107545,
    equityloan: 3142464,
    homeloan: 34379,
    pensions: 8075803,
    moneyfunds: 566358,
    paper: 433193,
    otherreceivables: 4418816,
    repurchaseshares: 4754825,
    repurchases: 10034181,
    interbank: 3593642,
    mortgages: 13342713,
    notes: 44202,
    bondnotes: 8144448,
    insurance: 27529595,
    monetarypaper: 29451,
    monetarygold: 11037,
    assets: 3078204,
    estates: 235436
  },
  "2016-03-01": {
    deposits: 17303847,
    fundshares: 12962099,
    self: 46473,
    equities: 16157368,
    funds: 5061559,
    foreign: 888324,
    shares: 7170009,
    domestic: 1001203,
    equipment: 588767,
    structures: 1037201,
    intellectual: 176766,
    otherpayables: 2227470,
    bonds: 4063907,
    treasury: 3193496,
    equityloan: 3219540,
    homeloan: 32737,
    pensions: 8185231,
    moneyfunds: 598158,
    paper: 489214,
    otherreceivables: 4655786,
    repurchaseshares: 4614529,
    repurchases: 9875538,
    interbank: 3784236,
    mortgages: 13396494,
    notes: 42738,
    bondnotes: 8153951,
    insurance: 27773975,
    monetarypaper: 30078,
    monetarygold: 11037,
    assets: 3064466,
    estates: 241120
  },
  "2016-06-01": {
    deposits: 17402335,
    fundshares: 13208765,
    self: 46253,
    equities: 16315771,
    funds: 5152806,
    foreign: 887936,
    shares: 7386854,
    domestic: 1026554,
    equipment: 594751,
    structures: 1047157,
    intellectual: 180752,
    otherpayables: 2422690,
    bonds: 4018127,
    treasury: 3176740,
    equityloan: 3188246,
    homeloan: 34941,
    pensions: 8236875,
    moneyfunds: 608406,
    paper: 494169,
    otherreceivables: 4753282,
    repurchaseshares: 4743637,
    repurchases: 10194853,
    interbank: 3689640,
    mortgages: 13518509,
    notes: 45360,
    bondnotes: 8299487,
    insurance: 28079662,
    monetarypaper: 30113,
    monetarygold: 11037,
    assets: 2969440,
    estates: 237602
  },
  "2016-09-01": {
    deposits: 17393119,
    fundshares: 13682571,
    self: 62674,
    equities: 16963057,
    funds: 5314825,
    foreign: 939388,
    shares: 7737058,
    domestic: 1064978,
    equipment: 600911,
    structures: 1049642,
    intellectual: 184821,
    otherpayables: 2157168,
    bonds: 4106055,
    treasury: 3388420,
    equityloan: 3481608,
    homeloan: 35362,
    pensions: 8232052,
    moneyfunds: 649941,
    paper: 455830,
    otherreceivables: 4669514,
    repurchaseshares: 4862128,
    repurchases: 10531812,
    interbank: 3327772,
    mortgages: 13684297,
    notes: 46010,
    bondnotes: 8390329,
    insurance: 28474658,
    monetarypaper: 30176,
    monetarygold: 11037,
    assets: 2943898,
    estates: 232231
  },
  "2016-12-01": {
    deposits: 17401539,
    fundshares: 13615579,
    self: 60967,
    equities: 17303395,
    funds: 5286365,
    foreign: 948826,
    shares: 8368735,
    domestic: 1003879,
    equipment: 607235,
    structures: 1059714,
    intellectual: 190191,
    otherpayables: 2082362,
    bonds: 4019919,
    treasury: 3340244,
    equityloan: 3443250,
    homeloan: 36286,
    pensions: 8292736,
    moneyfunds: 705622,
    paper: 433992,
    otherreceivables: 4447883,
    repurchaseshares: 4814811,
    repurchases: 10427269,
    interbank: 3074664,
    mortgages: 13808232,
    notes: 46948,
    bondnotes: 8496125,
    insurance: 28623314,
    monetarypaper: 30443,
    monetarygold: 11037,
    assets: 2955224,
    estates: 236469
  },
  "2017-03-01": {
    deposits: 17928115,
    fundshares: 14327132,
    self: 62566,
    equities: 18295453,
    funds: 5522812,
    foreign: 1023326,
    shares: 8735387,
    domestic: 1035345,
    equipment: 610976,
    structures: 1071538,
    intellectual: 193514,
    otherpayables: 2291958,
    bonds: 3976480,
    treasury: 3240129,
    equityloan: 3342708,
    homeloan: 35012,
    pensions: 8268333,
    moneyfunds: 734632,
    paper: 437644,
    otherreceivables: 4481978,
    repurchaseshares: 4722726,
    repurchases: 10546685,
    interbank: 3559909,
    mortgages: 13904403,
    notes: 45996,
    bondnotes: 8547328,
    insurance: 29125964,
    monetarypaper: 30595,
    monetarygold: 11037,
    assets: 2926657,
    estates: 237785
  },
  "2017-06-01": {
    deposits: 17840550,
    fundshares: 14795306,
    self: 61798,
    equities: 18926318,
    funds: 5665356,
    foreign: 1052181,
    shares: 9072990,
    domestic: 1086596,
    equipment: 612666,
    structures: 1083224,
    intellectual: 198271,
    otherpayables: 2382418,
    bonds: 4035150,
    treasury: 3205128,
    equityloan: 3303520,
    homeloan: 36883,
    pensions: 8318205,
    moneyfunds: 726507,
    paper: 418373,
    otherreceivables: 4568061,
    repurchaseshares: 4953237,
    repurchases: 10972908,
    interbank: 3407870,
    mortgages: 14046898,
    notes: 48000,
    bondnotes: 8642591,
    insurance: 29509616,
    monetarypaper: 30843,
    monetarygold: 11037,
    assets: 2892645,
    estates: 238557
  },
  "2017-09-01": {
    deposits: 18089005,
    fundshares: 15346982,
    self: 63022,
    equities: 19766872,
    funds: 5845859,
    foreign: 1096734,
    shares: 9484537,
    domestic: 1130836,
    equipment: 614873,
    structures: 1094317,
    intellectual: 201701,
    otherpayables: 2441653,
    bonds: 4068209,
    treasury: 3237237,
    equityloan: 3346314,
    homeloan: 37007,
    pensions: 8134894,
    moneyfunds: 769577,
    paper: 407415,
    otherreceivables: 4550069,
    repurchaseshares: 4820664,
    repurchases: 10855370,
    interbank: 3548271,
    mortgages: 14184980,
    notes: 50339,
    bondnotes: 8734496,
    insurance: 29987030,
    monetarypaper: 31188,
    monetarygold: 11037,
    assets: 3029916,
    estates: 237365
  },
  "2017-12-01": {
    deposits: 18194360,
    fundshares: 15898985,
    self: 61824,
    equities: 20789267,
    funds: 6030801,
    foreign: 1145865,
    shares: 10054234,
    domestic: 1185629,
    equipment: 618634,
    structures: 1096567,
    intellectual: 206118,
    otherpayables: 2390560,
    bonds: 4036423,
    treasury: 3224457,
    equityloan: 3341746,
    homeloan: 37657,
    pensions: 7983333,
    moneyfunds: 752940,
    paper: 397226,
    otherreceivables: 4393113,
    repurchaseshares: 4872334,
    repurchases: 11267793,
    interbank: 3383431,
    mortgages: 14364630,
    notes: 51148,
    bondnotes: 8842725,
    insurance: 30455157,
    monetarypaper: 31389,
    monetarygold: 11037,
    assets: 3120533,
    estates: 242044
  },
  "2018-03-01": {
    deposits: 18692981,
    fundshares: 15874256,
    self: 66771,
    equities: 20621648,
    funds: 5971373,
    foreign: 1123268,
    shares: 9970481,
    domestic: 1153881,
    equipment: 623404,
    structures: 1104124,
    intellectual: 210179,
    otherpayables: 2470186,
    bonds: 4021688,
    treasury: 3279143,
    equityloan: 3382718,
    homeloan: 37285,
    pensions: 8145534,
    moneyfunds: 749587,
    paper: 415038,
    otherreceivables: 4461449,
    repurchaseshares: 4512941,
    repurchases: 10896282,
    interbank: 3478780,
    mortgages: 14464294,
    notes: 50702,
    bondnotes: 8847149,
    insurance: 30525182,
    monetarypaper: 31667,
    monetarygold: 11037,
    assets: 3067973,
    estates: 238568
  },
  "2018-06-01": {
    deposits: 18573818,
    fundshares: 16077205,
    self: 68870,
    equities: 20980812,
    funds: 6043261,
    foreign: 1109886,
    shares: 10096017,
    domestic: 1164724,
    equipment: 629078,
    structures: 1118815,
    intellectual: 215390,
    otherpayables: 2483418,
    bonds: 4015563,
    treasury: 3278948,
    equityloan: 3357725,
    homeloan: 38670,
    pensions: 8218843,
    moneyfunds: 739540,
    paper: 434722,
    otherreceivables: 4491013,
    repurchaseshares: 4644568,
    repurchases: 11187289,
    interbank: 3270955,
    mortgages: 14628095,
    notes: 52281,
    bondnotes: 8936344,
    insurance: 30809759,
    monetarypaper: 32076,
    monetarygold: 11037,
    assets: 3092120,
    estates: 236869
  },
  "2018-09-01": {
    deposits: 18545891,
    fundshares: 16559687,
    self: 70855,
    equities: 21917342,
    funds: 6194367,
    foreign: 1125502,
    shares: 10497376,
    domestic: 1224268,
    equipment: 636810,
    structures: 1133375,
    intellectual: 219862,
    otherpayables: 2517299,
    bonds: 4048923,
    treasury: 3207494,
    equityloan: 3315094,
    homeloan: 37415,
    pensions: 8187519,
    moneyfunds: 797325,
    paper: 429358,
    otherreceivables: 4464941,
    repurchaseshares: 4596937,
    repurchases: 11277897,
    interbank: 3172176,
    mortgages: 14772433,
    notes: 51104,
    bondnotes: 8998517,
    insurance: 31276838,
    monetarypaper: 32276,
    monetarygold: 11037,
    assets: 3143468,
    estates: 229426
  },
  "2018-12-01": {
    deposits: 18688678,
    fundshares: 14669831,
    self: 70407,
    equities: 18814100,
    funds: 5519163,
    foreign: 958903,
    shares: 9272131,
    domestic: 1059120,
    equipment: 641922,
    structures: 1158664,
    intellectual: 223842,
    otherpayables: 2336500,
    bonds: 4063850,
    treasury: 3259872,
    equityloan: 3354690,
    homeloan: 38498,
    pensions: 8737179,
    moneyfunds: 765526,
    paper: 431158,
    otherreceivables: 4398065,
    repurchaseshares: 4893584,
    repurchases: 11869006,
    interbank: 2975841,
    mortgages: 14913749,
    notes: 52232,
    bondnotes: 9089623,
    insurance: 30468736,
    monetarypaper: 32335,
    monetarygold: 11037,
    assets: 3289687,
    estates: 226228
  },
  "2019-03-01": {
    deposits: 18744524,
    fundshares: 16179620,
    self: 70564,
    equities: 20751235,
    funds: 5885109,
    foreign: 1062197,
    shares: 10155547,
    domestic: 1180433,
    equipment: 649382,
    structures: 1174958,
    intellectual: 231086,
    otherpayables: 2597195,
    bonds: 4094981,
    treasury: 3337566,
    equityloan: 3409628,
    homeloan: 35785,
    pensions: 8537387,
    moneyfunds: 824200,
    paper: 442451,
    otherreceivables: 4871009,
    repurchaseshares: 4782938,
    repurchases: 12018030,
    interbank: 2984979,
    mortgages: 15002827,
    notes: 49578,
    bondnotes: 9123412,
    insurance: 31451753,
    monetarypaper: 32522,
    monetarygold: 11037,
    assets: 3482976,
    estates: 220531
  },
  "2019-06-01": {
    deposits: 18628567,
    fundshares: 16669576,
    self: 70556,
    equities: 21232104,
    funds: 5999515,
    foreign: 1090648,
    shares: 10644589,
    domestic: 1224788,
    equipment: 654693,
    structures: 1192271,
    intellectual: 239011,
    otherpayables: 2616691,
    bonds: 4155621,
    treasury: 3392053,
    equityloan: 3459169,
    homeloan: 36661,
    pensions: 8613648,
    moneyfunds: 956217,
    paper: 439956,
    otherreceivables: 5098988,
    repurchaseshares: 5027461,
    repurchases: 12435255,
    interbank: 2918778,
    mortgages: 15150409,
    notes: 50560,
    bondnotes: 9242028,
    insurance: 31928417,
    monetarypaper: 32395,
    monetarygold: 11037,
    assets: 3600902,
    estates: 219730
  },
  "2019-09-01": {
    deposits: 19014577,
    fundshares: 16694746,
    self: 72534,
    equities: 21256688,
    funds: 6073732,
    foreign: 1093631,
    shares: 10725196,
    domestic: 1229261,
    equipment: 657321,
    structures: 1203336,
    intellectual: 246270,
    otherpayables: 2638004,
    bonds: 4187235,
    treasury: 3384445,
    equityloan: 3447920,
    homeloan: 35195,
    pensions: 8538680,
    moneyfunds: 902242,
    paper: 402418,
    otherreceivables: 5049435,
    repurchaseshares: 5153860,
    repurchases: 12591933,
    interbank: 2934728,
    mortgages: 15346443,
    notes: 49208,
    bondnotes: 9321770,
    insurance: 32184787,
    monetarypaper: 32488,
    monetarygold: 11037,
    assets: 3834010,
    estates: 220274
  },
  "2019-12-01": {
    deposits: 19555155,
    fundshares: 17659517,
    self: 0,
    equities: 22827949,
    funds: 6379423,
    foreign: 1213911,
    shares: 11485509,
    domestic: 1221096,
    equipment: 660242,
    structures: 1213202,
    intellectual: 251075,
    otherpayables: 2627211,
    bonds: 4170426,
    treasury: 3376456,
    equityloan: 3471842,
    homeloan: 34495,
    pensions: 8343674,
    moneyfunds: 911609,
    paper: 397928,
    otherreceivables: 5158360,
    repurchaseshares: 5044964,
    repurchases: 12663346,
    interbank: 3049797,
    mortgages: 15491215,
    notes: 48565,
    bondnotes: 9408258,
    insurance: 32862174,
    monetarypaper: 31698,
    monetarygold: 11037,
    assets: 4002466,
    estates: 224312
  },
  "2020-03-01": {
    deposits: 22371752,
    fundshares: 14543146,
    self: 0,
    equities: 17894393,
    funds: 5250979,
    foreign: 955324,
    shares: 8676676,
    domestic: 997007,
    equipment: 663001,
    structures: 1224037,
    intellectual: 256957,
    otherpayables: 2745754,
    bonds: 4422437,
    treasury: 3390340,
    equityloan: 3496049,
    homeloan: 41037,
    pensions: 9304599,
    moneyfunds: 1053819,
    paper: 422031,
    otherreceivables: 5654899,
    repurchaseshares: 5898874,
    repurchases: 14258081,
    interbank: 4371398,
    mortgages: 15623925,
    notes: 55046,
    bondnotes: 9747918,
    insurance: 31599010,
    monetarypaper: 31966,
    monetarygold: 11037,
    assets: 4727179,
    estates: 225079
  },
  "2020-06-01": {
    deposits: 25088044,
    fundshares: 16776147,
    self: 0,
    equities: 21307725,
    funds: 6022081,
    foreign: 1112221,
    shares: 10019295,
    domestic: 1184079,
    equipment: 661425,
    structures: 1225464,
    intellectual: 263621,
    otherpayables: 2902831,
    bonds: 4437501,
    treasury: 3372207,
    equityloan: 3457138,
    homeloan: 32085,
    pensions: 8853446,
    moneyfunds: 1147717,
    paper: 403134,
    otherreceivables: 5655614,
    repurchaseshares: 4934731,
    repurchases: 13091622,
    interbank: 4654708,
    mortgages: 15765529,
    notes: 46187,
    bondnotes: 9722709,
    insurance: 32994293,
    monetarypaper: 32025,
    monetarygold: 11037,
    assets: 5087590,
    estates: 223779
  },
  "2020-09-01": {
    deposits: 25277453,
    fundshares: 17749306,
    self: 0,
    equities: 22845479,
    funds: 6326114,
    foreign: 1168195,
    shares: 10548629,
    domestic: 1263347,
    equipment: 659287,
    structures: 1231426,
    intellectual: 269624,
    otherpayables: 2927598,
    bonds: 4372160,
    treasury: 3380856,
    equityloan: 3478267,
    homeloan: 29669,
    pensions: 8688868,
    moneyfunds: 1104855,
    paper: 378739,
    otherreceivables: 5707493,
    repurchaseshares: 4839416,
    repurchases: 12655038,
    interbank: 4494861,
    mortgages: 15988736,
    notes: 43948,
    bondnotes: 9846036,
    insurance: 33741857,
    monetarypaper: 32392,
    monetarygold: 11037,
    assets: 4846839,
    estates: 225804
  },
  "2020-12-01": {
    deposits: 26150563,
    fundshares: 19562556,
    self: 0,
    equities: 26001660,
    funds: 6965988,
    foreign: 1355387,
    shares: 12409780,
    domestic: 1443567,
    equipment: 657481,
    structures: 1235530,
    intellectual: 277403,
    otherpayables: 2976741,
    bonds: 4416357,
    treasury: 3460131,
    equityloan: 3584115,
    homeloan: 27398,
    pensions: 8120569,
    moneyfunds: 1130941,
    paper: 390887,
    otherreceivables: 5924119,
    repurchaseshares: 4887303,
    repurchases: 12828083,
    interbank: 4757013,
    mortgages: 16212824,
    notes: 41772,
    bondnotes: 10066466,
    insurance: 34934515,
    monetarypaper: 32376,
    monetarygold: 11037,
    assets: 4765920,
    estates: 226455
  },
  "2021-03-01": {
    deposits: 27160646,
    fundshares: 20271300,
    self: 0,
    equities: 27527130,
    funds: 7141184,
    foreign: 1452742,
    shares: 13817537,
    domestic: 0,
    equipment: 661628,
    structures: 1248860,
    intellectual: 280834,
    otherpayables: 3319846,
    bonds: 4390421,
    treasury: 3536606,
    equityloan: 3642945,
    homeloan: 26669,
    pensions: 8067403,
    moneyfunds: 1176610,
    paper: 455204,
    otherreceivables: 6027095,
    repurchaseshares: 4892792,
    repurchases: 12675419,
    interbank: 5507566,
    mortgages: 16391978,
    notes: 41209,
    bondnotes: 10206533,
    insurance: 35360917,
    monetarypaper: 32469,
    monetarygold: 11037,
    assets: 4979998,
    estates: 220848
  },
  "2021-06-01": {
    deposits: 26886155,
    fundshares: 21459746,
    self: 0,
    equities: 29505440,
    funds: 7516104,
    foreign: 1576590,
    shares: 15200749,
    domestic: 0,
    equipment: 656530,
    structures: 1277198,
    intellectual: 289639,
    otherpayables: 3271427,
    bonds: 4533597,
    treasury: 3646092,
    equityloan: 3739869,
    homeloan: 25806,
    pensions: 7744135,
    moneyfunds: 1182229,
    paper: 429200,
    otherreceivables: 6202604,
    repurchaseshares: 5763595,
    repurchases: 13266813,
    interbank: 5257527,
    mortgages: 16698902,
    notes: 40875,
    bondnotes: 10387647,
    insurance: 36195378,
    monetarypaper: 32819,
    monetarygold: 11037,
    assets: 5015397,
    estates: 219180
  },
  "2021-09-01": {
    deposits: 27258391,
    fundshares: 21374931,
    self: 0,
    equities: 29218044,
    funds: 7428377,
    foreign: 1572868,
    shares: 15326080,
    domestic: 0,
    equipment: 663194,
    structures: 1312282,
    intellectual: 297320,
    otherpayables: 3373728,
    bonds: 4542151,
    treasury: 3684473,
    equityloan: 3775336,
    homeloan: 24814,
    pensions: 7891966,
    moneyfunds: 1217535,
    paper: 394045,
    otherreceivables: 6433828,
    repurchaseshares: 6442509,
    repurchases: 14002138,
    interbank: 5642858,
    mortgages: 17029099,
    notes: 40012,
    bondnotes: 10516398,
    insurance: 36349597,
    monetarypaper: 33089,
    monetarygold: 11037,
    assets: 5019225,
    estates: 210336
  },
  "2021-12-01": {
    deposits: 27881651,
    fundshares: 22208748,
    self: 0,
    equities: 31120757,
    funds: 7700004,
    foreign: 1658413,
    shares: 16239945,
    domestic: 0,
    equipment: 673091,
    structures: 1386114,
    intellectual: 304912,
    otherpayables: 3343526,
    bonds: 4651911,
    treasury: 3692375,
    equityloan: 3794961,
    homeloan: 23900,
    pensions: 7525029,
    moneyfunds: 1268246,
    paper: 390461,
    otherreceivables: 6544427,
    repurchaseshares: 6762377,
    repurchases: 14384066,
    interbank: 5387843,
    mortgages: 17405408,
    notes: 39704,
    bondnotes: 10646356,
    insurance: 37168170,
    monetarypaper: 33877,
    monetarygold: 11037,
    assets: 5205455,
    estates: 205671
  }
};
