import React from "react";
const sale = {
  allmanufacturing: {
    "2000-10-01": 1128790,
    "2001-01-01": 1082233,
    "2001-04-01": 1116597,
    "2001-07-01": 1062443,
    "2001-10-01": 1033734,
    "2002-01-01": 994073,
    "2002-04-01": 1071574,
    "2002-07-01": 1068667,
    "2002-10-01": 1082128,
    "2003-01-01": 1072017,
    "2003-04-01": 1096910,
    "2003-07-01": 1109436,
    "2003-10-01": 1118800,
    "2004-01-01": 1145872,
    "2004-04-01": 1248717,
    "2004-07-01": 1250976,
    "2004-10-01": 1288495,
    "2005-01-01": 1258442,
    "2005-04-01": 1352179,
    "2005-07-01": 1384246,
    "2005-10-01": 1416600,
    "2006-01-01": 1397414,
    "2006-04-01": 1485624,
    "2006-07-01": 1467143,
    "2006-10-01": 1432485,
    "2007-01-01": 1405836,
    "2007-04-01": 1526484,
    "2007-07-01": 1539399,
    "2007-10-01": 1588312,
    "2008-01-01": 1566418,
    "2008-04-01": 1724172,
    "2008-07-01": 1682270,
    "2008-10-01": 1401261,
    "2009-01-01": 1196693,
    "2009-04-01": 1253821,
    "2009-07-01": 1305867,
    "2009-10-01": 1353446,
    "2010-01-01": 1349210,
    "2010-04-01": 1461717,
    "2010-07-01": 1463507,
    "2010-10-01": 1481530,
    "2011-01-01": 1532484,
    "2011-04-01": 1657405,
    "2011-07-01": 1650561,
    "2011-10-01": 1645072,
    "2012-01-01": 1648177,
    "2012-04-01": 1689523,
    "2012-07-01": 1656755,
    "2012-10-01": 1673633,
    "2013-01-01": 1626424,
    "2013-04-01": 1703610,
    "2013-07-01": 1704177,
    "2013-10-01": 1709153,
    "2014-01-01": 1648649,
    "2014-04-01": 1769399,
    "2014-07-01": 1768117,
    "2014-10-01": 1716528,
    "2015-01-01": 1551422,
    "2015-04-01": 1669117,
    "2015-07-01": 1630470,
    "2015-10-01": 1581670,
    "2016-01-01": 1481839,
    "2016-04-01": 1590303,
    "2016-07-01": 1581096,
    "2016-10-01": 1595763,
    "2017-01-01": 1567401,
    "2017-04-01": 1644161,
    "2017-07-01": 1645179,
    "2017-10-01": 1695735,
    "2018-01-01": 1656885,
    "2018-04-01": 1768956,
    "2018-07-01": 1783152,
    "2018-10-01": 1761615,
    "2019-01-01": 1641870,
    "2019-04-01": 1747455,
    "2019-07-01": 1740864,
    "2019-10-01": 1705773,
    "2020-01-01": 1571108,
    "2020-04-01": 1321976,
    "2020-07-01": 1547408,
    "2020-10-01": 1632771,
    "2021-01-01": 1648655,
    "2021-04-01": 1812131,
    "2021-07-01": 1849951
  },
  perishablesall: {
    "2000-10-01": 505782,
    "2001-01-01": 490619,
    "2001-04-01": 515964,
    "2001-07-01": 495006,
    "2001-10-01": 472207,
    "2002-01-01": 447635,
    "2002-04-01": 488142,
    "2002-07-01": 503836,
    "2002-10-01": 516223,
    "2003-01-01": 523697,
    "2003-04-01": 524023,
    "2003-07-01": 539785,
    "2003-10-01": 526972,
    "2004-01-01": 552259,
    "2004-04-01": 604129,
    "2004-07-01": 612041,
    "2004-10-01": 628295,
    "2005-01-01": 615989,
    "2005-04-01": 660042,
    "2005-07-01": 700095,
    "2005-10-01": 704840,
    "2006-01-01": 695274,
    "2006-04-01": 737595,
    "2006-07-01": 737771,
    "2006-10-01": 701861,
    "2007-01-01": 690003,
    "2007-04-01": 765699,
    "2007-07-01": 772207,
    "2007-10-01": 816470,
    "2008-01-01": 825927,
    "2008-04-01": 943742,
    "2008-07-01": 924392,
    "2008-10-01": 710514,
    "2009-01-01": 612610,
    "2009-04-01": 661523,
    "2009-07-01": 692629,
    "2009-10-01": 716193,
    "2010-01-01": 723995,
    "2010-04-01": 772923,
    "2010-07-01": 767183,
    "2010-10-01": 784206,
    "2011-01-01": 837551,
    "2011-04-01": 924707,
    "2011-07-01": 907154,
    "2011-10-01": 888790,
    "2012-01-01": 893182,
    "2012-04-01": 898023,
    "2012-07-01": 884390,
    "2012-10-01": 890962,
    "2013-01-01": 875337,
    "2013-04-01": 897069,
    "2013-07-01": 904141,
    "2013-10-01": 888992,
    "2014-01-01": 867058,
    "2014-04-01": 928961,
    "2014-07-01": 914400,
    "2014-10-01": 850684,
    "2015-01-01": 742042,
    "2015-04-01": 810374,
    "2015-07-01": 779158,
    "2015-10-01": 733890,
    "2016-01-01": 682042,
    "2016-04-01": 744927,
    "2016-07-01": 747201,
    "2016-10-01": 754912,
    "2017-01-01": 754269,
    "2017-04-01": 787885,
    "2017-07-01": 791220,
    "2017-10-01": 815721,
    "2018-01-01": 799020,
    "2018-04-01": 852856,
    "2018-07-01": 865732,
    "2018-10-01": 841674,
    "2019-01-01": 783237,
    "2019-04-01": 851655,
    "2019-07-01": 829622,
    "2019-10-01": 815811,
    "2020-01-01": 762408,
    "2020-04-01": 643691,
    "2020-07-01": 709272,
    "2020-10-01": 738523,
    "2021-01-01": 783047,
    "2021-04-01": 881708,
    "2021-07-01": 931328
  },
  durablesall: {
    "2000-10-01": 623008,
    "2001-01-01": 591615,
    "2001-04-01": 600633,
    "2001-07-01": 567438,
    "2001-10-01": 561527,
    "2002-01-01": 546438,
    "2002-04-01": 583433,
    "2002-07-01": 564832,
    "2002-10-01": 565905,
    "2003-01-01": 548320,
    "2003-04-01": 572887,
    "2003-07-01": 569650,
    "2003-10-01": 591828,
    "2004-01-01": 593612,
    "2004-04-01": 644588,
    "2004-07-01": 638934,
    "2004-10-01": 660200,
    "2005-01-01": 642453,
    "2005-04-01": 692137,
    "2005-07-01": 684151,
    "2005-10-01": 711760,
    "2006-01-01": 702140,
    "2006-04-01": 748029,
    "2006-07-01": 729372,
    "2006-10-01": 730623,
    "2007-01-01": 715833,
    "2007-04-01": 760785,
    "2007-07-01": 767192,
    "2007-10-01": 771842,
    "2008-01-01": 740491,
    "2008-04-01": 780430,
    "2008-07-01": 757878,
    "2008-10-01": 690747,
    "2009-01-01": 584083,
    "2009-04-01": 592298,
    "2009-07-01": 613238,
    "2009-10-01": 637253,
    "2010-01-01": 625215,
    "2010-04-01": 688794,
    "2010-07-01": 696324,
    "2010-10-01": 697324,
    "2011-01-01": 694933,
    "2011-04-01": 732698,
    "2011-07-01": 743407,
    "2011-10-01": 756282,
    "2012-01-01": 754995,
    "2012-04-01": 791500,
    "2012-07-01": 772365,
    "2012-10-01": 782672,
    "2013-01-01": 751086,
    "2013-04-01": 806541,
    "2013-07-01": 800036,
    "2013-10-01": 820161,
    "2014-01-01": 781591,
    "2014-04-01": 840439,
    "2014-07-01": 853717,
    "2014-10-01": 865843,
    "2015-01-01": 809380,
    "2015-04-01": 858742,
    "2015-07-01": 851312,
    "2015-10-01": 847779,
    "2016-01-01": 799797,
    "2016-04-01": 845376,
    "2016-07-01": 833895,
    "2016-10-01": 840851,
    "2017-01-01": 813132,
    "2017-04-01": 856276,
    "2017-07-01": 853959,
    "2017-10-01": 880014,
    "2018-01-01": 857865,
    "2018-04-01": 916100,
    "2018-07-01": 917420,
    "2018-10-01": 919941,
    "2019-01-01": 858633,
    "2019-04-01": 895800,
    "2019-07-01": 911242,
    "2019-10-01": 889962,
    "2020-01-01": 808700,
    "2020-04-01": 678285,
    "2020-07-01": 838137,
    "2020-10-01": 894248,
    "2021-01-01": 865607,
    "2021-04-01": 930423,
    "2021-07-01": 918623
  },
  otherretail: {
    "2000-10-01": 116769,
    "2001-01-01": 116170,
    "2001-04-01": 118728,
    "2001-07-01": 118468,
    "2001-10-01": 126162,
    "2002-01-01": 123932,
    "2002-04-01": 130320,
    "2002-07-01": 130478,
    "2002-10-01": 133327,
    "2003-01-01": 139063,
    "2003-04-01": 140626,
    "2003-07-01": 153944,
    "2003-10-01": 159779,
    "2004-01-01": 159032,
    "2004-04-01": 162637,
    "2004-07-01": 166565,
    "2004-10-01": 181176,
    "2005-01-01": 181192,
    "2005-04-01": 190819,
    "2005-07-01": 195649,
    "2005-10-01": 206787,
    "2006-01-01": 212881,
    "2006-04-01": 223483,
    "2006-07-01": 223526,
    "2006-10-01": 227602,
    "2007-01-01": 230766,
    "2007-04-01": 234531,
    "2007-07-01": 235039,
    "2007-10-01": 241348,
    "2008-01-01": 236384,
    "2008-04-01": 248136,
    "2008-07-01": 239919,
    "2008-10-01": 223846,
    "2009-01-01": 214256,
    "2009-04-01": 222336,
    "2009-07-01": 222796,
    "2009-10-01": 237309,
    "2010-01-01": 239303,
    "2010-04-01": 245960,
    "2010-07-01": 245127,
    "2010-10-01": 258609,
    "2011-01-01": 261666,
    "2011-04-01": 280585,
    "2011-07-01": 277791,
    "2011-10-01": 280918,
    "2012-01-01": 281295,
    "2012-04-01": 284072,
    "2012-07-01": 280484,
    "2012-10-01": 297261,
    "2013-01-01": 287991,
    "2013-04-01": 304129,
    "2013-07-01": 306693,
    "2013-10-01": 320892,
    "2014-01-01": 302885,
    "2014-04-01": 325170,
    "2014-07-01": 322636,
    "2014-10-01": 337259,
    "2015-01-01": 310095,
    "2015-04-01": 332497,
    "2015-07-01": 336774,
    "2015-10-01": 346569,
    "2016-01-01": 329662,
    "2016-04-01": 355098,
    "2016-07-01": 357035,
    "2016-10-01": 371988,
    "2017-01-01": 351624,
    "2017-04-01": 370736,
    "2017-07-01": 378407,
    "2017-10-01": 402458,
    "2018-01-01": 381081,
    "2018-04-01": 406565,
    "2018-07-01": 403050,
    "2018-10-01": 423072,
    "2019-01-01": 386260,
    "2019-04-01": 416819,
    "2019-07-01": 413268,
    "2019-10-01": 434788,
    "2020-01-01": 401134,
    "2020-04-01": 427771,
    "2020-07-01": 455556,
    "2020-10-01": 483041,
    "2021-01-01": 478621,
    "2021-04-01": 539461,
    "2021-07-01": 531388
  },
  wholesalePerishable: {
    "2000-10-01": 169797,
    "2001-01-01": 165805,
    "2001-04-01": 166054,
    "2001-07-01": 161843,
    "2001-10-01": 155505,
    "2002-01-01": 151982,
    "2002-04-01": 163273,
    "2002-07-01": 157223,
    "2002-10-01": 173330,
    "2003-01-01": 178888,
    "2003-04-01": 180109,
    "2003-07-01": 179496,
    "2003-10-01": 183824,
    "2004-01-01": 182128,
    "2004-04-01": 193346,
    "2004-07-01": 195303,
    "2004-10-01": 205342,
    "2005-01-01": 199498,
    "2005-04-01": 210683,
    "2005-07-01": 217888,
    "2005-10-01": 208791,
    "2006-01-01": 208320,
    "2006-04-01": 223259,
    "2006-07-01": 225409,
    "2006-10-01": 219348,
    "2007-01-01": 217704,
    "2007-04-01": 231331,
    "2007-07-01": 239378,
    "2007-10-01": 255975,
    "2008-01-01": 253716,
    "2008-04-01": 283648,
    "2008-07-01": 284396,
    "2008-10-01": 249329,
    "2009-01-01": 218660,
    "2009-04-01": 232290,
    "2009-07-01": 241013,
    "2009-10-01": 250533,
    "2010-01-01": 246614,
    "2010-04-01": 255221,
    "2010-07-01": 256689,
    "2010-10-01": 275733,
    "2011-01-01": 284712,
    "2011-04-01": 303135,
    "2011-07-01": 297088,
    "2011-10-01": 297358,
    "2012-01-01": 300902,
    "2012-04-01": 308911,
    "2012-07-01": 304262,
    "2012-10-01": 316140,
    "2013-01-01": 308968,
    "2013-04-01": 325864,
    "2013-07-01": 322995,
    "2013-10-01": 335420,
    "2014-01-01": 328568,
    "2014-04-01": 346625,
    "2014-07-01": 353046,
    "2014-10-01": 350754,
    "2015-01-01": 321477,
    "2015-04-01": 340771,
    "2015-07-01": 336242,
    "2015-10-01": 335380,
    "2016-01-01": 312038,
    "2016-04-01": 336928,
    "2016-07-01": 337297,
    "2016-10-01": 356111,
    "2017-01-01": 351937,
    "2017-04-01": 368415,
    "2017-07-01": 364781,
    "2017-10-01": 374779,
    "2018-01-01": 368116,
    "2018-04-01": 388077,
    "2018-07-01": 382183,
    "2018-10-01": 382553,
    "2019-01-01": 351802,
    "2019-04-01": 378827,
    "2019-07-01": 379092,
    "2019-10-01": 391919,
    "2020-01-01": 368004,
    "2020-04-01": 332311,
    "2020-07-01": 363003,
    "2020-10-01": 393312,
    "2021-01-01": 399339,
    "2021-04-01": 448371,
    "2021-07-01": 463597
  },
  wholesaleDurable: {
    "2000-10-01": 160529,
    "2001-01-01": 149962,
    "2001-04-01": 146641,
    "2001-07-01": 143345,
    "2001-10-01": 145041,
    "2002-01-01": 139042,
    "2002-04-01": 148543,
    "2002-07-01": 153906,
    "2002-10-01": 155884,
    "2003-01-01": 143897,
    "2003-04-01": 152095,
    "2003-07-01": 156706,
    "2003-10-01": 171176,
    "2004-01-01": 168039,
    "2004-04-01": 179892,
    "2004-07-01": 183726,
    "2004-10-01": 192644,
    "2005-01-01": 185993,
    "2005-04-01": 192817,
    "2005-07-01": 195998,
    "2005-10-01": 207048,
    "2006-01-01": 201909,
    "2006-04-01": 203277,
    "2006-07-01": 209510,
    "2006-10-01": 213866,
    "2007-01-01": 208373,
    "2007-04-01": 219145,
    "2007-07-01": 224374,
    "2007-10-01": 229928,
    "2008-01-01": 219835,
    "2008-04-01": 231323,
    "2008-07-01": 226793,
    "2008-10-01": 204290,
    "2009-01-01": 166420,
    "2009-04-01": 172058,
    "2009-07-01": 184554,
    "2009-10-01": 192532,
    "2010-01-01": 182972,
    "2010-04-01": 200556,
    "2010-07-01": 206931,
    "2010-10-01": 221317,
    "2011-01-01": 213173,
    "2011-04-01": 221545,
    "2011-07-01": 234381,
    "2011-10-01": 239974,
    "2012-01-01": 235579,
    "2012-04-01": 245366,
    "2012-07-01": 248819,
    "2012-10-01": 260146,
    "2013-01-01": 243657,
    "2013-04-01": 260332,
    "2013-07-01": 259502,
    "2013-10-01": 273714,
    "2014-01-01": 248292,
    "2014-04-01": 278783,
    "2014-07-01": 285983,
    "2014-10-01": 303229,
    "2015-01-01": 270122,
    "2015-04-01": 286206,
    "2015-07-01": 290381,
    "2015-10-01": 296042,
    "2016-01-01": 270103,
    "2016-04-01": 281075,
    "2016-07-01": 289645,
    "2016-10-01": 297284,
    "2017-01-01": 286287,
    "2017-04-01": 304852,
    "2017-07-01": 312523,
    "2017-10-01": 326937,
    "2018-01-01": 310313,
    "2018-04-01": 322075,
    "2018-07-01": 329793,
    "2018-10-01": 340896,
    "2019-01-01": 313968,
    "2019-04-01": 327570,
    "2019-07-01": 330196,
    "2019-10-01": 340388,
    "2020-01-01": 319893,
    "2020-04-01": 289723,
    "2020-07-01": 344132,
    "2020-10-01": 373051,
    "2021-01-01": 357454,
    "2021-04-01": 401443,
    "2021-07-01": 404505
  },
  garments: {
    "2000-10-01": 163513,
    "2001-01-01": 128012,
    "2001-04-01": 134219,
    "2001-07-01": 133228,
    "2001-10-01": 169367,
    "2002-01-01": 135572,
    "2002-04-01": 140865,
    "2002-07-01": 140442,
    "2002-10-01": 174175,
    "2003-01-01": 137051,
    "2003-04-01": 144215,
    "2003-07-01": 146087,
    "2003-10-01": 179779,
    "2004-01-01": 147776,
    "2004-04-01": 152785,
    "2004-07-01": 153011,
    "2004-10-01": 183552,
    "2005-01-01": 151648,
    "2005-04-01": 158070,
    "2005-07-01": 157649,
    "2005-10-01": 195394,
    "2006-01-01": 163801,
    "2006-04-01": 169411,
    "2006-07-01": 169021,
    "2006-10-01": 208662,
    "2007-01-01": 173522,
    "2007-04-01": 178295,
    "2007-07-01": 175884,
    "2007-10-01": 213110,
    "2008-01-01": 177809,
    "2008-04-01": 184050,
    "2008-07-01": 178554,
    "2008-10-01": 208450,
    "2009-01-01": 175206,
    "2009-04-01": 177753,
    "2009-07-01": 178659,
    "2009-10-01": 214283,
    "2010-01-01": 183294,
    "2010-04-01": 186281,
    "2010-07-01": 183394,
    "2010-10-01": 220268,
    "2011-01-01": 188184,
    "2011-04-01": 192874,
    "2011-07-01": 190381,
    "2011-10-01": 228012,
    "2012-01-01": 199040,
    "2012-04-01": 199542,
    "2012-07-01": 199303,
    "2012-10-01": 234735,
    "2013-01-01": 203674,
    "2013-04-01": 206741,
    "2013-07-01": 206013,
    "2013-10-01": 241786,
    "2014-01-01": 208404,
    "2014-04-01": 214234,
    "2014-07-01": 214335,
    "2014-10-01": 250457,
    "2015-01-01": 213709,
    "2015-04-01": 219390,
    "2015-07-01": 217252,
    "2015-10-01": 253765,
    "2016-01-01": 218302,
    "2016-04-01": 223372,
    "2016-07-01": 221332,
    "2016-10-01": 257594,
    "2017-01-01": 221495,
    "2017-04-01": 228877,
    "2017-07-01": 228682,
    "2017-10-01": 264973,
    "2018-01-01": 230168,
    "2018-04-01": 241073,
    "2018-07-01": 239439,
    "2018-10-01": 281104,
    "2019-01-01": 241501,
    "2019-04-01": 251396,
    "2019-07-01": 250991,
    "2019-10-01": 290086,
    "2020-01-01": 235987,
    "2020-04-01": 249193,
    "2020-07-01": 255899,
    "2020-10-01": 301536,
    "2021-01-01": 274873,
    "2021-04-01": 291040,
    "2021-07-01": 285876
  },
  telecommunicationService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 118231,
    "2010-01-01": 117837,
    "2010-04-01": 118755,
    "2010-07-01": 120141,
    "2010-10-01": 121944,
    "2011-01-01": 124845,
    "2011-04-01": 128778,
    "2011-07-01": 128636,
    "2011-10-01": 130808,
    "2012-01-01": 130292,
    "2012-04-01": 131387,
    "2012-07-01": 132599,
    "2012-10-01": 135282,
    "2013-01-01": 131843,
    "2013-04-01": 134318,
    "2013-07-01": 134245,
    "2013-10-01": 137966,
    "2014-01-01": 137715,
    "2014-04-01": 138352,
    "2014-07-01": 139974,
    "2014-10-01": 145954,
    "2015-01-01": 142679,
    "2015-04-01": 144220,
    "2015-07-01": 146412,
    "2015-10-01": 148698,
    "2016-01-01": 146868,
    "2016-04-01": 149342,
    "2016-07-01": 152493,
    "2016-10-01": 154724,
    "2017-01-01": 146979,
    "2017-04-01": 149027,
    "2017-07-01": 149708,
    "2017-10-01": 156764,
    "2018-01-01": 151041,
    "2018-04-01": 157241,
    "2018-07-01": 159080,
    "2018-10-01": 166746,
    "2019-01-01": 158965,
    "2019-04-01": 159058,
    "2019-07-01": 160759,
    "2019-10-01": 165423,
    "2020-01-01": 153557,
    "2020-04-01": 147073,
    "2020-07-01": 152788,
    "2020-10-01": 162688,
    "2021-01-01": 157456,
    "2021-04-01": 160058,
    "2021-07-01": 162393
  },
  pharmaceuticals: {
    "2000-10-01": 52890,
    "2001-01-01": 53159,
    "2001-04-01": 55222,
    "2001-07-01": 55335,
    "2001-10-01": 59203,
    "2002-01-01": 56258,
    "2002-04-01": 57873,
    "2002-07-01": 57823,
    "2002-10-01": 63869,
    "2003-01-01": 60483,
    "2003-04-01": 60355,
    "2003-07-01": 58257,
    "2003-10-01": 57463,
    "2004-01-01": 57083,
    "2004-04-01": 58169,
    "2004-07-01": 59404,
    "2004-10-01": 64785,
    "2005-01-01": 62004,
    "2005-04-01": 60296,
    "2005-07-01": 61744,
    "2005-10-01": 67318,
    "2006-01-01": 70118,
    "2006-04-01": 70697,
    "2006-07-01": 70723,
    "2006-10-01": 73945,
    "2007-01-01": 73634,
    "2007-04-01": 73801,
    "2007-07-01": 74730,
    "2007-10-01": 78188,
    "2008-01-01": 74988,
    "2008-04-01": 75407,
    "2008-07-01": 77409,
    "2008-10-01": 79258,
    "2009-01-01": 75834,
    "2009-04-01": 78442,
    "2009-07-01": 79439,
    "2009-10-01": 83656,
    "2010-01-01": 81795,
    "2010-04-01": 81313,
    "2010-07-01": 82384,
    "2010-10-01": 83021,
    "2011-01-01": 81036,
    "2011-04-01": 82885,
    "2011-07-01": 84402,
    "2011-10-01": 84537,
    "2012-01-01": 82657,
    "2012-04-01": 84106,
    "2012-07-01": 82450,
    "2012-10-01": 84809,
    "2013-01-01": 82583,
    "2013-04-01": 85093,
    "2013-07-01": 88397,
    "2013-10-01": 86470,
    "2014-01-01": 87328,
    "2014-04-01": 97035,
    "2014-07-01": 96857,
    "2014-10-01": 101037,
    "2015-01-01": 94975,
    "2015-04-01": 100849,
    "2015-07-01": 101171,
    "2015-10-01": 102740,
    "2016-01-01": 98967,
    "2016-04-01": 104329,
    "2016-07-01": 105502,
    "2016-10-01": 106578,
    "2017-01-01": 102244,
    "2017-04-01": 108106,
    "2017-07-01": 108390,
    "2017-10-01": 108982,
    "2018-01-01": 104489,
    "2018-04-01": 111260,
    "2018-07-01": 113486,
    "2018-10-01": 117255,
    "2019-01-01": 111540,
    "2019-04-01": 116743,
    "2019-07-01": 115907,
    "2019-10-01": 119894,
    "2020-01-01": 118935,
    "2020-04-01": 112211,
    "2020-07-01": 121181,
    "2020-10-01": 130851,
    "2021-01-01": 128991,
    "2021-04-01": 138637,
    "2021-07-01": 148187
  },
  foodandbeverage: {
    "2000-10-01": 76151,
    "2001-01-01": 73832,
    "2001-04-01": 78988,
    "2001-07-01": 76799,
    "2001-10-01": 81221,
    "2002-01-01": 76903,
    "2002-04-01": 78931,
    "2002-07-01": 77299,
    "2002-10-01": 78654,
    "2003-01-01": 78214,
    "2003-04-01": 79945,
    "2003-07-01": 79814,
    "2003-10-01": 88468,
    "2004-01-01": 87797,
    "2004-04-01": 90014,
    "2004-07-01": 90180,
    "2004-10-01": 91970,
    "2005-01-01": 93177,
    "2005-04-01": 93693,
    "2005-07-01": 92823,
    "2005-10-01": 95250,
    "2006-01-01": 91480,
    "2006-04-01": 94082,
    "2006-07-01": 92188,
    "2006-10-01": 95496,
    "2007-01-01": 93674,
    "2007-04-01": 97217,
    "2007-07-01": 91991,
    "2007-10-01": 96508,
    "2008-01-01": 96972,
    "2008-04-01": 99659,
    "2008-07-01": 99727,
    "2008-10-01": 100832,
    "2009-01-01": 96986,
    "2009-04-01": 97875,
    "2009-07-01": 95952,
    "2009-10-01": 98236,
    "2010-01-01": 97350,
    "2010-04-01": 97941,
    "2010-07-01": 97787,
    "2010-10-01": 102422,
    "2011-01-01": 104533,
    "2011-04-01": 107478,
    "2011-07-01": 104228,
    "2011-10-01": 108870,
    "2012-01-01": 109126,
    "2012-04-01": 110268,
    "2012-07-01": 109789,
    "2012-10-01": 111624,
    "2013-01-01": 112204,
    "2013-04-01": 112227,
    "2013-07-01": 109871,
    "2013-10-01": 112895,
    "2014-01-01": 113582,
    "2014-04-01": 115969,
    "2014-07-01": 114888,
    "2014-10-01": 115827,
    "2015-01-01": 111877,
    "2015-04-01": 118021,
    "2015-07-01": 116731,
    "2015-10-01": 117089,
    "2016-01-01": 116312,
    "2016-04-01": 115797,
    "2016-07-01": 115912,
    "2016-10-01": 121730,
    "2017-01-01": 117255,
    "2017-04-01": 119304,
    "2017-07-01": 115330,
    "2017-10-01": 118018,
    "2018-01-01": 118068,
    "2018-04-01": 118491,
    "2018-07-01": 117749,
    "2018-10-01": 120884,
    "2019-01-01": 117961,
    "2019-04-01": 120763,
    "2019-07-01": 121091,
    "2019-10-01": 128803,
    "2020-01-01": 133322,
    "2020-04-01": 139922,
    "2020-07-01": 134566,
    "2020-10-01": 132623,
    "2021-01-01": 135866,
    "2021-04-01": 136259,
    "2021-07-01": 132269
  },
  machines: {
    "2000-10-01": 70490,
    "2001-01-01": 67314,
    "2001-04-01": 71092,
    "2001-07-01": 65794,
    "2001-10-01": 62511,
    "2002-01-01": 59258,
    "2002-04-01": 67190,
    "2002-07-01": 64161,
    "2002-10-01": 61801,
    "2003-01-01": 59257,
    "2003-04-01": 66083,
    "2003-07-01": 63153,
    "2003-10-01": 59817,
    "2004-01-01": 58985,
    "2004-04-01": 73018,
    "2004-07-01": 71944,
    "2004-10-01": 73225,
    "2005-01-01": 72752,
    "2005-04-01": 81824,
    "2005-07-01": 81794,
    "2005-10-01": 81070,
    "2006-01-01": 84019,
    "2006-04-01": 91699,
    "2006-07-01": 88332,
    "2006-10-01": 88386,
    "2007-01-01": 88597,
    "2007-04-01": 99102,
    "2007-07-01": 93891,
    "2007-10-01": 93423,
    "2008-01-01": 95034,
    "2008-04-01": 104446,
    "2008-07-01": 101527,
    "2008-10-01": 94937,
    "2009-01-01": 79734,
    "2009-04-01": 77184,
    "2009-07-01": 74200,
    "2009-10-01": 74692,
    "2010-01-01": 73514,
    "2010-04-01": 86947,
    "2010-07-01": 88175,
    "2010-10-01": 91513,
    "2011-01-01": 91824,
    "2011-04-01": 101556,
    "2011-07-01": 102481,
    "2011-10-01": 103050,
    "2012-01-01": 102517,
    "2012-04-01": 111838,
    "2012-07-01": 108401,
    "2012-10-01": 107514,
    "2013-01-01": 103012,
    "2013-04-01": 115538,
    "2013-07-01": 107860,
    "2013-10-01": 108898,
    "2014-01-01": 103632,
    "2014-04-01": 113191,
    "2014-07-01": 111388,
    "2014-10-01": 110736,
    "2015-01-01": 100891,
    "2015-04-01": 106247,
    "2015-07-01": 101998,
    "2015-10-01": 95983,
    "2016-01-01": 88101,
    "2016-04-01": 98150,
    "2016-07-01": 90593,
    "2016-10-01": 88433,
    "2017-01-01": 90210,
    "2017-04-01": 100540,
    "2017-07-01": 99199,
    "2017-10-01": 99021,
    "2018-01-01": 99432,
    "2018-04-01": 112303,
    "2018-07-01": 109943,
    "2018-10-01": 108685,
    "2019-01-01": 101299,
    "2019-04-01": 110493,
    "2019-07-01": 109442,
    "2019-10-01": 100779,
    "2020-01-01": 91376,
    "2020-04-01": 88183,
    "2020-07-01": 93703,
    "2020-10-01": 93821,
    "2021-01-01": 94523,
    "2021-04-01": 107243,
    "2021-07-01": 106359
  },
  otherinformationService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 16816,
    "2010-01-01": 15314,
    "2010-04-01": 13731,
    "2010-07-01": 14061,
    "2010-10-01": 18075,
    "2011-01-01": 17459,
    "2011-04-01": 17677,
    "2011-07-01": 19785,
    "2011-10-01": 21702,
    "2012-01-01": 21488,
    "2012-04-01": 24371,
    "2012-07-01": 24309,
    "2012-10-01": 26687,
    "2013-01-01": 25463,
    "2013-04-01": 24499,
    "2013-07-01": 24863,
    "2013-10-01": 29183,
    "2014-01-01": 27414,
    "2014-04-01": 28951,
    "2014-07-01": 29856,
    "2014-10-01": 33825,
    "2015-01-01": 33163,
    "2015-04-01": 33095,
    "2015-07-01": 34535,
    "2015-10-01": 38645,
    "2016-01-01": 36762,
    "2016-04-01": 38866,
    "2016-07-01": 40423,
    "2016-10-01": 45548,
    "2017-01-01": 43805,
    "2017-04-01": 45004,
    "2017-07-01": 46766,
    "2017-10-01": 51775,
    "2018-01-01": 48693,
    "2018-04-01": 51431,
    "2018-07-01": 52445,
    "2018-10-01": 63329,
    "2019-01-01": 57379,
    "2019-04-01": 61609,
    "2019-07-01": 62005,
    "2019-10-01": 68830,
    "2020-01-01": 64279,
    "2020-04-01": 61103,
    "2020-07-01": 68873,
    "2020-10-01": 82052,
    "2021-01-01": 82666,
    "2021-04-01": 88758,
    "2021-07-01": 91928
  },
  mining: {
    "2000-10-01": 31852,
    "2001-01-01": 34380,
    "2001-04-01": 30551,
    "2001-07-01": 25079,
    "2001-10-01": 19177,
    "2002-01-01": 17034,
    "2002-04-01": 19672,
    "2002-07-01": 20066,
    "2002-10-01": 21146,
    "2003-01-01": 23462,
    "2003-04-01": 22992,
    "2003-07-01": 23340,
    "2003-10-01": 25341,
    "2004-01-01": 28361,
    "2004-04-01": 29614,
    "2004-07-01": 31279,
    "2004-10-01": 33364,
    "2005-01-01": 34286,
    "2005-04-01": 37190,
    "2005-07-01": 39186,
    "2005-10-01": 46467,
    "2006-01-01": 45558,
    "2006-04-01": 44218,
    "2006-07-01": 45259,
    "2006-10-01": 47574,
    "2007-01-01": 45886,
    "2007-04-01": 50015,
    "2007-07-01": 51041,
    "2007-10-01": 53922,
    "2008-01-01": 58344,
    "2008-04-01": 65308,
    "2008-07-01": 67324,
    "2008-10-01": 53149,
    "2009-01-01": 44263,
    "2009-04-01": 43473,
    "2009-07-01": 45628,
    "2009-10-01": 49645,
    "2010-01-01": 52685,
    "2010-04-01": 48537,
    "2010-07-01": 51645,
    "2010-10-01": 54718,
    "2011-01-01": 57008,
    "2011-04-01": 63297,
    "2011-07-01": 68216,
    "2011-10-01": 70739,
    "2012-01-01": 69771,
    "2012-04-01": 74017,
    "2012-07-01": 77905,
    "2012-10-01": 81042,
    "2013-01-01": 78054,
    "2013-04-01": 80769,
    "2013-07-01": 85121,
    "2013-10-01": 87736,
    "2014-01-01": 93664,
    "2014-04-01": 96873,
    "2014-07-01": 98711,
    "2014-10-01": 92572,
    "2015-01-01": 69939,
    "2015-04-01": 70685,
    "2015-07-01": 65076,
    "2015-10-01": 56816,
    "2016-01-01": 45745,
    "2016-04-01": 50272,
    "2016-07-01": 54478,
    "2016-10-01": 57417,
    "2017-01-01": 60868,
    "2017-04-01": 61859,
    "2017-07-01": 63474,
    "2017-10-01": 70958,
    "2018-01-01": 74563,
    "2018-04-01": 80551,
    "2018-07-01": 83557,
    "2018-10-01": 81913,
    "2019-01-01": 74308,
    "2019-04-01": 77342,
    "2019-07-01": 76436,
    "2019-10-01": 76601,
    "2020-01-01": 65697,
    "2020-04-01": 39770,
    "2020-07-01": 51291,
    "2020-10-01": 56886,
    "2021-01-01": 70538,
    "2021-04-01": 77537,
    "2021-07-01": 91287
  },
  otherelectronics: {
    "2000-10-01": 74560,
    "2001-01-01": 66492,
    "2001-04-01": 58790,
    "2001-07-01": 53882,
    "2001-10-01": 51652,
    "2002-01-01": 52493,
    "2002-04-01": 53031,
    "2002-07-01": 55610,
    "2002-10-01": 54175,
    "2003-01-01": 50506,
    "2003-04-01": 50482,
    "2003-07-01": 51942,
    "2003-10-01": 62231,
    "2004-01-01": 61526,
    "2004-04-01": 64883,
    "2004-07-01": 66356,
    "2004-10-01": 68125,
    "2005-01-01": 66984,
    "2005-04-01": 67431,
    "2005-07-01": 69009,
    "2005-10-01": 69794,
    "2006-01-01": 67536,
    "2006-04-01": 69104,
    "2006-07-01": 70246,
    "2006-10-01": 71483,
    "2007-01-01": 69960,
    "2007-04-01": 70424,
    "2007-07-01": 70182,
    "2007-10-01": 72882,
    "2008-01-01": 67934,
    "2008-04-01": 71374,
    "2008-07-01": 71980,
    "2008-10-01": 66379,
    "2009-01-01": 57312,
    "2009-04-01": 59021,
    "2009-07-01": 60917,
    "2009-10-01": 67183,
    "2010-01-01": 65804,
    "2010-04-01": 70922,
    "2010-07-01": 73727,
    "2010-10-01": 74210,
    "2011-01-01": 68912,
    "2011-04-01": 71947,
    "2011-07-01": 70738,
    "2011-10-01": 71386,
    "2012-01-01": 66522,
    "2012-04-01": 69076,
    "2012-07-01": 68169,
    "2012-10-01": 68540,
    "2013-01-01": 65070,
    "2013-04-01": 69433,
    "2013-07-01": 69500,
    "2013-10-01": 73402,
    "2014-01-01": 70229,
    "2014-04-01": 74779,
    "2014-07-01": 74664,
    "2014-10-01": 76240,
    "2015-01-01": 72130,
    "2015-04-01": 76760,
    "2015-07-01": 76292,
    "2015-10-01": 79934,
    "2016-01-01": 74916,
    "2016-04-01": 78261,
    "2016-07-01": 78047,
    "2016-10-01": 80670,
    "2017-01-01": 75497,
    "2017-04-01": 81398,
    "2017-07-01": 82953,
    "2017-10-01": 88258,
    "2018-01-01": 83074,
    "2018-04-01": 90456,
    "2018-07-01": 89942,
    "2018-10-01": 90938,
    "2019-01-01": 86546,
    "2019-04-01": 87037,
    "2019-07-01": 87438,
    "2019-10-01": 89053,
    "2020-01-01": 82020,
    "2020-04-01": 73598,
    "2020-07-01": 81457,
    "2020-10-01": 86617,
    "2021-01-01": 83983,
    "2021-04-01": 88548,
    "2021-07-01": 90494
  },
  inkService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 46708,
    "2010-01-01": 42109,
    "2010-04-01": 46075,
    "2010-07-01": 44192,
    "2010-10-01": 49338,
    "2011-01-01": 44915,
    "2011-04-01": 48532,
    "2011-07-01": 47605,
    "2011-10-01": 50131,
    "2012-01-01": 45609,
    "2012-04-01": 49104,
    "2012-07-01": 45755,
    "2012-10-01": 49502,
    "2013-01-01": 47293,
    "2013-04-01": 49394,
    "2013-07-01": 47310,
    "2013-10-01": 52930,
    "2014-01-01": 47304,
    "2014-04-01": 53219,
    "2014-07-01": 50163,
    "2014-10-01": 54077,
    "2015-01-01": 49209,
    "2015-04-01": 54209,
    "2015-07-01": 51774,
    "2015-10-01": 55660,
    "2016-01-01": 50908,
    "2016-04-01": 54370,
    "2016-07-01": 54479,
    "2016-10-01": 58711,
    "2017-01-01": 54669,
    "2017-04-01": 61484,
    "2017-07-01": 59114,
    "2017-10-01": 64724,
    "2018-01-01": 61236,
    "2018-04-01": 66887,
    "2018-07-01": 64524,
    "2018-10-01": 71461,
    "2019-01-01": 66568,
    "2019-04-01": 71804,
    "2019-07-01": 69248,
    "2019-10-01": 73461,
    "2020-01-01": 68854,
    "2020-04-01": 74711,
    "2020-07-01": 72525,
    "2020-10-01": 79844,
    "2021-01-01": 77399,
    "2021-04-01": 86623,
    "2021-07-01": 85603
  },
  chemicals: {
    "2000-10-01": 41503,
    "2001-01-01": 43671,
    "2001-04-01": 43178,
    "2001-07-01": 38026,
    "2001-10-01": 33911,
    "2002-01-01": 34147,
    "2002-04-01": 37995,
    "2002-07-01": 35048,
    "2002-10-01": 34727,
    "2003-01-01": 39372,
    "2003-04-01": 40181,
    "2003-07-01": 38773,
    "2003-10-01": 36594,
    "2004-01-01": 41366,
    "2004-04-01": 43839,
    "2004-07-01": 43058,
    "2004-10-01": 45125,
    "2005-01-01": 50503,
    "2005-04-01": 52936,
    "2005-07-01": 50864,
    "2005-10-01": 53184,
    "2006-01-01": 55746,
    "2006-04-01": 58316,
    "2006-07-01": 57000,
    "2006-10-01": 56613,
    "2007-01-01": 59273,
    "2007-04-01": 62808,
    "2007-07-01": 62226,
    "2007-10-01": 63192,
    "2008-01-01": 67692,
    "2008-04-01": 73796,
    "2008-07-01": 68762,
    "2008-10-01": 51867,
    "2009-01-01": 47250,
    "2009-04-01": 47739,
    "2009-07-01": 50082,
    "2009-10-01": 52493,
    "2010-01-01": 57602,
    "2010-04-01": 63672,
    "2010-07-01": 58549,
    "2010-10-01": 59707,
    "2011-01-01": 69360,
    "2011-04-01": 74948,
    "2011-07-01": 72576,
    "2011-10-01": 68086,
    "2012-01-01": 74475,
    "2012-04-01": 73962,
    "2012-07-01": 67685,
    "2012-10-01": 65220,
    "2013-01-01": 71825,
    "2013-04-01": 74434,
    "2013-07-01": 71647,
    "2013-10-01": 70661,
    "2014-01-01": 68016,
    "2014-04-01": 71537,
    "2014-07-01": 68225,
    "2014-10-01": 64176,
    "2015-01-01": 61242,
    "2015-04-01": 62442,
    "2015-07-01": 57224,
    "2015-10-01": 55163,
    "2016-01-01": 55445,
    "2016-04-01": 58280,
    "2016-07-01": 56415,
    "2016-10-01": 55055,
    "2017-01-01": 59587,
    "2017-04-01": 60393,
    "2017-07-01": 57588,
    "2017-10-01": 58431,
    "2018-01-01": 61587,
    "2018-04-01": 65199,
    "2018-07-01": 63438,
    "2018-10-01": 57715,
    "2019-01-01": 57989,
    "2019-04-01": 57064,
    "2019-07-01": 57108,
    "2019-10-01": 54816,
    "2020-01-01": 57908,
    "2020-04-01": 46267,
    "2020-07-01": 56435,
    "2020-10-01": 60119,
    "2021-01-01": 65801,
    "2021-04-01": 76708,
    "2021-07-01": 84785
  },
  metaldesign: {
    "2000-10-01": 49872,
    "2001-01-01": 50067,
    "2001-04-01": 50712,
    "2001-07-01": 49447,
    "2001-10-01": 47697,
    "2002-01-01": 49528,
    "2002-04-01": 53239,
    "2002-07-01": 52133,
    "2002-10-01": 49312,
    "2003-01-01": 47875,
    "2003-04-01": 51658,
    "2003-07-01": 52828,
    "2003-10-01": 51237,
    "2004-01-01": 53157,
    "2004-04-01": 56899,
    "2004-07-01": 56988,
    "2004-10-01": 56513,
    "2005-01-01": 57218,
    "2005-04-01": 59040,
    "2005-07-01": 56855,
    "2005-10-01": 56610,
    "2006-01-01": 60460,
    "2006-04-01": 63870,
    "2006-07-01": 64533,
    "2006-10-01": 63295,
    "2007-01-01": 65151,
    "2007-04-01": 70047,
    "2007-07-01": 72614,
    "2007-10-01": 69107,
    "2008-01-01": 71749,
    "2008-04-01": 78397,
    "2008-07-01": 74920,
    "2008-10-01": 68134,
    "2009-01-01": 58951,
    "2009-04-01": 56868,
    "2009-07-01": 56891,
    "2009-10-01": 54653,
    "2010-01-01": 56632,
    "2010-04-01": 61387,
    "2010-07-01": 62445,
    "2010-10-01": 62686,
    "2011-01-01": 65633,
    "2011-04-01": 70289,
    "2011-07-01": 71714,
    "2011-10-01": 70462,
    "2012-01-01": 72105,
    "2012-04-01": 77049,
    "2012-07-01": 74484,
    "2012-10-01": 71723,
    "2013-01-01": 69717,
    "2013-04-01": 72899,
    "2013-07-01": 72577,
    "2013-10-01": 70392,
    "2014-01-01": 71157,
    "2014-04-01": 76275,
    "2014-07-01": 77649,
    "2014-10-01": 76241,
    "2015-01-01": 73713,
    "2015-04-01": 77205,
    "2015-07-01": 75001,
    "2015-10-01": 73356,
    "2016-01-01": 70041,
    "2016-04-01": 72239,
    "2016-07-01": 72036,
    "2016-10-01": 69545,
    "2017-01-01": 71141,
    "2017-04-01": 74532,
    "2017-07-01": 74500,
    "2017-10-01": 72192,
    "2018-01-01": 74793,
    "2018-04-01": 79966,
    "2018-07-01": 80585,
    "2018-10-01": 63948,
    "2019-01-01": 61288,
    "2019-04-01": 65634,
    "2019-07-01": 67006,
    "2019-10-01": 64658,
    "2020-01-01": 65048,
    "2020-04-01": 60296,
    "2020-07-01": 69661,
    "2020-10-01": 71014,
    "2021-01-01": 75593,
    "2021-04-01": 82339,
    "2021-07-01": 81186
  },
  computerService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 36040,
    "2010-01-01": 33992,
    "2010-04-01": 36784,
    "2010-07-01": 35842,
    "2010-10-01": 39111,
    "2011-01-01": 36868,
    "2011-04-01": 38482,
    "2011-07-01": 38041,
    "2011-10-01": 40775,
    "2012-01-01": 38625,
    "2012-04-01": 39379,
    "2012-07-01": 39511,
    "2012-10-01": 43329,
    "2013-01-01": 40904,
    "2013-04-01": 43108,
    "2013-07-01": 43610,
    "2013-10-01": 46741,
    "2014-01-01": 44376,
    "2014-04-01": 46193,
    "2014-07-01": 48004,
    "2014-10-01": 48279,
    "2015-01-01": 45436,
    "2015-04-01": 48348,
    "2015-07-01": 48373,
    "2015-10-01": 51534,
    "2016-01-01": 48926,
    "2016-04-01": 51900,
    "2016-07-01": 51984,
    "2016-10-01": 56071,
    "2017-01-01": 52525,
    "2017-04-01": 56546,
    "2017-07-01": 56869,
    "2017-10-01": 61581,
    "2018-01-01": 56547,
    "2018-04-01": 54654,
    "2018-07-01": 53886,
    "2018-10-01": 60002,
    "2019-01-01": 57510,
    "2019-04-01": 61374,
    "2019-07-01": 60879,
    "2019-10-01": 66519,
    "2020-01-01": 63854,
    "2020-04-01": 64773,
    "2020-07-01": 67330,
    "2020-10-01": 73742,
    "2021-01-01": 73187,
    "2021-04-01": 76736,
    "2021-07-01": 79286
  },
  aerospacials: {
    "2000-10-01": 42802,
    "2001-01-01": 37960,
    "2001-04-01": 43263,
    "2001-07-01": 42458,
    "2001-10-01": 45126,
    "2002-01-01": 38639,
    "2002-04-01": 39604,
    "2002-07-01": 39215,
    "2002-10-01": 42144,
    "2003-01-01": 41422,
    "2003-04-01": 42437,
    "2003-07-01": 41982,
    "2003-10-01": 46184,
    "2004-01-01": 43964,
    "2004-04-01": 46181,
    "2004-07-01": 45803,
    "2004-10-01": 50095,
    "2005-01-01": 46194,
    "2005-04-01": 51095,
    "2005-07-01": 46896,
    "2005-10-01": 53147,
    "2006-01-01": 49194,
    "2006-04-01": 52137,
    "2006-07-01": 51466,
    "2006-10-01": 57396,
    "2007-01-01": 51845,
    "2007-04-01": 58369,
    "2007-07-01": 59270,
    "2007-10-01": 62390,
    "2008-01-01": 58111,
    "2008-04-01": 63961,
    "2008-07-01": 60201,
    "2008-10-01": 57940,
    "2009-01-01": 56367,
    "2009-04-01": 60495,
    "2009-07-01": 60362,
    "2009-10-01": 64250,
    "2010-01-01": 56390,
    "2010-04-01": 60620,
    "2010-07-01": 61813,
    "2010-10-01": 64316,
    "2011-01-01": 58706,
    "2011-04-01": 62526,
    "2011-07-01": 64446,
    "2011-10-01": 67425,
    "2012-01-01": 65113,
    "2012-04-01": 67228,
    "2012-07-01": 66414,
    "2012-10-01": 70527,
    "2013-01-01": 63270,
    "2013-04-01": 68624,
    "2013-07-01": 68446,
    "2013-10-01": 72604,
    "2014-01-01": 64963,
    "2014-04-01": 70304,
    "2014-07-01": 71459,
    "2014-10-01": 74844,
    "2015-01-01": 67570,
    "2015-04-01": 73267,
    "2015-07-01": 73543,
    "2015-10-01": 73069,
    "2016-01-01": 67594,
    "2016-04-01": 72890,
    "2016-07-01": 70183,
    "2016-10-01": 73873,
    "2017-01-01": 68140,
    "2017-04-01": 73746,
    "2017-07-01": 74204,
    "2017-10-01": 78966,
    "2018-01-01": 74572,
    "2018-04-01": 78711,
    "2018-07-01": 79883,
    "2018-10-01": 86526,
    "2019-01-01": 78398,
    "2019-04-01": 73990,
    "2019-07-01": 78983,
    "2019-10-01": 77649,
    "2020-01-01": 72633,
    "2020-04-01": 61660,
    "2020-07-01": 66173,
    "2020-10-01": 71280,
    "2021-01-01": 68400,
    "2021-04-01": 72138,
    "2021-07-01": 68998
  },
  othermanufacturing: {
    "2000-10-01": 23773,
    "2001-01-01": 23157,
    "2001-04-01": 23044,
    "2001-07-01": 23721,
    "2001-10-01": 24789,
    "2002-01-01": 24978,
    "2002-04-01": 25657,
    "2002-07-01": 27400,
    "2002-10-01": 28208,
    "2003-01-01": 26742,
    "2003-04-01": 25934,
    "2003-07-01": 26674,
    "2003-10-01": 27222,
    "2004-01-01": 26448,
    "2004-04-01": 27920,
    "2004-07-01": 29229,
    "2004-10-01": 30088,
    "2005-01-01": 30401,
    "2005-04-01": 31190,
    "2005-07-01": 31618,
    "2005-10-01": 32077,
    "2006-01-01": 32522,
    "2006-04-01": 33889,
    "2006-07-01": 33986,
    "2006-10-01": 35267,
    "2007-01-01": 36664,
    "2007-04-01": 38089,
    "2007-07-01": 42869,
    "2007-10-01": 43863,
    "2008-01-01": 40768,
    "2008-04-01": 41676,
    "2008-07-01": 43145,
    "2008-10-01": 41203,
    "2009-01-01": 36254,
    "2009-04-01": 36451,
    "2009-07-01": 36976,
    "2009-10-01": 38166,
    "2010-01-01": 35601,
    "2010-04-01": 37099,
    "2010-07-01": 37169,
    "2010-10-01": 37023,
    "2011-01-01": 35801,
    "2011-04-01": 37915,
    "2011-07-01": 37977,
    "2011-10-01": 38103,
    "2012-01-01": 37754,
    "2012-04-01": 39047,
    "2012-07-01": 38536,
    "2012-10-01": 40176,
    "2013-01-01": 39732,
    "2013-04-01": 42448,
    "2013-07-01": 43305,
    "2013-10-01": 43128,
    "2014-01-01": 41734,
    "2014-04-01": 44048,
    "2014-07-01": 45433,
    "2014-10-01": 45297,
    "2015-01-01": 41515,
    "2015-04-01": 44442,
    "2015-07-01": 46566,
    "2015-10-01": 47551,
    "2016-01-01": 46402,
    "2016-04-01": 47445,
    "2016-07-01": 46567,
    "2016-10-01": 47028,
    "2017-01-01": 47795,
    "2017-04-01": 48848,
    "2017-07-01": 49650,
    "2017-10-01": 49630,
    "2018-01-01": 48737,
    "2018-04-01": 51241,
    "2018-07-01": 50901,
    "2018-10-01": 53050,
    "2019-01-01": 50517,
    "2019-04-01": 54289,
    "2019-07-01": 55322,
    "2019-10-01": 60503,
    "2020-01-01": 56486,
    "2020-04-01": 48551,
    "2020-07-01": 60116,
    "2020-10-01": 69848,
    "2021-01-01": 65803,
    "2021-04-01": 67698,
    "2021-07-01": 67345
  },
  otherService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 35815,
    "2010-01-01": 32558,
    "2010-04-01": 35652,
    "2010-07-01": 33419,
    "2010-10-01": 35752,
    "2011-01-01": 37627,
    "2011-04-01": 43385,
    "2011-07-01": 38689,
    "2011-10-01": 41498,
    "2012-01-01": 39575,
    "2012-04-01": 43107,
    "2012-07-01": 41496,
    "2012-10-01": 43934,
    "2013-01-01": 39563,
    "2013-04-01": 43343,
    "2013-07-01": 38242,
    "2013-10-01": 41869,
    "2014-01-01": 38309,
    "2014-04-01": 42536,
    "2014-07-01": 40169,
    "2014-10-01": 42474,
    "2015-01-01": 40900,
    "2015-04-01": 46408,
    "2015-07-01": 46187,
    "2015-10-01": 49954,
    "2016-01-01": 47198,
    "2016-04-01": 51851,
    "2016-07-01": 50690,
    "2016-10-01": 53336,
    "2017-01-01": 50025,
    "2017-04-01": 52581,
    "2017-07-01": 49706,
    "2017-10-01": 53178,
    "2018-01-01": 49568,
    "2018-04-01": 51705,
    "2018-07-01": 48821,
    "2018-10-01": 51439,
    "2019-01-01": 48696,
    "2019-04-01": 53692,
    "2019-07-01": 51151,
    "2019-10-01": 56269,
    "2020-01-01": 50521,
    "2020-04-01": 49314,
    "2020-07-01": 47933,
    "2020-10-01": 50916,
    "2021-01-01": 48525,
    "2021-04-01": 55342,
    "2021-07-01": 52634
  },
  appliances: {
    "2000-10-01": 46056,
    "2001-01-01": 46324,
    "2001-04-01": 46818,
    "2001-07-01": 43533,
    "2001-10-01": 43460,
    "2002-01-01": 43016,
    "2002-04-01": 47067,
    "2002-07-01": 44196,
    "2002-10-01": 46912,
    "2003-01-01": 42254,
    "2003-04-01": 45827,
    "2003-07-01": 45263,
    "2003-10-01": 48970,
    "2004-01-01": 45556,
    "2004-04-01": 48357,
    "2004-07-01": 49148,
    "2004-10-01": 47795,
    "2005-01-01": 44422,
    "2005-04-01": 48826,
    "2005-07-01": 49265,
    "2005-10-01": 51667,
    "2006-01-01": 45908,
    "2006-04-01": 47886,
    "2006-07-01": 48145,
    "2006-10-01": 50093,
    "2007-01-01": 48484,
    "2007-04-01": 51869,
    "2007-07-01": 51563,
    "2007-10-01": 55223,
    "2008-01-01": 50868,
    "2008-04-01": 54335,
    "2008-07-01": 52733,
    "2008-10-01": 51259,
    "2009-01-01": 43364,
    "2009-04-01": 45426,
    "2009-07-01": 45390,
    "2009-10-01": 47372,
    "2010-01-01": 44316,
    "2010-04-01": 47530,
    "2010-07-01": 46080,
    "2010-10-01": 44658,
    "2011-01-01": 42036,
    "2011-04-01": 43894,
    "2011-07-01": 43777,
    "2011-10-01": 44009,
    "2012-01-01": 44283,
    "2012-04-01": 46238,
    "2012-07-01": 45564,
    "2012-10-01": 44344,
    "2013-01-01": 43479,
    "2013-04-01": 44755,
    "2013-07-01": 44912,
    "2013-10-01": 46656,
    "2014-01-01": 43572,
    "2014-04-01": 47624,
    "2014-07-01": 50104,
    "2014-10-01": 51601,
    "2015-01-01": 47008,
    "2015-04-01": 50808,
    "2015-07-01": 47259,
    "2015-10-01": 47214,
    "2016-01-01": 48143,
    "2016-04-01": 48680,
    "2016-07-01": 47217,
    "2016-10-01": 46840,
    "2017-01-01": 46179,
    "2017-04-01": 48398,
    "2017-07-01": 49548,
    "2017-10-01": 46711,
    "2018-01-01": 45586,
    "2018-04-01": 48371,
    "2018-07-01": 48489,
    "2018-10-01": 51373,
    "2019-01-01": 47093,
    "2019-04-01": 47270,
    "2019-07-01": 45484,
    "2019-10-01": 46862,
    "2020-01-01": 43489,
    "2020-04-01": 41696,
    "2020-07-01": 43786,
    "2020-10-01": 45701,
    "2021-01-01": 44166,
    "2021-04-01": 46386,
    "2021-07-01": 42816
  },
  broadcastingService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 26335,
    "2010-01-01": 24611,
    "2010-04-01": 25876,
    "2010-07-01": 25745,
    "2010-10-01": 27993,
    "2011-01-01": 25082,
    "2011-04-01": 27577,
    "2011-07-01": 27386,
    "2011-10-01": 29123,
    "2012-01-01": 26720,
    "2012-04-01": 28048,
    "2012-07-01": 27848,
    "2012-10-01": 29687,
    "2013-01-01": 28461,
    "2013-04-01": 29721,
    "2013-07-01": 29427,
    "2013-10-01": 30770,
    "2014-01-01": 29474,
    "2014-04-01": 31270,
    "2014-07-01": 31678,
    "2014-10-01": 33323,
    "2015-01-01": 30956,
    "2015-04-01": 31572,
    "2015-07-01": 31224,
    "2015-10-01": 34711,
    "2016-01-01": 32393,
    "2016-04-01": 33243,
    "2016-07-01": 32530,
    "2016-10-01": 35792,
    "2017-01-01": 33345,
    "2017-04-01": 33672,
    "2017-07-01": 32758,
    "2017-10-01": 36493,
    "2018-01-01": 34565,
    "2018-04-01": 36223,
    "2018-07-01": 34563,
    "2018-10-01": 38730,
    "2019-01-01": 36336,
    "2019-04-01": 38659,
    "2019-07-01": 37136,
    "2019-10-01": 41382,
    "2020-01-01": 38011,
    "2020-04-01": 27900,
    "2020-07-01": 32086,
    "2020-10-01": 38059,
    "2021-01-01": 36832,
    "2021-04-01": 38273,
    "2021-07-01": 40781
  },
  consultingService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 12854,
    "2010-01-01": 13192,
    "2010-04-01": 13745,
    "2010-07-01": 14010,
    "2010-10-01": 15233,
    "2011-01-01": 15593,
    "2011-04-01": 17191,
    "2011-07-01": 16474,
    "2011-10-01": 16765,
    "2012-01-01": 17301,
    "2012-04-01": 18908,
    "2012-07-01": 18021,
    "2012-10-01": 18237,
    "2013-01-01": 17957,
    "2013-04-01": 18729,
    "2013-07-01": 19493,
    "2013-10-01": 19900,
    "2014-01-01": 19910,
    "2014-04-01": 21199,
    "2014-07-01": 20606,
    "2014-10-01": 20430,
    "2015-01-01": 18859,
    "2015-04-01": 21501,
    "2015-07-01": 20880,
    "2015-10-01": 22444,
    "2016-01-01": 21201,
    "2016-04-01": 22700,
    "2016-07-01": 21342,
    "2016-10-01": 22614,
    "2017-01-01": 20913,
    "2017-04-01": 20702,
    "2017-07-01": 19926,
    "2017-10-01": 21102,
    "2018-01-01": 21253,
    "2018-04-01": 22057,
    "2018-07-01": 21353,
    "2018-10-01": 23594,
    "2019-01-01": 21530,
    "2019-04-01": 23038,
    "2019-07-01": 23901,
    "2019-10-01": 26296,
    "2020-01-01": 25824,
    "2020-04-01": 24304,
    "2020-07-01": 25641,
    "2020-10-01": 28734,
    "2021-01-01": 29602,
    "2021-04-01": 32788,
    "2021-07-01": 33284
  },
  communications: {
    "2000-10-01": 36368,
    "2001-01-01": 33534,
    "2001-04-01": 31758,
    "2001-07-01": 27991,
    "2001-10-01": 24849,
    "2002-01-01": 21240,
    "2002-04-01": 22121,
    "2002-07-01": 20440,
    "2002-10-01": 21988,
    "2003-01-01": 20012,
    "2003-04-01": 19922,
    "2003-07-01": 20378,
    "2003-10-01": 23285,
    "2004-01-01": 22570,
    "2004-04-01": 24939,
    "2004-07-01": 25551,
    "2004-10-01": 27035,
    "2005-01-01": 25154,
    "2005-04-01": 28182,
    "2005-07-01": 28325,
    "2005-10-01": 29008,
    "2006-01-01": 27851,
    "2006-04-01": 28500,
    "2006-07-01": 29080,
    "2006-10-01": 29072,
    "2007-01-01": 28315,
    "2007-04-01": 29291,
    "2007-07-01": 33031,
    "2007-10-01": 35758,
    "2008-01-01": 32553,
    "2008-04-01": 34605,
    "2008-07-01": 35012,
    "2008-10-01": 34445,
    "2009-01-01": 30180,
    "2009-04-01": 30332,
    "2009-07-01": 29994,
    "2009-10-01": 30703,
    "2010-01-01": 30929,
    "2010-04-01": 33202,
    "2010-07-01": 34465,
    "2010-10-01": 35183,
    "2011-01-01": 33201,
    "2011-04-01": 32454,
    "2011-07-01": 33143,
    "2011-10-01": 34104,
    "2012-01-01": 32482,
    "2012-04-01": 30868,
    "2012-07-01": 31239,
    "2012-10-01": 31657,
    "2013-01-01": 29953,
    "2013-04-01": 31854,
    "2013-07-01": 32248,
    "2013-10-01": 32519,
    "2014-01-01": 29674,
    "2014-04-01": 31273,
    "2014-07-01": 31157,
    "2014-10-01": 31775,
    "2015-01-01": 30141,
    "2015-04-01": 30616,
    "2015-07-01": 29689,
    "2015-10-01": 31212,
    "2016-01-01": 29097,
    "2016-04-01": 30444,
    "2016-07-01": 30858,
    "2016-10-01": 31024,
    "2017-01-01": 28530,
    "2017-04-01": 29651,
    "2017-07-01": 28217,
    "2017-10-01": 27461,
    "2018-01-01": 25264,
    "2018-04-01": 26892,
    "2018-07-01": 27936,
    "2018-10-01": 30097,
    "2019-01-01": 29195,
    "2019-04-01": 31077,
    "2019-07-01": 31850,
    "2019-10-01": 28296,
    "2020-01-01": 25449,
    "2020-04-01": 23732,
    "2020-07-01": 25214,
    "2020-10-01": 28075,
    "2021-01-01": 25081,
    "2021-04-01": 26662,
    "2021-07-01": 28873
  },
  nonferrous: {
    "2000-10-01": 19466,
    "2001-01-01": 18002,
    "2001-04-01": 16723,
    "2001-07-01": 15070,
    "2001-10-01": 13977,
    "2002-01-01": 14154,
    "2002-04-01": 14303,
    "2002-07-01": 14230,
    "2002-10-01": 13543,
    "2003-01-01": 13682,
    "2003-04-01": 13894,
    "2003-07-01": 13997,
    "2003-10-01": 14264,
    "2004-01-01": 15717,
    "2004-04-01": 17262,
    "2004-07-01": 16161,
    "2004-10-01": 16212,
    "2005-01-01": 17223,
    "2005-04-01": 18351,
    "2005-07-01": 18706,
    "2005-10-01": 20773,
    "2006-01-01": 22140,
    "2006-04-01": 25726,
    "2006-07-01": 25612,
    "2006-10-01": 25489,
    "2007-01-01": 25174,
    "2007-04-01": 27253,
    "2007-07-01": 25620,
    "2007-10-01": 25030,
    "2008-01-01": 25549,
    "2008-04-01": 27830,
    "2008-07-01": 26733,
    "2008-10-01": 20037,
    "2009-01-01": 15047,
    "2009-04-01": 15752,
    "2009-07-01": 17643,
    "2009-10-01": 18377,
    "2010-01-01": 19986,
    "2010-04-01": 21314,
    "2010-07-01": 21094,
    "2010-10-01": 21576,
    "2011-01-01": 23900,
    "2011-04-01": 25120,
    "2011-07-01": 25170,
    "2011-10-01": 22303,
    "2012-01-01": 23705,
    "2012-04-01": 23073,
    "2012-07-01": 22125,
    "2012-10-01": 21435,
    "2013-01-01": 21855,
    "2013-04-01": 23154,
    "2013-07-01": 22588,
    "2013-10-01": 22106,
    "2014-01-01": 22459,
    "2014-04-01": 24403,
    "2014-07-01": 24722,
    "2014-10-01": 23970,
    "2015-01-01": 25974,
    "2015-04-01": 26330,
    "2015-07-01": 25115,
    "2015-10-01": 22605,
    "2016-01-01": 23191,
    "2016-04-01": 24911,
    "2016-07-01": 26418,
    "2016-10-01": 21583,
    "2017-01-01": 18525,
    "2017-04-01": 19883,
    "2017-07-01": 19609,
    "2017-10-01": 18993,
    "2018-01-01": 19857,
    "2018-04-01": 20350,
    "2018-07-01": 19266,
    "2018-10-01": 18192,
    "2019-01-01": 18083,
    "2019-04-01": 18553,
    "2019-07-01": 18025,
    "2019-10-01": 17306,
    "2020-01-01": 17679,
    "2020-04-01": 16357,
    "2020-07-01": 18254,
    "2020-10-01": 19465,
    "2021-01-01": 22271,
    "2021-04-01": 27236,
    "2021-07-01": 27547
  },
  apparel: {
    "2000-10-01": 24785,
    "2001-01-01": 23129,
    "2001-04-01": 22454,
    "2001-07-01": 24450,
    "2001-10-01": 22344,
    "2002-01-01": 21355,
    "2002-04-01": 21188,
    "2002-07-01": 23442,
    "2002-10-01": 22926,
    "2003-01-01": 20588,
    "2003-04-01": 20526,
    "2003-07-01": 23039,
    "2003-10-01": 18503,
    "2004-01-01": 18558,
    "2004-04-01": 20428,
    "2004-07-01": 23432,
    "2004-10-01": 22336,
    "2005-01-01": 21119,
    "2005-04-01": 22749,
    "2005-07-01": 23315,
    "2005-10-01": 22718,
    "2006-01-01": 21587,
    "2006-04-01": 19719,
    "2006-07-01": 22333,
    "2006-10-01": 22752,
    "2007-01-01": 19913,
    "2007-04-01": 19772,
    "2007-07-01": 22453,
    "2007-10-01": 22241,
    "2008-01-01": 20322,
    "2008-04-01": 21155,
    "2008-07-01": 23504,
    "2008-10-01": 22397,
    "2009-01-01": 20687,
    "2009-04-01": 19848,
    "2009-07-01": 22490,
    "2009-10-01": 21202,
    "2010-01-01": 20625,
    "2010-04-01": 21381,
    "2010-07-01": 22622,
    "2010-10-01": 21580,
    "2011-01-01": 21201,
    "2011-04-01": 22048,
    "2011-07-01": 24017,
    "2011-10-01": 23249,
    "2012-01-01": 22011,
    "2012-04-01": 22141,
    "2012-07-01": 23875,
    "2012-10-01": 23410,
    "2013-01-01": 21457,
    "2013-04-01": 22111,
    "2013-07-01": 23392,
    "2013-10-01": 23063,
    "2014-01-01": 20815,
    "2014-04-01": 20985,
    "2014-07-01": 23334,
    "2014-10-01": 23693,
    "2015-01-01": 20856,
    "2015-04-01": 21422,
    "2015-07-01": 23582,
    "2015-10-01": 23580,
    "2016-01-01": 22001,
    "2016-04-01": 21277,
    "2016-07-01": 23439,
    "2016-10-01": 22677,
    "2017-01-01": 20801,
    "2017-04-01": 21146,
    "2017-07-01": 23446,
    "2017-10-01": 22611,
    "2018-01-01": 20981,
    "2018-04-01": 21671,
    "2018-07-01": 23873,
    "2018-10-01": 23233,
    "2019-01-01": 21480,
    "2019-04-01": 19670,
    "2019-07-01": 22024,
    "2019-10-01": 22092,
    "2020-01-01": 19006,
    "2020-04-01": 12919,
    "2020-07-01": 18551,
    "2020-10-01": 20210,
    "2021-01-01": 18731,
    "2021-04-01": 21880,
    "2021-07-01": 22972
  },
  furniture: {
    "2000-10-01": 14558,
    "2001-01-01": 14929,
    "2001-04-01": 14757,
    "2001-07-01": 14215,
    "2001-10-01": 14216,
    "2002-01-01": 14009,
    "2002-04-01": 14303,
    "2002-07-01": 14319,
    "2002-10-01": 13246,
    "2003-01-01": 12968,
    "2003-04-01": 13092,
    "2003-07-01": 13914,
    "2003-10-01": 13952,
    "2004-01-01": 13857,
    "2004-04-01": 14801,
    "2004-07-01": 15258,
    "2004-10-01": 15036,
    "2005-01-01": 15404,
    "2005-04-01": 18703,
    "2005-07-01": 21256,
    "2005-10-01": 20725,
    "2006-01-01": 21349,
    "2006-04-01": 22202,
    "2006-07-01": 21473,
    "2006-10-01": 20145,
    "2007-01-01": 19718,
    "2007-04-01": 20332,
    "2007-07-01": 20551,
    "2007-10-01": 18609,
    "2008-01-01": 17823,
    "2008-04-01": 18176,
    "2008-07-01": 19156,
    "2008-10-01": 15918,
    "2009-01-01": 13364,
    "2009-04-01": 13960,
    "2009-07-01": 14149,
    "2009-10-01": 13267,
    "2010-01-01": 13115,
    "2010-04-01": 14200,
    "2010-07-01": 13528,
    "2010-10-01": 13364,
    "2011-01-01": 14176,
    "2011-04-01": 14983,
    "2011-07-01": 14975,
    "2011-10-01": 13720,
    "2012-01-01": 14208,
    "2012-04-01": 15086,
    "2012-07-01": 15898,
    "2012-10-01": 16083,
    "2013-01-01": 15944,
    "2013-04-01": 17198,
    "2013-07-01": 19352,
    "2013-10-01": 18019,
    "2014-01-01": 17113,
    "2014-04-01": 18649,
    "2014-07-01": 20002,
    "2014-10-01": 18651,
    "2015-01-01": 17650,
    "2015-04-01": 19126,
    "2015-07-01": 18730,
    "2015-10-01": 17857,
    "2016-01-01": 17685,
    "2016-04-01": 18570,
    "2016-07-01": 19071,
    "2016-10-01": 18566,
    "2017-01-01": 18261,
    "2017-04-01": 19317,
    "2017-07-01": 19862,
    "2017-10-01": 18682,
    "2018-01-01": 18182,
    "2018-04-01": 19639,
    "2018-07-01": 19776,
    "2018-10-01": 16464,
    "2019-01-01": 16334,
    "2019-04-01": 17975,
    "2019-07-01": 19210,
    "2019-10-01": 18645,
    "2020-01-01": 17224,
    "2020-04-01": 14824,
    "2020-07-01": 18630,
    "2020-10-01": 17920,
    "2021-01-01": 18003,
    "2021-04-01": 19795,
    "2021-07-01": 20850
  },
  scientificService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 7828,
    "2010-01-01": 8241,
    "2010-04-01": 8147,
    "2010-07-01": 8342,
    "2010-10-01": 9441,
    "2011-01-01": 9120,
    "2011-04-01": 9624,
    "2011-07-01": 10099,
    "2011-10-01": 10235,
    "2012-01-01": 9616,
    "2012-04-01": 10029,
    "2012-07-01": 9604,
    "2012-10-01": 9789,
    "2013-01-01": 9654,
    "2013-04-01": 10472,
    "2013-07-01": 10135,
    "2013-10-01": 10819,
    "2014-01-01": 9441,
    "2014-04-01": 10708,
    "2014-07-01": 11050,
    "2014-10-01": 10875,
    "2015-01-01": 9872,
    "2015-04-01": 10393,
    "2015-07-01": 10301,
    "2015-10-01": 12289,
    "2016-01-01": 10980,
    "2016-04-01": 11670,
    "2016-07-01": 12236,
    "2016-10-01": 13895,
    "2017-01-01": 13967,
    "2017-04-01": 14284,
    "2017-07-01": 15562,
    "2017-10-01": 16431,
    "2018-01-01": 15269,
    "2018-04-01": 16135,
    "2018-07-01": 15687,
    "2018-10-01": 15626,
    "2019-01-01": 15952,
    "2019-04-01": 16543,
    "2019-07-01": 17516,
    "2019-10-01": 17903,
    "2020-01-01": 15655,
    "2020-04-01": 16644,
    "2020-07-01": 19760,
    "2020-10-01": 20923,
    "2021-01-01": 19405,
    "2021-04-01": 20073,
    "2021-07-01": 20591
  },
  knitting: {
    "2000-10-01": 12123,
    "2001-01-01": 12325,
    "2001-04-01": 12525,
    "2001-07-01": 11425,
    "2001-10-01": 10906,
    "2002-01-01": 11507,
    "2002-04-01": 12549,
    "2002-07-01": 12474,
    "2002-10-01": 11949,
    "2003-01-01": 11231,
    "2003-04-01": 12326,
    "2003-07-01": 11679,
    "2003-10-01": 11611,
    "2004-01-01": 12063,
    "2004-04-01": 12833,
    "2004-07-01": 12603,
    "2004-10-01": 12045,
    "2005-01-01": 12075,
    "2005-04-01": 12382,
    "2005-07-01": 12666,
    "2005-10-01": 12165,
    "2006-01-01": 12207,
    "2006-04-01": 12343,
    "2006-07-01": 12000,
    "2006-10-01": 11684,
    "2007-01-01": 11160,
    "2007-04-01": 11166,
    "2007-07-01": 11654,
    "2007-10-01": 10998,
    "2008-01-01": 10661,
    "2008-04-01": 10491,
    "2008-07-01": 9888,
    "2008-10-01": 8768,
    "2009-01-01": 8084,
    "2009-04-01": 8562,
    "2009-07-01": 8672,
    "2009-10-01": 8049,
    "2010-01-01": 8196,
    "2010-04-01": 8890,
    "2010-07-01": 9250,
    "2010-10-01": 8936,
    "2011-01-01": 8477,
    "2011-04-01": 9458,
    "2011-07-01": 9877,
    "2011-10-01": 9032,
    "2012-01-01": 9477,
    "2012-04-01": 9867,
    "2012-07-01": 9859,
    "2012-10-01": 9392,
    "2013-01-01": 9507,
    "2013-04-01": 10147,
    "2013-07-01": 10061,
    "2013-10-01": 9981,
    "2014-01-01": 9608,
    "2014-04-01": 10518,
    "2014-07-01": 10727,
    "2014-10-01": 10825,
    "2015-01-01": 10224,
    "2015-04-01": 10531,
    "2015-07-01": 10154,
    "2015-10-01": 9008,
    "2016-01-01": 9030,
    "2016-04-01": 9339,
    "2016-07-01": 9004,
    "2016-10-01": 9172,
    "2017-01-01": 9109,
    "2017-04-01": 10514,
    "2017-07-01": 10251,
    "2017-10-01": 9996,
    "2018-01-01": 10244,
    "2018-04-01": 11997,
    "2018-07-01": 11317,
    "2018-10-01": 9476,
    "2019-01-01": 9042,
    "2019-04-01": 10313,
    "2019-07-01": 9572,
    "2019-10-01": 8981,
    "2020-01-01": 8435,
    "2020-04-01": 7648,
    "2020-07-01": 9043,
    "2020-10-01": 9167,
    "2021-01-01": 9119,
    "2021-04-01": 11056,
    "2021-07-01": 11514
  },
  welding: {
    "2000-10-01": 4787,
    "2001-01-01": 4773,
    "2001-04-01": 5446,
    "2001-07-01": 5194,
    "2001-10-01": 5304,
    "2002-01-01": 5566,
    "2002-04-01": 5831,
    "2002-07-01": 5350,
    "2002-10-01": 4874,
    "2003-01-01": 4734,
    "2003-04-01": 4824,
    "2003-07-01": 4188,
    "2003-10-01": 4036,
    "2004-01-01": 4136,
    "2004-04-01": 4396,
    "2004-07-01": 4289,
    "2004-10-01": 4506,
    "2005-01-01": 4989,
    "2005-04-01": 5690,
    "2005-07-01": 5411,
    "2005-10-01": 5886,
    "2006-01-01": 6247,
    "2006-04-01": 7297,
    "2006-07-01": 6966,
    "2006-10-01": 6994,
    "2007-01-01": 6521,
    "2007-04-01": 6583,
    "2007-07-01": 6173,
    "2007-10-01": 5837,
    "2008-01-01": 5658,
    "2008-04-01": 6161,
    "2008-07-01": 5810,
    "2008-10-01": 5111,
    "2009-01-01": 4486,
    "2009-04-01": 3941,
    "2009-07-01": 3695,
    "2009-10-01": 4112,
    "2010-01-01": 4328,
    "2010-04-01": 4635,
    "2010-07-01": 4523,
    "2010-10-01": 4358,
    "2011-01-01": 4685,
    "2011-04-01": 4928,
    "2011-07-01": 5220,
    "2011-10-01": 5016,
    "2012-01-01": 5523,
    "2012-04-01": 5892,
    "2012-07-01": 5473,
    "2012-10-01": 5415,
    "2013-01-01": 5986,
    "2013-04-01": 6071,
    "2013-07-01": 5785,
    "2013-10-01": 5886,
    "2014-01-01": 6663,
    "2014-04-01": 7003,
    "2014-07-01": 7031,
    "2014-10-01": 7122,
    "2015-01-01": 7149,
    "2015-04-01": 7133,
    "2015-07-01": 7005,
    "2015-10-01": 6825,
    "2016-01-01": 7077,
    "2016-04-01": 6987,
    "2016-07-01": 6878,
    "2016-10-01": 6614,
    "2017-01-01": 7067,
    "2017-04-01": 7308,
    "2017-07-01": 6911,
    "2017-10-01": 6684,
    "2018-01-01": 7420,
    "2018-04-01": 7961,
    "2018-07-01": 7138,
    "2018-10-01": 6640,
    "2019-01-01": 6922,
    "2019-04-01": 6973,
    "2019-07-01": 6821,
    "2019-10-01": 6249,
    "2020-01-01": 6118,
    "2020-04-01": 4396,
    "2020-07-01": 4950,
    "2020-10-01": 5094,
    "2021-01-01": 5298,
    "2021-04-01": 6047,
    "2021-07-01": 6210
  },
  productionaryService: {
    "2000-10-01": null,
    "2001-01-01": null,
    "2001-04-01": null,
    "2001-07-01": null,
    "2001-10-01": null,
    "2002-01-01": null,
    "2002-04-01": null,
    "2002-07-01": null,
    "2002-10-01": null,
    "2003-01-01": null,
    "2003-04-01": null,
    "2003-07-01": null,
    "2003-10-01": null,
    "2004-01-01": null,
    "2004-04-01": null,
    "2004-07-01": null,
    "2004-10-01": null,
    "2005-01-01": null,
    "2005-04-01": null,
    "2005-07-01": null,
    "2005-10-01": null,
    "2006-01-01": null,
    "2006-04-01": null,
    "2006-07-01": null,
    "2006-10-01": null,
    "2007-01-01": null,
    "2007-04-01": null,
    "2007-07-01": null,
    "2007-10-01": null,
    "2008-01-01": null,
    "2008-04-01": null,
    "2008-07-01": null,
    "2008-10-01": null,
    "2009-01-01": null,
    "2009-04-01": null,
    "2009-07-01": null,
    "2009-10-01": 10911,
    "2010-01-01": 9779,
    "2010-04-01": 9965,
    "2010-07-01": 10125,
    "2010-10-01": 10893,
    "2011-01-01": 9426,
    "2011-04-01": 10848,
    "2011-07-01": 11025,
    "2011-10-01": 11574,
    "2012-01-01": 10779,
    "2012-04-01": 10508,
    "2012-07-01": 10893,
    "2012-10-01": 12167,
    "2013-01-01": 10739,
    "2013-04-01": 11592,
    "2013-07-01": 10591,
    "2013-10-01": 12649,
    "2014-01-01": 11253,
    "2014-04-01": 10762,
    "2014-07-01": 10678,
    "2014-10-01": 12416,
    "2015-01-01": 11722,
    "2015-04-01": 12153,
    "2015-07-01": 11148,
    "2015-10-01": 12839,
    "2016-01-01": 12442,
    "2016-04-01": 11818,
    "2016-07-01": 12065,
    "2016-10-01": 12819,
    "2017-01-01": 12548,
    "2017-04-01": 11899,
    "2017-07-01": 12018,
    "2017-10-01": 13636,
    "2018-01-01": 12689,
    "2018-04-01": 6534,
    "2018-07-01": 6299,
    "2018-10-01": 6722,
    "2019-01-01": 5924,
    "2019-04-01": 7386,
    "2019-07-01": 7376,
    "2019-10-01": 7756,
    "2020-01-01": 6767,
    "2020-04-01": 4256,
    "2020-07-01": 4229,
    "2020-10-01": 4914,
    "2021-01-01": 5057,
    "2021-04-01": 5967,
    "2021-07-01": 5991
  }
};

class Sales extends React.Component {
  constructor(props) {
    super(props);

    //let testing = [];
    let noData = [];
    let date = [];
    let currentDataData = [];
    let industries = [];
    let allindustry = [];
    var industryInitial = "allmanufacturing";
    Object.keys(sale).forEach((industry, i) => {
      industries.push(industry);
      const dta = Object.values(sale)[i];
      Object.keys(dta).forEach((yr, i) => {
        const year = new Date(yr).getTime();
        const num = Object.values(dta)[i];
        allindustry.push(num);
        if (industryInitial === industry) {
          date.push(year);
          noData.push([year, 0]);
          currentDataData.push([year, num]);
        }
      });
    });
    var lowPPCS = Math.min(...allindustry);
    var lowDate = Math.min(...date);
    var highPPCS = Math.max(...allindustry);
    var highDate = Math.max(...date);
    var state = {
      industry: industryInitial,
      date,
      industries,
      highPPCS,
      currentDataData,
      noData,
      yAxisPPCS: highPPCS - null,
      xAxisPPCS: highDate - lowDate,
      lowPPCS,
      highDate,
      lowDate
    };
    this.state = state;
  }
  componentDidUpdate = () => {
    if (this.state.industry !== this.state.laststate) {
      this.setState(
        {
          laststate: this.state.industry
        },
        () => {
          let currentData = [];
          let currentDataData = [];
          let allindustry = [];
          let highest = [];
          Object.keys(sale).forEach((industry, i) => {
            const dta = Object.values(sale)[i];
            Object.keys(dta).forEach((yr, i) => {
              const year = new Date(yr).getTime();
              const num = Object.values(dta)[i];
              allindustry.push(num);
              !["allmanufacturing", "perishablesall", "durablesall"].includes(
                industry
              ) && currentData.push(num);

              if (this.state.industry === industry) {
                highest.push(num);
                currentDataData.push([Number(year), num]);
              }
            });
          });
          const all = [
            "allmanufacturing",
            "perishablesall",
            "durablesall"
          ].includes(this.state.industry)
            ? allindustry
            : currentData;
          var highPPCS = Math.max(...all);
          var state = {
            currentDataData,
            highPPCS: Math.max(...highest),
            lowPPCS: Math.min(...currentData),
            yAxisPPCS: highPPCS - 0
          };
          this.setState(state);
        }
      );
    }
  };
  render() {
    const { industries } = this.state;
    const labelstyle = {
      backgroundColor: "rgba(50,120,200,.6)",
      top: "0px",
      height: "min-content",
      display: "flex",
      maxWidth: "100%",
      left: "2px",
      flexWrap: "wrap"
    };
    const buttonStyle = {
      userSelect: "none",
      border: "1px solid black",
      color: "black",
      backgroundColor: "rgb(220,220,220)",
      borderRadius: "4px",
      padding: "5px",
      margin: "2px"
    };

    const lineheight = this.props.lineheight ? this.props.lineheight : 200;
    const linecss = {
      left: "0px",
      bottom: "0px",
      display: "flex",
      position: "absolute",
      width: "100%",
      height: lineheight + 10,
      transform: "translate(0%,0%) scale(1,-1)"
    };
    const shortNumber = (scler, notRound) => {
      var newnum = String(Math.round(scler));
      if (notRound) newnum = String(scler);
      var app = null;
      var decimal = null;
      const suff = ["", "k", "m", "b", "t"];
      for (let i = 0; i < suff.length; i++) {
        if (newnum.length > 3) {
          decimal = newnum[newnum.length - 3];
          newnum = newnum.substring(null, newnum.length - 3);
        } else {
          app = i;
          break;
        }
      }
      return newnum + (decimal ? "." + decimal : "") + suff[app];
    };
    const coefficience = (this.props.lastWidth - 60) / this.props.lastWidth;
    //console.log(this.state.oilprice);
    const noData = this.state.noData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxis) *
        coefficience *
        this.props.lastWidth,
      0
    ]);
    //console.log(this.state.oilprice);

    const currentDataData = this.state.currentDataData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxisPPCS) *
        coefficience *
        this.props.lastWidth,
      ((y - 0) / this.state.yAxisPPCS) * lineheight
    ]);

    return (
      <div
        style={{
          width: "100%",
          height: lineheight + 30,
          position: "relative",
          backgroundColor: "rgb(0,40,0)"
        }}
      >
        <a
          style={{
            zIndex: "1",
            color: "white",
            fontWeight: "bolder",
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px",
            bottom: "0px"
          }}
          href="https://fredaccount.stlouisfed.org/datalists/277069"
        >
          Sales
        </a>

        <div
          style={{
            color: "white",
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px"
          }}
        >
          <select
            state={this.state.industry}
            defaultValue={this.state.industry}
            onChange={(name) => {
              this.setState({ industry: name.target.value });
            }}
            style={{ width: "100px" }}
          >
            {industries.map((name) => (
              <option key={name}>{name}</option>
            ))}
          </select>
          Sales ${shortNumber(this.state.lowPPCS * 1000000)}-
          {shortNumber(this.state.highPPCS * 1000000)}
        </div>
        <div
          style={{
            display: "flex"
          }}
        >
          <div
            style={{
              width: "min-content",
              padding: "4px 8px",
              backgroundColor: "rgba(250,250,250,.6)",
              height: "min-content",
              display: "flex",
              position: "relative"
            }}
          >
            {new Date(this.state.lowDate).getFullYear()} &nbsp;-&nbsp;{" "}
            {new Date(this.state.highDate).getFullYear()}
          </div>
          <svg
            style={{ ...linecss, marginLeft: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            {noData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={4}
                    height={4}
                    stroke="rgb(230,230,230)"
                    fill="transparent"
                    strokeWidth={0}
                    key={i}
                  />
                )
            )}
            {currentDataData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={2}
                    stroke="white"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
          </svg>
        </div>
        {/*
        <div
          style={{
            backgroundColor: "rgba(250,250,250,0.6)",
            top: "10px",
            height: "40px",
            display: "flex",
            position: "relative",
            width: "100%",
            left: "2px",
            zIndex: "0",
            overflowX: "auto",
            overflowY: "hidden"
          }}
        >
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              position: "absolute",
              width: "max-content"
            }}
          ></div>
        </div>
        */}
      </div>
    );
  }
}

export default Sales;
