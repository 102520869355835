import React from "react";
const revenue = {
  finance: [
    {
      "2013": 1093702,
      "2014": 1093341,
      "2015": 1133127,
      "2016": 1207094,
      "2017": 1286776,
      "2018": 1402356,
      "2019": 1487125,
      "2020": 1444268,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 1.6,
      "2018c": 1.1,
      "2017c": 0,
      "2016c": 0.9,
      "2015c": 0.8,
      "2014c": 0.6,
      "2013c": 0.3
    },
    {
      "2013": 506947,
      "2014": 490309,
      "2015": 512659,
      "2016": 525034,
      "2017": 527278,
      "2018": 562322,
      "2019": 600383,
      "2020": 612659,
      item: "Loan income from consumers",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.7,
      "2018c": 1.4,
      "2017c": 0.9,
      "2016c": 1.2,
      "2015c": 1,
      "2014c": 1.3,
      "2013c": 0.9
    },
    {
      "2013": 196912,
      "2014": 201498,
      "2015": 220088,
      "2016": 254214,
      "2017": 297157,
      "2018": 345113,
      "2019": 363576,
      "2020": 336998,
      item: "Loan income from businesses and governments",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 5.2,
      "2018c": 4.6,
      "2017c": 2.7,
      "2016c": 4.6,
      "2015c": 4,
      "2014c": 2.5,
      "2013c": 2.4
    },
    {
      "2013": 254214,
      "2014": 260605,
      "2015": 242664,
      "2016": 252722,
      "2017": 272574,
      "2018": 295162,
      "2019": 315778,
      "2020": 311994,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 2.3,
      "2018c": 2.2,
      "2017c": 1.9,
      "2016c": 2.1,
      "2015c": 2.1,
      "2014c": 2.1,
      "2013c": 1.9
    },
    {
      "2013": 101346,
      "2014": 107055,
      "2015": 118369,
      "2016": 130021,
      "2017": 143122,
      "2018": 151421,
      "2019": 161148,
      "2020": 146222,
      item: "Credit card income from consumers",
      taxexempt: "all",
      "2020c": 1,
      "2019c": 0.9,
      "2018c": 1,
      "2017c": 0.6,
      "2016c": 0.7,
      "2015c": 0.7,
      "2014c": 1,
      "2013c": 1
    },
    {
      "2013": 34283,
      "2014": 33874,
      "2015": 39347,
      "2016": 45103,
      "2017": 46645,
      "2018": 48338,
      "2019": 46240,
      "2020": 36395,
      item: "Credit card income from businesses and governments",
      taxexempt: "all",
      "2020c": 2.5,
      "2019c": 2.3,
      "2018c": 2,
      "2017c": 1.5,
      "2016c": 2.1,
      "2015c": 2.1,
      "2014c": 3.5,
      "2013c": 3.2
    }
  ],
  hospitalizations: [
    {
      "2013": 898333,
      "2014": 933367,
      "2015": 983359,
      "2016": 1031314,
      "2017": 1070864,
      "2018": 1115638,
      "2019": 1191917,
      "2020": 1213236,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.8,
      "2019c": 0.6,
      "2018c": 0.2,
      "2017c": 0,
      "2016c": 0.4,
      "2015c": 0.4,
      "2014c": 0.5,
      "2013c": 0.5
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 478431,
      "2016": 501984,
      "2017": 520550,
      "2018": 544111,
      "2019": 569843,
      "2020": 570112,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 1.4,
      "2019c": 1.3,
      "2018c": 1,
      "2017c": 1,
      "2016c": 1.2,
      "2015c": 1.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 236890,
      "2016": 248845,
      "2017": 252422,
      "2018": 264460,
      "2019": 282831,
      "2020": 271800,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 1.4,
      "2019c": 1.4,
      "2018c": 1.3,
      "2017c": 1.2,
      "2016c": 1.3,
      "2015c": 1.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 78004,
      "2016": 81277,
      "2017": 83873,
      "2018": 89487,
      "2019": 100516,
      "2020": 101682,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 3,
      "2019c": 2.7,
      "2018c": 2.7,
      "2017c": 2.4,
      "2016c": 2,
      "2015c": 2.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 41886,
      "2016": 43794,
      "2017": 47018,
      "2018": 48988,
      "2019": 54479,
      "2020": 68386,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 5.1,
      "2019c": 4.9,
      "2018c": 5.4,
      "2017c": 6,
      "2016c": 6,
      "2015c": 6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 42209,
      "2016": 43854,
      "2017": 47230,
      "2018": 50714,
      "2019": 52754,
      "2020": 57879,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 9.2,
      "2019c": 9.8,
      "2018c": 9.7,
      "2017c": 9.9,
      "2016c": 8,
      "2015c": 7.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 34426,
      "2016": 38078,
      "2017": 39967,
      "2018": 42194,
      "2019": 43514,
      "2020": 43865,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 14.6,
      "2019c": 15.3,
      "2018c": 14.9,
      "2017c": 15,
      "2016c": 14.9,
      "2015c": 15.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15673,
      "2016": 12776,
      "2017": 12708,
      "2018": 13208,
      "2019": 14751,
      "2020": 28876,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 3.7,
      "2019c": 9.5,
      "2018c": 6.6,
      "2017c": 6,
      "2016c": 4.7,
      "2015c": 20.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 25753,
      "2016": 26380,
      "2017": 26853,
      "2018": 27697,
      "2019": 29002,
      "2020": 25371,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 2.6,
      "2018c": 2.9,
      "2017c": 2.6,
      "2016c": 2.8,
      "2015c": 3.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6265,
      "2016": 9165,
      "2017": 16031,
      "2018": 10365,
      "2019": 17570,
      "2020": 18669,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 8.1,
      "2019c": 5,
      "2018c": 9.1,
      "2017c": 4.9,
      "2016c": 4.9,
      "2015c": 7.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6338,
      "2016": 6953,
      "2017": 6442,
      "2018": 6482,
      "2019": 7693,
      "2020": 8818,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 7,
      "2019c": 5.2,
      "2018c": 5.7,
      "2017c": 5.3,
      "2016c": 6.2,
      "2015c": 5.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 8312,
      "2016": 8286,
      "2017": 7845,
      "2018": 7831,
      "2019": 8551,
      "2020": 8263,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 13.2,
      "2019c": 13,
      "2018c": 11.8,
      "2017c": 11.6,
      "2016c": 10.9,
      "2015c": 10,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6249,
      "2016": 6248,
      "2017": 6629,
      "2018": 6807,
      "2019": 6999,
      "2020": 6236,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 4.2,
      "2019c": 4.1,
      "2018c": 3.9,
      "2017c": 3.4,
      "2016c": 3.5,
      "2015c": 3.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2923,
      "2016": 3525,
      "2017": 3129,
      "2018": 3112,
      "2019": 3177,
      "2020": 2972,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 9.7,
      "2019c": 8.9,
      "2018c": 11,
      "2017c": 11,
      "2016c": 13.2,
      "2015c": 7.7,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  electric: [
    {
      "2013": 22,
      "2014": 18,
      "2015": "ZZ",
      "2016": "ZZ",
      "2017": "ZZ",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Sewer system user charges",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": 2,
      "2013c": 1.8
    },
    {
      "2013": 9081,
      "2014": 10802,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "Sales of energy and resources - Other revenue from sales of energy and resources",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 4.2,
      "2013c": 4.2
    },
    {
      "2013": 445802,
      "2014": 470793,
      "2015": 462910,
      "2016": 460025,
      "2017": 461919,
      "2018": 471080,
      "2019": 471706,
      "2020": 460244,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.4,
      "2019c": 0.3,
      "2018c": 0.4,
      "2017c": 0,
      "2016c": 0.5,
      "2015c": 0.5,
      "2014c": 0.7,
      "2013c": 0.5
    },
    {
      "2013": 423777,
      "2014": 447111,
      "2015": 432612,
      "2016": 431303,
      "2017": 435662,
      "2018": 442698,
      "2019": 440264,
      "2020": 428995,
      item:
        "Sales of energy and resources - Electricity generation and distribution",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1.2,
      "2018c": 1.2,
      "2017c": 1.1,
      "2016c": 1.5,
      "2015c": 1.4,
      "2014c": 0.8,
      "2013c": 0.5
    },
    {
      "2013": 7839,
      "2014": 7964,
      "2015": 9258,
      "2016": 9352,
      "2017": 8263,
      "2018": 9463,
      "2019": 10744,
      "2020": 11832,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 3.3,
      "2019c": 2.9,
      "2018c": 3.5,
      "2017c": 3.2,
      "2016c": 18.3,
      "2015c": 22.1,
      "2014c": 7.1,
      "2013c": 8
    },
    {
      "2013": 5057,
      "2014": 4900,
      "2015": 5878,
      "2016": 5490,
      "2017": 6478,
      "2018": 7632,
      "2019": 8040,
      "2020": 7013,
      item:
        "Sales of energy and resources - Natural gas distribution to final customer",
      taxexempt: "all",
      "2020c": 8.9,
      "2019c": 9.9,
      "2018c": 10.5,
      "2017c": 9.6,
      "2016c": 9.9,
      "2015c": 10.2,
      "2014c": 9.1,
      "2013c": 6.6
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 23,
      "2016": 25,
      "2017": 23,
      "2018": 23,
      "2019": 26,
      "2020": 23,
      item: "Sales of energy and resources - Water",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 12.4,
      "2018c": 5,
      "2017c": 3.9,
      "2016c": 9.7,
      "2015c": 10.4,
      "2014c": "S",
      "2013c": "S"
    }
  ],
  standardizedguaranteeschemesmedical: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 794714,
      "2016": 818060,
      "2017": 855940,
      "2018": 923495,
      "2019": 988390,
      "2020": 1044128,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 0.4,
      "2018c": 0.2,
      "2017c": 0,
      "2016c": 1.8,
      "2015c": 1.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 765506,
      "2016": 787326,
      "2017": 825083,
      "2018": 890600,
      "2019": 954170,
      "2020": 1007591,
      item: "Health and medical insurance products - net premiums earned",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 0.5,
      "2018c": 0.3,
      "2017c": 0.2,
      "2016c": 1.7,
      "2015c": 1.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 29208,
      "2016": 30734,
      "2017": 30857,
      "2018": 32895,
      "2019": 34220,
      "2020": 36537,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 4.4,
      "2019c": 4.8,
      "2018c": 5.5,
      "2017c": 5.7,
      "2016c": 6.6,
      "2015c": 9.3,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  standardizedguaranteeschemes: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 500785,
      "2016": 500895,
      "2017": 501786,
      "2018": 518335,
      "2019": 528534,
      "2020": 536571,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 0.3,
      "2018c": 0.3,
      "2017c": 0,
      "2016c": 2.1,
      "2015c": 2.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 259443,
      "2016": 256307,
      "2017": 249917,
      "2018": 254555,
      "2019": 256123,
      "2020": 256554,
      item: "Life insurance underwriting services - net premiums earned",
      taxexempt: "all",
      "2020c": 1.1,
      "2019c": 1,
      "2018c": 1.1,
      "2017c": 1.1,
      "2016c": 2.3,
      "2015c": 2.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 141816,
      "2016": 142956,
      "2017": 145768,
      "2018": 149335,
      "2019": 152870,
      "2020": 160400,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 2,
      "2019c": 2.2,
      "2018c": 1.9,
      "2017c": 2.3,
      "2016c": 2.5,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 99526,
      "2016": 101632,
      "2017": 106101,
      "2018": 114445,
      "2019": 119541,
      "2020": 119617,
      item: "Pensions and annuities underwriting services - fees",
      taxexempt: "all",
      "2020c": 4.9,
      "2019c": 2.8,
      "2018c": 2.7,
      "2017c": 2.5,
      "2016c": 3.4,
      "2015c": 3.2,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  physicianofficesall: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 388513,
      "2014": 407590,
      "2015": 432135,
      "2016": 456892,
      "2017": 475671,
      "2018": 492482,
      "2019": 512532,
      "2020": 510895,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1,
      "2018c": 0.4,
      "2017c": 0,
      "2016c": 1.3,
      "2015c": 1.1,
      "2014c": 1.1,
      "2013c": 0.6
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 190868,
      "2016": 199864,
      "2017": 209945,
      "2018": 216908,
      "2019": 226032,
      "2020": 227387,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.9,
      "2018c": 1.2,
      "2017c": 1.2,
      "2016c": 1.7,
      "2015c": 1.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 74350,
      "2016": 78320,
      "2017": 81671,
      "2018": 84413,
      "2019": 87289,
      "2020": 85457,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 2.7,
      "2019c": 2.2,
      "2018c": 1.9,
      "2017c": 1.9,
      "2016c": 2.6,
      "2015c": 2.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 44848,
      "2016": 47654,
      "2017": 46881,
      "2018": 48764,
      "2019": 50820,
      "2020": 50082,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 3.2,
      "2019c": 2.8,
      "2018c": 3.5,
      "2017c": 2.8,
      "2016c": 2.2,
      "2015c": 3.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 35705,
      "2016": 36918,
      "2017": 37982,
      "2018": 40085,
      "2019": 42033,
      "2020": 40167,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 3.6,
      "2019c": 4.5,
      "2018c": 5.3,
      "2017c": 4.7,
      "2016c": 4.5,
      "2015c": 4.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 25957,
      "2016": 29587,
      "2017": 31707,
      "2018": 33106,
      "2019": 35806,
      "2020": 34842,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 7.9,
      "2019c": 7.7,
      "2018c": 7.1,
      "2017c": 8.1,
      "2016c": 7.8,
      "2015c": 6.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 16249,
      "2016": 17358,
      "2017": 18322,
      "2018": 19317,
      "2019": 20502,
      "2020": 20087,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 6,
      "2019c": 6,
      "2018c": 5.9,
      "2017c": 6,
      "2016c": 5.4,
      "2015c": 4.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15474,
      "2016": 15341,
      "2017": 15367,
      "2018": 15470,
      "2019": 16295,
      "2020": 17134,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 6.6,
      "2019c": 6.2,
      "2018c": 6.1,
      "2017c": 6.8,
      "2016c": 6,
      "2015c": 6.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 14611,
      "2016": 14183,
      "2017": 14226,
      "2018": 14773,
      "2019": 13870,
      "2020": 16522,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 6.9,
      "2019c": 7,
      "2018c": 9,
      "2017c": 9.3,
      "2016c": 8.1,
      "2015c": 7.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7995,
      "2016": 8737,
      "2017": 9877,
      "2018": 10111,
      "2019": 10008,
      "2020": 9542,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 6.6,
      "2018c": 6.9,
      "2017c": 6.8,
      "2016c": 6.8,
      "2015c": 7.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4133,
      "2016": 4434,
      "2017": 4718,
      "2018": 4936,
      "2019": 5148,
      "2020": 4509,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 12.9,
      "2019c": 14.5,
      "2018c": 12.8,
      "2017c": 12.2,
      "2016c": 11.8,
      "2015c": 9.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1945,
      "2016": "S",
      "2017": 1960,
      "2018": 1837,
      "2019": 1872,
      "2020": 1662,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 15,
      "2019c": 16.7,
      "2018c": 15.6,
      "2017c": 20,
      "2016c": "S",
      "2015c": 28.4,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  physicianoffices: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 383400,
      "2014": 402374,
      "2015": 426918,
      "2016": 451396,
      "2017": 469832,
      "2018": 486131,
      "2019": 505567,
      "2020": 503327,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1,
      "2018c": 0.5,
      "2017c": 0,
      "2016c": 1.3,
      "2015c": 1.1,
      "2014c": 1.1,
      "2013c": 0.5
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 189147,
      "2016": 198038,
      "2017": 207997,
      "2018": 214722,
      "2019": 223562,
      "2020": 224605,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 2,
      "2018c": 1.2,
      "2017c": 1.3,
      "2016c": 1.8,
      "2015c": 1.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 73922,
      "2016": 77895,
      "2017": 81231,
      "2018": 83934,
      "2019": 86781,
      "2020": 84960,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 2.7,
      "2019c": 2.2,
      "2018c": 1.9,
      "2017c": 1.9,
      "2016c": 2.6,
      "2015c": 2.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 44451,
      "2016": 47235,
      "2017": 46486,
      "2018": 48377,
      "2019": 50435,
      "2020": 49692,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 3.2,
      "2019c": 2.8,
      "2018c": 3.5,
      "2017c": 2.8,
      "2016c": 2.2,
      "2015c": 3.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 34637,
      "2016": 35793,
      "2017": 36755,
      "2018": 38760,
      "2019": 40579,
      "2020": 38655,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 3.7,
      "2019c": 4.6,
      "2018c": 5.4,
      "2017c": 4.8,
      "2016c": 4.8,
      "2015c": 4.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 25335,
      "2016": 28886,
      "2017": 30931,
      "2018": 32238,
      "2019": 34849,
      "2020": 33763,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 8.3,
      "2019c": 8,
      "2018c": 7.4,
      "2017c": 8.3,
      "2016c": 7.9,
      "2015c": 7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15966,
      "2016": 17066,
      "2017": 18008,
      "2018": 18972,
      "2019": 20144,
      "2020": 19697,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 6.1,
      "2019c": 6,
      "2018c": 6,
      "2017c": 6.1,
      "2016c": 5.4,
      "2015c": 4.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15301,
      "2016": 15202,
      "2017": 15220,
      "2018": 15327,
      "2019": 16140,
      "2020": 16972,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 6.6,
      "2019c": 6.2,
      "2018c": 6.1,
      "2017c": 6.9,
      "2016c": 6,
      "2015c": 6.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 14403,
      "2016": 14033,
      "2017": 14081,
      "2018": 14632,
      "2019": 13725,
      "2020": 16361,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 7,
      "2019c": 7.1,
      "2018c": 9.1,
      "2017c": 9.4,
      "2016c": 8.3,
      "2015c": 8.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7767,
      "2016": 8500,
      "2017": 9623,
      "2018": 9848,
      "2019": 9718,
      "2020": 9202,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 7.3,
      "2019c": 6.8,
      "2018c": 7,
      "2017c": 6.9,
      "2016c": 6.9,
      "2015c": 7.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4058,
      "2016": 4360,
      "2017": 4636,
      "2018": 4849,
      "2019": 5059,
      "2020": 4415,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 13.4,
      "2019c": 14.9,
      "2018c": 13.1,
      "2017c": 12.5,
      "2016c": 12,
      "2015c": 9.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1931,
      "2016": "S",
      "2017": 1945,
      "2018": 1823,
      "2019": 1857,
      "2020": 1650,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 15.1,
      "2019c": 16.7,
      "2018c": 15.7,
      "2017c": 20,
      "2016c": "S",
      "2015c": 28.6,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  macroeconomicgains: [
    {
      "2013": 248574,
      "2014": 266580,
      "2015": 268971,
      "2016": 270066,
      "2017": 285607,
      "2018": 297810,
      "2019": 313973,
      "2020": 332104,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.5,
      "2019c": 2.1,
      "2018c": 1.2,
      "2017c": 0,
      "2016c": 2,
      "2015c": 1.6,
      "2014c": 1,
      "2013c": 0.9
    },
    {
      "2013": 115794,
      "2014": 126145,
      "2015": 120146,
      "2016": 122257,
      "2017": 136075,
      "2018": 141172,
      "2019": 147544,
      "2020": 154258,
      item:
        "Financial planning and investment management services for businesses and governments",
      taxexempt: "all",
      "2020c": 4.2,
      "2019c": 4.1,
      "2018c": 4.6,
      "2017c": 4.6,
      "2016c": 5.1,
      "2015c": 4.5,
      "2014c": 2.7,
      "2013c": 2.8
    },
    {
      "2013": 73920,
      "2014": 77221,
      "2015": 80679,
      "2016": 81265,
      "2017": 81882,
      "2018": 84788,
      "2019": 87921,
      "2020": 93883,
      item:
        "Financial planning and investment management services for individuals",
      taxexempt: "all",
      "2020c": 6.2,
      "2019c": 7,
      "2018c": 5.9,
      "2017c": 5.1,
      "2016c": 4.9,
      "2015c": 5.5,
      "2014c": 4.1,
      "2013c": 3.8
    },
    {
      "2013": 44897,
      "2014": 48143,
      "2015": 53882,
      "2016": 52530,
      "2017": 53803,
      "2018": 56913,
      "2019": 63122,
      "2020": 67225,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 8.5,
      "2019c": 10.3,
      "2018c": 5.5,
      "2017c": 5.8,
      "2016c": 5.6,
      "2015c": 5.2,
      "2014c": 6.5,
      "2013c": 6.5
    },
    {
      "2013": 8543,
      "2014": 9213,
      "2015": 7214,
      "2016": 7195,
      "2017": 7205,
      "2018": 8189,
      "2019": 8535,
      "2020": 9706,
      item: "Brokering and dealing products - equities",
      taxexempt: "all",
      "2020c": 14.9,
      "2019c": 14.4,
      "2018c": 18.8,
      "2017c": 22.3,
      "2016c": 21.3,
      "2015c": 24.2,
      "2014c": 21.7,
      "2013c": 19.4
    },
    {
      "2013": 5420,
      "2014": 5858,
      "2015": 7050,
      "2016": 6819,
      "2017": 6642,
      "2018": 6748,
      "2019": 6851,
      "2020": 7032,
      item: "Trust products",
      taxexempt: "all",
      "2020c": 6.8,
      "2019c": 6.7,
      "2018c": 6.6,
      "2017c": 5.9,
      "2016c": 8.2,
      "2015c": 8.6,
      "2014c": 5.9,
      "2013c": 4.1
    }
  ],
  trucking: [
    {
      "2013": 248590,
      "2014": 270270,
      "2015": 273250,
      "2016": 273083,
      "2017": 290532,
      "2018": 313814,
      "2019": 320817,
      "2020": 316982,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 1.1,
      "2018c": 0.8,
      "2017c": 0,
      "2016c": 1.8,
      "2015c": 1.4,
      "2014c": 1,
      "2013c": 0.8
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 102475,
      "2016": 103091,
      "2017": 113265,
      "2018": 126983,
      "2019": 130642,
      "2020": 131938,
      item: "General freight trucking, long distance",
      taxexempt: "all",
      "2020c": 2.1,
      "2019c": 2.3,
      "2018c": 2.3,
      "2017c": 2.3,
      "2016c": 3.3,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 55608,
      "2016": 55415,
      "2017": 56870,
      "2018": 59857,
      "2019": 61865,
      "2020": 59017,
      item: "General freight trucking, local",
      taxexempt: "all",
      "2020c": 3.7,
      "2019c": 3.4,
      "2018c": 3.4,
      "2017c": 2.3,
      "2016c": 2.5,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 57919,
      "2016": 56908,
      "2017": 58405,
      "2018": 62248,
      "2019": 62830,
      "2020": 58982,
      item: "Specialized freight trucking, long distance",
      taxexempt: "all",
      "2020c": 4.4,
      "2019c": 3.8,
      "2018c": 3.3,
      "2017c": 3.7,
      "2016c": 4.1,
      "2015c": 4.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 25358,
      "2016": 25472,
      "2017": 27398,
      "2018": 29658,
      "2019": 29670,
      "2020": 29588,
      item: "Specialized freight trucking, local",
      taxexempt: "all",
      "2020c": 5,
      "2019c": 4.5,
      "2018c": 6.8,
      "2017c": 6.8,
      "2016c": 4.8,
      "2015c": 5.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 19561,
      "2016": 19913,
      "2017": 20373,
      "2018": 20015,
      "2019": 20703,
      "2020": 21910,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 5.4,
      "2019c": 4.8,
      "2018c": 4,
      "2017c": 3.4,
      "2016c": 3.4,
      "2015c": 3.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 12329,
      "2016": 12284,
      "2017": 14221,
      "2018": 15053,
      "2019": 15107,
      "2020": 15547,
      item: "Household goods moving",
      taxexempt: "all",
      "2020c": 4.5,
      "2019c": 2.5,
      "2018c": 3,
      "2017c": 2.8,
      "2016c": 4.2,
      "2015c": 3,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  legal: [
    {
      "2013": 257596,
      "2014": 261805,
      "2015": 270317,
      "2016": 275911,
      "2017": 293882,
      "2018": 295363,
      "2019": 305414,
      "2020": 309309,
      item: "Revenue",
      taxexempt: "FALSE",
      "2020c": 1.9,
      "2019c": 1.6,
      "2018c": 1.8,
      "2017c": 0,
      "2016c": 2.7,
      "2015c": 2,
      "2014c": 1.8,
      "2013c": 1.2
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 220520,
      "2016": 224951,
      "2017": 235265,
      "2018": 233688,
      "2019": 252743,
      "2020": 256198,
      item: "Legal services, civil law",
      taxexempt: "FALSE",
      "2020c": 2.5,
      "2019c": 1.6,
      "2018c": 2.2,
      "2017c": 2.1,
      "2016c": 3,
      "2015c": 2.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 30379,
      "2016": 31334,
      "2017": 34772,
      "2018": 39612,
      "2019": 29441,
      "2020": 27947,
      item: "All other operating revenue",
      taxexempt: "FALSE",
      "2020c": 9.8,
      "2019c": 9.4,
      "2018c": 11.5,
      "2017c": 10,
      "2016c": 9.9,
      "2015c": 9.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 13899,
      "2016": 13896,
      "2017": 17671,
      "2018": 12328,
      "2019": 14592,
      "2020": 16023,
      item: "Legal research and document services",
      taxexempt: "FALSE",
      "2020c": 6.1,
      "2019c": 5.7,
      "2018c": 4.1,
      "2017c": 25.3,
      "2016c": 4.9,
      "2015c": 5.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5519,
      "2016": 5730,
      "2017": 6174,
      "2018": 9735,
      "2019": 8638,
      "2020": 9141,
      item: "Legal services, criminal law",
      taxexempt: "FALSE",
      "2020c": 14.1,
      "2019c": 13.1,
      "2018c": 26.7,
      "2017c": 15.9,
      "2016c": 15.4,
      "2015c": 13.2,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  organizations: [
    {
      "2013": 207704,
      "2014": 220830,
      "2015": 222251,
      "2016": 227789,
      "2017": 251756,
      "2018": 261852,
      "2019": 276053,
      "2020": 288093,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 1.4,
      "2018c": 1.6,
      "2017c": 0,
      "2016c": 1.7,
      "2015c": 1.8,
      "2014c": 1.1,
      "2013c": 1.6
    },
    {
      "2013": 100133,
      "2014": 105632,
      "2015": 110564,
      "2016": 113838,
      "2017": 119437,
      "2018": 129674,
      "2019": 130700,
      "2020": 144850,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 2.5,
      "2019c": 2.5,
      "2018c": 2.5,
      "2017c": 2.2,
      "2016c": 2.2,
      "2015c": 3,
      "2014c": 2.1,
      "2013c": 3.2
    },
    {
      "2013": 57037,
      "2014": 60329,
      "2015": 66360,
      "2016": 68301,
      "2017": 73041,
      "2018": 77326,
      "2019": 79661,
      "2020": 72925,
      item: "Program service",
      taxexempt: "all",
      "2020c": 1.7,
      "2019c": 1.8,
      "2018c": 1.6,
      "2017c": 1.9,
      "2016c": 2.1,
      "2015c": 2.4,
      "2014c": 2.1,
      "2013c": 1.8
    },
    {
      "2013": 20295,
      "2014": 23928,
      "2015": 22458,
      "2016": 22700,
      "2017": 31388,
      "2018": 29041,
      "2019": 36339,
      "2020": 39048,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 3.9,
      "2019c": 4.6,
      "2018c": 4.9,
      "2017c": 5.5,
      "2016c": 6.6,
      "2015c": 4.3,
      "2014c": 5.2,
      "2013c": 5.1
    },
    {
      "2013": 30239,
      "2014": 30941,
      "2015": 22869,
      "2016": 22950,
      "2017": 27890,
      "2018": 25811,
      "2019": 29353,
      "2020": 31270,
      item: "All other non-operating revenue",
      taxexempt: "all",
      "2020c": 4.1,
      "2019c": 3.8,
      "2018c": 7.2,
      "2017c": 3.6,
      "2016c": 4.8,
      "2015c": 3.2,
      "2014c": 3.7,
      "2013c": 4.1
    }
  ],
  air: [
    {
      "2013": 188927,
      "2014": 198674,
      "2015": 199733,
      "2016": 198787,
      "2017": 208830,
      "2018": 224690,
      "2019": 236830,
      "2020": 110158,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.5,
      "2019c": 0.5,
      "2018c": 0.6,
      "2017c": 0,
      "2016c": 1.7,
      "2015c": 1.7,
      "2014c": 1.2,
      "2013c": 1.1
    },
    {
      "2013": 99502,
      "2014": 105017,
      "2015": 107778,
      "2016": 107423,
      "2017": 113392,
      "2018": 123621,
      "2019": 135612,
      "2020": 51184,
      item: "Domestic, scheduled passenger transportation by air",
      taxexempt: "all",
      "2020c": 2.7,
      "2019c": 2.1,
      "2018c": 1.9,
      "2017c": 1.9,
      "2016c": 2.6,
      "2015c": 2.7,
      "2014c": 2.8,
      "2013c": 2.7
    },
    {
      "2013": 21907,
      "2014": 24151,
      "2015": 26879,
      "2016": 29613,
      "2017": 30146,
      "2018": 32934,
      "2019": 27184,
      "2020": 20566,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 2.4,
      "2019c": 2,
      "2018c": 2.2,
      "2017c": 1.9,
      "2016c": 4.5,
      "2015c": 3.5,
      "2014c": 3,
      "2013c": 2.4
    },
    {
      "2013": 47014,
      "2014": 48204,
      "2015": 45546,
      "2016": 42999,
      "2017": 45236,
      "2018": 46807,
      "2019": 51395,
      "2020": 16649,
      item: "International, scheduled passenger transportation by air",
      taxexempt: "all",
      "2020c": 7,
      "2019c": 4.2,
      "2018c": 4.4,
      "2017c": 4.1,
      "2016c": 3.9,
      "2015c": 3.6,
      "2014c": 8.7,
      "2013c": 7.9
    },
    {
      "2013": 8320,
      "2014": 8795,
      "2015": 9274,
      "2016": 9040,
      "2017": 9530,
      "2018": 10003,
      "2019": 10649,
      "2020": 9636,
      item: "Domestic, non-scheduled passenger transportation by air",
      taxexempt: "all",
      "2020c": 7.9,
      "2019c": 6.8,
      "2018c": 7.5,
      "2017c": 7.5,
      "2016c": 8.2,
      "2015c": 8.3,
      "2014c": 2.4,
      "2013c": 1.8
    },
    {
      "2013": 7325,
      "2014": 7544,
      "2015": 5709,
      "2016": 5156,
      "2017": 5524,
      "2018": 5928,
      "2019": 5898,
      "2020": 6129,
      item: "International, scheduled freight transportation by air",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 3.9,
      "2018c": 4,
      "2017c": 4.8,
      "2016c": 3.6,
      "2015c": 3.4,
      "2014c": 9.8,
      "2013c": 9.6
    },
    {
      "2013": 2444,
      "2014": 2512,
      "2015": 2360,
      "2016": 2397,
      "2017": 2738,
      "2018": 2943,
      "2019": 3369,
      "2020": 3173,
      item: "International, non-scheduled passenger transportation by air",
      taxexempt: "all",
      "2020c": 18.7,
      "2019c": 9.5,
      "2018c": 10.6,
      "2017c": 11.3,
      "2016c": 12.2,
      "2015c": 12.4,
      "2014c": 3.2,
      "2013c": 10.9
    },
    {
      "2013": 2415,
      "2014": 2451,
      "2015": 2187,
      "2016": 2159,
      "2017": 2264,
      "2018": 2454,
      "2019": 2723,
      "2020": 2821,
      item: "Domestic, scheduled freight transportation by air",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 2.4,
      "2018c": 3.2,
      "2017c": 1.5,
      "2016c": 1.7,
      "2015c": 1.8,
      "2014c": 2.9,
      "2013c": 2.9
    }
  ],
  leasing: [
    {
      "2013": 206998,
      "2014": 221153,
      "2015": 235775,
      "2016": 247132,
      "2017": 259373,
      "2018": 269805,
      "2019": 286358,
      "2020": 276537,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1,
      "2019c": 0.8,
      "2018c": 0.5,
      "2017c": 0,
      "2016c": 0.7,
      "2015c": 0.7,
      "2014c": 0.5,
      "2013c": 0.3
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 96028,
      "2016": 101616,
      "2017": 107738,
      "2018": 110935,
      "2019": 118978,
      "2020": 116185,
      item:
        "Home, apartment, rooming house, and other residential space rental and leasing",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.5,
      "2018c": 1.3,
      "2017c": 1.1,
      "2016c": 1.7,
      "2015c": 2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 48288,
      "2016": 50587,
      "2017": 52360,
      "2018": 53156,
      "2019": 55739,
      "2020": 49787,
      item: "Rental and leasing of commercial space",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 2.2,
      "2018c": 2.7,
      "2017c": 2.7,
      "2016c": 2.8,
      "2015c": 2.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 37153,
      "2016": 38148,
      "2017": 40023,
      "2018": 43464,
      "2019": 46179,
      "2020": 44814,
      item: "Rental and leasing of office and professional space",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 3.8,
      "2018c": 4.5,
      "2017c": 4,
      "2016c": 3.9,
      "2015c": 4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 33539,
      "2016": 34664,
      "2017": 35836,
      "2018": 37591,
      "2019": 39555,
      "2020": 38843,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 3.3,
      "2019c": 3.2,
      "2018c": 2.6,
      "2017c": 2.7,
      "2016c": 2.5,
      "2015c": 2.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 10257,
      "2016": 11247,
      "2017": 12230,
      "2018": 13075,
      "2019": 13396,
      "2020": 13881,
      item: "Rental of miniwarehouses and self-storage units space",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.2,
      "2018c": 1.3,
      "2017c": 0.9,
      "2016c": 2.3,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 8016,
      "2016": 8309,
      "2017": 8413,
      "2018": 8790,
      "2019": 9598,
      "2020": 10224,
      item: "Rental and leasing of industrial and manufacturing space",
      taxexempt: "all",
      "2020c": 13.9,
      "2019c": 13.1,
      "2018c": 9.1,
      "2017c": 9,
      "2016c": 8.8,
      "2015c": 8.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2494,
      "2016": 2561,
      "2017": 2773,
      "2018": 2794,
      "2019": 2913,
      "2020": 2803,
      item: "Rental and leasing of land for nonresidential use",
      taxexempt: "all",
      "2020c": 8.1,
      "2019c": 6.3,
      "2018c": 8.1,
      "2017c": 7.5,
      "2016c": 8.3,
      "2015c": 9,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  gas: [
    {
      "2013": 102759,
      "2014": 120061,
      "2015": 98447,
      "2016": 95496,
      "2017": 100586,
      "2018": 111279,
      "2019": 112016,
      "2020": 104394,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 2,
      "2018c": 1.8,
      "2017c": 0,
      "2016c": 1.2,
      "2015c": 1.1,
      "2014c": 1,
      "2013c": 0.6
    },
    {
      "2013": 85981,
      "2014": 99249,
      "2015": 84294,
      "2016": 82347,
      "2017": 85078,
      "2018": 97001,
      "2019": 97769,
      "2020": 92046,
      item:
        "Sales of energy and resources - Natural gas distribution to final customer",
      taxexempt: "all",
      "2020c": 2.4,
      "2019c": 2.2,
      "2018c": 1.9,
      "2017c": 1.4,
      "2016c": 1.7,
      "2015c": 1.5,
      "2014c": 1.4,
      "2013c": 1
    },
    {
      "2013": 12061,
      "2014": 14570,
      "2015": 9905,
      "2016": 8721,
      "2017": 11390,
      "2018": 10370,
      "2019": 10114,
      "2020": 7780,
      item:
        "Sales of energy and resources - Other revenue from sales of energy and resources",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 5.3,
      "2018c": 5.1,
      "2017c": 3.3,
      "2016c": 4.4,
      "2015c": 4.5,
      "2014c": 2.5,
      "2013c": 3.1
    },
    {
      "2013": 1687,
      "2014": "S",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 2044,
      "2019": 2175,
      "2020": 2643,
      item:
        "Sales of energy and resources - Electricity generation and distribution",
      taxexempt: "all",
      "2020c": 26.5,
      "2019c": 20,
      "2018c": 23.2,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "S",
      "2013c": 9.5
    },
    {
      "2013": 3029,
      "2014": 3553,
      "2015": 2096,
      "2016": 2073,
      "2017": "S",
      "2018": 1862,
      "2019": 1956,
      "2020": 1923,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 19.3,
      "2019c": 23.8,
      "2018c": 22,
      "2017c": "S",
      "2016c": 29.1,
      "2015c": 24.5,
      "2014c": 1.2,
      "2013c": 1.2
    },
    {
      "2013": 1,
      "2014": "S",
      "2015": 1,
      "2016": 1,
      "2017": 1,
      "2018": 1,
      "2019": 1,
      "2020": 1,
      item: "Sales of energy and resources - Water",
      taxexempt: "all",
      "2020c": 1.7,
      "2019c": 1.8,
      "2018c": 1.8,
      "2017c": 1.7,
      "2016c": 2.3,
      "2015c": 2.3,
      "2014c": "S",
      "2013c": 1.5
    },
    {
      "2013": "ZZ",
      "2014": "S",
      "2015": 1,
      "2016": 1,
      "2017": 1,
      "2018": 1,
      "2019": 1,
      "2020": 1,
      item: "Sewer system user charges",
      taxexempt: "all",
      "2020c": 1.7,
      "2019c": 1.8,
      "2018c": 1.9,
      "2017c": 1.7,
      "2016c": 2.3,
      "2015c": 2.3,
      "2014c": "S",
      "2013c": 1.5
    }
  ],
  computers: [
    {
      "2013": 56094,
      "2014": 63100,
      "2015": 98585,
      "2016": 105282,
      "2017": 115225,
      "2018": 128015,
      "2019": "S",
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 6,
      "2017c": 4.8,
      "2016c": 6,
      "2015c": 5.5,
      "2014c": 4.9,
      "2013c": 6
    },
    {
      "2013": 44238,
      "2014": 47549,
      "2015": 35643,
      "2016": 37440,
      "2017": 46837,
      "2018": 53635,
      "2019": 49597,
      "2020": "S",
      item: "Computer systems design, development, and integration",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 5.8,
      "2018c": 4.7,
      "2017c": 4.9,
      "2016c": 5.5,
      "2015c": 6.1,
      "2014c": 7.6,
      "2013c": 5.6
    },
    {
      "2013": 2707,
      "2014": 2910,
      "2015": 2588,
      "2016": 2651,
      "2017": 2356,
      "2018": 3148,
      "2019": 2596,
      "2020": "S",
      item: "IT related training services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 18.2,
      "2018c": 18.8,
      "2017c": 11,
      "2016c": 10.9,
      "2015c": 11.2,
      "2014c": 15.2,
      "2013c": 16.7
    },
    {
      "2013": 337128,
      "2014": 360995,
      "2015": 370302,
      "2016": 404970,
      "2017": 442827,
      "2018": 485553,
      "2019": 497222,
      "2020": 519068,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.1,
      "2019c": 1.7,
      "2018c": 1.2,
      "2017c": 0,
      "2016c": 2,
      "2015c": 1.9,
      "2014c": 1.5,
      "2013c": 1.3
    },
    {
      "2013": 83856,
      "2014": 87900,
      "2015": 98704,
      "2016": 111520,
      "2017": 115064,
      "2018": 124505,
      "2019": 126901,
      "2020": 134799,
      item: "Custom application design and development",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 4.9,
      "2018c": 3.3,
      "2017c": 3.4,
      "2016c": 4.1,
      "2015c": 2.9,
      "2014c": 4.3,
      "2013c": 3.5
    },
    {
      "2013": 33895,
      "2014": 37915,
      "2015": 46313,
      "2016": 52112,
      "2017": 59456,
      "2018": 61968,
      "2019": 63856,
      "2020": 61373,
      item: "IT technical consulting",
      taxexempt: "all",
      "2020c": 8.2,
      "2019c": 6,
      "2018c": 4.6,
      "2017c": 4.6,
      "2016c": 5.5,
      "2015c": 5.5,
      "2014c": 13.8,
      "2013c": 9.4
    },
    {
      "2013": 44308,
      "2014": 45670,
      "2015": 33416,
      "2016": "S",
      "2017": 39737,
      "2018": 40498,
      "2019": 44197,
      "2020": 45966,
      item: "IT technical support",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 4.9,
      "2018c": 3.4,
      "2017c": 3.9,
      "2016c": "S",
      "2015c": 3.8,
      "2014c": 5,
      "2013c": 4
    },
    {
      "2013": 47434,
      "2014": 50733,
      "2015": 31335,
      "2016": 32978,
      "2017": 34985,
      "2018": 39084,
      "2019": 40661,
      "2020": 43629,
      item: "IT infrastructure and network management",
      taxexempt: "all",
      "2020c": 9.5,
      "2019c": 5.7,
      "2018c": 4.8,
      "2017c": 3.1,
      "2016c": 2.7,
      "2015c": 2.6,
      "2014c": 2.2,
      "2013c": 2.5
    },
    {
      "2013": 16782,
      "2014": 17126,
      "2015": 16738,
      "2016": 18151,
      "2017": 21604,
      "2018": 27040,
      "2019": 28674,
      "2020": 31067,
      item: "Hosting and IT infrastructure provisioning services",
      taxexempt: "all",
      "2020c": 15.2,
      "2019c": 14.3,
      "2018c": 13.9,
      "2017c": 13.3,
      "2016c": 5.9,
      "2015c": 7,
      "2014c": 6.3,
      "2013c": 6.6
    },
    {
      "2013": 6272,
      "2014": 6420,
      "2015": 5752,
      "2016": 6118,
      "2017": 6371,
      "2018": 6568,
      "2019": 5998,
      "2020": 7135,
      item: "Network design and development",
      taxexempt: "all",
      "2020c": 12.6,
      "2019c": 15.9,
      "2018c": 15.6,
      "2017c": 12.7,
      "2016c": 12.6,
      "2015c": 11.4,
      "2014c": 17.1,
      "2013c": 16.8
    },
    {
      "2013": 1542,
      "2014": 1672,
      "2015": 1228,
      "2016": "S",
      "2017": 1192,
      "2018": "S",
      "2019": 1024,
      "2020": 1049,
      item: "Rental and leasing of computer hardware",
      taxexempt: "all",
      "2020c": 23,
      "2019c": 25.8,
      "2018c": "S",
      "2017c": 22.7,
      "2016c": "S",
      "2015c": 24.9,
      "2014c": 5.8,
      "2013c": 7.4
    }
  ],
  software: [
    {
      "2013": 5294,
      "2014": 6935,
      "2015": 8051,
      "2016": 8530,
      "2017": 11433,
      "2018": 14350,
      "2019": "S",
      "2020": "S",
      item: "System software publishing - Other systems software",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 17.8,
      "2017c": 7.9,
      "2016c": 8.6,
      "2015c": 4.6,
      "2014c": 13.1,
      "2013c": 15.8
    },
    {
      "2013": 18663,
      "2014": 20170,
      "2015": 28258,
      "2016": 31285,
      "2017": 31863,
      "2018": 35248,
      "2019": 32863,
      "2020": "S",
      item:
        "Application software publishing - Cross-industry application software",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 8.7,
      "2018c": 5.6,
      "2017c": 4.5,
      "2016c": 5.8,
      "2015c": 4.9,
      "2014c": 4.6,
      "2013c": 4.6
    },
    {
      "2013": 6855,
      "2014": 9279,
      "2015": 9133,
      "2016": 10167,
      "2017": 10063,
      "2018": 11227,
      "2019": 15366,
      "2020": "S",
      item: "Resale of computer hardware and software",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 3,
      "2018c": 3,
      "2017c": 1.9,
      "2016c": 2.9,
      "2015c": 2.4,
      "2014c": 9.4,
      "2013c": 9.1
    },
    {
      "2013": 6812,
      "2014": 7023,
      "2015": 6918,
      "2016": 8986,
      "2017": 11621,
      "2018": 11829,
      "2019": 12272,
      "2020": "S",
      item: "Information technology (IT) technical consulting services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 10,
      "2018c": 8.4,
      "2017c": 6.6,
      "2016c": 8.6,
      "2015c": 8.7,
      "2014c": 12.4,
      "2013c": 15
    },
    {
      "2013": 188711,
      "2014": 208911,
      "2015": 222564,
      "2016": 249823,
      "2017": 276234,
      "2018": 303612,
      "2019": 331159,
      "2020": 360605,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.3,
      "2018c": 0.9,
      "2017c": 0,
      "2016c": 1.4,
      "2015c": 1.1,
      "2014c": 0.9,
      "2013c": 0.6
    },
    {
      "2013": 33408,
      "2014": 38590,
      "2015": 46534,
      "2016": 52340,
      "2017": 60051,
      "2018": 66725,
      "2019": 86134,
      "2020": 91842,
      item:
        "Application software publishing - General business productivity and home use applications",
      taxexempt: "all",
      "2020c": 4.5,
      "2019c": 3.2,
      "2018c": 3.3,
      "2017c": 3.4,
      "2016c": 3.2,
      "2015c": 2.4,
      "2014c": 5.1,
      "2013c": 6.3
    },
    {
      "2013": "S",
      "2014": 37300,
      "2015": 37460,
      "2016": 44041,
      "2017": 49076,
      "2018": 47182,
      "2019": 48867,
      "2020": 44022,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 7.2,
      "2019c": 5.8,
      "2018c": 7,
      "2017c": 4.8,
      "2016c": 4.8,
      "2015c": 4.9,
      "2014c": 4,
      "2013c": "S"
    },
    {
      "2013": 20573,
      "2014": 20616,
      "2015": 21294,
      "2016": 23006,
      "2017": 25472,
      "2018": 28047,
      "2019": 27654,
      "2020": 36493,
      item: "System software publishing - Operating systems software",
      taxexempt: "all",
      "2020c": 6.6,
      "2019c": 8.1,
      "2018c": 7,
      "2017c": 5.6,
      "2016c": 6.8,
      "2015c": 5.2,
      "2014c": 3.4,
      "2013c": 3.3
    },
    {
      "2013": 18290,
      "2014": 19577,
      "2015": 14456,
      "2016": 16471,
      "2017": 18039,
      "2018": 23788,
      "2019": 22951,
      "2020": 27340,
      item: "System software publishing - Network software",
      taxexempt: "all",
      "2020c": 15.2,
      "2019c": 12.6,
      "2018c": 10.6,
      "2017c": 7,
      "2016c": 12.8,
      "2015c": 8.3,
      "2014c": 9.5,
      "2013c": 8.6
    },
    {
      "2013": 10099,
      "2014": 12234,
      "2015": 12544,
      "2016": 14229,
      "2017": 16504,
      "2018": 17752,
      "2019": 16235,
      "2020": 22166,
      item:
        "Application software publishing - Vertical market application software",
      taxexempt: "all",
      "2020c": 17.8,
      "2019c": 16.6,
      "2018c": 13.5,
      "2017c": 13.5,
      "2016c": 14.7,
      "2015c": 13,
      "2014c": 13.6,
      "2013c": 12.8
    },
    {
      "2013": 5269,
      "2014": 6570,
      "2015": 9914,
      "2016": 10655,
      "2017": 10624,
      "2018": 12793,
      "2019": 18161,
      "2020": 20890,
      item: "Application software publishing - Other application software",
      taxexempt: "all",
      "2020c": 13.5,
      "2019c": 16.1,
      "2018c": 4.9,
      "2017c": 2.7,
      "2016c": 3.6,
      "2015c": 3.5,
      "2014c": 8.8,
      "2013c": 13.4
    },
    {
      "2013": 13226,
      "2014": 14404,
      "2015": 15517,
      "2016": 16089,
      "2017": 17136,
      "2018": 17557,
      "2019": 18272,
      "2020": 19548,
      item: "System software publishing - Database management software",
      taxexempt: "all",
      "2020c": 3.6,
      "2019c": 4.9,
      "2018c": 2.7,
      "2017c": 8.1,
      "2016c": 3.3,
      "2015c": 2.4,
      "2014c": 4,
      "2013c": 2.8
    },
    {
      "2013": 6430,
      "2014": 6108,
      "2015": 3906,
      "2016": 4232,
      "2017": 4587,
      "2018": 6218,
      "2019": "S",
      "2020": 8792,
      item: "Custom application design and development",
      taxexempt: "all",
      "2020c": 26.6,
      "2019c": "S",
      "2018c": 26.9,
      "2017c": 21.5,
      "2016c": 17.1,
      "2015c": 11.6,
      "2014c": 10.1,
      "2013c": 7.2
    },
    {
      "2013": 3263,
      "2014": 3410,
      "2015": 2610,
      "2016": 3021,
      "2017": 3037,
      "2018": 4172,
      "2019": 4553,
      "2020": 5274,
      item: "Application software publishing - Utilities application software",
      taxexempt: "all",
      "2020c": 25.3,
      "2019c": 15.6,
      "2018c": 16.4,
      "2017c": 3.4,
      "2016c": 4.1,
      "2015c": 1.7,
      "2014c": 19.9,
      "2013c": 18.1
    },
    {
      "2013": 2081,
      "2014": 2099,
      "2015": 3316,
      "2016": 4118,
      "2017": 4110,
      "2018": 4049,
      "2019": 4022,
      "2020": 4078,
      item: "Information technology (IT) related training services",
      taxexempt: "all",
      "2020c": 6.6,
      "2019c": 6.7,
      "2018c": 7.3,
      "2017c": 12.8,
      "2016c": 11.5,
      "2015c": 6.3,
      "2014c": 5,
      "2013c": 7.3
    },
    {
      "2013": 4346,
      "2014": 4596,
      "2015": 2653,
      "2016": 2653,
      "2017": 2618,
      "2018": 2675,
      "2019": 2483,
      "2020": 3171,
      item:
        "System software publishing - Development tools and programming languages software",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 2.5,
      "2018c": 2.1,
      "2017c": 2.1,
      "2016c": 2.6,
      "2015c": 1.8,
      "2014c": 17.4,
      "2013c": 23.7
    }
  ],
  wireless: [
    {
      "2013": "S",
      "2014": "S",
      "2015": 3691,
      "2016": 2928,
      "2017": "S",
      "2018": 2185,
      "2019": "S",
      "2020": "S",
      item: "Messaging (paging) services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 1.6,
      "2017c": "S",
      "2016c": 0.8,
      "2015c": 0.5,
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 61838,
      "2014": 59563,
      "2015": 46089,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": 5755,
      "2020": "S",
      item: "Mobile telephony",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 1.9,
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 0.3,
      "2014c": 0.4,
      "2013c": 0.5
    },
    {
      "2013": 232458,
      "2014": 250391,
      "2015": 252264,
      "2016": 256298,
      "2017": 254082,
      "2018": 270220,
      "2019": 276114,
      "2020": 278868,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.4,
      "2019c": 0.9,
      "2018c": 0.2,
      "2017c": 0,
      "2016c": 0.3,
      "2015c": 0.3,
      "2014c": 0.4,
      "2013c": 0.1
    },
    {
      "2013": 66084,
      "2014": 76950,
      "2015": 90177,
      "2016": 96397,
      "2017": 94877,
      "2018": 89647,
      "2019": 92179,
      "2020": 96949,
      item: "Internet access services",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 0.4,
      "2018c": 0.4,
      "2017c": 0.3,
      "2016c": 0.4,
      "2015c": 0.4,
      "2014c": 0.2,
      "2013c": 0.3
    },
    {
      "2013": 59496,
      "2014": 53798,
      "2015": 46696,
      "2016": "S",
      "2017": "S",
      "2018": 81992,
      "2019": 82536,
      "2020": 86778,
      item: "Mobile all distance",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 0.6,
      "2018c": 0.3,
      "2017c": "S",
      "2016c": "S",
      "2015c": 0.4,
      "2014c": 0.6,
      "2013c": 0.2
    },
    {
      "2013": 24620,
      "2014": 35289,
      "2015": 41022,
      "2016": 43956,
      "2017": 46096,
      "2018": 56902,
      "2019": 58217,
      "2020": 56276,
      item: "Reselling services for telecommunications equipment, retail",
      taxexempt: "all",
      "2020c": 6,
      "2019c": 3.8,
      "2018c": 0.9,
      "2017c": 0.6,
      "2016c": 0.7,
      "2015c": 0.5,
      "2014c": 0.4,
      "2013c": 0.6
    },
    {
      "2013": 13684,
      "2014": 17183,
      "2015": 15691,
      "2016": 16700,
      "2017": 17833,
      "2018": 22195,
      "2019": 24052,
      "2020": 19364,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 3.9,
      "2019c": 2.8,
      "2018c": 2.1,
      "2017c": 1.9,
      "2016c": 3,
      "2015c": 2.8,
      "2014c": 4.8,
      "2013c": 2
    },
    {
      "2013": 432,
      "2014": 486,
      "2015": "S",
      "2016": "S",
      "2017": 4646,
      "2018": 6671,
      "2019": 6643,
      "2020": 6954,
      item: "Rental of telecommunications equipment",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 0.4,
      "2018c": 0.4,
      "2017c": 0.4,
      "2016c": "S",
      "2015c": "S",
      "2014c": 7.7,
      "2013c": 11.5
    },
    {
      "2013": "D",
      "2014": 4161,
      "2015": 2891,
      "2016": 2418,
      "2017": 2494,
      "2018": 2448,
      "2019": 2585,
      "2020": 1971,
      item: "Mobile long-distance",
      taxexempt: "all",
      "2020c": 4.5,
      "2019c": 3.9,
      "2018c": 0.6,
      "2017c": 0.2,
      "2016c": 0.4,
      "2015c": 0.3,
      "2014c": 0.6,
      "2013c": "D"
    },
    {
      "2013": "D",
      "2014": 2220,
      "2015": 2289,
      "2016": 2316,
      "2017": 1834,
      "2018": 1660,
      "2019": 1694,
      "2020": 1656,
      item: "Repair and maintenance services for telecommunications equipment",
      taxexempt: "all",
      "2020c": 5.1,
      "2019c": 3,
      "2018c": 2.8,
      "2017c": 1.6,
      "2016c": 1.3,
      "2015c": 1.1,
      "2014c": 1.2,
      "2013c": "D"
    },
    {
      "2013": 195,
      "2014": 206,
      "2015": 205,
      "2016": 239,
      "2017": 189,
      "2018": "S",
      "2019": 195,
      "2020": 267,
      item: "Telecommunications network installation services",
      taxexempt: "all",
      "2020c": 17.7,
      "2019c": 9.9,
      "2018c": "S",
      "2017c": 6.7,
      "2016c": 13.7,
      "2015c": 9.2,
      "2014c": 12.2,
      "2013c": 19
    }
  ],
  consulting: [
    {
      "2013": 180176,
      "2014": 192119,
      "2015": 197854,
      "2016": 208339,
      "2017": 214126,
      "2018": 224169,
      "2019": 236012,
      "2020": 241078,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 1.8,
      "2018c": 1.2,
      "2017c": 0,
      "2016c": 2.5,
      "2015c": 2.2,
      "2014c": 1.3,
      "2013c": 1
    },
    {
      "2013": 29951,
      "2014": 35118,
      "2015": 60175,
      "2016": 62032,
      "2017": 64542,
      "2018": 69641,
      "2019": 70450,
      "2020": 71641,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 4,
      "2018c": 2.9,
      "2017c": 3,
      "2016c": 3.8,
      "2015c": 3.4,
      "2014c": 4.5,
      "2013c": 3.8
    },
    {
      "2013": 48449,
      "2014": 49371,
      "2015": 49149,
      "2016": 51610,
      "2017": 51543,
      "2018": 51359,
      "2019": 54294,
      "2020": 57305,
      item:
        "Strategic management consulting, and consulting combined with implementation",
      taxexempt: "all",
      "2020c": 3.6,
      "2019c": 3.4,
      "2018c": 3.4,
      "2017c": 3,
      "2016c": 4.5,
      "2015c": 4.3,
      "2014c": 3.1,
      "2013c": 3.2
    },
    {
      "2013": 26883,
      "2014": 29091,
      "2015": 23783,
      "2016": 25224,
      "2017": 26827,
      "2018": 31748,
      "2019": 35932,
      "2020": 38670,
      item: "All other consulting revenue",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 7.3,
      "2018c": 5.7,
      "2017c": 4.7,
      "2016c": 7.7,
      "2015c": 8,
      "2014c": 6.9,
      "2013c": 4.7
    },
    {
      "2013": 21370,
      "2014": 22777,
      "2015": 20661,
      "2016": 22488,
      "2017": 21096,
      "2018": 21878,
      "2019": 22004,
      "2020": 21166,
      item:
        "Marketing management consulting, and consulting combined with implementation",
      taxexempt: "all",
      "2020c": 5.9,
      "2019c": 5.4,
      "2018c": 5.2,
      "2017c": 5,
      "2016c": 6.7,
      "2015c": 6.5,
      "2014c": 7.3,
      "2013c": 9.5
    },
    {
      "2013": 11788,
      "2014": 13908,
      "2015": 12616,
      "2016": 14093,
      "2017": 15701,
      "2018": 16486,
      "2019": 17674,
      "2020": 19044,
      item: "IT technical design, consulting, and development services",
      taxexempt: "all",
      "2020c": 10,
      "2019c": 8,
      "2018c": 7.6,
      "2017c": 6.2,
      "2016c": 6.4,
      "2015c": 6.9,
      "2014c": 4.7,
      "2013c": 4.9
    },
    {
      "2013": 12273,
      "2014": 12125,
      "2015": 9526,
      "2016": 9890,
      "2017": 11352,
      "2018": 11271,
      "2019": 12595,
      "2020": 12632,
      item:
        "Operations and supply chain management consulting, and consulting combined with implementation",
      taxexempt: "all",
      "2020c": 10.2,
      "2019c": 8.4,
      "2018c": 8.5,
      "2017c": 8.3,
      "2016c": 9.1,
      "2015c": 8.8,
      "2014c": 4.8,
      "2013c": 5
    },
    {
      "2013": 18558,
      "2014": 19146,
      "2015": 13167,
      "2016": 13652,
      "2017": 12607,
      "2018": 12367,
      "2019": 12498,
      "2020": 11527,
      item:
        "Human resources management consulting, and consulting combined with implementation",
      taxexempt: "all",
      "2020c": 5.1,
      "2019c": 4.5,
      "2018c": 5.3,
      "2017c": 4.1,
      "2016c": 5,
      "2015c": 4.9,
      "2014c": 4.7,
      "2013c": 4.6
    },
    {
      "2013": 8896,
      "2014": 8799,
      "2015": 6969,
      "2016": 7514,
      "2017": 8685,
      "2018": 7418,
      "2019": 7893,
      "2020": 7944,
      item:
        "Financial management consulting, and consulting combined with implementation",
      taxexempt: "all",
      "2020c": 15.4,
      "2019c": 8.7,
      "2018c": 8.7,
      "2017c": 11.7,
      "2016c": 10.3,
      "2015c": 10.2,
      "2014c": 7.8,
      "2013c": 7.3
    },
    {
      "2013": 2008,
      "2014": 1784,
      "2015": 1808,
      "2016": 1836,
      "2017": 1773,
      "2018": 2001,
      "2019": 2672,
      "2020": 1149,
      item:
        "Actuarial consulting (except for employee pensions and other benefits)",
      taxexempt: "all",
      "2020c": 18.2,
      "2019c": 10.4,
      "2018c": 7.5,
      "2017c": 13.2,
      "2016c": 7,
      "2015c": 7.1,
      "2014c": 9.1,
      "2013c": 14.6
    }
  ],
  socialwork: [
    {
      "2013": 110195,
      "2014": 114231,
      "2015": 119507,
      "2016": 125078,
      "2017": 135462,
      "2018": 141271,
      "2019": 145597,
      "2020": 157505,
      item: "Revenue",
      taxexempt: "TRUE",
      "2020c": 1.5,
      "2019c": 1.1,
      "2018c": 0.9,
      "2017c": 0,
      "2016c": 1.2,
      "2015c": 1.3,
      "2014c": 0.7,
      "2013c": 0.5
    },
    {
      "2013": 58460,
      "2014": 60567,
      "2015": 62587,
      "2016": 65186,
      "2017": 71814,
      "2018": 75098,
      "2019": 77029,
      "2020": 86819,
      item: "Contributions, gifts, and grants received",
      taxexempt: "TRUE",
      "2020c": 2.9,
      "2019c": 2,
      "2018c": 2,
      "2017c": 1.5,
      "2016c": 1.8,
      "2015c": 1.7,
      "2014c": 1.9,
      "2013c": 2
    },
    {
      "2013": 45979,
      "2014": 47515,
      "2015": 53081,
      "2016": 56032,
      "2017": 59444,
      "2018": 61742,
      "2019": 63854,
      "2020": 65787,
      item: "Program service",
      taxexempt: "TRUE",
      "2020c": 2.7,
      "2019c": 1.9,
      "2018c": 1.7,
      "2017c": 2,
      "2016c": 1.9,
      "2015c": 1.9,
      "2014c": 2.4,
      "2013c": 2.5
    },
    {
      "2013": 4513,
      "2014": 4814,
      "2015": 2803,
      "2016": 2796,
      "2017": 2976,
      "2018": 3063,
      "2019": 3315,
      "2020": 3448,
      item: "All other non-operating revenue",
      taxexempt: "TRUE",
      "2020c": 6.4,
      "2019c": 5.4,
      "2018c": 5.1,
      "2017c": 5.3,
      "2016c": 4.1,
      "2015c": 4.8,
      "2014c": 6,
      "2013c": 6.1
    },
    {
      "2013": 1243,
      "2014": 1335,
      "2015": 1036,
      "2016": 1064,
      "2017": 1228,
      "2018": 1368,
      "2019": 1399,
      "2020": 1451,
      item: "Investment and property income",
      taxexempt: "TRUE",
      "2020c": 10.1,
      "2019c": 10.5,
      "2018c": 10,
      "2017c": 11.7,
      "2016c": 14.1,
      "2015c": 14.4,
      "2014c": 7,
      "2013c": 7.4
    }
  ],
  skillednursecare: [
    {
      "2013": 110834,
      "2014": 112989,
      "2015": 115648,
      "2016": 119189,
      "2017": 118877,
      "2018": 120267,
      "2019": 124102,
      "2020": 130126,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.9,
      "2019c": 0.9,
      "2018c": 0.6,
      "2017c": 0,
      "2016c": 0.8,
      "2015c": 0.7,
      "2014c": 0.6,
      "2013c": 0.4
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 47846,
      "2016": 49630,
      "2017": 49233,
      "2018": 48789,
      "2019": 51052,
      "2020": 51577,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 1.4,
      "2019c": 1.2,
      "2018c": 1.1,
      "2017c": 0.8,
      "2016c": 1,
      "2015c": 0.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 30618,
      "2016": 30814,
      "2017": 30389,
      "2018": 30527,
      "2019": 30333,
      "2020": 32025,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 1.4,
      "2019c": 1.7,
      "2018c": 1.8,
      "2017c": 0.8,
      "2016c": 1.1,
      "2015c": 0.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 13991,
      "2016": 14855,
      "2017": 15915,
      "2018": 17002,
      "2019": 18074,
      "2020": 18402,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 2.1,
      "2018c": 2,
      "2017c": 1.5,
      "2016c": 2,
      "2015c": 1.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 10878,
      "2016": 11132,
      "2017": 10883,
      "2018": 11028,
      "2019": 10948,
      "2020": 10265,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 2.5,
      "2019c": 2.3,
      "2018c": 2.2,
      "2017c": 3.1,
      "2016c": 3.1,
      "2015c": 3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4133,
      "2016": 4079,
      "2017": 4391,
      "2018": 4813,
      "2019": 4888,
      "2020": 7087,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 3,
      "2019c": 3.2,
      "2018c": 1.8,
      "2017c": 2.7,
      "2016c": 2.7,
      "2015c": 3.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4282,
      "2016": 4472,
      "2017": 3856,
      "2018": 3943,
      "2019": 4423,
      "2020": 5258,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 4.1,
      "2018c": 5.1,
      "2017c": 4.4,
      "2016c": 3,
      "2015c": 2.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1650,
      "2016": 1728,
      "2017": 1703,
      "2018": 2021,
      "2019": 2082,
      "2020": 2302,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 5,
      "2019c": 4.6,
      "2018c": 6.9,
      "2017c": 3.3,
      "2016c": 4.5,
      "2015c": 4.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1491,
      "2016": 1533,
      "2017": 1506,
      "2018": 1362,
      "2019": 1320,
      "2020": 1343,
      item:
        "Social security benefits - direct payment of social security on behalf of patients",
      taxexempt: "all",
      "2020c": 4.2,
      "2019c": 3.6,
      "2018c": 3.8,
      "2017c": 4.2,
      "2016c": 4.5,
      "2015c": 4.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 381,
      "2016": 324,
      "2017": 308,
      "2018": 332,
      "2019": 347,
      "2020": 757,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 11.4,
      "2019c": 22.3,
      "2018c": 20.7,
      "2017c": 21.9,
      "2016c": 20.9,
      "2015c": 17.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 159,
      "2016": 204,
      "2017": 267,
      "2018": 101,
      "2019": 282,
      "2020": 276,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 13,
      "2019c": 10.7,
      "2018c": 29.8,
      "2017c": 12.8,
      "2016c": 15.9,
      "2015c": 21.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 177,
      "2016": 170,
      "2017": 168,
      "2018": "S",
      "2019": "S",
      "2020": 148,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 17.8,
      "2019c": "S",
      "2018c": "S",
      "2017c": 21.9,
      "2016c": 23.4,
      "2015c": 25.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 42,
      "2016": 37,
      "2017": 47,
      "2018": 55,
      "2019": 65,
      "2020": 82,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 20.6,
      "2019c": 15.4,
      "2018c": 15.9,
      "2017c": 8.9,
      "2016c": 7.2,
      "2015c": 11.1,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  depositories: [
    {
      "2013": 226656,
      "2014": 233743,
      "2015": 229612,
      "2016": 228390,
      "2017": 231475,
      "2018": 231992,
      "2019": 244393,
      "2020": 255485,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.8,
      "2019c": 0.8,
      "2018c": 0.5,
      "2017c": 0,
      "2016c": 0.8,
      "2015c": 0.7,
      "2014c": 0.6,
      "2013c": 0.5
    },
    {
      "2013": 58502,
      "2014": 64583,
      "2015": 59716,
      "2016": 59978,
      "2017": 59546,
      "2018": 56956,
      "2019": 62234,
      "2020": 63397,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.9,
      "2018c": 1.6,
      "2017c": 1.3,
      "2016c": 1.4,
      "2015c": 1.1,
      "2014c": 1.7,
      "2013c": 1.8
    },
    {
      "2013": 32262,
      "2014": 31983,
      "2015": 38771,
      "2016": 36859,
      "2017": 31699,
      "2018": 32497,
      "2019": 34087,
      "2020": 35145,
      item: "Brokering and dealing products - equities",
      taxexempt: "all",
      "2020c": 4.3,
      "2019c": 4.8,
      "2018c": 2.9,
      "2017c": 3,
      "2016c": 2.4,
      "2015c": 2.2,
      "2014c": 2.1,
      "2013c": 2.1
    },
    {
      "2013": 32207,
      "2014": 34184,
      "2015": 25402,
      "2016": 24808,
      "2017": 27710,
      "2018": 29135,
      "2019": 29533,
      "2020": 33288,
      item: "Securities origination products",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 2,
      "2018c": 1.9,
      "2017c": 1.6,
      "2016c": 1.5,
      "2015c": 2,
      "2014c": 1.9,
      "2013c": 1.5
    },
    {
      "2013": 18093,
      "2014": 19439,
      "2015": 18865,
      "2016": 19523,
      "2017": 30629,
      "2018": 28917,
      "2019": 30242,
      "2020": 31460,
      item:
        "Financial planning and investment management services for individuals",
      taxexempt: "all",
      "2020c": 2.5,
      "2019c": 2,
      "2018c": 1.7,
      "2017c": 2.1,
      "2016c": 3.2,
      "2015c": 3.2,
      "2014c": 1.3,
      "2013c": 1.2
    },
    {
      "2013": 25058,
      "2014": 24267,
      "2015": 28971,
      "2016": 28654,
      "2017": 24289,
      "2018": 25258,
      "2019": 26139,
      "2020": 27392,
      item: "Brokering and dealing products - debt instruments",
      taxexempt: "all",
      "2020c": 2.8,
      "2019c": 2.4,
      "2018c": 2.9,
      "2017c": 2.3,
      "2016c": 2.1,
      "2015c": 2.1,
      "2014c": 2.3,
      "2013c": 1.8
    },
    {
      "2013": 15203,
      "2014": 15409,
      "2015": 20687,
      "2016": 20079,
      "2017": 17718,
      "2018": 17644,
      "2019": 18087,
      "2020": 18400,
      item: "Brokering and dealing investment company securities",
      taxexempt: "all",
      "2020c": 5.1,
      "2019c": 5.3,
      "2018c": 4.8,
      "2017c": 3.3,
      "2016c": 2.2,
      "2015c": 2.3,
      "2014c": 3.1,
      "2013c": 3.5
    },
    {
      "2013": 8595,
      "2014": 8291,
      "2015": 11580,
      "2016": 11577,
      "2017": 11372,
      "2018": 12216,
      "2019": 12595,
      "2020": 13551,
      item:
        "Financial planning and investment management services for businesses and governments",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 4.6,
      "2018c": 6,
      "2017c": 5.8,
      "2016c": 6.3,
      "2015c": 5.6,
      "2014c": 3.3,
      "2013c": 3.6
    },
    {
      "2013": 15949,
      "2014": 15756,
      "2015": 7712,
      "2016": 7919,
      "2017": 7797,
      "2018": 9013,
      "2019": 9367,
      "2020": 9850,
      item: "Brokering and dealing products - derivative contracts",
      taxexempt: "all",
      "2020c": 2,
      "2019c": 1.6,
      "2018c": 1.7,
      "2017c": 0.9,
      "2016c": 1.2,
      "2015c": 1.2,
      "2014c": 3.1,
      "2013c": 2.3
    },
    {
      "2013": 5862,
      "2014": 5248,
      "2015": 4314,
      "2016": 4963,
      "2017": 7001,
      "2018": 7266,
      "2019": 7734,
      "2020": 7879,
      item: "Repurchase agreements - net gains (losses)",
      taxexempt: "all",
      "2020c": 1.1,
      "2019c": 1.1,
      "2018c": 1,
      "2017c": 1,
      "2016c": 1.8,
      "2015c": 1.9,
      "2014c": 3.8,
      "2013c": 4.3
    },
    {
      "2013": 2680,
      "2014": 3187,
      "2015": 6166,
      "2016": 6418,
      "2017": 7322,
      "2018": 7261,
      "2019": 7354,
      "2020": 7644,
      item: "Trading equities on own account - net gains (losses)",
      taxexempt: "all",
      "2020c": 1.5,
      "2019c": 1.6,
      "2018c": 2.6,
      "2017c": 6.2,
      "2016c": 7.1,
      "2015c": 1.9,
      "2014c": 9.9,
      "2013c": 10
    },
    {
      "2013": 5662,
      "2014": 5714,
      "2015": 6793,
      "2016": 7045,
      "2017": 5563,
      "2018": 5585,
      "2019": 6332,
      "2020": 6572,
      item: "Trading debt instruments on own account - net gains (losses)",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 1.9,
      "2018c": 2.6,
      "2017c": 3.4,
      "2016c": 3.1,
      "2015c": 3,
      "2014c": 1.5,
      "2013c": 2.2
    },
    {
      "2013": 6583,
      "2014": 5682,
      "2015": 635,
      "2016": 567,
      "2017": 829,
      "2018": 244,
      "2019": 689,
      "2020": 907,
      item: "Trading derivative contracts on own account - net gains (losses)",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1.1,
      "2018c": 4.4,
      "2017c": 1.4,
      "2016c": 2.6,
      "2015c": 2.8,
      "2014c": 15.4,
      "2013c": 11.7
    }
  ],
  fees: [
    {
      "2013": 69000,
      "2014": 75478,
      "2015": 81846,
      "2016": 90310,
      "2017": 96688,
      "2018": 101434,
      "2019": 108030,
      "2020": 112231,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 1.5,
      "2018c": 1.1,
      "2017c": 0,
      "2016c": 2,
      "2015c": 1.6,
      "2014c": 1.4,
      "2013c": 1
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 26109,
      "2016": 27339,
      "2017": 29737,
      "2018": 30225,
      "2019": 32634,
      "2020": 33930,
      item: "Residential building property management",
      taxexempt: "all",
      "2020c": 3.2,
      "2019c": 2.8,
      "2018c": 2.7,
      "2017c": 2.2,
      "2016c": 2.7,
      "2015c": 2.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 20588,
      "2016": 21932,
      "2017": 23885,
      "2018": 25394,
      "2019": 26696,
      "2020": 28471,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 5.3,
      "2019c": 5.4,
      "2018c": 4,
      "2017c": 3.2,
      "2016c": 3.2,
      "2015c": 3.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 14878,
      "2016": 18392,
      "2017": 19727,
      "2018": 21520,
      "2019": 23147,
      "2020": 23887,
      item: "Nonresidential building property management",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 2.4,
      "2018c": 2.4,
      "2017c": 2.6,
      "2016c": 3.3,
      "2015c": 3.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4849,
      "2016": 6185,
      "2017": 6655,
      "2018": 7848,
      "2019": 8639,
      "2020": 8309,
      item: "Real estate consulting services",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 5.1,
      "2018c": 4.9,
      "2017c": 4.3,
      "2016c": 3.9,
      "2015c": 5.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7443,
      "2016": 7771,
      "2017": 7862,
      "2018": 7937,
      "2019": 8161,
      "2020": 8108,
      item:
        "Agent and brokerage services for the sale and rental of residential real estate",
      taxexempt: "all",
      "2020c": 6.9,
      "2019c": 5.5,
      "2018c": 4.8,
      "2017c": 5.8,
      "2016c": 6.6,
      "2015c": 7.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5623,
      "2016": 6225,
      "2017": 6355,
      "2018": 5993,
      "2019": 6317,
      "2020": 7177,
      item: "Real estate appraisal services",
      taxexempt: "all",
      "2020c": 2.8,
      "2019c": 2.5,
      "2018c": 2.2,
      "2017c": 1.2,
      "2016c": 4,
      "2015c": 3.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2356,
      "2016": 2466,
      "2017": 2467,
      "2018": 2517,
      "2019": 2436,
      "2020": 2349,
      item:
        "Agent and brokerage services for the sale and rental of nonresidential real estate",
      taxexempt: "all",
      "2020c": 6.4,
      "2019c": 8.1,
      "2018c": 9.2,
      "2017c": 8.2,
      "2016c": 5.9,
      "2015c": 6.5,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  programming: [
    {
      "2013": 69297,
      "2014": 74404,
      "2015": 82061,
      "2016": 85929,
      "2017": 89101,
      "2018": 91424,
      "2019": 84740,
      "2020": 86249,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.4,
      "2019c": 0.6,
      "2018c": 0.2,
      "2017c": 0,
      "2016c": 0.4,
      "2015c": 0.3,
      "2014c": 0.3,
      "2013c": 0.1
    },
    {
      "2013": 39785,
      "2014": 43392,
      "2015": 50747,
      "2016": 53465,
      "2017": 55775,
      "2018": 58248,
      "2019": 53253,
      "2020": 54925,
      item:
        "Advertising and program revenue - Licensing of rights to broadcast specialty programming protected by copyright",
      taxexempt: "all",
      "2020c": 0.4,
      "2019c": 0.4,
      "2018c": 0.3,
      "2017c": 0.3,
      "2016c": 0.5,
      "2015c": 0.4,
      "2014c": 0.6,
      "2013c": 0.5
    },
    {
      "2013": 25057,
      "2014": 26733,
      "2015": 27305,
      "2016": 28196,
      "2017": 28771,
      "2018": 27379,
      "2019": 26057,
      "2020": 25260,
      item: "Air time",
      taxexempt: "all",
      "2020c": 0.4,
      "2019c": 0.3,
      "2018c": 0.4,
      "2017c": 0.3,
      "2016c": 0.3,
      "2015c": 0.2,
      "2014c": 0.4,
      "2013c": 0.7
    },
    {
      "2013": 4455,
      "2014": 4279,
      "2015": 4009,
      "2016": 4268,
      "2017": 4555,
      "2018": 5797,
      "2019": 5430,
      "2020": 6064,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 6.6,
      "2019c": 11.8,
      "2018c": 5.8,
      "2017c": 5.2,
      "2016c": 4.4,
      "2015c": 4.3,
      "2014c": 7.5,
      "2013c": 6.3
    }
  ],
  television: [
    {
      "2013": 44618,
      "2014": 49583,
      "2015": 50067,
      "2016": 56978,
      "2017": 56242,
      "2018": 61322,
      "2019": 62421,
      "2020": 64912,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.4,
      "2019c": 0.3,
      "2018c": 0.2,
      "2017c": 0,
      "2016c": 0.5,
      "2015c": 0.2,
      "2014c": 0.2,
      "2013c": 0.9
    },
    {
      "2013": 34115,
      "2014": 37029,
      "2015": 35274,
      "2016": 38709,
      "2017": 36064,
      "2018": 39816,
      "2019": 40426,
      "2020": 41054,
      item: "National/regional/local air time",
      taxexempt: "all",
      "2020c": 1.1,
      "2019c": 1.2,
      "2018c": 1.3,
      "2017c": 1.3,
      "2016c": 1,
      "2015c": 1.1,
      "2014c": 0.4,
      "2013c": 1
    },
    {
      "2013": 8390,
      "2014": 10323,
      "2015": 12682,
      "2016": 16105,
      "2017": 18198,
      "2018": 19281,
      "2019": 19755,
      "2020": 20271,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 2.5,
      "2019c": 2.4,
      "2018c": 2.6,
      "2017c": 2.5,
      "2016c": 3.3,
      "2015c": 3.5,
      "2014c": 0.6,
      "2013c": 1.1
    },
    {
      "2013": 2113,
      "2014": 2231,
      "2015": 2111,
      "2016": 2164,
      "2017": 1980,
      "2018": 2225,
      "2019": 2240,
      "2020": 3587,
      item: "Public and non-commercial programming services",
      taxexempt: "all",
      "2020c": 6.3,
      "2019c": 9.1,
      "2018c": 9.6,
      "2017c": 9.9,
      "2016c": 10.9,
      "2015c": 10.3,
      "2014c": 5.8,
      "2013c": 5.2
    }
  ],
  wastecollection: [
    {
      "2013": 42054,
      "2014": 45077,
      "2015": 46360,
      "2016": 49793,
      "2017": 53254,
      "2018": 57938,
      "2019": 61301,
      "2020": 60231,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 3.4,
      "2018c": 3,
      "2017c": 0,
      "2016c": 2.6,
      "2015c": 2.2,
      "2014c": 2.5,
      "2013c": 2.3
    },
    {
      "2013": 20707,
      "2014": 22718,
      "2015": 26793,
      "2016": 29456,
      "2017": 31480,
      "2018": 35552,
      "2019": 38285,
      "2020": 36326,
      item:
        "Non-residential nonhazardous waste and recyclable material collection services",
      taxexempt: "all",
      "2020c": 4.5,
      "2019c": 4.9,
      "2018c": 5.2,
      "2017c": 1.8,
      "2016c": 3.5,
      "2015c": 3.1,
      "2014c": 4.7,
      "2013c": 5.2
    },
    {
      "2013": 16159,
      "2014": 16745,
      "2015": 14540,
      "2016": 15715,
      "2017": 17555,
      "2018": 17962,
      "2019": 18510,
      "2020": 19094,
      item:
        "Residential nonhazardous waste and recyclable material collection services",
      taxexempt: "all",
      "2020c": 4.8,
      "2019c": 4.3,
      "2018c": 4.3,
      "2017c": 3.7,
      "2016c": 4.6,
      "2015c": 4.4,
      "2014c": 4.4,
      "2013c": 3.1
    },
    {
      "2013": 3581,
      "2014": 4020,
      "2015": 3867,
      "2016": 3988,
      "2017": 3541,
      "2018": 3677,
      "2019": 3734,
      "2020": 4081,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 10.5,
      "2019c": 11.3,
      "2018c": 9,
      "2017c": 9.1,
      "2016c": 9.7,
      "2015c": 9.5,
      "2014c": 11.7,
      "2013c": 12.3
    },
    {
      "2013": 1607,
      "2014": 1594,
      "2015": "S",
      "2016": 634,
      "2017": 678,
      "2018": 747,
      "2019": 772,
      "2020": 730,
      item: "Hazardous waste management collection services",
      taxexempt: "all",
      "2020c": 8,
      "2019c": 7.1,
      "2018c": 6.6,
      "2017c": 7.1,
      "2016c": 8.2,
      "2015c": "S",
      "2014c": 3.8,
      "2013c": 4.4
    }
  ],
  pipelinefees: [
    {
      "2013": 38857,
      "2014": 41938,
      "2015": 43891,
      "2016": 44627,
      "2017": 47192,
      "2018": 54125,
      "2019": 59522,
      "2020": 56319,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.3,
      "2019c": 0.4,
      "2018c": 0.1,
      "2017c": 0,
      "2016c": 0.1,
      "2015c": 0.1,
      "2014c": 0.1,
      "2013c": "Z"
    },
    {
      "2013": 21429,
      "2014": 22611,
      "2015": 23266,
      "2016": 23798,
      "2017": 25954,
      "2018": 28185,
      "2019": 32172,
      "2020": 30569,
      item:
        "Transportation of bulk natural gas and liquefied natural gas by pipeline",
      taxexempt: "all",
      "2020c": "Z",
      "2019c": 0.1,
      "2018c": "Z",
      "2017c": "Z",
      "2016c": "Z",
      "2015c": "Z",
      "2014c": "Z",
      "2013c": "Z"
    },
    {
      "2013": 7771,
      "2014": 9098,
      "2015": 9864,
      "2016": 9830,
      "2017": 10257,
      "2018": 13488,
      "2019": 14024,
      "2020": 13262,
      item: "Transportation of bulk crude oil by pipeline",
      taxexempt: "all",
      "2020c": 0.6,
      "2019c": 0.7,
      "2018c": 0.1,
      "2017c": 0.1,
      "2016c": 0.4,
      "2015c": 0.4,
      "2014c": "Z",
      "2013c": "Z"
    },
    {
      "2013": 6395,
      "2014": 6850,
      "2015": 7691,
      "2016": 7916,
      "2017": 8073,
      "2018": 8373,
      "2019": 9158,
      "2020": 8691,
      item:
        "Transportation of refined petroleum products (including ethylene and other petrochemicals) by pipeline",
      taxexempt: "all",
      "2020c": 0.7,
      "2019c": 0.6,
      "2018c": 0.6,
      "2017c": "Z",
      "2016c": "Z",
      "2015c": "Z",
      "2014c": 0.1,
      "2013c": 0.1
    },
    {
      "2013": 2949,
      "2014": 3063,
      "2015": 2624,
      "2016": 2651,
      "2017": 2481,
      "2018": 3378,
      "2019": 3496,
      "2020": 3142,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 0.7,
      "2018c": 0.4,
      "2017c": 0.3,
      "2016c": "Z",
      "2015c": "Z",
      "2014c": 0.1,
      "2013c": "Z"
    },
    {
      "2013": 313,
      "2014": 316,
      "2015": 446,
      "2016": 432,
      "2017": 427,
      "2018": 701,
      "2019": 672,
      "2020": 655,
      item: "Transportation of other bulk liquids and gases by pipeline",
      taxexempt: "all",
      "2020c": 4.4,
      "2019c": 4,
      "2018c": 0.1,
      "2017c": 0.2,
      "2016c": 0.4,
      "2015c": 0.4,
      "2014c": 8.9,
      "2013c": "Z"
    }
  ],
  advertising: [
    {
      "2013": 35747,
      "2014": 38124,
      "2015": 40699,
      "2016": 43592,
      "2017": 46241,
      "2018": 50220,
      "2019": 51186,
      "2020": 54457,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 4.9,
      "2019c": 5.1,
      "2018c": 3.1,
      "2017c": 0,
      "2016c": 4,
      "2015c": 3.6,
      "2014c": 4.5,
      "2013c": 2.6
    },
    {
      "2013": 18865,
      "2014": 20159,
      "2015": 21145,
      "2016": 22531,
      "2017": 23927,
      "2018": 26847,
      "2019": 28983,
      "2020": 29736,
      item: "Integrated advertising services",
      taxexempt: "all",
      "2020c": 6.7,
      "2019c": 7.4,
      "2018c": 5.3,
      "2017c": 2.4,
      "2016c": 6.1,
      "2015c": 5.7,
      "2014c": 5.9,
      "2013c": 4.6
    },
    {
      "2013": 5657,
      "2014": 6169,
      "2015": 7581,
      "2016": 8092,
      "2017": 8250,
      "2018": 7619,
      "2019": 7273,
      "2020": 8784,
      item: "Media buying",
      taxexempt: "all",
      "2020c": 12.2,
      "2019c": 8.9,
      "2018c": 9,
      "2017c": 6.8,
      "2016c": 7.5,
      "2015c": 7,
      "2014c": 18,
      "2013c": 14.2
    },
    {
      "2013": 1863,
      "2014": 1531,
      "2015": 2847,
      "2016": 3012,
      "2017": 3859,
      "2018": 4797,
      "2019": 4562,
      "2020": 4747,
      item: "Advertising creative services (including graphic design services)",
      taxexempt: "all",
      "2020c": 12.3,
      "2019c": 13.3,
      "2018c": 8.6,
      "2017c": 10,
      "2016c": 13.2,
      "2015c": 13.1,
      "2014c": 7.6,
      "2013c": 13.9
    },
    {
      "2013": 3753,
      "2014": 4124,
      "2015": 3018,
      "2016": 3183,
      "2017": 3343,
      "2018": 3811,
      "2019": 3655,
      "2020": 4127,
      item: "Other advertising services",
      taxexempt: "all",
      "2020c": 15.8,
      "2019c": 19.3,
      "2018c": 10.8,
      "2017c": 9.4,
      "2016c": 8.3,
      "2015c": 7.7,
      "2014c": 10.7,
      "2013c": 9.1
    },
    {
      "2013": 1957,
      "2014": 2361,
      "2015": 2445,
      "2016": 2888,
      "2017": 3080,
      "2018": 2697,
      "2019": 2955,
      "2020": 3088,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 16.5,
      "2019c": 19.2,
      "2018c": 15.2,
      "2017c": 17.4,
      "2016c": 16.3,
      "2015c": 15.1,
      "2014c": 19.7,
      "2013c": 19.1
    },
    {
      "2013": 1828,
      "2014": 1980,
      "2015": 1694,
      "2016": 1808,
      "2017": "S",
      "2018": 1917,
      "2019": "S",
      "2020": 1828,
      item: "Direct marketing",
      taxexempt: "all",
      "2020c": 13.1,
      "2019c": "S",
      "2018c": 17.9,
      "2017c": "S",
      "2016c": 17,
      "2015c": 16.2,
      "2014c": 24.3,
      "2013c": 24.4
    },
    {
      "2013": 917,
      "2014": 966,
      "2015": 1258,
      "2016": 1370,
      "2017": "S",
      "2018": 2026,
      "2019": 1585,
      "2020": 1607,
      item: "Sales promotion",
      taxexempt: "all",
      "2020c": 26.6,
      "2019c": 26.5,
      "2018c": 28.5,
      "2017c": "S",
      "2016c": 27,
      "2015c": 28,
      "2014c": 21.1,
      "2013c": 20.3
    },
    {
      "2013": 907,
      "2014": 834,
      "2015": 711,
      "2016": 708,
      "2017": 617,
      "2018": 506,
      "2019": 496,
      "2020": 540,
      item: "Marketing research",
      taxexempt: "all",
      "2020c": 9.3,
      "2019c": 10.2,
      "2018c": 7.3,
      "2017c": 7.6,
      "2016c": 6.4,
      "2015c": 5.5,
      "2014c": 9.3,
      "2013c": 11
    }
  ],
  wastetreatment: [
    {
      "2013": 15092,
      "2014": 16652,
      "2015": 16609,
      "2016": 17492,
      "2017": 18337,
      "2018": 18513,
      "2019": 19419,
      "2020": 19132,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 1.3,
      "2018c": 0.7,
      "2017c": 0,
      "2016c": 2,
      "2015c": 1.9,
      "2014c": 2.4,
      "2013c": 1.7
    },
    {
      "2013": 7692,
      "2014": 8409,
      "2015": 8574,
      "2016": 9215,
      "2017": 8929,
      "2018": 9037,
      "2019": 10344,
      "2020": 10200,
      item: "Nonhazardous waste disposal services",
      taxexempt: "all",
      "2020c": 2.4,
      "2019c": 2,
      "2018c": 1.7,
      "2017c": 1.4,
      "2016c": 3,
      "2015c": 2.9,
      "2014c": 2.3,
      "2013c": 2.1
    },
    {
      "2013": 5422,
      "2014": 5419,
      "2015": 5665,
      "2016": 5770,
      "2017": 6117,
      "2018": 5967,
      "2019": 6454,
      "2020": 6496,
      item: "Hazardous wastetreatment services",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 2,
      "2018c": 1.7,
      "2017c": 1.5,
      "2016c": 4,
      "2015c": 4,
      "2014c": 2.7,
      "2013c": 2.4
    },
    {
      "2013": 1978,
      "2014": 2824,
      "2015": 2370,
      "2016": 2507,
      "2017": 3291,
      "2018": 3509,
      "2019": 2621,
      "2020": 2436,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 6.6,
      "2019c": 5.6,
      "2018c": 4.5,
      "2017c": 5,
      "2016c": 2.9,
      "2015c": 3,
      "2014c": 10.1,
      "2013c": 7.8
    }
  ],
  releasingnetworking: [
    {
      "2013": 16103,
      "2014": 17242,
      "2015": 18137,
      "2016": 18220,
      "2017": 18495,
      "2018": 19037,
      "2019": 18825,
      "2020": 19129,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.9,
      "2018c": 0.9,
      "2017c": 0,
      "2016c": 2.4,
      "2015c": 1.8,
      "2014c": 1.6,
      "2013c": 1.3
    },
    {
      "2013": 6174,
      "2014": 6505,
      "2015": 7237,
      "2016": 7231,
      "2017": 7422,
      "2018": 7356,
      "2019": 6887,
      "2020": 7050,
      item: "Mobile telephony",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 1.1,
      "2018c": 1.1,
      "2017c": 1.1,
      "2016c": 3,
      "2015c": 2.4,
      "2014c": 3.2,
      "2013c": 2.6
    },
    {
      "2013": 2974,
      "2014": 4235,
      "2015": 3019,
      "2016": 3425,
      "2017": 3813,
      "2018": 5008,
      "2019": 5035,
      "2020": 5515,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 3.2,
      "2018c": 2.3,
      "2017c": 2.4,
      "2016c": 4,
      "2015c": 4.4,
      "2014c": 6.2,
      "2013c": 3.9
    },
    {
      "2013": 4194,
      "2014": 3970,
      "2015": 5630,
      "2016": 5350,
      "2017": 4942,
      "2018": "S",
      "2019": 5327,
      "2020": 4931,
      item: "Carrier services",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 5.4,
      "2018c": "S",
      "2017c": 1.5,
      "2016c": 3.7,
      "2015c": 3.2,
      "2014c": 3.8,
      "2013c": 3.4
    },
    {
      "2013": 1669,
      "2014": 1550,
      "2015": 1668,
      "2016": 1694,
      "2017": 1799,
      "2018": 1656,
      "2019": 1386,
      "2020": 1420,
      item: "Fixed local telephony",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 3.9,
      "2018c": 3,
      "2017c": 2,
      "2016c": 2.7,
      "2015c": 2.2,
      "2014c": 6,
      "2013c": 5.8
    },
    {
      "2013": 1092,
      "2014": "S",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 252,
      "2019": 190,
      "2020": 213,
      item: "Fixed long-distance telephony",
      taxexempt: "all",
      "2020c": 12.5,
      "2019c": 12.6,
      "2018c": 9,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "S",
      "2013c": 8
    }
  ],
  waterandsewage: [
    {
      "2013": 12643,
      "2014": 13570,
      "2015": 13938,
      "2016": 14152,
      "2017": 14595,
      "2018": 15122,
      "2019": 15498,
      "2020": 16009,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 1.2,
      "2018c": 1.7,
      "2017c": 0,
      "2016c": 2.4,
      "2015c": 2.2,
      "2014c": 1,
      "2013c": 0.8
    },
    {
      "2013": 8792,
      "2014": 9536,
      "2015": 11223,
      "2016": 11434,
      "2017": 11675,
      "2018": 11847,
      "2019": 12000,
      "2020": 12222,
      item: "Water sales",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 2.3,
      "2018c": 2.8,
      "2017c": 1,
      "2016c": 3,
      "2015c": 2.7,
      "2014c": 2.3,
      "2013c": 2
    },
    {
      "2013": 2469,
      "2014": 2624,
      "2015": 1598,
      "2016": 1610,
      "2017": 1824,
      "2018": 2201,
      "2019": 2613,
      "2020": 2778,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 9.5,
      "2019c": 12.1,
      "2018c": 10.3,
      "2017c": 6.3,
      "2016c": 5.5,
      "2015c": 5,
      "2014c": 6,
      "2013c": 6.8
    },
    {
      "2013": 1382,
      "2014": 1410,
      "2015": 1117,
      "2016": 1108,
      "2017": 1096,
      "2018": 1074,
      "2019": 885,
      "2020": 1009,
      item: "Sewer system user charges",
      taxexempt: "all",
      "2020c": 4.4,
      "2019c": 4.8,
      "2018c": 3.9,
      "2017c": 4.4,
      "2016c": 4.8,
      "2015c": 5.4,
      "2014c": 5.5,
      "2013c": 4.8
    }
  ],
  museumsandhistoricalsites: [
    {
      "2013": 14220,
      "2014": 15400,
      "2015": 14966,
      "2016": 15377,
      "2017": 17873,
      "2018": 17658,
      "2019": 19467,
      "2020": 15999,
      item: "Revenue",
      taxexempt: "TRUE",
      "2020c": 3.7,
      "2019c": 3.7,
      "2018c": 1.8,
      "2017c": 0,
      "2016c": 3.9,
      "2015c": 2.4,
      "2014c": 2.4,
      "2013c": 2.5
    },
    {
      "2013": 6857,
      "2014": 7480,
      "2015": 7695,
      "2016": 7948,
      "2017": 9344,
      "2018": 9012,
      "2019": 10325,
      "2020": 9271,
      item: "Contributions, gifts, and grants received",
      taxexempt: "TRUE",
      "2020c": 6.3,
      "2019c": 7.9,
      "2018c": 3.8,
      "2017c": 3.9,
      "2016c": 6.4,
      "2015c": 4,
      "2014c": 3.6,
      "2013c": 3.6
    },
    {
      "2013": 4199,
      "2014": 4596,
      "2015": 4611,
      "2016": 5064,
      "2017": 5457,
      "2018": 5631,
      "2019": 5920,
      "2020": 3856,
      item: "Program service",
      taxexempt: "TRUE",
      "2020c": 3.5,
      "2019c": 3.3,
      "2018c": 3.1,
      "2017c": 3.5,
      "2016c": 2.7,
      "2015c": 2.7,
      "2014c": 2.9,
      "2013c": 3.3
    },
    {
      "2013": 2046,
      "2014": 2060,
      "2015": 1164,
      "2016": 1147,
      "2017": 1425,
      "2018": 1358,
      "2019": 1459,
      "2020": 1490,
      item: "All other non-operating revenue",
      taxexempt: "TRUE",
      "2020c": 5.2,
      "2019c": 4.6,
      "2018c": 5.3,
      "2017c": 3.7,
      "2016c": 3.9,
      "2015c": 4.5,
      "2014c": 5.3,
      "2013c": 6.6
    },
    {
      "2013": 1118,
      "2014": 1264,
      "2015": 1496,
      "2016": 1218,
      "2017": 1647,
      "2018": 1657,
      "2019": 1763,
      "2020": 1382,
      item: "Investment and property income",
      taxexempt: "TRUE",
      "2020c": 6.4,
      "2019c": 7,
      "2018c": 7.9,
      "2017c": 5.7,
      "2016c": 3.9,
      "2015c": 4.2,
      "2014c": 5.7,
      "2013c": 3.9
    }
  ],
  radiostations: [
    {
      "2013": 12596,
      "2014": 12539,
      "2015": 12657,
      "2016": 13113,
      "2017": 12705,
      "2018": 12787,
      "2019": 12681,
      "2020": 10955,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.9,
      "2019c": 0.7,
      "2018c": 0.6,
      "2017c": 0,
      "2016c": 2.1,
      "2015c": 1.9,
      "2014c": 0.9,
      "2013c": 0.5
    },
    {
      "2013": 10620,
      "2014": 10556,
      "2015": 10801,
      "2016": 11257,
      "2017": 10832,
      "2018": 10804,
      "2019": 10708,
      "2020": 8636,
      item: "National/regional/local air time",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 0.9,
      "2018c": 1.2,
      "2017c": 0.8,
      "2016c": 2.1,
      "2015c": 2.1,
      "2014c": 0.9,
      "2013c": 0.9
    },
    {
      "2013": 1283,
      "2014": 1290,
      "2015": 1111,
      "2016": 1162,
      "2017": 1078,
      "2018": 1178,
      "2019": 1188,
      "2020": 1527,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 3.2,
      "2019c": 4.7,
      "2018c": 4.7,
      "2017c": 4.8,
      "2016c": 6.7,
      "2015c": 6,
      "2014c": 5,
      "2013c": 3.2
    },
    {
      "2013": 693,
      "2014": 693,
      "2015": 745,
      "2016": 694,
      "2017": 795,
      "2018": 805,
      "2019": 785,
      "2020": 792,
      item: "Public and non-commercial programming services",
      taxexempt: "all",
      "2020c": 12.5,
      "2019c": 8.9,
      "2018c": 11.8,
      "2017c": 10.3,
      "2016c": 11.9,
      "2015c": 11.2,
      "2014c": 14.6,
      "2013c": 16.7
    }
  ],
  radionetworking: [
    {
      "2013": 5745,
      "2014": 6104,
      "2015": 6661,
      "2016": 7293,
      "2017": 7922,
      "2018": 8361,
      "2019": 8910,
      "2020": 9773,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 0.5,
      "2018c": 0.2,
      "2017c": 0,
      "2016c": 1.2,
      "2015c": 1.1,
      "2014c": 0.9,
      "2013c": 1.1
    },
    {
      "2013": 4353,
      "2014": 4649,
      "2015": 4787,
      "2016": 5261,
      "2017": 5640,
      "2018": 5905,
      "2019": 6105,
      "2020": 6337,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 0.7,
      "2018c": 0.4,
      "2017c": 0.4,
      "2016c": 1.1,
      "2015c": 1.1,
      "2014c": 1.5,
      "2013c": 1.6
    },
    {
      "2013": 968,
      "2014": 1021,
      "2015": 1497,
      "2016": 1625,
      "2017": 1851,
      "2018": 1991,
      "2019": 2347,
      "2020": 2933,
      item: "National/regional/local air time",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1.4,
      "2018c": 1.8,
      "2017c": 1.6,
      "2016c": 2.7,
      "2015c": 2.1,
      "2014c": 5.5,
      "2013c": 5
    },
    {
      "2013": 424,
      "2014": 434,
      "2015": 377,
      "2016": 407,
      "2017": 431,
      "2018": 465,
      "2019": 458,
      "2020": 503,
      item: "Public and non-commercial programming services",
      taxexempt: "all",
      "2020c": 7.7,
      "2019c": 3.6,
      "2018c": 3.8,
      "2017c": 3.9,
      "2016c": 4,
      "2015c": 4.2,
      "2014c": 12.1,
      "2013c": 9.9
    }
  ],
  records: [
    {
      "2013": 4996,
      "2014": 5011,
      "2015": 5663,
      "2016": 6116,
      "2017": 7048,
      "2018": 7715,
      "2019": 8467,
      "2020": 8987,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1,
      "2019c": 1.1,
      "2018c": 0.6,
      "2017c": 0,
      "2016c": 0.4,
      "2015c": 0.4,
      "2014c": 0.6,
      "2013c": 0.8
    },
    {
      "2013": 3954,
      "2014": 3839,
      "2015": 4526,
      "2016": 4859,
      "2017": 5607,
      "2018": 6165,
      "2019": 6879,
      "2020": 7295,
      item: "Sale of recordings",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 1,
      "2018c": 0.9,
      "2017c": 0.4,
      "2016c": 0.6,
      "2015c": 0.6,
      "2014c": 0.9,
      "2013c": 1.2
    },
    {
      "2013": 657,
      "2014": 620,
      "2015": 674,
      "2016": 766,
      "2017": 891,
      "2018": 949,
      "2019": 1040,
      "2020": 1088,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 4.5,
      "2019c": 7.5,
      "2018c": 2,
      "2017c": 2.5,
      "2016c": 2.8,
      "2015c": 2.4,
      "2014c": 7.1,
      "2013c": 7.7
    },
    {
      "2013": 286,
      "2014": 440,
      "2015": 326,
      "2016": 365,
      "2017": 418,
      "2018": 455,
      "2019": 412,
      "2020": 459,
      item:
        "Licensing revenue - Licensing of rights to use musical compositions",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 1.5,
      "2018c": 9.9,
      "2017c": 5.6,
      "2016c": 2.1,
      "2015c": 2.8,
      "2014c": 3.2,
      "2013c": 8.9
    },
    {
      "2013": 99,
      "2014": 112,
      "2015": 137,
      "2016": 126,
      "2017": 132,
      "2018": 146,
      "2019": 136,
      "2020": 145,
      item: "Licensing revenue - Licensing of rights to use musical recordings",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 4,
      "2018c": 3.2,
      "2017c": 4.1,
      "2016c": 3.9,
      "2015c": 5.3,
      "2014c": 4.7,
      "2013c": 5.4
    }
  ],
  otherwaste: [
    {
      "2013": 4619,
      "2014": 4975,
      "2015": 5478,
      "2016": 5619,
      "2017": 5983,
      "2018": 6496,
      "2019": 6941,
      "2020": 6914,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.7,
      "2019c": 1.7,
      "2018c": 1.5,
      "2017c": 0,
      "2016c": 3.9,
      "2015c": 5.6,
      "2014c": 2,
      "2013c": 1.1
    },
    {
      "2013": 2746,
      "2014": 2937,
      "2015": 2870,
      "2016": 3188,
      "2017": 3539,
      "2018": 3741,
      "2019": 4133,
      "2020": 4289,
      item: "Other waste management services",
      taxexempt: "all",
      "2020c": 2.6,
      "2019c": 2.2,
      "2018c": 2.4,
      "2017c": 1.8,
      "2016c": 4.3,
      "2015c": 4.1,
      "2014c": 2.4,
      "2013c": 2.4
    },
    {
      "2013": 1071,
      "2014": 1197,
      "2015": 2161,
      "2016": 1959,
      "2017": 1999,
      "2018": 2270,
      "2019": 2380,
      "2020": 2215,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 3,
      "2018c": 3.2,
      "2017c": 2.9,
      "2016c": 5.2,
      "2015c": 14.7,
      "2014c": 3.6,
      "2013c": 4.5
    },
    {
      "2013": 503,
      "2014": 506,
      "2015": 409,
      "2016": 427,
      "2017": 419,
      "2018": 457,
      "2019": 394,
      "2020": 381,
      item:
        "Nonhazardous waste and recyclable material consolidation, storage, and preparation services",
      taxexempt: "all",
      "2020c": 9.2,
      "2019c": 9.4,
      "2018c": 7.7,
      "2017c": 9.1,
      "2016c": 13.6,
      "2015c": 13.6,
      "2014c": 10.9,
      "2013c": 9.3
    },
    {
      "2013": 299,
      "2014": "S",
      "2015": 38,
      "2016": 45,
      "2017": "S",
      "2018": 28,
      "2019": 34,
      "2020": 29,
      item: "Sale or brokerage of nonhazardous recyclable material",
      taxexempt: "all",
      "2020c": 19.2,
      "2019c": 17.8,
      "2018c": 17.8,
      "2017c": "S",
      "2016c": 8.4,
      "2015c": 8.4,
      "2014c": "S",
      "2013c": 7
    }
  ],
  musicpublishers: [
    {
      "2013": 4151,
      "2014": 4400,
      "2015": 4532,
      "2016": 4527,
      "2017": 4653,
      "2018": 5079,
      "2019": 5525,
      "2020": 5765,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.1,
      "2019c": 0.7,
      "2018c": 0.3,
      "2017c": 0,
      "2016c": 0.7,
      "2015c": 0.8,
      "2014c": 0.5,
      "2013c": 0.3
    },
    {
      "2013": 3364,
      "2014": 3491,
      "2015": 3732,
      "2016": 3717,
      "2017": 3751,
      "2018": 4041,
      "2019": 4484,
      "2020": 4698,
      item:
        "Licensing revenue - Licensing of rights to use musical compositions",
      taxexempt: "all",
      "2020c": 1.1,
      "2019c": 0.7,
      "2018c": 0.4,
      "2017c": 0.3,
      "2016c": 0.7,
      "2015c": 0.9,
      "2014c": 0.9,
      "2013c": 0.6
    },
    {
      "2013": 197,
      "2014": 220,
      "2015": 149,
      "2016": 140,
      "2017": 185,
      "2018": 260,
      "2019": 286,
      "2020": 353,
      item: "Administration of copyrights for others",
      taxexempt: "all",
      "2020c": 4.1,
      "2019c": 1.8,
      "2018c": 3.1,
      "2017c": 3.8,
      "2016c": 3.2,
      "2015c": 3.1,
      "2014c": 7.6,
      "2013c": 3.9
    },
    {
      "2013": 133,
      "2014": 184,
      "2015": 204,
      "2016": 224,
      "2017": 232,
      "2018": 290,
      "2019": 264,
      "2020": 239,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 10.2,
      "2019c": 8.3,
      "2018c": 4.8,
      "2017c": 3,
      "2016c": 2.8,
      "2015c": 3.5,
      "2014c": 6.5,
      "2013c": 7
    },
    {
      "2013": 166,
      "2014": 247,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 168,
      "2019": 176,
      "2020": 197,
      item: "Sale of recordings",
      taxexempt: "all",
      "2020c": 11.5,
      "2019c": 8.4,
      "2018c": 4.1,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 2.7,
      "2013c": 3.6
    },
    {
      "2013": 262,
      "2014": 225,
      "2015": 239,
      "2016": 223,
      "2017": 242,
      "2018": 208,
      "2019": 194,
      "2020": 157,
      item: "Print music",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1.6,
      "2018c": 2.9,
      "2017c": 1.9,
      "2016c": 2.3,
      "2015c": 2.3,
      "2014c": 2.7,
      "2013c": 2.2
    },
    {
      "2013": 29,
      "2014": 33,
      "2015": "S",
      "2016": "S",
      "2017": 104,
      "2018": 112,
      "2019": 121,
      "2020": 121,
      item: "Licensing revenue - Licensing of rights to use musical recordings",
      taxexempt: "all",
      "2020c": 4.1,
      "2019c": 3.6,
      "2018c": 7.7,
      "2017c": 9.6,
      "2016c": "S",
      "2015c": "S",
      "2014c": 7.9,
      "2013c": 21.2
    }
  ],
  legaloffices: [
    {
      "2013": 2909,
      "2014": 3012,
      "2015": 3178,
      "2016": 3348,
      "2017": 3818,
      "2018": 4201,
      "2019": 4456,
      "2020": 4845,
      item: "Revenue",
      taxexempt: "TRUE",
      "2020c": 7.6,
      "2019c": 7,
      "2018c": 7.1,
      "2017c": 0,
      "2016c": 8.2,
      "2015c": 7.7,
      "2014c": 4.2,
      "2013c": 6.2
    },
    {
      "2013": 2027,
      "2014": 2183,
      "2015": 2439,
      "2016": 2549,
      "2017": 2772,
      "2018": 2926,
      "2019": 2898,
      "2020": 3219,
      item: "Contributions, gifts, and grants received",
      taxexempt: "TRUE",
      "2020c": 11.1,
      "2019c": 10,
      "2018c": 9.1,
      "2017c": 9.6,
      "2016c": 11,
      "2015c": 10.1,
      "2014c": 7.4,
      "2013c": 9.9
    },
    {
      "2013": 765,
      "2014": 734,
      "2015": 516,
      "2016": 558,
      "2017": 766,
      "2018": 1083,
      "2019": 1333,
      "2020": 1349,
      item: "Program service",
      taxexempt: "TRUE",
      "2020c": 14,
      "2019c": 12.4,
      "2018c": 14.7,
      "2017c": 10.6,
      "2016c": 16.1,
      "2015c": 16.8,
      "2014c": 17.7,
      "2013c": 17.5
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 186,
      "2016": 202,
      "2017": 203,
      "2018": 171,
      "2019": "S",
      "2020": 226,
      item: "All other non-operating revenue",
      taxexempt: "TRUE",
      "2020c": 16.2,
      "2019c": "S",
      "2018c": 29.6,
      "2017c": 28.8,
      "2016c": 29.5,
      "2015c": 28.9,
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 37,
      "2016": 39,
      "2017": "S",
      "2018": 21,
      "2019": 50,
      "2020": 51,
      item: "Investment and property income",
      taxexempt: "TRUE",
      "2020c": 22.1,
      "2019c": 15.5,
      "2018c": 13.5,
      "2017c": "S",
      "2016c": 14,
      "2015c": 14.2,
      "2014c": "S",
      "2013c": "S"
    }
  ],
  classifieds: [
    {
      "2013": 7739,
      "2014": 7746,
      "2015": 7056,
      "2016": 6365,
      "2017": 4968,
      "2018": 5090,
      "2019": 4623,
      "2020": 4409,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.7,
      "2019c": 1.4,
      "2018c": 1.3,
      "2017c": 0,
      "2016c": 1.6,
      "2015c": 1.5,
      "2014c": 1.3,
      "2013c": 0.4
    },
    {
      "2013": 3163,
      "2014": 3094,
      "2015": 2572,
      "2016": 2435,
      "2017": 1653,
      "2018": 2432,
      "2019": 2480,
      "2020": 2038,
      item:
        "Directories, databases, and other collections of information - Subscriptions and sales",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 2.2,
      "2018c": 2.3,
      "2017c": 2.1,
      "2016c": 2.2,
      "2015c": 2.3,
      "2014c": 2,
      "2013c": 1.8
    },
    {
      "2013": 4200,
      "2014": 4239,
      "2015": 3712,
      "2016": 3079,
      "2017": 2770,
      "2018": 2066,
      "2019": 1618,
      "2020": 1781,
      item:
        "Directories, databases, and other collections of information - Advertising space",
      taxexempt: "all",
      "2020c": 2,
      "2019c": 2.7,
      "2018c": 2.6,
      "2017c": 1.8,
      "2016c": 2.6,
      "2015c": 2.3,
      "2014c": 2.2,
      "2013c": 1.4
    },
    {
      "2013": 297,
      "2014": 305,
      "2015": 470,
      "2016": 575,
      "2017": 302,
      "2018": 340,
      "2019": 333,
      "2020": 405,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 16.2,
      "2019c": 11.9,
      "2018c": 13,
      "2017c": 14.8,
      "2016c": 7.2,
      "2015c": 9,
      "2014c": 22.2,
      "2013c": 20.8
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 302,
      "2016": 276,
      "2017": 243,
      "2018": 252,
      "2019": 192,
      "2020": 185,
      item: "Rental or sale of mailing lists",
      taxexempt: "all",
      "2020c": 29.3,
      "2019c": 26.7,
      "2018c": 19.7,
      "2017c": 23.8,
      "2016c": 21.6,
      "2015c": 22.6,
      "2014c": "S",
      "2013c": "S"
    }
  ],
  touring: [
    {
      "2013": 5844,
      "2014": 6244,
      "2015": 6748,
      "2016": 7051,
      "2017": 8425,
      "2018": 9652,
      "2019": 9892,
      "2020": 3669,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 2.4,
      "2018c": 2,
      "2017c": 0,
      "2016c": 1.6,
      "2015c": 1.9,
      "2014c": 2.1,
      "2013c": 2.8
    },
    {
      "2013": 1760,
      "2014": 1835,
      "2015": 2050,
      "2016": 2124,
      "2017": 2883,
      "2018": 3539,
      "2019": 4010,
      "2020": 1391,
      item: "Pre-packaged tours - International travel",
      taxexempt: "all",
      "2020c": 7.8,
      "2019c": 3.6,
      "2018c": 3.7,
      "2017c": 4,
      "2016c": 4.4,
      "2015c": 4.3,
      "2014c": 5,
      "2013c": 5.7
    },
    {
      "2013": 1458,
      "2014": 1568,
      "2015": 2058,
      "2016": 2204,
      "2017": 2311,
      "2018": 2443,
      "2019": 2634,
      "2020": 1059,
      item: "Pre-packaged tours - Domestic travel",
      taxexempt: "all",
      "2020c": 8.7,
      "2019c": 6.2,
      "2018c": 6,
      "2017c": 6.6,
      "2016c": 6.6,
      "2015c": 6.2,
      "2014c": 5.6,
      "2013c": 7.3
    },
    {
      "2013": 1069,
      "2014": 1135,
      "2015": 1087,
      "2016": 1115,
      "2017": 1398,
      "2018": 1532,
      "2019": 1303,
      "2020": 611,
      item: "Customized group tours - Domestic travel",
      taxexempt: "all",
      "2020c": 9.9,
      "2019c": 11.1,
      "2018c": 10.3,
      "2017c": 10.8,
      "2016c": 10.8,
      "2015c": 10.8,
      "2014c": 8.8,
      "2013c": 9.8
    },
    {
      "2013": 1145,
      "2014": 1223,
      "2015": 1109,
      "2016": 1148,
      "2017": 1376,
      "2018": 1632,
      "2019": 1452,
      "2020": 389,
      item: "Customized group tours - International travel",
      taxexempt: "all",
      "2020c": 10.9,
      "2019c": 6.2,
      "2018c": 7,
      "2017c": 9.2,
      "2016c": 6.5,
      "2015c": 7.4,
      "2014c": 9.5,
      "2013c": 10.7
    },
    {
      "2013": 412,
      "2014": 483,
      "2015": 444,
      "2016": 460,
      "2017": 457,
      "2018": 506,
      "2019": 493,
      "2020": 219,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 9.8,
      "2019c": 11.7,
      "2018c": 14.1,
      "2017c": 19.4,
      "2016c": 16.1,
      "2015c": 14.4,
      "2014c": 17.3,
      "2013c": 16.5
    }
  ],
  greetings: [
    {
      "2013": 3590,
      "2014": 3512,
      "2015": 3473,
      "2016": 3371,
      "2017": 3732,
      "2018": 3552,
      "2019": 3004,
      "2020": 2931,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.6,
      "2019c": 0.4,
      "2018c": 0.1,
      "2017c": 0,
      "2016c": 0.6,
      "2015c": 0.5,
      "2014c": 0.3,
      "2013c": 0.3
    },
    {
      "2013": 2382,
      "2014": 2389,
      "2015": 2514,
      "2016": 2465,
      "2017": 2681,
      "2018": 2540,
      "2019": 2066,
      "2020": 1996,
      item: "Greeting cards",
      taxexempt: "all",
      "2020c": 0.7,
      "2019c": 0.7,
      "2018c": 0.7,
      "2017c": 0.5,
      "2016c": 0.5,
      "2015c": 0.5,
      "2014c": 1.5,
      "2013c": 1.5
    },
    {
      "2013": 1208,
      "2014": 1123,
      "2015": 959,
      "2016": 906,
      "2017": 1051,
      "2018": 1012,
      "2019": 938,
      "2020": 935,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 2.7,
      "2018c": 2,
      "2017c": 1.3,
      "2016c": 1.9,
      "2015c": 1.6,
      "2014c": 2.5,
      "2013c": 2.1
    }
  ],
  otherinformation: [
    {
      "2013": 2045,
      "2014": 2130,
      "2015": 2406,
      "2016": 2538,
      "2017": 2593,
      "2018": 2711,
      "2019": 2578,
      "2020": 2766,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 2.6,
      "2018c": 1.2,
      "2017c": 0,
      "2016c": 5.1,
      "2015c": 4.6,
      "2014c": 4,
      "2013c": 3.3
    },
    {
      "2013": 842,
      "2014": "S",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": 982,
      item: "Information search and retrieval services",
      taxexempt: "all",
      "2020c": 9.8,
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "S",
      "2013c": 5.5
    },
    {
      "2013": 595,
      "2014": 572,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": 930,
      "2020": 964,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 7.9,
      "2019c": 7.9,
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 6.1,
      "2013c": 8.5
    },
    {
      "2013": 608,
      "2014": 611,
      "2015": 914,
      "2016": 942,
      "2017": 1002,
      "2018": 1103,
      "2019": 901,
      "2020": 820,
      item: "Information services",
      taxexempt: "all",
      "2020c": 4.4,
      "2019c": 3.3,
      "2018c": 3,
      "2017c": 2.7,
      "2016c": 6.4,
      "2015c": 5.8,
      "2014c": 8.9,
      "2013c": 7.4
    }
  ],
  recording: [
    {
      "2013": 919,
      "2014": 936,
      "2015": 982,
      "2016": 954,
      "2017": 922,
      "2018": 1019,
      "2019": 1198,
      "2020": 1118,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 11.2,
      "2019c": 8.8,
      "2018c": 7.8,
      "2017c": 0,
      "2016c": 8.4,
      "2015c": 6.1,
      "2014c": 2,
      "2013c": 2.7
    },
    {
      "2013": 626,
      "2014": 601,
      "2015": 699,
      "2016": 651,
      "2017": 566,
      "2018": 546,
      "2019": 625,
      "2020": 580,
      item: "Studio recording",
      taxexempt: "all",
      "2020c": 10.1,
      "2019c": 9.4,
      "2018c": 7.6,
      "2017c": 9.3,
      "2016c": 9.6,
      "2015c": 9.6,
      "2014c": 3.7,
      "2013c": 3.3
    },
    {
      "2013": 249,
      "2014": 291,
      "2015": 229,
      "2016": 248,
      "2017": 291,
      "2018": 424,
      "2019": 516,
      "2020": 484,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 24.6,
      "2019c": 20.7,
      "2018c": 19.3,
      "2017c": 8.8,
      "2016c": 11.9,
      "2015c": 9.8,
      "2014c": 7.1,
      "2013c": 8.2
    },
    {
      "2013": 44,
      "2014": 44,
      "2015": 54,
      "2016": 55,
      "2017": 65,
      "2018": 49,
      "2019": 57,
      "2020": 54,
      item: "Sound recording studio rental and leasing",
      taxexempt: "all",
      "2020c": 25.9,
      "2019c": 25.8,
      "2018c": 21,
      "2017c": 15.9,
      "2016c": 17.6,
      "2015c": 19.4,
      "2014c": 26.2,
      "2013c": 24.6
    }
  ],
  ferryshipping: [
    {
      "2013": "S",
      "2014": "S",
      "2015": 1,
      "2016": 1,
      "2017": 1,
      "2018": 1,
      "2019": 1,
      "2020": "ZZ",
      item:
        "Participatory recreational services by water craft, except overnight cruises with cabin accommodation",
      taxexempt: "all",
      "2020c": 4.3,
      "2019c": 2.4,
      "2018c": 2,
      "2017c": 1.8,
      "2016c": 3.8,
      "2015c": 3.7,
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 385,
      "2014": 453,
      "2015": 452,
      "2016": 468,
      "2017": 512,
      "2018": 549,
      "2019": 459,
      "2020": "S",
      item:
        "Coastal and Great Lakes fixed-route, passenger transportation by water",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 3.9,
      "2018c": 1.8,
      "2017c": 1.3,
      "2016c": 4.4,
      "2015c": 4.1,
      "2014c": 4.1,
      "2013c": 3.8
    },
    {
      "2013": 51,
      "2014": 57,
      "2015": 88,
      "2016": 101,
      "2017": 120,
      "2018": "S",
      "2019": 181,
      "2020": "S",
      item: "Other transportation of passengers by water",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 5.4,
      "2018c": "S",
      "2017c": 6.6,
      "2016c": 8.1,
      "2015c": 9.8,
      "2014c": 24.6,
      "2013c": 26.6
    },
    {
      "2013": 24,
      "2014": 27,
      "2015": 17,
      "2016": 18,
      "2017": 20,
      "2018": 22,
      "2019": 21,
      "2020": "S",
      item: "Sightseeing by water",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 14.6,
      "2018c": 9.9,
      "2017c": 9.5,
      "2016c": 6.8,
      "2015c": 6.1,
      "2014c": 24.1,
      "2013c": 24.2
    },
    {
      "2013": 42892,
      "2014": 45361,
      "2015": 44373,
      "2016": 43366,
      "2017": 43010,
      "2018": 46042,
      "2019": 48902,
      "2020": 29653,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1,
      "2018c": 0.9,
      "2017c": 0,
      "2016c": 1.8,
      "2015c": 1.9,
      "2014c": 0.6,
      "2013c": 0.2
    },
    {
      "2013": 21321,
      "2014": 22375,
      "2015": 20826,
      "2016": 18912,
      "2017": 16428,
      "2018": 16543,
      "2019": 17235,
      "2020": 16180,
      item: "Transportation of freight and cargo by water",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 3,
      "2018c": 2.7,
      "2017c": 2.2,
      "2016c": 3.9,
      "2015c": 3.9,
      "2014c": 1.1,
      "2013c": 0.7
    },
    {
      "2013": 16999,
      "2014": 18237,
      "2015": 17966,
      "2016": 19113,
      "2017": "S",
      "2018": 23392,
      "2019": 24918,
      "2020": 6913,
      item: "Cruises",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 0.3,
      "2018c": 0.3,
      "2017c": "S",
      "2016c": 0.1,
      "2015c": 0.1,
      "2014c": 0.1,
      "2013c": 0.1
    },
    {
      "2013": 2479,
      "2014": 2480,
      "2015": 3525,
      "2016": 3354,
      "2017": 3576,
      "2018": 3834,
      "2019": 4461,
      "2020": 4574,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.8,
      "2018c": 1.6,
      "2017c": 1.6,
      "2016c": 2.2,
      "2015c": 3.8,
      "2014c": 3.2,
      "2013c": 3.5
    },
    {
      "2013": 1158,
      "2014": 1224,
      "2015": 1095,
      "2016": 1046,
      "2017": 1021,
      "2018": 1284,
      "2019": 1337,
      "2020": 1209,
      item: "Towing services by water",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 4.6,
      "2018c": 4.6,
      "2017c": 4.8,
      "2016c": 4.8,
      "2015c": 5.6,
      "2014c": 7.2,
      "2013c": 6.3
    },
    {
      "2013": 474,
      "2014": 507,
      "2015": 403,
      "2016": 353,
      "2017": 333,
      "2018": 294,
      "2019": 289,
      "2020": 276,
      item: "Harbor tugboat services",
      taxexempt: "all",
      "2020c": 15.2,
      "2019c": 16.2,
      "2018c": 13.7,
      "2017c": 11.7,
      "2016c": 10.1,
      "2015c": 11.4,
      "2014c": 14.2,
      "2013c": 15.9
    }
  ],
  kidneydialysis: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "ZZ",
      "2018": "ZZ",
      "2019": "ZZ",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 7.1,
      "2018c": 12.2,
      "2017c": 0.4,
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "ZZ",
      "2017": "S",
      "2018": "ZZ",
      "2019": "D",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "D",
      "2018c": 0.2,
      "2017c": "S",
      "2016c": 0.2,
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 8369,
      "2016": 9427,
      "2017": 10021,
      "2018": 10333,
      "2019": 11121,
      "2020": "S",
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 0.9,
      "2018c": 0.5,
      "2017c": 0.7,
      "2016c": 1.9,
      "2015c": 1.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": 176,
      "2018": "D",
      "2019": "D",
      "2020": "D",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "D",
      "2019c": "D",
      "2018c": "D",
      "2017c": 0.7,
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": 2382,
      "2018": "D",
      "2019": 2706,
      "2020": "D",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "D",
      "2019c": 1.3,
      "2018c": "D",
      "2017c": 1.1,
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 17870,
      "2014": 19534,
      "2015": 21488,
      "2016": 23489,
      "2017": 24677,
      "2018": 26563,
      "2019": 28132,
      "2020": 28903,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.1,
      "2019c": 0.6,
      "2018c": 0.3,
      "2017c": 0,
      "2016c": 1.4,
      "2015c": 1.5,
      "2014c": 1.2,
      "2013c": 0.6
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7905,
      "2016": 8288,
      "2017": 8671,
      "2018": 9753,
      "2019": 10147,
      "2020": 9875,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 1.2,
      "2018c": 0.7,
      "2017c": 0.6,
      "2016c": 1.6,
      "2015c": 1.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1364,
      "2016": 1723,
      "2017": 1748,
      "2018": 1852,
      "2019": 1888,
      "2020": 1942,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 1.1,
      "2019c": 1.1,
      "2018c": 1.1,
      "2017c": 1.1,
      "2016c": 1.4,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 825,
      "2016": 874,
      "2017": 961,
      "2018": 997,
      "2019": 1089,
      "2020": 1124,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 4.1,
      "2018c": 4.1,
      "2017c": 4.4,
      "2016c": 4.3,
      "2015c": 4.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 397,
      "2016": 400,
      "2017": 441,
      "2018": 479,
      "2019": 516,
      "2020": 542,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 3.4,
      "2018c": 3.1,
      "2017c": 3,
      "2016c": 4,
      "2015c": 5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 109,
      "2016": 116,
      "2017": 117,
      "2018": 179,
      "2019": 259,
      "2020": 213,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 17.4,
      "2019c": 17.5,
      "2018c": 5.6,
      "2017c": 0.5,
      "2016c": 0.4,
      "2015c": 0.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 125,
      "2016": 128,
      "2017": 145,
      "2018": 159,
      "2019": 167,
      "2020": 176,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 2.7,
      "2018c": 2.3,
      "2017c": 3.1,
      "2016c": 3.3,
      "2015c": 8.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5,
      "2016": 7,
      "2017": 10,
      "2018": 14,
      "2019": 18,
      "2020": 20,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "Z",
      "2019c": "Z",
      "2018c": "Z",
      "2017c": "Z",
      "2016c": 0.2,
      "2015c": 0.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "ZZ",
      "2016": 2,
      "2017": 4,
      "2018": 3,
      "2019": 4,
      "2020": 8,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 10.5,
      "2019c": 6,
      "2018c": 0.2,
      "2017c": "Z",
      "2016c": 0.2,
      "2015c": 0.2,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  familyplanners: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "ZZ",
      "2016": "ZZ",
      "2017": "ZZ",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 3.1,
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 317,
      "2016": 385,
      "2017": 450,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 10.4,
      "2016c": 5,
      "2015c": 3.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6,
      "2016": 7,
      "2017": "S",
      "2018": "S",
      "2019": "D",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "D",
      "2018c": "S",
      "2017c": "S",
      "2016c": 2.9,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6260,
      "2016": 6223,
      "2017": 7345,
      "2018": 7609,
      "2019": 8012,
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 7,
      "2018c": 8.3,
      "2017c": 7.4,
      "2016c": 6.1,
      "2015c": 6.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5927,
      "2016": 6532,
      "2017": 6790,
      "2018": 7478,
      "2019": 7939,
      "2020": "S",
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 3,
      "2018c": 3.7,
      "2017c": 3.5,
      "2016c": 2.8,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6,
      "2016": 8,
      "2017": 10,
      "2018": 3,
      "2019": 3,
      "2020": "S",
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 4,
      "2018c": 5.9,
      "2017c": 3.1,
      "2016c": 6.6,
      "2015c": 6.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": 8,
      "2017": 7,
      "2018": "D",
      "2019": "S",
      "2020": "D",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "D",
      "2019c": "S",
      "2018c": "D",
      "2017c": 12.9,
      "2016c": 8.1,
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": "D",
      "2018": "D",
      "2019": "D",
      "2020": "D",
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": "D",
      "2019c": "D",
      "2018c": "D",
      "2017c": "D",
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "D",
      "2017": "D",
      "2018": 144,
      "2019": 235,
      "2020": "D",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "D",
      "2019c": 11.1,
      "2018c": 11.4,
      "2017c": "D",
      "2016c": "D",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 122554,
      "2014": 131900,
      "2015": 143704,
      "2016": 156783,
      "2017": 168080,
      "2018": 176857,
      "2019": 186530,
      "2020": 193330,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 1.7,
      "2018c": 1.6,
      "2017c": 0,
      "2016c": 0.9,
      "2015c": 0.8,
      "2014c": 0.7,
      "2013c": 0.5
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 42130,
      "2016": 48441,
      "2017": 52805,
      "2018": 54787,
      "2019": 57404,
      "2020": 60257,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 2.7,
      "2019c": 2.1,
      "2018c": 2.2,
      "2017c": 2.4,
      "2016c": 1.6,
      "2015c": 1.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 31071,
      "2016": 32000,
      "2017": 32566,
      "2018": 33399,
      "2019": 35017,
      "2020": 35318,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 2.2,
      "2018c": 2.2,
      "2017c": 1.5,
      "2016c": 1.7,
      "2015c": 1.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 18111,
      "2016": 19576,
      "2017": 20638,
      "2018": 22875,
      "2019": 23919,
      "2020": 23736,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 1.5,
      "2019c": 1.7,
      "2018c": 1,
      "2017c": 1.3,
      "2016c": 2.2,
      "2015c": 2.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 14507,
      "2016": 15475,
      "2017": 17156,
      "2018": 18380,
      "2019": 19721,
      "2020": 20347,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 4.3,
      "2019c": 4.3,
      "2018c": 3.9,
      "2017c": 4,
      "2016c": 3.7,
      "2015c": 3.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 8144,
      "2016": 9094,
      "2017": 9635,
      "2018": 10173,
      "2019": 10658,
      "2020": 11203,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 5.3,
      "2019c": 5.5,
      "2018c": 5.2,
      "2017c": 3.7,
      "2016c": 5.4,
      "2015c": 5.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7479,
      "2016": 8167,
      "2017": 8892,
      "2018": 8960,
      "2019": 9715,
      "2020": 10878,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 7.4,
      "2019c": 8.4,
      "2018c": 9.9,
      "2017c": 10.1,
      "2016c": 5.5,
      "2015c": 6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4043,
      "2016": 4182,
      "2017": 4496,
      "2018": 4919,
      "2019": 5203,
      "2020": 5294,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 5.5,
      "2018c": 6.5,
      "2017c": 5.1,
      "2016c": 2.7,
      "2015c": 2.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3398,
      "2016": 3659,
      "2017": 3927,
      "2018": 4037,
      "2019": 4285,
      "2020": 4313,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 6.4,
      "2019c": 6.1,
      "2018c": 5.9,
      "2017c": 5.8,
      "2016c": 5.3,
      "2015c": 5.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 2286,
      "2014": 2329,
      "2015": 2477,
      "2016": 2716,
      "2017": 2968,
      "2018": 3143,
      "2019": 3300,
      "2020": 3219,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 4.6,
      "2018c": 4.3,
      "2017c": 0,
      "2016c": 4.5,
      "2015c": 4.1,
      "2014c": 1.3,
      "2013c": 1.9
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1921,
      "2016": 1929,
      "2017": 2056,
      "2018": 2408,
      "2019": 2566,
      "2020": 2481,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 4.3,
      "2018c": 3.6,
      "2017c": 3.2,
      "2016c": 5.3,
      "2015c": 4.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 638,
      "2016": 694,
      "2017": 847,
      "2018": 859,
      "2019": 877,
      "2020": 923,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 10.5,
      "2019c": 11.7,
      "2018c": 13,
      "2017c": 11.5,
      "2016c": 11.9,
      "2015c": 9.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 465,
      "2016": 576,
      "2017": 679,
      "2018": 624,
      "2019": 687,
      "2020": 681,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 6.8,
      "2018c": 10.6,
      "2017c": 13.6,
      "2016c": 13.2,
      "2015c": 14.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 536,
      "2016": 589,
      "2017": 560,
      "2018": 680,
      "2019": 663,
      "2020": 622,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 5.1,
      "2018c": 11.7,
      "2017c": 3.9,
      "2016c": 2.7,
      "2015c": 2.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 450,
      "2016": 483,
      "2017": 518,
      "2018": 558,
      "2019": 577,
      "2020": 609,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 3.9,
      "2018c": 3.4,
      "2017c": 3.3,
      "2016c": 3.4,
      "2015c": 3.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 248,
      "2016": 291,
      "2017": 344,
      "2018": 446,
      "2019": 530,
      "2020": 499,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 21.7,
      "2019c": 22.6,
      "2018c": 29.2,
      "2017c": 24.6,
      "2016c": 23.1,
      "2015c": 25.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 251,
      "2016": 242,
      "2017": 266,
      "2018": 273,
      "2019": 286,
      "2020": 323,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 4.5,
      "2019c": 4.4,
      "2018c": 4.8,
      "2017c": 5.1,
      "2016c": 5.9,
      "2015c": 5.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 120,
      "2016": 125,
      "2017": 124,
      "2018": 160,
      "2019": 137,
      "2020": 133,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 6.1,
      "2019c": 5,
      "2018c": 6.3,
      "2017c": 7.9,
      "2016c": 16.5,
      "2015c": 14.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": 24,
      "2017": 38,
      "2018": 12,
      "2019": 29,
      "2020": 28,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 6.5,
      "2019c": 4,
      "2018c": 4.9,
      "2017c": 5,
      "2016c": 6.6,
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  dentistoffices: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 9265,
      "2016": 9467,
      "2017": 8564,
      "2018": 7584,
      "2019": "S",
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 13,
      "2017c": 7.4,
      "2016c": 9.1,
      "2015c": 9.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 766,
      "2019": "S",
      "2020": "S",
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 20.1,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 248,
      "2016": "S",
      "2017": 197,
      "2018": 142,
      "2019": "S",
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 26.3,
      "2017c": 23.8,
      "2016c": "S",
      "2015c": 25,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5473,
      "2016": 5957,
      "2017": 6600,
      "2018": 7138,
      "2019": 7336,
      "2020": "S",
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 13.6,
      "2018c": 14.6,
      "2017c": 15.6,
      "2016c": 11.8,
      "2015c": 10.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 106797,
      "2014": 109891,
      "2015": 114804,
      "2016": 120974,
      "2017": 125878,
      "2018": 132139,
      "2019": 138468,
      "2020": 125487,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.4,
      "2018c": 1.1,
      "2017c": 0,
      "2016c": 1.5,
      "2015c": 1.1,
      "2014c": 1,
      "2013c": 0.9
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 48387,
      "2016": 51174,
      "2017": 54183,
      "2018": 57786,
      "2019": 60236,
      "2020": 54915,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 2.1,
      "2019c": 2.4,
      "2018c": 2.6,
      "2017c": 1.7,
      "2016c": 3,
      "2015c": 2.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 45436,
      "2016": 47884,
      "2017": 49917,
      "2018": 52997,
      "2019": 56622,
      "2020": 51243,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 2.8,
      "2018c": 2.1,
      "2017c": 2.4,
      "2016c": 2.9,
      "2015c": 2.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3017,
      "2016": 3099,
      "2017": 3133,
      "2018": 3082,
      "2019": 3363,
      "2020": 3012,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 15.8,
      "2019c": 13.6,
      "2018c": 15.1,
      "2017c": 15.7,
      "2016c": 15.7,
      "2015c": 17.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1396,
      "2016": 1505,
      "2017": 1577,
      "2018": 1737,
      "2019": 1868,
      "2020": 1609,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 16.1,
      "2019c": 15,
      "2018c": 12.6,
      "2017c": 13,
      "2016c": 14.2,
      "2015c": 15.1,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  homecare: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 68263,
      "2014": 71600,
      "2015": 75754,
      "2016": 79461,
      "2017": 84499,
      "2018": 89420,
      "2019": 97225,
      "2020": 102723,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 1.7,
      "2018c": 0.7,
      "2017c": 0,
      "2016c": 2.6,
      "2015c": 2.7,
      "2014c": 2,
      "2013c": 0.9
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15320,
      "2016": 16410,
      "2017": 17712,
      "2018": 18938,
      "2019": 20746,
      "2020": 21061,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 6.7,
      "2019c": 6.1,
      "2018c": 4.2,
      "2017c": 4.7,
      "2016c": 4.6,
      "2015c": 4.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 12770,
      "2016": 13558,
      "2017": 15101,
      "2018": 16079,
      "2019": 18121,
      "2020": 19795,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 5.1,
      "2019c": 6.2,
      "2018c": 7,
      "2017c": 6.3,
      "2016c": 7,
      "2015c": 6.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3424,
      "2016": 3446,
      "2017": 4122,
      "2018": 4863,
      "2019": "S",
      "2020": 5520,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 6.7,
      "2019c": "S",
      "2018c": 7.4,
      "2017c": 8.6,
      "2016c": 12.2,
      "2015c": 12.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3506,
      "2016": 3613,
      "2017": 4148,
      "2018": 4659,
      "2019": 4930,
      "2020": 4976,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 14.7,
      "2019c": 15.3,
      "2018c": 13.1,
      "2017c": 12.4,
      "2016c": 11.2,
      "2015c": 10.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3387,
      "2016": 3684,
      "2017": 3612,
      "2018": 3865,
      "2019": 4024,
      "2020": 4233,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 16.9,
      "2019c": 17.2,
      "2018c": 13.2,
      "2017c": 10.3,
      "2016c": 10.6,
      "2015c": 8.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1346,
      "2016": 1636,
      "2017": 2230,
      "2018": 2528,
      "2019": 2783,
      "2020": 2756,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 10.8,
      "2019c": 9.8,
      "2018c": 9.1,
      "2017c": 9.4,
      "2016c": 14.4,
      "2015c": 12.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 671,
      "2016": 618,
      "2017": 559,
      "2018": 511,
      "2019": 635,
      "2020": 738,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 9.9,
      "2018c": 7.6,
      "2017c": 9,
      "2016c": 13.7,
      "2015c": 15,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 502,
      "2016": 522,
      "2017": 486,
      "2018": 474,
      "2019": 527,
      "2020": 734,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 9.6,
      "2019c": 9.7,
      "2018c": 10.7,
      "2017c": 8.3,
      "2016c": 10.8,
      "2015c": 8.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 487,
      "2016": 465,
      "2017": 490,
      "2018": 523,
      "2019": 510,
      "2020": 564,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 20.9,
      "2019c": 14.8,
      "2018c": 15.2,
      "2017c": 14.9,
      "2016c": 9,
      "2015c": 6.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 72,
      "2016": 109,
      "2017": 174,
      "2018": 119,
      "2019": 194,
      "2020": 194,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 4.4,
      "2019c": 14.8,
      "2018c": 9.1,
      "2017c": 8.5,
      "2016c": 10.2,
      "2015c": 13.3,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  elderlycare: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 12,
      "2016": 12,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 19,
      "2015c": 19.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 547,
      "2016": 582,
      "2017": 663,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "Social security benefits - direct payment of social security on behalf of patients",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 19.6,
      "2016c": 16.8,
      "2015c": 16.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": 657,
      "2017": "S",
      "2018": 756,
      "2019": "S",
      "2020": "S",
      item: "All other government programs",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 19.3,
      "2017c": "S",
      "2016c": 18.5,
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 53606,
      "2014": 56336,
      "2015": 59358,
      "2016": 62275,
      "2017": 64822,
      "2018": 68093,
      "2019": 72578,
      "2020": 73948,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 1.1,
      "2018c": 1,
      "2017c": 0,
      "2016c": 1.2,
      "2015c": 1.1,
      "2014c": 1.5,
      "2013c": 1.2
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 29619,
      "2016": 30992,
      "2017": 31850,
      "2018": 33467,
      "2019": 34799,
      "2020": 33564,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 3.2,
      "2019c": 3,
      "2018c": 2.6,
      "2017c": 2.3,
      "2016c": 2.4,
      "2015c": 2.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 8404,
      "2016": 8910,
      "2017": 8948,
      "2018": 9585,
      "2019": 10803,
      "2020": 11954,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 4.9,
      "2019c": 5.6,
      "2018c": 4.9,
      "2017c": 5.5,
      "2016c": 5.3,
      "2015c": 4.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5309,
      "2016": 5414,
      "2017": 5847,
      "2018": 6411,
      "2019": 7251,
      "2020": 7796,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 7,
      "2019c": 7.7,
      "2018c": 7.8,
      "2017c": 4.6,
      "2016c": 5.8,
      "2015c": 5.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4827,
      "2016": 5208,
      "2017": 5694,
      "2018": 6062,
      "2019": 6407,
      "2020": 6921,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 6.9,
      "2019c": 6,
      "2018c": 6.3,
      "2017c": 6.3,
      "2016c": 5.8,
      "2015c": 5.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4331,
      "2016": 4801,
      "2017": 4938,
      "2018": 5181,
      "2019": 5482,
      "2020": 5691,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 6.8,
      "2019c": 7.9,
      "2018c": 6.3,
      "2017c": 4.6,
      "2016c": 5.1,
      "2015c": 5.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4189,
      "2016": 4284,
      "2017": 4422,
      "2018": 4423,
      "2019": 4429,
      "2020": 4180,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 4.9,
      "2019c": 6.8,
      "2018c": 6.5,
      "2017c": 7,
      "2016c": 6.6,
      "2015c": 6.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 525,
      "2016": 670,
      "2017": 982,
      "2018": 746,
      "2019": 1067,
      "2020": 1078,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 11.3,
      "2019c": 10.2,
      "2018c": 21.2,
      "2017c": 11.1,
      "2016c": 12.5,
      "2015c": 16,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 691,
      "2016": 647,
      "2017": 655,
      "2018": 677,
      "2019": 722,
      "2020": 884,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 7.8,
      "2019c": 9,
      "2018c": 9.3,
      "2017c": 10.5,
      "2016c": 13.7,
      "2015c": 15.5,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  otherresidentialcare: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "Social security benefits - direct payment of social security on behalf of patients",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1,
      "2016": 1,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 28.6,
      "2015c": 11,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 203,
      "2016": 194,
      "2017": 203,
      "2018": "S",
      "2019": 217,
      "2020": "S",
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 10.6,
      "2018c": "S",
      "2017c": 6,
      "2016c": 4.3,
      "2015c": 5.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 7908,
      "2014": 7832,
      "2015": 7815,
      "2016": 7808,
      "2017": 7805,
      "2018": 7896,
      "2019": 8140,
      "2020": 8447,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2,
      "2019c": 1.6,
      "2018c": 1.5,
      "2017c": 0,
      "2016c": 1.8,
      "2015c": 1.8,
      "2014c": 1.1,
      "2013c": 0.6
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3387,
      "2016": 3390,
      "2017": 3304,
      "2018": 3327,
      "2019": 3322,
      "2020": 3394,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 3,
      "2019c": 3.4,
      "2018c": 2.9,
      "2017c": 2.1,
      "2016c": 2.1,
      "2015c": 2.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1473,
      "2016": 1457,
      "2017": 1517,
      "2018": 1350,
      "2019": 1396,
      "2020": 1445,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 4.8,
      "2019c": 5.1,
      "2018c": 6.3,
      "2017c": 5.8,
      "2016c": 6.2,
      "2015c": 5.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 860,
      "2016": 846,
      "2017": 828,
      "2018": 985,
      "2019": 1061,
      "2020": 1144,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 8.4,
      "2019c": 9.2,
      "2018c": 12.2,
      "2017c": 8.2,
      "2016c": 7.5,
      "2015c": 7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 901,
      "2016": 902,
      "2017": 904,
      "2018": 940,
      "2019": 953,
      "2020": 987,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 15.1,
      "2019c": 13.4,
      "2018c": 11.7,
      "2017c": 12.2,
      "2016c": 11.5,
      "2015c": 11.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 317,
      "2016": 321,
      "2017": 312,
      "2018": 326,
      "2019": 360,
      "2020": 422,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 13.3,
      "2019c": 13,
      "2018c": 12.6,
      "2017c": 10.4,
      "2016c": 8,
      "2015c": 8.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 258,
      "2016": 273,
      "2017": 288,
      "2018": 301,
      "2019": 335,
      "2020": 367,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 11.3,
      "2019c": 11.1,
      "2018c": 9.5,
      "2017c": 10.2,
      "2016c": 13,
      "2015c": 13.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 163,
      "2016": 180,
      "2017": 196,
      "2018": 190,
      "2019": 249,
      "2020": 224,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 15.1,
      "2019c": 16.1,
      "2018c": 9.4,
      "2017c": 12,
      "2016c": 13.8,
      "2015c": 16.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 218,
      "2016": 208,
      "2017": 215,
      "2018": 225,
      "2019": 199,
      "2020": 180,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 12.9,
      "2019c": 11.9,
      "2018c": 11.9,
      "2017c": 14.4,
      "2016c": 18.2,
      "2015c": 18.5,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  optometristsoffice: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 488,
      "2016": 468,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 21.8,
      "2015c": 16.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 155,
      "2016": 169,
      "2017": 171,
      "2018": 176,
      "2019": "S",
      "2020": "S",
      item: "All other government programs",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 14.4,
      "2017c": 14.2,
      "2016c": 10.2,
      "2015c": 10.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1323,
      "2016": 1415,
      "2017": 1392,
      "2018": 1436,
      "2019": 1536,
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 10,
      "2018c": 10.4,
      "2017c": 8.9,
      "2016c": 6.7,
      "2015c": 6.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 142,
      "2016": 134,
      "2017": 123,
      "2018": 133,
      "2019": 132,
      "2020": "S",
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 29.2,
      "2018c": 27.1,
      "2017c": 27.3,
      "2016c": 26.4,
      "2015c": 26.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 12692,
      "2014": 13424,
      "2015": 14014,
      "2016": 14867,
      "2017": 15501,
      "2018": 16135,
      "2019": 16668,
      "2020": 15689,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.5,
      "2019c": 1.6,
      "2018c": 1.2,
      "2017c": 0,
      "2016c": 1.8,
      "2015c": 1.6,
      "2014c": 1.5,
      "2013c": 1.4
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5749,
      "2016": 6011,
      "2017": 6310,
      "2018": 6788,
      "2019": 6969,
      "2020": 6629,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 3.9,
      "2018c": 4.5,
      "2017c": 2.5,
      "2016c": 2.9,
      "2015c": 2.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4676,
      "2016": 4954,
      "2017": 5503,
      "2018": 5570,
      "2019": 5751,
      "2020": 5335,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 2.1,
      "2018c": 2,
      "2017c": 2.5,
      "2016c": 2.4,
      "2015c": 2.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 890,
      "2016": 980,
      "2017": 966,
      "2018": 983,
      "2019": 973,
      "2020": 906,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 11.1,
      "2019c": 9,
      "2018c": 10.4,
      "2017c": 9.5,
      "2016c": 11.3,
      "2015c": 10.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 528,
      "2016": 658,
      "2017": 544,
      "2018": 553,
      "2019": 572,
      "2020": 491,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 9.6,
      "2019c": 8.8,
      "2018c": 6.3,
      "2017c": 7.3,
      "2016c": 7,
      "2015c": 7.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 33,
      "2016": 34,
      "2017": 23,
      "2018": "S",
      "2019": 39,
      "2020": 36,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 26.8,
      "2019c": 29.1,
      "2018c": "S",
      "2017c": 27.7,
      "2016c": 27.1,
      "2015c": 24.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7,
      "2016": 10,
      "2017": "S",
      "2018": 11,
      "2019": 15,
      "2020": 12,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 16.9,
      "2019c": 29.4,
      "2018c": 16.6,
      "2017c": "S",
      "2016c": 29.9,
      "2015c": 14.4,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  voluntarycare: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 218,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 25.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 789,
      "2016": 819,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 8.5,
      "2015c": 6.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1,
      "2016": 2,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 28.2,
      "2015c": 29.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 863,
      "2016": 974,
      "2017": 991,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 9,
      "2016c": 11,
      "2015c": 8.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 625,
      "2016": 662,
      "2017": 676,
      "2018": 767,
      "2019": "S",
      "2020": "S",
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 9.9,
      "2017c": 9.7,
      "2016c": 4.8,
      "2015c": 4.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 19,
      "2016": 19,
      "2017": "S",
      "2018": 35,
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 23.8,
      "2017c": "S",
      "2016c": 21.5,
      "2015c": 20.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2025,
      "2016": 2331,
      "2017": 2297,
      "2018": 1984,
      "2019": 2251,
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 10.7,
      "2018c": 12.9,
      "2017c": 11.4,
      "2016c": 11.8,
      "2015c": 11.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1103,
      "2016": 1266,
      "2017": 1293,
      "2018": 1420,
      "2019": 1483,
      "2020": "S",
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 9.4,
      "2018c": 8.5,
      "2017c": 7.3,
      "2016c": 8.3,
      "2015c": 9.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 487,
      "2016": 475,
      "2017": 424,
      "2018": 474,
      "2019": 516,
      "2020": "S",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 10.6,
      "2018c": 6.4,
      "2017c": 10.5,
      "2016c": 6.2,
      "2015c": 6.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 15476,
      "2014": 16619,
      "2015": 18200,
      "2016": 20162,
      "2017": 21703,
      "2018": 23090,
      "2019": 24812,
      "2020": 25969,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 2.1,
      "2018c": 1.9,
      "2017c": 0,
      "2016c": 3.7,
      "2015c": 3.5,
      "2014c": 2.5,
      "2013c": 1.5
    },
    {
      "2013": 7009,
      "2014": 7559,
      "2015": 8417,
      "2016": 9150,
      "2017": 10002,
      "2018": 11029,
      "2019": 12335,
      "2020": 12673,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3,
      "2019c": 2.6,
      "2018c": 1.9,
      "2017c": 0,
      "2016c": 2.5,
      "2015c": 2.2,
      "2014c": 1.6,
      "2013c": 1.3
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5011,
      "2016": 5365,
      "2017": 5997,
      "2018": 6463,
      "2019": 7096,
      "2020": 7231,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 5.7,
      "2018c": 5,
      "2017c": 6,
      "2016c": 6.2,
      "2015c": 5.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3216,
      "2016": 3920,
      "2017": 4655,
      "2018": 5342,
      "2019": 5591,
      "2020": 5945,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 6.9,
      "2019c": 6.6,
      "2018c": 6.2,
      "2017c": 7.1,
      "2016c": 9.3,
      "2015c": 9.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3288,
      "2016": 3615,
      "2017": 3695,
      "2018": 3924,
      "2019": 3967,
      "2020": 4226,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 10.8,
      "2019c": 10.2,
      "2018c": 10.2,
      "2017c": 7.9,
      "2016c": 9.4,
      "2015c": 10.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2158,
      "2016": 2348,
      "2017": 2519,
      "2018": 2845,
      "2019": 3315,
      "2020": 3590,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 3.2,
      "2018c": 3.4,
      "2017c": 2.6,
      "2016c": 3.6,
      "2015c": 3.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1262,
      "2016": 1409,
      "2017": 1668,
      "2018": 2026,
      "2019": 2296,
      "2020": 2138,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 13.2,
      "2019c": 14.9,
      "2018c": 12.1,
      "2017c": 10.7,
      "2016c": 10.3,
      "2015c": 8.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1224,
      "2016": 1356,
      "2017": 1558,
      "2018": 1772,
      "2019": 1981,
      "2020": 2064,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 5.6,
      "2019c": 5,
      "2018c": 6.3,
      "2017c": 6,
      "2016c": 8.1,
      "2015c": 7.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1325,
      "2016": 1312,
      "2017": 1341,
      "2018": 1299,
      "2019": 1625,
      "2020": 1865,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 13.4,
      "2019c": 14.3,
      "2018c": 16.9,
      "2017c": 16.3,
      "2016c": 13.9,
      "2015c": 15.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1166,
      "2016": 1009,
      "2017": 1036,
      "2018": 1077,
      "2019": 1100,
      "2020": 1268,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 16.3,
      "2019c": 17.8,
      "2018c": 20.4,
      "2017c": 14,
      "2016c": 15.3,
      "2015c": 17.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 689,
      "2016": 721,
      "2017": 857,
      "2018": 862,
      "2019": 977,
      "2020": 940,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 8.6,
      "2019c": 7.8,
      "2018c": 6.3,
      "2017c": 5.5,
      "2016c": 4.9,
      "2015c": 6.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 548,
      "2016": 549,
      "2017": 644,
      "2018": 571,
      "2019": 588,
      "2020": 644,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 16.9,
      "2019c": 16.2,
      "2018c": 13.2,
      "2017c": 12.6,
      "2016c": 8.4,
      "2015c": 9.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 255,
      "2016": 281,
      "2017": 271,
      "2018": 295,
      "2019": 328,
      "2020": 309,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 9,
      "2019c": 8.9,
      "2018c": 9.6,
      "2017c": 9.9,
      "2016c": 10.6,
      "2015c": 10.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 148,
      "2016": 133,
      "2017": 124,
      "2018": 120,
      "2019": 117,
      "2020": 173,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 12.8,
      "2019c": 15.1,
      "2018c": 11,
      "2017c": 14.3,
      "2016c": 11.7,
      "2015c": 12.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 123,
      "2016": 129,
      "2017": 119,
      "2018": 136,
      "2019": 153,
      "2020": 139,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 8.5,
      "2019c": 9.5,
      "2018c": 10.9,
      "2017c": 8.1,
      "2016c": 8.8,
      "2015c": 9.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 59,
      "2016": 70,
      "2017": 80,
      "2018": 88,
      "2019": "S",
      "2020": 128,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 29.5,
      "2019c": "S",
      "2018c": 19,
      "2017c": 16.3,
      "2016c": 13,
      "2015c": 15.7,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  "HMO Medical Centers": [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": 438,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "Z",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 43,
      "2016": 48,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 0.1,
      "2015c": "Z",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": "D",
      "2018": "D",
      "2019": "D",
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "D",
      "2018c": "D",
      "2017c": "D",
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": "D",
      "2018": "D",
      "2019": "D",
      "2020": "D",
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": "D",
      "2019c": "D",
      "2018c": "D",
      "2017c": "D",
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": "D",
      "2018": "D",
      "2019": "D",
      "2020": "D",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "D",
      "2019c": "D",
      "2018c": "D",
      "2017c": "D",
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 25257,
      "2014": 27968,
      "2015": 29848,
      "2016": 31809,
      "2017": 34385,
      "2018": 36788,
      "2019": 39324,
      "2020": 40901,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.1,
      "2019c": 0.1,
      "2018c": "Z",
      "2017c": 0,
      "2016c": "Z",
      "2015c": "Z",
      "2014c": 0.1,
      "2013c": 0.1
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 18821,
      "2016": 19311,
      "2017": 19969,
      "2018": 21133,
      "2019": 22896,
      "2020": 23789,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "Z",
      "2019c": "Z",
      "2018c": "Z",
      "2017c": "Z",
      "2016c": "Z",
      "2015c": "Z",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 75,
      "2016": 67,
      "2017": "S",
      "2018": 80,
      "2019": 130,
      "2020": 123,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 1,
      "2019c": 0.8,
      "2018c": 1,
      "2017c": "S",
      "2016c": "Z",
      "2015c": "Z",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 63,
      "2016": 76,
      "2017": 73,
      "2018": 76,
      "2019": 78,
      "2020": 76,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 0.1,
      "2019c": 0.1,
      "2018c": "Z",
      "2017c": "Z",
      "2016c": "Z",
      "2015c": "Z",
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  otherfamilyplanners: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1262,
      "2016": 1308,
      "2017": "S",
      "2018": 1333,
      "2019": "S",
      "2020": "S",
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 7,
      "2017c": "S",
      "2016c": 5.9,
      "2015c": 6.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7631,
      "2016": 7599,
      "2017": 7507,
      "2018": 7492,
      "2019": 7060,
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 8.5,
      "2018c": 6.8,
      "2017c": 4,
      "2016c": 4.3,
      "2015c": 3.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 38906,
      "2014": 42428,
      "2015": 46334,
      "2016": 50017,
      "2017": 53438,
      "2018": 55444,
      "2019": 57648,
      "2020": 59898,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 5.4,
      "2018c": 4.9,
      "2017c": 0,
      "2016c": 1.6,
      "2015c": 1.5,
      "2014c": 0.9,
      "2013c": 0.6
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 10536,
      "2016": 12251,
      "2017": 12950,
      "2018": 12851,
      "2019": 13364,
      "2020": 14145,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 10.2,
      "2019c": 9.4,
      "2018c": 9.7,
      "2017c": 9.8,
      "2016c": 3.3,
      "2015c": 2.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6915,
      "2016": 7218,
      "2017": 7845,
      "2018": 8391,
      "2019": 8808,
      "2020": 8991,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 6.9,
      "2018c": 6.7,
      "2017c": 5.8,
      "2016c": 4.6,
      "2015c": 5.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5357,
      "2016": 5945,
      "2017": 6470,
      "2018": 6560,
      "2019": 6952,
      "2020": 7824,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 8.6,
      "2019c": 9.5,
      "2018c": 10.5,
      "2017c": 10.8,
      "2016c": 5.2,
      "2015c": 5.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4492,
      "2016": 4853,
      "2017": 4941,
      "2018": 5377,
      "2019": 5701,
      "2020": 5608,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 5.6,
      "2019c": 6.6,
      "2018c": 4.5,
      "2017c": 4.7,
      "2016c": 8.4,
      "2015c": 8.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3087,
      "2016": 3134,
      "2017": 3946,
      "2018": 4050,
      "2019": 4235,
      "2020": 4465,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 11.5,
      "2019c": 12.2,
      "2018c": 13.7,
      "2017c": 12,
      "2016c": 11.1,
      "2015c": 12.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2865,
      "2016": 3084,
      "2017": 3468,
      "2018": 3585,
      "2019": 3975,
      "2020": 4209,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 6.5,
      "2019c": 6.9,
      "2018c": 7.5,
      "2017c": 6.7,
      "2016c": 7.8,
      "2015c": 7.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1651,
      "2016": 1745,
      "2017": 1777,
      "2018": 1967,
      "2019": 2207,
      "2020": 2399,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 11,
      "2019c": 8.6,
      "2018c": 10.5,
      "2017c": 9,
      "2016c": 5.8,
      "2015c": 5.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1135,
      "2016": 1132,
      "2017": 1385,
      "2018": 1585,
      "2019": "S",
      "2020": 1761,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 14,
      "2019c": "S",
      "2018c": 20.1,
      "2017c": 15.7,
      "2016c": 8.9,
      "2015c": 6.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 946,
      "2016": 960,
      "2017": 986,
      "2018": 1326,
      "2019": 1382,
      "2020": 1317,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 5.8,
      "2019c": 4.4,
      "2018c": 4,
      "2017c": 4.8,
      "2016c": 6.7,
      "2015c": 5.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 301,
      "2016": 354,
      "2017": 434,
      "2018": 387,
      "2019": 383,
      "2020": 357,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 8,
      "2019c": 11.1,
      "2018c": 16.5,
      "2017c": 20.4,
      "2016c": 20.7,
      "2015c": 21.5,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  networking: [
    {
      "2013": 1365,
      "2014": 1669,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "Installation services for connections to program distribution networks",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 17.3,
      "2013c": 18.6
    },
    {
      "2013": 134,
      "2014": 179,
      "2015": 574,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Website hosting services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 28,
      "2014c": 3,
      "2013c": 4.9
    },
    {
      "2013": 568,
      "2014": 910,
      "2015": 984,
      "2016": "S",
      "2017": 873,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Repair and maintenance services for telecommunications equipment",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 20.7,
      "2016c": "S",
      "2015c": 16.6,
      "2014c": 7.1,
      "2013c": 8.5
    },
    {
      "2013": 1253,
      "2014": 1139,
      "2015": 2023,
      "2016": 1977,
      "2017": 1614,
      "2018": 2808,
      "2019": 3544,
      "2020": "S",
      item:
        "Fixed services - Fixed all distance telephony (no distinction between local or long distance)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 2.4,
      "2018c": 2.9,
      "2017c": 4.6,
      "2016c": 4.7,
      "2015c": 4.4,
      "2014c": 6.1,
      "2013c": 6.3
    },
    {
      "2013": 289645,
      "2014": 298811,
      "2015": 309485,
      "2016": 330437,
      "2017": 320758,
      "2018": 316766,
      "2019": 319252,
      "2020": 312008,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.3,
      "2019c": 0.3,
      "2018c": 0.1,
      "2017c": 0,
      "2016c": 1.2,
      "2015c": 0.5,
      "2014c": 0.3,
      "2013c": 0.2
    },
    {
      "2013": 70387,
      "2014": 73546,
      "2015": 79148,
      "2016": 88408,
      "2017": 93272,
      "2018": 97418,
      "2019": 100043,
      "2020": 103256,
      item: "Internet access services",
      taxexempt: "all",
      "2020c": 0.3,
      "2019c": 0.4,
      "2018c": 0.5,
      "2017c": 0.5,
      "2016c": 1.3,
      "2015c": 1.3,
      "2014c": 0.5,
      "2013c": 0.5
    },
    {
      "2013": 58772,
      "2014": 60442,
      "2015": 61709,
      "2016": 64502,
      "2017": 61766,
      "2018": 61348,
      "2019": 61599,
      "2020": 60721,
      item: "Basic programming package",
      taxexempt: "all",
      "2020c": 0.6,
      "2019c": 0.6,
      "2018c": 0.6,
      "2017c": 0.5,
      "2016c": 1.3,
      "2015c": 1.3,
      "2014c": 0.3,
      "2013c": 0.3
    },
    {
      "2013": 13391,
      "2014": 15861,
      "2015": 20974,
      "2016": 31685,
      "2017": 35131,
      "2018": 29423,
      "2019": 30608,
      "2020": 28600,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 2.1,
      "2019c": 1.9,
      "2018c": 1.7,
      "2017c": 1.6,
      "2016c": 2.4,
      "2015c": 2.4,
      "2014c": 4.7,
      "2013c": 4.6
    },
    {
      "2013": 21254,
      "2014": 21268,
      "2015": 23493,
      "2016": 24717,
      "2017": 23369,
      "2018": 22557,
      "2019": 22842,
      "2020": 20047,
      item: "Premium programming package",
      taxexempt: "all",
      "2020c": 0.6,
      "2019c": 0.6,
      "2018c": 0.7,
      "2017c": 0.5,
      "2016c": 1.3,
      "2015c": 1.3,
      "2014c": 0.5,
      "2013c": 0.4
    },
    {
      "2013": 16504,
      "2014": 15583,
      "2015": "S",
      "2016": 19061,
      "2017": "S",
      "2018": 20748,
      "2019": 19965,
      "2020": 17562,
      item: "Fixed long-distance telephony",
      taxexempt: "all",
      "2020c": 0.6,
      "2019c": 0.6,
      "2018c": 0.6,
      "2017c": "S",
      "2016c": 1.3,
      "2015c": "S",
      "2014c": 1.3,
      "2013c": 1.1
    },
    {
      "2013": 16443,
      "2014": 18714,
      "2015": 21706,
      "2016": "S",
      "2017": 13480,
      "2018": 14522,
      "2019": 14352,
      "2020": 14459,
      item: "Carrier services",
      taxexempt: "all",
      "2020c": 2,
      "2019c": 2.2,
      "2018c": 1.7,
      "2017c": 2,
      "2016c": "S",
      "2015c": 14.7,
      "2014c": 3.6,
      "2013c": 3.4
    },
    {
      "2013": 31309,
      "2014": 30602,
      "2015": 18980,
      "2016": 19651,
      "2017": 17378,
      "2018": 15423,
      "2019": 13762,
      "2020": 12843,
      item: "Fixed local telephony",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1.2,
      "2018c": 1,
      "2017c": 0.8,
      "2016c": 1.4,
      "2015c": 1.3,
      "2014c": 0.8,
      "2013c": 0.7
    },
    {
      "2013": 11598,
      "2014": 12215,
      "2015": 14364,
      "2016": 14045,
      "2017": 12254,
      "2018": 11188,
      "2019": 10600,
      "2020": 10771,
      item: "Rental and reselling services for program distribution equipment",
      taxexempt: "all",
      "2020c": 0.5,
      "2019c": 3.5,
      "2018c": 2,
      "2017c": 0.7,
      "2016c": 1.2,
      "2015c": 1.2,
      "2014c": 0.4,
      "2013c": 0.4
    },
    {
      "2013": 13021,
      "2014": 13102,
      "2015": 11828,
      "2016": 11438,
      "2017": 10418,
      "2018": 9746,
      "2019": 10030,
      "2020": 9495,
      item: "Internet telephony",
      taxexempt: "all",
      "2020c": 1.2,
      "2019c": 1.3,
      "2018c": 1.4,
      "2017c": 1.5,
      "2016c": 1.6,
      "2015c": 1.5,
      "2014c": 0.8,
      "2013c": 0.4
    },
    {
      "2013": 486,
      "2014": 596,
      "2015": 569,
      "2016": 1724,
      "2017": 4534,
      "2018": 7041,
      "2019": 7314,
      "2020": 8496,
      item: "Rental of telecommunications equipment",
      taxexempt: "all",
      "2020c": 0.7,
      "2019c": 0.7,
      "2018c": 0.7,
      "2017c": 0.5,
      "2016c": 1.4,
      "2015c": 1.9,
      "2014c": 2.5,
      "2013c": 2.1
    },
    {
      "2013": 19998,
      "2014": 19561,
      "2015": 17640,
      "2016": 17840,
      "2017": 10596,
      "2018": 7727,
      "2019": 8023,
      "2020": 6976,
      item: "Private network services",
      taxexempt: "all",
      "2020c": 5,
      "2019c": 6,
      "2018c": 6,
      "2017c": 4.3,
      "2016c": 2.4,
      "2015c": 2.3,
      "2014c": 2.2,
      "2013c": 2.1
    },
    {
      "2013": "S",
      "2014": 5055,
      "2015": 5780,
      "2016": 6428,
      "2017": 5702,
      "2018": 6561,
      "2019": 5884,
      "2020": 5838,
      item: "Air time",
      taxexempt: "all",
      "2020c": 0.9,
      "2019c": 1,
      "2018c": 0.9,
      "2017c": 0.9,
      "2016c": 1.5,
      "2015c": 1.6,
      "2014c": 0.4,
      "2013c": "S"
    },
    {
      "2013": 3567,
      "2014": 3453,
      "2015": 3263,
      "2016": 3266,
      "2017": 2945,
      "2018": 2831,
      "2019": 2292,
      "2020": 2123,
      item: "Subscriber line charges",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.1,
      "2018c": 1.1,
      "2017c": 1.3,
      "2016c": 1,
      "2015c": 1,
      "2014c": 1,
      "2013c": 0.9
    },
    {
      "2013": 1310,
      "2014": 1151,
      "2015": 1644,
      "2016": 1497,
      "2017": 1491,
      "2018": 816,
      "2019": 1866,
      "2020": 1838,
      item: "Reselling services for telecommunications equipment, retail",
      taxexempt: "all",
      "2020c": 10,
      "2019c": 1.7,
      "2018c": 7,
      "2017c": 7.1,
      "2016c": 4.1,
      "2015c": 4.1,
      "2014c": 4.7,
      "2013c": 7.7
    },
    {
      "2013": 506,
      "2014": 658,
      "2015": 906,
      "2016": 829,
      "2017": 856,
      "2018": 1315,
      "2019": "S",
      "2020": 1686,
      item: "Telecommunications network installation services",
      taxexempt: "all",
      "2020c": 20.1,
      "2019c": "S",
      "2018c": 15.3,
      "2017c": 16.1,
      "2016c": 12.6,
      "2015c": 13,
      "2014c": 27.5,
      "2013c": 14.2
    },
    {
      "2013": "S",
      "2014": 3107,
      "2015": 2922,
      "2016": 2936,
      "2017": 2829,
      "2018": 2020,
      "2019": 1629,
      "2020": 1399,
      item: "Pay-per-view",
      taxexempt: "all",
      "2020c": 0.6,
      "2019c": 0.6,
      "2018c": 0.6,
      "2017c": 0.5,
      "2016c": 1.3,
      "2015c": 1.3,
      "2014c": 0.4,
      "2013c": "S"
    }
  ],
  otherpublishers: [
    {
      "2013": 295,
      "2014": 253,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Sale of advertising space",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 20.2,
      "2013c": 17.7
    },
    {
      "2013": 957,
      "2014": 1017,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 1006,
      "2019": "S",
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 15.6,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 5.2,
      "2013c": 4.4
    },
    {
      "2013": 1252,
      "2014": 1270,
      "2015": 1220,
      "2016": 1320,
      "2017": 1312,
      "2018": 1338,
      "2019": 1384,
      "2020": 1356,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 11.1,
      "2019c": 11.1,
      "2018c": 10.7,
      "2017c": 0,
      "2016c": 19.4,
      "2015c": 20.6,
      "2014c": 3.1,
      "2013c": 1.9
    }
  ],
  booking: [
    {
      "2013": 148,
      "2014": 148,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Commissions or fees from cruises",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 14.2,
      "2013c": 12.4
    },
    {
      "2013": 227,
      "2014": 209,
      "2015": "S",
      "2016": "S",
      "2017": 324,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Commissions or fees from packaged tours",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 7.2,
      "2016c": "S",
      "2015c": "S",
      "2014c": 20.1,
      "2013c": 19.1
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": "S",
      "2016": "S",
      "2017": 159,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "Commissions or fees from airline seats, international destinations",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 29.2,
      "2016c": "S",
      "2015c": "S",
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 3702,
      "2014": 3635,
      "2015": 4871,
      "2016": "S",
      "2017": 4168,
      "2018": 4332,
      "2019": "S",
      "2020": "S",
      item:
        "Commissions or fees from automobile clubs and road and travel service",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 7.5,
      "2017c": 7.3,
      "2016c": "S",
      "2015c": 6.8,
      "2014c": 3.2,
      "2013c": 2.9
    },
    {
      "2013": 217,
      "2014": 265,
      "2015": "S",
      "2016": "S",
      "2017": 449,
      "2018": 447,
      "2019": "S",
      "2020": "S",
      item: "Commissions or fees from airline seats, domestic destinations",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 25.9,
      "2017c": 20.7,
      "2016c": "S",
      "2015c": "S",
      "2014c": 4.4,
      "2013c": 4.1
    },
    {
      "2013": 365,
      "2014": 389,
      "2015": "S",
      "2016": "S",
      "2017": 186,
      "2018": 201,
      "2019": "S",
      "2020": "S",
      item: "Commissions or fees from other reservation services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 2.7,
      "2017c": 2.6,
      "2016c": "S",
      "2015c": "S",
      "2014c": 9.3,
      "2013c": 9.8
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": "S",
      "2016": "S",
      "2017": 110,
      "2018": 118,
      "2019": "S",
      "2020": "S",
      item: "Commissions or fees from computerized reservation systems",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 2.5,
      "2017c": 2.3,
      "2016c": "S",
      "2015c": "S",
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 3790,
      "2014": 4104,
      "2015": 6599,
      "2016": 6847,
      "2017": 7112,
      "2018": 7498,
      "2019": 7869,
      "2020": "S",
      item: "Commissions or fees from event tickets",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 7.4,
      "2018c": 10.1,
      "2017c": 7.7,
      "2016c": 7.9,
      "2015c": 7.8,
      "2014c": 9,
      "2013c": 10.9
    },
    {
      "2013": 15063,
      "2014": 15645,
      "2015": 16435,
      "2016": 16620,
      "2017": 16996,
      "2018": 18005,
      "2019": 20441,
      "2020": 11361,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 4.8,
      "2019c": 3.7,
      "2018c": 4.7,
      "2017c": 0,
      "2016c": 4.1,
      "2015c": 3.2,
      "2014c": 1.3,
      "2013c": 0.8
    },
    {
      "2013": "S",
      "2014": 1899,
      "2015": 1969,
      "2016": 2090,
      "2017": 2272,
      "2018": 2174,
      "2019": 2932,
      "2020": 2169,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 12.4,
      "2019c": 14,
      "2018c": 17.9,
      "2017c": 14.9,
      "2016c": 13.6,
      "2015c": 15.1,
      "2014c": 4,
      "2013c": "S"
    },
    {
      "2013": 2449,
      "2014": 2633,
      "2015": 1166,
      "2016": 1083,
      "2017": 1136,
      "2018": 1575,
      "2019": "S",
      "2020": 748,
      item: "Commissions or fees from lodging",
      taxexempt: "all",
      "2020c": 20.3,
      "2019c": "S",
      "2018c": 23.4,
      "2017c": 21.9,
      "2016c": 18.7,
      "2015c": 19.8,
      "2014c": 3.8,
      "2013c": 3.4
    },
    {
      "2013": 1560,
      "2014": 1670,
      "2015": 552,
      "2016": 557,
      "2017": 592,
      "2018": 750,
      "2019": 640,
      "2020": 475,
      item: "Commissions or fees from trip planning",
      taxexempt: "all",
      "2020c": 7.4,
      "2019c": 18.8,
      "2018c": 26.5,
      "2017c": 18.3,
      "2016c": 15.3,
      "2015c": 16.4,
      "2014c": 17.1,
      "2013c": 16.6
    },
    {
      "2013": 161,
      "2014": 162,
      "2015": 86,
      "2016": 85,
      "2017": 66,
      "2018": 106,
      "2019": 150,
      "2020": 121,
      item:
        "Commissions or fees from other travel arrangement services revenue",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 3.2,
      "2018c": 3.4,
      "2017c": 4.4,
      "2016c": 16.3,
      "2015c": 16.7,
      "2014c": 4.3,
      "2013c": 4.3
    }
  ],
  sportclubs: [
    {
      "2013": 8585,
      "2014": 9120,
      "2015": 8701,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Admissions revenue (excluding admissions taxes)",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 6.4,
      "2014c": 7.9,
      "2013c": 7.2
    },
    {
      "2013": 24029,
      "2014": 26420,
      "2015": 28576,
      "2016": 30897,
      "2017": 34249,
      "2018": 35741,
      "2019": 37083,
      "2020": 26170,
      item: "Revenue",
      taxexempt: "FALSE",
      "2020c": 1.5,
      "2019c": 1.1,
      "2018c": 0.8,
      "2017c": 0,
      "2016c": 6.6,
      "2015c": 6.5,
      "2014c": 6.1,
      "2013c": 6
    },
    {
      "2013": 15444,
      "2014": 17300,
      "2015": 19875,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": 20594,
      item: "All other operating revenue",
      taxexempt: "FALSE",
      "2020c": 2,
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 6.7,
      "2014c": 5.9,
      "2013c": 5.8
    }
  ],
  nursecare: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 264,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 4.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 207347,
      "2014": 213900,
      "2015": 221931,
      "2016": 230599,
      "2017": 235524,
      "2018": 242367,
      "2019": 252503,
      "2020": 263015,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.7,
      "2019c": 0.6,
      "2018c": 0.7,
      "2017c": 0,
      "2016c": 0.5,
      "2015c": 0.5,
      "2014c": 0.5,
      "2013c": 0.4
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 68459,
      "2016": 71614,
      "2017": 73353,
      "2018": 74955,
      "2019": 78517,
      "2020": 80866,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.7,
      "2018c": 1.5,
      "2017c": 1.2,
      "2016c": 1.4,
      "2015c": 1.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 42566,
      "2016": 44295,
      "2017": 45010,
      "2018": 46963,
      "2019": 48187,
      "2020": 46373,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 2.4,
      "2019c": 2.4,
      "2018c": 2.2,
      "2017c": 2,
      "2016c": 1.9,
      "2015c": 2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 36503,
      "2016": 36890,
      "2017": 36637,
      "2018": 36727,
      "2019": 36534,
      "2020": 37929,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.8,
      "2018c": 2.3,
      "2017c": 1.4,
      "2016c": 1.5,
      "2015c": 1.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 22387,
      "2016": 24107,
      "2017": 25806,
      "2018": 27235,
      "2019": 28903,
      "2020": 29945,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 2.2,
      "2018c": 1.8,
      "2017c": 1.6,
      "2016c": 1.9,
      "2015c": 1.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15950,
      "2016": 16861,
      "2017": 16206,
      "2018": 17015,
      "2019": 18662,
      "2020": 20976,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 3.7,
      "2019c": 3.3,
      "2018c": 3.8,
      "2017c": 4,
      "2016c": 3.9,
      "2015c": 3.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15441,
      "2016": 15724,
      "2017": 15511,
      "2018": 16382,
      "2019": 17154,
      "2020": 17940,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 4.2,
      "2019c": 3.3,
      "2018c": 3.4,
      "2017c": 3.5,
      "2016c": 4.1,
      "2015c": 3.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 11262,
      "2016": 11360,
      "2017": 12444,
      "2018": 13357,
      "2019": 14027,
      "2020": 17013,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 3.6,
      "2019c": 4.1,
      "2018c": 4.2,
      "2017c": 3.9,
      "2016c": 3.1,
      "2015c": 3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4912,
      "2016": 4760,
      "2017": 4803,
      "2018": 4785,
      "2019": 5005,
      "2020": 5848,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 6.9,
      "2019c": 7.8,
      "2018c": 8.5,
      "2017c": 8.3,
      "2016c": 7.9,
      "2015c": 8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2854,
      "2016": 2925,
      "2017": 3027,
      "2018": 2921,
      "2019": 2882,
      "2020": 2940,
      item:
        "Social security benefits - direct payment of social security on behalf of patients",
      taxexempt: "all",
      "2020c": 7.7,
      "2019c": 7.5,
      "2018c": 6.6,
      "2017c": 6.2,
      "2016c": 6.4,
      "2015c": 6.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1081,
      "2016": 1247,
      "2017": 1811,
      "2018": 1284,
      "2019": 1863,
      "2020": 1780,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 6.8,
      "2019c": 6.1,
      "2018c": 12.5,
      "2017c": 7,
      "2016c": 7.8,
      "2015c": 8.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 252,
      "2016": 250,
      "2017": 247,
      "2018": 210,
      "2019": 212,
      "2020": 220,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 12.6,
      "2019c": 22,
      "2018c": 24,
      "2017c": 21.8,
      "2016c": 20.8,
      "2015c": 21.6,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  interventions: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 193,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 4.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 62,
      "2016": 67,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 26.9,
      "2015c": 28.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1851,
      "2016": 1963,
      "2017": 2062,
      "2018": 2243,
      "2019": 2241,
      "2020": "S",
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 14.4,
      "2018c": 13.6,
      "2017c": 12.2,
      "2016c": 15.2,
      "2015c": 15.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 34999,
      "2014": 36743,
      "2015": 39110,
      "2016": 41327,
      "2017": 44020,
      "2018": 46111,
      "2019": 47683,
      "2020": 50494,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 1.2,
      "2018c": 1,
      "2017c": 0,
      "2016c": 1.6,
      "2015c": 1.5,
      "2014c": 1.1,
      "2013c": 0.9
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 14885,
      "2016": 15874,
      "2017": 17522,
      "2018": 19164,
      "2019": 20105,
      "2020": 21381,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 4,
      "2018c": 3.6,
      "2017c": 3.6,
      "2016c": 3.7,
      "2015c": 4.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 9522,
      "2016": 9949,
      "2017": 9885,
      "2018": 10278,
      "2019": 10980,
      "2020": 11352,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 4.5,
      "2018c": 4.9,
      "2017c": 5,
      "2016c": 6.6,
      "2015c": 5.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3807,
      "2016": 4178,
      "2017": 4665,
      "2018": 4751,
      "2019": "S",
      "2020": 5485,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 6.5,
      "2019c": "S",
      "2018c": 4.4,
      "2017c": 4.2,
      "2016c": 8.2,
      "2015c": 7.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2367,
      "2016": 2332,
      "2017": 2323,
      "2018": 2426,
      "2019": 2540,
      "2020": 2762,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 12.6,
      "2019c": 13.5,
      "2018c": 14.3,
      "2017c": 14.9,
      "2016c": 14.5,
      "2015c": 14.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2404,
      "2016": 2633,
      "2017": 2574,
      "2018": 2502,
      "2019": 2375,
      "2020": 2620,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 10.5,
      "2019c": 11.6,
      "2018c": 13.2,
      "2017c": 12.6,
      "2016c": 12.2,
      "2015c": 11.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1503,
      "2016": 1546,
      "2017": 1894,
      "2018": 1807,
      "2019": 1528,
      "2020": 1708,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 21,
      "2019c": 17.3,
      "2018c": 23.4,
      "2017c": 24.4,
      "2016c": 12.2,
      "2015c": 12.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1493,
      "2016": 1598,
      "2017": 1623,
      "2018": 1570,
      "2019": 1555,
      "2020": 1490,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 20.6,
      "2019c": 19.6,
      "2018c": 24.8,
      "2017c": 19.6,
      "2016c": 16.5,
      "2015c": 15.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 789,
      "2016": 785,
      "2017": 831,
      "2018": 862,
      "2019": 816,
      "2020": 817,
      item:
        "Social security benefits - direct payment of social security on behalf of patients",
      taxexempt: "all",
      "2020c": 10.7,
      "2019c": 10,
      "2018c": 10.4,
      "2017c": 9.9,
      "2016c": 9.1,
      "2015c": 9.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 234,
      "2016": 193,
      "2017": 366,
      "2018": 247,
      "2019": 265,
      "2020": 202,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 12.5,
      "2019c": 13,
      "2018c": 16.5,
      "2017c": 19.4,
      "2016c": 14.4,
      "2015c": 11.9,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  cashmedical: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 73,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 11.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 45,
      "2016": 54,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 3.3,
      "2015c": 2.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2123,
      "2016": 2366,
      "2017": 2534,
      "2018": 2744,
      "2019": "S",
      "2020": "S",
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 5.2,
      "2017c": 4.2,
      "2016c": 6.8,
      "2015c": 6.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2168,
      "2016": 2258,
      "2017": 2226,
      "2018": 2206,
      "2019": "S",
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 8.8,
      "2017c": 9.4,
      "2016c": 14.2,
      "2015c": 14.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 480,
      "2016": 491,
      "2017": 577,
      "2018": 525,
      "2019": "S",
      "2020": "S",
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 18.3,
      "2017c": 22.5,
      "2016c": 26.5,
      "2015c": 26.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 596,
      "2016": "D",
      "2017": "D",
      "2018": "S",
      "2019": "D",
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "D",
      "2018c": "S",
      "2017c": "D",
      "2016c": "D",
      "2015c": 10.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": "D",
      "2018": "D",
      "2019": "D",
      "2020": "D",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "D",
      "2019c": "D",
      "2018c": "D",
      "2017c": "D",
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "D",
      "2016": "D",
      "2017": 534,
      "2018": "D",
      "2019": 588,
      "2020": "D",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "D",
      "2019c": 22.2,
      "2018c": "D",
      "2017c": 23.1,
      "2016c": "D",
      "2015c": "D",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 22759,
      "2014": 23022,
      "2015": 25357,
      "2016": 28590,
      "2017": 30909,
      "2018": 31829,
      "2019": 33314,
      "2020": 34440,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 1.9,
      "2018c": 1.1,
      "2017c": 0,
      "2016c": 4,
      "2015c": 3.2,
      "2014c": 2.5,
      "2013c": 2.2
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 13452,
      "2016": 15455,
      "2017": 16653,
      "2018": 16866,
      "2019": 17655,
      "2020": 17889,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 5.1,
      "2019c": 2.1,
      "2018c": 1.8,
      "2017c": 1.3,
      "2016c": 4.1,
      "2015c": 3.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3784,
      "2016": 4265,
      "2017": 4553,
      "2018": 5024,
      "2019": 5199,
      "2020": 5114,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 4.4,
      "2019c": 4.3,
      "2018c": 3.6,
      "2017c": 3.9,
      "2016c": 3.9,
      "2015c": 2.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 785,
      "2016": 919,
      "2017": 1028,
      "2018": 1052,
      "2019": 1158,
      "2020": 1272,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 9.2,
      "2019c": 7.3,
      "2018c": 5.7,
      "2017c": 8.1,
      "2016c": 5.2,
      "2015c": 4.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 917,
      "2016": 905,
      "2017": 1013,
      "2018": 1023,
      "2019": 1121,
      "2020": 1099,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 7.5,
      "2019c": 6.6,
      "2018c": 6.4,
      "2017c": 4.3,
      "2016c": 9.1,
      "2015c": 7.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 444,
      "2016": 471,
      "2017": 527,
      "2018": 576,
      "2019": 613,
      "2020": 617,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 15.7,
      "2019c": 17.3,
      "2018c": 14,
      "2017c": 11.8,
      "2016c": 11.9,
      "2015c": 11.8,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  therapyoffices: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 47,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 21.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 334,
      "2016": 347,
      "2017": 371,
      "2018": 414,
      "2019": 440,
      "2020": "S",
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 5.8,
      "2018c": 6.1,
      "2017c": 7,
      "2016c": 6.9,
      "2015c": 6.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 482,
      "2016": 298,
      "2017": 283,
      "2018": 318,
      "2019": 370,
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 13.6,
      "2018c": 13.3,
      "2017c": 13.5,
      "2016c": 11,
      "2015c": 9.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 7187,
      "2014": 7607,
      "2015": 7922,
      "2016": 8600,
      "2017": 8982,
      "2018": 10105,
      "2019": 11465,
      "2020": 12849,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 1.4,
      "2018c": 1.2,
      "2017c": 0,
      "2016c": 3,
      "2015c": 2.2,
      "2014c": 1.5,
      "2013c": 1
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2409,
      "2016": 2684,
      "2017": 2899,
      "2018": 3336,
      "2019": 3898,
      "2020": 4515,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 4.5,
      "2019c": 5,
      "2018c": 5.7,
      "2017c": 4.6,
      "2016c": 4.2,
      "2015c": 5.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1536,
      "2016": 1638,
      "2017": 1890,
      "2018": 2286,
      "2019": 2619,
      "2020": 2867,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 5.2,
      "2019c": 5.1,
      "2018c": 4.9,
      "2017c": 4.8,
      "2016c": 6,
      "2015c": 5.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1162,
      "2016": 1302,
      "2017": 1292,
      "2018": 1398,
      "2019": 1552,
      "2020": 1786,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 8.2,
      "2019c": 6.8,
      "2018c": 6.1,
      "2017c": 7.2,
      "2016c": 7.7,
      "2015c": 6.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 892,
      "2016": 987,
      "2017": 881,
      "2018": 886,
      "2019": 1039,
      "2020": 1186,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 11.2,
      "2019c": 9.5,
      "2018c": 9.2,
      "2017c": 6.1,
      "2016c": 6.4,
      "2015c": 5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 715,
      "2016": 755,
      "2017": 757,
      "2018": 898,
      "2019": 940,
      "2020": 946,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 8,
      "2019c": 8.1,
      "2018c": 6.8,
      "2017c": 7,
      "2016c": 4.1,
      "2015c": 4.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 154,
      "2016": 172,
      "2017": 206,
      "2018": 207,
      "2019": 240,
      "2020": 287,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 20.9,
      "2019c": 19.8,
      "2018c": 18.8,
      "2017c": 18,
      "2016c": 17.3,
      "2015c": 15.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 105,
      "2016": 95,
      "2017": 96,
      "2018": 93,
      "2019": 83,
      "2020": 93,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 16.6,
      "2019c": 18.1,
      "2018c": 15.2,
      "2017c": 15.6,
      "2016c": 16.7,
      "2015c": 19.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 86,
      "2016": 50,
      "2017": "S",
      "2018": 53,
      "2019": "S",
      "2020": 62,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 22.2,
      "2019c": "S",
      "2018c": 25.3,
      "2017c": "S",
      "2016c": 23.7,
      "2015c": 25.5,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  leasingoffices: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 28,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Real estate appraisal services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 28,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 285,
      "2016": 316,
      "2017": 312,
      "2018": 301,
      "2019": 288,
      "2020": "S",
      item: "Real estate consulting services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 19.4,
      "2018c": 17.2,
      "2017c": 13.9,
      "2016c": 13.4,
      "2015c": 14.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 76395,
      "2014": 85294,
      "2015": 94722,
      "2016": 105235,
      "2017": 112934,
      "2018": 121236,
      "2019": 129797,
      "2020": 135250,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.8,
      "2019c": 2.3,
      "2018c": 1.5,
      "2017c": 0,
      "2016c": 3.9,
      "2015c": 3.1,
      "2014c": 2.9,
      "2013c": 2.1
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 65753,
      "2016": 73049,
      "2017": 78411,
      "2018": 84353,
      "2019": 89066,
      "2020": 97467,
      item:
        "Agent and brokerage services for the sale and rental of residential real estate",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 3,
      "2018c": 2.6,
      "2017c": 1.9,
      "2016c": 3.9,
      "2015c": 3.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 19540,
      "2016": 21827,
      "2017": 23264,
      "2018": 25487,
      "2019": 28636,
      "2020": 25848,
      item:
        "Agent and brokerage services for the sale and rental of nonresidential real estate",
      taxexempt: "all",
      "2020c": 5.4,
      "2019c": 4.8,
      "2018c": 4.3,
      "2017c": 4.6,
      "2016c": 6.1,
      "2015c": 5.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 6461,
      "2016": 7050,
      "2017": 7885,
      "2018": 7900,
      "2019": 8451,
      "2020": 8141,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 9.9,
      "2019c": 13.6,
      "2018c": 13.4,
      "2017c": 12.9,
      "2016c": 15.5,
      "2015c": 13.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1246,
      "2016": 1401,
      "2017": 1530,
      "2018": 1580,
      "2019": 1726,
      "2020": 1801,
      item: "Nonresidential building property management",
      taxexempt: "all",
      "2020c": 12.8,
      "2019c": 13.3,
      "2018c": 12.7,
      "2017c": 13.8,
      "2016c": 14.3,
      "2015c": 14.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1409,
      "2016": 1562,
      "2017": 1506,
      "2018": 1588,
      "2019": 1595,
      "2020": 1668,
      item: "Residential building property management",
      taxexempt: "all",
      "2020c": 14.5,
      "2019c": 15.1,
      "2018c": 14.2,
      "2017c": 15.6,
      "2016c": 13.5,
      "2015c": 12.3,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  books: [
    {
      "2013": 4449,
      "2014": 4437,
      "2015": 6568,
      "2016": 7217,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Books - Professional, technical, and scholarly books",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 2,
      "2015c": 2.3,
      "2014c": 4.7,
      "2013c": 3.2
    },
    {
      "2013": 3672,
      "2014": 4566,
      "2015": 2213,
      "2016": 2073,
      "2017": 1947,
      "2018": 2807,
      "2019": 2889,
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 5.3,
      "2018c": 4.9,
      "2017c": 7,
      "2016c": 10,
      "2015c": 9.9,
      "2014c": 5.3,
      "2013c": 5.3
    },
    {
      "2013": 3248,
      "2014": 3285,
      "2015": 1942,
      "2016": 2942,
      "2017": 3085,
      "2018": 3067,
      "2019": 2840,
      "2020": "S",
      item: "Books - Children's books",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 6.8,
      "2018c": 10.8,
      "2017c": 5.3,
      "2016c": 4.9,
      "2015c": 6.8,
      "2014c": 7.7,
      "2013c": 7.8
    },
    {
      "2013": 26930,
      "2014": 27312,
      "2015": 28318,
      "2016": 28608,
      "2017": 29116,
      "2018": 27978,
      "2019": 27056,
      "2020": 25552,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 0.8,
      "2018c": 0.6,
      "2017c": 0,
      "2016c": 1,
      "2015c": 1.2,
      "2014c": 1,
      "2013c": 0.7
    },
    {
      "2013": 8649,
      "2014": 8751,
      "2015": 11967,
      "2016": 10482,
      "2017": 10240,
      "2018": 9135,
      "2019": 8389,
      "2020": 7849,
      item: "Books - Textbooks",
      taxexempt: "all",
      "2020c": 3.3,
      "2019c": 2.9,
      "2018c": 3.6,
      "2017c": 2.4,
      "2016c": 2.2,
      "2015c": 2,
      "2014c": 3,
      "2013c": 2.3
    },
    {
      "2013": 6360,
      "2014": 5638,
      "2015": 5291,
      "2016": 5589,
      "2017": 5578,
      "2018": 5379,
      "2019": 5242,
      "2020": 5253,
      item: "Books - Adult trade books",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 5,
      "2018c": 4.4,
      "2017c": 4.4,
      "2016c": 4.9,
      "2015c": 5.4,
      "2014c": 3.3,
      "2013c": 3.1
    },
    {
      "2013": 552,
      "2014": 635,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 265,
      "2019": 287,
      "2020": 250,
      item: "Books - General reference books",
      taxexempt: "all",
      "2020c": 13.6,
      "2019c": 2.2,
      "2018c": 2.7,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 29.4,
      "2013c": 20.1
    }
  ],
  databases: [
    {
      "2013": 3241,
      "2014": 4190,
      "2015": 6686,
      "2016": 6453,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Information technology (IT) design and development services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 7.2,
      "2015c": 4.2,
      "2014c": 9.7,
      "2013c": 11.4
    },
    {
      "2013": 5244,
      "2014": 5485,
      "2015": 4092,
      "2016": 4142,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Information technology (IT) technical consulting services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 9.6,
      "2015c": 7.9,
      "2014c": 7.5,
      "2013c": 7.1
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 2539,
      "2016": 2540,
      "2017": 3227,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Resale of computer hardware and software",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 21.8,
      "2016c": 24,
      "2015c": 23.3,
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 3037,
      "2014": 2861,
      "2015": 2457,
      "2016": "S",
      "2017": 2701,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Information and document transformation services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 19.8,
      "2016c": "S",
      "2015c": 23.9,
      "2014c": 12.3,
      "2013c": 14.4
    },
    {
      "2013": 116703,
      "2014": 121880,
      "2015": 132249,
      "2016": 139462,
      "2017": 157477,
      "2018": 175092,
      "2019": 193228,
      "2020": 205869,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.5,
      "2019c": 2.3,
      "2018c": 1.1,
      "2017c": 0,
      "2016c": 1.7,
      "2015c": 1.3,
      "2014c": 1,
      "2013c": 0.6
    },
    {
      "2013": 85740,
      "2014": 87573,
      "2015": "S",
      "2016": 97054,
      "2017": 110107,
      "2018": 125064,
      "2019": 134969,
      "2020": 146405,
      item:
        "Data processing, information technology (IT) infrastructure provisioning, and hosting services",
      taxexempt: "all",
      "2020c": 3.3,
      "2019c": 2.9,
      "2018c": 1.8,
      "2017c": 1.5,
      "2016c": 2.4,
      "2015c": "S",
      "2014c": 1.5,
      "2013c": 1.3
    },
    {
      "2013": 13253,
      "2014": 14306,
      "2015": 18758,
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": 28301,
      "2020": 31046,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 14.5,
      "2019c": 13.4,
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": 6.9,
      "2014c": 5,
      "2013c": 3.4
    },
    {
      "2013": 3330,
      "2014": 4071,
      "2015": 5631,
      "2016": 5422,
      "2017": 5660,
      "2018": 5589,
      "2019": 6207,
      "2020": 6289,
      item: "Information technology (IT) technical support services",
      taxexempt: "all",
      "2020c": 7.3,
      "2019c": 7.1,
      "2018c": 5.3,
      "2017c": 3.7,
      "2016c": 5.5,
      "2015c": 5.7,
      "2014c": 4.7,
      "2013c": 7.1
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 3406,
      "2016": 3432,
      "2017": 3586,
      "2018": 4422,
      "2019": 6350,
      "2020": 5589,
      item: "Software publishing",
      taxexempt: "all",
      "2020c": 17.2,
      "2019c": 21.2,
      "2018c": 15.3,
      "2017c": 12,
      "2016c": 9,
      "2015c": 7.8,
      "2014c": "S",
      "2013c": "S"
    }
  ],
  websites: [
    {
      "2013": 3744,
      "2014": 4053,
      "2015": "S",
      "2016": 2958,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Licensing of rights to use intellectual property",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 8.9,
      "2015c": "S",
      "2014c": 7.3,
      "2013c": 19
    },
    {
      "2013": 95981,
      "2014": 107283,
      "2015": 122279,
      "2016": 145759,
      "2017": 165430,
      "2018": 191581,
      "2019": 216851,
      "2020": 239073,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.1,
      "2018c": 0.5,
      "2017c": 0,
      "2016c": 1.8,
      "2015c": 1.5,
      "2014c": 1.1,
      "2013c": 0.5
    },
    {
      "2013": 49307,
      "2014": 53605,
      "2015": 74600,
      "2016": 92729,
      "2017": 106982,
      "2018": 125032,
      "2019": 144803,
      "2020": 160393,
      item: "Online advertising space",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 1.7,
      "2018c": 1.4,
      "2017c": 1.3,
      "2016c": 2.8,
      "2015c": 2.8,
      "2014c": 1.8,
      "2013c": 1.6
    },
    {
      "2013": 30457,
      "2014": 33415,
      "2015": 34830,
      "2016": 39659,
      "2017": 43847,
      "2018": 50474,
      "2019": 50894,
      "2020": 56875,
      item: "Publishing and broadcasting of content on the Internet",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 3,
      "2018c": 2.4,
      "2017c": 2.6,
      "2016c": 3.1,
      "2015c": 2.8,
      "2014c": 3.2,
      "2013c": 2.8
    },
    {
      "2013": 12473,
      "2014": 16210,
      "2015": 10119,
      "2016": 10413,
      "2017": 11290,
      "2018": "S",
      "2019": 16358,
      "2020": 17449,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 14.7,
      "2019c": 13.9,
      "2018c": "S",
      "2017c": 12.6,
      "2016c": 8.4,
      "2015c": 10.2,
      "2014c": 5.4,
      "2013c": 4
    }
  ],
  otherhealthoffices: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2731,
      "2016": 2422,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 6.2,
      "2015c": 5.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 9555,
      "2016": 9824,
      "2017": 9189,
      "2018": 9351,
      "2019": 9412,
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 3.9,
      "2018c": 3.4,
      "2017c": 4.8,
      "2016c": 5.8,
      "2015c": 4.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 67440,
      "2014": 71203,
      "2015": 75633,
      "2016": 80100,
      "2017": 84052,
      "2018": 90379,
      "2019": 95981,
      "2020": 93620,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 0.8,
      "2019c": 0.7,
      "2018c": 0.7,
      "2017c": 0,
      "2016c": 2,
      "2015c": 1.4,
      "2014c": 1.2,
      "2013c": 0.6
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 22296,
      "2016": 23737,
      "2017": 25574,
      "2018": 27601,
      "2019": 29848,
      "2020": 29093,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 1.7,
      "2019c": 1.4,
      "2018c": 1.5,
      "2017c": 1.1,
      "2016c": 2,
      "2015c": 1.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 15693,
      "2016": 16572,
      "2017": 18076,
      "2018": 19937,
      "2019": 21611,
      "2020": 20938,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 2.6,
      "2019c": 2.8,
      "2018c": 2.2,
      "2017c": 2,
      "2016c": 2.8,
      "2015c": 2.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 8356,
      "2016": 8733,
      "2017": 9166,
      "2018": 9846,
      "2019": 10317,
      "2020": 9683,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 2.8,
      "2018c": 2.9,
      "2017c": 2.8,
      "2016c": 3.3,
      "2015c": 2.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5659,
      "2016": 6407,
      "2017": 6966,
      "2018": 7640,
      "2019": 7924,
      "2020": 7662,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 5.6,
      "2019c": 5.8,
      "2018c": 5.3,
      "2017c": 4.1,
      "2016c": 4.7,
      "2015c": 4.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3843,
      "2016": 4165,
      "2017": 4165,
      "2018": 4337,
      "2019": 4661,
      "2020": 4865,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 5,
      "2018c": 4.9,
      "2017c": 5.1,
      "2016c": 4.8,
      "2015c": 4.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2222,
      "2016": 2354,
      "2017": 2400,
      "2018": 2707,
      "2019": 2920,
      "2020": 2915,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 6.7,
      "2019c": 8,
      "2018c": 7.7,
      "2017c": 6.6,
      "2016c": 4.9,
      "2015c": 3.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1809,
      "2016": 1990,
      "2017": 2221,
      "2018": 2494,
      "2019": 2527,
      "2020": 2352,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 5.3,
      "2019c": 4.9,
      "2018c": 5.3,
      "2017c": 5,
      "2016c": 5.3,
      "2015c": 4.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2063,
      "2016": 2121,
      "2017": 2210,
      "2018": 2377,
      "2019": 2432,
      "2020": 2209,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 5.5,
      "2019c": 5.9,
      "2018c": 4.9,
      "2017c": 4.9,
      "2016c": 4.2,
      "2015c": 4.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1406,
      "2016": 1259,
      "2017": 1249,
      "2018": 1326,
      "2019": 1334,
      "2020": 1224,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 5.8,
      "2019c": 6.4,
      "2018c": 6.4,
      "2017c": 8.3,
      "2016c": 6.5,
      "2015c": 7.1,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  satellite: [
    {
      "2013": "S",
      "2014": 2010,
      "2015": 1407,
      "2016": 1539,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Private network services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 2.2,
      "2015c": 2.1,
      "2014c": 10.1,
      "2013c": "S"
    },
    {
      "2013": 6670,
      "2014": 6228,
      "2015": 6183,
      "2016": 6211,
      "2017": 5742,
      "2018": 5844,
      "2019": 5635,
      "2020": 5630,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 1.9,
      "2018c": 0.3,
      "2017c": 0,
      "2016c": 1.8,
      "2015c": 1.6,
      "2014c": 4.3,
      "2013c": 0.7
    },
    {
      "2013": "S",
      "2014": 2598,
      "2015": 2618,
      "2016": 2679,
      "2017": 2579,
      "2018": 2807,
      "2019": 2584,
      "2020": 2615,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 3.3,
      "2019c": 6,
      "2018c": 2.8,
      "2017c": 2.7,
      "2016c": 2.9,
      "2015c": 2.2,
      "2014c": 2.6,
      "2013c": "S"
    },
    {
      "2013": 1734,
      "2014": 1620,
      "2015": 2158,
      "2016": 1993,
      "2017": 1810,
      "2018": 1709,
      "2019": 1780,
      "2020": 1840,
      item: "Carrier services",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 4,
      "2018c": 3.7,
      "2017c": 3.6,
      "2016c": 3.9,
      "2015c": 3.4,
      "2014c": 5.3,
      "2013c": 4.1
    }
  ],
  advertisingmail: [
    {
      "2013": 252,
      "2014": 228,
      "2015": 261,
      "2016": 245,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Fulfillment services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 25.4,
      "2015c": 24.6,
      "2014c": 14.7,
      "2013c": 10
    },
    {
      "2013": 358,
      "2014": 299,
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 226,
      "2019": "S",
      "2020": "S",
      item: "Concept development for a advertisingmail campaign",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 29.5,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": 25,
      "2013c": 22
    },
    {
      "2013": 345,
      "2014": 370,
      "2015": 212,
      "2016": 249,
      "2017": 193,
      "2018": 194,
      "2019": "S",
      "2020": "S",
      item: "Other advertisingmail services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 15.7,
      "2017c": 12.8,
      "2016c": 15.9,
      "2015c": 18.2,
      "2014c": 12.7,
      "2013c": 13
    },
    {
      "2013": 316,
      "2014": 283,
      "2015": 193,
      "2016": 191,
      "2017": 155,
      "2018": 189,
      "2019": "S",
      "2020": "S",
      item: "Mail list creation and support services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 28.8,
      "2017c": 21.5,
      "2016c": 26,
      "2015c": 25.5,
      "2014c": 16.5,
      "2013c": 16.4
    },
    {
      "2013": 9886,
      "2014": 9939,
      "2015": 9699,
      "2016": 10032,
      "2017": 9598,
      "2018": 9511,
      "2019": 9818,
      "2020": 9470,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.2,
      "2019c": 1.5,
      "2018c": 1.4,
      "2017c": 0,
      "2016c": 3.6,
      "2015c": 2.5,
      "2014c": 1.9,
      "2013c": 1.8
    },
    {
      "2013": 5011,
      "2014": 5273,
      "2015": 4438,
      "2016": 4722,
      "2017": 4728,
      "2018": 4561,
      "2019": 4794,
      "2020": 4833,
      item: "Full direct mail services",
      taxexempt: "all",
      "2020c": 6.5,
      "2019c": 5.3,
      "2018c": 5.4,
      "2017c": 5.1,
      "2016c": 7.3,
      "2015c": 5.5,
      "2014c": 4.8,
      "2013c": 4.7
    },
    {
      "2013": 1562,
      "2014": 1580,
      "2015": 1686,
      "2016": 1724,
      "2017": 1571,
      "2018": 1624,
      "2019": 1751,
      "2020": 1803,
      item: "Print services for advertisingmail materials",
      taxexempt: "all",
      "2020c": 7.6,
      "2019c": 7.8,
      "2018c": 7.1,
      "2017c": 5.4,
      "2016c": 7,
      "2015c": 7.3,
      "2014c": 7.4,
      "2013c": 7.4
    },
    {
      "2013": 1319,
      "2014": 1253,
      "2015": 1339,
      "2016": 1304,
      "2017": 1147,
      "2018": 1200,
      "2019": 1208,
      "2020": 1075,
      item: "Letter shop services",
      taxexempt: "all",
      "2020c": 11.2,
      "2019c": 11.3,
      "2018c": 7.4,
      "2017c": 7.7,
      "2016c": 7.7,
      "2015c": 9,
      "2014c": 9.4,
      "2013c": 10
    },
    {
      "2013": 723,
      "2014": 653,
      "2015": 1355,
      "2016": 1366,
      "2017": "S",
      "2018": 898,
      "2019": 906,
      "2020": 741,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 14.3,
      "2019c": 17,
      "2018c": 16,
      "2017c": "S",
      "2016c": 21,
      "2015c": 19.5,
      "2014c": 13.9,
      "2013c": 11.9
    }
  ],
  editing: [
    {
      "2013": 975,
      "2014": 970,
      "2015": 205,
      "2016": 209,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Duplication and copying services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 6.1,
      "2015c": 4.5,
      "2014c": 3.8,
      "2013c": 3.6
    },
    {
      "2013": 5056,
      "2014": 5208,
      "2015": 5238,
      "2016": 5381,
      "2017": 5319,
      "2018": 5124,
      "2019": 4976,
      "2020": 4430,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 3.3,
      "2018c": 1.2,
      "2017c": 0,
      "2016c": 3.6,
      "2015c": 3.1,
      "2014c": 2,
      "2013c": 1.7
    },
    {
      "2013": 3421,
      "2014": 3400,
      "2015": 3033,
      "2016": 3178,
      "2017": 3070,
      "2018": 3210,
      "2019": 3091,
      "2020": 2733,
      item: "Audiovisual postproduction services",
      taxexempt: "all",
      "2020c": 5.3,
      "2019c": 3.8,
      "2018c": 2.9,
      "2017c": 2.2,
      "2016c": 3.9,
      "2015c": 3.3,
      "2014c": 3.4,
      "2013c": 3.2
    },
    {
      "2013": 539,
      "2014": 699,
      "2015": 1896,
      "2016": 1898,
      "2017": 1981,
      "2018": "S",
      "2019": 1666,
      "2020": 1522,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 4.7,
      "2019c": 9.6,
      "2018c": "S",
      "2017c": 3.4,
      "2016c": 4.7,
      "2015c": 4.5,
      "2014c": 10,
      "2013c": 7.5
    },
    {
      "2013": 121,
      "2014": 139,
      "2015": "S",
      "2016": "S",
      "2017": 69,
      "2018": "S",
      "2019": "S",
      "2020": 59,
      item: "Motion picture film laboratory services",
      taxexempt: "all",
      "2020c": 8.7,
      "2019c": "S",
      "2018c": "S",
      "2017c": 16.9,
      "2016c": "S",
      "2015c": "S",
      "2014c": 7.8,
      "2013c": 7.4
    }
  ],
  podiatryoffice: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 213,
      "2016": 203,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 16.7,
      "2015c": 16,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 71,
      "2016": 47,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 23.8,
      "2015c": 26.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 4408,
      "2014": 4414,
      "2015": 4432,
      "2016": 4471,
      "2017": 4621,
      "2018": 4878,
      "2019": 5123,
      "2020": 4912,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 4.9,
      "2019c": 4.6,
      "2018c": 4.5,
      "2017c": 0,
      "2016c": 3.3,
      "2015c": 3.1,
      "2014c": 2.5,
      "2013c": 1.8
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1919,
      "2016": 1962,
      "2017": 2093,
      "2018": 2202,
      "2019": 2318,
      "2020": 2183,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 5.3,
      "2019c": 5.4,
      "2018c": 4.3,
      "2017c": 2.5,
      "2016c": 4.1,
      "2015c": 3.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1231,
      "2016": 1236,
      "2017": 1239,
      "2018": 1315,
      "2019": 1360,
      "2020": 1329,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 5.6,
      "2019c": 5.1,
      "2018c": 5.6,
      "2017c": 2.3,
      "2016c": 2.8,
      "2015c": 3.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 635,
      "2016": 642,
      "2017": 705,
      "2018": 765,
      "2019": 812,
      "2020": 766,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 8.7,
      "2019c": 7.3,
      "2018c": 7,
      "2017c": 4.1,
      "2016c": 7,
      "2015c": 6.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 122,
      "2016": 128,
      "2017": 147,
      "2018": 155,
      "2019": 150,
      "2020": 157,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 14.5,
      "2019c": 15.4,
      "2018c": 18.9,
      "2017c": 17.8,
      "2016c": 16.6,
      "2015c": 14.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 114,
      "2016": 113,
      "2017": 106,
      "2018": 115,
      "2019": 139,
      "2020": 137,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 17.8,
      "2019c": 17.7,
      "2018c": 18.7,
      "2017c": 9.6,
      "2016c": 9.8,
      "2015c": 9.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 57,
      "2016": "S",
      "2017": 56,
      "2018": 56,
      "2019": 70,
      "2020": 76,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 29.1,
      "2019c": 19.8,
      "2018c": 19.5,
      "2017c": 21.7,
      "2016c": "S",
      "2015c": 18.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": 62,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 25.8,
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 40,
      "2016": 43,
      "2017": "S",
      "2018": 49,
      "2019": 47,
      "2020": 44,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 20.6,
      "2019c": 24.1,
      "2018c": 22.4,
      "2017c": "S",
      "2016c": 26.2,
      "2015c": 25.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": 5,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 14,
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  othersports: [
    {
      "2013": 479,
      "2014": 445,
      "2015": "S",
      "2016": 177,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Admissions revenue (excluding admissions taxes)",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 27,
      "2015c": "S",
      "2014c": 21.4,
      "2013c": 20.4
    },
    {
      "2013": 3422,
      "2014": 3381,
      "2015": 3556,
      "2016": 3695,
      "2017": 3668,
      "2018": 3731,
      "2019": 3948,
      "2020": 3662,
      item: "Revenue",
      taxexempt: "FALSE",
      "2020c": 5.2,
      "2019c": 3.7,
      "2018c": 2.6,
      "2017c": 0,
      "2016c": 6.2,
      "2015c": 4.9,
      "2014c": 4.5,
      "2013c": 3.4
    },
    {
      "2013": 2943,
      "2014": 2936,
      "2015": 3329,
      "2016": 3518,
      "2017": 3477,
      "2018": 3499,
      "2019": 3649,
      "2020": 3451,
      item: "All other operating revenue",
      taxexempt: "FALSE",
      "2020c": 5.5,
      "2019c": 3.6,
      "2018c": 3.8,
      "2017c": 1.7,
      "2016c": 6.7,
      "2015c": 5.2,
      "2014c": 6.4,
      "2013c": 5.3
    }
  ],
  science: [
    {
      "2013": 4702,
      "2014": 4337,
      "2015": 3391,
      "2016": 3705,
      "2017": 3226,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "TRUE",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 13.6,
      "2016c": 14.3,
      "2015c": 13.7,
      "2014c": 4.6,
      "2013c": 4.5
    },
    {
      "2013": 13075,
      "2014": 14290,
      "2015": 7753,
      "2016": "S",
      "2017": "S",
      "2018": 11541,
      "2019": "S",
      "2020": "S",
      item: "Licensing of right to use intellectual property",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": "S",
      "2018c": 5.1,
      "2017c": "S",
      "2016c": "S",
      "2015c": 6.5,
      "2014c": 6.1,
      "2013c": 5.6
    },
    {
      "2013": 4024,
      "2014": 4665,
      "2015": 5482,
      "2016": "S",
      "2017": 9434,
      "2018": 9548,
      "2019": "S",
      "2020": "S",
      item: "Production services for development",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": "S",
      "2018c": 7.3,
      "2017c": 6.6,
      "2016c": "S",
      "2015c": 5.1,
      "2014c": 8.4,
      "2013c": 7.2
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 33058,
      "2019": 41364,
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 4.8,
      "2018c": 5.3,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 7586,
      "2014": 7215,
      "2015": 10190,
      "2016": 12827,
      "2017": 13914,
      "2018": 15584,
      "2019": 17804,
      "2020": "S",
      item:
        "Basic and applied research in natural and exact sciences, except biological sciences",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 10,
      "2018c": 6,
      "2017c": 5.7,
      "2016c": 4.7,
      "2015c": 5,
      "2014c": 7.4,
      "2013c": 6.9
    },
    {
      "2013": "S",
      "2014": 499,
      "2015": 543,
      "2016": 615,
      "2017": 612,
      "2018": 787,
      "2019": 1132,
      "2020": "S",
      item: "Original works of intellectual property",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 12.3,
      "2018c": 11.7,
      "2017c": 25.5,
      "2016c": 14.3,
      "2015c": 12.8,
      "2014c": 8.3,
      "2013c": "S"
    },
    {
      "2013": 82425,
      "2014": 87732,
      "2015": 93998,
      "2016": 101457,
      "2017": 114987,
      "2018": 126081,
      "2019": 146478,
      "2020": 162714,
      item: "Revenue",
      taxexempt: "FALSE",
      "2020c": 5,
      "2019c": 3.8,
      "2018c": 3.6,
      "2017c": 0,
      "2016c": 2.6,
      "2015c": 2.2,
      "2014c": 1.7,
      "2013c": 1.4
    },
    {
      "2013": 39813,
      "2014": 41578,
      "2015": 42763,
      "2016": 41719,
      "2017": 45374,
      "2018": 45963,
      "2019": 52193,
      "2020": 54475,
      item: "Revenue",
      taxexempt: "TRUE",
      "2020c": 7,
      "2019c": 6.6,
      "2018c": 6.9,
      "2017c": 0,
      "2016c": 8.6,
      "2015c": 6.6,
      "2014c": 1,
      "2013c": 0.9
    },
    {
      "2013": 15858,
      "2014": 16060,
      "2015": 22439,
      "2016": 23283,
      "2017": "S",
      "2018": 23532,
      "2019": 32013,
      "2020": 37128,
      item: "Basic and applied research in engineering and technology",
      taxexempt: "FALSE",
      "2020c": 24.2,
      "2019c": 19.3,
      "2018c": 17.8,
      "2017c": "S",
      "2016c": 6.7,
      "2015c": 5.1,
      "2014c": 12,
      "2013c": 11.7
    },
    {
      "2013": 16538,
      "2014": 17846,
      "2015": 20658,
      "2016": 22510,
      "2017": 25405,
      "2018": 24834,
      "2019": 27744,
      "2020": 36754,
      item:
        "Basic and applied research in the biological and biomedical sciences",
      taxexempt: "FALSE",
      "2020c": 4.9,
      "2019c": 5.5,
      "2018c": 3.9,
      "2017c": 4,
      "2016c": 5.2,
      "2015c": 3.9,
      "2014c": 6.3,
      "2013c": 5.6
    },
    {
      "2013": 9687,
      "2014": 10101,
      "2015": 14532,
      "2016": 14687,
      "2017": 15479,
      "2018": 11927,
      "2019": 14045,
      "2020": 15940,
      item: "Basic and applied research in engineering and technology",
      taxexempt: "TRUE",
      "2020c": 4,
      "2019c": 4.1,
      "2018c": 3.9,
      "2017c": 10.8,
      "2016c": 10.1,
      "2015c": 9.3,
      "2014c": 2,
      "2013c": 2.6
    },
    {
      "2013": 11755,
      "2014": 12072,
      "2015": 10930,
      "2016": 8700,
      "2017": 9819,
      "2018": 9789,
      "2019": 11891,
      "2020": 11906,
      item: "Contributions, gifts, and grants received",
      taxexempt: "TRUE",
      "2020c": 16.2,
      "2019c": 11.5,
      "2018c": 5.9,
      "2017c": 7.5,
      "2016c": 9.2,
      "2015c": 11.1,
      "2014c": 2.9,
      "2013c": 3
    },
    {
      "2013": 2305,
      "2014": 2704,
      "2015": 2245,
      "2016": 2373,
      "2017": 2810,
      "2018": 5416,
      "2019": 7666,
      "2020": 7965,
      item:
        "Basic and applied research in natural and exact sciences, except biological sciences",
      taxexempt: "TRUE",
      "2020c": 4.4,
      "2019c": 3.5,
      "2018c": 3,
      "2017c": 7.4,
      "2016c": 15.5,
      "2015c": 13.9,
      "2014c": 5.5,
      "2013c": 2.5
    },
    {
      "2013": 5417,
      "2014": 5645,
      "2015": 4994,
      "2016": 5258,
      "2017": 5632,
      "2018": 5714,
      "2019": 5691,
      "2020": 6164,
      item:
        "Basic and applied research in the biological and biomedical sciences",
      taxexempt: "TRUE",
      "2020c": 7.4,
      "2019c": 7.1,
      "2018c": 7.2,
      "2017c": 8.6,
      "2016c": 9.8,
      "2015c": 9.5,
      "2014c": 5.5,
      "2013c": 5.4
    },
    {
      "2013": 796,
      "2014": 662,
      "2015": 1013,
      "2016": 995,
      "2017": 1712,
      "2018": 2074,
      "2019": 2259,
      "2020": 2436,
      item: "All other non-operating revenue",
      taxexempt: "TRUE",
      "2020c": 13.5,
      "2019c": 16.1,
      "2018c": 24.4,
      "2017c": 5.8,
      "2016c": 10.1,
      "2015c": 8.1,
      "2014c": 14.2,
      "2013c": 8.6
    },
    {
      "2013": 1979,
      "2014": 2364,
      "2015": 1819,
      "2016": 1873,
      "2017": 2355,
      "2018": 2222,
      "2019": 2336,
      "2020": 2208,
      item: "Basic and applied research in the social sciences and humanities",
      taxexempt: "TRUE",
      "2020c": 5.9,
      "2019c": 5.4,
      "2018c": 5,
      "2017c": 4,
      "2016c": 5,
      "2015c": 5,
      "2014c": 3.5,
      "2013c": 4.1
    },
    {
      "2013": 1621,
      "2014": 1681,
      "2015": "S",
      "2016": "S",
      "2017": 8617,
      "2018": 7197,
      "2019": 7147,
      "2020": 2048,
      item: "Basic and applied research in the social sciences and humanities",
      taxexempt: "FALSE",
      "2020c": 3.5,
      "2019c": 2.7,
      "2018c": 3,
      "2017c": 5.5,
      "2016c": "S",
      "2015c": "S",
      "2014c": 7.1,
      "2013c": 4.7
    },
    {
      "2013": 2688,
      "2014": 2850,
      "2015": 2563,
      "2016": 2734,
      "2017": 2985,
      "2018": 1527,
      "2019": 1141,
      "2020": 1119,
      item: "Investment and property income",
      taxexempt: "TRUE",
      "2020c": 5.1,
      "2019c": 10.9,
      "2018c": 3.9,
      "2017c": 7.8,
      "2016c": 11.1,
      "2015c": 10.4,
      "2014c": 8,
      "2013c": 6.5
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 758,
      "2016": 877,
      "2017": 1010,
      "2018": 1090,
      "2019": 980,
      "2020": 957,
      item: "Production services for development",
      taxexempt: "TRUE",
      "2020c": 5.8,
      "2019c": 3.9,
      "2018c": 3.7,
      "2017c": 6.9,
      "2016c": 8.3,
      "2015c": 8.1,
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 398,
      "2014": 457,
      "2015": "S",
      "2016": 499,
      "2017": 322,
      "2018": 398,
      "2019": 569,
      "2020": 300,
      item: "Licensing of right to use intellectual property",
      taxexempt: "TRUE",
      "2020c": 16,
      "2019c": 8.2,
      "2018c": 11.6,
      "2017c": 14.1,
      "2016c": 10.3,
      "2015c": "S",
      "2014c": 13.5,
      "2013c": 19.5
    },
    {
      "2013": 43,
      "2014": 45,
      "2015": 20,
      "2016": 18,
      "2017": 24,
      "2018": 13,
      "2019": 48,
      "2020": 19,
      item: "Original works of intellectual property",
      taxexempt: "TRUE",
      "2020c": 3.8,
      "2019c": 8.5,
      "2018c": 3.3,
      "2017c": 11.2,
      "2016c": 19.7,
      "2015c": 12.5,
      "2014c": 19.5,
      "2013c": 16.6
    }
  ],
  racing: [
    {
      "2013": 696,
      "2014": 703,
      "2015": 650,
      "2016": 637,
      "2017": 747,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Admissions revenue (excluding admissions taxes)",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 10.4,
      "2016c": 11.3,
      "2015c": 10.7,
      "2014c": 7,
      "2013c": 7
    },
    {
      "2013": 6683,
      "2014": 6452,
      "2015": 6820,
      "2016": 6943,
      "2017": 7538,
      "2018": 7849,
      "2019": 7679,
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 2.7,
      "2018c": 1.7,
      "2017c": 1.3,
      "2016c": 4.3,
      "2015c": 4,
      "2014c": 1,
      "2013c": 0.8
    },
    {
      "2013": 7379,
      "2014": 7155,
      "2015": 7470,
      "2016": 7580,
      "2017": 8285,
      "2018": 8618,
      "2019": 8827,
      "2020": 5974,
      item: "Revenue",
      taxexempt: "FALSE",
      "2020c": 3.9,
      "2019c": 2.7,
      "2018c": 2.3,
      "2017c": 0,
      "2016c": 4.6,
      "2015c": 4.3,
      "2014c": 1,
      "2013c": 0.8
    }
  ],
  syndications: [
    {
      "2013": 613,
      "2014": 580,
      "2015": 521,
      "2016": 497,
      "2017": 452,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 6,
      "2016c": 6.5,
      "2015c": 6,
      "2014c": 11.6,
      "2013c": 12.9
    },
    {
      "2013": 1829,
      "2014": 1693,
      "2015": 1738,
      "2016": 1645,
      "2017": 1398,
      "2018": 1400,
      "2019": 1454,
      "2020": 1466,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 2.7,
      "2018c": 1.7,
      "2017c": 0,
      "2016c": 3.9,
      "2015c": 3.8,
      "2014c": 3.7,
      "2013c": 3.3
    },
    {
      "2013": 1216,
      "2014": 1113,
      "2015": 1217,
      "2016": 1148,
      "2017": 946,
      "2018": 936,
      "2019": "S",
      "2020": 896,
      item: "Licensing of rights to use syndicated media content",
      taxexempt: "all",
      "2020c": 3,
      "2019c": "S",
      "2018c": 2.8,
      "2017c": 2.9,
      "2016c": 4.5,
      "2015c": 4.4,
      "2014c": 6.4,
      "2013c": 5.6
    }
  ],
  publicrelations: [
    {
      "2013": 425,
      "2014": 460,
      "2015": "S",
      "2016": "S",
      "2017": 393,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Event management",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 29.9,
      "2016c": "S",
      "2015c": "S",
      "2014c": 28.4,
      "2013c": 26.1
    },
    {
      "2013": 10612,
      "2014": 11362,
      "2015": 11795,
      "2016": 13514,
      "2017": 12505,
      "2018": 13813,
      "2019": 14066,
      "2020": 14536,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 2.4,
      "2018c": 2.8,
      "2017c": 0,
      "2016c": 4.9,
      "2015c": 4.1,
      "2014c": 3.6,
      "2013c": 2.3
    },
    {
      "2013": 7203,
      "2014": 7621,
      "2015": 8040,
      "2016": 8763,
      "2017": 8365,
      "2018": 9116,
      "2019": 9330,
      "2020": 9671,
      item: "Full public relations services",
      taxexempt: "all",
      "2020c": 4.7,
      "2019c": 3.6,
      "2018c": 4.5,
      "2017c": 3,
      "2016c": 4.6,
      "2015c": 4.3,
      "2014c": 4.3,
      "2013c": 3.4
    },
    {
      "2013": 1359,
      "2014": 1453,
      "2015": 1486,
      "2016": 1629,
      "2017": 1628,
      "2018": 1715,
      "2019": 1897,
      "2020": 1991,
      item: "Lobbying",
      taxexempt: "all",
      "2020c": 10,
      "2019c": 9.6,
      "2018c": 10.3,
      "2017c": 10.7,
      "2016c": 11.7,
      "2015c": 11.9,
      "2014c": 7.4,
      "2013c": 7
    },
    {
      "2013": 768,
      "2014": 841,
      "2015": 1048,
      "2016": 1149,
      "2017": 1187,
      "2018": 1449,
      "2019": 1400,
      "2020": 1523,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 21.3,
      "2019c": 22,
      "2018c": 21.2,
      "2017c": 15.6,
      "2016c": 13.1,
      "2015c": 12.5,
      "2014c": 12.9,
      "2013c": 10.5
    },
    {
      "2013": 776,
      "2014": 876,
      "2015": 756,
      "2016": 1331,
      "2017": 795,
      "2018": 878,
      "2019": 763,
      "2020": 697,
      item: "Media relations",
      taxexempt: "all",
      "2020c": 15,
      "2019c": 12.5,
      "2018c": 13.1,
      "2017c": 12.5,
      "2016c": 23.4,
      "2015c": 16.5,
      "2014c": 12.8,
      "2013c": 13.5
    },
    {
      "2013": 81,
      "2014": 111,
      "2015": 95,
      "2016": "S",
      "2017": 137,
      "2018": 178,
      "2019": 161,
      "2020": 166,
      item: "Media monitoring and analysis",
      taxexempt: "all",
      "2020c": 19.4,
      "2019c": 24,
      "2018c": 20,
      "2017c": 16.6,
      "2016c": "S",
      "2015c": 11.8,
      "2014c": 11.3,
      "2013c": 9.3
    }
  ],
  productions: [
    {
      "2013": 291,
      "2014": 353,
      "2015": 337,
      "2016": 341,
      "2017": 321,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Audiovisual works speculatively produced for outright sale",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 10.7,
      "2016c": 12,
      "2015c": 11.2,
      "2014c": 7.5,
      "2013c": 7.6
    },
    {
      "2013": 8193,
      "2014": 7022,
      "2015": 7961,
      "2016": 7869,
      "2017": 6618,
      "2018": 6650,
      "2019": "S",
      "2020": "S",
      item: "International licensing of rights to motion picture films",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 0.8,
      "2017c": 0.5,
      "2016c": 1.2,
      "2015c": 1.6,
      "2014c": 2.9,
      "2013c": 2
    },
    {
      "2013": 4230,
      "2014": 4912,
      "2015": 3788,
      "2016": 3976,
      "2017": 3604,
      "2018": 3577,
      "2019": "S",
      "2020": "S",
      item: "International licensing of rights to television programs",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 1.1,
      "2017c": 0.6,
      "2016c": 1.2,
      "2015c": 1.3,
      "2014c": 1.9,
      "2013c": 3
    },
    {
      "2013": 5354,
      "2014": 6197,
      "2015": 6149,
      "2016": 8998,
      "2017": 10593,
      "2018": 13323,
      "2019": 15710,
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 9.6,
      "2018c": 9.9,
      "2017c": 2,
      "2016c": 1.5,
      "2015c": 1.8,
      "2014c": 5.4,
      "2013c": 4.9
    },
    {
      "2013": 13394,
      "2014": 12367,
      "2015": 14966,
      "2016": 14247,
      "2017": 13549,
      "2018": 12950,
      "2019": 12493,
      "2020": "S",
      item: "Domestic licensing of rights to motion picture films",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 2.2,
      "2018c": 0.8,
      "2017c": 1,
      "2016c": 1.4,
      "2015c": 1.2,
      "2014c": 2.2,
      "2013c": 2.1
    },
    {
      "2013": 8222,
      "2014": 6501,
      "2015": 7806,
      "2016": 7353,
      "2017": 6161,
      "2018": 6014,
      "2019": 4806,
      "2020": "S",
      item:
        "Sale of audiovisual works for the wholesale, retail, and rental markets",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 1.1,
      "2018c": 0.5,
      "2017c": 0.5,
      "2016c": 1.3,
      "2015c": 1.3,
      "2014c": 2.1,
      "2013c": 1.8
    },
    {
      "2013": 1194,
      "2014": 1018,
      "2015": 950,
      "2016": 1080,
      "2017": 1019,
      "2018": 1651,
      "2019": 1233,
      "2020": "S",
      item:
        "Domestic licensing of rights to others to distribute audiovisual works",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 8.5,
      "2018c": 5.1,
      "2017c": 2.6,
      "2016c": 2.3,
      "2015c": 2.3,
      "2014c": 5,
      "2013c": 4.3
    },
    {
      "2013": 433,
      "2014": 377,
      "2015": 924,
      "2016": 988,
      "2017": 921,
      "2018": 1324,
      "2019": 907,
      "2020": "S",
      item: "Merchandise licensing",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 7,
      "2018c": 26.3,
      "2017c": 0.8,
      "2016c": 1.7,
      "2015c": 2,
      "2014c": 3.6,
      "2013c": 4.7
    },
    {
      "2013": 369,
      "2014": 358,
      "2015": 362,
      "2016": 463,
      "2017": 458,
      "2018": 498,
      "2019": 502,
      "2020": "S",
      item:
        "International licensing of rights to others to distribute audiovisual works",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 12.1,
      "2018c": 2.4,
      "2017c": 2.1,
      "2016c": 1.2,
      "2015c": 1.4,
      "2014c": 1.7,
      "2013c": 1.9
    },
    {
      "2013": 65034,
      "2014": 63945,
      "2015": 66137,
      "2016": 69201,
      "2017": 67300,
      "2018": 74000,
      "2019": 74972,
      "2020": 65854,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1,
      "2019c": 2.1,
      "2018c": 2.5,
      "2017c": 0,
      "2016c": 1.1,
      "2015c": 1.3,
      "2014c": 1.3,
      "2013c": 0.9
    },
    {
      "2013": 13084,
      "2014": 13467,
      "2015": 13671,
      "2016": 14726,
      "2017": 14134,
      "2018": 16178,
      "2019": 17082,
      "2020": 15317,
      item: "Domestic licensing of rights to television programs",
      taxexempt: "all",
      "2020c": 0.8,
      "2019c": 0.9,
      "2018c": 2.6,
      "2017c": 0.7,
      "2016c": 2.1,
      "2015c": 2.5,
      "2014c": 4.8,
      "2013c": 5.4
    },
    {
      "2013": 6409,
      "2014": 7047,
      "2015": 5893,
      "2016": 5819,
      "2017": 6039,
      "2018": 6091,
      "2019": 7059,
      "2020": 6085,
      item: "Contract production of audiovisual works",
      taxexempt: "all",
      "2020c": 6.1,
      "2019c": 8.6,
      "2018c": 5.9,
      "2017c": 3.5,
      "2016c": 4.2,
      "2015c": 4.8,
      "2014c": 8.6,
      "2013c": 8.9
    },
    {
      "2013": 3861,
      "2014": 4326,
      "2015": 3330,
      "2016": 3341,
      "2017": 3883,
      "2018": 5203,
      "2019": 4246,
      "2020": 3908,
      item: "Other production services",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 5.8,
      "2018c": 26.5,
      "2017c": 3.9,
      "2016c": 5.8,
      "2015c": 5.7,
      "2014c": 11.8,
      "2013c": 10.6
    }
  ],
  theatres: [
    {
      "2013": 24,
      "2014": 29,
      "2015": 29,
      "2016": 33,
      "2017": 45,
      "2018": "S",
      "2019": "S",
      "2020": "S",
      item: "Coin-operated games and rides",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": "S",
      "2017c": 27.9,
      "2016c": 22.5,
      "2015c": 20.7,
      "2014c": 11.9,
      "2013c": 6.4
    },
    {
      "2013": 14145,
      "2014": 14563,
      "2015": 15785,
      "2016": 16539,
      "2017": 15967,
      "2018": 17579,
      "2019": 17375,
      "2020": 4729,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.5,
      "2019c": 1.7,
      "2018c": 1.6,
      "2017c": 0,
      "2016c": 2.4,
      "2015c": 2.2,
      "2014c": 2,
      "2013c": 1.6
    },
    {
      "2013": 9191,
      "2014": 9316,
      "2015": 9592,
      "2016": 10024,
      "2017": 9676,
      "2018": 10329,
      "2019": 9914,
      "2020": 2445,
      item: "Admissions to feature film exhibitions",
      taxexempt: "all",
      "2020c": 3.8,
      "2019c": 1.8,
      "2018c": 1.6,
      "2017c": 0.8,
      "2016c": 2.6,
      "2015c": 2.4,
      "2014c": 2.4,
      "2013c": 2
    },
    {
      "2013": 4141,
      "2014": 4389,
      "2015": 4937,
      "2016": 5303,
      "2017": 5091,
      "2018": 5632,
      "2019": 5696,
      "2020": 1436,
      item: "Food and beverage sales",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 2.8,
      "2018c": 2.6,
      "2017c": 1.8,
      "2016c": 2.7,
      "2015c": 2.6,
      "2014c": 3.1,
      "2013c": 2.9
    },
    {
      "2013": 578,
      "2014": 599,
      "2015": 992,
      "2016": 919,
      "2017": 848,
      "2018": 1083,
      "2019": 1219,
      "2020": 684,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 18.3,
      "2019c": 5.6,
      "2018c": 11.6,
      "2017c": 8.5,
      "2016c": 12.2,
      "2015c": 11.2,
      "2014c": 14.3,
      "2013c": 18.2
    },
    {
      "2013": 170,
      "2014": 177,
      "2015": 173,
      "2016": 194,
      "2017": 237,
      "2018": 408,
      "2019": 411,
      "2020": 109,
      item: "Advertising services",
      taxexempt: "all",
      "2020c": 2.6,
      "2019c": 7.6,
      "2018c": 7.2,
      "2017c": 13.1,
      "2016c": 3.3,
      "2015c": 3.8,
      "2014c": 8,
      "2013c": 8.8
    },
    {
      "2013": 41,
      "2014": 53,
      "2015": 62,
      "2016": 66,
      "2017": 70,
      "2018": 76,
      "2019": 84,
      "2020": 33,
      item: "Rental of retail space",
      taxexempt: "all",
      "2020c": 15.6,
      "2019c": 4.3,
      "2018c": 6.8,
      "2017c": 11.8,
      "2016c": 16.6,
      "2015c": 17.3,
      "2014c": 11,
      "2013c": 15.2
    }
  ],
  ambulance: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 8259,
      "2016": 7625,
      "2017": 7564,
      "2018": 7376,
      "2019": "S",
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 2.6,
      "2017c": 2.4,
      "2016c": 2.7,
      "2015c": 3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1249,
      "2016": 1337,
      "2017": 1341,
      "2018": 1379,
      "2019": "S",
      "2020": "S",
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 4.2,
      "2017c": 3.5,
      "2016c": 3.6,
      "2015c": 3.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 767,
      "2016": 749,
      "2017": 1072,
      "2018": 1332,
      "2019": 1454,
      "2020": "S",
      item: "All other government programs",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 11.8,
      "2018c": 13.8,
      "2017c": 7.4,
      "2016c": 8,
      "2015c": 7.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 31632,
      "2014": 32449,
      "2015": 33824,
      "2016": 37150,
      "2017": 38926,
      "2018": 41010,
      "2019": 43643,
      "2020": 45056,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.4,
      "2018c": 1.5,
      "2017c": 0,
      "2016c": 2.1,
      "2015c": 2.1,
      "2014c": 1.2,
      "2013c": 0.7
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5206,
      "2016": 6531,
      "2017": 7019,
      "2018": 7850,
      "2019": 8077,
      "2020": 8411,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 2.6,
      "2019c": 2.8,
      "2018c": 3.3,
      "2017c": 3.1,
      "2016c": 3.7,
      "2015c": 3.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4405,
      "2016": 5247,
      "2017": 6009,
      "2018": 6807,
      "2019": "S",
      "2020": 7874,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 3,
      "2019c": "S",
      "2018c": 2,
      "2017c": 2,
      "2016c": 5.2,
      "2015c": 4.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 5271,
      "2016": 4938,
      "2017": 5026,
      "2018": 5223,
      "2019": 5594,
      "2020": 5536,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 2.3,
      "2018c": 1.7,
      "2017c": 1.7,
      "2016c": 2.2,
      "2015c": 2.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2908,
      "2016": 3728,
      "2017": 3556,
      "2018": 3789,
      "2019": 3975,
      "2020": 4251,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 9.7,
      "2019c": 9.8,
      "2018c": 11.4,
      "2017c": 9.3,
      "2016c": 9.5,
      "2015c": 11.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3043,
      "2016": 3836,
      "2017": 3934,
      "2018": 3871,
      "2019": 3985,
      "2020": 4035,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 3.9,
      "2019c": 3.9,
      "2018c": 4.9,
      "2017c": 3.4,
      "2016c": 4.5,
      "2015c": 5.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1443,
      "2016": 1899,
      "2017": 2011,
      "2018": 1977,
      "2019": "S",
      "2020": 2129,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 8.5,
      "2019c": "S",
      "2018c": 8.5,
      "2017c": 8.5,
      "2016c": 9.2,
      "2015c": 9.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 788,
      "2016": 618,
      "2017": 628,
      "2018": 703,
      "2019": 723,
      "2020": 928,
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": 5.8,
      "2019c": 6.4,
      "2018c": 5.5,
      "2017c": 6.3,
      "2016c": 9.2,
      "2015c": 7.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 279,
      "2016": 278,
      "2017": "S",
      "2018": 283,
      "2019": 267,
      "2020": 252,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 18.5,
      "2019c": 21.8,
      "2018c": 18,
      "2017c": "S",
      "2016c": 15,
      "2015c": 16.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 103,
      "2016": 107,
      "2017": 166,
      "2018": 113,
      "2019": 234,
      "2020": 203,
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": 2.4,
      "2019c": 2.9,
      "2018c": 4.2,
      "2017c": 3.2,
      "2016c": 4.5,
      "2015c": 8.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 103,
      "2016": 200,
      "2017": 232,
      "2018": "S",
      "2019": 231,
      "2020": 193,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 12.9,
      "2019c": 7.7,
      "2018c": "S",
      "2017c": 15.7,
      "2016c": 13.5,
      "2015c": 12.3,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  engineering: [
    {
      "2013": 2176,
      "2014": 2135,
      "2015": "S",
      "2016": "S",
      "2017": 2884,
      "2018": 3136,
      "2019": "S",
      "2020": "S",
      item: "Surveying and mapping services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 28.3,
      "2017c": 17.4,
      "2016c": "S",
      "2015c": "S",
      "2014c": 19.5,
      "2013c": 21.5
    },
    {
      "2013": 25632,
      "2014": 24978,
      "2015": 30735,
      "2016": 29970,
      "2017": "S",
      "2018": 35888,
      "2019": 37556,
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 11.1,
      "2018c": 9,
      "2017c": "S",
      "2016c": 10.6,
      "2015c": 9.9,
      "2014c": 13,
      "2013c": 12
    },
    {
      "2013": "S",
      "2014": "S",
      "2015": 7481,
      "2016": 7864,
      "2017": 9794,
      "2018": 11698,
      "2019": 13106,
      "2020": "S",
      item: "Municipal utility engineering projects",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 22.9,
      "2018c": 18.1,
      "2017c": 19.8,
      "2016c": 19.9,
      "2015c": 20.4,
      "2014c": "S",
      "2013c": "S"
    },
    {
      "2013": 205222,
      "2014": 215887,
      "2015": 223530,
      "2016": 228014,
      "2017": 237304,
      "2018": 249085,
      "2019": 264653,
      "2020": 263164,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.7,
      "2019c": 3.5,
      "2018c": 1.7,
      "2017c": 0,
      "2016c": 5.1,
      "2015c": 4.9,
      "2014c": 3.9,
      "2013c": 3
    },
    {
      "2013": 41424,
      "2014": 45214,
      "2015": 38518,
      "2016": 38162,
      "2017": 44529,
      "2018": 47311,
      "2019": 40293,
      "2020": 36017,
      item: "Industrial and manufacturing engineering projects",
      taxexempt: "all",
      "2020c": 9.3,
      "2019c": 8.2,
      "2018c": 8.7,
      "2017c": 11.2,
      "2016c": 10.9,
      "2015c": 9.8,
      "2014c": 8.9,
      "2013c": 8.9
    },
    {
      "2013": 24445,
      "2014": 28587,
      "2015": 29254,
      "2016": 30883,
      "2017": "S",
      "2018": 27373,
      "2019": 34323,
      "2020": 35903,
      item: "Other engineering projects",
      taxexempt: "all",
      "2020c": 12.2,
      "2019c": 10.8,
      "2018c": 12.2,
      "2017c": "S",
      "2016c": 12.7,
      "2015c": 10.8,
      "2014c": 12.1,
      "2013c": 12.4
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 25618,
      "2016": 26655,
      "2017": 29416,
      "2018": 28164,
      "2019": 30490,
      "2020": 33924,
      item:
        "Commercial, public, and institutional engineering projects (includes apartment building projects)",
      taxexempt: "all",
      "2020c": 14,
      "2019c": 12.1,
      "2018c": 11.1,
      "2017c": 12.9,
      "2016c": 12.3,
      "2015c": 11.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 18091,
      "2014": 20261,
      "2015": 23077,
      "2016": 24631,
      "2017": 27420,
      "2018": 29612,
      "2019": 29425,
      "2020": 33771,
      item: "Transportation infrastructure engineering projects",
      taxexempt: "all",
      "2020c": 9.4,
      "2019c": 10.7,
      "2018c": 9.9,
      "2017c": 8.8,
      "2016c": 12.3,
      "2015c": 11.6,
      "2014c": 18.1,
      "2013c": 14.3
    },
    {
      "2013": 19474,
      "2014": 19414,
      "2015": 20527,
      "2016": 19374,
      "2017": 18891,
      "2018": 17161,
      "2019": 23292,
      "2020": 25092,
      item: "Construction services",
      taxexempt: "all",
      "2020c": 11.1,
      "2019c": 11.1,
      "2018c": 9.3,
      "2017c": 8.6,
      "2016c": 16.7,
      "2015c": 13,
      "2014c": 15.1,
      "2013c": 16.1
    },
    {
      "2013": 25384,
      "2014": 22873,
      "2015": 11799,
      "2016": 14927,
      "2017": 13208,
      "2018": "S",
      "2019": 13423,
      "2020": 14874,
      item: "Power generation and distribution engineering projects",
      taxexempt: "all",
      "2020c": 15.5,
      "2019c": 8.3,
      "2018c": "S",
      "2017c": 7.3,
      "2016c": 11.4,
      "2015c": 12,
      "2014c": 20.4,
      "2013c": 17.5
    },
    {
      "2013": 5287,
      "2014": 5081,
      "2015": 13860,
      "2016": 12477,
      "2017": 12325,
      "2018": 13814,
      "2019": 17141,
      "2020": 13592,
      item: "Engineering advisory and drafting services",
      taxexempt: "all",
      "2020c": 20.1,
      "2019c": 20.1,
      "2018c": 22.3,
      "2017c": 19.2,
      "2016c": 19,
      "2015c": 17.1,
      "2014c": 14.3,
      "2013c": 12.8
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7205,
      "2016": 8031,
      "2017": 8317,
      "2018": 9807,
      "2019": 10479,
      "2020": 9739,
      item:
        "Residential engineering projects (excludes apartment building projects)",
      taxexempt: "all",
      "2020c": 26.9,
      "2019c": 28.8,
      "2018c": 21.6,
      "2017c": 19.1,
      "2016c": 16.1,
      "2015c": 14.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 6135,
      "2014": 7638,
      "2015": 8026,
      "2016": 7692,
      "2017": 7066,
      "2018": 6586,
      "2019": 6426,
      "2020": 6321,
      item: "Hazardous waste and industrial waste engineering projects",
      taxexempt: "all",
      "2020c": 20.9,
      "2019c": 21.4,
      "2018c": 15.3,
      "2017c": 17.7,
      "2016c": 15.3,
      "2015c": 13.9,
      "2014c": 21.8,
      "2013c": 14.4
    },
    {
      "2013": 3127,
      "2014": 3242,
      "2015": 4688,
      "2016": 4565,
      "2017": 4545,
      "2018": "S",
      "2019": 5385,
      "2020": 6274,
      item: "Telecommunications and broadcasting engineering projects",
      taxexempt: "all",
      "2020c": 12.3,
      "2019c": 15.2,
      "2018c": "S",
      "2017c": 21.7,
      "2016c": 10.2,
      "2015c": 17.3,
      "2014c": 10.6,
      "2013c": 11.3
    }
  ],
  recycling: [
    {
      "2013": 3103,
      "2014": 3025,
      "2015": 1409,
      "2016": 1469,
      "2017": "S",
      "2018": 2864,
      "2019": "S",
      "2020": "S",
      item:
        "Nonhazardous waste and recyclable material consolidation, storage, and preparation services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 2.8,
      "2017c": "S",
      "2016c": 2.4,
      "2015c": 2.5,
      "2014c": 1.8,
      "2013c": 1.3
    },
    {
      "2013": 55,
      "2014": 64,
      "2015": "S",
      "2016": 16,
      "2017": 123,
      "2018": 189,
      "2019": 191,
      "2020": "S",
      item: "Other waste management services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 2.2,
      "2018c": 2.3,
      "2017c": 2.6,
      "2016c": 14.2,
      "2015c": "S",
      "2014c": 24.4,
      "2013c": 24.5
    },
    {
      "2013": 5597,
      "2014": 5775,
      "2015": 5363,
      "2016": 5399,
      "2017": 5940,
      "2018": 6587,
      "2019": 6927,
      "2020": 7177,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.7,
      "2019c": 1.4,
      "2018c": 1.3,
      "2017c": 0,
      "2016c": 2.3,
      "2015c": 2.3,
      "2014c": 1.9,
      "2013c": 0.8
    },
    {
      "2013": 2017,
      "2014": 2232,
      "2015": 3108,
      "2016": 3090,
      "2017": 2552,
      "2018": 2705,
      "2019": 2458,
      "2020": 2590,
      item: "Sale or brokerage of nonhazardous recyclable material",
      taxexempt: "all",
      "2020c": 2.8,
      "2019c": 3.7,
      "2018c": 3.1,
      "2017c": 1.9,
      "2016c": 2.4,
      "2015c": 2.4,
      "2014c": 4.2,
      "2013c": 1.9
    },
    {
      "2013": 422,
      "2014": 454,
      "2015": 833,
      "2016": 824,
      "2017": 710,
      "2018": 829,
      "2019": 769,
      "2020": 747,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 13.1,
      "2019c": 9.9,
      "2018c": 6.8,
      "2017c": 4.2,
      "2016c": 5.1,
      "2015c": 3.9,
      "2014c": 5.5,
      "2013c": 6.1
    }
  ],
  newspapers: [
    {
      "2013": 1954,
      "2014": 1911,
      "2015": 1239,
      "2016": 1162,
      "2017": 1369,
      "2018": 1772,
      "2019": "S",
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 6.7,
      "2017c": 4.3,
      "2016c": 6.3,
      "2015c": 6.1,
      "2014c": 9.4,
      "2013c": 10.1
    },
    {
      "2013": 1081,
      "2014": 1058,
      "2015": 619,
      "2016": 553,
      "2017": 544,
      "2018": 455,
      "2019": "S",
      "2020": "S",
      item: "Distribution services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 3.5,
      "2017c": 4.8,
      "2016c": 5.5,
      "2015c": 5.4,
      "2014c": 2.9,
      "2013c": 2.4
    },
    {
      "2013": 1457,
      "2014": 1463,
      "2015": 1113,
      "2016": 1090,
      "2017": 1153,
      "2018": 1163,
      "2019": 1188,
      "2020": "S",
      item: "Printing services for others",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 6.6,
      "2018c": 8.5,
      "2017c": 7.1,
      "2016c": 7.2,
      "2015c": 6.3,
      "2014c": 7.6,
      "2013c": 7
    },
    {
      "2013": 28601,
      "2014": 28283,
      "2015": 26798,
      "2016": 25564,
      "2017": 26012,
      "2018": 25436,
      "2019": 23689,
      "2020": 22149,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.3,
      "2019c": 1.2,
      "2018c": 0.7,
      "2017c": 0,
      "2016c": 1.4,
      "2015c": 1.4,
      "2014c": 0.7,
      "2013c": 0.7
    },
    {
      "2013": 16131,
      "2014": 15762,
      "2015": 14867,
      "2016": 13799,
      "2017": 13788,
      "2018": 12788,
      "2019": 11648,
      "2020": 10221,
      item: "Advertising space",
      taxexempt: "all",
      "2020c": 1.5,
      "2019c": 1.7,
      "2018c": 1.5,
      "2017c": 1.2,
      "2016c": 1.3,
      "2015c": 1.3,
      "2014c": 1.4,
      "2013c": 1.3
    },
    {
      "2013": 7978,
      "2014": 8089,
      "2015": 8960,
      "2016": 8960,
      "2017": 9158,
      "2018": 9258,
      "2019": 8850,
      "2020": 8795,
      item: "Subscriptions and Sales",
      taxexempt: "all",
      "2020c": 2.3,
      "2019c": 2,
      "2018c": 1.6,
      "2017c": 1.4,
      "2016c": 2.3,
      "2015c": 2.3,
      "2014c": 1.6,
      "2013c": 1.7
    }
  ],
  expositions: [
    {
      "2013": 914,
      "2014": 945,
      "2015": 630,
      "2016": 609,
      "2017": 1829,
      "2018": 1448,
      "2019": "S",
      "2020": "S",
      item: "Printing services for others",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 9.8,
      "2017c": 4.6,
      "2016c": 11.6,
      "2015c": 11.5,
      "2014c": 18.3,
      "2013c": 19.1
    },
    {
      "2013": 28867,
      "2014": 28747,
      "2015": 27382,
      "2016": 27008,
      "2017": 26733,
      "2018": 25795,
      "2019": 26409,
      "2020": 23919,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.4,
      "2019c": 3.1,
      "2018c": 3.2,
      "2017c": 0,
      "2016c": 2.9,
      "2015c": 2.5,
      "2014c": 1,
      "2013c": 0.8
    },
    {
      "2013": 10574,
      "2014": 10211,
      "2015": 9775,
      "2016": 9432,
      "2017": "S",
      "2018": 8513,
      "2019": 9766,
      "2020": 9168,
      item: "Periodicals - Subscriptions and sales",
      taxexempt: "all",
      "2020c": 3.6,
      "2019c": 7.1,
      "2018c": 4.2,
      "2017c": "S",
      "2016c": 3.2,
      "2015c": 2.3,
      "2014c": 4.7,
      "2013c": 4.7
    },
    {
      "2013": 12480,
      "2014": 12208,
      "2015": 14019,
      "2016": 13797,
      "2017": "S",
      "2018": "S",
      "2019": 9949,
      "2020": 8958,
      item: "Periodicals - Advertising space",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 5.6,
      "2018c": "S",
      "2017c": "S",
      "2016c": 3.5,
      "2015c": 3.4,
      "2014c": 3.3,
      "2013c": 3.4
    },
    {
      "2013": 4472,
      "2014": 4897,
      "2015": 2488,
      "2016": 2708,
      "2017": 2461,
      "2018": 4283,
      "2019": 4926,
      "2020": 4348,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 8.9,
      "2019c": 7.7,
      "2018c": 7.4,
      "2017c": 12.5,
      "2016c": 12.2,
      "2015c": 11.6,
      "2014c": 6.6,
      "2013c": 7.4
    },
    {
      "2013": 427,
      "2014": 486,
      "2015": 470,
      "2016": 462,
      "2017": "S",
      "2018": "S",
      "2019": 518,
      "2020": 389,
      item: "Licensing of rights to content",
      taxexempt: "all",
      "2020c": 9.3,
      "2019c": 5.9,
      "2018c": "S",
      "2017c": "S",
      "2016c": 8.7,
      "2015c": 8,
      "2014c": 16.4,
      "2013c": 16.8
    }
  ],
  architecture: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1340,
      "2016": 1309,
      "2017": 945,
      "2018": 980,
      "2019": "S",
      "2020": "S",
      item:
        "Multi-family residential projects (excludes apartment building projects)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 16,
      "2017c": 15.8,
      "2016c": 15.4,
      "2015c": 13.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 28574,
      "2014": 31370,
      "2015": 33907,
      "2016": 36180,
      "2017": 37805,
      "2018": 40516,
      "2019": 43237,
      "2020": 42182,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 4.6,
      "2019c": 3.2,
      "2018c": 2.4,
      "2017c": 0,
      "2016c": 4.1,
      "2015c": 3.9,
      "2014c": 3.4,
      "2013c": 2.3
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 23418,
      "2016": 25754,
      "2017": 26998,
      "2018": 29264,
      "2019": 30144,
      "2020": 29206,
      item:
        "Non-residential building projects (includes apartment building projects)",
      taxexempt: "all",
      "2020c": 6.3,
      "2019c": 4.4,
      "2018c": 4.9,
      "2017c": 3.5,
      "2016c": 5.1,
      "2015c": 4.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 2970,
      "2014": 3012,
      "2015": 2972,
      "2016": 2886,
      "2017": 3035,
      "2018": 3522,
      "2019": 4819,
      "2020": 4242,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 18.3,
      "2019c": 20.6,
      "2018c": 21.3,
      "2017c": 17.6,
      "2016c": 14.8,
      "2015c": 18.2,
      "2014c": 15.6,
      "2013c": 13.7
    },
    {
      "2013": 2314,
      "2014": 2920,
      "2015": 3805,
      "2016": 3667,
      "2017": 4039,
      "2018": 3824,
      "2019": 4059,
      "2020": 3949,
      item: "Single-family residential projects",
      taxexempt: "all",
      "2020c": 24.1,
      "2019c": 21.5,
      "2018c": 25.6,
      "2017c": 23.6,
      "2016c": 24.2,
      "2015c": 23.1,
      "2014c": 15.5,
      "2013c": 16.9
    },
    {
      "2013": 2274,
      "2014": 2228,
      "2015": 1989,
      "2016": 2159,
      "2017": 2440,
      "2018": 2589,
      "2019": 2827,
      "2020": 3063,
      item: "Interior design services",
      taxexempt: "all",
      "2020c": 26.5,
      "2019c": 20.3,
      "2018c": 19.6,
      "2017c": 17.9,
      "2016c": 17.7,
      "2015c": 16.3,
      "2014c": 14.6,
      "2013c": 16.7
    },
    {
      "2013": 124,
      "2014": 161,
      "2015": "S",
      "2016": "S",
      "2017": 348,
      "2018": 337,
      "2019": "S",
      "2020": 196,
      item: "Landscape architecture",
      taxexempt: "all",
      "2020c": 19.6,
      "2019c": "S",
      "2018c": 28.5,
      "2017c": 29.4,
      "2016c": "S",
      "2015c": "S",
      "2014c": 14.1,
      "2013c": 19
    }
  ],
  psychiatryoffices: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 397,
      "2016": 419,
      "2017": 395,
      "2018": 387,
      "2019": "S",
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 6.4,
      "2017c": 6.2,
      "2016c": 14.1,
      "2015c": 14,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": 150,
      "2017": "S",
      "2018": 141,
      "2019": "S",
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 8.6,
      "2017c": "S",
      "2016c": 29,
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 173,
      "2016": 139,
      "2017": 147,
      "2018": 143,
      "2019": 155,
      "2020": "S",
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 8.8,
      "2018c": 6.6,
      "2017c": 10.5,
      "2016c": 16.1,
      "2015c": 15.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 5113,
      "2014": 5216,
      "2015": 5217,
      "2016": 5496,
      "2017": 5839,
      "2018": 6351,
      "2019": 6965,
      "2020": 7568,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 2.3,
      "2018c": 1.5,
      "2017c": 0,
      "2016c": 13.3,
      "2015c": 14.6,
      "2014c": 16.3,
      "2013c": 16.7
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1721,
      "2016": 1826,
      "2017": 1948,
      "2018": 2186,
      "2019": 2470,
      "2020": 2782,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 4.2,
      "2019c": 3.2,
      "2018c": 2.1,
      "2017c": 2.8,
      "2016c": 14.2,
      "2015c": 15.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1068,
      "2016": 1125,
      "2017": 1227,
      "2018": 1325,
      "2019": 1454,
      "2020": 1512,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 3.8,
      "2018c": 3.5,
      "2017c": 4.1,
      "2016c": 12.7,
      "2015c": 13.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 622,
      "2016": 701,
      "2017": 776,
      "2018": 868,
      "2019": 957,
      "2020": 1079,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 14.5,
      "2019c": 15,
      "2018c": 11.3,
      "2017c": 6.5,
      "2016c": 15,
      "2015c": 16,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 428,
      "2016": 425,
      "2017": 440,
      "2018": 479,
      "2019": 508,
      "2020": 497,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 5.2,
      "2019c": 4.6,
      "2018c": 5.4,
      "2017c": 5.8,
      "2016c": 15.7,
      "2015c": 15.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 283,
      "2016": 292,
      "2017": 314,
      "2018": 345,
      "2019": 358,
      "2020": 390,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 7.7,
      "2019c": 5.1,
      "2018c": 7.9,
      "2017c": 7.4,
      "2016c": 15.9,
      "2015c": 17.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 228,
      "2016": 237,
      "2017": 254,
      "2018": 263,
      "2019": 290,
      "2020": 340,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 18.1,
      "2019c": 17.8,
      "2018c": 17.6,
      "2017c": 16,
      "2016c": 24.1,
      "2015c": 23.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 75,
      "2016": 74,
      "2017": 82,
      "2018": 87,
      "2019": 89,
      "2020": 94,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 18,
      "2019c": 15.4,
      "2018c": 15.1,
      "2017c": 16.7,
      "2016c": 10.4,
      "2015c": 11.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": "S",
      "2017": "S",
      "2018": 14,
      "2019": 15,
      "2020": 12,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 21,
      "2019c": 28,
      "2018c": 29.7,
      "2017c": "S",
      "2016c": "S",
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  diagnosticlabs: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 277,
      "2016": 296,
      "2017": 304,
      "2018": 340,
      "2019": "S",
      "2020": "S",
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 24.4,
      "2017c": 26,
      "2016c": 21.7,
      "2015c": 19.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 315,
      "2016": 308,
      "2017": 307,
      "2018": "S",
      "2019": 327,
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 8.3,
      "2018c": "S",
      "2017c": 9.7,
      "2016c": 12.1,
      "2015c": 24.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 46665,
      "2014": 47671,
      "2015": 49303,
      "2016": 50947,
      "2017": 53610,
      "2018": 54164,
      "2019": 55607,
      "2020": 60736,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 0.9,
      "2018c": 0.7,
      "2017c": 0,
      "2016c": 2.6,
      "2015c": 2.8,
      "2014c": 1.7,
      "2013c": 1.1
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 21132,
      "2016": 21710,
      "2017": 22623,
      "2018": 21663,
      "2019": 22235,
      "2020": 24038,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 1.5,
      "2019c": 1.6,
      "2018c": 2.2,
      "2017c": 1.8,
      "2016c": 2.8,
      "2015c": 2.8,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7295,
      "2016": 7428,
      "2017": 8616,
      "2018": 9256,
      "2019": 9101,
      "2020": 11161,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 6.5,
      "2019c": 4.3,
      "2018c": 4.1,
      "2017c": 4.1,
      "2016c": 4.2,
      "2015c": 4.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4991,
      "2016": 4869,
      "2017": 5076,
      "2018": 5241,
      "2019": 5222,
      "2020": 6456,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 5.2,
      "2019c": 2.2,
      "2018c": 2.6,
      "2017c": 2.3,
      "2016c": 3.8,
      "2015c": 4.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2531,
      "2016": 2855,
      "2017": 2910,
      "2018": "S",
      "2019": 3308,
      "2020": 3574,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 17.8,
      "2019c": 19.1,
      "2018c": "S",
      "2017c": 15.8,
      "2016c": 10,
      "2015c": 6.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2185,
      "2016": 2673,
      "2017": 2646,
      "2018": 2540,
      "2019": 2465,
      "2020": 2425,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 8.1,
      "2019c": 6.9,
      "2018c": 8,
      "2017c": 4.1,
      "2016c": 8.9,
      "2015c": 9.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1564,
      "2016": 1585,
      "2017": 1620,
      "2018": 1656,
      "2019": 1720,
      "2020": 1833,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 5.4,
      "2019c": 3.2,
      "2018c": 4.7,
      "2017c": 5.6,
      "2016c": 3.5,
      "2015c": 2.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 949,
      "2016": 1051,
      "2017": 1052,
      "2018": 1037,
      "2019": 1240,
      "2020": 1237,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 8.9,
      "2019c": 6.7,
      "2018c": 8,
      "2017c": 5.9,
      "2016c": 15.1,
      "2015c": 17.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 545,
      "2016": 521,
      "2017": 504,
      "2018": 593,
      "2019": 659,
      "2020": 884,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 6.5,
      "2019c": 6.4,
      "2018c": 6,
      "2017c": 3.2,
      "2016c": 11.7,
      "2015c": 10.3,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  chiropractoroffices: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 313,
      "2016": 302,
      "2017": 312,
      "2018": 294,
      "2019": "S",
      "2020": "S",
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 7.7,
      "2017c": 8.8,
      "2016c": 10.6,
      "2015c": 11.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 294,
      "2016": 251,
      "2017": 211,
      "2018": 208,
      "2019": 232,
      "2020": "S",
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 14.8,
      "2018c": 14.7,
      "2017c": 15.4,
      "2016c": 13.9,
      "2015c": 15.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 11765,
      "2014": 12001,
      "2015": 12438,
      "2016": 12901,
      "2017": 13431,
      "2018": 14137,
      "2019": 14935,
      "2020": 14546,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.6,
      "2019c": 1.2,
      "2018c": 1.1,
      "2017c": 0,
      "2016c": 1.4,
      "2015c": 1.3,
      "2014c": 1.3,
      "2013c": 1
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4055,
      "2016": 4284,
      "2017": 4756,
      "2018": 5099,
      "2019": 5572,
      "2020": 5454,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 2.6,
      "2019c": 2.5,
      "2018c": 3,
      "2017c": 2.7,
      "2016c": 3.6,
      "2015c": 3.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3671,
      "2016": 3739,
      "2017": 3891,
      "2018": 4017,
      "2019": 4268,
      "2020": 4092,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 3.1,
      "2019c": 3.5,
      "2018c": 2.9,
      "2017c": 2.5,
      "2016c": 2.4,
      "2015c": 2.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1288,
      "2016": 1401,
      "2017": 1622,
      "2018": 1795,
      "2019": 1829,
      "2020": 1693,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 7.3,
      "2019c": 6.9,
      "2018c": 6.3,
      "2017c": 5.7,
      "2016c": 5.1,
      "2015c": 4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1526,
      "2016": 1604,
      "2017": 1347,
      "2018": 1388,
      "2019": 1388,
      "2020": 1428,
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": 5.6,
      "2019c": 6.8,
      "2018c": 6.6,
      "2017c": 4.8,
      "2016c": 5,
      "2015c": 4.9,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 719,
      "2016": 722,
      "2017": 682,
      "2018": 711,
      "2019": 782,
      "2020": 751,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 4.7,
      "2019c": 4.1,
      "2018c": 4,
      "2017c": 5.4,
      "2016c": 4.1,
      "2015c": 4.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 267,
      "2016": 281,
      "2017": 270,
      "2018": 276,
      "2019": "S",
      "2020": 229,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 10.5,
      "2019c": "S",
      "2018c": 10.3,
      "2017c": 14.1,
      "2016c": 9.8,
      "2015c": 10.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 75,
      "2016": 86,
      "2017": 105,
      "2018": 110,
      "2019": 114,
      "2020": 113,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 12.4,
      "2019c": 13.1,
      "2018c": 16.4,
      "2017c": 16.8,
      "2016c": 14.9,
      "2015c": 17.1,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 101,
      "2016": 84,
      "2017": 87,
      "2018": 90,
      "2019": 93,
      "2020": 104,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 13.3,
      "2019c": 5.9,
      "2018c": 5.3,
      "2017c": 5.7,
      "2016c": 6.9,
      "2015c": 16.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 129,
      "2016": 113,
      "2017": 118,
      "2018": "S",
      "2019": "S",
      "2020": 86,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 16.7,
      "2019c": "S",
      "2018c": "S",
      "2017c": 18.1,
      "2016c": 17.3,
      "2015c": 16.7,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  depositaries: [
    {
      "2013": 476,
      "2014": 452,
      "2015": 236,
      "2016": 210,
      "2017": 190,
      "2018": 171,
      "2019": "S",
      "2020": "S",
      item: "Trading foreign currency on own account - net gains (losses)",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 26.6,
      "2017c": 21.8,
      "2016c": 19.2,
      "2015c": 18.6,
      "2014c": 17.9,
      "2013c": 25
    },
    {
      "2013": 12371,
      "2014": 13436,
      "2015": 13440,
      "2016": 12764,
      "2017": 12628,
      "2018": 13332,
      "2019": 13988,
      "2020": 15384,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 1.6,
      "2018c": 1,
      "2017c": 0,
      "2016c": 3.9,
      "2015c": 3.6,
      "2014c": 2.7,
      "2013c": 1.4
    },
    {
      "2013": 2384,
      "2014": 2297,
      "2015": 2468,
      "2016": 3836,
      "2017": 3742,
      "2018": 4078,
      "2019": 4269,
      "2020": 4760,
      item: "Brokering and dealing products - derivative contracts",
      taxexempt: "all",
      "2020c": 3.7,
      "2019c": 3.6,
      "2018c": 2.9,
      "2017c": 2.6,
      "2016c": 5.2,
      "2015c": 9,
      "2014c": 5.5,
      "2013c": 3.9
    },
    {
      "2013": 2254,
      "2014": 2826,
      "2015": 3684,
      "2016": 2907,
      "2017": 2951,
      "2018": 3963,
      "2019": 3942,
      "2020": 4685,
      item: "Trading derivative contracts on own account - net gains (losses)",
      taxexempt: "all",
      "2020c": 3,
      "2019c": 2,
      "2018c": 2.3,
      "2017c": 4.3,
      "2016c": 9.5,
      "2015c": 7.6,
      "2014c": 5.5,
      "2013c": 4.6
    },
    {
      "2013": 3411,
      "2014": 3658,
      "2015": 3282,
      "2016": 2578,
      "2017": 2516,
      "2018": "S",
      "2019": 2240,
      "2020": 2293,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 7.3,
      "2019c": 6.8,
      "2018c": "S",
      "2017c": 3.3,
      "2016c": 5.3,
      "2015c": 4.5,
      "2014c": 8.8,
      "2013c": 6.1
    },
    {
      "2013": 1848,
      "2014": 2040,
      "2015": 2238,
      "2016": 2169,
      "2017": 2059,
      "2018": "S",
      "2019": 2105,
      "2020": 1979,
      item: "Brokering and dealing other financial instruments",
      taxexempt: "all",
      "2020c": 2.6,
      "2019c": 1.6,
      "2018c": "S",
      "2017c": 1.7,
      "2016c": 3,
      "2015c": 3.4,
      "2014c": 2.9,
      "2013c": 3
    },
    {
      "2013": 359,
      "2014": 403,
      "2015": 469,
      "2016": 402,
      "2017": 461,
      "2018": 408,
      "2019": 487,
      "2020": 601,
      item: "Brokering and dealing products - equities",
      taxexempt: "all",
      "2020c": 1.4,
      "2019c": 1.7,
      "2018c": 1.3,
      "2017c": 1.3,
      "2016c": 4.2,
      "2015c": 4.2,
      "2014c": 6.1,
      "2013c": 7
    },
    {
      "2013": 455,
      "2014": 398,
      "2015": 590,
      "2016": 431,
      "2017": 442,
      "2018": 458,
      "2019": 464,
      "2020": 526,
      item: "Management of financial market clearing products",
      taxexempt: "all",
      "2020c": 13.4,
      "2019c": 14.8,
      "2018c": 23.7,
      "2017c": 16.2,
      "2016c": 12,
      "2015c": 18.5,
      "2014c": 4.3,
      "2013c": 8.5
    },
    {
      "2013": 1184,
      "2014": 1362,
      "2015": 473,
      "2016": 231,
      "2017": 267,
      "2018": 253,
      "2019": 291,
      "2020": 308,
      item: "Brokering and dealing foreign currency fees - wholesale",
      taxexempt: "all",
      "2020c": 11.7,
      "2019c": 10,
      "2018c": 10.3,
      "2017c": 13.2,
      "2016c": 4.5,
      "2015c": 4.1,
      "2014c": 10.7,
      "2013c": 7.7
    }
  ],
  travelagency: [
    {
      "2013": 450,
      "2014": 521,
      "2015": 118,
      "2016": 124,
      "2017": 113,
      "2018": 130,
      "2019": "S",
      "2020": "S",
      item:
        "Commissions or fees from automobile clubs and road and travel service",
      taxexempt: "all",
      "2020c": "S",
      "2019c": "S",
      "2018c": 25.2,
      "2017c": 21.5,
      "2016c": 20,
      "2015c": 19.7,
      "2014c": 3.6,
      "2013c": 3.5
    },
    {
      "2013": 1048,
      "2014": 1185,
      "2015": 857,
      "2016": 986,
      "2017": 1301,
      "2018": 1528,
      "2019": 1561,
      "2020": "S",
      item:
        "Commissions or fees from airline seats, international destinations",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 8.8,
      "2018c": 7.2,
      "2017c": 7.4,
      "2016c": 7.3,
      "2015c": 7.4,
      "2014c": 10.5,
      "2013c": 11.6
    },
    {
      "2013": 639,
      "2014": 711,
      "2015": 629,
      "2016": 700,
      "2017": 818,
      "2018": 866,
      "2019": 1015,
      "2020": "S",
      item: "Commissions or fees from computerized reservation systems",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 5.9,
      "2018c": 5.1,
      "2017c": 3.5,
      "2016c": 4.3,
      "2015c": 3.3,
      "2014c": 3.5,
      "2013c": 2.9
    },
    {
      "2013": 14616,
      "2014": 16799,
      "2015": 19050,
      "2016": 22979,
      "2017": 27512,
      "2018": 31089,
      "2019": 32959,
      "2020": 17525,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2,
      "2019c": 1.9,
      "2018c": 1.7,
      "2017c": 0,
      "2016c": 2,
      "2015c": 2.1,
      "2014c": 1.9,
      "2013c": 1.3
    },
    {
      "2013": 2614,
      "2014": 3323,
      "2015": 6257,
      "2016": 7332,
      "2017": 8326,
      "2018": 8751,
      "2019": 9718,
      "2020": 5876,
      item: "Commissions or fees from lodging",
      taxexempt: "all",
      "2020c": 2,
      "2019c": 1.3,
      "2018c": 1.5,
      "2017c": 1.5,
      "2016c": 2.7,
      "2015c": 3,
      "2014c": 3.9,
      "2013c": 4.5
    },
    {
      "2013": 1592,
      "2014": 1859,
      "2015": 2375,
      "2016": 2627,
      "2017": 2793,
      "2018": 3640,
      "2019": 3649,
      "2020": 2032,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 4.3,
      "2019c": 4.6,
      "2018c": 5.7,
      "2017c": 4.4,
      "2016c": 4.3,
      "2015c": 5.6,
      "2014c": 3.6,
      "2013c": 3.7
    },
    {
      "2013": 3335,
      "2014": 3492,
      "2015": 2602,
      "2016": 2939,
      "2017": 4699,
      "2018": 4957,
      "2019": 4818,
      "2020": 1974,
      item: "Commissions or fees from trip planning",
      taxexempt: "all",
      "2020c": 3.4,
      "2019c": 3.3,
      "2018c": 3.6,
      "2017c": 3,
      "2016c": 4.7,
      "2015c": 4.4,
      "2014c": 7.3,
      "2013c": 7.8
    },
    {
      "2013": 1156,
      "2014": 1413,
      "2015": 1671,
      "2016": 2153,
      "2017": 2488,
      "2018": 2797,
      "2019": 3121,
      "2020": 1556,
      item: "Commissions or fees from airline seats, domestic destinations",
      taxexempt: "all",
      "2020c": 8.1,
      "2019c": 4.4,
      "2018c": 2.9,
      "2017c": 3,
      "2016c": 3.1,
      "2015c": 3.3,
      "2014c": 7.9,
      "2013c": 9.9
    },
    {
      "2013": 1185,
      "2014": 1455,
      "2015": 1235,
      "2016": 1406,
      "2017": 1702,
      "2018": 2439,
      "2019": 2628,
      "2020": 1482,
      item: "Commissions or fees from cruises",
      taxexempt: "all",
      "2020c": 15,
      "2019c": 11.6,
      "2018c": 15,
      "2017c": 3.3,
      "2016c": 4.3,
      "2015c": 4.9,
      "2014c": 12.9,
      "2013c": 10.9
    },
    {
      "2013": 745,
      "2014": 838,
      "2015": 991,
      "2016": 1228,
      "2017": 1410,
      "2018": 1566,
      "2019": 1700,
      "2020": 1149,
      item: "Commissions or fees from other reservation services",
      taxexempt: "all",
      "2020c": 1.8,
      "2019c": 4.9,
      "2018c": 5.2,
      "2017c": 2.6,
      "2016c": 2.6,
      "2015c": 2.6,
      "2014c": 12.2,
      "2013c": 16.3
    },
    {
      "2013": 1327,
      "2014": 1407,
      "2015": 1362,
      "2016": 1579,
      "2017": 2044,
      "2018": 2445,
      "2019": 2432,
      "2020": 1133,
      item: "Commissions or fees from packaged tours",
      taxexempt: "all",
      "2020c": 26.8,
      "2019c": 19.1,
      "2018c": 14.4,
      "2017c": 12.1,
      "2016c": 13.4,
      "2015c": 12.7,
      "2014c": 9.8,
      "2013c": 9.1
    },
    {
      "2013": 334,
      "2014": "S",
      "2015": 567,
      "2016": 1459,
      "2017": 1392,
      "2018": 1541,
      "2019": 1723,
      "2020": 996,
      item:
        "Commissions or fees from other travel arrangement services revenue",
      taxexempt: "all",
      "2020c": 1.9,
      "2019c": 2,
      "2018c": 2,
      "2017c": 3.4,
      "2016c": 2.3,
      "2015c": 2.7,
      "2014c": "S",
      "2013c": 6
    },
    {
      "2013": 191,
      "2014": 203,
      "2015": 386,
      "2016": 446,
      "2017": 426,
      "2018": 429,
      "2019": 457,
      "2020": 211,
      item: "Commissions or fees from event tickets",
      taxexempt: "all",
      "2020c": 5.4,
      "2019c": 2.6,
      "2018c": 4.7,
      "2017c": 2.7,
      "2016c": 2.5,
      "2015c": 3,
      "2014c": 17.7,
      "2013c": 16.6
    }
  ],
  bookkeepingandaccounting: [
    {
      "2013": 20173,
      "2014": 21894,
      "2015": 21887,
      "2016": 19131,
      "2017": 20034,
      "2018": 19698,
      "2019": 21278,
      "2020": "S",
      item: "Payroll services",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 3.8,
      "2018c": 3.6,
      "2017c": 2.6,
      "2016c": 6,
      "2015c": 19.6,
      "2014c": 2.1,
      "2013c": 2.8
    },
    {
      "2013": 126521,
      "2014": 133893,
      "2015": 142360,
      "2016": 146214,
      "2017": 152380,
      "2018": 157619,
      "2019": 167085,
      "2020": 170867,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 1.4,
      "2019c": 1,
      "2018c": 1.1,
      "2017c": 0,
      "2016c": 1.6,
      "2015c": 3.3,
      "2014c": 1,
      "2013c": 0.7
    },
    {
      "2013": 31084,
      "2014": 32907,
      "2015": 35444,
      "2016": 37926,
      "2017": 38436,
      "2018": 38520,
      "2019": 39347,
      "2020": 39966,
      item: "Assurance and related services",
      taxexempt: "all",
      "2020c": 2.7,
      "2019c": 2.8,
      "2018c": 2.8,
      "2017c": 3,
      "2016c": 3.4,
      "2015c": 3.4,
      "2014c": 4,
      "2013c": 3.7
    },
    {
      "2013": 13985,
      "2014": 15038,
      "2015": 16956,
      "2016": 17972,
      "2017": 19340,
      "2018": 20248,
      "2019": 21036,
      "2020": 24223,
      item:
        "Taxation preparation and representation services for corporate and other clients",
      taxexempt: "all",
      "2020c": 3.4,
      "2019c": 4,
      "2018c": 3.5,
      "2017c": 3.8,
      "2016c": 4.1,
      "2015c": 4.3,
      "2014c": 4.1,
      "2013c": 5
    },
    {
      "2013": 9940,
      "2014": 9897,
      "2015": 16351,
      "2016": 17587,
      "2017": 19504,
      "2018": 20641,
      "2019": 21189,
      "2020": 22611,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 3.6,
      "2019c": 4.7,
      "2018c": 4.3,
      "2017c": 3.8,
      "2016c": 5.1,
      "2015c": 4.8,
      "2014c": 9.3,
      "2013c": 12.3
    },
    {
      "2013": 16507,
      "2014": 17462,
      "2015": 19295,
      "2016": 19915,
      "2017": 19140,
      "2018": 19897,
      "2019": 20658,
      "2020": 21291,
      item:
        "Taxation preparation and representation services for individuals and unincorporated businesses",
      taxexempt: "all",
      "2020c": 6,
      "2019c": 5.5,
      "2018c": 4.4,
      "2017c": 4.7,
      "2016c": 5.6,
      "2015c": 5.4,
      "2014c": 3.6,
      "2013c": 4.3
    },
    {
      "2013": 12586,
      "2014": 14538,
      "2015": 9619,
      "2016": 10051,
      "2017": 10158,
      "2018": 12479,
      "2019": 15004,
      "2020": 15046,
      item: "consulting",
      taxexempt: "all",
      "2020c": 4,
      "2019c": 4.2,
      "2018c": 4.3,
      "2017c": 4.2,
      "2016c": 5.3,
      "2015c": 6.3,
      "2014c": 7.3,
      "2013c": 6
    },
    {
      "2013": 7718,
      "2014": 7873,
      "2015": 8796,
      "2016": 9582,
      "2017": 10711,
      "2018": 11190,
      "2019": 12122,
      "2020": 10010,
      item: "Taxation planning and consulting services",
      taxexempt: "all",
      "2020c": 5.6,
      "2019c": 3.5,
      "2018c": 3.5,
      "2017c": 3.6,
      "2016c": 3.3,
      "2015c": 3.4,
      "2014c": 6.3,
      "2013c": 10.3
    },
    {
      "2013": 8013,
      "2014": 7781,
      "2015": 8767,
      "2016": 8612,
      "2017": 8823,
      "2018": 8213,
      "2019": 9026,
      "2020": 9133,
      item: "General accounting services",
      taxexempt: "all",
      "2020c": 9.2,
      "2019c": 10.8,
      "2018c": 7.8,
      "2017c": 7.3,
      "2016c": 10.1,
      "2015c": 10.3,
      "2014c": 9,
      "2013c": 7.8
    },
    {
      "2013": 6515,
      "2014": 6503,
      "2015": 5245,
      "2016": 5438,
      "2017": 6234,
      "2018": 6733,
      "2019": 7425,
      "2020": 8090,
      item: "Bookkeeping, compilation, billing, and collection services",
      taxexempt: "all",
      "2020c": 15.8,
      "2019c": 14.7,
      "2018c": 12.4,
      "2017c": 13.1,
      "2016c": 10.7,
      "2015c": 11.5,
      "2014c": 8.7,
      "2013c": 9.3
    }
  ],
  amusementparks: [
    {
      "2013": 13504,
      "2014": 14652,
      "2015": 16184,
      "2016": 17046,
      "2017": 17816,
      "2018": 19011,
      "2019": 20090,
      "2020": "S",
      item: "Revenue",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 0.4,
      "2018c": 0.3,
      "2017c": 0,
      "2016c": 0.9,
      "2015c": 0.8,
      "2014c": 0.8,
      "2013c": 0.5
    },
    {
      "2013": 8309,
      "2014": 8880,
      "2015": 9967,
      "2016": 10530,
      "2017": 10985,
      "2018": 11846,
      "2019": 12503,
      "2020": "S",
      item: "Admissions revenue (excluding admissions taxes)",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 0.6,
      "2018c": 0.5,
      "2017c": 0.3,
      "2016c": 0.9,
      "2015c": 0.9,
      "2014c": 0.8,
      "2013c": 0.5
    },
    {
      "2013": 5195,
      "2014": 5772,
      "2015": 6217,
      "2016": 6516,
      "2017": 6831,
      "2018": 7165,
      "2019": 7587,
      "2020": "S",
      item: "All other operating revenue",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 0.5,
      "2018c": 0.5,
      "2017c": 0.5,
      "2016c": 1.1,
      "2015c": 1,
      "2014c": 1,
      "2013c": 0.7
    }
  ],
  performance: [
    {
      "2013": 5621,
      "2014": 5642,
      "2015": 6593,
      "2016": 6938,
      "2017": 7357,
      "2018": 7711,
      "2019": 8316,
      "2020": "S",
      item: "Admissions revenue (excluding admissions taxes)",
      taxexempt: "FALSE",
      "2020c": "S",
      "2019c": 5.4,
      "2018c": 3,
      "2017c": 0.9,
      "2016c": 6,
      "2015c": 4.4,
      "2014c": 5.1,
      "2013c": 6.3
    },
    {
      "2013": 7737,
      "2014": 7860,
      "2015": 8427,
      "2016": 8931,
      "2017": 9392,
      "2018": 9787,
      "2019": 10502,
      "2020": 4211,
      item: "Revenue",
      taxexempt: "FALSE",
      "2020c": 5.2,
      "2019c": 4.4,
      "2018c": 2.6,
      "2017c": 0,
      "2016c": 5.6,
      "2015c": 4.2,
      "2014c": 4.2,
      "2013c": 5.2
    },
    {
      "2013": 2116,
      "2014": 2218,
      "2015": 1834,
      "2016": 1993,
      "2017": 2035,
      "2018": 2076,
      "2019": "S",
      "2020": 1616,
      item: "All other operating revenue",
      taxexempt: "FALSE",
      "2020c": 6.2,
      "2019c": "S",
      "2018c": 5,
      "2017c": 3.1,
      "2016c": 6.7,
      "2015c": 5.1,
      "2014c": 6.7,
      "2013c": 6.5
    }
  ],
  temps: [
    {
      "2013": 4709,
      "2014": 5476,
      "2015": 5434,
      "2016": 5604,
      "2017": 5512,
      "2018": 5849,
      "2019": 6355,
      "2020": "S",
      item: "Temporary staffing-to-permanent placement",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 13,
      "2018c": 11,
      "2017c": 9.1,
      "2016c": 11.9,
      "2015c": 11.2,
      "2014c": 23.4,
      "2013c": 20.1
    },
    {
      "2013": 131453,
      "2014": 140629,
      "2015": 155930,
      "2016": 173338,
      "2017": 180118,
      "2018": 188702,
      "2019": 195153,
      "2020": 182364,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.1,
      "2019c": 1.4,
      "2018c": 1.4,
      "2017c": 0,
      "2016c": 2.6,
      "2015c": 2.5,
      "2014c": 2.1,
      "2013c": 1.2
    },
    {
      "2013": 108307,
      "2014": 115888,
      "2015": 129760,
      "2016": 146083,
      "2017": 153975,
      "2018": 159732,
      "2019": 164453,
      "2020": 149219,
      item: "Temporary staffing services",
      taxexempt: "all",
      "2020c": 2.7,
      "2019c": 2.2,
      "2018c": 2.2,
      "2017c": 1.7,
      "2016c": 2.3,
      "2015c": 2.6,
      "2014c": 2.7,
      "2013c": 1.9
    },
    {
      "2013": 8913,
      "2014": 9740,
      "2015": 7234,
      "2016": 7430,
      "2017": 7581,
      "2018": 8233,
      "2019": 8677,
      "2020": 14769,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 6.1,
      "2019c": 11.4,
      "2018c": 11,
      "2017c": 10,
      "2016c": 9,
      "2015c": 9.4,
      "2014c": 12,
      "2013c": 10.9
    },
    {
      "2013": 9524,
      "2014": 9525,
      "2015": 13502,
      "2016": 14221,
      "2017": 13050,
      "2018": 14888,
      "2019": 15668,
      "2020": 14497,
      item: "Long-term staffing",
      taxexempt: "all",
      "2020c": 14.3,
      "2019c": 14,
      "2018c": 16.9,
      "2017c": 17.1,
      "2016c": 19.6,
      "2015c": 16.8,
      "2014c": 14.7,
      "2013c": 12.2
    }
  ],
  functionaltherapy: [
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4738,
      "2016": 4641,
      "2017": 4422,
      "2018": 4367,
      "2019": 4144,
      "2020": "S",
      item: "All other sources of revenue for patient care",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 5.9,
      "2018c": 6.5,
      "2017c": 8.6,
      "2016c": 11.7,
      "2015c": 8.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Contributions, gifts, and grants received",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "NA",
      "2016": "NA",
      "2017": "NA",
      "2018": "NA",
      "2019": "NA",
      "2020": "NA",
      item: "Investment and property income",
      taxexempt: "all",
      "2020c": "NA",
      "2019c": "NA",
      "2018c": "NA",
      "2017c": "NA",
      "2016c": "NA",
      "2015c": "NA",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": 24379,
      "2014": 26198,
      "2015": 28410,
      "2016": 30111,
      "2017": 31515,
      "2018": 34095,
      "2019": 35455,
      "2020": 32951,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.2,
      "2019c": 1.6,
      "2018c": 1.4,
      "2017c": 0,
      "2016c": 4,
      "2015c": 3.6,
      "2014c": 3.4,
      "2013c": 1.4
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 7463,
      "2016": 8050,
      "2017": 8669,
      "2018": 9631,
      "2019": 10298,
      "2020": 9378,
      item:
        "Private health insurance (includes Medicare (part C) and Medicaid managed care plans)",
      taxexempt: "all",
      "2020c": 3.4,
      "2019c": 3.1,
      "2018c": 2.4,
      "2017c": 2.9,
      "2016c": 5.2,
      "2015c": 5.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 4493,
      "2016": 4727,
      "2017": 5051,
      "2018": 5561,
      "2019": 5785,
      "2020": 5307,
      item: "Medicare (Fee for service only from parts A, B and D)",
      taxexempt: "all",
      "2020c": 3.9,
      "2019c": 3.9,
      "2018c": 3.7,
      "2017c": 4.5,
      "2016c": 4.6,
      "2015c": 4.4,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 3815,
      "2016": 4408,
      "2017": 4753,
      "2018": 5196,
      "2019": 5265,
      "2020": 4872,
      item: "Revenue from health care providers for patient care",
      taxexempt: "all",
      "2020c": 9.9,
      "2019c": 8.4,
      "2018c": 8.3,
      "2017c": 7.1,
      "2016c": 6.6,
      "2015c": 6.5,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 2456,
      "2016": 2588,
      "2017": 2747,
      "2018": 2973,
      "2019": 3343,
      "2020": 3084,
      item: "Patient out-of-pocket from patients and their families",
      taxexempt: "all",
      "2020c": 7.1,
      "2019c": 7.1,
      "2018c": 7,
      "2017c": 6.9,
      "2016c": 8.6,
      "2015c": 11.6,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1382,
      "2016": 1444,
      "2017": 1451,
      "2018": 1570,
      "2019": 1706,
      "2020": 1683,
      item: "Medicaid (Fee for service only)",
      taxexempt: "all",
      "2020c": 12.3,
      "2019c": 11.6,
      "2018c": 12.4,
      "2017c": 11.7,
      "2016c": 9.6,
      "2015c": 11.2,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 1475,
      "2016": 1542,
      "2017": 1627,
      "2018": 1794,
      "2019": 1849,
      "2020": 1680,
      item: "Workers' compensation",
      taxexempt: "all",
      "2020c": 6.9,
      "2019c": 7.3,
      "2018c": 5.9,
      "2017c": 6.4,
      "2016c": 5.2,
      "2015c": 5.7,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": "S",
      "2016": 950,
      "2017": 990,
      "2018": 1113,
      "2019": 1159,
      "2020": 1178,
      item: "All other government programs",
      taxexempt: "all",
      "2020c": 13.6,
      "2019c": 17.8,
      "2018c": 15.6,
      "2017c": 12,
      "2016c": 8,
      "2015c": "S",
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 657,
      "2016": 571,
      "2017": "S",
      "2018": "S",
      "2019": 606,
      "2020": 563,
      item: "All other non-patient care revenue",
      taxexempt: "all",
      "2020c": 15.4,
      "2019c": 12.9,
      "2018c": "S",
      "2017c": "S",
      "2016c": 10.6,
      "2015c": 15.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 600,
      "2016": 540,
      "2017": 598,
      "2018": 616,
      "2019": "S",
      "2020": 549,
      item:
        "providers for non-patient care medical admin, incentives, management, medical director",
      taxexempt: "all",
      "2020c": 10.9,
      "2019c": "S",
      "2018c": 10.1,
      "2017c": 11.7,
      "2016c": 11.2,
      "2015c": 13.3,
      "2014c": "NA",
      "2013c": "NA"
    },
    {
      "2013": "NA",
      "2014": "NA",
      "2015": 423,
      "2016": 481,
      "2017": 472,
      "2018": 561,
      "2019": 552,
      "2020": 512,
      item: "Property, auto, and casualty insurance",
      taxexempt: "all",
      "2020c": 13.3,
      "2019c": 17.5,
      "2018c": 20.4,
      "2017c": 18.2,
      "2016c": 14.7,
      "2015c": 12.6,
      "2014c": "NA",
      "2013c": "NA"
    }
  ],
  travel: [
    {
      "2013": 2425,
      "2014": 2689,
      "2015": 2344,
      "2016": 2384,
      "2017": 2669,
      "2018": 2636,
      "2019": 2915,
      "2020": "S",
      item:
        "Long-distance, passenger transportation by road, except fixed-route",
      taxexempt: "all",
      "2020c": "S",
      "2019c": 4.1,
      "2018c": 3.9,
      "2017c": 5.7,
      "2016c": 4.7,
      "2015c": 4.8,
      "2014c": 8,
      "2013c": 7.5
    },
    {
      "2013": 31713,
      "2014": 33887,
      "2015": 35572,
      "2016": 38343,
      "2017": 44836,
      "2018": 50319,
      "2019": 55964,
      "2020": 39382,
      item: "Revenue",
      taxexempt: "all",
      "2020c": 2.9,
      "2019c": 1.7,
      "2018c": 1.2,
      "2017c": 0.7,
      "2016c": 1.6,
      "2015c": 1.6,
      "2014c": 1,
      "2013c": 0.9
    },
    {
      "2013": 11763,
      "2014": 12615,
      "2015": 11641,
      "2016": 13057,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": 16096,
      item: "Local, passenger transportation by road, except fixed-route",
      taxexempt: "all",
      "2020c": 4.9,
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 3.1,
      "2015c": 3.3,
      "2014c": 3.1,
      "2013c": 2.6
    },
    {
      "2013": 13187,
      "2014": 13988,
      "2015": 13671,
      "2016": 14103,
      "2017": 15393,
      "2018": 16599,
      "2019": 17936,
      "2020": 13023,
      item:
        "Local, fixed-route passenger transportation by road and transit rail",
      taxexempt: "all",
      "2020c": 3.3,
      "2019c": 3.7,
      "2018c": 2.9,
      "2017c": 3,
      "2016c": 4.9,
      "2015c": 4.5,
      "2014c": 3.2,
      "2013c": 2.9
    },
    {
      "2013": 1756,
      "2014": 1917,
      "2015": 4017,
      "2016": 4550,
      "2017": "S",
      "2018": "S",
      "2019": "S",
      "2020": 4901,
      item: "All other operating revenue",
      taxexempt: "all",
      "2020c": 6.5,
      "2019c": "S",
      "2018c": "S",
      "2017c": "S",
      "2016c": 6.4,
      "2015c": 6.7,
      "2014c": 8.6,
      "2013c": 5.6
    },
    {
      "2013": 2582,
      "2014": 2678,
      "2015": 3899,
      "2016": 4249,
      "2017": 4511,
      "2018": 4774,
      "2019": 5131,
      "2020": 3968,
      item: "Long-distance, fixed-route passenger transportation by road",
      taxexempt: "all",
      "2020c": 7.2,
      "2019c": 5.6,
      "2018c": 5.6,
      "2017c": 6.2,
      "2016c": 6.8,
      "2015c": 6.3,
      "2014c": 6.2,
      "2013c": 6.4
    }
  ]
};
class Revenues extends React.Component {
  constructor(props) {
    super(props);

    //let testing = [];
    let noData = [];
    let date = [];
    let currentDataData = [];
    let industries = [];
    let allindustry = [];
    let revenues = [];
    var revenueinitial = "Revenue";
    var industryInitial = "finance";
    Object.keys(revenue).forEach((industry, i) => {
      industries.push(industry);
      const dta = Object.values(revenue)[i];
      dta.forEach((itm, i) => {
        const years = Object.keys(itm).filter((x) => !isNaN(x));
        years.forEach((year) => {
          if (itm[year] && !isNaN(itm[year])) {
            allindustry.push(itm[year]);
            if (industryInitial === industry) {
              const item = itm.item;
              if (!revenues.includes(item)) revenues.push(item);
              if (revenues[0] === item) {
                revenueinitial = revenues[0];
                date.push(Number(year));
                noData.push([Number(year), 0]);
                currentDataData.push([Number(year), itm[year]]);
              }
            }
          }
        });
      });
    });
    var lowPPCS = Math.min(...allindustry);
    var lowDate = Math.min(...date);
    var highPPCS = Math.max(...allindustry);
    var highDate = Math.max(...date);
    var state = {
      industry: industryInitial,
      date,
      revenue: revenueinitial,
      revenues,
      industries,
      highPPCS,
      currentDataData,
      noData,
      yAxisPPCS: highPPCS - null,
      xAxisPPCS: highDate - lowDate,
      lowPPCS,
      highDate,
      lowDate
    };
    this.state = state;
  }
  componentDidUpdate = () => {
    if (
      this.state.industry !== this.state.laststate ||
      this.state.revenue !== this.state.lastrevenue
    ) {
      const newset = this.state.industry !== this.state.laststate;

      this.setState(
        {
          laststate: this.state.industry,
          lastrevenue: this.state.revenue
        },
        () => {
          let currentData = [];
          let currentDataData = [];
          let allindustry = [];
          let revenues = [];
          Object.keys(revenue).forEach((industry, i) => {
            const dta = Object.values(revenue)[i];
            dta.forEach((itm, i) => {
              const years = Object.keys(itm).filter((x) => !isNaN(x));
              years.forEach((year) => {
                if (itm[year] && !isNaN(itm[year])) {
                  allindustry.push(itm[year]);
                  if (this.state.industry === industry) {
                    const item = itm.item;
                    if (item !== "Revenue" || newset)
                      currentData.push(itm[year]);
                    if (!revenues.includes(item)) revenues.push(item);
                    const copy = !newset ? this.state.revenue : "Revenue"; //revenues[0];
                    if (copy === item) {
                      currentDataData.push([Number(year), itm[year]]);
                    }
                  }
                }
              });
            });
          });
          const all =
            newset || this.state.revenue === "Revenue"
              ? allindustry
              : currentData;
          var highPPCS = Math.max(...all);
          var state = {
            revenues,
            currentDataData,
            highPPCS: Math.max(...currentData),
            lowPPCS: Math.min(...currentData),
            yAxisPPCS: highPPCS - 0
          };
          this.setState(state);
        }
      );
    }
  };
  render() {
    const { industries, revenues } = this.state;
    const labelstyle = {
      backgroundColor: "rgba(50,120,200,.6)",
      top: "0px",
      height: "min-content",
      display: "flex",
      maxWidth: "100%",
      left: "2px",
      flexWrap: "wrap"
    };
    const buttonStyle = {
      userSelect: "none",
      border: "1px solid black",
      color: "black",
      backgroundColor: "rgb(220,220,220)",
      borderRadius: "4px",
      padding: "5px",
      margin: "2px"
    };

    const lineheight = this.props.lineheight ? this.props.lineheight : 200;
    const linecss = {
      left: "0px",
      bottom: "0px",
      display: "flex",
      position: "absolute",
      width: "100%",
      height: lineheight + 10,
      transform: "translate(0%,0%) scale(1,-1)"
    };
    const shortNumber = (scler, notRound) => {
      var newnum = String(Math.round(scler));
      if (notRound) newnum = String(scler);
      var app = null;
      var decimal = null;
      const suff = ["", "k", "m", "b", "t"];
      for (let i = 0; i < suff.length; i++) {
        if (newnum.length > 3) {
          decimal = newnum[newnum.length - 3];
          newnum = newnum.substring(null, newnum.length - 3);
        } else {
          app = i;
          break;
        }
      }
      return newnum + (decimal ? "." + decimal : "") + suff[app];
    };
    const coefficience = (this.props.lastWidth - 60) / this.props.lastWidth;
    //console.log(this.state.oilprice);
    const noData = this.state.noData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxis) *
        coefficience *
        this.props.lastWidth,
      0
    ]);
    //console.log(this.state.oilprice);

    const currentDataData = this.state.currentDataData.map(([x, y]) => [
      ((x - this.state.lowDate) / this.state.xAxisPPCS) *
        coefficience *
        this.props.lastWidth,
      ((y - 0) / this.state.yAxisPPCS) * lineheight
    ]);
    //Historic data for Table 3. Covered revenues, employment, and wages by industry

    /**[
"gas",
"waterandsewage",
"air",
"ferryshipping",
"trucking",
"travel",
"pipelinefees",
"newspapers",
"expositions",
"books",
"classifieds",
"greetings",
"otherpublishers",
"software",
"productions",
"exhibitions",
"editing",
"records",
"musicpublishers",
"recording",
"radionetworking",
"radiostations",
"television",
"programming",
"networking",
"wireless",
"satellite",
"releasing",
"databases",
"syndications",
"websites",
"otherinformation",
"finance",
"depositories",
"depositaries",
"macroeconomics",
"standardizedguaranteeschemes",
"standardizedguaranteeschemesmedical",
"leasing",
"leasingoffices",
"fees",
"legal",
"legaloffice",
"bookkeepingandaccounting",
"architecture",
"engineering",
"computers",
"consulting",
"science",
"advertising",
"publicrelations",
"advertisingmail",
"temps",
"travelagency",
"touring",
"booking",
"wastecollection",
"wastetreatment",
"materialrecovery",
"otherwaste",
"physicianoffices",
"psychiatryoffices",
"Offices of Dentists",
"otherhealthoffices",
"chiropractors",
"optometristoffices",
"therapyoffices",
"functionaltherapy",
"podiatryoffices",
"voluntarycare",
"familyplanners",
"voluntarypanicrooms",
"HMO Medical Centers",
"kidneydialysis",
"cashmedical",
"othervoluntary",
"diagnosticlabs",
"homecare",
"ambulance",
"hospitalizations",
"nursecare",
"skillednursecare",
"interventions",
"elderlycare",
"otherresidentialcare",
"socialwork",
"performance",
"sportclubs",
"racing",
"othersports",
"museumsandhistoricalsites",
"amusementparks",
"organizations"
] */
    return (
      <div
        style={{
          width: "100%",
          height: lineheight + 30,
          position: "relative",
          backgroundColor: "rgb(0,40,0)"
        }}
      >
        <a
          style={{
            zIndex: "1",
            color: "white",
            fontWeight: "bolder",
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px",
            bottom: "0px"
          }}
          href="https://www.census.gov/data/tables/2020/econ/services/sas-naics.html"
        >
          Revenue for Employer Firms (table 4: by source)
        </a>

        <div
          style={{
            color: "white",
            backgroundColor: "rgba(255,255,255,.3)",
            padding: "4px 8px",
            position: "absolute",
            right: "0px"
          }}
        >
          <select
            state={this.state.industry}
            onChange={(name) => {
              this.setState({ industry: name.target.value });
            }}
            style={{ width: "100px" }}
          >
            {industries.map((name) => (
              <option key={name}>{name}</option>
            ))}
          </select>
          :
          <select
            state={this.state.revenue}
            onChange={(name) => {
              this.setState({ revenue: name.target.value });
            }}
            style={{ width: "100px" }}
          >
            {revenues.map((name) => (
              <option key={name}>{name}</option>
            ))}
          </select>
          Revenues ${shortNumber(this.state.lowPPCS * 1000000)}-
          {shortNumber(this.state.highPPCS * 1000000)}
        </div>
        <div
          style={{
            display: "flex"
          }}
        >
          <div
            style={{
              width: "min-content",
              padding: "4px 8px",
              backgroundColor: "rgba(250,250,250,.6)",
              height: "min-content",
              display: "flex",
              position: "relative"
            }}
          >
            {this.state.lowDate} &nbsp;-&nbsp; {this.state.highDate}
          </div>
          <svg
            style={{ ...linecss, marginLeft: "50px" }}
            xmlns="http://www.w3.org/2000/svg"
          >
            {noData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={4}
                    height={4}
                    stroke="rgb(230,230,230)"
                    fill="transparent"
                    strokeWidth={0}
                    key={i}
                  />
                )
            )}
            {currentDataData.map(
              ([x, y], i) =>
                !isNaN(x) &&
                !isNaN(y) && (
                  <rect
                    x={x}
                    y={y}
                    width={2}
                    height={2}
                    stroke="white"
                    fill="blue"
                    strokeWidth={1}
                    key={i}
                  />
                )
            )}
          </svg>
        </div>
        {/*
        <div
          style={{
            backgroundColor: "rgba(250,250,250,0.6)",
            top: "10px",
            height: "40px",
            display: "flex",
            position: "relative",
            width: "100%",
            left: "2px",
            zIndex: "0",
            overflowX: "auto",
            overflowY: "hidden"
          }}
        >
          <div
            style={{
              fontSize: "15px",
              display: "flex",
              position: "absolute",
              width: "max-content"
            }}
          ></div>
        </div>
        */}
      </div>
    );
  }
}

export default Revenues;
